<template>
    <section class="add-contact">
        <div class="add-contact-form">
            <button class="add-contact-form__exit-btn"
                    @click="this.$emit('closeRename')">
                <img src="../../../../../assets/icons/components/exit-btn.svg" />
            </button>
            <section class="add-contact-form__form-wrapper">
                <h2 class="add-contact-form__title">
                    Zmień nazwę dokumentu
                </h2>
                <section class="add-contact-form__inputs">
                    <section>
                        <section>
                            <label class="add-contact-form__label">Nazwa</label>
                            <input type="text"
                                   v-model="dataToEdit.name"
                                   class="add-contact-form__input-text"
                                    />
                        </section>
                    </section>
                </section>
                <section class="add-contact-form__bottom-btns">
                    <button class="add-contact-form__action-btn"
                            @click="this.$emit('closeRename')">
                        Anuluj
                    </button>
                    <button class="
                            add-contact-form__action-btn
                            add-contact-form__action-btn--red
                        "
                            @click="(e) => handleRenameClick(e)">
                        Zapisz
                    </button>
                </section>
            </section>
            <LoaderInside v-if="isLoading" />
        </div>
    </section>
</template>

<script>
import ToolsService from "../../../../../services/tools.service";
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";

export default {
    name: "AddContact",
    data() {
        return {
            isLoading: false,
            errors: [],
            dataToEdit: {...this.data}
        };
    },
    components: {
        LoaderInside,
    },
    props: {
        data: Object
    },
    methods: {
        async handleRenameClick(e) {
            e.preventDefault();
            this.isLoading = true;

            try {
                var result = (await ToolsService.changeDocumentName(this.dataToEdit));
                if (result.status == 200) {
                    this.$emit('changeName', this.dataToEdit);
                    this.$emit("closeRename");
                }

                this.isLoading = false;

            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        },

    },
};
</script>

<style lang="scss" scoped>

    [data-tip] {
        position: relative;
    }

    [data-tip]:before {
        content: '';
        /* hides the tooltip when not hovered */
        display: none;
        content: '';
        display: none;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #1a1a1a;
        position: absolute;
        top: 55px;
        left: 35px;
        z-index: 8;
        font-size: 0;
        line-height: 0;
        width: 0;
        height: 0;
        position: absolute;
        top: 50px;
        left: 35px;
        z-index: 8;
        font-size: 0;
        line-height: 0;
        width: 0;
        height: 0;
    }

    [data-tip]:after {
        display: none;
        content: attr(data-tip);
        position: absolute;
        top: 55px;
        left: 0px;
        padding: 5px 8px;
        background: #1a1a1a;
        color: #fff;
        z-index: 9;
        font-size: 0.75em;
        height: 18px;
        line-height: 18px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        white-space: nowrap;
        word-wrap: normal;
    }

    [data-tip]:hover:before,
    [data-tip]:hover:after {
        display: block;
    }

    .add-contact {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 8;
        background: #00082c75;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .add-contact-form {
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        padding: 32px 56px;
        min-width: 612px;
        max-height: 90vh;
        padding-right: 0px;

        &__form-wrapper {
            max-height: 80vh;
            overflow-y: auto;
            padding-right: 56px;

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }
        }

        &__exit-btn {
            border: 0px;
            background-color: transparent;
            position: relative;
            right: calc(-100% + 48px);
        }

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 32px;

            &--small {
                font-size: 20px;
                margin-bottom: 27px;
            }
        }

        &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #dde0e7;
            padding-top: 32px;
        }

        &__row {
            display: flex;
            justify-content: space-between;
        }

        &__col {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;

            &:nth-child(1) {
                margin-right: 24px;
            }
        }

        &__col-duo {
            display: flex;
        }

        &__label {
            font-weight: 500;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-bottom: 8px;

            &--checkbox {
                font-weight: normal;
                font-size: 16px;
                line-height: 144%;
                color: #333956;
                margin-left: 12px;
                margin-bottom: 0px;
                padding-left: 24px;
                z-index: 6;
                cursor: pointer;
            }
        }

        &__input-text {
            margin-bottom: 10px;
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            width: 100%;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;
            min-width: 238px;

            &:focus {
                border: 1px solid #00082c;
            }

            &--small {
                min-width: 107px;
                margin-top: 7px;
            }
        }

        &__col-double {
            &__label {
                font-weight: 500;
                font-size: 14px;
                line-height: 145%;
                color: #00082c;
                margin-bottom: 8px;
            }

            &:nth-child(1) {
                margin-right: 24px;
            }
        }

        &__inputs {
            max-width: 500px;
            border-bottom: 1px solid #dde0e7;
            padding-bottom: 38px;
        }

        &__groups {
            margin-top: 28px;
        }

        &__checkbox {
            display: flex;
            align-items: center;
            margin-bottom: 13px;
        }

        &__check-icon {
            width: 0px;
            height: 24px;
            position: relative;
            opacity: 1;

            &:checked ~ .checkbox-mark {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:checked ~ .add-contact-form__label--checkbox {
                padding-left: 0px;
            }

            &:before {
                content: "";
                background: #ffffff;
                border: 1px solid #d1d6df;
                box-sizing: border-box;
                box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
                border-radius: 4px;
                width: 24px;
                height: 24px;
                position: relative;
                display: block;
                top: 0px;
                left: 0px;
                opacity: 1;
            }
        }

        &__bottom-btns {
            margin-top: 32.5px;
            padding-bottom: 56px;
        }

        &__bottom-btns-pass {
            margin-top: 0px;
            padding-bottom: 0px;
        }

        &__action-btn {
            padding: 18px 40px 17px;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            min-width: 238px;
            border: 1px solid #bbc2ce;
            background-color: transparent;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                border: 0px;
                background: #d80418;
                color: white;
                margin-left: 24px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: #ad0313;
                }
            }
        }
    }

    .import-from-db {
        margin-bottom: 27px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: #e9eef5;
        border-radius: 4px;
        padding: 9px 16px;
        border: 0px;

        &__text {
            margin-left: 8px;
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;
            color: #00082c;
        }
    }

    .checkbox-mark {
        background: #00082c;
        border: 1px solid #00082c;
        box-sizing: border-box;
        box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        width: 24px;
        height: 24px;
        position: relative;
        z-index: 99;
        display: none;
        top: 0px;
        left: 0px;
        opacity: 1;
    }
</style>
