<template>
    <button :type="type" :class="buttonClass" :disabled="disable">
        <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
        <slot></slot>
    </button>
</template>
<script charset="utf-8">
    export default {
        name: "ButtonOutline",
        props: {
            size: {
                type: String,
                default: ""
            },
            type: {
                type: String,
                default: "button"
            },
            color: {
                type: String,
                default: "primary"
            },
            disable: {
                type: Boolean,
                default: false
            },
            isLoading: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            buttonClass() {
                let btnClass = "btn";

                switch (this.color) {
                    case "primary": btnClass += " btn-outline-primary"; break;
                    case "secondary": btnClass += " btn-outline-secondary"; break;
                    case "success": btnClass += " btn-outline-success"; break;
                    case "danger": btnClass += " btn-outline-danger"; break;
                    case "warning": btnClass += " btn-outline-warning"; break;
                    case "info": btnClass += " btn-outline-info"; break;
                    case "light": btnClass += " btn-outline-light"; break;
                    case "dark": btnClass += " btn-outline-dark"; break;
                    case "link": btnClass += " btn-outline-link"; break;
                    default: btnClass += " btn-outline-primary"; break;
                }

                switch (this.size) {
                    case "lg": btnClass += " btn-lg"; break;
                    case "md": btnClass += " btn-md"; break;
                    case "sm": btnClass += " btn-sm"; break;
                }
                return btnClass;
            }
        }
    }
</script>
<style lang="scss">
    .btn-outline-light {
        color: #00082c !important;
        border-color: #bbc2ce !important;
        border-radius: 4px;
    }
</style>