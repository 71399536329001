<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Przenoszenie punktu</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeMovePointModal');}">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            
            <perfect-scrollbar class="esesja-modal__wraper__body">
               
                <section v-for="(s) in pointListToUpdate" :key="s.id">

                    <hr class="line-element" v-if="tempSelectedId == s.id && tempSelectedId != pointToMove.id && tempSelectedId != 0" />

                    <section class="box" @mouseover="tempSelectedId = s.id" v-tooltip.bottom="{ content: s.id != pointToMove.id && s.id != 0 ? 'Przenieś nad punkt nr ' + filterIndex(s, s.depth) : ''}" @click="movePointToPlace(s)">
                            
                        <section class="box__title" :class="{'point-to-move': s.id == pointToMove.id, 'last-point': s.id == 0 }" >
                            {{s.id != 0 ? filterIndex(s, s.depth) : ''}} {{ s.name }}
                        </section>

                    </section>
                </section>
 
            </perfect-scrollbar>

        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import SessionService from "../../../../../services/session.service";
import PointsNumerationHelper from "@/helpers/pointsNumeration.helper";

export default {
    name: "AddLink",
    data() {
        return {
            isLoading: false,
            tempSelectedId: 0,
            pointListToUpdate: PointsNumerationHelper.setOrderToDisplayRecursive([...this.pointList]),
        };
    },
    props: {
        pointList: Array,
        pointToMove: Object,
        firstLevel: Number,
        secondLevel: Number,
        thirdLevel: Number,
        sid: Number
    },
    components: {
        LoaderInside,
    },
    mounted() {
        var lastPoint = {
            name: 'Umieść punkt na końcu listy',
            id: 0,
            depth: this.pointList[0].depth
        };

        this.pointListToUpdate.push(lastPoint);
    },
    methods: {
        filterIndex(elem, level) {
            return PointsNumerationHelper.filterIndex(elem.orderToDisplay, elem.number, level, this.firstLevel, this.secondLevel, this.thirdLevel);
        },
        movePointToPlace(point) {
            if (point.id != this.pointToMove.id) {
                this.isLoading = true;

                var data = {
                    pointsList: this.changePointsOrder(point.id),
                    depth: this.pointList[0].depth
                };

                SessionService.movePointToSpecyficPlace(this.sid, data).then(() => {
                    this.isLoading = false;
                    this.$emit("closeMovePointModal");
                    this.$emit("reload");

                }).catch(() => {
                    this.isLoading = false;
                });
            }
        },
        changePointsOrder(pointAboveId) {
            var change = [...this.pointList];

            var element = change.find(x => x.id == this.pointToMove.id);
            var index = change.findIndex(x => x.id == this.pointToMove.id);
            var idsArray = [];
            if (index != -1) {
                change.splice(index, 1);

                if (pointAboveId == 0) {

                    for (var i = 0; i < change.length; i++) {
                        idsArray.push(change[i].id);
                    }
                    // last elemnt
                    idsArray.push(element.id);
                }
                else {

                    for (var j = 0; j < change.length; j++) {
                        if (change[j].id == pointAboveId) {
                            idsArray.push(element.id);
                            idsArray.push(change[j].id);
                        }
                        else {
                            idsArray.push(change[j].id);
                        }
                    }
                }
            }

            return idsArray;
        }
    },
};
</script>

<style lang="scss" scoped>

    @import "../../../../../assets/scss/esesja-modal.scss";

    .last-point {
        background-color: lightpink;
        border-radius: 12px;
        text-align: center;
    }

    hr.line-element {
        border-top: 5px solid dodgerblue;
        border-radius: 15px;
        margin-bottom: 5px;
    }

    .point-to-move {
        background-color: dodgerblue;
        border-radius: 12px;
    }

    .box {
        background: #ffffff;
        //width: 100%;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
        border-radius: 12px;
        margin-bottom: 12px;
        //transition: all 0.2s ease-in-out;

        &__title {
            font-size: 16px;
            padding: 8px;
            overflow-wrap: break-word;
            word-break: break-all;
        }

        &__top {
            //display: flex;
            align-items: center;
            padding: 29px;
            justify-content: space-between;
            font-size: 16px;
        }

        &__active {
            align-items: center;
            padding: 29px;
            justify-content: space-between;
            border: 1px solid green;
            background: #f7fff7;
            border-radius: 12px;
            font-size: 24px;
        }

        &--takenoff {
            text-decoration: line-through !important;
        }

        &:hover {
            //background-color: lightgray;
            cursor: pointer;
        }
    }
</style>

