import mainStore from "../store/mainStore";
import { generateUUID } from "../helpers/utils";

class ImportContactServive {
    constructor() {
        this._dataDelimeter = ";";
        this._groupsDelimeter = ":";
    }

    parseInputData(inlineData) {
        if (inlineData === undefined || inlineData === null)
            throw new Error("Dane nie mogą być pustę");

        let rows = this.getRows(inlineData);

        let data = rows.map(row => this.getContactObject(row));

        mainStore.commit('setContactImportData', data)
        mainStore.commit('setCurrentImportStep', 2)
        mainStore.commit("setContactImportStepDone", 1);
    }

    getRows(inlineData) {
        let rows = [];

        if (inlineData.includes("\n\r")) {
            rows = inlineData
                .trim()
                .split("\n\r")
                .map(line => line.trim());
        }
        else {
            rows = inlineData
                .trim()
                .split("\n")
                .map(line => line.trim());
        }

        return rows;
    }

    getContactObject(row) {
        if (row === undefined || row === null)
            return {};

        let splitedRow = row.split(this._dataDelimeter);

        return {
            id: generateUUID(),
            isUser: false,
            isEdit: false,
            name: this.getItemObj(splitedRow[0]?.trim() ?? ""),
            surname: this.getItemObj(splitedRow[1]?.trim() ?? ""),
            phonenumber: this.getItemObj(splitedRow[2]?.trim() ?? null),
            email: this.getItemObj(splitedRow[3]?.trim() ?? null),
            groups: this.getItemObj(this.getGroups(splitedRow[4]?.trim() ?? ""))
        }
    }

    getItemObj(data) {
        return {
            value: data,
            hasError: false,
        }
    }

    getGroups(inlineGroups) {
        let splitedGroups = inlineGroups
            .split(this._groupsDelimeter)
            .map(line => line.trim().toLowerCase());


        let groups = [...mainStore.state.appStore.groups];


        let filteredGroups = groups.filter(group => splitedGroups.includes(group.name.trim().toLowerCase()));

        return filteredGroups?.length > 0 ? filteredGroups : [];
    }


    removeItem(item) {
        let items = [...mainStore.state.importContactStore.importData];

        items = items.filter(obj => obj.id !== item?.id);

        mainStore.commit('setContactImportData', items);
    }

    setEdit(item) {
        let items = [...mainStore.state.importContactStore.importData];

        items = items.map(obj => {
            let copy = { ...obj }

            copy.isEdit = obj.id === item.id
          
            return copy;
        })

        mainStore.commit('setContactImportData', items);
    }

    setSave(item, selectedGroups) {
        let items = [...mainStore.state.importContactStore.importData];
        let groups = [...mainStore.state.appStore.groups];
        items = items.map(obj => {
            let copy = { ...obj }

            copy.isEdit = false

            if (copy.id === item.id) {
                copy.groups.value = groups.filter(group => selectedGroups.includes(group.id));
            }

            return copy;
        })

        mainStore.commit('setContactImportData', items);
    }

    clearStore() {
        mainStore.state.importContactStore.importData = [];
        mainStore.state.importContactStore.currentStep = 1;
        mainStore.state.importContactStore.allContactsHaveGroups = true;
        mainStore.state.importContactStore.stepsDone = [];
    }
}

export default new ImportContactServive();