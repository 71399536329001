import ApiService from "./api.service";

class SearchEngineService extends ApiService {
    MainSearch(request) {
        return this.get("search-engine/main-search", {
            headers: {
                'Content-Type': 'application/json'
            },
            params: request
        })
    }
}

export default new SearchEngineService();