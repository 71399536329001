<template>
    <section class="login">
        <section></section>
        <section class="login__form-wrapper">
            <img src="../assets/imgs/logo.png" class="login__logo" />
            <div class="login-form">
                <h1 class="login-form__header">Logowanie</h1>
            </div>

        </section>
        <span class="login__bottom-text">eSesja © 2023 Wszelkie prawa zastrzeżone <br /></span>
    </section>
</template>

<script>
    import UserService from "../services/user.service";

    export default {
        name: "ADFS",
        data() {
            return {
                user: ''
            };
        },
        mounted() {
            this.user = this.$route.query.u;

            UserService.loginAdfs(this.user).catch(() => {
                this.$store.commit("destroyUser");
                this.$store.commit("turnOffInteractive");
            });
        },
        methods: {

        },

    };
</script>

<style lang="scss" scoped>
    .forot-password-link {
        margin-top: -20px;
        margin-bottom: 10px;
        color: #333956;
        transition: all 0.2s ease-in-out;

        &:hover {
            color: #6c76a7;
            text-decoration: underline;
        }
    }

    .login {
        width: 100vw;
        height: 100vh;
        background: radial-gradient( 235.38% 418.45% at 0% 0%, #00082c 0%, #004dcc 100% );
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        font-family: Inter;
        position: relative;

        &__logo {
            margin-bottom: 26px;
            width: 157px;
            height: 47px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }

        &__bottom-text {
            margin-bottom: 18px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            color: #afb8c6;
        }
    }

    .login-form {
        padding: 48px 40px 56px 40px;
        display: flex;
        flex-direction: column;
        background: linear-gradient(146.23deg, #d5e0f5 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;

        &__header {
            text-align: center;
            color: #00082c;
            font-size: 28px;
            font-weight: 600;
        }

        &__text {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            margin-top: 12px;
            color: #333956;
        }

        &__label {
            margin-bottom: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-top: 24px;

            &--checkbox {
                padding-left: 32px;
                font-size: 14px;
                color: #333956;
                margin-bottom: 0px;
                font-weight: 400;
                z-index: 10;
                margin-top: 0px;
                cursor: pointer;
            }
        }

        &__input-text {
            padding: 16.5px 20px 16.5px 20px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);

            &:focus {
                border: 1px solid #00082c;
            }

            &--password {
                width: 80%;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-right: 0px;
            }

            border-radius: 4px;

            &:focus {
                outline: none;
            }

            &::placeholder {
                font-weight: normal;
                font-size: 16px;
                line-height: 144%;
                color: #808396;
            }
        }

        &__password-container {
            display: flex;
        }

        &__button-show {
            width: 20%;
            height: 51px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            border-left: 0px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            &::after {
                content: "";
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translateY(-50%);
                height: 40px;
                width: 1px;
                background: #e2e5eb;
            }
        }

        &__login-btn {
            background-color: #d80418;
            padding: 18px 0px;
            color: white;
            border: 0px;
            font-weight: 500;
            font-size: 16px;
            border-radius: 4px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            margin-top: 30px;

            &:hover {
                background-color: #ad0313;
            }

            &:disabled {
                background-color: grey;
                cursor: default;
            }
        }

        &__err-msg {
            color: #d80418;
            text-align: center;
            margin-bottom: 24px;
        }
    }

    .checkobx-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        margin-top: 24px;
    }

    .checkobx-btn {
        width: 24px;
        height: 24px;
        position: relative;
        opacity: 1;

        &:checked ~ .checkbox-mark {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:checked ~ .add-contact-form__label--checkbox {
            padding-left: 0px;
        }

        &:before {
            content: "";
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
            /*border-radius: 4px;*/
            width: 24px;
            height: 24px;
            position: relative;
            display: block;
            top: 0px;
            left: 0px;
            opacity: 1;
        }
    }

    .checkbox-mark {
        background: #00082c;
        border: 1px solid #00082c;
        box-sizing: border-box;
        box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        width: 24px;
        height: 24px;
        position: absolute;
        z-index: 5;
        display: none;
        top: 0px;
        left: 0px;
        opacity: 1;
    }
</style>
