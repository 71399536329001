<template>
    <div id="main" class="d-flex flex-column justify-content-center align-items-center">
        <div class="p-2">
            <div class="d-flex flex-row">
                <div class="me-3 pe-3 d-flex flex-column justify-content-center align-items-center border-end">
                    <h1 class="d-flex justify-content-center align-items-center">{{code}}</h1>
                    <h2 class="fw-normal d-flex justify-content-center align-items-center">{{title}}</h2>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <h2 class="fw-normal lead" id="desc">{{meessage}}</h2>
                </div>
            </div>
        </div>
        <div class="p-5">
            <a href="https://esesja.pl/" title="eSesja.pl" class="error-esesja-link" target="_blank">
                <img src="https://esesja.pl/img/logo-footer.png" alt="eSesja.pl" />
            </a>
        </div>
        <div>
            <div id="bern"></div>
        </div>
    </div>
</template>
<script charset="utf-8">
    export default {
        components: {},
        props: {
            code: {
                type: Number,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            meessage: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
            }
        },
        mounted() { },
        unmount() { },
        methods: {},
    }

</script>

<style type="text/css">
    #main {
        height: 100vh;
        background: grey;
        color: #333;
    }

        #main .error-esesja-link {
            opacity: 0.8
        }

            #main .error-esesja-link:hover {
                opacity: 1.0
            }

    #bern {
        width: 115px;
        height: 156px;
        margin: auto;
        background: url('../../assets/imgs/bart2.png') repeat-x;
        animation: walk .8s steps(6) infinite;
        position: relative;
        bottom: 34px;
    }

    @keyframes walk {
        0% {
            background-position: 0
        }
        100% {
            background-position: -672px
        }
    }
    @keyframes movebg {
        0% {
            background-position: 550px
        }

        100% {
            background-position: 0
        }
    }
</style>