<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section style="text-align: center">
                <p style="margin-bottom: 10px;">{{name + ' ' + lastName}}</p>
                <img :src="getQrCode()" alt="kod qr" />
            </section>
                <section style="margin-top: 10px; ">
                    <section class="esesja-modal__wraper__body__bottom">
                        <button class="esesja-modal__wraper__body__bottom-button" @click="close()"> Zamknij</button>
                    </section>
                </section>    
        </section>
    </section>
</template>

<script>
    export default {
        props: {
            qrcode: {
                type: String
            },
            name: {
                type: String
            },
            lastName: {
                type: String
            }
        },
        methods: {
            close() {
                this.$emit('closeQrCodeModal');
            },
            getQrCode() {
                return `https://tablet.esesja.pl/qrcode/400/${this.qrcode}`;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .esesja-modal {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 15;

        &__wraper {
            background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
            border-radius: 12px;
            padding: 30px;

            &__body {
                padding: 0px 32px 32px 32px;
                overflow-y: auto;
                max-height: 70vh;

                &__bottom {
                    display: flex;
                    justify-content: center;
                    column-gap: 12px;
                }

                &__bottom-button {
                    padding: 18px 40px 17px;
                    width: 238px;
                    height: 56px;
                    border: 1px solid #bbc2ce;
                    box-sizing: border-box;
                    border-radius: 4px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 132%;
                    color: #00082c;
                    background-color: transparent;
                    background: none;
                }
            }
        }
    }
</style>