import ApiService from "./api.service";

class ProtocolService extends ApiService {
    getProtocolsData(sessionId) {
        return this.get(`Protocol/getProtocolsData/${sessionId}`);
    }

    deleteProtocol(sessionId, id) {
        return this.delete(`Protocol/deleteProtocol/${sessionId}/${id}`)
    }

    updateProtocolName(data, sessionId) {
        return this.post(`Protocol/updateProtocolName/${sessionId}`, data)
    }

    downloadProtocol(sessionId, id) {
        return this.get(`Protocol/downloadProtocol/${sessionId}/${id}`, { responseType: "arraybuffer" })
    }

    uploadProtocol(data) {
        return this.post(`Protocol/uploadProtocol`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }
}

export default new ProtocolService();
