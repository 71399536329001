export const toolsModalStore = {
    state: () => ({
        reportBugModalStatus: false,
        addVersionsHistoryModalStatus: false,
        exportSmsModalStatus: false,
        exportEmailModalStatus: false,
        exportRegistryModalStatus: false,
    }),
    mutations: {
        setReportBugModalStatus(state, status) {
            state.reportBugModalStatus = status;
        },
        setAddVersionsHistoryModalStatus(state, status) {
            state.addVersionsHistoryModalStatus = status;
        },
        setExportSmsModalStatus(state, status){
            state.exportSmsModalStatus = status;
        },
        setExportEmailModalStatus(state, status){
            state.exportEmailModalStatus = status;
        },
        setExportRegistryModalStatus(state, status){
            state.exportRegistryModalStatus = status;
        }
    }
}