<template>
    <section class="user-online">
        <SearchInputTextComponent class="user-online--search" placeholder="Wyszukaj użytkownika online..."
            v-model="searchPerson" :withButton="false" />
        <section class="user-online--items">
            <section class="user-online-records" v-if="filteredPerson && filteredPerson.length > 0">
                <UserOnlineItem  v-for="(item, index) in filteredPerson" :item="item" :key="index" /> 
            </section>
            <section v-else class="user-online--no-records">
                <span>Brak wyników</span>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
import SearchInputTextComponent from '../../../BasicControls/SearchInputTextComponent.vue';
import UserOnlineItem from "./UserOnlineItem.vue";
export default {
    name: "UserOnline",
    components: {
        SearchInputTextComponent,
        UserOnlineItem
    },
    data() {
        return {
            people: [],
            searchPerson: "",
            filteredPerson: [],
        }
    },
    watch: {
        searchPerson: {
            immediate: true,
            handler() {
                if (this.people) {
                    this.filteredPerson = this.peopleOnline.map(item => { return { ...item } });
                    this.search();
                }
            }
        },
        peopleOnline: {
            deep: true,
            handler(val) {
                this.filteredPerson = Array.from([...val]);
                this.search();
            }
        }

    },
    computed: {
        peopleOnline() {
            let online = this.$store.state.userStore.online ?? [];

            return online.map(item => {
                    let newItem = { ...item }
                    newItem.queryString = (`${item.id} ${item.first_name} ${item.last_name} ${item.role}`).toLowerCase()
                    return newItem;
                });
        },
    },
    methods: {
        search() {
            const search = this.searchPerson.trim();
            if (search) {
                this.filteredPerson = Array.from(this.filteredPerson.filter(item => {
                    if (item.queryString.indexOf(search.toLowerCase()) !== -1) {
                        if (item.first_name !== undefined && item.first_name !== null)
                            item.first_name = item.first_name.replace(new RegExp(search, 'gi'), "<span class='person--match'>$&</span>");

                        if (item.last_name !== undefined && item.last_name !== null)
                            item.last_name = item.last_name.replace(new RegExp(search, 'gi'), "<span class='person--match'>$&</span>");

                        if (item.role !== undefined && item.role !== null)
                            item.role = item.role.replace(new RegExp(search, 'gi'), "<span class='person--match'>$&</span>");

                        return true;
                    }
                    return false;
                }));
            }
        }
    }

}
</script>
<style lang="scss" scoped>
.user-online {
    position: absolute;
    background: #fff;
    right: 397px;
    top: 97px;
    border-left: 1px solid #dde0e7;
    z-index: 7;
    border-bottom: 1px solid #dde0e7;
    border-right: 1px solid #dde0e7;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 10px 9px 0px #b1b1b1;
    gap: 10px;
    max-width: 400px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &--search {
        margin: 0 auto;
        padding: 0px 10px;
    }

    &--items {
        max-height: 50vh;
        overflow-x: auto;
        overflow-y: auto;
    }

    &--no-records {
        font-weight: 600;
        padding-bottom: 10px;
        text-align: center;
    }
}
</style>