<template>
    <section class="panel-content" :class="{'interactive-mode':isInteractive}">
        <HeaderComponent v-if="!isInteractive" />
        <InteractiveHeader v-else />
        <Transition>
            <AddMeeting v-if="visibleAddMeetingStatus" />
        </Transition>
        <Transition>
            <EditMeeting v-if="visibleEditMeetingStatus" :sessionId="this.$store.state.appStore.sessionEditMeetingId" />
        </Transition>
        <Transition>
            <AddMettingTemplate v-if="visibleAddMeetingTemplateStatus" />
        </Transition>
        <Transition>
            <SessionMetadataModal v-if="visibleSessionMetadata" />
        </Transition>
        <Transition>
            <SessionAddProtcolModal v-if="visibleAddProtocol" />
        </Transition>
        <Transition>
            <ImportSessionAgenda v-if="visibleImportSessionAgenda" />
        </Transition>
        <Transition>
            <DuplicateSessionModal v-if="visibleDuplicateSession" />
        </Transition>
        <Transition>
            <ChangeOwnPassowrdModal v-if="visibleChangeOwnPassword" />
        </Transition>
        <Transition>
            <ChangeOwnPin v-if="visibleChangeOwnPin" />
        </Transition>
        <Transition>
            <UserPresenceModal v-if="visibleUserPresenceStatus"
                               :presencesData="sessionUserPresenceData"
                               @closePresenceModal="closePresenceModal"
                               @saveUserPresents="saveCurrentUserPresents"
                               @printListToWord="printListToWord" 
                               @getPresenceTimeList="getPresenceTimeList" /> 
        </Transition>
        <router-view style="flex-grow: 1;"></router-view>
        <FooterComponent></FooterComponent>
    </section>
</template>

<style lang="scss" scoped>
    .panel-content {
        min-height: 100vh;
        background-color: #f4f6fa;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    .page-sms .panel-content {
        overflow: hidden;
    }

    .interactive-mode {
        margin-left: 0px !important;
    }
</style>

<script>
    import HeaderComponent from "./HeaderComponent.vue";
    import FooterComponent from "./FooterComponent.vue";
    import InteractiveHeader from "../Interactive/InteractiveHeader.vue"
    import AddMeeting from "./Home/Modals/AddMeetting.vue";
    import EditMeeting from "./Home/Modals/EditMeeting.vue";
    import AddMettingTemplate from "./Home/Modals/AddMeetingTemplate.vue"
    import SessionMetadataModal from "./Home/Modals/SessionMetadataModal.vue";
    import SessionAddProtcolModal from "./Home/Modals/SessionAddProtcolModal.vue";
    import ImportSessionAgenda from "./Home/Modals/ImportSessionAgenda.vue";
    import DuplicateSessionModal from "./Home/Modals/DuplicateSessionModal.vue";
    import ChangeOwnPassowrdModal from "./User/Modals/ChangeOwnPassowrdModal.vue";
    import ChangeOwnPin from "./User/Modals/ChangeOwnPin.vue";
    import UserPresenceModal from "../Interactive/Modals/UserPresenceModal.vue"
    import AlertHelper from '@/helpers/alerts.helper.js';
    import InteractiveService from "../../../services/interactive.service";
    import SessionService from "../../../services/session.service";

    export default {
        name: "ContentComponent",
        computed: {
            visibleAddMeetingStatus() {
                return this.$store.state.appStore.isAddMeetingVisible;
            },
            visibleEditMeetingStatus() {
                return this.$store.state.appStore.isSessionEditMeetingVisible;
            },
            visibleAddMeetingTemplateStatus() {
                return this.$store.state.appStore.isAddMeetingTemplateVisible;
            },
            visibleSessionMetadata() {
                return this.$store.state.appStore.isShowSessionMetadata;
            },
            visibleAddProtocol() {
                return this.$store.state.appStore.isShowAddProtocol;
            },
            visibleImportSessionAgenda() {
                return this.$store.state.appStore.isShowImportAgenda;
            },
            visibleDuplicateSession() {
                return this.$store.state.appStore.isSessionDuplicateVisible;
            },
            visibleChangeOwnPassword() {
                return this.$store.state.appStore.isShowUserChangeOwnPasswordModal;
            },
            visibleChangeOwnPin() {
                return this.$store.state.appStore.isShowUserChangeOwnPinModal;
            },
            isInteractive() {
                return this.$store.state.appStore.isInInteractiveMode;
            },
            visibleUserPresenceStatus() {
                if (this.$store.state.interactiveStore.isShowPresenceList) {
                    this.getUserPresences();
                }
                
                return this.$store.state.interactiveStore.isShowPresenceList;
            }
        },
        data() {
            return {
                sessionUserPresenceData: [],
                sessionTimeUserPresenceData: [],
            }
        },
        components: {
            HeaderComponent,
            FooterComponent,
            AddMeeting,
            EditMeeting,
            InteractiveHeader,
            SessionMetadataModal,
            SessionAddProtcolModal,
            AddMettingTemplate,
            ImportSessionAgenda,
            DuplicateSessionModal,
            ChangeOwnPassowrdModal,
            ChangeOwnPin,
            UserPresenceModal
        },
        created() {
            window.addEventListener("resize", this.myEventHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.myEventHandler);
        },
        methods: {
            myEventHandler(e) {
                if (e.currentTarget.innerWidth < 992) {
                    this.$store.commit("closeHumburger");
                }
                else {
                    this.$store.commit("openHumburger");
                }
            },
            async getUserPresences() {
                try {
                    this.$store.commit("showLoader");
                    let response = await InteractiveService.getUserPresences(this.$store.state.interactiveStore.tempSessionId);

                    if (response.status === 200)
                        this.sessionUserPresenceData = response.data;
                }
                catch {
                    var t = this.$swal.mixin(AlertHelper.errorToastElement());
                    t.fire(AlertHelper.errorToastAlert('Błąd pobrania obecności'));
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            async saveCurrentUserPresents(presentData) {
                try {
                    this.$store.commit("showLoader");
                    let res = await InteractiveService.saveUserPresences(this.$store.state.interactiveStore.tempSessionId, false, presentData);

                    if (res.status === 200)
                        this.sessionUserPresenceData = res.data;

                    this.$store.commit("showSuccessCustomMessage", "Obecność zapisana poprawnie");
                }
                catch (e) {
                    var t = this.$swal.mixin(AlertHelper.errorToastElement());
                    t.fire(AlertHelper.errorToastAlert('Błąd zapisu obecności'));
                }
                finally {
                    this.$store.commit('hidePresenceList');
                    this.$store.commit('hideCheckQourum');
                    this.$store.commit("hideLoader");
                }
            },
            async closePresenceModal() {
                await this.getUserPresences();
            },
            async printListToWord() {
                try {
                    let response = await SessionService.getWordListPresence(this.$store.state.interactiveStore.tempSessionId);
                    if (response.status === 200) {
                        const contentType = response.headers['content-type'];
                        let a = document.createElement('a');
                        var blob = new Blob([response.data], { 'type': contentType });
                        a.href = window.URL.createObjectURL(blob);
                        var filename = response.headers['content-disposition'].split("filename=")[1];

                        filename = filename.substr(0, filename.indexOf(';'));
                        filename = filename.replaceAll('"', '');
                        a.download = filename
                        a.click();
                    }
                } catch (e) {
                    console.log(e)
                }
            },
            async getPresenceTimeList() {
                try {
                    let response = await SessionService.getWordListPresenceTime(this.$store.state.interactiveStore.tempSessionId);
                    if (response.status === 200) {
                        const contentType = response.headers['content-type'];
                        let a = document.createElement('a');
                        var blob = new Blob([response.data], { 'type': contentType });
                        a.href = window.URL.createObjectURL(blob);
                        var filename = response.headers['content-disposition'].split("filename=")[1];

                        filename = filename.substr(0, filename.indexOf(';'));
                        filename = filename.replaceAll('"', '');
                        a.download = filename
                        a.click();
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        }
    };
</script>
