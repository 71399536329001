<template>
    <section class="notification-item">
        <section class="notification-item__icon">
            <img src="@/assets/icons/components/error-alert.svg" />
        </section>
        <section class="notification-item__body">
            <section class="notification-item__body__title">
                Brak adresu E-Mail
            </section>
            <section class="notification-item__body__description">
                <p>W systemie nie został odnaleziony adres E-Mail</p>
                <section class="notification-item--center">
                    <button class="notification-item__button notification-item__button--small notification-item__button--white"
                            @click="setEmail">
                        <span class="notification-item__button__text">Uzupełnij profil</span>
                    </button>
                </section>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "NoEmailSpecified",
        methods: {
            setEmail() {
                if (this.$route.name === 'user-profile') { 
                    this.$store.commit('toggleEditPersonalData', true)
                }
                else {
                    this.$router.push({ name: 'user-profile', query: { editPersonalData: true } });
                }

            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/notification-item.scss';
</style>