<template>
    <section :class="{'audio-recorder-wrapper-page' : isMouseDown}" @mousemove="mouseMove">
        <section class="audio-recorder-wrapper" ref="audioRecorderWrapper" @mousedown="mouseDown" @mouseup="mouseUp">
            <section class="audio-recorder gap-1" :class="{'is-small': isMaximize === false}">
                <section class="audio-recorder__toolbar d-flex flex-row justify-content-between align-content-center align-items-center gap-0">
                    <section class="audio-recorder__toolbar__title">
                        Nagrywanie audio
                    </section>
                    <section class="d-flex flex-row">
                        <ButtonOnlyWithIconVue v-if="isMaximize"
                                               v-tooltip="'Zmininalizuj'"
                                               :icon="'minimize'"
                                               :classModified=false
                                               @click="$store.commit('setMaximizeStatus', false)" />
                        <ButtonOnlyWithIconVue v-else
                                               :icon="'maximize'"
                                               v-tooltip="'Zmaksymalizuj'"
                                               :classModified=false
                                               @click="$store.commit('setMaximizeStatus', true)" />
                        <ButtonOnlyWithIconVue :icon="'exit-btn'"
                                               v-tooltip="'Zamknij'"
                                               :classModified=false
                                               @click="closeRecorder"/>
                    </section>
                </section>
                <section class="audio-recorder__panel">
                    <section class="audio-recorder__panel__recorder">
                        <section class="audio-recorder__panel__recorder__action" v-if="isAudioDeviceSelected === true && sessionId !== -1">
                            <ButtonOnlyWithIconVue :icon="'microphone'" v-tooltip="'Rozpocznij nagrywanie audio'" v-if="isRecording === false" @click="startRecord" :disable="isAudioDeviceSelected === false" />
                            <ButtonOnlyWithIconVue :icon="'stop'" :classModified="true" v-tooltip="'Zakończ nagrywanie audio'" v-else @click="stopRecord" :disable="isAudioDeviceSelected === false" />
                        </section>
                        <section class="audio-recorder__panel__recorder__action" v-else>
                            <ButtonOnlyWithIconVue :icon="'microphone'" v-tooltip="'Nie wybrano urządzenia nagrywającego lub posiedzenia'" :disable=true />
                        </section>
                        <section class="audio-recorder__panel__recorder__data" :class="{'d-none': isMaximize === false}">
                            <span>{{ getRecordTime }}</span>
                            <canvas ref="audioSpectrum"></canvas>
                        </section>
                        <section class="audio-recorder__panel__recorder__action" v-if="isAudioDeviceSelected === true && sessionId !== -1 && isRecording === true">
                            <ButtonOnlyWithIconVue :icon="'exit-btn'" v-tooltip="'Anuluj nagrywanie audio'" :classModified=true @click="cancelRecord" :disable="isAudioDeviceSelected === false" />
                        </section>
                    </section>
                    <section class="audio-recorder__panel__device" :class="{'d-none': isMaximize === false}">
                        <section class="audio-recorder__panel__device__item">
                            <span class="audio-recorder__panel__device__item__label">
                                Urządzenie nagrywające audio
                            </span>
                            <span class="audio-recorder__panel__device__item__name" v-tooltip="deviceName">
                                {{ deviceName }}
                            </span>
                        </section>

                        <ButtonWithIconVue :icon="'gear-complex'" :text="'Zmień'" :classModified=false @click="$store.commit('setSelectAudioInputDeviceModal', true)" :disabled="isRecording" />
                    </section>
                    <section class="audio-recorder__panel" :class="{'d-none': isMaximize === false}">
                        <section class="audio-recorder__panel__device">
                            <section class="audio-recorder__panel__device__item">
                                <span class="audio-recorder__panel__device__item__label">
                                    Posiedzenie
                                </span>
                                <span class="audio-recorder__panel__device__item__name" v-tooltip="deviceName">
                                    {{ getSessionName() }}
                                </span>
                            </section>
                            <ButtonWithIconVue class="force-black" :icon="'posiedzenia'" :text="'Wybierz'" :classModified=false @click="$store.commit('setRecorderSelectSessionModalStatus', true)" :disabled="isRecording" />
                        </section>
                    </section>
                    <Transition>
                        <SmallLoader v-if="isBusy" />
                    </Transition>
                </section>
                <Transition>
                    <SelectAudioInputDeviceVue v-if="$store.state.audioRecorder.selectAudioInputDeviceModalStatus" />
                </Transition>
                <Transition>
                    <SelectSessionModal v-if="$store.state.audioRecorder.selectSessionModalStatus"
                                        @selectedSession="internalSelectedSession"
                                        :currentSessionId="sessionId" />
                </Transition>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    import SelectAudioInputDeviceVue from "./Modals/SelectAudioInputDevice.vue";
    import ButtonWithIconVue from "../../../BasicControls/ButtonWithIcon.vue";
    import ButtonOnlyWithIconVue from "../../../BasicControls/ButtonOnlyWithIcon.vue";
    import SmallLoader from "../../../BasicControls/SmallLoader.vue";
    import SelectSessionModal from "./Modals/SelectSessionModal.vue";

    // services
    import audioRecorderService from "../../../../services/audio.recorder.service"
    import AlertHelper from "../../../../helpers/alerts.helper";



    export default {
        name: "AudioRecorder",
        components: {
            SelectAudioInputDeviceVue,
            ButtonWithIconVue,
            ButtonOnlyWithIconVue,
            SmallLoader,
            SelectSessionModal,
        },
        computed: {
            isBusy() {
                return this.$store.state.audioRecorder.isBusy;
            },
            selectedAudioDevice() {
                return this.$store.state.audioRecorder.selectedDevice;
            },

            deviceName() {
                return this.selectedAudioDevice?.label ?? 'Brak urządzenia'
            },

            isAudioDeviceSelected() {
                return this.selectedAudioDevice !== undefined && this.selectedAudioDevice !== null;
            },

            getRecordTime() {
                let timer = this.$store.state.audioRecorder.recorderTimer;
                if (timer === null) {
                    return '00:00:00';
                }

                return `${this.getLeadZero(timer.hours)}:${this.getLeadZero(timer.minutes)}:${this.getLeadZero(timer.seconds)}`;
            },

            isRecording() {
                return this.$store.state.audioRecorder.isRecording;
            },
            isMaximize() {
                return this.$store.state.audioRecorder.isMaximize;
            },
            sessionIdFromParam() {
                if (this.$route.params.sessionid === undefined || this.$route.params.sessionid === null) {
                    return -1;
                }
                return parseInt(this.$route.params.sessionid);
            },
            allSessiosn() {
                return this.$store.state.appStore.groups
                    .map(item => item.sessions)
                    .flat();
            }
        },
        data() {
            return {
                isMouseDown: false,
                position: {
                    current: {
                        x: -1,
                        y: -1,
                    },

                },
                internalSessionId: -1,
                sessionId: -1,
            }
        },
        watch: {
            sessionIdFromParam: {
                immediate: true,
                handler(val) {
                    if (this.isRecording === false) {
                        if (val !== -1) {
                            this.sessionId = val;
                        }
                        else {
                            if (this.internalSessionId !== -1) {
                                this.sessionId = this.internalSessionId;
                            }
                            else {
                                this.sessionId = -1;
                            }
                        }
                    }
                }
            }
        },

        mounted() {
            this.setPosition();
        },

        methods: {
            async getDevices() {
                await audioRecorderService.getAudioDevices();
            },
            async startRecord() {
                try {
                    if (this.sessionId == -1 && this.internalSessionId === -1) {
                        // call modal with select sessions
                    }
                    await audioRecorderService.record(this.$refs.audioSpectrum, this.sessionId);
                    this.$store.commit('setRecordingStatus', true);
                }
                catch {
                    var toast = this.$swal.mixin(AlertHelper.errorToastElement());
                    toast.fire(AlertHelper.errorToastAlert('Nie udało się rozpocząć nagrania audio'));
                }
            },

            cancelRecord() {
                this.$store.commit("showConfirmationAlert", async () => {
                    try {
                        this.$store.commit('setRecordingCanceledStatus', true);
                        await audioRecorderService.cancel();
                        this.$store.commit('setRecordingStatus', false);
                    }
                    catch {
                        var toast = this.$swal.mixin(AlertHelper.errorToastElement());
                        toast.fire(AlertHelper.errorToastAlert('Nie udało się anulować nagrania audio'));
                    }
                });
            },

            closeRecorder() {
                if (this.isRecording) {
                    this.$store.commit("showConfirmationAlert", async () => {
                        try {
                            this.$store.commit('setRecordingCanceledStatus', true);
                            await audioRecorderService.cancel();
                            this.$store.commit('setRecordingStatus', false);
                            this.$store.commit('setRecorderOpenStatus', false)
                        }
                        catch {
                            var toast = this.$swal.mixin(AlertHelper.errorToastElement());
                            toast.fire(AlertHelper.errorToastAlert('Nie udało się anulować nagrania audio'));
                        }
                    });
                }
                else {
                    this.$store.commit('setRecorderOpenStatus', false)
                }
            },
            stopRecord() {
                audioRecorderService.stop();
                this.$store.commit('setRecordingStatus', false);
            },
            getLeadZero(number) {
                if (number < 10)
                    return `0${number}`;

                return `${number}`;
            },

            mouseMove(event) {
                if (this.isMouseDown === false) {
                    return;
                }

                let newCurrent = { ... this.position.current };

                this.position.current.x = event.clientX;
                this.position.current.y = event.clientY;

                newCurrent.x = newCurrent.x - event.clientX;
                newCurrent.y = newCurrent.y - event.clientY;

                this.$store.state.audioRecorder.currentPosition.x = this.$refs.audioRecorderWrapper.offsetLeft - newCurrent.x;
                this.$store.state.audioRecorder.currentPosition.y = this.$refs.audioRecorderWrapper.offsetTop - newCurrent.y;

                this.setPosition();
            },

            setPosition() {
                this.$refs.audioRecorderWrapper.style.top = `${this.$store.state.audioRecorder.currentPosition.y}px`;
                this.$refs.audioRecorderWrapper.style.left = `${this.$store.state.audioRecorder.currentPosition.x}px`;
            },

            mouseDown(event) {
                this.isMouseDown = true;
                this.position.current.x = event.clientX;
                this.position.current.y = event.clientY;
            },

            mouseUp() {
                this.isMouseDown = false;
            },
            getSessionName() {
                let session = this.allSessiosn.filter(item => item.id === this.sessionId);

                if (session.length !== 1) {
                    return "Brak wybranego posiedzenia";
                }

                return session[0].name ?? 'Brak nazwy';
            },
            internalSelectedSession(item) {
                try {
                    if (this.isRecording === true) {
                        return;
                    }

                    if (item === undefined || item === null) {
                        return;
                    }

                    if (item.id === undefined || item === undefined) {
                        return;
                    }

                    this.internalSessionId = parseInt(item.id);

                    if (this.sessionIdFromParam === -1) {
                        this.sessionId = this.internalSessionId
                    }

                }
                catch (e) {
                    console.log(e);
                }
            }
        }
    }
</script>
<style lang="scss">

    .audio-recorder-wrapper-page {
        margin: 0;
        padding: 0;
        background: rgba(0, 8, 44, 0.4588235294);
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 19;
        overflow: hidden;
    }
    .audio-recorder-wrapper {
        position: fixed;
        z-index: 20;
        top: 30px;
        left: 40px;
        cursor: move;

        .d-none {
            display: none;
        }

        .is-small {
            width: auto !important;

            .audio-recorder__toolbar__title {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 65px;
            }

            .audio-recorder__panel__recorder {
                justify-content: center;
            }
        }

        .force-black {
            .btn-component__icon {
                filter: invert(1)
            }
        }

        .audio-recorder {
            background: #fff;
            position: relative;
            border: 1px solid #dce4ee;
            box-sizing: border-box;
            border-radius: 12px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            width: 20vw;
            padding: 10px 20px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

            &__toolbar {
                .button-only-icon {
                    margin-right: 0;
                    border: none;
                }
            }

            &__panel {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                row-gap: 10px;

                &__device {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    column-gap: 10px;

                    .btn-component {
                        margin-right: 0;
                        padding: 0px 10px;
                        height: 35px;
                    }

                    .btn-component__text {
                        margin-left: 5px;
                    }

                    .btn-component__icon {
                        height: 20px;
                    }

                    &__item {
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;
                        flex-grow: 1;

                        &__label {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 145%;
                            color: #00082c;
                        }

                        &__name {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                    }
                }

                &__recorder {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    column-gap: 10px;
                    position: relative;

                    &__action {
                        display: flex;
                        flex-direction: row;
                        /* width: 60px;
                    overflow: hidden;*/
                    }

                    &__data {
                        overflow: hidden;
                        flex-grow: 1;

                        canvas {
                            height: 50px;
                            width: 100%;
                        }
                    }

                    .button-only-icon {
                        width: 60px;
                        height: 60px;
                        border-radius: 100%;
                        margin-right: 0;
                    }

                    .button-only-icon__icon {
                        height: 35px;
                    }
                }
            }
        }
    }
</style>