import mainStore from "../store/mainStore";
import { useStopwatch } from "vue-timer-hook";
import sessionService from "./session.service";
import toolsService from "./tools.service";
import AlertHelper from '@/helpers/alerts.helper.js';
import Swal from 'sweetalert2/dist/sweetalert2.js';


class AudioRecorderService {
    constructor() {
        this._mediaRecorderOptions = {
            audioBitsPerSecond: 64000
        }
        this._initTimer();
        this._spectrumAnalyzer = null;
    }

    _initTimer() {
        mainStore.state.audioRecorder.recorderTimer = useStopwatch();
        mainStore.state.audioRecorder.recorderTimer.reset()
        mainStore.state.audioRecorder.recorderTimer.pause();
    }

    async getAudioDevices() {
        if (!navigator.mediaDevices?.enumerateDevices) {
            console.log("enumerateDevices() not supported.");
            throw new Error();
        }
        await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
        let audioDevices = await navigator.mediaDevices.enumerateDevices()
        mainStore.commit('setAvailableDevices', audioDevices.filter(item => item.kind === "audioinput"));
    }

    selectAudioDevice(device) {
        if (device === undefined || device == null) {
            console.log("missing medice");
            return;
        }

        mainStore.commit("setDevice", device);
    }

    async _initStream() {
        if (mainStore.state.audioRecorder.selectedDevice === undefined || mainStore.state.audioRecorder.selectedDevice == null) {
            throw new Error("Cannot init stream without select device");
        }
        
        let stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: mainStore.state.audioRecorder.selectedDevice.deviceId } });
        mainStore.commit("setMediaStream", stream);
    }

    async record(htmlElement, sessionId) {
        await this._initStream();

        mainStore.state.audioRecorder.isRecordingCanceled = false;
        mainStore.state.audioRecorder.model.sessionId = sessionId;
        mainStore.state.audioRecorder.recorder = new MediaRecorder(mainStore.state.audioRecorder.mediaStream, this._mediaRecorderOptions)
        
        mainStore.state.audioRecorder.recorder.ondataavailable = e => {
            mainStore.commit("appendAudioData", e.data);
        }

        mainStore.state.audioRecorder.recorder.onstop = async () => {
            try {
                mainStore.commit('setBusyStatus', true);
                this._initTimer();
                if (mainStore.state.audioRecorder.isRecordingCanceled === false) {
                    this._initTimer();
                    let blob = new Blob(mainStore.state.audioRecorder.audioChunks, { type: 'audio/x-mpeg-3' });
                    mainStore.commit("setAudioBlob", blob);
                    let file = new File([blob], `${mainStore.state.audioRecorder.model.sessionId}.mp3`);
                    let formData = new FormData();

                    // download file 
                    let fileName = await sessionService.getSessionNameById(mainStore.state.audioRecorder.model.sessionId);
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(mainStore.state.audioRecorder.audioBlobData);
                    a.download = `${fileName}.mp3`
                    a.click();

                    formData.append("StartTimestamp", mainStore.state.audioRecorder.model.startTimestamp);
                    formData.append("Record", file);
                    let response = await sessionService.saveSessionAudioRecord(mainStore.state.audioRecorder.model.sessionId, formData);
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }
                    var successSwal = Swal.mixin(AlertHelper.informationSuccessElement());
                    successSwal.fire(AlertHelper.successAlert('Zapisz posiedzenia zapisany poprawnie'));
                }
            }
            catch {
                var errorSwal = Swal.mixin(AlertHelper.errorToastElement());
                errorSwal.fire(AlertHelper.successAlert('Wystąpił błąd podczas zapisu posiedzenia. Plik z nagraniem zostanie zapisany na dysku'));
            }
            finally {
                mainStore.commit('setBusyStatus', false);
            }
        }

        mainStore.state.audioRecorder.recorder.onstart = async () => {
            try {
                mainStore.commit('setBusyStatus', true);
                mainStore.state.audioRecorder.recorderTimer.reset()
                mainStore.state.audioRecorder.recorderTimer.start()
                mainStore.state.audioRecorder.model.startTimestamp = (await toolsService.getServerTime()).data;
                var t = Swal.mixin(AlertHelper.informationSuccessElement());
                t.fire(AlertHelper.successAlert('Ropoczęto nagrywanie posiedzenia'));
            }
            finally {
                mainStore.commit('setBusyStatus', false);
            }
           
        }

        mainStore.state.audioRecorder.recorder.onerror = e => {
            console.log("onerror", e);
        }

        const audioCtx = new AudioContext();
        const analyser = audioCtx.createAnalyser();
        audioCtx.createMediaStreamSource(mainStore.state.audioRecorder.mediaStream).connect(analyser);

        const ctx = htmlElement.getContext("2d");
        const data = new Uint8Array(htmlElement.width);
        ctx.strokeStyle = 'rgba(0,77,204,0.5)';

        if (this._spectrumAnalyzer !== null)
            clearInterval(this._spectrumAnalyzer);

        this._spectrumAnalyzer = setInterval(() => {
            ctx.fillStyle = "#fff";
            ctx.fillRect(0, 0, htmlElement.width, htmlElement.height);
            analyser.getByteTimeDomainData(data);
            ctx.lineWidth = 5;
            ctx.beginPath();
            let x = 0;
            for (let d of data) {
                const y = htmlElement.height - (d / 128) * htmlElement.height / 2;
                x ? ctx.lineTo(x++, y) : ctx.moveTo(x++, y);
            }
            ctx.stroke();
        }, 1000 * htmlElement.width / audioCtx.sampleRate);

        mainStore.state.audioRecorder.recorder?.start();
    }

    stop() {
        mainStore.state.audioRecorder.recorder?.stop();
        mainStore.state.audioRecorder.mediaStream
            .getTracks()
            .forEach(track => {
                track.stop()
            })        
    }

    cancel() {
        this.stop();
    }
}

export default new AudioRecorderService();