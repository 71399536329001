<template>
    <section :class="dayClassName">
        <section class="day-grid--number">{{ dayNumber }}</section>
        <section class="calendar-events">
            <section class="calendar-event-item" v-for="(item, index) in dayObj.entries" :key="index">
                <CalendarEventSession v-if="item.type.name === 'CalendarEntrySession'" :event="item" />
                <CalendarEventAnnouncement v-if="item.type.name === 'CalendarEntryAnnouncement'" :event="item" />
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
import CalendarEventSession from "./CalendarEventSession.vue";
import CalendarEventAnnouncement from "./CalendarEventAnnouncement.vue";

export default {
    name: "CalendarDayGrid",
    emits: ['openContextMenu'],
    components:{
        CalendarEventSession,
        CalendarEventAnnouncement,
    },
    props: {
        dayObj: {
            type: Object
        }
    },
    computed:{
        dayNumber(){
            return new Date(this.dayObj.date).getDate();
        },
        dayClassName(){
            let className = "day-grid";
            if(this.dayObj.isOutsideMain === true){
                className += " half-hidden"
            }
            
            if(this.dayObj.isWeekend === true){
                className += " weekend"
            }
            return className;
        },

    },
}
</script>
<style lang="scss" scoped>
.half-hidden {
    opacity: 0.5;
}

.day-grid {
    color: #00082c;
    flex: 0 0 calc((100% / 7) - 2px);
    border: 1px solid #dce4ee;
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &:hover{
        background: #f4f6fa;
        .half-hidden{
            opacity: 1;
        }
    }
    &--number{
        text-align: right;
        padding: 5px 10px;
    }
}

.weekend{
    background: #f4f5f7;
    color: #bfc1ca;
    cursor: initial;

}

.calendar-events{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

</style>