<template>
    <ul :class="classes" :id="id" role="tablist" v-if="mode === horizontal">
        <slot></slot>
    </ul>
    <div class="d-flex align-items-start" v-else>
        <div :class="classes" id="id" role="tablist" aria-orientation="vertical">
            <slot></slot>
        </div>
    </div>
</template>
<script charset="utf-8">
    export default {
        name: "eSesjaTabNav",
        props: {
            id: {
                type: String,
                default: "default-tab"
            },
            mode: {
                type: String,
                default: "horizontal"
            },
            type: {
                type: String,
                default: "default"
            }
        },
        computed: {
            classes() {
                let className = "";

                switch (this.mode) {
                    case "horizontal": className += "nav nav-tabs mb-3"; break;
                    case "vertical": className += "nav flex-column nav-pills me-3"; break;
                }

                switch(this.type){
                    case "pills": className += " nav-pills" ; break;
                }

                return className;
            }
        }
    }
</script>