import ApiService from "./api.service";
import encryptDecryptService from "./encrypt.decrypt.service";

class TransmissionService extends ApiService {
    async auth() {
        const result = await this.get("transmission/auth-credential");

        if (result.status !== 200) {
            throw await Promise.reject(result);
        }

        const form = this._createForm(result.data);

        // Dodajemy formularz do dokumentu i wysyłamy
        document.body.appendChild(form);
        form.submit();

        // Usuwamy formularz po wysłaniu, aby nie zaśmiecać DOM
        document.body.removeChild(form);
        return result;
    }

    _createForm(data){
        const form = document.createElement("form");
        form.method = "POST";
        form.action = "https://admin.esesja.tv/login";
        form.target = "_blank"; // otwarcie w nowej karcie

        const cidHiddenField = document.createElement("input");
        cidHiddenField.type = "hidden";
        cidHiddenField.name = "cid";
        cidHiddenField.value = encryptDecryptService.Decryption(data.id);
        form.appendChild(cidHiddenField);

        const clHiddenField = document.createElement("input");
        clHiddenField.type = "hidden";
        clHiddenField.name = "cl";
        clHiddenField.value = encryptDecryptService.Decryption(data.codeLog);
        form.appendChild(clHiddenField);

        const uidHiddenField = document.createElement("input");
        uidHiddenField.type = "hidden";
        uidHiddenField.name = "uid";
        uidHiddenField.value = data.userId;
        form.appendChild(uidHiddenField);
        
        return form;

    }
}

export default new TransmissionService();