<template>
    <section class="component">
        <section class="component__header">
            <span class="component__header__title">
                Ustawienia e-mail
            </span>
            <section class="component__header__action">
                <button class="component__button
                               component__button--small
                               component__button--white"
                        @click="showEditEmailSettings">
                    <img src="@/assets/icons/components/edit.svg" />
                    <span class="component__button__text">Edytuj</span>
                </button>
            </section>
        </section>
        <section class="component__body personal-info">
            <section class="personal-info__item">
                <section class="personal-info__item__label" style="cursor: pointer;" v-tooltip="'Nazwa nadawcy w wysyłanych wiadomościach e-mail'">Nazwa nadawcy:</section>
                <section class="personal-info__item__value">{{this.$store.state.userStore.settings.userEmailSenderName}}</section>
            </section>
            <section class="personal-info__item">
                <section class="personal-info__item__label" style="cursor: pointer;" v-tooltip="'Zwrotny adres e-mail dla wysyłanych wiadomości'">Zwrotny adres e-mail:</section>
                <section class="personal-info__item__value">{{this.$store.state.userStore.settings.userReplyToEmail}}</section>
            </section>
        </section>
        <Transition>
            <EditEmailSettings v-if="isShowEditEmailSettings" @closeModal="closeEditEmailSettings" />
        </Transition>
    </section>
</template>
<script charset="utf-8">
    import EditEmailSettings from "./Modals/EditEmailSettings.vue";

    export default {
        name: "AccountEmailSettings",
        components: {
            EditEmailSettings
        },
        data() {
            return {
                isLoading: false,
                isShowEditEmailSettings: false,
            }
        },
        mounted() {

        },
        methods: {
            showEditEmailSettings() {
                this.isShowEditEmailSettings = true;
            },
            closeEditEmailSettings() {
                this.isShowEditEmailSettings = false;
            },
        }

    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/user-profile-component.scss";

    .component {
        height: fit-content;
    }

    .personal-info {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding: 15px 20px;

        &__item {
            display: flex;
            flex-direction: row;
            column-gap: 10px;

            &__label {
                font-weight: 800;
            }
        }
    }
</style>