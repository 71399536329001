import router from "../router/main-router";
import { encryptStorage } from '../../src/services/encrypt.service';

export const userStore = {
    state: () => ({
        userData: {},
        accountErrors:[],
        token: "",
        isLogged: false,
        isLoading: false,
        isUserLockApplication: false,
        isAutoblockEnable: false,
        autoblockInterval: 10000,
        modules: [],
        priv: {},
        settings: {
            userReplyToEmail: '',
            userEmailSenderName: ''
        },
        online: [],
    }),
    mutations: {
        setUser(state, payloads) {
            state.accountErrors = payloads.accountErrors;
            state.userData = payloads.user;
            state.isLogged = true;
            state.token = payloads.access_token;
            state.isLoading = false;
            encryptStorage.setItem("token", payloads.access_token);
        },
        reNew(state, payloads) {
            state.token = payloads.access_token;
            encryptStorage.setItem("token", payloads.access_token);
        },
        destroyUser(state) {
            state.userData = {};
            state.token = "";
            state.isLogged = false;
            state.isLoading = false;
            encryptStorage.removeItem("token");
            encryptStorage.removeItem('priv');
            encryptStorage.removeItem('modules');
            router.push({name: "Login"});
        },
        showLoader(state) {
            state.isLoading = true;
        },
        hideLoader(state) {
            state.isLoading = false;
        },
        updateAccountErrors(state, error) {
            state.accountErrors = error;
        },
        updateAvatar(state, avatar) {
            state.userData.avatar = avatar;
        },
        updateUserFirstName(state, firsName) {
            state.userData.first_name = firsName;
        },
        updateUserLastName(state, lastName) {
            state.userData.last_name = lastName;
        },
        updateUserLockApplication(state, status) {
            state.isUserLockApplication = status;
        },
        updateUserLockConfiguration(state, payload) {
            state.isAutoblockEnable = payload.autolockEnable;
            state.autoblockInterval = payload.autolockInterval;
        },
        updateEmailSettings(state, settings) {
            state.settings.userReplyToEmail = settings.userReplyToEmail;
            state.settings.userEmailSenderName = settings.userEmailSenderName;
        },
        setUserAccountSettings(state, settings) {
            state.settings = settings;
        },
        setOnline(state, online){
            state.online = online;
        }
    },
};
