<template>
    <section class="file-upload d-flex flex-column gap-2 px-2 py-2 align-items-center" :class="computedClass">
        <section class="ile-upload__errors d-flex flex-row gap-2 align-items-center" v-if="isUploadFail">
            <span v-for="(error, key) in item.errors" :key="key" v-tooltip="error.rule.description">{{ error.rule.reason }}</span>
        </section>
        <section class="d-flex flex-row gap-2 align-items-center justify-content-between w-100">
            <section class="file-upload__icon">
                <img src="@/assets/icons/components/file-check.svg" style="width: 25px" v-if="isUploadSuccess" />
                <img src="@/assets/icons/components/file-exclamation.svg" style="width: 25px" v-else-if="isUploadFail" />
                <img src="@/assets/icons/components/file.svg" style="width: 25px" v-else />
            </section>
            <section class="file-upload__data d-flex flex-column flex-grow-1 gap-1">
                <section class="d-flex flex-row justify-content-between">
                    <section class="file-upload__data__filename" v-tooltip="item.file.name">
                        {{ item.file.name }}
                    </section>
                    <section class="file-upload__data__percent" v-if="isUploadSuccess === false && isUploadFail === false">
                        {{ item.percent }}%
                    </section>
                </section>
                <section class="d-flex flex-row">
                    <span class="file-upload__data__filesize" v-if="isUploadSuccess && isUploadFail === false">{{ formatBytes(item.total, 2) }}</span>
                    <ProgressBar v-else-if="isUploadFail === false" :current="item.percent" min=0 max=100 label="Postęp pliku" type="danger">{{ item.percent }}%</ProgressBar>
                </section>
            </section>
            <section v-if="isUploadSuccess">
                <img src="@/assets/icons/components/accept-mark-green.svg" alt="Plik załadowany popprawnie" style="width: 12.5px" />
            </section>
            <section v-else-if="isUploadFail" v-tooltip="getItemErros">
                <img src="@/assets/icons/components/error-alert.svg" alt="Plik załadowany popprawnie" style="width: 12.5px" />
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    import UploaderFileState from "../../../../services/uploader.file.state"
    import { formatBytes } from "../../../../helpers/utils";

    export default {
        name: "FileUpload",
        props: {
            item: {
                type: Object
            }
        },
        computed: {
            isUploadSuccess() {
                return this.item.status.name === UploaderFileState.UploadSuccess.name;
            },
            isUploadFail() {
                return this.item.status.name === UploaderFileState.UploadFail.name;
            },
            getItemErros() {
                let rules = this.item.errors.map(item => item.rule.description);
                return rules.join("\n");
            },

            computedClass() {
                if (this.isUploadFail) {
                    return "bg-danger";
                }

                if (this.isUploadSuccess) {
                    return "bg-success"
                }

                return "";
            }
        },
        methods: {
            formatBytes
        }
    }
</script>
<style lang="scss" scoped>
    .file-upload {
        overflow: hidden;
        min-height: 60px;
        border-bottom: 1px solid #bbc2ce;

        &__errors {
            span {
                font-weight: 600;
                font-size: 12px;
                line-height: 145%;
                color: #00082c;
            }
        }

        &:hover {
            background: #e8ebef;
        }

        &__data {
            overflow: hidden;

            &__filename {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-weight: 500;
                font-size: 16px;
                line-height: 145%;
                color: #00082c;
            }

            &__percent {
                text-align: right;
            }

            &__filesize {
                font-weight: 600;
                font-size: 12px;
                line-height: 145%;
                color: #00082c;
            }
        }
    }

    .bg-danger {
        background: #ffd9d9 !important;
    }

    .bg-success {
        background: #caffc1 !important;
    }
</style>