<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">{{ isEdit ? 'Edytuj':'Dodaj'}} interpelację</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeAddInterpellation');}">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <label class="esesja-modal__wraper__body__label">Autor</label>
                <section class="esesja-modal__row">

                    <Multiselect v-model="value"
                                 mode="tags"
                                 placeholder="Wyszukaj użytkownika..."
                                 :close-on-select="false"
                                 :searchable="true"
                                 class="multiselect-green"
                                 noResultsText="brak wyników"
                                 noOptionsText="brak opcji"
                                 :options="options2" />
                </section>

                <section class="esesja-modal__row-double">
                    <section class="esesja-modal__col-double">
                        <label class="edit-meeting__label">Typ</label>
                        <Multiselect v-model="value2"
                                     placeholder="Wyszukaj typ..."
                                     :close-on-select="true"
                                     :searchable="false"
                                     class="multiselect-green"
                                     noResultsText="brak wyników"
                                     noOptionsText="brak opcji"
                                     :options="options"
                                     style="min-width: 250px; padding: 12.5px 16px;" />
                    </section>
                    <section class="esesja-modal__col-double">
                        <label class="edit-meeting__label">Numer</label>
                        <input class="esesja-modal__wraper__body__input" v-model="number" placecholder="temat" />
                    </section>
                </section>

                <label class="esesja-modal__wraper__body__label">Tytuł</label>
                <section class="esesja-modal__row">
                    <input class="esesja-modal__wraper__body__input" v-model="title" placecholder="temat" />
                </section>
                <label class="esesja-modal__wraper__body__label">Treść</label>
                <section class="esesja-modal__row">
                    <textarea class="esesja-modal__wraper__body__wyswig" v-model="body"></textarea>
                </section>


                <section class="esesja-modal__row-double">
                    <section class="esesja-modal__col-double">
                        <label class="esesja-modal__wraper__body__label">Czas</label>
                        <section class="esesja-modal__picker-wrapper">
                            <input type="date"
                                   class="esesja-modal__wraper__body__picker"
                                   v-model="time"
                                   v-error="{errors, input_key: 'time'}" />
                            <ErrorMessage v-if="isError('time', this.errors)" :errorMsgs="passErrorMsg('time',this.errors)" />
                        </section>
                    </section>

                    <section class="esesja-modal__col-double">
                        <label class="esesja-modal__wraper__body__label">Godzina</label>
                        <section class="esesja-modal__picker-wrapper">
                            <input type="time"
                                   class="esesja-modal__wraper__body__picker"
                                   v-model="interpelationTime"
                                   v-error="{ errors, input_key: 'interpelationTime', }" />
                        </section>
                    </section>

                </section>

                <section class="esesja-modal__row">
                    <section class="switch-section">
                        <SwitchButton label="Publiczne" :isPublic="isPublic" @setIsPublic="(value3) => (isPublic = value3)" />
                    </section>
                </section>

                    <section class="esesja-modal__wraper__body__bottom">
                        <button class="esesja-modal__wraper__body__bottom-button" @click="this.$emit('closeAddInterpellation')"> Anuluj</button>
                        <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="addAnnouncement()" :disabled="(value == null || value.length <= 0) || (value2 == null || value2.length <= 0 || body.length == 0 )">Wyślij</button>
                    </section>
            </perfect-scrollbar>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import moment from "moment";
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import Multiselect from '@vueform/multiselect'
import InterpellationService from "../../../../../services/interpellation.service";
import SwitchButton from "../../../../BasicControls/SwitchButton.vue";
import ErrorMessage from "../../../../BasicControls/ErrorMessage.vue";
import {
    passErrorMsg,
    isError,
    setErrors,
} from "../../../../../formValidation/main";

export default {
    name: "AddAnnouncement",
    data() {
        return {
            name: "",
            isLoading: false,
            body: this.elemToUpdate.body,
            value2: [...this.selected],
            value: [...this.selected2],
            isEdit: this.elemToUpdate.isEdit,
            number: this.elemToUpdate.number,
            isGroups: false,
            time: moment(this.elemToUpdate.time).format("YYYY-MM-DD"),
            interpelationTime: moment(this.elemToUpdate.time).format("HH:mm"),
            title: this.elemToUpdate.title,
            id: this.elemToUpdate.id,
            isPublic: this.elemToUpdate.isPublic,
            errors: [],
            files: []
        };
    },
    props: {
        options: Array,
        options2: Array,
        selected: Array,
        selected2: Array,
        datem: String,
        elemToUpdate: Object
    },
    components: {
        LoaderInside,
        Multiselect,
        //CheckBoxButtonSingle,
        ErrorMessage,
        SwitchButton
    },
    methods: {
        validate() {
            var bo = true;
            var err = {};

            if (moment(this.time, 'yyyy-MM-DD', true).isValid() == false) {
                err.time = ['Zły format daty']
                bo = false
            }

            if (moment(this.interpelationTime, 'HH:mm', true).isValid() == false) {
                err.interpelationTime = ['Zły format godziny']
                bo = false
            }
            
            if (!bo) {
                this.isLoading = false;
                this.errors = setErrors(err)
            }
            return bo
        },
        async addAnnouncement() {
            try {
                this.isLoading = true;
                if (this.validate() == false)
                    return

                if (this.isEdit) {

                    let object = {
                        id: -1,
                        isPublic: this.isPublic,
                        time: moment(this.time).format("YYYY-MM-DD"),
                        interpelationTime: this.interpelationTime.toString(),
                        number: this.number,
                        lid: null,
                        type: this.value2[0],
                        body: this.body,
                        title: this.title,
                        author: ''
                    };

                    if (Array.isArray(this.id)) {
                        object.id = this.id[0]
                    }
                    else {
                        object.id = this.id
                    }

                    object.lid = this.value

                    if (Array.isArray(this.value2)) {
                        object.type = this.value2[0]
                    }
                    else {
                        object.type = this.value2;
                    }

                    var resultEdit = (await InterpellationService.updateInterpellation(object));
                    if (resultEdit.status == 200) {
                        this.$emit("updateInterpellations", 2);
                    }
                    else {
                        if (resultEdit.status == 401)
                            this.$router.push({ name: 'Login' })
                    }
                } else {
                    let object = {
                        id: 0,
                        isPublic: this.isPublic,
                        time: moment(this.time).format("YYYY-MM-DD"),
                        interpelationTime: this.interpelationTime.toString(),
                        number: this.number,
                        lid: this.value,
                        type: this.value2,
                        body: this.body,
                        title: this.title,
                        author: ''
                    };

                    var result = (await InterpellationService.addInterpellation(object));
                    if (result.status == 200) {
                        this.$emit("updateInterpellations", 1);
                    }
                    else {
                        if (result.status == 401)
                            this.$router.push({ name: 'Login' })
                    }
                }

                this.value = [];
                this.value2 = [];
                this.title = "";
                this.body = "";
                this.number = "";
                this.time = new Date();
                this.interpelationTime = new Date();
                this.isPublic = false;
                this.$emit("closeAddInterpellation");

            }
            catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
            finally {
                this.isLoading = false;
            }
        },
        passErrorMsg,
        isError,
        setErrors,
    },
    mounted() {
        
    },
};</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>

    @import "../../../../../assets/scss/esesja-modal.scss";
    .label {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;
        margin-bottom: 8px;

        &--checkbox {
            padding-left: 36px;
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            z-index: 9;
            color: #333956;
            cursor: pointer;
        }
    }

    .multiselect-green {
        --ms-tag-bg: #D1FAE5;
        --ms-tag-color: #059669;
        padding: 12.5px 16px;
    }

    .is-active{
        background-color: white !important;
    }

</style>

<style lang="scss">
    .ProseMirror {
        height: 160px;
        max-height: 160px;

        h1 {
            font-size: 32px;
        }

        h2 {
            font-size: 24px;
        }

        p {
            font-size: 16px;
        }
    }
</style>
