<template>
    <section class="component">
        <section class="component__header">
            <span class="component__header__title">
                Ustawienia konta
            </span>
            <section class="component__header__action">
                <button class="component__button
                               component__button--small
                               component__button--white"
                        @click="showEditAccountSettings">
                    <img src="@/assets/icons/components/edit.svg" />
                    <span class="component__button__text">Edytuj</span>
                </button>
            </section>
        </section>
        <section class="component__body personal-info">
            <section class="personal-info__item">
                <section class="personal-info__item__label">Automatyczne blokowanie aplikacji</section>
                <section class="personal-info__item__value">{{getBooleanData(accountSettings.autolockEnable)}}</section>
            </section>
            <section class="personal-info__item">
                <section class="personal-info__item__label">Czas bezczynności</section>
                <section class="personal-info__item__value">{{milisecondsToHuman(accountSettings.autolockInterval)}}</section>
            </section>
        </section>
        <Transition>
            <EditAccountSettings v-if="isShowEditAccountSettings"
                                 v-model="accountSettings"
                                 @closeModal="closeEditAccountSettings" />
        </Transition>
    </section>
</template>
<script charset="utf-8">
    import UserService from "../../../../../services/user.service";
    import EditAccountSettings from "./Modals/EditAccountSettings.vue";

    export default {
        name: "AccountSettings",
        components: {
            EditAccountSettings
        },
        data() {
            return {
                accountSettings: {
                    autolockEnable: false,
                    autolockInterval: 0,
                },
                isLoading: false,
                isShowEditAccountSettings: false,
            }
        },

        watch: {
            isShowEditAccountSettings: {
                deep: true,
                handler(value) {
                    if (value === false) {
                        UserService.updateAutolockSettings(this.accountSettings)
                        //this.$store.commit("updateUserLockConfiguration", this.accountSettings)
                    }
                }
            }
        },

        async mounted() {
            await this.fetchAccountSettings()
        },
        methods: {
            async fetchAccountSettings() {
                try {
                    this.isLoading = true;

                    let response = await UserService.getAccountSettings();
                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }
                    this.accountSettings = response.data;

                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading = false;
                }
            },

            showEditAccountSettings() {
                this.isShowEditAccountSettings = true;
            },
            closeEditAccountSettings() {
                this.isShowEditAccountSettings = false;
            },
            getBooleanData(item) {
                if (item === true)
                    return "Tak";
                return "Nie"
            },
            milisecondsToHuman(item) {

                let res = item;
                let append = "ms"

                if (res > 1000) {
                    res /= 1000;
                    append = "s"
                }

                if (res >= 60) {
                    res /= 60;
                    append = "min"
                }

                return `${res} ${append}`
            }
        }

    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/user-profile-component.scss";

    .component {
        height: fit-content;
    }

    .personal-info {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding: 15px 20px;

        &__item {
            display: flex;
            flex-direction: row;
            column-gap: 10px;

            &__label {
                font-weight: 800;
            }
        }
    }
</style>