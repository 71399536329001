export const placeholderStore = {
    state: () => ({
        placeholders: [],
        isShowAddPlaceholder: false,
        selectedPlaceholder: null,
        currentActivePlaceholder: null,
    }),
    mutations: {
        setAvailablePlaceholders(state, placeholders){
            state.placeholders = placeholders;
        },
        setIsShowAddPlaceholderStatus(state, status){
            if(status === false){
                state.selectedPlaceholder = null;
            }
            state.isShowAddPlaceholder = status;
        },
        setSelectedPlaceholder(state, item){
            state.selectedPlaceholder = item;
        },
        setActivePlaceholder(state, placeholderId) {
            state.currentActivePlaceholder = placeholderId
        }
    }
}