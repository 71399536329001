<template>
    <section class="meetings">
        <section class="meetings-top">
            <section class="meetings-top__left">
                <img src="../../../../../assets/icons/panel/home/box-archive.svg" class="meetings-top__icon" />
                <span class="meetings-top__title">Archiwum posiedzeń</span>
            </section>
            
        </section>
        <section class="meetings__bottom">
            <SmallLoader v-if="isLoading" />
            <section>
                <MeetingsWrapper :list="meetings" />
            </section>
        </section>
    </section>
</template>

<script>
import MeetingsService from "../../../../../services/meetings.service";
import MeetingsWrapper from "./MeetingArchiveWrapper.vue";
import SmallLoader from "../../../../BasicControls/SmallLoader.vue";

export default {
    name: "MeetingsArchive",
    data() {
        return {
            isLoading: false,
            meetings: [],
        };
    },
    components: {
        MeetingsWrapper,
        SmallLoader,
    },
    mounted() {
        this.isLoading = true;
        this.reloadAllData();
    },
    computed: {
        meetingsReload() {
            return this.$store.state.appStore.groups;
        },
    },
    watch: {
        meetingsReload() {
            this.meetings = [];
            this.reloadAllData();
        },
    },
    methods: {
        reloadAllData() {
            this.isLoading = true;
            this.meetings = [];
            MeetingsService.getArchiveSessions().then((response) => {
                this.isLoading = false;

                if (response.data != null && response.data.length > 0)
                    this.meetings = response.data;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/breakpoints.scss";

    .meetings {
        max-height: 56vh;
        background-color: white;
        background: #ffffff;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        border-radius: 12px;
        box-sizing: border-box;
        padding-bottom: 18px;

        &__bottom {
            max-height: 40vh;
            overflow-y: auto;
            position: relative;

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }
        }
    }

    .meetings-top {
        border-bottom: 1px solid #dce4ee;
        padding: 20px 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__icon {
            margin-right: 12px;
            width: 20px;
        }

        &__title {
            font-weight: 600;
            font-size: 18px;
            line-height: 132%;
            color: #00082c;
        }

        &__right {
            display: flex;
        }
    }
</style>
