import ApiService from "./api.service";
import { encryptStorage } from "./encrypt.service"; 
import router from "../router/main-router";

class CustomCss extends ApiService {
    isLoggedIn() {
        const token = encryptStorage.getItem("token");
        return (token !== undefined && token !== null && token !== "");
    }

    async injectCss() {

        if (this.isLoggedIn()) {
            let response = await this.getNewestStyles();

            if (response.status !== 200)
                return;

            this.createInlineStyles(response.data);
        }
        else {
            router.isReady()
                .then(async () => {
                    let route = router.currentRoute._value
                    let response = null;
                    switch (route.name) {
                        case "PublicSession": response = await this.getNewestStylesByToken(route.name, route.params.code); break;
                    }

                    if (response !== null && response.data !== undefined && response.status !== undefined && response.status === 200) {
                        this.createInlineStyles(response.data);
                    }
                });
        }
    }

    createInlineStyles(customStyles){
        if(customStyles === undefined || customStyles === null || customStyles === ""){
            return;
        }

        let styles = document.createElement("style")
        styles.setAttribute("type", "text/css")
        document.head.appendChild(styles);
        styles.textContent = customStyles;
    }

    getNewestStyles(){
        return this.get("api/custom-css/newest/styles")
    }

    getNewestStylesByToken(tokenType, token) {
        return this.get(`api/custom-css/newest/styles/by-token/${tokenType}/${token}`)
    }

    getNewest(){
        return this.get("api/custom-css/newest")
    }

    getById(id){
        return this.get(`api/custom-css/${id}`);
    }

    getAll(){
        return this.get(`api/custom-css`);
    }

    async create(data){
        let reponse = await this.post(`api/custom-css`, data);
        await this.injectCss();
        return reponse
    }
}

export default new CustomCss();