<template>
    <div class="discussionList">
        <div class="discussionList-header">
            <h3>Zgłoszeni do dyskusji <span v-if="discussionList.length > 0">({{ discussionList.length }})</span></h3>
        </div>
        <perfect-scrollbar class="discussionList-items">
            <div v-for="(item, index) in discussionList" :key="index" class="speechrequest-item">
                <div class="speechrequest-item__user__avatar">
                    <img v-if="$store.state.settings.client.interactiveDiscussionShowAvatar" :src="getUserAvata(item.userId)" class="speechrequest-item__user__avatar__img" :alt="item.name + ' ' + item.surname" />
                </div>
                <div class="speechrequest-item__user__data__name">
                    {{item.name}} {{item.surname}} {{getDisscussionType(item.type.id)}}
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</template>
<script charset="utf-8">
    import CommonHelper from '@/helpers/common.helper.js';
    export default {
        name: "DiscussionListModal",
        components: {},
        computed: {

        },
        props: {
            discussionList: Array
        },
        data() {
            return {
                avatarVersion: CommonHelper.getRandomVersionImageForUrlRefresh(0, 100)
            }
        },
        mounted() {

        },
        methods: {
            getDisscussionType(type) {
                switch (type) {
                    case 1:
                    case 2:
                        return "";
                    case 3:
                        return "(AV)";
                    case 4:
                        return "(WF)";
                    default:
                        return "";
                }
            },
            getUserAvata(uid) {
                return `https://api.esesja.pl/users/${uid}.jpg${this.avatarVersion}`;
            },
        }
    }
</script>
<style lang="scss" scoped>
    .discussionList {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        background: #ffffff;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
        border-radius: 12px;
        margin-bottom: 12px;
        transition: all 0.2s ease-in-out;
        //padding: 20px;
        text-align: center;
        padding: 0px !important;
        top: 140px;
        position: sticky;
        max-height: calc(100vh - 240px);

        .discussionList-header {
            border-bottom: 1px solid #dce4ee !important;
        }
    }

    .user-speak {
        .discussionList {
            max-height: calc(100vh - 270px);
        }
    }

    h3 {
        margin-bottom: 15px;
        margin: 0px !important;
        padding: 15px 15px;
    }

    img.sticky-bottom__container__avatar {
        margin-right: 20px;
    }

    .speechrequest-item {
        display: flex;
        flex-direction: row;
        border-width: 5px;
        border-style: solid;
        border-color: transparent;
        border-bottom: 1px solid #dce4ee !important;
        padding: 8px;
        padding-bottom: 3px;
        align-items: center;

        :last-child {
            border-bottom: none !important;
        }

        &__user {
            &__avatar {
                &__img {
                    width: 60px;
                    height: 60px;
                    vertical-align: middle;
                    margin: 0px !important;
                    border-radius: 30px !important;
                    margin-right: 10px !important;
                }
            }

            &__data {
                width: 100%;
                display: flex;
                flex-direction: column;
                row-gap: 5px;
                justify-content: center;


                &__name {
                    font-weight: 600;
                    font-size: 1.3em;
                    line-height: 132%;
                    color: #00082c;
                    padding: 5px 0px;
                    white-space: pre-wrap;
                    text-align: left;
                }
            }
        }
    }
</style>