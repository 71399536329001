<template>
    <eSesjaModal>
        <eSesjaModalHeader @close="$store.commit('setIsShowAddSessionGuest', false)">
            Goście na posiedzeniu
        </eSesjaModalHeader>
        <eSesjaModalBody>
            <label class="esesja-modal__wraper__body__label">Lista gości</label>

            <section class="esesja-modal__row">
                <textarea row="5" class="esesja-modal__wraper__body__input"
                    :class="{ 'input-has-error': model.guests.length > maxChars }" v-model="model.guests">
                </textarea>
                <CharactersCounter :text="model.guests" maxLenght=1000 />
            </section>
        </eSesjaModalBody>
        <eSesjaModalFooter>
            <eSesjaModalButtonCancel @close="$store.commit('setIsShowAddSessionGuest', false)">
                Anuluj
            </eSesjaModalButtonCancel>
            <eSesjaModalButtonSave :disabled="hasError" @click="saveData">
                Zapisz
            </eSesjaModalButtonSave>
        </eSesjaModalFooter>
    </eSesjaModal>
</template>
<script charset="utf-8">
import CharactersCounter from '../../../BasicControls/CharactersCounter.vue';

import sessionService from '../../../../services/session.service';

export default {
    name: "AddSessionGuestModal",
    components: {
        CharactersCounter
    },
    computed:{
        hasError(){
            return this.model.guests.length > this.maxChars
        },
        sessionId(){
            return this.$store.state.interactiveStore.tempSessionId
        }
    },
    data() {
        return {
            maxChars: 1000,
            model: {
                id: null,
                guests: "",
            }
        }
    },
    async mounted(){
        await this.fetchData();
    },
    methods:{
        async fetchData(){
            try{
                this.$store.commit("showLoader");
                const response = await sessionService.getSessionGuest(this.sessionId);
                if(response.status !== 200){
                    throw await Promise.reject(response);
                }
                this.model = response.data;
            }
            catch(e){
                console.log(e);
            }
            finally{
                this.$store.commit("hideLoader");
            }
        },
        async saveData(){
            try{
                this.$store.commit("showLoader");
                let response = null;
                if(this.model.id == null){
                    // add
                    response = await sessionService.addSessionGuest(this.sessionId, this.model);
                }
                else{
                    response = await sessionService.updateSessionGuest(this.sessionId, this.model.id, this.model);
                }

                if(response.status !== 200){
                    throw await Promise.reject(response);
                }
                
                this.$store.commit('setIsShowAddSessionGuest', false);
            }
            catch(e){
                console.log(e);
            }
            finally{
                this.$store.commit("hideLoader");
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.esesja-modal__wraper__body__input{
    height: 50vh;
}
.input-has-error {
    border-color: red;
}
</style>