import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { eSesjaEventEmitter } from "../helpers/esesja.event.emitter";

class eSesjaInteractivePublicService {
    constructor() {
        this._hubConfig = {
            address: "/public-esesja-live",
            logLevel: LogLevel.Error
        }

        this._connectionState = {
            hub: false
        }

        this._hubConnection = {};
        this.events = new eSesjaEventEmitter();
        this._parametrizeHubConnection();
    }
    //settery i gettery 
    get hubConfig() {
        return this._hubConfig;
    }
    get connectionComputedState() {
        return this._connectionState.hub;
    }
    get connectionState() {
        return this._connectionState;
    }

    //create connection
    //hub
    async _parametrizeHubConnection() {
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(this._hubConfig.address)
            .withAutomaticReconnect()
            .configureLogging(this._hubConfig.logLevel)
            .build();

        this._hubConnection.on("ActiveAgendaPointChange", async (obj, status) => {
            var data = {
                point: obj,
                status: status
            };
            this.events.emit("ActiveAgendaPointChange", data);
        });

        this._hubConnection.on("SetDonePoint", async (obj) => {
            this.events.emit("SetDonePoint", obj);
        });

        this._hubConnection.on("SessionBreakStatusChange", async (obj) => {
            this.events.emit("SessionBreakStatusChange", obj);
        });
        this._hubConnection.on("ShowHideVotingResultsLive", async (obj) => {
            this.events.emit("ShowHideVotingResultsLive", obj);
        });
        this._hubConnection.on("AddTimeToSpeakerForPublic", async (obj) => {
            this.events.emit("AddTimeToSpeakerForPublic", obj);
        });
        this._hubConnection.on("CloseVotingResultModal", async (obj) => {
            this.events.emit("CloseVotingResultModal", obj);
        });
        this._hubConnection.on("CloseResultCheckQuorumModal", async (obj) => {
            this.events.emit("CloseResultCheckQuorumModal", obj);
        });
        this._hubConnection.on("CurrentAgendaItems", async (obj) => {
            this.events.emit("CurrentAgendaItems", obj);
        });
        this._hubConnection.on("UserStartSpeech", async (obj) => {
            this.events.emit("UserStartSpeech", obj);
        });
        this._hubConnection.on("UserEndSpeech", async (obj) => {
            this.events.emit("UserEndSpeech", obj);
        });
        this._hubConnection.on("QuorumStarted", async (obj) => {
            this.events.emit("QuorumStarted", obj);
        });
        this._hubConnection.on("QuorumEnded", async (obj) => {
            this.events.emit("QuorumEnded", obj);
        });
        this._hubConnection.on("QuorumResultsChanged", async (sessionId, data) => {
            this.events.emit("QuorumResultsChanged", data);
        });
        this._hubConnection.on("QuorumSummaryReults", async (councilid, obj) => {
            this.events.emit("QuorumSummaryReults", obj);
        });
        this._hubConnection.on("VotingStarted", async (obj) => {
            this.events.emit("VotingStarted", obj);
        });
        this._hubConnection.on("OpenInteractiveVotingPublic", async (obj) => {
            this.events.emit("OpenInteractiveVotingPublic", obj);
        });
        this._hubConnection.on("VotingEnded", async (obj) => {
            this.events.emit("VotingEnded", obj);
        });
        this._hubConnection.on("VoteResultChanged", async (obj) => {
            this.events.emit("VoteResultChanged", obj);
        });
        this._hubConnection.on("VoteResultSummary", async (obj, obj2) => {

            var data = {
                u: obj,
                s: obj2
            };

            this.events.emit("VoteResultSummary", data);
        });
        this._hubConnection.on("VoiceRequestListChanged", async (obj) => {
            this.events.emit("VoiceRequestListChanged", obj);
        });

        this._hubConnection.on("OpenAttachment", (sessionId, ssoToken) => {
            var data = {
                sessionId: sessionId,
                ssoToken: ssoToken
            }
            this.events.emit("OpenAttachment", data);
        })

        this._hubConnection.on("ChangeAttachemtnPage", (sessionId, page, scale) => {
            var data = {
                sessionId: sessionId,
                page: page,
                scale: scale
            }
            this.events.emit("ChangeAttachemtnPage", data);
        })

        this._hubConnection.on("ScroolAttachmentPage", (sessionId, percent) => {
            var data = {
                sessionId: sessionId,
                percent: percent
            }
            this.events.emit("ScroolAttachmentPage", data);
        })


        this._hubConnection.on("CloseAttachemnt", sessionId => {
            this.events.emit("CloseAttachemnt", sessionId);
        })

        this._hubConnection.on("ChangePlaceholderStatus", (placeholder, isActive) => {
            var data = {
                placeholder: placeholder,
                isActive: isActive
            }
            this.events.emit("ChangePlaceholderStatus", data);
        })

        this._hubConnection.on("CurrentClientSettings", settings => {
            this.events.emit("CurrentClientSettings", settings);
        });

        this._hubConnection.on("StartDummySpeech", obj => {
            this.events.emit("StartDummySpeech", obj);
        });


        this._hubConnection.on("EndDummySpeech", () => {
            this.events.emit("EndDummySpeech", null);
        });
        this._hubConnection.onreconnected(async (connectionId) => {
            this.events.emit("SignalRReconnected", connectionId);
        });
    }

    async _hubConnect() {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!")

        if (this._connectionState.hub == false) {
            await this._hubConnection.start();
            this._connectionState.hub = true;
        }
    }

    async joinRoom(obj) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!")

        return await this._hubConnection.invoke("Join", obj);
    }

    async start() {
        await this._hubConnect(); 
        return this.connectionComputedState;
    }

    async stop() {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!")

        await this._hubConnection.stop();

        this._hubConnection.off("ActiveAgendaPointChange");
        this._hubConnection.off("SetDonePoint");
        this._hubConnection.off("SessionBreakStatusChange");
        this._hubConnection.off("ShowHideVotingResultsLive");
        this._hubConnection.off("AddTimeToSpeakerForPublic");
        this._hubConnection.off("CloseVotingResultModal");
        this._hubConnection.off("CloseResultCheckQuorumModal");
        this._hubConnection.off("CurrentAgendaItems");
        this._hubConnection.off("UserStartSpeech");
        this._hubConnection.off("UserEndSpeech");
        this._hubConnection.off("QuorumStarted");
        this._hubConnection.off("QuorumEnded");
        this._hubConnection.off("QuorumResultsChanged");
        this._hubConnection.off("QuorumSummaryReults");
        this._hubConnection.off("VotingStarted");
        this._hubConnection.off("OpenInteractiveVotingPublic");
        this._hubConnection.off("VotingEnded");
        this._hubConnection.off("VoteResultChanged");
        this._hubConnection.off("VoteResultSummary");
        this._hubConnection.off("VoiceRequestListChanged");
        this._hubConnection.off("OpenAttachment");
        this._hubConnection.off("ChangeAttachemtnPage");
        this._hubConnection.off("ScroolAttachmentPage");
        this._hubConnection.off("CloseAttachemnt");
        this._hubConnection.off("CurrentClientSettings");
        this._hubConnection.off("StartDummySpeech");
        this._hubConnection.off("EndDummySpeech");

        this._connectionState.hub = false;
        this._connectionState.socket = false;
    }
}

export default new eSesjaInteractivePublicService();