<template>
    <section class="component">
        <section class="component__header">
            <span class="component__header__title">
                Herb/Logo
            </span>
            <section class="component__header__action">
                <button class="component__button
                               component__button--small
                               component__button--white"
                        @click="selectAvatar" v-tooltip="'Załaduj plik (png) z herbem/logo'">
                    <img src="@/assets/icons/components/edit.svg" />
                    <span class="component__button__text">Wybierz</span>
                </button>
            </section>
            <section v-if="logo != null && logo.length > 0" class="component__header__action">
                <button class="component__button
                               component__button--small
                               component__button--white"
                        @click="deleteLogo" v-tooltip="'Usuń herb/logo'">
                    <img src="@/assets/icons/components/delete.svg" />
                    <span class="component__button__text">Usuń</span>
                </button>
            </section>
        </section>
        <section class="avatar-info__item">
            <section class="avatar-info__item__label">Wybrany herb/logo dla okna wizualizacji oraz nagłówek eksportowanych dokumentów:</section>
        </section>
        <section class="avatar-info__item2">Plik png o maksymalnych wymiarach 500x500px</section>
        <section v-if="logo != null && logo.length > 0" class="component__body avatar">
            <section class="avatar__picture">
                <img :src="logo" alt="herb/logo" class="avatar__picture__img" />
            </section>
        </section>
        <section v-else class="component__body avatar">
            <section class="avatar__picture">
                Brak wybranego herbu/logo
            </section>
        </section>

    </section>
</template>
<script charset="utf-8">
    import SettingsService from "@/services/settings.service";
    import AlertHelper from '@/helpers/alerts.helper.js';

    export default {
        name: "LogoSettings",
        components: {
        },
        data() {
            return {
                rawAvatar: '',
                showAvatarCropModal: false,
                logo: null,
            }
        },
        mounted() {
            SettingsService.getLogoClientImage().then((response) => {

                if (response.status !== 200) {
                    this.logo = null;
                }

                this.logo = response.data.image;

            }).catch(() => {
                this.logo = null;
            });
        },
        methods: {
            async deleteLogo() {
                try {
                    this.$store.commit("showLoader");

                    let response = await SettingsService.deleteLogoClientImage();
                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }

                    this.logo = '';
                    this.$store.commit('setLogoBase64', '');
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'logosettings' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.showAvatarCropModal = false;
                    this.$store.commit("hideLoader");
                }
            },
            async updateAvatar() {
                try {
                    this.$store.commit("showLoader");
                    
                    let request = {
                        image: this.rawAvatar,
                        userId: 0
                    }

                    let response = await SettingsService.setLogoClientImage(request);
                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }

                    this.logo = this.rawAvatar;
                    this.$store.commit('setLogoBase64', this.rawAvatar);
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'logosettings' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.showAvatarCropModal = false;
                    this.$store.commit("hideLoader");
                }
            },
            selectAvatar() {
                let input = document.createElement('input');
                input.type = 'file';
                input.accept = '.png';
                input.multiple = false;
                input.click();
                input.addEventListener('change', event => {
                    if (event.target.files.length == 0)
                        return;

                    let proposedFile = event.target.files[0];
                    if (proposedFile.size > 10000000) {
                        this.$swal(AlertHelper.errorAlert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 10MB"));
                        return;
                    }

                    if (!this.checkMimeType(proposedFile.type)) {
                        this.$swal(AlertHelper.errorAlert("Nieobsługiwany typ pliku. Pliki obsługiwane to: PNG"));
                        return;
                    }

                    let reader = new FileReader();
                    reader.readAsDataURL(proposedFile);
                    reader.onload = e => {
                        this.rawAvatar = e.target.result;
                        if (this.rawAvatar.length > 0) {
                            this.updateAvatar();
                        }
                    }
                });
            },
            checkMimeType(type) {
                if (type != "image/png") {
                    return false;
                } else {
                    return true;
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/user-profile-component.scss";

    .component {
        width: 400px;
        height: fit-content;
    }

    .avatar {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 15px 20px;
        align-items: center;
        justify-content: center;

        &__picture {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__img {
                max-width: 250px;
            }
        }

        &__actions {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .avatar-info {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        &__item {
            display: flex;
            flex-direction: row;
            column-gap: 10px;
            padding: 10px;
            text-align: center;

            &__label {
                font-weight: 800;
            }
        }

        &__item2 {
            display: flex;
            flex-direction: row;
            column-gap: 5px;
            padding: 5px;
            text-align: center;
        }
    }

</style>