<template>
    <button class="cancel-button">
        <span class="cancel-button__text"> Anuluj </span>
    </button>
</template>

<style lang="scss" scoped>
.cancel-button {
    width: 238px;
    height: 56px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #bbc2ce;
    border-radius: 4px;
    background-color: transparent;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #e8ebef;
    }

    &__text {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 132%;
        color: #00082c;
    }
}
</style>

<script>
export default {
    name: "ButtonCancel",
};
</script>
