<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Odnotuj głos użytkownika {{user.firstName}} {{user.lastName}}</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeVotingForUserModal');}">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section>
                    <div class="results">
                        <div class="uprawniony"
                             v-for="s in votinoptions"
                             :value="s.id" :key="s.id"
                             :style="{'background-color': formatAnswersColor(s), 'color': formatAnswersColorText(s.option)}"
                             @click="selectVote(s)">{{s.option}}</div>
                    </div>
                </section>
            </perfect-scrollbar>
        </section>
    </section>
</template>

<script charset="utf-8">

export default {
    name: "VoteForUserModal",
    data() {
        return {

        };
    },
    props: {
        user: Object,
        votinoptions: Array,
        basic: Object
    },
    components: {
  
    },
    methods: {
        formatAnswersColorText(result) {
            if (result == "NIEOBECNI" || result == "NIEOBECNY" || result == 'PRZECIW' || result == 'NIE')
                return 'white';
            else
                return 'black';
        },
        formatAnswersColor(result) {
            if (result != null) {
                switch (result.option) {
                    case 'ZA':
                    case 'TAK':
                        result.color = '#79e563';
                        break;
                    case 'PRZECIW':
                    case 'NIE':
                        result.color = '#e40030';
                        break;
                    case 'WSTRZYMUJĘ SIĘ':
                    case 'NIE WIEM':
                        result.color = '#ffce56';
                        break;
                    case 'BRAK GŁOSU':
                        result.color = '#c0c0c0';
                        break;
                    case 'NIEOBECNI':
                    case 'NIEOBECNY':
                        result.color = '#000';
                        break;
                    default:
                        result.color = '#c0c0c0';
                        break;
                }
                return result.color;
            }
        },
        selectVote(s) {
            var data = {
                votingId: this.basic.votingId,
                pointId: this.basic.pointId,
                sessionId: this.basic.sessionId,
                isPublic: this.basic.isPublic,
                type: this.basic.gType,
                userId: this.user.userId,
                optionId: s.id
            };

            this.$emit("sendVoteForUserAdmin", data);
            this.$emit('closeVotingForUserModal');
        }
    },
    mounted() {
    },
};</script>
<style lang="scss" scoped>
    @import '../../../../../assets/scss/esesja-modal.scss';

    .uprawnieni {
        display: block;
    }

    .results {
        display: block;
        text-align: center;
    }

    .uprawniony {
        display: inline-block;
        padding: 8px;
        font-size: 1.4em;
        font-weight: 300;
        margin-right: 10px;
        margin-bottom: 10px;
        background-color: #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        cursor: pointer;
        border-radius: 10px;
    }

    .uprawniony:hover {
        background: #e8ebef !important;
        color: #000 !important;
    }


    .label {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;
        margin-bottom: 8px;

        &--checkbox {
            padding-left: 36px;
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            z-index: 9;
            color: #333956;
            cursor: pointer;
        }
    }

    .multiselect-green {
        --ms-tag-bg: #D1FAE5;
        --ms-tag-color: #059669;
        margin-top: 10px;
    }

    .checkbox {
        font-weight: normal;
        font-size: 16px;
        line-height: 144%;
        color: #333956;
        margin-left: 12px;
        margin-bottom: 0px;
        padding-left: 24px;
        z-index: 0;
        cursor: pointer;
    }

    .checkbox-wrapper {
        display: flex;
        padding: 5px;
        float: left;
    }

    .multiSelectrow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0px;

        &__elem {
            width: 240px;
        }
    }

    .selectedSection {
        background-color: #dce4ee;
        padding: 5px;
        border-radius: 0px 5px 5px 5px;
    }

    .tabs {
        margin-left: 0px;
        cursor: pointer;
        padding: 5px;
        background-color: #D3D3D3;
        border-radius: 5px 5px 0px 0px;
    }

    .selected {
        background-color: #dce4ee;
        margin: 0px;
        cursor: pointer;
        padding: 5px;
        border-radius: 5px 5px 0px 0px;
    }
</style>
