<template>
    <section class="interactive-view">
        <router-view></router-view>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "InteractiveView",
        components: {
        }
    }
</script>