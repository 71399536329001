<template>
    <section class="component">
        <section class="component__header">
            <span class="component__header__title">
                Typ wykresu
            </span>
            <section class="component__header__action">
                <button class="component__button component__button--small component__button--white" @click="changeChartType">
                    <img src="@/assets/icons/components/edit.svg" />
                    <span class="component__button__text">Zmień</span>
                </button>
            </section>
        </section>
        <section class="chart-info__item">
            <section class="chart-info__item__label">Wybrany typ wykresu dla prezentacji wyników głosowań:</section>
        </section>
        <section class="chart-info__item2">{{getChartType()}}</section>
        <section class="component__body chart" v-if="$store.state.settings.client.chartType < 4">
            <Chart :chartData="chartData" :height="250" :plugins="plugins" :options="options" :type="$store.state.settings.client.chartType" />
        </section>

        <Transition>
            <ChangeChartTypeModal v-if="$store.state.modals.settings.changeChartTypeModalStatus" :type="$store.state.settings.client.chartType" @savechangeChartType="savechangeChartType" />
        </Transition>

    </section>
</template>
<script charset="utf-8">
    import Chart from '../../../../BasicControls/Charts/Chart.vue';
    import ChangeChartTypeModal from "./Modals/ChangeChartTypeModal.vue";
    export default {
        name: "ChartSettings",
        components: {
            ChangeChartTypeModal,
            Chart,
        },
        data() {
            return {
                chartData: {
                    labels: ['ZA', 'PRZECIW', 'WSTRZYMUJĘ SIĘ', 'NIEOBECNI'],
                    datasets: [
                        {
                            backgroundColor: ['#79e563', '#e40030', '#ffce56', '#000'],
                            data: [60, 20, 20, 10]
                        }
                    ]
                },
                plugins: [{
                    options: {
                        legend: {
                            display: false
                        }
                    },
                }],
                options: {
                    legend: {
                        display: false
                    }
                },
            }
        },
        computed: {
           
        },
        methods: {
            changeChartType() {
                this.$store.commit('setChangeChartTypeModalStatus', true);
            },
            savechangeChartType(elem) {
                this.$store.commit('setVotingsChartType', elem);
                //this.chartData.destroy();
                this.chartData = {
                    labels: ['ZA', 'PRZECIW', 'WSTRZYMUJĘ SIĘ', 'NIEOBECNI'],
                    datasets: [
                        {
                            backgroundColor: ['#79e563', '#e40030', '#ffce56', '#000'],
                            data: [60, 20, 20, 10]
                        }
                    ]
                };
            },
            closeAvatarModal() {
                this.rawAvatar = '';
                this.showAvatarCropModal = false;
            },
            getChartType() {
                var type = this.$store.state.settings.client.chartType;

                switch (type) {
                    case 0:
                        return "Słupkowy";
                    case 1:
                        return "Kołowy";
                    case 2:
                        return "Pierścieniowy";
                    case 3:
                        return "Słupkowy poziomy";
                    case 4:
                        return "Bez wykresu";
                    default:
                        return "Bez wykresu";
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/user-profile-component.scss";

    .component {
        width: 450px;
        //height: fit-content;
    }

    .chart {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 15px 20px;
        align-items: center;
        justify-content: center;
    }

    .chart-info {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        &__item {
            display: flex;
            flex-direction: row;
            column-gap: 10px;
            padding: 10px;
            text-align: center;

            &__label {
                font-weight: 800;
            }
        }

        &__item2 {
            flex-direction: row;
            column-gap: 10px;
            padding: 5px;
            text-align: center;
        }
    }

</style>