<template>
    <section class="attachment-interactive">
        <router-view></router-view>
    </section>
</template>
<script charset="utf-8">
    export default{
        name: "AttachmentInteractive"
    }
</script>
