<template>
    <section class="esesja-search-component-date">
        <input type="date"
               v-model="searchDate"
               placeholder="Data do"
               class="esesja-search-component-date__input" />
        <label class="esesja-search-component-date__button" @click="() => { this.searchDate = null}">
            <img src="~@/assets/icons/components/exit-btn.svg"
                 class="esesja-search-component-date__icon" />
        </label>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "SearchInputDateComponent",
        props: {
            modelValue: {
                type: String,
                default: null,
                required: true
            },
            placeholder: {
                type: String,
                default() {
                    return ""
                }
            },
            disabled: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        data() {
            return {
                searchDate: this.modelValue,
            }
        },
        watch: {
            searchDate: {
                handler(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            modelValue:{
                hanlder(value){
                    this.searchDate = value;
                }
            }
        },
        mounted() {
            this.searchDate = this.modelValue;
        }
    }
</script>
<style lang="scss" scoped>
    .esesja-search-component-date {
        display: flex;
        align-items: center;

        &__input {
            width: 165px;
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            box-sizing: border-box;
            padding: 12.5px 16px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__button {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left: 0px;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 32px;
                background: #e2e5eb;
                top: 8px;
                left: 0px;
            }
        }

        &__icon {
            padding: 14px;
        }
    }
</style>