<template>
    <section class="dummy-speech-item d-flex flex-column justify-content-center pb-2">
        <div class="dummy-speech-item--header pb-2 d-flex justify-content-center">
            <span class="dummy-speech-item--header--text">Wypowiedź</span>
        </div>
        <div class="dummy-speech-item--count-down d-flex justify-content-center" :class="{'dummy-speech-item--over-time': workObj.isOverTime }">
            <span class="dummy-speech-item--count-down--text">{{ workObj.diffCountDownLabel }}</span></div>
        <div class="dummy-speech-item--count-up pt-0 pb-2 d-flex justify-content-center">{{ workObj.diffCountUpLabel }}</div>
        <div class="dummy-speech-item--action d-flex justify-content-center">
            <button class="dummy-speech-item--button dummy-speech-item--button-red" @click="endDummySpeech">Zakończ
                wypowiedź</button>
        </div>
    </section>
</template>
<script charset="utf-8">
import { debounce } from '../../../helpers/utils';
import speechTimerService from '../../../services/speech-timer.service';
import toolsService from '../../../services/tools.service';
import { timeUnitsBetween, getLeadZero } from '../../../helpers/utils';
export default {
    name: "DummySpeechItem",
    data() {
        return {
            cyclicQueryFlag: false,
            workObj: {
                startTimestamp: null,
                estimatedEndTimestamp: null,
                currentTimestamp: null,
                diffCountDown: null,
                diffCountDownLabel: "",
                diffCountUp: null,
                diffCountUpLabel: "",
                isOverTime: false,
            }
        }
    },
    computed: {
        sessionId() {
            return parseInt(this.$route.params.sessionid);
        }
    },
    watch: {
        cyclicQueryFlag: debounce(async function () {
            await this.calculateTime();
        }, 200),
        '$store.state.speechTimerStore': {
            deep: true,
            immediate: true,
            handler: async function (val) {
                this.workObj.startTimestamp = Date.parse(val.currentSpeechTimestamp);
                this.workObj.estimatedEndTimestamp = Date.parse(val.estimatedSpeechEndTimestamp);
                await this.calculateTime();
            }
        }
    },

    // mounted() {
    //     this.cyclicQueryFlag = !this.cyclicQueryFlag;
    // },
    methods: {
        async endDummySpeech() {
            await speechTimerService.endSpeechTimer(this.sessionId);
        },

        async calculateTime() {
            try {
                const response = await toolsService.getServerTime();
                this.workObj.currentTimestamp = response.status === 200 ? Date.parse(response.data) : Date.now();
                this.workObj.diffCountDown = timeUnitsBetween(this.workObj.currentTimestamp, this.workObj.estimatedEndTimestamp);
                this.workObj.isOverTime = this.isLessThanZero(this.workObj.diffCountDown);
                this.workObj.diffCountDownLabel = this.getDiffLabel(this.workObj.diffCountDown);
                this.workObj.diffCountUp = timeUnitsBetween(this.workObj.startTimestamp, this.workObj.currentTimestamp);
                this.workObj.diffCountUpLabel = this.getDiffLabel(this.workObj.diffCountUp);
            }
            catch (e) {
                console.log(e);
            }
            finally {
                this.cyclicQueryFlag = !this.cyclicQueryFlag;
            }
        },
        getDiffLabel(diffObj) {
            if (diffObj == null) {
                return "";
            }
            const isPlusSign = this.isLessThanZero(diffObj) ? "+" : "";
            return `${isPlusSign}${getLeadZero(this.getHours(diffObj))}:${getLeadZero(Math.abs(diffObj.seconds))}`
        },
        isLessThanZero(diffObj) {
            if (diffObj == null) {
                return false;
            }

            return diffObj.days < 0 || diffObj.hours < 0 || diffObj.minutes < 0 || diffObj.seconds < 0;
        },

        getHours(diffObj) {
            if (diffObj == null) {
                return false;
            }

            return (Math.abs(diffObj.days) * 24 * 60) + (Math.abs(diffObj.hours) * 60) + (Math.abs(diffObj.minutes));
        }
    }
}
</script>
<style lang="scss">
.dummy-speech-item {
    &--button {
        padding: 5px 10px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 0px;
        transition: all 0.2s ease-in-out;
    }

    &--button-red {
        background: #d80418;
        color: #fff;
    }

    &--count-down{
        font-size: 2rem;
    }
    &--over-time{
        color: #d80418;
    }
    &--header {
        &--text {
            font-weight: 600;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
            padding: 5px 0px;
            white-space: pre-wrap;
        }

    }
}
</style>