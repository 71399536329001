<template>
    <section class="current-month">
        {{ humanCurrentMonth }}
    </section>
</template>
<script charset="utf-8">
export default {
    name: "CalendarCurrentMonth",
    computed:{
        humanCurrentMonth(){
            return `${this.monthDict[this.currentDate.getMonth()]} ${this.currentDate.getFullYear()}`;
        },
        currentDate(){
            return this.$store.state.calendarStore.currentDate;
        }
    },
    data(){
        return {
            monthDict: [
                "Styczeń",
                "Luty",
                "Marzec",
                "Kwiecień",
                "Maj",
                "Czerwiec",
                "Lipiec",
                "Sierpień",
                "Wrzesień",
                "Październik",
                "Listopad",
                "Grudzień"
            ]
        }
    }
}
</script>
<style lang="scss">
.current-month{
    font-style: normal;
    font-weight: 600;
    font-size: 2em;
    line-height: 132%;
    color: #00082c;
}
</style>