<template>
    <section class="additional-menu" ref="templateList">
        <ul class="additional-menu__groups" v-if="templates.length > 0">
            <li v-for="(item, index) in templates"
                :key="item.id"
                class="additional-menu__link-wrapper session-template-item"
                :ref="`mainContainerTemplates${index}`"
                @mouseover="
                ()=>
                setMenuElementPosition(
                this.$refs[`mainContainerTemplates${index}`],
                this.$refs[`subMenuTemplateActions${index}`]
                )
                ">{{item.signature}}
                <img src="@/assets/icons/components/additional-arrow.svg" />
                <div class="additional-menu__sub-wrapper"
                     :ref="`subMenuTemplateActions${index}`">
                    <MenuSessionTemplateActions :sessionTemplateId="item.id" />
                </div>
            </li>
            <SmallLoader v-if="isLoading" />
        </ul>
        <p v-else class="sessions__no-data">Brak zapisanych szablonów</p>
        <section class="additional-menu__add" @click="$store.commit('showAddMeetingTemplate')">
            <SmallAddButtonRed />
            <span class="additional-menu__add-text">Dodaj szablon</span>
        </section>
    </section>
</template>
<script charset="utf-8">
    import setMenuElementPosition from "@/helpers/setMenuElementPosition";
    import SessionService from "@/services/session.service";
    import SmallAddButtonRed from "@/components/BasicControls/SmallAddButtonRed.vue";
    import SmallLoader from "@/components/BasicControls/SmallLoader.vue";
    import MenuSessionTemplateActions from "./MenuSessionTemplateActions.vue";
    import { isOnScreen } from "@/helpers/isOnScreen";
    export default {
        name: "AdditionalMenuMeetingTheme",
        components: {
            SmallAddButtonRed,
            SmallLoader,
            MenuSessionTemplateActions
        },
        data() {
            return {
                isLoading: false,
            }
        },
        computed: {
            templates() {
                return this.$store.state.appStore.sessionTemplates;
            }
        },
        mounted() {
            this.fetchTemplate();
            isOnScreen(this.$refs.templateList);
        },
        methods: {
            async fetchTemplate() {
                try {
                    this.isLoading = true;
                    let response = await SessionService.getSessionTemplate();
                    if (response.status === 200) {
                        this.$store.commit("setSessionTemplates", response.data);
                    }
                }
                catch (e) {
                    console.warn(e);
                }
                finally {
                    this.isLoading = false;
                }
            },
            setMenuElementPosition
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/additional-menu.scss";

    .additional-menu__sub-wrapper {
        position: fixed;
        top: 0px;
        left: 0px;
    }
</style>