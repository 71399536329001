<template>
    <div class="speechrequest-item">
        <div class="speechrequest-item__user__avatar">
            <img v-if="$store.state.settings.client.interactiveDiscussionShowAvatar" :src="getUserAvata(1)" class="speechrequest-item__user__avatar__img" />
        </div>
        <div class="speechrequest-item__user__data">
            <div class="speechrequest-item__user__data__name">
                <span class="custom-placeholder"></span> <span class="custom-placeholder"></span>
            </div>
            <div class="speechrequest-item__user__data__function">
                <span class="custom-placeholder"></span> &nbsp; <span class="custom-placeholder"></span>
            </div>
        </div>
        <SmallLoader v-if="isLoading" :isTiny="true" />
    </div>
</template>
<script charset="utf-8">
    import SmallLoader from '../../BasicControls/SmallLoader.vue';

    export default {
        name: "SpeechRequestItemGhost",
        components: {
            SmallLoader
        },
        data() {
            return {
                speechTimer: null,
                isSpeakOutOfRange: false,
                isLoading: true,
            }
        },
        methods: {
            getDisscussionType(user) {
                if (user.type === undefined || user.type === null) {
                    console.warn("brak typu");
                    return;
                }

                if (user.type.id === undefined || user.type === null) {
                    console.warn("brak typu id");
                    return;
                }

                let localTypeId = parseInt(user.type.id);

                switch (localTypeId) {
                    case 1:
                    case 2:
                        return "";
                    case 3:
                        return "(AV)";
                    case 4:
                        return "(WF)";
                    default:
                        return "";
                }
            },
            getUserAvata(uid) {
                return `https://ssl.esesja.pl/users/${uid}.jpg`;
            },
        }
    }
</script>
<style lang="scss" scoped>

    .custom-placeholder {
        display: inline-block;
        min-height: 1em;
        vertical-align: middle;
        cursor: wait;
        background-color: dimgray;
        opacity: .5;
        width: 40%
    }

    .speechrequest-item {
        position: relative;
        display: flex;
        flex-direction: row;
        border-width:5px;
        border-style: solid;
        border-color: transparent;
        &__user {
            &__avatar {
                &__img {
                    width: 60px;
                    height: 60px;
                    margin: 10px;
                    vertical-align: middle;
                    border-radius: 0%;
                }
            }

            &__data {
                width: 100%;
                display: flex;
                flex-direction: column;
                row-gap:5px;
                justify-content: center;

                &__name {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 132%;
                    color: #00082c;
                    padding: 5px 0px;
                    white-space: pre-wrap;
                }

                &__function {
                    display: flex;
                    visibility: hidden;
                    flex-direction: row;
                    padding-bottom:5px;
                    &__button {
                        padding: 5px 10px;
                        margin-right: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 4px;
                        border: 0px;
                        transition: all 0.2s ease-in-out;

                        .button-image {
                            height: 20px;
                        }

                        &__white {
                            background: #fff;
                            border: 1px solid #bbc2ce;

                            &:hover {
                                background: #e8ebef;
                            }
                        }


                        &__red {
                            background: #d80418;
                            color: #fff;

                            .button-image {
                                filter: brightness(0) invert(1);
                            }

                            &:hover {
                                background: #ad0313;
                            }
                        }
                    }
                }
            }
        }

/*        &:hover {
            background: #ddd;
        }*/

        &:hover .speechrequest-item__user__data__function {
            visibility: visible;
        }
    }
    .participant-speech {
        border-right: 4px solid red;
        border-bottom: 1px solid red;
        border-top: 1px solid red;

        .user-speech-image {
            height: 20px;
            animation: pulse 2s infinite;
        }

        @keyframes pulse {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }
    }
</style>