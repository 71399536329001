export const isOnScreen = (list) => {
    const topPosition = list.getBoundingClientRect().top + list.offsetHeight;
    const leftPosition = list.getBoundingClientRect().left;
    if (topPosition > window.screen.height) {
        list.style.position = "fixed";
        list.style.top = "auto";
        list.style.bottom = "0px";
        list.style.left = `${leftPosition}px`;
    }
    if (leftPosition > window.screen.width) {
        list.style.position = "fixed";
        list.style.top = `${topPosition}px`;
        list.style.bottom = "auto";
        list.style.left = "auto";
        list.style.right = "0px";
    }
};
