<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Duplikacja posiedzenia</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$store.commit('hideDuplicateSession');}">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section class="esesja-modal__row">
                    <section class="esesja-modal__col">
                        <section class="esesja-modal-label">
                            Wybrane posiedzenie:
                        </section>
                    </section>
                    <section class="esesja-modal-value">
                        {{sessionData.name}}&nbsp;
                        <section v-if="sessionData.number">
                            nr {{sessionData.number}}
                        </section>
                    </section>
                </section>
                <section class="esesja-modal__row">
                    <section class="esesja-modal__col-double">
                        <label class="edit-meeting__label">Opcje kopiowanie posiedzenia</label>
                    </section>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-votings" v-model="requestData.options.withVotings" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-votings">Głosowania</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-attachemnts" v-model="requestData.options.withAttachemnts" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-attachemnts">Załączniki</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-subpoints" v-model="requestData.options.withSubpoints" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-subpoints">Z podpunktami</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-applicant" v-model="requestData.options.withApplicant" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-applicant">Z wnioskodawcą</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-reporter" v-model="requestData.options.withReporter" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-reporter">Z referującym</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-opinion" v-model="requestData.options.withOpinion" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-opinion">Z opiniującymi</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-responsible" v-model="requestData.options.withResponsible" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-responsible">Z komisjami odpowiedzialnymi</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-notes" v-model="requestData.options.withNotes" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-notes">Z notatkami</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-links" v-model="requestData.options.withLinks" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-links">Z linkami</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-statements" v-model="requestData.options.withStatements" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-statements">Z wypowiedziami</label>
                    </div>
                </section>
                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="this.$store.commit('hideDuplicateSession')"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="duplicateSession()">Zapisz</button>
                </section>
            </perfect-scrollbar>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
    //components
    import LoaderInside from "../../../../BasicControls/LoaderInside.vue";

    //services
    import SessionService from "../../../../../services/session.service";
    import MeetingsService from "../../../../../services/meetings.service";

    export default {
        name: "DuplicateSesionModal",
        components: {
            LoaderInside
        },
        computed: {
            sourceSessionId() {
                return this.$store.state.appStore.sessionDuplicateId;
            }
        },
        data() {
            return {
                isLoading: false,
                sessionData: {},

                requestData: {
                    options: {
                        withVotings: true,
                        withAttachemnts: true,
                        withSubpoints: true,
                        withNotes: true,
                        withLinks: true,
                        withStatements: false, 
                        withApplicant: true,
                        withReporter: true,
                        withOpinion: true,
                        withResponsible: true,
                    }
                }
            }
        },
        async mounted() {
            await this.fetchSessionData();
        },
        methods: {
            async fetchSessionData() {
                try {
                    this.isLoading = true;

                    let response = await SessionService.getSession(this.sourceSessionId);
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.sessionData = response.data;
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'fetchSessionData' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading = false;
                }
            },

            async duplicateSession() {
                try {
                    this.isLoading = true;

                    let response = await SessionService.duplicateSessions(this.sourceSessionId, this.requestData);

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    let fetchSessionResponse = await MeetingsService.groups(false);

                    if (fetchSessionResponse.status !== 200) {
                        throw await Promise.reject(fetchSessionResponse);
                    }
                    this.$store.commit("setGroups", fetchSessionResponse.data)

                    this.$store.commit('hideDuplicateSession');
                    this.$router.push({ name: 'sessionEdit', params: { sessionid: response.data, groupid: this.sessionData.groups[0].id } });
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'duplicateSession' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading = false;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../../../../../assets/scss/esesja-modal.scss';

    .esesja-modal-label {
        font-weight: 600;
        font-size: 14px;
        line-height: 128%;
        color: #00082c;
    }

    .esesja-modal-value {
        font-size: 24px;
        line-height: 128%;
        color: #00082c;
        display: flex;
        flex-direction: row;
    }
</style>