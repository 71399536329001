<template>
    <section class="contacts">
        <h1 class="contacts__title">Aktualności samorządowe</h1>
        <section class="contact-top">
            <section class="contact-top__search">
                <SearchInputTextComponent v-model="searchText" :placeholder="'Wyszukaj...'" />
            </section>
        </section>
        <section class="news-wrapper">
            <section v-for="item in newsSearch" :key="item.id">
                <Information :data="item" />
            </section>
        </section>
    </section>
</template>

<script>
import Information from "../../Home/News/Information.vue"
import NewsService from "../../../../../services/news.service";
import SearchInputTextComponent from "../../../../BasicControls/SearchInputTextComponent.vue";

export default {
    name: "news",
    data() {
        return {
            news: [],
            searchBar: "",
            searchText: "",
            newsSearch: []
        };
    },
    watch: {
        searchText: {
            handler: function (newValue) {
                if (newValue == null || newValue.length == 0) {
                    this.newsSearch = this.news;
                } else {
                    var temp = this.news.map(a => { return { ...a } });
                    this.newsSearch = temp.filter((item) => {
                        var search = newValue.toLowerCase();
                        var complexText = `${item.title} ${item.date}`.toLowerCase();

                        return complexText.includes(search);
                    });
                }
            }
        }
    },
    mounted() {
        this.$store.commit("showLoader");
        NewsService.getNews(1).then((response) => {
            this.news = response.data;
            this.$store.commit("hideLoader");
            this.newsSearch = this.news.map(a => { return { ...a } });
        });
    },
    components: {
        Information,
        SearchInputTextComponent
    },
};
</script>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/breakpoints.scss";

    .contact-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        &__input {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            width: 326px;
            box-sizing: border-box;
            padding: 12.5px 16px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__button-search {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left: 0px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 32px;
                background: #e2e5eb;
                top: 8px;
                left: 0px;
            }
        }

        &__form {
            display: flex;
            align-items: center;
        }

        &__search-icon {
            padding: 14px;
        }

        &__action-buttons {
            display: flex;
        }
    }


    .contacts {
        margin: 49px 48px;

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 20px;
        }
    }


    .news {
        background: #ffffff;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        border-radius: 12px;
        margin-top: 24px;
        padding: 23px 0px;
        max-width: 600px;

        @media only screen and (max-width:1850px) {
            margin-top: 0px;
        }

        &__top {
            display: flex;
            align-items: center;
            padding: 0px 29px;
            padding-bottom: 20px;
            border-bottom: 1px solid #dce4ee;
        }

        &__icon {
            width: 18px;
            height: 18px;
            margin-right: 13px;
        }

        &__title {
            font-weight: 600;
            font-size: 18px;
            line-height: 132%;
            color: #00082c;
        }
    }

    .news-wrapper {
        //max-height: 460px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 12px;
            height: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background: #b5b8bd;
            border-radius: 30px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #b5b8bd;
        }

        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 30px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }
    }
</style>
