<template>
    <section class="sessions" ref="list">
        <router-link :to="{ name: 'sessionSearchRoute', query: { group: queryGroupId } }" class="sessions__show-all">
            <span class="sessions__show-all__icon">
                <img src="@/assets/icons/navMenu/show-all.svg" class="sessions__show-all__icon__icon" />
            </span>
            <span class="sessions__show-all__text"> 
                Pokaż wszystkie posiedzenia
            </span>
        </router-link>
        <ul class="sessions__list" v-if="sessions.length > 0">
            <li v-for="(session, key) in sessions"
                :key="session.id"
                class="sessions__item"
                :ref="`mainContainerSessions${key}`"
                @mouseover="
                ()=>
                setMenuElementPosition(
                this.$refs[`mainContainerSessions${key}`],
                this.$refs[`subMenuActions${key}`]
                )
                "
                >
                <router-link 
                    v-tooltip="getSessionTooltip(session)"
                    :to="{ name: 'sessionEdit', params: { sessionid: session.id, groupid: group?.id } }" 
                    class="sessions__show-all__text">
                    {{ getSessionName(session) }}
                </router-link>
                <img src="@/assets/icons/components/additional-arrow.svg" />
                <div class="sessions__sub-wrapper"
                        :ref="`subMenuActions${key}`">
                    <MeetingsActions :sessionId="session.id"
                                     :groupID="group?.id"
                                     :sessionLocked="session.is_end" />
                </div>
            </li>
        </ul>
        <p v-else class="sessions__no-data">Brak danych</p>
        <section class="sessions__add"
                 @click="() => {
                    $store.commit('setGroupId', group?.id);
                    $store.commit('showAddMeeting');
                }">
            <SmallAddButtonRed />
            <span class="sessions__add-text">Dodaj posiedzenie</span>
        </section>
    </section>
</template>

<script charset="utf-8">
    import moment from "moment";
    import { isOnScreen } from "@/helpers/isOnScreen";
    import { getSessionName } from "@/helpers/session.helper";
    import SmallAddButtonRed from "@/components/BasicControls/SmallAddButtonRed.vue"; 
    import MeetingsActions from "./MeetingsActions.vue";
    import setMenuElementPosition from "@/helpers/setMenuElementPosition";

    export default {
        name: "MenuSessionList",
        data() {
            return {
                sessionsList: [],
            };
        },
        props: {
            groupID: Number,
            group: Object,
            sessions: Array,
        },
        computed: {
            queryGroupId() {
                return [this.group?.id];
            }
        },
        components: {
            SmallAddButtonRed,
            MeetingsActions,
        },
        mounted() {
            this.sessionsList = this.sessions;
            isOnScreen(this.$refs.list);
        },
        methods: {
            fullDate(meeting_date) {
                return moment(meeting_date).format("DD.MM.YYYY");
            },
            getTime(meeting_date) {
                return moment(meeting_date).format("LT");
            },
            getSessionTooltip(session){
                let sessionName = `${getSessionName(this.group, session, 99999999)}, ${this.fullDate(session.meeting_date)}, ${this.getTime(session.meeting_date)}`;
                return sessionName;
            },
            getSessionName(session){
                let sessionName = `${getSessionName(this.group, session)}, ${this.fullDate(session.meeting_date)}, ${this.getTime(session.meeting_date)}`;
                return sessionName;
            },
            setMenuElementPosition,
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
    .sessions {
        display: none;
        width: 338px;
        background: #ffffff;
        border-radius: 12px;
        box-sizing: border-box;
        box-shadow: 16px 32px 48px rgba(0, 8, 44, 0.12);
        border: 1px solid #dce4ee;
        padding: 10px;
        list-style: none;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        z-index: 8;
        margin-top: -30px;

        &__list {
            list-style: none;
            padding: 0px 10px 0px 0px;
            width: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 45vh;

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
                padding-left: 100px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
                margin-left: 100px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
                margin-left: 100px;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 10px;
            transition: al 0.2s ease-in-out;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;
            color: #00082c;

            &:hover {
                background-color: $additionalMenuHoverBackground;
            }

            &:hover .action-list {
                display: block;
            }
        }

        &__add {
            display: flex;
            padding-top: 10px;
            align-items: center;
            border-top: 1px solid #dce4ee;
            width: 100%;
        }

        &__add-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;
            color: #d80418;
            margin-left: 12px;
        }

        &__show-all {
            display: flex;
            flex-direction: row;
            padding-top: 14px;
            padding-bottom: 14px;
            column-gap: 5px;
            align-items: baseline;
            border-bottom: 1px solid #dce4ee;
            width: 100%;

            &__icon {
                display: flex;
                align-items: center;

                &__icon {
                    height: 15px;
                }
            }

            &__text {
                color: #000000;
            }
        }

        &__no-data {
            padding: 10px 0px;
        }

        &__sub-wrapper {
            position: fixed;
            top: 0px;
            left: 0px;
        }
    }
</style>
