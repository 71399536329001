<template>
    <section class="attachment-viewer-modal esesja-modal" ref="modal" tabindex="0">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__body attachment-viewer"  id="container_scroll">
                <section class="attachment-viewer--body">
                    <section class="attachment-viewer--body--content" style="max-height: 90vh !important;">
                        <VuePDF :pdf="fileBlob" :page="internalPage" :scale="(internalScale/100)" v-if="fileType.isPfd" />
                        <img :src="imageData" v-else-if="fileType.isImage" />
                        <textarea v-else-if="fileType.isText" v-model="textData" style="height:80vh; width: 30vw;"></textarea>
                        <section class="attachment-viewer--footer">
                            <section class="left"></section>
                            <section class="center">
                                <span v-if="fileType.isPfd">
                                    Strona {{page}} z {{maxPages}}
                                </span>
                            </section>
                            <section class="right"></section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    import { VuePDF, usePDF } from '@tato30/vue-pdf'

    export default {
        name: "InteractiveViewModal",
        components: {
            VuePDF
        },
        props: {
            title: {
                type: String,
                default: 'Przeglądarka załączników'
            },
            fileData: {
                type: Object,
                require: true
            },
            page: {
                type: Number,
                require: true,
                default: 1
            },
            scale: {
                type: Number,
                require: true,
                default: 100
            },
            scrool: {
                type: Number,
                require: false,
                default: 0
            }

        },
        watch: {
            page: {
                deep: true,
                handler: function (val) {
                    this.internalPage = val;
                }
            },
            scale: {
                deep: true,
                handler: function (val) {
                    this.internalScale = val;
                }
            },
            scrool: {
                deep: true,
                handler: function (val) {
                    if (val < -20 || val > 120)
                        return;

                    const container = document.getElementById("container_scroll");
                    const totalHeight = container.scrollHeight;
                    const viewportHeight = container.offsetHeight;
                    const scrollableDistance = totalHeight - viewportHeight;
                    const targetScrollPosition = (scrollableDistance * (val / 100));
                    container.scrollTop = targetScrollPosition;
                }
            }
        },
        data() {
            return {
                internalPage: 1,
                maxPages: 1,
                internalScale: 100,
                fileBlob: undefined,
                imageData: undefined,
                textData: undefined,
                fileType: {
                    isPfd: false,
                    isImage: false,
                    isText: false,
                }
            }
        },
        async mounted() {
            await this.prepareFile();
        },
        methods: {
            async prepareFile() {
                try {
                    if (this.fileData.headers['content-type'] === 'application/pdf') {
                        this.fileType.isPfd = true;
                        this.fileType.isImage = false;
                        this.fileType.isText = false;
                    }
                    else if (this.fileData.headers['content-type'].startsWith("image/")) {
                        this.fileType.isPfd = false;
                        this.fileType.isImage = true;
                        this.fileType.isText = false;
                    }
                    else if (this.fileData.headers['content-type'].startsWith("text/plain")) {
                        this.fileType.isPfd = false;
                        this.fileType.isImage = false;
                        this.fileType.isText = true;
                    }

                    if (this.fileType.isPfd) {
                        const { pdf, pages } = await usePDF(this.fileData.data)
                        this.fileBlob = pdf;
                        this.maxPages = pages;
                    }
                    else if (this.fileType.isImage) {
                        let blob = new Blob([this.fileData.data], { type: this.fileData.headers['content-type'] });
                        let urlCreator = window.URL || window.webkitURL;
                        this.imageData = urlCreator.createObjectURL(blob);
                    }
                    else if (this.fileType.isText) {
                        let blob = new Blob([this.fileData.data], { type: this.fileData.headers['content-type'] });
                        this.textData = await blob.text()
                    }
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.$store.commit('hideLoader');
                }
            },
        }
    }
</script>
<style lang="scss" scope>
    @import "@/assets/scss/esesja-modal.scss";


    .attachment-viewer-modal .esesja-modal__wraper {
        max-width: 1200px;
    }

    .attachment-viewer-modal .esesja-modal__wraper__body {
        max-height: 100vh;
        padding: 0px;
    }

    .attachment-viewer {
        display: flex;
        flex-direction: column;

        &--header {
            display: flex;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        &--body {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &--footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &--button {
            height: 32px;
            margin-left: 8px;
            background-color: white;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;

            &--small {
                width: 30px;
            }

            img {
                width: 2.2em
            }

            &:hover {
                background-color: #e8ebef;
            }
        }
    }
</style>