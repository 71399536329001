<template>
    <section class="news">
        <section class="news__top">
            <img
                src="../../../../../assets/icons/panel/home/news.svg"
                class="news__icon"
            />
            <span class="news__title">Aktualności samorządowe</span>
        </section>
        <section class="news-wrapper">
            <section v-for="item in news" :key="item.id">
                <Information :data="item" />
            </section>
        </section>
    </section>
</template>

<script>
import Information from "./Information.vue";
import NewsService from "../../../../../services/news.service";
export default {
    name: "News",
    data() {
        return {
            news: [],
        };
        },
        mounted() {
            NewsService.getNews(0).then((response) => {
            this.news = response.data;
        });
        },
    components: {
        Information,
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/scss/breakpoints.scss";

    .news {
        background: #ffffff;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 23px 0px;
        max-width: 485px;
        max-height: 570px;

        @media only screen and (max-width:1850px) {
            margin-top: 0px;
        }

        &__top {
            display: flex;
            align-items: center;
            padding: 0px 29px;
            padding-bottom: 20px;
            border-bottom: 1px solid #dce4ee;
        }

        &__icon {
            width: 18px;
            height: 18px;
            margin-right: 13px;
        }

        &__title {
            font-weight: 600;
            font-size: 18px;
            line-height: 132%;
            color: #00082c;
        }
    }

    .news-wrapper {
        max-height: 460px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 12px;
            height: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background: #b5b8bd;
            border-radius: 30px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #b5b8bd;
        }

        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 30px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }
    }
</style>
