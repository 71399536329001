import ApiService from "./api.service";
import mainStore from "../store/mainStore";

class PlaceholderService extends ApiService {
    async GetAll() {
        let response = await this.get("placeholder");
        if (response.status !== 200) {
            throw await Promise.reject(response);
        }
        mainStore.commit('setAvailablePlaceholders', response.data)
    }

    async Delete(id){
        let response = await this.delete(`placeholder/${id}`);
        if(response.status !== 204){
            throw await Promise.reject(response);
        }
        await this.GetAll();
    }

    async Create(data){
        let response = await this.post("placeholder", data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        if(response.status !== 200){
            throw await Promise.reject(response);
        }

        await this.GetAll();
    }

    async Update(id, data){
        let response = await this.put(`placeholder/${id}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        
        if(response.status !== 200){
            throw await Promise.reject(response);
        }

        await this.GetAll();
    }
}

export default new PlaceholderService();