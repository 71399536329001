import ApiService from "./api.service";
import store from "../store/mainStore"

class SettingsService extends ApiService {
    setVotingChartType(data) {
        return this.post(`Settings/setVotingChartType`, data);
    }

    setLogoSetting(data) {
        return this.post(`Settings/setLogoSetting`, data);
    }

    setLogoClientImage(data) {
        return this.post(`Settings/setLogoClientImage`, data);
    }

    getLogoClientImage() {
        return this.get(`Settings/getLogoClientImage`);
    }

    getFooterClientImage() {
        return this.get(`Settings/getFooterClientImage`);
    }

    deleteLogoClientImage() {
        return this.delete(`Settings/deleteLogoClientImage`);
    }

    deleteFooterClientImage() {
        return this.delete(`Settings/deleteFooterClientImage`);
    }

    setFooterClientImage(data) {
        return this.post(`Settings/setFooterClientImage`, data);
    }

    setCameraApiAddress(data) {
        return this.post('Settings/setCameraApiAddress', data);
    }

    getClientType() {
        return this.get('Settings/getClientType');
    }

    getIntegrationAuthData() {
        return this.get('Settings/getIntegrationAuthData');
    }

    async updateClientSettings(data)
    {
        let response = await this.post(`Settings/update-client-settings`, data);
        if (response.status !== 200) {
            throw await Promise.reject(response);
        }

        await store.commit("setClientSettings", response.data);

        return response;
    }

    async getVotingAllowedIp() {
        let response = await this.get(`Settings/voting-allowed-ip`);
        if (response.status !== 200) {
            throw await Promise.reject(response);
        }

        await store.commit("setAllowIpAddress", response.data);
        return response;
    }

    async setVotingAllowedIp(ip) {
        let response = await this.post(`Settings/voting-allowed-ip`, {
            allowedIp: ip
        });

        if (response.status !== 200) {
            throw await Promise.reject(response);
        }

        return await this.getVotingAllowedIp();
    }
}

export default new SettingsService();
