import ApiService from "./api.service";

class InteractiveService extends ApiService {

    getAvailableGuests() {
        return this.get("Interactive/discussion/get-available-guests");
    }

    getUserPresences(sessionId) {
        return this.get(`Interactive/presence/${sessionId}`);
    }

    getUserPartialPresences(sessionId) {
        return this.get(`Interactive/presence/${sessionId}/partial`);
    }

    setUserPresence(sessionId, personId) {
        return this.put(`Interactive/presence/${sessionId}/partial/${personId}`)
    }

    deleteUserPresence(sessionId, personId) {
        return this.delete(`Interactive/presence/${sessionId}/partial/${personId}`)
    }

    getPresenceTimeList(sessionId) {
        return this.get(`Interactive/presence-time/${sessionId}`);
    }

    saveUserPresences(sessionId, quorum, data) {
        return this.post(`Interactive/presence/${sessionId}/${quorum}`, data);
    }

    getCurrentRequestList(sessionId) {
        return this.get(`Interactive/discussion/get-current-voice-request/${sessionId}`);
    }

    getActivePoint(sessionId) {
        return this.get(`Interactive/activepoint/${sessionId}`);
    }

    addTimeForUserSpeaker(data) {
        return this.post(`Interactive/addTimeForUserSpeaker`, data)
    }

    startVoting(data) {
        return this.put(`Interactive/startVoting`, data)
    }

    cancelVoting(data) {
        return this.put(`Interactive/cancelVoting`, data)
    }

    saveVoting(data) {
        return this.put(`Interactive/saveVoting`, data)
    }

    getVotingPartialResults(data) {
        return this.put(`Interactive/getVotingPartialResults`, data)
    }

    startCheckQuorum(data) {
        return this.put(`Interactive/startCheckQuorum`, data)
    }

    stopCheckQuorum(data) {
        return this.put(`Interactive/stopCheckQuorum`, data)
    }

    quorumResultsChanged(data) {
        return this.put(`Interactive/quorumResultsChanged`, data)
    }
}

export default new InteractiveService();