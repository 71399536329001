const ErrorDirective = {
    updated(el, binding) {
        const { errors, input_key } = binding.value;
        let error_find = false;
        for (const error of errors) {
            if (error.key === input_key) {
                error_find = true;
            }
        }
        if (error_find) {
            el.style.border = "1px solid #d80418";
        } else {
            el.style.border = "";
        }
    },
};

export default ErrorDirective;
