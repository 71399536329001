<template>
    <div class="confirm-alert-wrapper">
        <div class="confirm-alert">
            <p class="confirm-alert__text" v-html="this.$store.state.appStore.alertData.mainText">

            </p>
            <div class="confirm-alert__buttons">
                <ButtonCancel @click="this.$store.commit('hideConfirmationAlertWithData'); this.$store.state.userStore.isLoading = false;" />

                <section v-for="item in this.$store.state.appStore.alertData.buttons" :key="item.id">
                    <ButtonWithSpecyficText @click="actionFire(item.id)" :text="item.text" :color="item.color" class="button-margin-left" />
                </section>
            </div>
        </div>
    </div>
</template>
<script charset="utf-8">
    import ButtonCancel from "../BasicControls/ButtonCancel.vue";
    import ButtonWithSpecyficText from "../BasicControls/ButtonWithSpecyficText.vue";

    export default {
        name: "ConfirmationAlertWithData",
        components: {
            ButtonCancel,
            ButtonWithSpecyficText,
        },
        methods:{
            async actionFire(id){
                await this.$store.state.appStore.confirmationFunctionHandlerAlertWithData(id)
            }
        }
};
</script>

<style lang="scss" scoped>
    .confirm-alert-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 999999;
    }

    .confirm-alert {
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px 56px;
        border-radius: 12px;

        &__buttons {
            display: flex;
            justify-content: space-between;
        }

        &__text {
            margin-bottom: 26px;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
        }
    }

    .button-margin-left {
        margin-left: 24px;
    }
</style>
