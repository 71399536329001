import { generateUUID } from "../helpers/utils";

export const wizardNewCadenceStore = {
    state: () => ({
        step: null,
        isBusy: false,
        stepsDone: [],

        requiredSteps: [1, 2, 3, 4, 5],

        modalAddMultipleUser: false,
        modalConfirmCreateNewCadence: false,

        // data 
        clientHasTransmission: false,
        availableGroups: [],
        selectedGroups: [],
        selectedContactGroups: [],
        transmissionSettings: true,
        availableAdministrators: [],
        selectedAdministrators: [],
        availableTemplates: [],
        selectedTemplates: [],
        availablelicences: 0,
        availableAdminlicences: 0,
        userCreateModel: {
            id: generateUUID(),
            login: "",
            name: "",
            surname: "",
            phonenumber: "",
            email: "",
            selectedGroups: [{
                value: "-1",
                label: "Radni"
            }],
        },
        usersList: [],
        allSuccess: false,
    }),
    mutations: {
        setModalAddMultipleUserStatus(state, status) {
            state.modalAddMultipleUser = status;
        },
        setWizardNewCadenceCurrentStep(state, step) {
            state.step = step;
        },
        setWizardNewCadenceBusyStatus(state, status) {
            state.isBusy = status;
        },
        setWizardNewCadenceStepsDone(state, stepsDone) {
            let internalSteps = [...state.stepsDone];
            internalSteps.push(stepsDone)
            state.stepsDone = [...new Set(internalSteps)];
        },

        setWizardNewCadenceUsersList(state) {
            let modelCopy = { ...state.userCreateModel }
            state.userCreateModel = {
                id: generateUUID(),
                login: "",
                name: "",
                surname: "",
                phonenumber: "",
                email: "",
                selectedGroups: [{
                    value: "-1",
                    label: "Radni"
                }],
            };
            state.usersList.push(modelCopy);
        },
        setModalConfirmCreateNewCadenceStatus(state, status)
        {
            state.modalConfirmCreateNewCadence = status;
        },

        setAvailableAdminlicences(state, data) {
            state.availableAdminlicences = data;
        }
    }
}