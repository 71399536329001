import { switchToRoman, switchIndexToLetter } from "./index.conversion";

export function sessionNumberConversion(convencion, index) {
    switch (convencion) {
        case 1:
            return `${switchToRoman(index)}`;
        case 2:
            return `${index}`;
        case 3:
            return `${switchIndexToLetter(index)})`;
        case 4:
            return `${index})`;

        default:
            return `${index}.`;
    }
}

export function getSessionName(group, session, maxLetters = 60) {
    let name = ""
    if (session && session.name) {
        name = session.name;
    }
    else if (group && group.session_default_name) {
        name = group.session_default_name
    }
    else {
        name = "Posiedzenie";
    }
    name = shortenSessionName(name, maxLetters);

    if (session && session.number) {
        name += ` nr ${sessionNumberConversion(group?.session_number_format_id, session.number)}`
    }
    return name;
}

export function getPublicSessionName(session, maxLetters = 60) {
    let name = ""
    if (session && session.name) {
        name = session.name;
    }
    else if (session.default_group_name) {
        name = session.default_group_name;
    }
    else {
        name = "Posiedzenie";
    }
    name = shortenSessionName(name, maxLetters);

    if (session && session.number) {
        let numberFormat = session.default_session_number_format ? session.default_session_number_format : session.session_numbering_format
        name += ` nr ${sessionNumberConversion(numberFormat, session.number)}`
    }

    return name;
}

export function shortenSessionName(name, maxLetters = 60) {
    name = name.trim();
    if (name.length > maxLetters) {
        name = `${name.substring(0, maxLetters)}...`
    }
    return name;
}