export const sessionsModalStore = {
    state: () => ({
        sendMeetingRegardingModalStatus: false,
        sendMeetingRegardingModalSessionId: null,
        sendMeetingRegardingModalAvailableUsers: [],

        addCustomMeetingRegardingUser: false,

        prepareMeetingInvitationModalStatus: false,
        prepareMeetingInvitationModalSessionId: null,

        audioVideoLinkModalStatus: false,
        audioVidelLinkSessionId: null,

    }),
    mutations: {
        setSendMeetingRegardingModalStatus(state, status) {
            state.sendMeetingRegardingModalStatus = status;
            
            if (status === false) {
                state.sendMeetingRegardingModalAvailableUsers = [];
                state.sendMeetingRegardingModalSessionId = null;
            }
        },

        setSendMeetingRegardingModalSessionId(state, sessionId) {
            state.sendMeetingRegardingModalSessionId = sessionId;
        },

        setAddCustomMeetingRegardingUser(state, status) {
            state.addCustomMeetingRegardingUser = status
        },

        setPrepareMeetingInvitationModalStatus(state, status) {
            state.prepareMeetingInvitationModalStatus = status;

            if (status === false) {
                state.prepareMeetingInvitationModalSessionId = null;
            }
        },

        setPrepareMeetingInvitationModalSessionId(state, sessionId) {
            state.prepareMeetingInvitationModalSessionId = sessionId;
        },

        setAudioVideoLinkModalStatus(state, status) {
            state.audioVideoLinkModalStatus = status;

            if (status === false) {
                state.audioVidelLinkSessionId = null;
            }
        },

        setAudioVideoLinkSessionId(state, sessionId) {
            console.log("setAudioVideoLinkSessionId", sessionId);
            state.audioVidelLinkSessionId = sessionId;
        }
    }
}