<template>
    <div class="separator"></div>
</template>
<script charset="utf-8">
    export default {
        name: "ButtonSeparator"
    }
</script>
<style lang="scss" scoped>
    .separator {
        position: relative;
        padding: .75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: .25rem;
        color: #1b1e21;
        background-color: #d6d8d9;
        border-color: #c6c8ca;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin: 0 !important;
    }
</style>