<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">{{att.id == 0 ? 'Dodaj' : 'Edytuj'}} Link</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeAddLink');}">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <label class="esesja-modal__wraper__body__label">Nazwa załącznika</label>
                <section class="esesja-modal__row">
                    <input type="text"
                           placeholder="Wpisz nazwę załącznika..."
                           class="esesja-modal__wraper__body__input"
                           v-model="name" />
                </section>

                <label class="esesja-modal__wraper__body__label">Adres URL</label>
                <section class="esesja-modal__row">

                    <input type="text"
                           placeholder="http://"
                           class="esesja-modal__wraper__body__input"
                           v-model="url" />
                </section>

                <section>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="public-attachement" v-model="isPublic" @change="changeVisibleFlags()" />
                        <label class="form-bootstrap-check-label" for="public-attachement">Publiczny</label>
                        <section name="externalTrigger2"
                                 class="tip-btn">
                            <img src="../../../../../assets/icons/components/tooltip.svg"
                                 class="tooltip" />
                            <Hint title="Publiczny załącznik"
                                  text="Aktywny oznacza, że załącznik jest widoczny we wszystkich miejscach w systemie w których został opublikowanych (np. Portal Mieszkańca)." />
                        </section>
                    </div>
                </section>

                <section>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="visible-for-users" v-model="isVisibleForUsers" @change="changePublicFlags()" />
                        <label class="form-bootstrap-check-label" for="visible-for-users">Widoczny dla użytkowników</label>
                        <section name="externalTrigger2"
                                 class="tip-btn">
                            <img src="../../../../../assets/icons/components/tooltip.svg"
                                 class="tooltip" />
                            <Hint style="left: calc(100% - 310px)" title="Widoczny dla użytkowników"
                                  text="Aktywny oznacza, że załącznik jest widoczny dla wszystkich zalogowanych użytkowników." />
                        </section>
                    </div>
                </section>

                <section class="esesja-modal__wraper__body__bottom" style="margin-top: 20px;">
                    <button class="esesja-modal__wraper__body__bottom-button"
                            @click="this.$emit('closeAddLink')">
                        Anuluj
                    </button>
                    <button class="
                        esesja-modal__wraper__body__bottom-button
                        esesja-modal__wraper__body__bottom-button--red
                    "
                            @click="handleSaveClick" :disabled="name.length == 0 || url.length < 8">
                        Zapisz
                    </button>
                </section>
            </perfect-scrollbar>

        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import Hint from "../../../../BasicControls/Hint.vue";
import AttachmentService from "../../../../../services/attachments.service";

export default {
    name: "AddLink",
    data() {
        return {
            name: this.att.name,
            url: this.att.url,
            isLoading: false,
            isPublic: this.att.public == 1 ? true : false,
            isVisibleForUsers: this.att.visible_for_users == 1 ? true : false
        };
    },
    props: {
        pointID: Number,
        att: Object
    },
    components: {
        LoaderInside,
        Hint
    },
    methods: {
        changeVisibleFlags() {
            if (this.isPublic) {
                this.isVisibleForUsers = true;
            }
        },
        changePublicFlags() {
            if (!this.isVisibleForUsers) {
                this.isPublic = false;
            }
        },
        handleSaveClick() {
            this.isLoading = true;
            const data = {
                id: this.att.id,
                name: this.name,
                url: this.url,
                isPublic: this.isPublic,
                isVisibleForUsers: this.isVisibleForUsers
            };

            AttachmentService.addLink(this.pointID, data).then(() => {
                this.name = "";
                this.url = "";
                this.isPublic = false;
                this.isVisibleForUsers = false;
                this.$emit("reload");
                this.$emit("closeAddLink");
                this.isLoading = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>

    @import "../../../../../assets/scss/esesja-modal.scss";

    .switch-section {
        display: flex;
        margin-bottom: 12px;
    }

    .tooltip {
        position: relative;
        left: 5px;
        top: -3px;
    }

    .tip-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        background-color: transparent;
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;

        &:hover .hint {
            opacity: 1;
            display: block;
        }
    }
</style>

