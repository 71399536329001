<template>
    <section class="contacts">
        <h1 class="contacts__title">Raporty obecności</h1>

        <section class="edit-meeting__row-double">
            <section class="edit-meeting__col-double">
                <label class="edit-meeting__label">Data od:</label>
                <section class="edit-meeting__picker-wrapper">
                    <input type="date"
                           class="edit-meeting__picker"
                           v-model="from_date"
                           v-error="{errors,input_key: 'from_date',}" />
                    <ErrorMessage v-if="isError('from_date', this.errors)" :errorMsgs="passErrorMsg('from_date',this.errors)" />
                </section>
            </section>
            <section class="edit-meeting__col-double">
                <label class="edit-meeting__label">Data do:</label>
                <section class="edit-meeting__picker-wrapper">
                    <input type="date"
                           class="edit-meeting__picker"
                           v-model="to_date"
                           v-error="{errors,input_key: 'to_date',}" />
                    <ErrorMessage v-if="isError('to_date', this.errors)" :errorMsgs="passErrorMsg('to_date',this.errors)" />
                </section>
            </section>
            <section class="edit-meeting__col-double">
                <label class="edit-meeting__label">Grupa:</label>
                <section class="meetings-settings__wrapper">
                    <select class="meetings-settings__select" v-model="selected">
                        <option v-for="s in groups" :key="s.id"> {{s.nazwa}}</option>
                    </select>
                </section>
            </section>
            <ButtonWithIcon icon="search" class="generate-button"
                            text="Generuj"
                            :classModified="true"
                            @click="(e) => generatePresenceReport()" />
        </section>
        <section class="table_section" v-if="dataArr.length > 0">
            <ButtonWithIcon icon="download" class="download-button"
                            text="Pobierz"
                            :classModified="true"
                            @click="getPdfReport()" />
            <div ref="pdf">
                <h3>{{client}}</h3>
                <p>Raport obecności za okres od {{from_date}} od {{to_date}}</p>
                <p v-if="tempGroupName != 'Sumaryczny raport'">Grupa: {{tempGroupName}}</p>
                <TableComponent :search="search" :pagination="pagination" :fields="fields" :dataArr="dataArr" />
            </div>
        </section>
    </section>

</template>

<script>
    import pdfMake from 'pdfmake';
    import pdfFonts from 'pdfmake/build/vfs_fonts';
    import htmlToPdfmake from 'html-to-pdfmake';
    import ButtonWithIcon from "../../../BasicControls/ButtonWithIcon.vue";
    import moment from "moment";
    import ToolsService from "../../../../services/tools.service";
    import ContactService from '../../../../services/contact.service';
    import SettingsService from '../../../../services/settings.service';
    //import CommunicationService from "../../../../services/communication.service";
    import TableComponent from '../../../../components/Table/TableComponent.vue'
    import ErrorMessage from "../../../BasicControls/ErrorMessage.vue";
    import {
        passErrorMsg,
        isError,
        setErrors,
    } from "../../../../formValidation/main";

    export default {
        name: "presence",
        data() {
            return {
                chartData: {
                    labels: [],
                    datasets: [{ data: [] }]

                },
                plugins: [],
                tempdata: [],
                //logo: require('@/assets/imgs/esesjalogo.png'),
                complex: [],
                dataArr: [],
                lastUserLoginsArr: [],
                showLastUsersLoginsModal: false,
                tempUserId: 0,
                tempUserName: '',
                pagination: true,
                fields: ['Lp.', 'Imię', 'Nazwisko', 'Obecny', 'Nieobecny', 'Spóźnienia', 'Wcześniejsze wyjścia'],
                search: false,
                from_date: moment(new Date()).format("YYYY-MM-DD"),
                to_date: moment(new Date()).format("YYYY-MM-DD"),
                errors: [],
                groups: [],
                selected: 'Sumaryczny raport',
                client: '',
                tempGroupName: ''
            };
        },
        methods: {
            validate() {
                var bo = true;
                var err = {};

                if (this.from_date.length <= 0) {
                    err.from_date = ['Pole wymagane']
                    bo = false
                }

                if (this.to_date.length <= 0) {
                    err.to_date = ['Pole wymagane']
                    bo = false
                }

                if (moment(this.from_date, 'yyyy-MM-DD', true).isValid() == false) {
                    err.from_date = ['Zły format daty']
                    bo = false
                }

                if (moment(this.to_date, 'yyyy-MM-DD', true).isValid() == false) {
                    err.to_date = ['Zły format daty']
                    bo = false
                }

                if (Date.parse(this.to_date) < Date.parse(this.from_date)) {
                    err.from_date = ['Zły zakres dat']
                    bo = false
                }

                if (!bo) {
                    this.errors = setErrors(err)
                }
                return bo
            },
            getBase64ImageFromURL(url) {
                return new Promise((resolve, reject) => {
                    var img = new Image();
                    img.setAttribute("crossOrigin", "anonymous");

                    img.onload = () => {
                        var canvas = document.createElement("canvas");
                        canvas.width = img.width;
                        canvas.height = img.height;

                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0);

                        var dataURL = canvas.toDataURL("image/png");

                        resolve(dataURL);
                    };

                    img.onerror = error => {
                        reject(error);
                    };

                    img.src = url;
                });
            },
            async getClientFooter() {
                try {

                    if (this.$store.state.settings.client.clientFooterShowDocuments === false)
                        return "";

                    var footer = await SettingsService.getFooterClientImage();

                    if (footer.status !== 200) {
                        return '';
                    }

                    return footer.data.image;

                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async getClientHeaderLogo() {
                try {

                    if (this.$store.state.settings.client.clientLogoShowDocuments === false)
                        return "";

                    var header = await SettingsService.getLogoClientImage();

                    if (header.status !== 200) {
                        return '';
                    }

                    return header.data.image;

                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async calculateLogo(logo) {

                if (logo.length > 0) {
                    let img = new Image();
                    img.src = logo;
                    await img.decode();

                    return img;
                }
                else {
                    return '';
                }
                
            },
            async getPdfReport() {
                var logo = await this.getClientHeaderLogo();
                const pdfTable = this.$refs.pdf;
                var html = htmlToPdfmake(pdfTable.innerHTML);

                var date = new Date().toLocaleDateString();
                var docWithLogo = await this.getClientFooter();

                var imageData = await this.calculateLogo(logo);

                const documentDefinition = {
                    content: [{text: '', margin: 10 }, html],
                    footer: function (currentPage, pageCount) {
                        if (docWithLogo.length > 0) {
                            return [
                                {
                                    columns:
                                        [
                                            { image: docWithLogo, margin: [30, -50, 0, 0], maxWidth: 250 },
                                            { text: date + " | Wygenerowano za pomocą app.esesja.pl, Strona " + currentPage.toString() + ' z ' + pageCount, alignment: 'right', style: ['quote', 'small'], margin: [0, 20, 50, 0] }
                                        ]
                                }
                            ]
                        }
                        else {
                            return [{ text: date + " | Wygenerowano za pomocą app.esesja.pl, Strona " + currentPage.toString() + ' z ' + pageCount, alignment: 'right', style: ['quote', 'small'], margin: [0, 20, 50, 0] }];
                        }
                    },
                    header: function () {
                        if (logo.length > 0) {

                            var width = 0;
                            if (imageData.width >= imageData.height) {
                                width = 80;
                            }
                            else {
                                width = 60;
                            }

                            return [{ image: logo, alignment: 'right', margin: [0, 20, 20, 0], maxWidth: width }]
                        }
                    },
                    styles: {
                        quote: {
                            italics: true
                        },
                        small: {
                            fontSize: 8
                        }
                    }
                };
                pdfMake.vfs = pdfFonts.pdfMake.vfs;
                pdfMake.createPdf(documentDefinition).open();
            },
            createObj(_raw, _parsed) {
                return {
                    raw: _raw,
                    parsed: _parsed
                };
            },
            async generatePresenceReport() {
                try {
                    this.errors = [];
                    this.dataArr = [];
                    if (this.validate() == false) {
                        return
                    }

                    this.$store.commit("showLoader");

                    var grId = this.groups.find(x => x.nazwa.trim() == this.selected.trim()).id;

                    var t = {
                        groupId: grId,
                        dateFrom: new Date(this.from_date),
                        dateTo: new Date(this.to_date),
                    };

                    this.complex = (await ToolsService.getUserPresenceReport(t)).data;
                    var count = 1;
                    for (var j = 0; j < this.complex.up.length; j++) {
                        var temp = {
                            'Lp.': this.createObj(count, count + '.'),
                            Imię: this.createObj(this.complex.up[j].firstName, this.complex.up[j].firstName),
                            Nazwisko: this.createObj(this.complex.up[j].lastName, this.complex.up[j].lastName),
                            Obecny: this.createObj(this.complex.up[j].presence, this.complex.up[j].presence),
                            Nieobecny: this.createObj(this.complex.up[j].absence, this.complex.up[j].absence),
                            'Spóźnienia': this.createObj(this.complex.up[j].late, this.complex.up[j].late + " minut"),
                            'Wcześniejsze wyjścia': this.createObj(this.complex.up[j].exit, this.complex.up[j].exit + " minut"),
                            ID: this.createObj(this.complex.up[j].id, this.complex.up[j].id)
                        };
                        this.dataArr.push(temp);
                        count++;
                    }
                    this.client = this.complex.client;
                    this.tempGroupName = this.selected;
                    this.pagination = false;
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            passErrorMsg,
            isError,
            setErrors,
        },
        components: {
            TableComponent,
            ErrorMessage,
            ButtonWithIcon
        },
        async mounted() {
            try {
                this.$store.commit("showLoader");


                let response = await ContactService.index(false);
                if (response.status !== 200) {
                    throw await Promise.reject(response);
                }

                this.groups = response.data
                    .filter(item => {
                        return item.can_sessions;
                    })
                    .map(item => (
                        {
                            nazwa: item.name,
                            id: item.id
                        }));

                var d = {
                    nazwa: 'Sumaryczny raport',
                    id: 0
                }
                this.groups.push(d);
            } catch (e) {
                if (e.response !== undefined) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                } else {
                    console.log(e); 
                }

            }
            finally {
                this.$store.commit("hideLoader");
            }
        },
    };
</script>

<style lang="scss" scoped>
    .generate-button {
        margin-top: 30px;
        margin-left: auto;
    }

    .download-button {
        margin-left: auto;
        margin-right: 0;
        float: right;
    }

    .table_section {
        background-color: white;
        border-radius: 10px;
        padding: 10px;
        margin-top: 20px;

        h3 {
            padding: 10px;
        }

        p {
            padding: 10px;
        }
    }

    .chart_section {
        background-color: white;
        border-radius: 10px;
        max-height: 500px;
        padding: 10px;
    }

    .meetings-settings {
        margin-top: 20px;

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        &__wrapper {
            position: relative;
            /* &::after {
                content: "";
                position: absolute;
                top: 8px;
                background: #e2e5eb;
                width: 1px;
                height: 24px;
                right: 40px;
                z-index: 99;
            }*/
        }

        &__select {
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            padding: 8px 10px 8px 16px;
            font-weight: normal;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url("../../../../assets/icons/components/select-arrow.svg");
            background-repeat: no-repeat;
            background-position-x: calc(100% - 14px);
            background-position-y: 50%;
            min-width: 300px;
            min-height: 56px;

            &:focus {
                outline: none;
            }
        }
    }

    .contacts {
        margin: 49px 48px;

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 20px;
        }

        &__add-user-btn {
            position: fixed;
            bottom: 40px;
            right: 48px;
            width: 56px;
            height: 56px;
            border: 0px;
            background: #d80418;
            box-shadow: 8px 8px 28px rgba(173, 3, 19, 0.16);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.2 ease-in-out;

            &:hover {
                background: #ad0313 !important;
            }
        }

        &__add-user-btn-icon {
            width: 25px;
            height: 24px;
        }
    }

    .contact-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        &__input {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            width: 326px;
            box-sizing: border-box;
            padding: 12.5px 16px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__button-search {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left: 0px;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 32px;
                background: #e2e5eb;
                top: 8px;
                left: 0px;
            }
        }

        &__form {
            display: flex;
            align-items: center;
        }

        &__search-icon {
            padding: 14px;
        }

        &__action-buttons {
            display: flex;
        }
    }

    .contact-list-no-result {
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .edit-meeting {
        &__row-double {
            display: flex;
            //justify-content: space-between;
            margin-bottom: 24px;
        }

        &__col-double {
            display: flex;
            flex-direction: column;
            margin-right: 15px;
        }

        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-bottom: 8px;
        }

        &__picker {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            min-width: 238px;
            height: 56px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;

            &[type="date"]::-webkit-calendar-picker-indicator {
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url("../../../../assets/icons/components/callendar.svg") no-repeat;
                background-position-x: 100%;
                width: 22px;
                height: 22px;
                border-width: thin;
            }

            &[type="time"]::-webkit-calendar-picker-indicator {
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url("../../../../assets/icons/components/clock.svg") no-repeat;
                width: 22px;
                height: 22px;
                border-width: thin;
            }
        }

        &__picker-wrapper {
            position: relative;
            /*&::after {
                content: "";
                position: absolute;
                top: 8px;
                background: #e2e5eb;
                width: 1px;
                height: 40px;
                right: 56px;
                z-index: 99;
            }*/
        }
    }
</style>
