<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Wyniki bloku głosowań</h3>
                <button class="esesja-modal__wraper__header__exit-btn" @click="closeModale">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                
                <ButtonWithIcon icon="download" class="download-button"
                                text=".PDF"
                                :classModified="true"
                                @click="getPdfReport()" />

                <div style="clear: both;" ref="pdf">
                    <section v-for="(item, index) in pointBlockVotingResults" :key="index" style="padding: 5px;">
                        <p class="voting-label">Głosowanie: <b>{{item.name}}</b></p>
                        <table class="table-bootstrap table-bootstrap-sm table-bootstrap-bordered table-bootstrap-striped align-middle text-sm-center table-label">
                            <thead>
                                <tr>
                                    <th style="font-size: 14px;" v-for="i in item.results.sort((a, b) => (a.id > b.id) ? 1 : -1)" :key="i.id">{{i.name}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="align-middle">
                                    <td v-for="i in item.results.sort((a, b) => (a.id > b.id) ? 1 : -1)" :key="i.id">
                                        <span style="text-align: center;">
                                            {{i.count}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </div>
            </perfect-scrollbar>
        </section>
    </section>
</template>

<script>
    import pdfMake from 'pdfmake';
    import pdfFonts from 'pdfmake/build/vfs_fonts';
    import htmlToPdfmake from 'html-to-pdfmake';
    import ButtonWithIcon from "../../../../BasicControls/ButtonWithIcon.vue";
    import SettingsService from '../../../../../services/settings.service';

    export default {
        name: "InteractiveVotingBlockResultsModal",
        data() {
            return {
                //logo: require('@/assets/imgs/esesjalogo.png'),
            };
        },
        components: {
            ButtonWithIcon
        },
        props: {
            pointBlockVotingResults: Array,
            pointIdForResults: Number
        },

        methods: {
            getBase64ImageFromURL(url) {
                return new Promise((resolve, reject) => {
                    var img = new Image();
                    img.setAttribute("crossOrigin", "anonymous");

                    img.onload = () => {
                        var canvas = document.createElement("canvas");
                        canvas.width = img.width;
                        canvas.height = img.height;

                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0);

                        var dataURL = canvas.toDataURL("image/png");

                        resolve(dataURL);
                    };

                    img.onerror = error => {
                        reject(error);
                    };

                    img.src = url;
                });
            },
            async getClientFooter() {
                try {

                    if (this.$store.state.settings.client.clientFooterShowDocuments === false)
                        return "";

                    var footer = await SettingsService.getFooterClientImage();

                    if (footer.status !== 200) {
                        return '';
                    }

                    return footer.data.image;

                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async getClientHeaderLogo() {
                try {

                    if (this.$store.state.settings.client.clientLogoShowDocuments === false)
                        return "";

                    var header = await SettingsService.getLogoClientImage();

                    if (header.status !== 200) {
                        return '';
                    }

                    return header.data.image;

                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async calculateLogo(logo) {

                if (logo.length > 0) {
                    let img = new Image();
                    img.src = logo;
                    await img.decode();

                    return img;
                }
                else {
                    return '';
                }

            },
            async getPdfReport() {

                const pdfTable = this.$refs.pdf;
                var html = htmlToPdfmake( "<h3>Wyniki bloku głosowań</h3>"+"<hr>"+pdfTable.innerHTML);
                var logo = await this.getClientHeaderLogo();
                var date = new Date().toLocaleDateString();
                var docWithLogo = await this.getClientFooter();

                var imageData = await this.calculateLogo(logo);

                const documentDefinition = {
                    content: [{ text: '', margin: 10 }, html],
                    footer: function (currentPage, pageCount) {
                        if (docWithLogo.length > 0) {
                            return [
                                {
                                    columns:
                                        [
                                            { image: docWithLogo, margin: [30, -50, 0, 0], maxWidth: 250 },
                                            { text: date + " | Wygenerowano za pomocą app.esesja.pl, Strona " + currentPage.toString() + ' z ' + pageCount, alignment: 'right', style: ['quote', 'small'], margin: [0, 20, 50, 0] }
                                        ]
                                }
                            ]
                        }
                        else {
                            return [{ text: date + " | Wygenerowano za pomocą app.esesja.pl, Strona " + currentPage.toString() + ' z ' + pageCount, alignment: 'right', style: ['quote', 'small'], margin: [0, 20, 50, 0] }];
                        }
                    },
                    header: function () {
                        if (logo.length > 0) {

                            var width = 0;
                            if (imageData.width >= imageData.height) {
                                width = 80;
                            }
                            else {
                                width = 60;
                            }

                            return [{ image: logo, alignment: 'right', margin: [0, 20, 20, 0], maxWidth: width }]
                        }
                    },
                    styles: {
                        header: {
                            fontSize: 18,
                            bold: true,
                            margin: 10
                        },
                        quote: {
                            italics: true
                        },
                        small: {
                            fontSize: 8
                        }
                    }
                };

                pdfMake.vfs = pdfFonts.pdfMake.vfs;
                pdfMake.createPdf(documentDefinition).download("wyniki_bloku_glosowan_" + this.pointIdForResults +".pdf");
            },
            formatDateToDisplay(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    weekday: 'long',
                    day: 'numeric'
                });

                return d;
            },
            closeModale(e) {
                e.preventDefault();
                this.$emit('closePiontBlockVotingsResults');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/esesja-modal.scss";
    .voting-label {
        text-align: center;
        border: solid 1px;
    }

    .table-label {
        table-layout: fixed; 
        border: solid 1px;
    }

    .download-button {
        float: right;
        margin: 5px;
        padding: 10px;
    }

</style>
