<template>
    <section class="attachment-edit">
        <section class="attachment-edit__top">
            <section class="attachment-edit__name">
                <div @click="downloadAttachement()">
                    <img :src="require(`@/assets/icons/components/attachment.svg`)" class="attachment-edit__type-icon" />
                    <span class="attachment-edit__name-text">{{ name }} </span>
                    <span class="attachment-edit__time-text" v-if="time != null && time.length > 0">({{formatDateToDisplay(time)}})</span>
                </div>
               
                <section class="attachment-edit__actions-wrapper" v-if="annoAdminPrivilige">
                    <!--<button  v-if="!isEditVisible" class="attachment-edit__action-btn" @click=" (e) => { e.preventDefault(); isEditVisible = !isEditVisible; }" >
            <img  src="../../../../../assets/icons/components/edit.svg" class="attachment-edit__action-icon" />
        </button>-->
                    <button class="attachment-edit__action-btn attachment-edit__action-btn--red" @click="removeAttachment">
                        <img src="@/assets/icons/components/bin.svg" class="attachment-edit__action-icon" />
                    </button>
                </section>
            </section>
            
        </section>
        <!--<EditAttachment
            v-if="isEditVisible"
            :attachment="attachment"
            :elemID="elemID"
            @closeEdit="isEditVisible = false"
            @reload="reloadAttachementList" />-->
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script charset="utf-8">
//import EditAttachment from "./EditAttachment.vue";
    import LoaderInside from "@/components/BasicControls/LoaderInside.vue";
import AttachmentService from "@/services/attachments.service";
import { encryptStorage } from '@/services/encrypt.service';

export default {
    name: "Attachment",
    data() {
        return {
            name: "",
            time: '',
            isEditVisible: false,
            isLoading: false,
            annoAdminPrivilige: encryptStorage.getItem('priv').informator,
        };
    },
    props: {
        attachment: Object,
        elemID: Number,
    },
    components: {
        //EditAttachment,
        LoaderInside,
    },
    methods: {
        removeAttachment(e) {
            e.preventDefault();
            const actionToFire = () => {
                this.isLoading = true;
                AttachmentService.deleteAnnouncementFile(this.attachment.id, this.elemID).then(
                    () => {
                        this.getChange();
                    }
                );
            };
            this.$store.commit("showConfirmationAlert", actionToFire);
        },
        getChange() {
            this.isLoading = true;
            var d = {
                fileId: this.attachment.id,
                kid: this.elemID
            }
            this.$emit("removeAttachement", d);
            this.isLoading = false;
        },
        //reloadAttachementList(value) {
        //    this.$emit("reloadAttachementName", value);
        //},
        formatDateToDisplay(value) {
            var date = new Date(value)
            var d = date.toLocaleString('default', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            });

            return d;
        },
        async downloadAttachement() {

            try {
                var result = (await AttachmentService.downloadAnnouncementAttachment(this.elemID, this.attachment.id));
                if (result.status == 200) {
                    const contentType = result.headers['content-type'];
                    var a = document.createElement('a');
                    var blob = new Blob([result.data], { 'type': contentType });
                    a.href = window.URL.createObjectURL(blob);
                    a.download = this.name;
                    a.click();
                }

            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        }
    },
    mounted() {
        this.name = this.attachment.name;
        this.time = this.attachment.time;
    },
};
</script>

<style lang="scss" scoped>
    .attachment-edit {
        //width: 100%;
        //background: white;
        //border: 1px solid #d1d6df;
        //box-sizing: border-box;
        //box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        //border-radius: 4px;
        //margin-bottom: 16px;

        &:hover .attachment-edit__actions-wrapper {
            opacity: 1;
        }

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 16px 8px 16px;
        }

        &__name {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        &__type-icon {
            margin-right: 11px;
        }

        &__name-text {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;
        }

        &__time-text {
            font-size: 12px;
            color: #00082c;
            margin-left: 10px;
        }

        &__actions-wrapper {
            display: flex;
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }

        &__action-btn {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            background: #ffffff;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                border: 1px solid hsla(354, 96%, 43%, 0.324);
            }
        }
    }
</style>
