<template>
    <section class="notification-item">
        <section class="notification-item__icon">
            <img src="@/assets/icons/components/error-alert.svg" />
        </section>
        <section class="notification-item__body">
            <section class="notification-item__body__title">
                Adres E-mail nie został potwierdzony
            </section>
            <section class="notification-item__body__description">
                <p>Na podany adres e-mail został wysłany link aktywacyjny.</p>
                <section class="notification-item--center">
                    <button class="notification-item__button notification-item__button--small notification-item__button--white"
                            @click="() => this.$store.commit('toggleNoEmailConfirmDetails', true)">
                        <span class="notification-item__button__text">Sprawdź szczegóły</span>
                    </button>
                </section>
            </section>
        </section>
        <Transition>
            <NoEmailConfirmedDetails v-if="isShowDetails"/>
        </Transition>
    </section>
</template>
<script charset="utf-8">
    import NoEmailConfirmedDetails from "./Modals/NoEmailConfirmedDetails.vue";
    export default {
        name: "NoEmailConfirmed",
        components: {
            NoEmailConfirmedDetails
        },
        data() {
            return {
                isOpenDetails: false
            }
        },
        computed: {
            isShowDetails(){
                return this.$store.state.appStore.isShowNoEmailConfirmDetails;
            }
        },
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/notification-item.scss';
</style>