<template>
    <section>
        <Transition>
            <section class="panel" v-if="!isAppIsLocked" @click="detectClick" @contextmenu="detectClick" @keyup="detectClick">
                <Transition>
                    <AudioRecorder v-if="$store.state.audioRecorder.isOpen"/>
                </Transition>
                <Transition>
                    <FilesUploader v-if="isOpenFilesUploader" />
                </Transition>
                <NavMenu v-if="isLoading && !isInteractive" />
                <DiscussionInteractive v-if="isLoading && isInteractive" />
                <ContentComponent v-if="isLoading" />
                <BreakBoard v-if="isBreak" @stopBreak="stopSessionBreak" />
            </section>
        </Transition>
        <Transition>
            <AppLocked v-if="isAppIsLocked" />
        </Transition>
        <ModalsComponents />
    </section>
</template>

<script charset="utf-8">
    import NavMenu from "../components/Panel/NavMenu/NavMenu.vue";
    import ContentComponent from "../components/Panel/Content/ContentComponent.vue";
    import DiscussionInteractive from "../components/Panel/Interactive/DiscussionInteractive.vue";
    import BreakBoard from "../components/Panel/Interactive/BreakBoard.vue";
    import UserService from "../services/user.service";
    import AppLocked from "./Parts/AppLocked.vue";
    import ModalsComponents from "../components/Modals/ModalsComponents.vue";
    import { debounce } from "../helpers/utils";
    import AudioRecorder from "../components/Panel/Content/AudioRecorder/AudioRecorder.vue";
    import FilesUploader from "../components/Panel/Content/Uploader/FilesUploader.vue";
    import userService from "../services/user.service";
    import { debounceIntervals } from "../config/debounce.intervals";

    export default {
        name: "Panel",
        data() {
            return {
                lastActivityToLock: null,
                lastActivityNotify: null,
                notifyLockTime: 1000,
                minNotifyInternal: 15000,
                autoLockTimestamp: null,

                isWatchRegisterd: false,

                watchers: {
                    notifyWatch: {},
                    blockWatch: {},
                },
                onlineUserFlag: false,
            };
        },
        computed: {
            isAnyModalIsOpen(){
                return this.$store.state.appStore.isAddMeetingVisible 
                    || this.$store.state.appStore.isAddMeetingTemplateVisible
            },
            isLoading() {
                if (this.$store.state.userStore.token === "") {
                    return false;
                } else {
                    return true;
                }
            },
            isUserLogged() {
                return this.$store.state.userStore.isLogged
            },
            isInteractive() {
                let res = this.$store.state.appStore.isInInteractiveMode;
                return res;
            },

            isBreak() {
                return (this.$store.state.interactiveStore.isStartBreak && this.isInteractive)
            },
            isAppIsLocked() {
                let result = this.$store.state.userStore.isUserLockApplication;
                if (result === false) {
                    this.detectClick();
                }
                return result;
            },
            isAutoblockEnable() {
                if (this.isUserLogged) {
                    let res = this.$store.state.userStore.isAutoblockEnable;
                    if (res && !this.isInteractive) {
                        this.registerWatchers();
                    }
                    else {
                        this.deRegisterWatchers();
                    }
                    return res;
                }
                return false;
            },
            autoLockTime() {
                return this.$store.state.userStore.autoblockInterval;
            },
            isOpenFilesUploader() {
                return this.$store.state.uploaderStore.uploaderModalStauts;
            }
        },
        watch: {
            isUserLogged: {
                deep: true,
                handler(value) {
                    if (value === true && this.isWatchRegisterd == false) {
                        if (this.isAutoblockEnable) {
                            this.detectClick();
                        }
                    }
                }
            },
            isInteractive: {
                deep: true,
                handler(value) {
                    if (value === false) {
                        this.registerWatchers();
                        this.detectClick();
                    }
                }
            },

            isAnyModalIsOpen: {
                deep: true,
                handler: function (val) {
                    document.querySelector('body').style.overflow = val ? 'hidden' : 'auto';
                }
            },

            onlineUserFlag: {
                handler: debounce( async function (){
                    await userService.getOnline();
                    this.onlineUserFlag = !this.onlineUserFlag;
                }, debounceIntervals.panel.onlineUserFlagInterval)
            }
        },
        components: {
            NavMenu,
            ContentComponent,
            DiscussionInteractive,
            BreakBoard,
            AppLocked,
            ModalsComponents,
            AudioRecorder,
            FilesUploader,
        },
        async mounted() {
            try {
                if (this.isAutoblockEnable) {
                    this.detectClick();
                }

                UserService.updateStoreBasedOnStorage();
                await userService.getOnline();
                this.onlineUserFlag = !this.onlineUserFlag;
            }
            catch {
                return;
            }
        },
        methods: {
            registerWatchers() {
                if (!this.isWatchRegisterd) {
                    this.watchers.blockWatch = this.$watch('lastActivityToLock', debounce(this.fireLockApp, this.autoLockTime));
                    this.watchers.notifyWatch = this.$watch('lastActivityNotify', debounce(this.fireLockNotify, this.notifyLockTime));
                    this.isWatchRegisterd = true;
                }
            },
            deRegisterWatchers() {
                if (this.watchers.blockWatch !== undefined && typeof this.watchers.blockWatch === 'function') {
                    this.watchers.blockWatch();
                }
                if (this.watchers.notifyWatch !== undefined && typeof this.watchers.notifyWatch === 'function') {
                    this.watchers.notifyWatch();
                }

                this.isWatchRegisterd = false;
            },
            calculateNotifyTime() {
                this.notifyLockTime = this.autoLockTime - this.minNotifyInternal;
                if (this.notifyLockTime < 0) {
                    this.notifyLockTime = 1000;
                }
            },
            fireLockApp() {
                if (!this.isAutoblockEnable) {
                    return;
                }

                if (!this.isInteractive) {
                    UserService.updateLockState(true);
                }
            },
            async fireLockNotify() {
                if (!this.isAutoblockEnable) {
                    return;
                }

                if (this.isInteractive) {
                    this.detectClick();
                    return
                }

                if(this.isAppIsLocked)
                    return;

                let currentDate = new Date();
                let diff = this.autoLockTimestamp - currentDate;

                let timerInterval;
                let result = await this.$swal({
                    title: 'Wykryto brak aktywności',
                    html: '<span>Aplikacja zostanie zablokowana w ciagu<br /><b></b><span>',
                    timerProgressBar: true,
                    timer: diff,
                    didOpen: () =>{
                        const b = this.$swal.getHtmlContainer().querySelector('b')
                        timerInterval = setInterval(() => {
                          b.textContent = this.parseTimeLeft(this.$swal.getTimerLeft())
                        }, 100)
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    },
                    showCloseButton: true,
                });
                if (result.isConfirmed || result.isDismissed){
                    if(!this.isAppIsLocked){
                        this.detectClick();
                    }
                }
            },
            stopSessionBreak() {
                this.$store.commit("stopBreak");
                this.$store.commit("setBreakDuration", 0);
            },

            detectClick() {
                //if (!this.isInteractive) {
                    this.lastActivityToLock = new Date();
                    this.lastActivityNotify = new Date();
                    this.autoLockTimestamp = this.addMilliseconds(this.lastActivityToLock, this.autoLockTime);
                    this.calculateNotifyTime();
                //}
            },
            addMilliseconds(date, milliseconds) {
                const result = new Date(date);
                result.setMilliseconds(result.getMilliseconds() + milliseconds);
                return result;
            },

            parseTimeLeft(milliseconds){

                let seconds = Math.floor(milliseconds / 1000);
                let minutes = Math.floor(seconds / 60);

                seconds = seconds % 60;
                minutes = minutes % 60;

                return `${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
            },

            padTo2Digits(num) {
                return num.toString().padStart(2, '0');
            }
        }
    };
</script>

<style lang="scss" scoped>
    .panel {
        display: flex;
    }
</style>
