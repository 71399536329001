<template>
    <section class="client-settings">
        <section class="client-settings__header">
            <h2 class="client-settings__header__title">Ustawienia</h2>
        </section>

        <section class="client-settings__body">
            <ChartSettings />
            <LogoSettings />
            <FooterSettings />
            <CameraSettings v-if="camModule === 1" />
            <VotingSettings />
            <IntegrationInportantDataSettings />
            <PlaceholderSettings />
            <CustomCssSettings />
            <UiElementSettings />
        </section>
    </section>
</template>
<script charset="utf-8">
    import ChartSettings from "./Parts/ChartSettings.vue"
    import LogoSettings from "./Parts/LogoSettings.vue"
    import FooterSettings from "./Parts/FooterSettings.vue"
    import CameraSettings from "./Parts/CameraApiAddressSettings.vue"
    import VotingSettings from "./Parts/VotingSettings.vue"
    import IntegrationInportantDataSettings from "./Parts/IntegrationImportantDataSettings.vue"
    import PlaceholderSettings from "./Parts/PlaceholderSettings.vue";
    import CustomCssSettings from "./Parts/CustomCssSettings.vue";
    import UiElementSettings from "./Parts/UiElementSettings.vue";
    import { encryptStorage } from '../../../../services/encrypt.service';


    export default {
        name: "client-settings",
        components: {
            ChartSettings,
            LogoSettings,
            FooterSettings,
            CameraSettings,
            VotingSettings,
            IntegrationInportantDataSettings,
            PlaceholderSettings,
            CustomCssSettings,
            UiElementSettings
        },
        data() {
            return {
                camModule: encryptStorage.getItem('modules').cam,
            }
        },
        methods: {
            
        }
    }
</script>
<style lang="scss" scoped>
    .client-settings {
        margin: 49px 48px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        color: #00082c;

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;

            &__action {
                display: flex;
                flex-direction: row;
                column-gap: 20px;
            }

            &__title {
            }

            &__button {
                padding: 13.5px 33px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #d80418;
                border-radius: 4px;
                border: 0px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: #ad0313;
                }

                &--spacing {
                    margin-right: 12px;
                }

                &--white {
                    border: 1px solid #bbc2ce;
                    background-color: white;


                    &:hover {
                        background-color: #e8ebef;
                    }
                }
            }

            &__text {
                color: white;
                font-weight: 500;
                font-size: 16px;
                line-height: 132%;
                margin-left: 8px;

                &--white {
                    color: #00082c;
                }
            }
        }

        &__errors {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            &__item {
                display: flex;
                flex-direction: row;
                column-gap: 20px;
                padding: 20px;
                border: 1px solid red;
                background: rgba(255,0,0, 0.3);
                align-items: center;

                &__button {
                    padding: 13.5px 33px;
                    align-items: center;
                    justify-content: center;
                    background: #d80418;
                    border-radius: 4px;
                    border: 0px;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        background-color: #ad0313;
                    }

                    &__text {
                        color: white;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 132%;
                        margin-left: 8px;
                    }

                    &--small {
                        padding: 7px 16px;
                    }

                    &--white {
                        border: 1px solid #bbc2ce;
                        background-color: white;


                        &:hover {
                            background-color: #e8ebef;
                        }

                        .component__button__text {
                            color: #00082c;
                        }
                    }
                }
            }
        }

        &__body {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 20px;
            row-gap: 20px;
        }
    }
</style>