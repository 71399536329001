<template>
    <li class="nav-item" role="presentation">
        <button class="nav-link"
                :class="{'active': isActive }"
                :id="id" 
                data-bs-toggle="tab"
                :data-bs-target="internalHref" 
                type="button" 
                role="tab" 
                :aria-controls="href" 
                :aria-selected="isActive">
            <slot></slot>
        </button>
    </li>
</template>
<script charset="utf-8">
    export default {
        name: "eSesjaTabNavItem",
        props: {
            id: {
                type: String,
                required: true
            },
            href: {
                type: String,
                required: true
            },
            isActive: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            internalHref() {
                return `#${this.href}`
            }
        }
    }
</script>