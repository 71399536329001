class AlertsHelper {
    errorAlert(text) {
        return {
            title: 'Błąd',
            text: text,
            icon: 'error',
            timer: 6000,
            allowOutsideClick: false
        };
    }

    errorToastElement() {
        return {
            toast: true,
            position: 'bottom-right',
            iconColor: 'red',
            color: 'red',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true
        }
    }

    informationToastElement() {
        return {
            toast: true,
            position: 'center',
            //iconColor: 'red',
            //color: 'red',
            showConfirmButton: true,
            //timer: 5000,
            //timerProgressBar: true
            width: '600px',
        }
    }

    informationSuccessElement() {
        return {
            toast: true,
            //iconColor: 'red',
            //color: 'red',
            showConfirmButton: false,
            //timer: 5000,
            //timerProgressBar: true
            width: '600px',
            position: 'bottom-right',
        }
    }

    errorToastAlert(text) {
        return {
            icon: 'error',
            title: 'Błąd',
            text: text,
            timer: 3000,
        }
    }

    successAlert(text) {
        return {
            title: 'Sukces',
            html: text,
            icon: 'success',
            timer: 3000,
            allowOutsideClick: false,
            timerProgressBar: true
        };
    }

    informationAlert(text) {
        return {
            icon: "question",
            title: "Informacja",
            html: text,

        };
    }
}

export default new AlertsHelper();