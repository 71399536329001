<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h2 class="esesja-modal__wraper__header__title">{{ isEdit ? "Edytuj" : "Dodaj"}} szablon</h2>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click="$store.commit('hideAddMeetingTempplate');">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <label class="esesja-modal__wraper__body__label">Nazwa szablonu</label>
                <section class="esesja-modal__row">
                    <input class="esesja-modal__wraper__body__input" v-model="templateData.name" placecholder="temat" />
                </section>
                <section class="esesja-modal__row">
                    <SwitchButton label="Posiedzenie publiczne"
                                  :isPublic="templateData.isPublic"
                                  @setIsPublic="(value) => (templateData.isPublic = value)" />
                </section>
                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="$store.commit('hideAddMeetingTempplate')"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="saveSessionTemplate">Zapisz</button>
                </section>
            </perfect-scrollbar>
            <LoaderInside v-if="isLoading" />
        </section>
    </section>
</template>
<script charset="utf-8">
    import SessionService from "../../../../../services/session.service";
    import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
    import SwitchButton from "../../../../BasicControls/SwitchButton.vue";
    export default {
        name: "AddMeetingTemplate",
        components: {
            LoaderInside,
            SwitchButton,
        },
        computed: {
            isEdit() {
                return this.$store.state.appStore.templateId !== null;
            },
            templateId() {
                return this.$store.state.appStore.templateId;
            }
        },
        data() {
            return {
                isLoading: false,
                templateData: {
                    name: "",
                    isPublic: false,
                }
            }
        },
        mounted() {
            if (this.isEdit) {
                this.fetchTemplateData();
            }
        },
        methods: {

            async fetchTemplateData() {
                try {
                    this.isLoading = true;
                    let response = await SessionService.getSession(this.templateId);

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }
                    this.templateData.name = response.data.signature;
                    this.templateData.isPublic = response.data.is_public;
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sessionTemplate' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading = false;
                }
            },

            async saveSessionTemplate() {
                try {
                    this.isLoading = true;
                    

                    if (this.isEdit) {
                        let response = await SessionService.updateSessionTemplate(this.templateId, this.templateData);
                        if (response.status !== 200) {
                            throw await Promise.reject(response);
                        }
                        let currentTemplateList = await SessionService.getSessionTemplate();
                        if (currentTemplateList.status !== 200) {
                            throw await Promise.reject(currentTemplateList);
                        }
                        this.$store.commit("setSessionTemplates", currentTemplateList.data);
                    }
                    else {
                        let response = await SessionService.addSessionTemplate(this.templateData);
                        if (response.status !== 200) {
                            throw await Promise.reject(response);
                        }
                        let currentTemplateList = await SessionService.getSessionTemplate();
                        if (currentTemplateList.status !== 200) {
                            throw await Promise.reject(currentTemplateList);
                        }
                        this.$store.commit("setSessionTemplates", currentTemplateList.data);
                        
                        this.$router.push({ name: "sessionTemplateEdit", params: { sessionid: response.data } });
                    }


                    
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sessionTemplate' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading = false;
                    this.$store.commit('hideAddMeetingTempplate');
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "../../../../../assets/scss/esesja-modal.scss";
</style>