<template>
    <section class="import-contact-importer d-flex flex-column gap-2">
        <section class="information d-flex flex-column gap-2">
            <span class="text-center">Masowe dodawanie kontaktów. Prawidłowy format danych:</span>
            <span class="text-center" style="font-weight: bold">Imię;Nazwisko;Numer telefonu;Adres e-mail;Grupy</span>
            <span class="text-center">Przykład:</span>
            <span class="text-center">Jan;Nowak;123456789;jan@test.pl;Grupa A;Grupa B</span>
            <span class="text-center" style="color: red">Uwaga!</span>
            <span class="text-center">Jeśli dla danego kontaktu ma nie być podanego np. adresu e-mail, wówczas należy podać pustą wartość.</span>
            <span class="text-center">Przykład:</span>
            <span class="text-center" style="font-weight: bold">Jan;Nowak;123456789;;Grupa A;Grupa B</span>
        </section>
        <textarea v-model="inlineData" class="inline-data" cols="5" rows="10"></textarea>
        <section class="d-flex justify-content-center">
            <ButtonBasic @click="next" color="danger" size="lg" :disabled="inlineData === ''">Wczytaj</ButtonBasic>
        </section>

    </section>
</template>
<script charset="utf-8">
    import importContactService from "../../../../../../../services/import.contact.service"

    export default {
        name: "ImportConcatcImporter",
        data() {
            return {
                inlineData: "",
            }
        },
        methods: {
            next() {
                try {
                    importContactService.parseInputData(this.inlineData);
                }
                catch (e) {
                    console.log(e);
                }

            }
        }
    }
</script>