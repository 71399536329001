<template>
    <section :class="{ 'full-page': isBig }">
        <section class="component">
            <section class="component__header">
                <span class="component__header__title">
                    Ustawienia css
                </span>
                <section class="component__header__action">
                    <button class="component__button component__button--small component__button--white"
                        @click="toogleEditor()">
                        <img src="@/assets/icons/components/up-right-and-down-left-from-center.svg" v-if="!isBig" width="20"/>
                        <img src="@/assets/icons/components/arrow-down-left-and-arrow-up-right-to-center.svg" v-else width="20"/>
                        
                    </button>
                    <button class="component__button component__button--small component__button--white"
                        @click="save()">
                        <span class="component__button__text">Zapisz</span>
                    </button>
                </section>
            </section>
            <section class="component__body">
                <CodeEditor v-model="customCss.styles" :line-nums="true" :languages="[['css', 'css']]" height="96%" width="100%"
                    @keyup.down="removeAttr" :display-language="true" theme="github"
                    @lang="(lang) => console.log(lang)">
                </CodeEditor>
                <section class="custom-css-metadata">
                    <span v-if="customCss.revision &&customCss.revision !== 0">Data: {{ formatDate(customCss.created) }}</span>
                    <span v-if="customCss.revision &&customCss.revision !== 0">Autor: {{ customCss.user.firstName }} {{ customCss.user.lastName }}</span>
                    <span v-if="customCss.revision &&customCss.revision !== 0">Rewizja: <span class="link">{{ customCss.revision }}</span></span>
                </section>
            </section>
            <SmallLoader v-if="isLoading" />
        </section>
    </section>
</template>
<script charset="utf-8">
import CodeEditor from "simple-code-editor";
//import hljs from 'highlight.js';
import customCssService from '../../../../../services/custom.css.service';
import SmallLoader from "../../../../BasicControls/SmallLoader.vue";
import moment from "moment";

export default {
    name: "CustomCssSettings",
    components: {
        SmallLoader,
        CodeEditor
    },
    data() {
        return {
            isLoading: false,
            customCss: {
                styles: ""
            },
            editorConf: {
                automaticLayout: false,
                formatOnType: false,
                formatOnPaste: false,
            },
            isBig: false,
        }
    },
    computed: {
        editorHeight(){
            this.removeAttr();
            if(this.isBig){
                return "calc(80vh - 20px)"
            }
            return "300px";
        },
        editorWidth(){
            this.removeAttr();
            if(this.isBig){
                return "100%"
            }
            return "100%";
        }
    },
    watch: {
        customCss: {
            deep: true,
            immediate: true,
            handler() {
                this.removeAttr();
            }
        }
    },
    created() {
        this.removeAttr();
    },
    async mounted() {
        try {
            this.isLoading = true;
            this.removeAttr();
            await this.internalFetch();
            this.removeAttr();
        }
        finally {
            this.isLoading = false;
        }
    },
    methods: {
        removeAttr() {
            Array.from(document.getElementsByClassName("language-css")).forEach(item => {
                delete item.dataset.highlighted;
            })
        },
        async internalFetch() {
            let response = await customCssService.getNewest();
            if (response.status !== 200) {
                throw await Promise.reject(response);
            }
            this.customCss = response.data;
        },
        async save() {
            try {
                this.isLoading = true;
                await customCssService.create(this.customCss);
                await this.internalFetch();
            }
            finally {
                this.isLoading = false;
            }
        },
        toogleEditor(){
            this.removeAttr();
            this.isBig = !this.isBig
            this.removeAttr();
        },
        formatDate(date){
            return moment(date).format("YYYY-MM-DD HH:mm");
        }
    }
}
</script>
<style lang="scss">
@import "@/assets/scss/user-profile-component.scss";

.component {
    position: relative
}

.full-page {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;

    .component{
        width: 80vw;
        &__body {
            height: 80vh;
        }
    }
}

.custom-css-metadata{
    padding: 5px 10px;
    margin-bottom: 10px;
    display:  flex;
    flex-direction: row;
    column-gap: 5px;
    font-weight: 300;
    font-size: 0.75em;
    color: #808396;
    font-style: italic;
}

.component__header__action{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}
</style>