import ApiService from "./api.service";

class CommunicationService extends ApiService {
    getSms(page) {
        return this.get(`Message/sms-page/${page}`);
    }
    searchSms(data) {
        return this.post(`sms/search`, data);
    }
    getSmsStatistics() {
        return this.get(`sms/statistics`);
    }
    getSmsCredits() {
        return this.get(`sms/credits`);
    }
    exportSms(data){
        return this.post(`sms/export`, data, { responseType: "arraybuffer" });
    }
    searchEmails(data) {
        return this.post(`email/search`, data);
    }
    getEmailStatistics() {
        return this.get(`email/statistics`);
    }
    exportEmails(data){
        return this.post(`email/export`, data, { responseType: "arraybuffer" });
    }
    getEmails(page) {
        return this.get(`Message/email-page/${page}`);
    }
    addAnnoucementForUsers(data) {
        return this.post(`Message/addAnnoucementForUsers`, data)
    }
    getAnnoucementBetweenDates(date_from, date_to) {
        return this.post("Message/between", { date_from, date_to });
    }
    getAllAnnoucement(page) {
        return this.get(`Message/announcement-page/${page}`);
    }
    searchAnnoucements(data) {
        return this.post(`Message/announcement/search`, data)
    }
    deleteAnnouncement(id) {
        return this.delete(`Message/deleteAnnouncement/${id}`)
    }
    updateAnnoucementForUsers(data) {
        return this.post(`Message/updateAnnoucementForUsers`, data)
    }
    showLogs(id) {
        return this.get(`Message/showAnnouncementLogs/${id}`)
    }
    getGroups() {
        return this.get('Message/getGroupsForFilter')
    }
    getSpecyficAnnoucement(id) {
        return this.get(`Message/getSpecyficAnnoucement/${id}`)
    }
}

export default new CommunicationService();