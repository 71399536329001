<template>
    <section class="add-note">
        <section class="add-note-form">
            <button class="add-note-form__exit-btn"
                    @click="
                    (e)=>
                {
                e.preventDefault();
                this.$emit('closeAddNote');
                }
                "
                >
                <img src="../../../../assets/icons/components/exit-btn.svg" />
            </button>
            <h2 class="add-note-form__title">Dodaj notatkę</h2>
            <section class="add-note-form__input-wrapper">
                <label class="add-note-form__label">Nazwa</label>
                <input type="text"
                       placeholder="Wpisz nazwę notatki..."
                       class="add-note-form__input"
                       v-model="name" />
            </section>
            <label class="add-note-form__label">Treść</label>
            <section class="add-note-form__wyswig">
                <section class="wyswig-actions">
                    <button @click="editor.chain().focus().toggleBold().run()"
                            class="wyswig-actions__btn"
                            :class="{'is-active': editor !== null ? editor.isActive('bold') : false,}">
                        <img class="wyswig-actions__icon" src="../../../../assets/icons/components/bold.svg" />
                    </button>

                    <button @click="editor.chain().focus().toggleItalic().run()"
                            class="wyswig-actions__btn"
                            :class="{'is-active': editor !== null ? editor.isActive('italic') : false,}">
                        <img src="../../../../assets/icons/components/italic.svg" class="wyswig-actions__icon" />
                    </button>

                    <button @click="editor.chain().focus().toggleStrike().run()"
                            class="wyswig-actions__btn"
                            :class="{'is-active': editor !== null ? editor.isActive('strike') : false,}">
                        <img src="../../../../assets/icons/components/strike.svg" class="wyswig-actions__icon" />
                    </button>

                    <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                            class="wyswig-actions__btn"
                            :class="{'is-active': editor !== null ? editor.isActive('heading', { level: 1, }) : false, }">
                        <img src="../../../../assets/icons/components/header-1.svg" class="wyswig-actions__icon heading" />
                    </button>

                    <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                            class="wyswig-actions__btn"
                            :class="{'is-active': editor !== null ? editor.isActive('heading', {level: 2,}) : false,}">
                        <img src="../../../../assets/icons/components/header-2.svg" class="wyswig-actions__icon heading" />
                    </button>

                    <button @click="editor.chain().focus().toggleBulletList().run()"
                            class="wyswig-actions__btn"
                            :class="{'is-active': editor !== null ? editor.isActive('bulletList') : false,}">
                        <img src="../../../../assets/icons/components/bullet-list.svg" class="wyswig-actions__icon" />
                    </button>

                    <button @click="editor.chain().focus().toggleOrderedList().run()"
                            class="wyswig-actions__btn"
                            :class="{ 'is-active': editor !== null ? editor.isActive('orderedList') : false,}">
                        <img src="../../../../assets/icons/components/ordered-list.svg" class="wyswig-actions__icon" />
                    </button>

                    <button @click="editor.chain().focus().undo().run()"
                            class="wyswig-actions__btn">
                        <img src="../../../../assets/icons/components/undo.svg" class="wyswig-actions__icon" />
                    </button>

                    <button @click="editor.chain().focus().redo().run()"
                            class="wyswig-actions__btn">
                        <img src="../../../../assets/icons/components/redo.svg" class="wyswig-actions__icon" />
                    </button>
                </section>
                <editor-content :editor="editor" />
            </section>

            <section class="switch-section">
                <SwitchButton label="Publiczna"
                              :isPublic="isPublic"
                              @setIsPublic="(value) => (isPublic = value)" />
                <section name="externalTrigger" class="tip-btn">
                    <img src="../../../../assets/icons/components/tooltip.svg" class="tooltip" />
                    <Hint title="Publiczna notatka" text="Aktywna oznacza, że jest ona widoczna dla wszystkich administratorów." />
                </section>
            </section>

            <section class="add-note-form__bottom">
                <button class="add-note-form__bottom-button"
                        @click="this.$emit('closeAddNote')">
                    Anuluj
                </button>
                <button class="
                        add-note-form__bottom-button
                        add-note-form__bottom-button--red
                    "
                        @click="handleSaveClick" :disabled="name.length == 0">
                    Zapisz
                </button>
            </section>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import LoaderInside from "../../../BasicControls/LoaderInside.vue";
import SwitchButton from "../../../BasicControls/SwitchButton.vue";
import Hint from "../../../BasicControls/Hint.vue";
import AttachmentService from "../../../../services/attachments.service";

export default {
    name: "AddNote",
    data() {
        return {
            name: this.att.name,
            editor: null,
            isLoading: false,
            isPublic: this.att.public == 1 ? true : false,
            description: this.att.description
        };
    },
    props: {
        pointID: Number,
        att: Object
    },
    components: {
        EditorContent,
        LoaderInside,
        SwitchButton,
        Hint
    },
    methods: {
        handleSaveClick() {
            this.isLoading = true;
            const data = {
                id: this.att.id,
                name: this.name,
                description: this.editor.getHTML(),
                isPublic: this.isPublic,
            };

            if (this.att.id > 0) {
                AttachmentService.updateNote(this.att.id, this.pointID, data).then(() => {
                    this.editor.commands.clearContent();
                    this.name = "";
                    this.isPublic = false;
                    this.$emit("reload");
                    this.$emit("closeAddNote");
                    this.isLoading = false;
                });
                    
            }
            else {
                AttachmentService.addNote(this.pointID, data).then(() => {
                    this.editor.commands.clearContent();
                    this.name = "";
                    this.isPublic = false;
                    this.$emit("reload");
                    this.$emit("closeAddNote");
                    this.isLoading = false;
                });
            }
        },
    },
    mounted() {
        this.editor = new Editor({
            content: this.description,
            extensions: [StarterKit],
        });
    },
    beforeUnmount() {
        this.editor.destroy();
    },
};
</script>

<style lang="scss" scoped>
    .is-active {
        background: grey !important;
    }

    .add-note {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 15;

        .ProseMirror {
            height: 160px;
            max-height: 160px;

            h1 {
                font-size: 32px;
            }

            h2 {
                font-size: 24px;
            }

            p {
                font-size: 16px;
            }
        }

        .ProseMirror-focused {
            border: 0px !important;
        }

        .add-note-form {
            padding: 32px 56px;
            background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
            border-radius: 12px;
            max-height: 90vh;
            overflow-y: auto;
            width: 612px;

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }

            &__exit-btn {
                border: 0px;
                background-color: transparent;
                position: relative;
                right: -100%;
            }

            &__title {
                font-weight: 600;
                line-height: 128%;
                color: #00082c;
                margin-bottom: 32px;
            }

            &__input-wrapper {
                display: flex;
                flex-direction: column;
                margin-top: 16px;
            }

            &__label {
                font-weight: 600;
                font-size: 14px;
                line-height: 145%;
                color: #00082c;
            }

            &__input {
                padding: 12.5px 16px;
                background: #ffffff;
                border: 1px solid #d1d6df;
                box-sizing: border-box;
                box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
                border-radius: 4px;
                margin-top: 8px;
                font-size: 16px;
                line-height: 144%;
                color: #00082c;
                margin-bottom: 16px;

                &:focus {
                    border: 1px solid #00082c;
                }
            }

            &__wyswig {
                margin-top: 8px;
                height: 190px;
                max-height: 190px;
                background: #ffffff;
                border: 1px solid #d1d6df;
                border-radius: 4px;

                .wyswig-actions {
                    background: #f8f8f8;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px solid #d1d6df;

                    &__btn {
                        background: transparent;
                        border: 0px;
                        height: 30px;
                        padding: 5px 16px;

                        &:hover {
                            -webkit-box-shadow: inset 0px 0px 20px -5px rgba(209, 214, 223, 1);
                            -moz-box-shadow: inset 0px 0px 20px -5px rgba(209, 214, 223, 1);
                            box-shadow: inset 0px 0px 20px -5px rgba(209, 214, 223, 1);
                        }
                    }

                    &__icon {
                        height: 20px;
                    }
                }
            }

            &__bottom {
                display: flex;
                justify-content: space-between;
            }

            &__bottom-button {
                padding: 18px 40px 17px;
                width: 238px;
                height: 56px;
                border: 1px solid #bbc2ce;
                box-sizing: border-box;
                border-radius: 4px;
                font-weight: 500;
                font-size: 16px;
                line-height: 132%;
                color: #00082c;
                background-color: transparent;
                margin-top: 40px;
                background: none;

                &--red {
                    border: 0px;
                    background: #d80418;
                    color: white;
                }

                &--red:disabled {
                    border: 0px;
                    background: #D3D3D3;
                    color: white;
                    cursor: default;
                }
            }
        }

        .switch-section {
            display: flex;
            margin-bottom: 0px;
            margin-top: 10px;
        }

        .tooltip {
            position: relative;
            left: 5px;
            top: -3px;
        }

        .tip-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0px;
            background-color: transparent;
            width: 20px;
            height: 20px;
            position: relative;
            cursor: pointer;

            &:hover .hint {
                opacity: 1;
                display: block;
            }
        }
    }
</style>
