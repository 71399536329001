<template>
    <section :class="classes">
        <slot></slot>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "eSesjaTab",
        props: {
            mode: {
                type: String,
                default: "horizontal"
            }
        },
        computed: {
            classes() {
                let className = "esesja-tab";

                switch (this.mode) {
                    case "vertical": className += " d-flex align-items-start"; break;
                }

                return className;
            }
        }
    }
</script>