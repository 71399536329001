<template>
    <eSesjaModal>
        <eSesjaModalHeader @close="$store.commit('setAudioVideoLinkModalStatus', false)">
            Publikuj nagrania z posiedzenia
        </eSesjaModalHeader>
        <eSesjaModalBody>
            <label class="esesja-modal__wraper__body__label">Audio</label>
            <section class="esesja-modal__row">
                <input class="esesja-modal__wraper__body__input" :class="{ 'input-has-error' : model.audioLink.length > maxChars ||  validation.audioLinkUri === false}" placecholder="Audio" v-model="model.audioLink" />
                <section class="audio-link-append">
                    <ErrorMessage v-if="validation.audioLinkUri === false" :errorMsgs="['Wprowadzony adres jest nie prawidłowy']" />
                    <section class="char-count" :class="{ 'counter-has-error' : model.audioLink.length > maxChars }">{{ model.audioLink.length }}/{{ maxChars }} </section>
                </section>
            </section>
            <label class="esesja-modal__wraper__body__label">Video</label>
            <section class="esesja-modal__row">
                <input class="esesja-modal__wraper__body__input" :class="{ 'input-has-error' : model.videoLink.length > maxChars || validation.videoLinkUri === false}" placecholder="Video" v-model="model.videoLink" />
                <section class="audio-link-append">
                    <ErrorMessage v-if="validation.videoLinkUri === false" :errorMsgs="['Wprowadzony adres jest nie prawidłowy']" />
                    <section class="char-count" :class="{ 'counter-has-error' : model.videoLink.length > maxChars }">{{ model.videoLink.length }}/{{ maxChars }} </section>
                </section>
            </section>
        </eSesjaModalBody>
        <eSesjaModalFooter>
            <eSesjaModalButtonCancel @close="$store.commit('setAudioVideoLinkModalStatus', false)">
                Anuluj
            </eSesjaModalButtonCancel>
            <eSesjaModalButtonSave @click="saveData" :disabled="hasError()">
                Zapisz
            </eSesjaModalButtonSave>
        </eSesjaModalFooter>
    </eSesjaModal>
</template>
<script charset="utf-8">
    import SessionService from "../../../services/session.service";
    import { isUrlIsValid } from "../../../helpers/utils";
	import ErrorMessage from "../../BasicControls/ErrorMessage.vue";
    export default {
        name: "SessionAudioLinkModal",
        components: {
			ErrorMessage
        },
        computed:{
            sessionId(){
                return parseInt(this.$store.state.modals.sessions.audioVidelLinkSessionId);
            }
        },
        data(){
            return {
                model:{
                    audioLink: "",
                    videoLink: "",
                },
                validation: {
                    audioLinkUri: true,
                    videoLinkUri: true,
                },
                maxChars: 150,
            }
        },
        watch: {
            'model.audioLink': {
                handler: function (val) {
                        
                    this.validation.audioLinkUri = val !== "" ? isUrlIsValid(val) : true;
                }
            },
			'model.videoLink': {
				handler: function (val) {
                    console.log("model.videoLinkUri", val)
					this.validation.videoLinkUri = val !== "" ? isUrlIsValid(val) : true;
				}
			},
        },
        async mounted(){
            try{
                this.$store.commit("showLoader");
                await this.internalFetchAudioLinkModal();
            }
            finally{
                this.$store.commit("hideLoader");
            }
        },
        methods:{
            async internalFetchAudioLinkModal(){
                let response = await SessionService.getSessionAudioLink(this.sessionId);
                if(response.status !== 200){
                    throw await Promise.reject(response);
                }
                this.model = response.data;
            },
            async saveData(){
                try{
                    this.$store.commit("showLoader");
                    let response = await SessionService.setSessionAudioLink(this.sessionId, this.model);

                     if(response.status !== 200){
                         throw await Promise.reject(response);
                     }

                     this.$store.commit('setAudioVideoLinkModalStatus', false)
                }
                catch(e){
                    console.log(e);
                }
                finally{
                    this.$store.commit("hideLoader");
                }
            },

            hasError(){
				return (this.model.videoLink.length > this.maxChars) || (this.model.audioLink.length > this.maxChars) || !this.validation.audioLinkUri || !this.validation.videoLinkUri
            }
        }
    }
</script>
<style lang="scss" scoped>
    .input-has-error{
        border-color: red;
    }
    .counter-has-error{
        color: red;
    }

    .char-count {
        text-align: right;
        font-size: 0.8em;
        font-weight: 500;
        padding: 5px;
        margin-left: auto;
    }

    .audio-link-append {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
</style>
