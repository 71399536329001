<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Zmiana Pinu</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$store.commit('hidehowUserChangeOwnPinModal');}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section class="esesja-modal__wraper__body__fallback-error" v-if="changePinError">
                    <img src="@/assets/icons/components/exit-btn.svg" class="esesja-modal__wraper__body__fallback-error__icon" alt="Ikona błędu" />
                    <span class="esesja-modal__wraper__body__fallback-error__text">
                        Sprawdź poprawność wpisanych danych
                    </span>
                </section>
                <label class="esesja-modal__wraper__body__label">Hasło do konta</label>
                <section class="esesja-modal__row">
                    <input class="esesja-modal__wraper__body__input"
                           type="password"
                           v-model="request.password"
                           placecholder="Dotychczasowe hasło"
                           v-error="{ errors, input_key: 'password' }" />
                    <ErrorMessage v-if="isError('oldPassword', this.errors)"
                                  :errorMsgs="passErrorMsg('password', this.errors)" />
                </section>

                <label class="esesja-modal__wraper__body__label">Nowy Pin</label>
                <section class="esesja-modal__row">
                    <section style="display: flex"  data-tip="Pin musi zawierać min. 6 cyfr">
                        <input class="esesja-modal__wraper__body__input"
                               :type="passwordFieldType"
                               v-model="request.pin"
                               placecholder="Nowe hasło"
                               v-error="{ errors, input_key: 'pin' }" />
                    </section>
                    <ErrorMessage v-if="isError('pin', this.errors)"
                                  :errorMsgs="passErrorMsg('pin', this.errors)" />
                </section>

                <label class="esesja-modal__wraper__body__label">Powtórz nowy Pin</label>
                <section class="esesja-modal__row">
                    <section style="display: flex" data-tip="Pin musi zawierać min. 6 cyfr">
                        <input class="esesja-modal__wraper__body__input"
                               :type="passwordFieldType"
                               v-model="request.confirmPin"
                               placecholder="Nowe hasło"
                               v-error="{ errors, input_key: 'confirmPin' }" />
                    </section>
                    <ErrorMessage v-if="isError('confirmPin', this.errors)"
                                  :errorMsgs="passErrorMsg('confirmPin', this.errors)" />
                </section>

                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="this.$store.commit('hidehowUserChangeOwnPinModal')">Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="changePin">Zapisz</button>
                </section>
            </perfect-scrollbar>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
    import ErrorMessage from "@/components/BasicControls/ErrorMessage.vue";
    import {
        passErrorMsg,
        isError,
        setErrors,
    } from "@/formValidation/main";
    import UserService from "../../../../../services/user.service";

    export default {
        name: "ChangeOwnPin",
        components: {
            ErrorMessage
        },
        data() {
            return {
                request: {
                    password: "",
                    pin: "",
                    confirmPin: "",
                },
                errors: [],
                changePinError: false,
            }
        },
        methods: {

            async changePin() {
                try {
                    this.changePinError = false;
                    this.$store.commit("showLoader");
                    if (!this.validate()) {
                        return;
                    }

                    let response = await UserService.updateOwnPin(this.request);

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.changePinError = !response.data;
                    if (response.data === true) {
                        this.$store.commit('hidehowUserChangeOwnPinModal');
                        return;
                    }

                }
                catch (e) {
                    console.log(e)
                    this.changePinError = true;
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            validate() {
                var validateStatus = true;
                var errors = {};

                if (this.request.password.length <= 0) {
                    validateStatus = false;
                    errors.password = ['Pole wymagane']
                }

                if (this.request.pin.length < 6) {
                    validateStatus = false;
                    errors.pin = ['Pin nie może być krótszy niż 6 cyfr']
                }

                if (this.request.confirmPin.length < 6) {
                    validateStatus = false;
                    errors.confirmPin = ['Pin nie może być krótszy niż 6 cyfr']
                }
                else {
                    if (this.request.pin !== this.request.confirmPin) {
                        validateStatus = false;
                        errors.pin = ['Podane hasła się nie zgadzają']
                        errors.confirmPin = ['Podane hasła się nie zgadzają']
                    }
                }
                if (!validateStatus) {
                    this.isLoading = false;
                    this.errors = setErrors(errors)
                }
                return validateStatus;
            },
            passErrorMsg,
            setErrors,
            isError,
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/esesja-modal.scss';
    @import '@/assets/scss/esesja-data-tip.scss';
</style>