<template>
    <section class="notification__menu" v-if="notifications.length > 0">
        <section class="notification__menu__item"
                 v-for="(item, index) in notifications"
                 :key="index">
            <NoEmailConfirmed v-if="item.errorTypeHuman === 'noemailconfirmed'" />
            <NoEmailSpecified v-if="item.errorTypeHuman === 'noemailspecified'" />
            <OldPassword v-if="item.errorTypeHuman === 'oldpassword'" />
            <ChangeInAppHistory v-if="item.errorTypeHuman === 'changeinapphistory'" />
            <!--<Dummy v-else />-->
        </section>
    </section>
</template>
<script charset="utf-8">
    import NoEmailConfirmed from "./Notifications/NoEmailConfirmed.vue";
    import NoEmailSpecified from "./Notifications/NoEmailSpecified.vue";
    import OldPassword from "./Notifications/OldPassword.vue";
    import ChangeInAppHistory from "./Notifications/ChangeInAppHistory.vue";
    //import Dummy from "./Notifications/Dummy.vue";
     
    export default {
        name: "UserNotification",
        components: {
            NoEmailConfirmed,
            NoEmailSpecified,
            OldPassword,
            ChangeInAppHistory,
            //Dummy
        },
        data() {
            return {
            };
        },
        computed: {
            notifications() {
                return this.$store.state.userStore.accountErrors;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .notification {
        &__menu {
            position: absolute;
            background: #fff;
            right: 341px;
            top: 98px;
            border-left: 1px solid #dde0e7;
            z-index: 7;
            border-bottom: 1px solid #dde0e7;
            border-right: 1px solid #dde0e7;
            border-radius: 0 0 20px 20px;
            box-shadow: 0px 10px 9px 0px #b1b1b1;
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;

            &__item {
                display: flex;
                flex-direction: column;
            }
        }
    }
</style>
