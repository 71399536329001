<template>
    <section class="component">
        <section class="component__header">
            <span class="component__header__title">
                Ostatnie logowania
            </span>
        </section>
        <section class="component__body last-logins">
            <table class="table-bootstrap table-bootstrap-hover table-bootstrap-bordered table-bootstrap-striped align-middle">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in data" :key="index" class="align-middle login-info-row">
                        <td>{{ index + 1 }}</td>
                        <td class="text-end">{{ formatDate(item.date) }}</td>
                    </tr>
                </tbody>
            </table>
        </section>
    </section>
</template>
<script charset="utf-8">
    import ToolsService from "@/services/tools.service"
    import moment from "moment";
    export default {
        name: "LastLogins",
        data() {
            return {
                data: []
            }
        },

        async mounted() {
            await this.fetchUserLogins();
        },

        methods: {
            async fetchUserLogins() {
                try {
                    this.$store.commit("showLoader");
                    let response = await ToolsService.getUserLastLoginsDetails();
                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }
                    this.data = response.data;
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            formatDate(date) {
                try {
                    let humanDate = moment(date).format("dddd, DD MMMM YYYY HH:mm");
                    let duration = moment(date).fromNow();
                    return `${duration}, w ${humanDate}`
                }
                catch {
                    return date;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/user-profile-component.scss";

    .component {
        width: 450px;
        height: fit-content;
    }

    .last-logins {
        .login-info-row td {
            padding: 10px;
            font-size: 0.8em;
        }
    }

</style>