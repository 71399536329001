<template>
    <section class="attachment-viewer" @wheel="wheeled" ref="attachInternaModal" tabindex="0" @keyup="keyPressed">
        
        <section class="attachment-viewer--body--content">
            <section class="scale-buttons" v-if="fileType.isPfd" ref="scaleButton">
                <section style="display: flex; align-items: baseline;">
                    <button class="attachment-viewer--button attachment-viewer--button--small" @click="scaleUp" :disabled="isBusy" style="margin-right: 2px;">+</button>
                    {{ scale }}%
                    <button class="attachment-viewer--button attachment-viewer--button--small" @click="scaleDown" :disabled="isBusy || scale <= firsRescaledPdfValue">-</button>
                </section>
                <section style="display: flex; align-items: baseline;">

                    <button v-tooltip="'Poprzednia strona'" class="attachment-viewer--button attachment-viewer--button--small" style="margin-right: 20px;" v-if="fileType.isPfd" @click="prevPage" :disabled="isBusy">
                        <img src="@/assets/icons/panel/editSession/arrow-left.svg" />
                    </button>

                    <input id="changeRange" type="range" v-model="range" @change="rangeChange" :min="firsRescaledPdfValue" max=250 style="width: 350px" />

                    <button v-tooltip="'Następna strona'" class="attachment-viewer--button attachment-viewer--button--small" style="margin-left: 20px;" v-if="fileType.isPfd" @click="nextPage" :disabled="isBusy">
                        <img src="@/assets/icons/panel/editSession/arrow-left.svg" style="transform: rotate(180deg);" />
                    </button>

                </section>
            </section>
            <section class="attachment-viewer--body--content--interactive" ref="containerAttachment">
                <VuePDF :id="pdfFIle" :pdf="fileBlob" :page="page" :scale="(scale / 100)" v-if="fileType.isPfd"
                    intent="display" @loaded="pdfLoaded" ref="pdfAttachment">
                </VuePDF>

                <img :src="imageData" v-else-if="fileType.isImage" ref="imageAttachment"
                    style="max-width: 95vw; max-height: 95vh" />

                <textarea v-else-if="fileType.isText" v-model="textData" style="height:80vh; width: 95vw;"
                    ref="textAttachment">
                </textarea>
            </section>
            <section class="attachment-viewer--footer">
                <span v-if="fileType.isPfd">
                    Strona {{ page }} z {{ maxPages }}
                </span>
            </section>
        </section>
       
    </section>
</template>
<script charset="utf-8">
import { VuePDF, usePDF } from '@tato30/vue-pdf'
import attachmentsService from '../../../../services/attachments.service';

export default {
    name: "AttachmentViewer",
    components: {
        VuePDF,
    },
    data() {
        return {
            pdfFIle: null,
            viewer: {
                attachmentId: -1,
                sessionId: -1,
                pointId: -1,
                isInteractive: false,
            },
            fileType: {
                isPfd: false,
                isImage: false,
                isText: false,
            },
            range: 100,
            scaleStep: 10,
            page: 1,
            maxPages: 1,
            scale: 100,
            isFromRange: false,
            fileBlob: undefined,
            imageData: undefined,
            textData: undefined,
            lastPdfPageSize: {
                height: -1,
                width: -1
            },
            isBusy: false,
            isFirstRun: true,
            currentWindowSize: {
                width: -1,
                height: -1,
            },
            firsRescaledPdfValue: 100,
        }
    },
    watch:{
        scale:{
            handler(val){
                this.range = val
            },
            immediate: true
        }
    },
    computed: {
        attachmentId() {
            return parseInt(this.$route.params.attachmentId);
        }
    },
    async mounted() {
        try {
            this.getCurrentSize();
            this.parseRequest();
            await this.internalFetchAttachment();
            this.notifyParentWindow();
            this.$refs.attachInternaModal.focus();
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.$store.commit("hideLoader");
        }
    },
    methods: {
        parseRequest() {
            this.viewer.attachmentId = this.attachmentId;
            this.viewer.sessionId = parseInt(this.$route.query.sessionId);
            this.viewer.pointId = parseInt(this.$route.query.pointId);
            this.viewer.isInteractive = (this.$route.query.isInteractive.toLowerCase() === "true");
        },
        async notifyParentWindow() {
            try {
                if (this.fileType.isImage) {
                    const img = new Image();
                    img.src = this.imageData;
                    img.onload = () => {
                        this.sentDataToParent({ width: img.width, height: img.height });
                    }
                }
                else if (this.fileType.isText) {
                    this.sentDataToParent({ width: window.parent.innerWidth * 0.45, height: window.parent.innerHeight * 0.80 });
                }

            }
            catch (e) {
                console.log(e);
            }
        },
        async sentDataToParent(data) {
            if (window.parent !== undefined && window.parent !== null) {
                window.parent.postMessage(data);
            }
        },
        async internalFetchAttachment() {
            let response = await attachmentsService.downloadAttachment(this.viewer.pointId, this.viewer.attachmentId);
            this.resolveAttachmentType(response);
            await this.resolveFile(response);

        },
        resolveAttachmentType(response) {
            if (response.headers['content-type'] === 'application/pdf') {
                this.fileType.isPfd = true;
                this.fileType.isImage = false;
                this.fileType.isText = false;
            }
            else if (response.headers['content-type'].startsWith("image/")) {
                this.fileType.isPfd = false;
                this.fileType.isImage = true;
                this.fileType.isText = false;
            }
            else if (response.headers['content-type'].startsWith("text/plain")) {
                this.fileType.isPfd = false;
                this.fileType.isImage = false;
                this.fileType.isText = true;
            }
        },
        async resolveFile(response) {
            if (this.fileType.isPfd) {
                const { pdf, pages } = await usePDF(response.data);
                this.fileBlob = pdf;
                this.maxPages = pages;
            }
            else if (this.fileType.isImage) {
                let blob = new Blob([response.data], { type: response.headers['content-type'] });
                let urlCreator = window.URL || window.webkitURL;
                this.imageData = await urlCreator.createObjectURL(blob);
            }
            else if (this.fileType.isText) {
                let blob = new Blob([response.data], { type: response.headers['content-type'] });
                this.textData = await blob.text()
            }
        },
        getCurrentSize() {
            this.currentWindowSize.width = window.parent.innerWidth * 0.85;
            this.currentWindowSize.height = window.parent.innerHeight * 0.85;
        },
        reScalePdf() {
            let scaleButton = this.$refs.scaleButton;
            let scaleButtonHeight = 0;
            if (scaleButton !== undefined) {
                scaleButtonHeight = scaleButton.clientHeight;
            }

            let maxHeight = this.currentWindowSize.height - scaleButtonHeight - 30;

            this.scale = Math.round((maxHeight / this.lastPdfPageSize.height) * 100);
            this.firsRescaledPdfValue = this.scale;
            this.range = this.firsRescaledPdfValue;
        },
        pdfLoaded(e) {
            this.isBusy = false;
            
            if (this.isFirstRun) {
                this.lastPdfPageSize = e;
                this.reScalePdf();
                this.isFirstRun = false;
                this.sentDataToParent({ width: (parseInt(e.width + 150)), height: (parseInt(e.height + 100)), force: false })
            }

            this.isFirstRun = false;
            this.isFromRange = false;
        },
        prevPage() {
            if (this.isBusy === true) {
                return;
            }

            if (this.page <= 1) {
                return;
            }

            this.isBusy = true;
            this.page--;

            let internalScale = this.scale / 100;
            this.sentDataToParent({ width: (parseInt(this.lastPdfPageSize.width * internalScale + 150)), height:  (parseInt(this.lastPdfPageSize.height + 100)), force: true, prevPage: true, nextPage: undefined, page: this.page, scale: this.scale });
        },

        nextPage() {
            if (this.isBusy === true) {
                return;
            }

            if (this.page >= this.maxPages) {
                return;
            }

            this.isBusy = true;
            this.page++;

            let internalScale = this.scale / 100;
            this.sentDataToParent({ width: (parseInt(this.lastPdfPageSize.width * internalScale + 150)), height: (parseInt(this.lastPdfPageSize.height + 100)), force: true, prevPage: undefined, nextPage: true, page: this.page, scale: this.scale });
        },

        scaleUp() {
            if (this.isBusy === true) {
                return;
            }

            this.isBusy = true;
            this.isFromRange = true;
            this.scale += this.scaleStep;
            if(this.scale >= 250){
                this.scale = 250;
                this.isBusy = false;
            }

            let internalScale = this.scale / 100;
            this.sentDataToParent({ width: (parseInt(this.lastPdfPageSize.width * internalScale + 150)), height: (parseInt(this.lastPdfPageSize.height + 100)), force: true, page: this.page, scale: this.scale })
        },

        scaleDown() {
            if (this.isBusy === true) {
                return;
            }

            this.isBusy = true;
            this.isFromRange = true;
            this.scale -= this.scaleStep;
            if (this.scale <= this.firsRescaledPdfValue) {
                this.scale = this.firsRescaledPdfValue;
                this.isBusy = false;
            }

            let internalScale = this.scale / 100;
            this.sentDataToParent({ width: (parseInt(this.lastPdfPageSize.width * internalScale + 150)), height: (parseInt(this.lastPdfPageSize.height + 100)), force: true, page: this.page, scale: this.scale })
        },

        scroolView(percent) {
            this.sentDataToParent({ scroolView: percent });
        },

        closeModal() {
            this.sentDataToParent({ closeModal: true });
        },

        keyPressed(e) {
            if (!e.isTrusted) {
                return;
            }
            if (e.key === undefined) {
                return;
            }
            if (e.keyCode === undefined) {
                return;
            }
            if (this.isBusy === true) {
                return;
            }

            e.preventDefault();

            switch (e.keyCode) {
                case 38:
                case 40:
                    if (this.scale > this.firsRescaledPdfValue)
                    {
                        var percent = this.calculateScroolValue();
                        this.scroolView(percent);
                    }
                    break;
                case 37:
                    if (this.scale <= this.firsRescaledPdfValue)
                        this.prevPage();
                    break; //arrow left;
                case 39:
                    if (this.scale <= this.firsRescaledPdfValue)
                        this.nextPage();
                    break; //arrow right
                case 36:
                    this.page = 1;
                    this.isBusy = true;
                    break; // home
                case 27:
                    this.closeModal();
                    break;// escape
            }
        },

        calculateScroolValue() {
            const scrollTop = window.scrollY;
            // Get the total scrollable height
            const totalHeight = document.body.scrollHeight;
            // Get the height of the viewport
            const viewportHeight = window.innerHeight;
            // Calculate the total scrollable distance
            const scrollableDistance = totalHeight - viewportHeight;
            // Calculate the scroll percentage
            const scrollPercentage = (scrollTop / scrollableDistance) * 100;
            // Return the scroll percentage
            return Math.round(scrollPercentage);
        },

        wheeled(e) {
            if (!this.fileType.isPfd) {
                return;
            }

            if (this.isBusy === true) {
                return;
            }

            if (this.scale > this.firsRescaledPdfValue) {
                var percent = this.calculateScroolValue();
                this.scroolView(percent);
                return;
            }
                
            if (e.deltaY > 0) {
                this.nextPage();
            }
            else {
                this.prevPage();
            }
        },
        rangeChange(e) {
            e.preventDefault();

            const elem = document.getElementById("changeRange");
            elem.blur();

            this.isFromRange = true;
            this.scale = parseInt(e.target.value);
            let internalScale = this.scale / 100;

            this.sentDataToParent({ width: (parseInt(this.lastPdfPageSize.width * internalScale + 150)), height: (parseInt(this.lastPdfPageSize.height + 100)), force: true, page: this.page, scale: this.scale })
        }
    }
}
</script>
<style lang="scss">
.attachment-viewer {
    background: transparent;
    display: flex;
    flex-direction: row;
    column-gap: 10px;

    &--body {
        background: transparent;

        &--content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .scale-buttons {
                padding-top: 10px;
                z-index: 999;
                display: flex;
                align-items: center;
                justify-items: center;
                justify-content: center;
                flex-direction: column;
            }

            &--interactive {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                // width: calc(100vw - 100px);
                // height: calc(100vh - 50px);
            }

        }

        &--prev,
        &--next {
            z-index: 999;
            background: transparent;
            display: flex;
            align-items: center;
        }

        &--next {
            margin-right: 10px;
        }
    }

    &--footer{
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
    }
}
</style>