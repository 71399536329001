<template>
    <section class="calendar-component" ref="calendarComponent">
        <section class="calendar-component--header">
            <section class="calendar-component--header--left">
                <CalendarCurrentMonth />
            </section>
            <section class="calendar-component--header--right">
                <section class="change-month">
                    <ButtonCalendarLeft @prevMonth="previousMonth" width=30 />
                    <ButtonCalendarRight @nextMonth="nextMonth" width=30 />
                </section>
            </section>
        </section>
        <section class="calendar-component--body">
            <section class="calendar-grid">
                <section class="calendar-grid--header">
                    <section class="calendar-grid--header--weekday" v-for="(weekDay, index) in availableWeekDays"
                        :key="index">
                        {{ weekDay.display }}
                    </section>
                </section>
                <section class="calendar-grid--slots" v-if="dataArray.length > 0">
                    <CalendarDayGrid v-for="(day, index) in dataArray" :key="index" :dayObj="day"
                        @contextmenu="(e) => openContextMenu(e, day)" />
                </section>
                <CalendarContextMenu v-if="isShowContextMenu" @addMetting="addMetting"
                    @addAnnoucement="addAnnoucement" />
            </section>
        </section>
        <Transition>
            <AnnouncementModal v-if="announcementModal.isOpen"
                @closeAnnoucement="() => announcementModal.isOpen = false"
                @updateCalendar="() => { internalInit(); announcementModal.isOpen = false }"
                :date="announcementModal.date" :options="announcementModal.annoOptions"
                :options2="announcementModal.annoOptions2" 
                :selected="announcementModal.selectedEmail" 
                :selected2="announcementModal.selectedEmail"
                :elemToUpdate="announcementModal.dummy"/>
        </Transition>
        <SmallLoader v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
// services
import calendarService from "../../../../services/calendar.service";
import contactService from "../../../../services/contact.service";

// components
import CalendarDayGrid from "./CalendarDayGrid.vue"
import CalendarCurrentMonth from "./CalendarCurrentMonth.vue";
import SmallLoader from "../../../BasicControls/SmallLoader.vue";
import ButtonCalendarLeft from "../../../BasicControls/Buttons/ButtonCalendarLeft.vue";
import ButtonCalendarRight from "../../../BasicControls/Buttons/ButtonCalendarRight.vue";
import CalendarContextMenu from "./CalendarContextMenu.vue";
import AnnouncementModal from "../Home/Modals/AddAnnouncement.vue"
import { encryptStorage } from "../../../../services/encrypt.service";

export default {
    name: "CalendarComponent",
    components: {
        CalendarDayGrid,
        CalendarCurrentMonth,
        SmallLoader,
        ButtonCalendarLeft,
        ButtonCalendarRight,
        CalendarContextMenu,
        AnnouncementModal
    },
    data() {
        return {
            isLoading: false,
            announcementModal: {
                isOpen: false,
                hasModule: encryptStorage.getItem('modules').inf === 1 ? true : false,
                date: '',
                annoOptions: [],
                annoOptions2: [],
                selectedEmail: [],
                dummy: {
                    id: 0,
                    author: "",
                    description: "",
                    isInCalendar: false,
                    meetingDate: this.datem,
                    status: 3,
                    title: "",
                    isEdit: false,
                }
            }
        }
    },
    computed: {
        availableWeekDays() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.$store.state.calendarStore.weekDays.sort((a, b) => {
                if (a.order > b.order) {
                    return 1;
                }
                else {
                    return -1;
                }
            });
        },
        dataArray() {
            return this.$store.state.calendarStore.dataArray;
        },
        isShowContextMenu() {
            return this.$store.state.calendarStore.isShowContenxtMenu;
        }
    },
    watch: {
        '$store.state.appStore.groups': {
            deep: true,
            handler: async function () {
                await this.internalInit();
            }
        }
    },
    async mounted() {
        await this.internalInit();
    },
    methods: {
        async internalInit() {
            try {
                this.isLoading = true;
                await calendarService.init();
            }
            finally {
                this.isLoading = false;
            }
        },
        async previousMonth() {
            try {
                this.isLoading = true;
                await calendarService.previousMonth();
            }
            finally {
                this.isLoading = false;
            }
        },
        async nextMonth() {
            try {
                this.isLoading = true;
                await calendarService.nextMonth();
            }
            finally {
                this.isLoading = false;
            }
        },
        openContextMenu(e, day) {
            e.preventDefault();
            this.$store.state.calendarStore.contextMenuPos.x = e.clientX - this.$refs.calendarComponent.offsetLeft + 10;
            this.$store.state.calendarStore.contextMenuPos.y = e.clientY - this.$refs.calendarComponent.offsetTop;
            let maxWidth = this.$store.state.calendarStore.contextMenuPos.x + 270 + 320 + 100;
            if (maxWidth >= window.screen.availWidth) {
                this.$store.state.calendarStore.contextMenuPos.x -= 270 + 10
            }
            this.$store.state.calendarStore.contextMenuDate = day.date;
            this.$store.state.calendarStore.isShowContenxtMenu = true;
        },
        addMetting() {
            this.$store.commit("setMeetingDate", `${this.$store.state.calendarStore.contextMenuDate.getMonth() + 1}.${this.$store.state.calendarStore.contextMenuDate.getDate()}.${this.$store.state.calendarStore.contextMenuDate.getFullYear()}`);
            //this.$store.commit("setMeetingDate", this.$store.state.calendarStore.contextMenuDate);
            this.$store.commit("showAddMeeting");
            this.$store.state.calendarStore.contextMenuDate = null;
            this.$store.state.calendarStore.isShowContenxtMenu = false;
        },
        async addAnnoucement() {
            try {
                this.$store.commit("showLoader");
                this.$store.state.calendarStore.isShowContenxtMenu = false;
                if (this.announcementModal.hasModule === false) {
                    return;
                }

                this.announcementModal.dummy.meetingDate = `${this.$store.state.calendarStore.contextMenuDate.getMonth() + 1}.${this.$store.state.calendarStore.contextMenuDate.getDate()}.${this.$store.state.calendarStore.contextMenuDate.getFullYear()}`;

                var response = await contactService.getUsersToSmsOrEmail(3);

                if (response.status !== 200) {
                    throw await Promise.reject(response);
                }


                this.announcementModal.annoOptions = [];
                this.announcementModal.annoOptions2 = [];

                for (let i = 0; i < response.data.length; i++) {
                    this.announcementModal.annoOptions.push({
                        value: response.data[i].id,
                        label: response.data[i].label
                    })
                    for (let j = 0; j < response.data[i].options.length; j++) {
                        if (this.announcementModal.annoOptions2.find(x => x.value == response.data[i].options[j].value) == null) {
                            this.announcementModal.annoOptions2.push(response.data[i].options[j]);
                        }
                    }
                }

                this.announcementModal.isOpen = true;
            }
            catch (e) {
                if (e.response !== undefined) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            }
            finally {
                this.$store.commit('hideLoader');
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.calendar-component {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;

    &--header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &--body {
        flex-grow: 1;
        display: flex;
    }
}

.calendar-grid {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &--header {
        display: flex;
        flex-direction: row;
        padding: 10px 00px;
        background: linear-gradient(90.71deg, #00082c 0%, #004dcc 150.66%);
        //justify-content: space-evenly;

        &--weekday {
            color: white;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            text-align: center;
            flex: 0 0 calc((100% / 7) - 2px); // 100%  / liczba dni w tygodni - grubość obraowania
            //flex-grow: 1;
        }
    }

    &--slots {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
    }
}

.change-month {
    display: flex;
    flex-direction: row;
}
</style>