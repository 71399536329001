<template>
    <section class="esesja-footer">
        <span class="esesja-footer__text">eSesja © 2023 Wszelkie prawa zastrzeżone <br /><!--Projekt i wykonanie:<img src="../assets/imgs/logo-mda.png"/>--></span>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "eSesjaFooter"
    }
</script>
<style lang="scss">
    .esesja-footer {
        &__text {
            margin-bottom: 18px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            color: #afb8c6;
        }
    }

    .page-DiscussionQuestList {
        .esesja-footer {
            &__text {
                color: #000;
            }
        }
    }
</style>