<template>
    <section class="component" style="position: relative">
        <section class="component__header">
            <span class="component__header__title">
                Dane autoryzacyjne dla aplikacji integracyjnych:
            </span>
        </section>
        <section class="component__body settings">
            <section class="settings__item">
                <p class="paragraph">RID: <input class="input-type-label" id="rid" type="password" disabled v-model="rid" /> <span class="login-form__button-show" @click="handleClickShow('rid')"> <img src="../../../../../assets/icons/eye.svg" /></span></p>
                <p class="paragraph">KID: <input class="input-type-label" id="kid" type="password" disabled v-model="kid" /> <span class="login-form__button-show" @click="handleClickShow('kid')"> <img src="../../../../../assets/icons/eye.svg" /></span></p>
                <p class="paragraph">KOD/TOKEN: <input class="input-type-label" id="kod" type="password" disabled v-model="kod" /> <span class="login-form__button-show" @click="handleClickShow('kod')"> <img src="../../../../../assets/icons/eye.svg" /></span></p>
                <p class="info">Pamiętaj o ochronie danych wrażliwych i nie udostępniaj ich osobom trzecim.</p>
                
            </section>
        </section>
        <SmallLoader v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
    import SettingsService from "../../../../../services/settings.service";
    import SmallLoader from "../../../../BasicControls/SmallLoader.vue";

    export default {
        name: "IntegrationInportantDataSettings",
        components: {
            SmallLoader
        },
        data() {
            return {
                isLoading: false,
                kid: '',
                rid: '',
                kod: '',
            }
        },
        async mounted() {
            await this.getIntegrationAuthData();
        },
        methods: {
            async getIntegrationAuthData() {
                SettingsService.getIntegrationAuthData().then((response) => {

                    if (response.status !== 200) {
                        this.kid = '';
                        this.rid = '';
                        this.kod = '';
                    }

                    this.kid = response.data.kid;
                    this.rid = response.data.rid;
                    this.kod = response.data.kod;

                }).catch(() => {
                    this.kid = '';
                    this.rid = '';
                    this.kod = '';
                });
            },

            handleClickShow(elem) {
                const passwordElement = document.getElementById(elem);
                passwordElement.type = passwordElement.type === "password" ? "text" : "password";
            },
        }
    }
</script>
<style lang="scss">
    @import "@/assets/scss/user-profile-component.scss";

    .settings {
        padding: 15px 10px;
    }

    .input-type-label {
        border: 1px solid rgba(0,0,0,0);
        background-color: white;
    }

    .paragraph {
        padding: 5px;
    }

    .login-form__button-show {
        cursor: pointer;
        float: right;
    }

    .info {
        text-wrap: wrap;
        max-width: 420px;
        text-align: center;
        margin-top: 30px;
    }

</style>