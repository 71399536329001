<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
    <section class="search-results-container">
        <perfect-scrollbar class="search-results-container-body">
            <div v-if="resultsList.length > 0" v-for="(item, index) in resultsList" class="search-results-container-body-item" :key="index">
                <SearchSessionItem :item="item" v-if="parseInt(item.resourceType) === 0" @closeSearch="closeSearch" />
                <SearchAgendaItem :item="item" v-if="parseInt(item.resourceType) === 10" @closeSearch="closeSearch" />
                <SearchPersonItem :item="item" v-if="parseInt(item.resourceType) === 20" @closeSearch="closeSearch" />
                <SearchAnnouncementItem :item="item" v-if="parseInt(item.resourceType) === 30" @closeSearch="closeSearch" />
            </div>
        </perfect-scrollbar>
    </section>
</template>
<script charset="utf-8">
    import SearchAgendaItem from "./SearchEngine/SearchAgendaItem.vue"
    import SearchAnnouncementItem from "./SearchEngine/SearchAnnouncementItem.vue"
    import SearchPersonItem from "./SearchEngine/SearchPersonItem.vue"
    import SearchSessionItem from "./SearchEngine/SearchSessionItem.vue"


    export default {
        name: "SearchResults",
        components: {
            SearchSessionItem,
            SearchPersonItem,
            SearchAgendaItem,
            SearchAnnouncementItem
        },
        props: {
            resultsList: {
                type: Array,
                require: true
            }
        },
        methods: {
            closeSearch() {
                this.$emit("closeAllSearchSection");
            },
        }
    }
</script>
<style lang="scss">
    .search-results-container {
        background: #fff;
        position: absolute;
        z-index: 10;
        border-bottom: 1px solid #d1d6df;
        border-left: 1px solid #d1d6df;
        border-right: 1px solid #d1d6df;
        margin-top: -3px;
        box-shadow: 1px 3px 3px rgba(0, 8, 44, 0.08);


        &-body {
            max-height: 50vh;

            &-item {
                padding: 5px 10px;
                width: 539px;

                &:nth-of-type(even) {
                    background-color: #eee;
                }

                &:hover {
                    background-color: #f4f6fa;
                }
            }
        }
    }
</style>