<template>
    <section class="esesja-search-component-select">
        <select class="esesja-search-component-select__control" v-model="selecteModel">
            <option class="esesja-search-component-select__option"
                    v-for="(item, index) in internalOptions" 
                    :key="index"
                    :value="item">
                    {{item.label}}
            </option>
        </select>
    </section>
</template>
<script charset="utf-8">
    import CommonHelper from "../../helpers/common.helper"
    export default {
        name: "SearchSelectCoponent",
        props: {
            modelValue: {
                type: Object,
                required: true,
                default() {
                    return {
                        label: "",
                        value: null,
                    }
                }
            },
            options: {
                type: Array,
                required: true,
                default: () => [{value: null, label: "---"}]
            },
            placeholder: {
                type: String,
                default() {
                    return ""
                }
            },
            disabled: {
                type: Boolean,
                default() {
                    return false
                }
            },
            width: {
                type: String,
                debug() {
                    return '290px';
                }
            }
        },
        data() {
            return {
                selecteModel: [],
                internalOptions: [],
                emptyOptions: {
                    label: this.placeholder,
                    value: null,
                },
            }
        },
        watch: {
            selecteModel: {
                handler(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            modelValue: {
                handler(value) {
                    this.selecteModel = value;
                }
            },
            options: {
                immediate: true,
                handler(value) {
                    this.internalOptions = [...value];
                    this.internalOptions.push(this.emptyOptions);
                }
            }
        },
        mounted() {
            if (CommonHelper.isObjectIsEmpty(this.modelValue)) {
                this.selecteModel = this.emptyOptions;
            }
            else {
                this.selecteModel = this.modelValue;
            }
                
        }
    }
</script>
<style lang="scss">
    .esesja-search-component-select {
        display: flex;
        align-items: center;

        &__control {
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            padding: 8px 10px 8px 16px;
            font-weight: normal;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url('~@/assets/icons/components/select-arrow.svg');
            background-repeat: no-repeat;
            background-position-x: calc(100% - 14px);
            background-position-y: 50%;
            min-width: 290px;
            min-height: 48px;

            &:focus {
                border: 1px solid #00082c;
            }
        }
    }
</style>