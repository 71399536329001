import ApiService from "./api.service";

class ReportBugService extends ApiService {
    GetTypes() {
        return this.get('report-bug/types');
    }

    Create(model) {
        return this.post('report-bug', model);
    }
}
export default new ReportBugService();