<template>
    <section class="app-spa">
        <section class="app-spa__box">
            <section class="app-spa__box__logo">
                <img src="@/assets/imgs/logo.png" />
            </section>
            <section class="app-spa__box__form">
                <h1 class="app-spa__box__form__header">Aplikacja zablokowana</h1>
                <p class="app-spa__box__form__text">
                    Aby odblokować podaj swój kod PIN
                </p>
                <section class="app-spa__box__form__fallback-error" v-if="isError">
                    <img src="@/assets/icons/components/exit-btn.svg" class="app-spa__box__form__fallback-error__icon" alt="Ikona błędu" />
                    <span class="app-spa__box__form__fallback-error__text">
                        Sprawdź poprawność wpisanych danych
                    </span>
                </section>
                <section class="app-spa__box__form__row">
                    <label for="user-pin" class="app-spa__box__form__label">PIN</label>
                    <input type="password"
                           name="user-pin"
                           id="user-pin"
                           class="app-spa__box__form__input"
                           v-model="pin"
                           placeholder="Wpisz swój PIN"
                           autocomplete="off"
                           @keyup.enter="unlockApplication" />
                </section>
                <button type="submit"
                        class="app-spa__box__form__btn"
                        @click="unlockApplication">
                    Odblokuj aplikację
                </button>
                <button type="button"
                        class="app-spa__box__form__btn"
                        @click="logoutUser">
                    Wyloguj
                </button>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    import UserService from "../../services/user.service"
    export default {
        name: "AppLocked",
        data() {
            return {
                pin: "",
                isError: false,
            }
        },
        methods: {
            async unlockApplication(e) {
                try {
                    e.preventDefault();
                    this.isError = false;
                    this.$store.commit("showLoader");

                    let request = {
                        pin: this.pin
                    };
                    let response = await UserService.unlokcApplication(request);
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }
                    this.isError = !response.data;
                    if (response.data === true) {
                        UserService.updateLockState(false);
                        return;
                    }
                }
                catch (e) {
                    this.isError = true;
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }

            },
            async logoutUser() {
                await UserService.logout();
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "~@/assets/scss/esesja-app-spa.scss";
</style>
