<template>
    <section class="notification-item">
        <section class="notification-item__icon">
            <img src="@/assets/icons/components/tooltip.svg" style="width: 24px;" />
        </section>
        <section class="notification-item__body">
            <section class="notification-item__body__title">
                Aktualizacja eSesji
            </section>
            <section class="notification-item__body__description">
                Sprawdź najnowsze aktualizacje aplikacji eSesja, które dla Ciebie przygotowaliśmy.
            </section>
            <section class="notification-item--center">
                <button class="notification-item__button notification-item__button--small notification-item__button--white"
                        @click="goToHistoryPage()">
                    <span class="notification-item__button__text">Kliknij i dowiedz się więcej</span>
                </button>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "ChangeInAppHistory",
        methods: {
            goToHistoryPage() {
                this.$router.push({ name: 'versions-history' });
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/notification-item.scss';
</style>