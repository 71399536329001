export default class UploaderFileValidationEnum {
    static FileToBig = new UploaderFileValidationEnum("FILE_TO_BIG", "Przekazany plik jest za duży", "Przekazany plik jest za duży. Maksymalny rozmiar pliku to: {0}");
    static UnsupportedMediaType = new UploaderFileValidationEnum("UNSUPPORTED_MEDIA_TYPE", "Niedozwolony format pliku", "Niedozwolony format pliku. Dozwolne formaty to: {0}");
    static UnknownError = new UploaderFileValidationEnum("UNKNOWN_ERROR", "Nieznany bład", "Wystąpił nieznany błąd");
    static FileNameToLongError = new UploaderFileValidationEnum("FILE_NAME_TO_LONG", "Za długa nazwa pliku", "Za długa nazwa pliku. Maksymalna długość nazwy wynosi: {0}");
    static FileNameToShortError = new UploaderFileValidationEnum("FILE_NAME_TO_SHORT", "Za krótka nazwa pliku", "Za krótka nazwa pliku. Minimalna długość nazwy wynosi: {0}");

    constructor(label, reason, description) {
        this.label = label;
        this.reason = reason;
        this.description = description;
    }
}