<template>
    <section class="esesja-error-message d-flex flex-column gap-2">
        <section>
            <strong>W formularz są następujące błędy:</strong>
        </section>
        <section>
            <ul>
                <li v-for="(item, index) in errors" :key="index">{{ item.errorMessage }}</li>
            </ul>
        </section>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "ValidationErrors",
        props: {
            errors: {
                type: Array,
            }
        }
    };
</script>
<style lang="scss" scoped>
    .esesja-error-message {
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 4px;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;

       /* &__row {
            display: flex;
            flex-direction: row;
            column-gap: 10px;
        }*/
    }
</style>