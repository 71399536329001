export const interpellationsStore = {
    state: () => ({
        newInterpellationsCount: 0,
        interpellatinMenuElementId: 0,
        isInterpellationAccess: false,
    }),
    mutations: {
        setNewInterpellationsCount(state, count) {
            state.newInterpellationsCount = count;
        },
        seInterpellationsMenuId(state, id) {
            state.interpellatinMenuElementId = id;
        },
        seInterpellationsIsAccess(state, status) {
            state.isInterpellationAccess = status;
        },
    }
}