<template>
    <section class="mwc-view">
        <section class="mwc-view__text">
            Trwa logowanie pracownika MWC na konto klienta
        </section>
    </section>
</template>
<script charset="utf-8">
    import userService from "../../../services/user.service";
    export default {
        name: "MwcView",
        computed: {
            mwcCode() {
                return this.$route.params.code
            }
        },
        async mounted() {
            try {
                this.$store.commit('showLoader');
                await this.internalAuthMwc();
            }
            finally {
                this.$store.commit('hideLoader');
            }
        },
        methods: {
            async internalAuthMwc() {
                await userService.mwcLogin(this.mwcCode);
            }
        }
    }
</script>
<style lang="scss">
    .mwc-view {
        padding: 48px 40px 56px 40px;
        display: flex;
        flex-direction: column;
        background: linear-gradient(146.23deg, #d5e0f5 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;

        &__text {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            margin-top: 12px;
            color: #333956;
        }
    }
</style>