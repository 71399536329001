<template>
    <section class="esesja-input-component" :class="{ 'has-error': isError }" v-tooltip="errorTooltip">
        <input :type="type"
               :placeholder="placeholder"
               :disabled="disabled"
               class="esesja-input-component__input"
               v-model="data"
               @blur="$emit('blured')"
               @focus="$emit('focused')" />
        <img src="@/assets/icons/components/error-alert.svg"  />
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "InputComponent",
        props: {
            modelValue: {
                type: String,
                default: '',
                required: true
            },
            type: {
                type: String,
                default: "text",
            },
            placeholder: {
                type: String,
                default: ""
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            isError: {
                type: Boolean,
                default: false,
            },
            errorTooltip: {
                type: String,
                default: "",
            }
        },
        data() {
            return {
                data: ""
            }
        },
        watch: {
            data: {
                handler(val) {
                    this.$emit('update:modelValue', val)
                },
            },
            modelValue: {
                handler(newValue) {
                    this.data = newValue;
                }
            },
        },
        mounted() {
            this.data = this.modelValue;
        }
    }
</script>
<style lang="scss" scoped>
    .esesja-input-component {
        display: flex;
        width: 100%;
        position: relative;

        &__input {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            box-sizing: border-box;
            padding: 12.5px 16px;
            width: 100%;

            &:focus {
                border: 1px solid #00082c;
            }
        }
        img{
            display: none;
        }
    }

    .has-error {
        .esesja-input-component__input {
            border-color: red;

            &::before {
                content: "a"
            }
        }

        img {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(-50%, -50%);
        }
    }
</style>