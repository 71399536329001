<template>
    <section class="add-group">
        <form class="add-group-form">
            <button class="add-group-form__close" @click="(e) => handler(e, 'none')" >
                <img src="../../../../../../assets/icons/components/exit-btn.svg" />
            </button>
            <section class="add-group-form__form-wrapper">
                <h3 class="add-group-form__title">
                    {{ isEdit ? "Edytuj" : "Dodaj" }} grupę
                </h3>
                <label class="add-group-form__label">Nazwa grupy</label>
                <ErrorMessage
                    v-if="isError('name', this.errors)"
                    :errorMsgs="passErrorMsg('name', this.errors)"
                />
                <input 
                    type="text"
                    class="add-group-form__input-text"
                    v-model="groupName"
                    v-error="{ errors, input_key: 'name' }"
                />
                <p class="add-group-form__sub-title">Wybierz opcje dla grupy</p>
                <section class="checkbox-section">
                    <section class="checkbox-section__checkbox">
                        <section class="checkbox-section__checkbox-wrapper">
                            <CheckBoxButton value="posiedzenia"
                                            id="posiedzenia"
                                            :checked="canSession" @click="this.canSession = !this.canSession" />
                            <label class="add-group-form__label--checkbox"
                                   @click="this.canSession = !this.canSession">Obsługa posiedzeń</label>
                        </section>


                        <label v-if="canSession" class="meetings-options__label">Domyślna nazwa posiedzenia</label>

                        <input v-if="canSession" type="text"
                               class="meetings-options__input-text"
                               v-model="session_default_name" />

                        <label v-if="canSession" class="meetings-options__label">Domyślna sygnatura</label>

                        <input v-if="canSession" type="text"
                               class="meetings-options__input-text"
                               v-model="session_default_signature" />

                        <label v-if="canSession" class="meetings-options__label">Domyślne miejsce posiedzenia</label>

                        <textarea rows="3" v-if="canSession"
                                  placeholder="Wpisz miejsce posiedzenia..."
                                  class="meetings-options__input-text"
                                  v-model="session_default_place"></textarea>

                        <section v-if="canSession" class="meetings-options__label">
                            <div class="form-bootstrap-check form-bootstrap-switch">
                                <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="public-attachement" v-model="session_default_attachement_public" />
                                <label class="form-bootstrap-check-label" for="public-attachement">Domyślnie publiczny załącznik</label>
                                <section name="externalTrigger2"
                                         class="tip-btn">
                                    <img src="../../../../../../assets/icons/components/tooltip.svg"
                                         class="tooltip" />
                                    <Hint style="left: calc(100% - 300px)" title="Publiczny załącznik"
                                          text="Aktywny oznacza, że dodawany załącznik do posiedzenia domyślnie będzie publiczny." />
                                </section>
                            </div>
                        </section>

                        <section class="checkbox-section__checkbox-wrapper" v-if="canSession">
                            <CheckBoxButton value="posiedzenia_numeracja"
                                            id="posiedzenia_numeracja"
                                            :checked="is_sessions_numbered"
                                             @click="this.is_sessions_numbered = !this.is_sessions_numbered"/>
                            <label class="add-group-form__label--checkbox"
                                   @click="this.is_sessions_numbered = !this.is_sessions_numbered">Numeracja posiedzeń i punktów porządku obrad</label>
                        </section>


                        <MeetingsOprions v-if="canSession"
                                         :isSessionNumbered="is_sessions_numbered"
                                         :sessionNumberFormat="session_number_format"
                                         :agendaNumberFormat1="agenda_number_format_1_id"
                                         :agendaNumberFormat2="agenda_number_format_2_id"
                                         :agendaNumberFormat3="agenda_number_format_3_id"
                                         :errors="errors"
                                         @setIsSessionNumbered="(value) => (this.is_sessions_numbered = value)"
                                         @setSessionNumberFormat="(value) => {this.session_number_format = value;}"
                                         @setAgendaNumberFormat1="(value) => (this.agenda_number_format_1_id = value)"
                                         @setAgendaNumberFormat2="(value) => (this.agenda_number_format_2_id = value) "
                                         @setAgendaNumberFormat3="(value) => (this.agenda_number_format_3_id = value) " />
                    </section>
                    <section class="checkbox-section__checkbox">
                        <section class="checkbox-section__checkbox-wrapper">
                            <CheckBoxButton value="czlonkowie"
                                            id="czlonkowie"
                                            :checked="is_debatters" @click="this.is_debatters = !this.is_debatters" />
                            <label class="add-group-form__label--checkbox"
                                   @click="this.is_debatters = !this.is_debatters">
                                Członkowie grupy widoczni na liście
                                gości
                            </label>
                        </section>
                    </section>
                    <section class="checkbox-section__checkbox">
                        <section class="checkbox-section__checkbox-wrapper">
                            <CheckBoxButton value="members"
                                            id="members"
                                            :checked="only_for_members" @click="this.only_for_members = !this.only_for_members" />
                            <label class="add-group-form__label--checkbox"
                                   @click="this.only_for_members = !this.only_for_members">
                                Posiedzenia widoczne tylko dla członków grupy
                            </label>
                        </section>
                    </section>
                    <section class="checkbox-section__checkbox">
                        <section class="checkbox-section__checkbox-wrapper">
                            <CheckBoxButton value="rejestr"
                                            id="rejestr"
                                            :checked="canRegistry" @click="this.canRegistry = !this.canRegistry" />
                            <label class="add-group-form__label--checkbox"
                                   @click="this.canRegistry = !this.canRegistry">Rejestr dokumentów</label>
                        </section>
                        <DocumentsOptions v-if="canRegistry"
                                          :registryName="registryName"
                                          :registryDocName="registry_document_name"
                                          :registryNumberFormat="resolution_number_format_id"
                                          :errors="errors"
                                          @registryNameChanged="
                                          (value)=>
                            (this.registryName = value)
                            "
                            @registryDocNameChanged="
                            (value) => (this.registry_document_name = value)
                            "
                            @resolution_number_format_id="
                            (value) =>
                            (this.resolution_number_format_id = value)
                            "
                            />
                            <section class="resolution-points" v-if="canRegistry">
                                <p>NP - numer posiedzenia</p>
                                <p>n - numer uchwały</p>
                                <p>RR - ostatnie dwie cyfry roku (lub RRRR dla pełnego roku)</p>
                                <p>/ - dozwolony separator np. NP/n/RR</p>
                            </section>
                            <section class="resolution-switch" v-if="canRegistry">
                                <div class="form-bootstrap-check form-bootstrap-switch">
                                    <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="resol_num_year" v-model="begin_resolution_number_year" @click="selectNumberResolutionBegin()" />
                                    <label class="form-bootstrap-check-label" for="resol_num_year">Numeruj uchwały od początku, w każdym nowym roku.</label>
                                </div>
                            </section>
                    </section>
                </section>

                <section class="add-group-form__bottom">
                    <button
                        class="add-group-form__action-btn"
                        @click="(e) => handler(e, 'none')"
                    >
                        Anuluj
                    </button>
                    <button
                        class="
                            add-group-form__action-btn
                            add-group-form__action-btn--red
                        "
                        @click="handleAddClick"
                    >
                        {{ isEdit ? "Zapisz zmiany" : "Dodaj grupę" }}
                    </button>
                </section>
            </section>
            <LoaderInside v-if="isLoading" />
        </form>
    </section>
</template>

<script>
import ContactService from "../../../../../../services/contact.service";
import LoaderInside from "../../../../../BasicControls/LoaderInside.vue";
import ErrorMessage from "../../../../../BasicControls/ErrorMessage.vue";
import Hint from "../../../../../BasicControls/Hint.vue";
import CheckBoxButton from "../../../../../BasicControls/CheckBoxButton.vue";
import MeetingsOprions from "./MeetingsOptions.vue";
import DocumentsOptions from "./DocumentsOption.vue";

import {
    passErrorMsg,
    isError,
    setErrors,
} from "../../../../../../formValidation/main";

export default {
    name: "AddGroup",
    data() {
        return {
            groupName: "",
            type: 4,
            canRegistry: false,
            registryName: "Rejestr Uchwał",
            registry_document_name: "Uchwała",
            resolution_number_format_id: "NP/n/RR",
            canSession: false,
            is_sessions_numbered: false,
            session_number_format: 1,
            session_default_name: "",
            session_default_signature: "",
            session_default_place: "",
            session_default_attachement_public: false,
            agenda_number_format_1_id: 1,
            agenda_number_format_2_id: 1,
            agenda_number_format_3_id: 1,
            is_debatters: false,
            isEdit: false,
            isLoading: false,
            only_for_members: false,
            errors: [],
            begin_resolution_number_year: false,
        };
    },
    components: {
        CheckBoxButton,
        MeetingsOprions,
        DocumentsOptions,
        LoaderInside,
        ErrorMessage,
        Hint
    },
    props: {
        handler: Function,
        storedGroupData: Object,
    },
        mounted() {
        if (Object.keys(this.storedGroupData).length > 0) {
            const {
                name,
                can_registry,
                registry_name,
                registry_document_name,
                resolution_number_format_id,
                can_sessions,
                is_sessions_numbered,
                session_number_format_id,
                session_default_name,
                session_default_signature,
                session_default_place,
                session_default_attachement_public,
                agenda_number_format_1_id,
                agenda_number_format_2_id,
                agenda_number_format_3_id,
                is_debaters,
                only_for_members,
            } = this.storedGroupData;

            if (registry_name == null || registry_name.length == 0)
                this.registryName = "Rejestr Uchwał";
            else
                this.registryName = registry_name;

            if (registry_document_name == null || registry_document_name.length == 0)
                this.registry_document_name = "Uchwała";
            else
                this.registry_document_name = registry_document_name;

            if (resolution_number_format_id == null || resolution_number_format_id.length == 0)
                this.resolution_number_format_id = "NP/n/RR";
            else
                this.resolution_number_format_id = resolution_number_format_id;

            this.groupName = name.trim();
            this.canRegistry = can_registry;
            this.canSession = can_sessions;
            this.is_sessions_numbered = is_sessions_numbered;
            this.session_number_format = session_number_format_id;
            this.session_default_name = session_default_name;
            this.session_default_signature = session_default_signature;
            this.session_default_place = session_default_place;
            this.session_default_attachement_public = session_default_attachement_public;
            this.agenda_number_format_1_id = agenda_number_format_1_id;
            this.agenda_number_format_2_id = agenda_number_format_2_id;
            this.agenda_number_format_3_id = agenda_number_format_3_id;
            this.is_debatters = is_debaters;
            this.only_for_members = only_for_members;
            this.isEdit = true;

            ContactService.getResolutionBeginByYear().then((result) => {
                this.begin_resolution_number_year = result.data;
            });
        }
    },
    methods: {
        selectNumberResolutionBegin() {
            this.begin_resolution_number_year = !this.begin_resolution_number_year;
        },
        validate(data) {
            if (data.name.length <= 0) {
                this.isLoading = false;
                this.errors = setErrors({ name: ['Pole wymagane'] });
                return false
            }
            return true
        },
        handleAddClick(e) {
            e.preventDefault();

            this.isLoading = true;
            this.errors = [];

            const data = {
                name: this.groupName.trim(),
                type: this.type,
                can_registry: this.canRegistry,
                registry_name: this.registryName,
                registry_document_name: this.registry_document_name,
                resolution_number_format_id: this.resolution_number_format_id,
                can_sessions: this.canSession,
                is_sessions_numbered: this.is_sessions_numbered,
                session_number_format_id: this.session_number_format,
                session_default_name: this.session_default_name,
                session_default_signature: this.session_default_signature,
                session_default_place: this.session_default_place,
                session_default_attachement_public: this.session_default_attachement_public,
                agenda_number_format_1_id: this.agenda_number_format_1_id,
                agenda_number_format_2_id: this.agenda_number_format_2_id,
                agenda_number_format_3_id: this.agenda_number_format_3_id,
                is_debaters: this.is_debatters,
                only_for_members: this.only_for_members,
                cadence_id: 1,
            };

            if (this.validate(data) == false) {
                return
            }

            if (this.isEdit) {
                ContactService.updateGroup(this.storedGroupData.id, data)
                    .then(() => {
                        var d = this.$store.state.appStore.groups;
                        var index = d.findIndex(x => x.id == this.storedGroupData.id);
                        if (index != -1) {
                            d[index].name = data.name;
                            d[index].type = data.type;
                            d[index].can_registry = data.can_registry;
                            d[index].registry_name = data.registry_name;
                            d[index].registry_document_name = data.registry_document_name;
                            d[index].resolution_number_format_id = data.resolution_number_format_id;
                            d[index].can_sessions = data.can_sessions;
                            d[index].is_sessions_numbered = data.is_sessions_numbered;
                            d[index].session_number_format_id = data.session_number_format_id;
                            d[index].session_default_name = data.session_default_name;
                            d[index].session_default_signature = data.session_default_signature;
                            d[index].session_default_place = data.session_default_place;
                            d[index].session_default_attachement_public = data.session_default_attachement_public;
                            d[index].agenda_number_format_1_id = data.agenda_number_format_1_id;
                            d[index].agenda_number_format_2_id = data.agenda_number_format_2_id;
                            d[index].agenda_number_format_3_id = data.agenda_number_format_3_id;
                            d[index].is_debaters = data.is_debaters;
                            d[index].cadence_id = data.cadence_id;
                            d[index].only_for_members = data.only_for_members;
                        }

                        var data1 = {
                            set: this.begin_resolution_number_year
                        };

                        ContactService.setResolutionBeginByYear(data1);

                        this.finishRequest(d);
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.errors = setErrors(err);
                    });
            } else {
                ContactService.createGroup(data)
                    .then((response) => {
                        var d = this.$store.state.appStore.groups;
                        var g = response.data;
                        g.people = [];
                        g.sessions = [];

                        d.push(g);

                        var data = {
                            set: this.begin_resolution_number_year
                        };

                        ContactService.setResolutionBeginByYear(data);

                        this.finishRequest(d);
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.errors = setErrors(err);
                    });
            }
        },
        finishRequest(data) {
            this.$emit("setGroups", data);
            this.$emit("reloadGroups", data);
            this.handler(null, "none");
            this.isLoading = false;
            this.$store.commit("setGroups", data);
        },
        passErrorMsg,
        isError,
        setErrors,
    },
};
</script>

<style lang="scss" scoped>

    .tooltip {
        position: relative;
        left: 5px;
        top: -3px;
    }

    .tip-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        background-color: transparent;
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;

        &:hover .hint {
            opacity: 1;
            display: block;
        }
    }


    .resolution-switch {
        margin-bottom: 15px;
    }

    .resolution-points {
        font-size: 14px;
        color: grey;
        margin: 0px 20px 15px;
    }

    .add-group {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 8;
        background: #00082c75;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .meetings-options {
        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            display: block;
            color: #00082c;
            margin-bottom: 8px;
            margin-left: 20px;
            &--no-margin {
                margin-bottom: 0px;
            }
        }

        &__input-text {
            width: 90%;
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;
            margin-bottom: 24px;
            margin-left: 20px;

            &:focus {
                border: 1px solid #00082c;
            }
        }

    }

    .add-group-form {
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        padding: 28px 56px;
        max-height: 90vh;
        position: relative;
        padding-right: 0px;
        padding-bottom: 0px;

        &__form-wrapper {
            max-height: 80vh;
            overflow-y: auto;
            padding-right: 56px;

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }
        }

        &__close {
            position: relative;
            right: calc(-100% + 48px);
            border: 0px;
            background-color: transparent;
        }

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 48px;
        }

        &__label {
            display: block;
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-bottom: 8px;

            &--checkbox {
                padding-left: 36px;
                font-weight: normal;
                font-size: 16px;
                line-height: 144%;
                z-index: 9;
                color: #333956;
                cursor: pointer;
            }
        }

        &__input-text {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            width: 100%;
            margin-bottom: 40px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__sub-title {
            font-weight: 600;
            font-size: 13px;
            line-height: 120%;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #333956;
            margin-bottom: 16px;
        }

        &__bottom {
            margin-top: 28px;
            padding-bottom: 56px;
        }

        &__action-btn {
            min-width: 238px;
            padding: 18px 40px 17px;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            background-color: transparent;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                margin-left: 24px;
                color: #ffffff;
                background: #d80418;
                border: 0px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: #ad0313;
                }
            }
        }
    }

        .checkbox-section {
            &__checkbox {
                background: #f0f4fb;
                border: 1px solid #dde0e7;
                box-sizing: border-box;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;
            }

            &__checkbox-wrapper {
                display: flex;
                padding: 16px;
            }
        }
</style>
