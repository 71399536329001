<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Edycja danych personalnych</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeModal');}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <ValidationErrors :errors="saveErrors" v-if="showErrors" />
                <section class="column">
                    <section class="column-item">
                        <label class="esesja-modal__wraper__body__label">Imię</label>
                        <section class="esesja-modal__row">
                            <input class="esesja-modal__wraper__body__input"
                                   type="text"
                                   v-model="internalData.firstName"
                                   placecholder="Imię"
                                   v-error="{ errors, input_key: 'firstName' }" />
                            <ErrorMessage v-if="isError('firstName', this.errors)"
                                          :errorMsgs="passErrorMsg('firstName', this.errors)" />
                        </section>
                    </section>
                    <section class="column-item">
                        <label class="esesja-modal__wraper__body__label">Nazwisko</label>
                        <section class="esesja-modal__row">
                            <input class="esesja-modal__wraper__body__input"
                                   type="text"
                                   v-model="internalData.lastName"
                                   placecholder="Nazwisko"
                                   v-error="{ errors, input_key: 'lastName' }" />
                            <ErrorMessage v-if="isError('lastName', this.errors)"
                                          :errorMsgs="passErrorMsg('lastName', this.errors)" />
                        </section>
                    </section>
                </section>
                <section class="column">
                    <section class="column-item">
                        <label class="esesja-modal__wraper__body__label">Numer telefonu</label>
                        <section class="esesja-modal__row">
                            <input class="esesja-modal__wraper__body__input"
                                   type="text"
                                   v-model="internalData.telephone"
                                   placecholder="Numer telefonu"
                                   v-error="{ errors, input_key: 'telephone' }" />
                            <ErrorMessage v-if="isError('telephone', this.errors)"
                                          :errorMsgs="passErrorMsg('telephone', this.errors)" />
                        </section>
                    </section>
                    <section class="column-item">
                        <label class="esesja-modal__wraper__body__label">Fax</label>
                        <section class="esesja-modal__row">
                            <input class="esesja-modal__wraper__body__input"
                                   type="text"
                                   v-model="internalData.fax"
                                   placecholder="Fax"/>
                        </section>
                    </section>
                </section>
                <section class="column">

                    <section class="column-item">
                        <label class="esesja-modal__wraper__body__label">Adres E-Mail</label>
                        <section class="esesja-modal__row">
                            <input class="esesja-modal__wraper__body__input"
                                   type="email"
                                   v-model="internalData.email"
                                   placecholder="Adres E-Mail"
                                   v-error="{ errors, input_key: 'email' }" />
                            <ErrorMessage v-if="isError('email', this.errors)"
                                          :errorMsgs="passErrorMsg('email', this.errors)" />
                        </section>
                    </section>
                </section>
                <section class="column">
                    <section class="column-item">
                        <label class="esesja-modal__wraper__body__label">Ulica i numer domu/mieszkania</label>
                        <section class="esesja-modal__row">
                            <input class="esesja-modal__wraper__body__input"
                                   type="text"
                                   v-model="internalData.street"
                                   placecholder="Ulica i numer domu/mieszkania" />
                        </section>
                    </section>
                    <section class="column-item">
                        <label class="esesja-modal__wraper__body__label">Kod pocztowy</label>
                        <section class="esesja-modal__row">
                            <input class="esesja-modal__wraper__body__input"
                                   type="text"
                                   v-model="internalData.postalCode"
                                   placecholder="Kod pocztowy"
                                   v-error="{ errors, input_key: 'postalCode' }" />
                            <ErrorMessage v-if="isError('postalCode', this.errors)"
                                          :errorMsgs="passErrorMsg('postalCode', this.errors)" />
                        </section>
                    </section>
                </section>
                <section class="column">
                    <section class="column-item">
                        <label class="esesja-modal__wraper__body__label">Miasto</label>
                        <section class="esesja-modal__row">
                            <input class="esesja-modal__wraper__body__input"
                                   v-model="internalData.city"
                                   placecholder="Miasto" />
                        </section>
                    </section>
                </section>
                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="this.$emit('closeModal')"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="updateUserPersonalData">Zapisz</button>
                </section>
            </perfect-scrollbar>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
    import UserService from "../../../../../../services/user.service";
    import ErrorMessage from "@/components/BasicControls/ErrorMessage.vue";
    import ValidationErrors from "@/components/BasicControls/ValidationErrors.vue";
    import AlertHelper from '@/helpers/alerts.helper.js';
    import {
        passErrorMsg,
        isError,
        setErrors,
    } from "@/formValidation/main";

    export default {
        name: "EditPersonalDataModal",
        emits: ['closeModal'],
        props: {
            modelValue: {
                type: Object,
                required: true
            }
        },
        components: {
            ErrorMessage,
            ValidationErrors
        },
        data() {
            return {
                internalData: {
                    firstName: '',
                    lastName: '',
                    role: '',
                    telephone: '',
                    fax: '',
                    email: '',
                    street: '',
                    postalCode: '',
                    city: '',
                    accountSince: '',
                },
                errors: [],
                showErrors: false,
                saveErrors: [],
            }
        },
        mounted() {
            this.internalData = { ...this.modelValue }
        },
        methods: {
            async updateUserPersonalData() {
                try {
                    this.showErrors = false;
                    if (!this.validate()) {
                        return;
                    }

                    this.$store.commit("showLoader");

                    let response = await UserService.updatePersonalInfo(this.internalData);
                    if (response.status !== 200) {
                        throw await Promise.reject(response)

                    }

                    this.$emit('update:modelValue', response.data);
                    this.$emit('closeModal');
                }
                catch (e) {
                    console.log(e);
                    if (e.response !== undefined && e.response !== null) {
                        if (e.response.status == 404) {
                            this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                        }
                        else if (e.response.status == 401) {
                            this.$router.push({ name: 'Login' })
                        }
                        else if (e.response.status === 400) {

                            if (Array.isArray(e.response.data)) {
                                this.showErrors = true;
                                this.saveErrors = [...e.response.data];
                            }
                            else {
                                var t = this.$swal.mixin(AlertHelper.errorToastElement());
                                t.fire(AlertHelper.errorToastAlert('Wystąpił ogólny błąd'));
                            }
                        }
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            validate() {
                var validateStatus = true;
                this.errors = [];
                var errors = {};

                if (this.internalData.firstName.length <= 0) {
                    validateStatus = false;
                    errors.firstName = ['Pole wymagane']
                }

                if (this.internalData.lastName.length <= 0) {
                    validateStatus = false;
                    errors.lastName = ['Pole wymagane']
                }

                if (this.internalData.telephone != null && this.internalData.telephone.length > 0) {
                    if (/^[0-9]{9}$/.test(this.internalData.telephone) == false) {
                        errors.telephone = ['Niepoprawny format numeru telefonu']
                        validateStatus = false
                    }
                }

                if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.internalData.email) == false) {
                    errors.email = ['Niepoprawny format email']
                    validateStatus = false
                }

                if (this.internalData.postalCode != null && this.internalData.postalCode.length > 0) {
                    if (/^[0-9]{2}-[0-9]{3}$/.test(this.internalData.postalCode) == false) {
                        errors.postalCode = ['Niepoprawny format kodu pocztowego']
                        validateStatus = false
                    }
                }
                
                if (!validateStatus) {
                    this.isLoading = false;
                    this.errors = setErrors(errors)
                }

                return validateStatus;
            },

            passErrorMsg,
            setErrors,
            isError,
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/esesja-modal.scss';
    .column{
        display: flex;
        flex-direction:row;
        column-gap: 10px;
    }
</style>