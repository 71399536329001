<template>
    <section class="notification-item">
        <section class="notification-item__icon">
            <img src="@/assets/icons/components/error-alert.svg" />
        </section>
        <section class="notification-item__body">
            <section class="notification-item__body__title">
                Zmień hasło
            </section>
            <section class="notification-item__body__description">
                <p>Ostatnia zmiana hasła miała miejsce ponad 6 miesięcy temu. Rekomendujemy zmianę hasła.</p>
                <section class="notification-item--center">
                    <button class="notification-item__button notification-item__button--small notification-item__button--white"
                            @click="noShowaAgain">
                        <span class="notification-item__button__text">Nie pokazuj więcej</span>
                    </button>
                    <button class="notification-item__button notification-item__button--small notification-item__button--white"
                            @click="remindLater">
                        <span class="notification-item__button__text">Przypomnij później</span>
                    </button>
                </section>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    import UserService from "../../../../../services/user.service";

    export default {
        name: "OldPassword",
        methods: {
            async noShowaAgain() {
                try {
                    
                    let response = await UserService.noShowAgainPasswordReminder();

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.$store.commit('updateAccountErrors', this.getNewNotifications());
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'oldpassword' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
            },
            async remindLater() {
                try {

                    let response = await UserService.remindAboutChangePasswordLater();

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.$store.commit('updateAccountErrors', this.getNewNotifications());
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'oldpassword' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
            },
            getNewNotifications() {
                var notifications = this.$store.state.userStore.accountErrors;
                notifications = notifications.filter(item => item.errorTypeHuman !== 'oldpassword');
                return notifications;
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/notification-item.scss';
</style>