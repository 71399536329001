<template>
    <section class="agenda-item-context-menu" :class="{'hidden-for-template': isTemplate}">
        <!--<section class="agenda-item-context-menu__item"
             @click="this.$emit('editPoint', this.item)">
        <img src="../../assets/icons/components/edit.svg"
             class="agenda-item-context-menu__item__icon" />
        <span class="agenda-item-context-menu__item__text">Edytuj punkt</span>
    </section>-->
        <section v-if="isInteractive && !isHide" class="agenda-item-context-menu__item"
                 @click="this.$emit('activePoint', this.item)">
            <img src="@/assets/icons/components/accept-mark-black.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text">{{ isActive ? 'Deaktywuj' : 'Aktywuj' }} punkt</span>
        </section>
        <section v-if="isInteractive" class="agenda-item-context-menu__item"
                 @click="this.$emit('pointIsDone', this.item)">
            <img src="@/assets/icons/components/clock.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text">{{ isEnd ? 'Cofnij zakończenie' : 'Zakończenie' }} punktu</span>
        </section>
        <section v-if="isInteractive" class="agenda-item-context-menu__item"
                 @click="this.$emit('pointIsHide', this.item)">
            <img src="@/assets/icons/components/eye.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text">{{ isHide ? 'Usuń ukrycie' : 'Ukrycie' }} punktu</span>
        </section>
        <section v-if="isInteractive" class="agenda-item-context-menu__item"
                 @click="this.$emit('pointIsTakenOff', this.item)">
            <img src="@/assets/icons/components/exit.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text">{{ isTakenOff ? 'Cofnij zdjęcie' : 'Zdjęcie' }} punktu z porządku</span>
        </section>
        <hr v-if="isInteractive" />
        <section v-if="exportPrivilige && canRegistry"
                 class="agenda-item-context-menu__item"
                 @click="this.$emit('addResolution')">
            <img src="@/assets/icons/components/attachment-red.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text2">Dodaj uchwałę</span>
        </section>
        <section v-if="exportPrivilige && canRegistry"
                 class="agenda-item-context-menu__item"
                 @click="this.$emit('addResolutionFromRepository', this.item)">
            <img src="@/assets/icons/components/attachment-red.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text2">Dodaj uchw. z repozytorium</span>
        </section>
        <section v-if="exportPrivilige"
                 class="agenda-item-context-menu__item"
                 @click="this.$emit('addAttachment')">
            <img src="@/assets/icons/components/attachment.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text">Dodaj załącznik</span>
        </section>
        <section v-if="exportPrivilige"
                 class="agenda-item-context-menu__item"
                 @click="this.$emit('addAttachmentRepository', this.item)">
            <img src="@/assets/icons/components/attachment.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text">Dodaj zał. z repozytorium</span>
        </section>
        <section v-if="votingModule == 1"
                 class="agenda-item-context-menu__item"
                 @click="this.$emit('addVottingFromMenu', this.item)">
            <img src="@/assets/icons/components/voting-add.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text">Dodaj głosowanie</span>
        </section>
        <section v-if="exportPrivilige"
                 class="agenda-item-context-menu__item"
                 @click="this.$emit('addNote', this.item)">
            <img src="@/assets/icons/components/note.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text">Dodaj notatkę</span>
        </section>
        <section v-if="exportPrivilige"
                 class="agenda-item-context-menu__item"
                 @click="this.$emit('addlink', this.item)">
            <img src="@/assets/icons/components/note.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text">Dodaj link</span>
        </section>
        <section v-if="exportPrivilige"
                 class="agenda-item-context-menu__item"
                 @click="this.$emit('addStatement', this.item)">
            <img src="@/assets/icons/components/note.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text">Dodaj wypowiedź</span>
        </section>
        <section v-if="votingModule == 1 && isVotingResults"
                 class="agenda-item-context-menu__item"
                 @click="this.$emit('pointBlockVotingsResults', this.item)">
            <img src="@/assets/icons/components/cube.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text">Wyniki bloku głosowań</span>
        </section>

        <section
                 class="agenda-item-context-menu__item"
                 @click="this.$emit('editSessionPointFromMenu', this.item)">
            <img src="@/assets/icons/components/edit.svg"
                 class="agenda-item-context-menu__item__icon" />
            <span class="agenda-item-context-menu__item__text">Ustawienia punktu</span>
        </section>


        <!--<section class="agenda-item-context-menu__item"
             @click="this.$emit('delete', this.item)">
        <img src="../../assets/icons/components/bin.svg"
             class="agenda-item-context-menu__item__icon" />
        <span class="
                agenda-item-context-menu__item__text
                agenda-item-context-menu__item__text--red
            ">Usuń</span>
    </section>-->
    </section>
</template>

<script>
import { encryptStorage } from '@/services/encrypt.service';
export default {
    name: "AgendaItemContextMenu",
    props: {
        item: Object,
        isTemplate: {
            type: Boolean,
            default: false
        },
        isInteractive: {
            type: Boolean,
            default:false
        },
        isHide: {
            type: Boolean,
            default: false
        },
        isEnd: {
            type: Boolean,
            default: false
        },
        isTakenOff: {
            type: Boolean,
            default: false
        },
        isActive: {
            type: Boolean,
            default: false
        },
        canRegistry: {
            type: Boolean,
            default: false
        },
        isVotingResults: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            exportPrivilige: encryptStorage.getItem('priv').eksport,
            votingModule: encryptStorage.getItem('modules').g,
        }
    }
};
</script>

<style lang="scss" scoped>
    .agenda-item-context-menu {
        padding: 12px 0px;
        position: absolute;
        z-index: 128;
        background-color: white;
        border-radius: 12px;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 16px 32px 48px rgba(0, 8, 44, 0.12);
        display: none;

        &__item {
            padding: 12px 24px;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &__icon {
                width: 20px;
                height: 20px;
            }

            &__text {
                margin-left: 12px;
                font-weight: 300;
                font-size: 14px;
                line-height: 132%;
                color: #00082c;
            }

            &__text2 {
                margin-left: 12px;
                font-weight: 500;
                font-size: 14px;
                line-height: 132%;
                color: #d80418;
            }

            &:hover {
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }

    .hidden-for-template{
        display: none !important;
    }
</style>
