<template>
    <section>
        <!--<ProductInDevelopmentMode />-->
        <!--<NewCadenceInstructionsInformation v-if="showInformationForClientType" />
        <br v-if="showInformationForClientType" />-->
        <!--<NewCadenceLink v-if="showInformationForClientType" />-->
        <!--<WelcomeInNewCadence v-if="showWelcomeInNewCadence" />-->
        <AdvertisingBanner v-if="showAdvertisingBanner" />
        <!--<esesjaTvConfiguration v-if="showesesjaTvConfigurationInfo" :kod="kod" />-->
        <section class="home-hello">
        </section>
        <section class="home-content">
            <h2 class="home-content__header">Panel główny</h2>
            <section class="home-content__wrapper">
                <section class="home-content-left">
                    <section class="home-content-left__action-boxes">
                        <section v-for="box in checkActionisEnabled(actionBoxes)"
                                 :key="box.id"
                                 class="home-content-left__box-wrapper">
                            <ActionBox :title="box.title"
                                       :icon="box.icon"
                                       @click="box.event" />
                        </section>
                    </section>
                    <Meetings />
                    <MeetingsArchive />
                </section>
                <section class="home-content-right">
                    <Calendar />
                    <News />
                </section>
            </section>
            <Transition>
                <SMSModal v-if="showSMSModal" @close="closeSMSModal" :options="smsOptions" :selected="selected" />
            </Transition>
            <Transition>
                <EmailModal v-if="showEmailModal" @closeEmail="closeEmailModal" :options="emailOptions" :selected="selectedEmail" />
            </Transition>
            <Transition>
                <AnnouncementModal v-if="showAnnouncementModal" @closeAnnoucement="closeAnnouncementModal" @updateCalendar="updateCalendarAnno" :options="annoOptions" :options2="annoOptions2" :selected="selectedEmail" :selected2="selectedEmail" :elemToUpdate="elemToUpdate" />
            </Transition>
        </section>
    </section>
</template>

<script charset="utf-8">
    import ActionBox from "./ActionBoxes.vue";
    import Calendar from "./Calendar.vue";
    import Meetings from "./Meetings/Meetings.vue";
    import MeetingsArchive from "./Meetings/MeetingsArchive.vue";
    import News from "./News/News.vue";
    import SMSModal from "./Modals/SendSMS.vue";
    import EmailModal from "./Modals/SendEmail.vue";
    import AnnouncementModal from "./Modals/AddAnnouncement.vue";

    import ContactService from "../../../../services/contact.service";
    import CommunicationService from "../../../../services/communication.service";
    import MeetingService from "../../../../services/meetings.service";
    import SettingsService from "../../../../services/settings.service";
    import { encryptStorage } from '../../../../services/encrypt.service';
    import AlertHelper from '../../../../helpers/alerts.helper.js';

    //import ProductInDevelopmentMode from "../../../Shared/CustomMessages/ProductInDevelopmentMode.vue";
    /*import NewCadenceInstructionsInformation from "../../../Shared/CustomMessages/NewCadenceInstructionsInformation.vue";*/
    //import NewCadenceLink from "../../../Shared/CustomMessages/NewCadenceLink.vue";
    //import WelcomeInNewCadence from "../../../Shared/CustomMessages/WelcomeInNewCadence.vue";
    import AdvertisingBanner from "../../../Shared/CustomMessages/AdvertisingBanner.vue";
    //import esesjaTvConfiguration from "../../../Shared/CustomMessages/esesjaTvConfiguration.vue";


    export default {
        name: "home",
        computed:{
            isAnyModalOpen(){
                return this.showSMSModal 
                    || this.showEmailModal 
                    || this.showAnnouncementModal
                    || this.$store.state.appStore.isAddMeetingVisible
            }
        },
        watch:{
            isAnyModalOpen: {
                deep: true,
                handler: function (val) {
                    document.querySelector('body').style.overflow = val ? 'hidden' : 'auto';
                }
            },
        },
        data() {
            return {
                actionBoxes: [
                    {
                        id: 0,
                        icon: "add_meeting",
                        title: "Dodaj posiedzenie",
                        event: (e) => this.handleAddMeetingClick(e),
                    },
                    {
                        id: 1,
                        icon: "add_annoucment",
                        title: "Dodaj komunikat",
                        event: (e) => this.handleAddAnnouncementClick(e),
                    },
                    {
                        id: 2,
                        icon: "send_sms",
                        title: "Wyślij SMS",
                        event: (e) => this.handleSendSMSClick(e),
                    },
                    {
                        id: 3,
                        icon: "send_e-mail",
                        title: "Wyślij e-mail",
                        event: (e) => this.handleSendEmailCLick(e),
                    },
                ],
                showSMSModal: false,
                smsOptions: [],
                selected: [],
                selected2: [],
                emailOptions: [],
                annoOptions: [],
                showEmailModal: false,
                showAnnouncementModal: false,
                selectedEmail: [],
                annoOptions2: [],
                showInformationForClientType: false,
                showWelcomeInNewCadence: false,
                showesesjaTvConfigurationInfo: false,
                showAdvertisingBanner: false,
                kod: "",
                smsmodule: encryptStorage.getItem('modules').s,
                elemToUpdate: {
                    id: 0,
                    author: "",
                    description: "",
                    isInCalendar: false,
                    meetingDate: "",
                    status: 3,
                    title: "",
                    isEdit: false
                }
            };
        },
        components: {
            ActionBox,
            Calendar,
            Meetings,
            News,
            SMSModal,
            EmailModal,
            AnnouncementModal,
            MeetingsArchive,
            //ProductInDevelopmentMode,
            //NewCadenceInstructionsInformation,
            //NewCadenceLink,
            //WelcomeInNewCadence,
            AdvertisingBanner
            //esesjaTvConfiguration
        },
        mounted() {
            this.getClientType();
        },
        methods: {
            handleAddMeetingClick(e) {
                e.preventDefault();
                this.$store.commit("showAddMeeting");
            },
            checkActionisEnabled(actions) {
                var modules = encryptStorage.getItem('modules');
                var act = [];

                for (var i = 0; i < actions.length; i++) {
                    if (actions[i].id == 2) {
                        if (modules.s == 1)
                            act.push(actions[i]);
                    } else if (actions[i].id == 1) {
                        if (modules.inf == 1)
                            act.push(actions[i]);
                    } else
                        act.push(actions[i]);
                }
                return act;
            },
            getClientType() {
                SettingsService.getClientType().then((response) => {

                    if (response.status !== 200) {
                        this.showInformationForClientType = false;
                        this.showWelcomeInNewCadence = false;
                        this.showesesjaTvConfigurationInfo = false;
                        this.showAdvertisingBanner = false;
                    }

                    if (parseInt(response.data.type) > 0 && parseInt(response.data.type) < 7) {

                        this.showAdvertisingBanner = true;

                        if (response.data.cadenceYears == '2024-2029') {
                            this.showInformationForClientType = false;
                            this.showWelcomeInNewCadence = true;
                            
                            if (response.data.esesjaTvKod != null && response.data.esesjaTvKod.length > 0) {
                                this.showesesjaTvConfigurationInfo = true;
                                this.kod = response.data.esesjaTvKod;
                            } 
                        }
                        else {
                            this.showInformationForClientType = true;
                            this.showWelcomeInNewCadence = false;
                            this.showesesjaTvConfigurationInfo = false;
                        }
                    }
                    else {
                        this.showInformationForClientType = false;
                        this.showWelcomeInNewCadence = false;
                        this.showesesjaTvConfigurationInfo = false;
                        this.showAdvertisingBanner = false;
                    }

                    this.$store.commit('setCadenceYears', response.data.cadenceYears);

                }).catch(() => {
                    this.showInformationForClientType = false;
                    this.showWelcomeInNewCadence = false;
                    this.showesesjaTvConfigurationInfo = false;
                    this.showAdvertisingBanner = false;
                });
            },
            async handleAddAnnouncementClick(e) {
                try {
                    e.preventDefault();
                    this.$store.commit('showLoader');
                    var result = (await ContactService.getUsersToSmsOrEmail(3)).data;
                    if (Object.keys(result).length > 0) {
                        this.annoOptions = [];
                        this.annoOptions2 = [];

                        for (var i = 0; i < result.length; i++) {
                            var d = {
                                value: result[i].id,
                                label: result[i].label
                            }
                            this.annoOptions.push(d);

                            for (var j = 0; j < result[i].options.length; j++) {
                                if (this.annoOptions2.find(x => x.value == result[i].options[j].value) == null) {
                                    this.annoOptions2.push(result[i].options[j]);
                                }
                            }
                        }
                        this.showAnnouncementModal = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally{
                    this.$store.commit('hideLoader');
                }

            },
            async handleSendSMSClick(e) {
                try {
                    if (this.smsmodule !== 1) {
                        this.$swal(AlertHelper.errorAlert("Brak wykupionego modułu sms."));
                        return;
                    }

                    this.$store.commit('showLoader');

                    let availableCreditsResponse = await CommunicationService.getSmsCredits();
                    if (availableCreditsResponse.status !== 200) {
                        throw await Promise.reject(availableCreditsResponse);
                    }

                    if (availableCreditsResponse.data === 0) {
                        this.$swal(AlertHelper.errorAlert("Brak dostępnych kredytów SMS."));
                        return;
                    }

                    e.preventDefault();
                    var result = (await ContactService.getUsersToSmsOrEmail(1)).data;
                    if (Object.keys(result).length > 0) {
                        this.smsOptions = result;
                        this.showSMSModal = true;
                    }
                } 
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally{
                    this.$store.commit('hideLoader');
                }
            },
            async handleSendEmailCLick(e) {
                try {
                    this.$store.commit('showLoader');
                    e.preventDefault();
                    var result = (await ContactService.getUsersToSmsOrEmail(2)).data;
                    if (Object.keys(result).length > 0) {
                        this.emailOptions = result;
                        this.showEmailModal = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally{
                    this.$store.commit('hideLoader');
                }
            },
            updateCalendarAnno() {
                MeetingService.groups(false).then((response) =>
                    this.$store.commit("setGroups", response.data)
                );
            },
            closeSMSModal() {
                this.showSMSModal = false;
            },
            closeEmailModal() {
                this.showEmailModal = false;
            },
            closeAnnouncementModal() {
                this.showAnnouncementModal = false;
            }
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../../../assets/scss/breakpoints.scss";

    .home-hello {
        padding: 10px 0px 0px 0px;
        position: absolute;
        right: 48px;
    }

    .home-content {
        padding: 49px 24px 49px 48px;
        font-weight: 600;
        font-size: 28px;
        line-height: 128%;
        color: #00082c;

        &__wrapper {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            column-gap: 20px;
            row-gap: 20px;
        }
    }

    .home-content-left {
        display: flex;
        flex-direction: column;
        flex: 1;
        row-gap: 20px;
        /*        @media only screen and(max-width:1850px) {
            width: 100%;
        }*/

        &__box-wrapper{
            flex-grow: 1;
        } 
        &__action-boxes {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            column-gap: 20px;
            row-gap: 20px;
            /*            @media only screen and(max-width:1850px) {
                width: 100%;
                margin-right: 0px;
            }*/

            @media (max-width: 992px) {
                flex-wrap: wrap;
            }
        }
    }

    .home-content-right {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        column-gap: 20px;

        @media only screen and (min-width: 992px) and (max-width:1900px) {
            flex-direction: row;
            justify-content: space-between;
            flex: 1;
            /*            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;*/
        }

        @media only screen and (min-width:2500px) {
            flex-direction: row;
        }
    }
</style>
