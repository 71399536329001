<template>
    <button class="confirm-button" :style="buttonColor">
        <span class="confirm-button__text"> {{ text }}</span>
    </button>
</template>

<script>
    export default {
        name: "ButtonWithIconContacts",
        props: {
            text: String,
            color: String
        },
        computed: {
            buttonColor() {
                return `background: ${this.color}`
            }
        },
    };
</script>



<style lang="scss" scoped>
    .confirm-button {
        width: 238px;
        height: 56px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        //background: #d80418;
        border-radius: 4px;
        border: 0px;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #ad0313;
        }

        &__text {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            color: white;
        }
    }
</style>
