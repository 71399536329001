export default class eSesjaSocketAction {
    static ActivePoint = new eSesjaSocketAction("ActivePoint");
    static StartVoting = new eSesjaSocketAction("StartVoting");
    static CancelVoting = new eSesjaSocketAction("CancelVoting");
    static SaveVotingResult = new eSesjaSocketAction("SaveVotingResult");
    static DiscussionListChanged = new eSesjaSocketAction("DiscussionListChanged");
    static GetCurrentDiscussionList = new eSesjaSocketAction("GetCurrentDiscussionList");
    static QuorumCheckStart = new eSesjaSocketAction("QuorumCheckStart");
    static QuorumCheckEnd = new eSesjaSocketAction("QuorumCheckEnd");
    static GetCurrentVotingResult = new eSesjaSocketAction("GetCurrentVotingResult");
    static UserConfirmPresence = new eSesjaSocketAction("UserConfirmPresence"); 
    constructor(name) {
        this.name = name;
    }
}


