import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/main-router.js";
import store from "./store/mainStore.js";
import axios from "axios";
import axiosSetup from "./helpers/axios.interceptor";
import UserService from "./services/user.service";
import ErrorDirective from "./directives/form.error.directive";
import moment from "moment";
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { encryptStorage } from '../src/services/encrypt.service';
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// global components
// modals
import eSesjaModal from "./components/BasicControls/Modals/eSesjaModal.vue";
import eSesjaModalHeader from "./components/BasicControls/Modals/eSesjaModalHeader.vue";
import eSesjaModalBody from "./components/BasicControls/Modals/eSesjaModalBody.vue";
import eSesjaModalButtonCancel from "./components/BasicControls/Modals/eSesjaModalButtonCancel.vue";
import eSesjaModalButtonSave from "./components/BasicControls/Modals/eSesjaModalButtonSave.vue";
import eSesjaModalFooter from "./components/BasicControls/Modals/eSesjaModalFooter.vue"
// tabs
import eSesjaTab from "./components/BasicControls/Tabs/eSesjaTab.vue";
import eSesjaTabNav from "./components/BasicControls/Tabs/eSesjaTabNav.vue";
import eSesjaTabNavItem from "./components/BasicControls/Tabs/eSesjaTabNavItem.vue";
import eSesjaTabContent from "./components/BasicControls/Tabs/eSesjaTabContent.vue";
import eSesjaTabPane from "./components/BasicControls/Tabs/eSesjaTabPane.vue";

// cards
import CardWrapper from "./components/BasicControls/Cards/CardWrapper.vue"
import CardHeader from "./components/BasicControls/Cards/CardHeader.vue"
import CardBody from "./components/BasicControls/Cards/CardBody.vue"
import CardTitle from "./components/BasicControls/Cards/CardTitle.vue"
import CardText from "./components/BasicControls/Cards/CardText.vue"

// buttons
import ButtonBasic from "./components/BasicControls/Buttons/ButtonBasic.vue";
import ButtonOutline from "./components/BasicControls/Buttons/ButtonOutline.vue"
import ButtonGroup from "./components/BasicControls/Buttons/ButtonGroup.vue"
import ButtonToolbar from "./components/BasicControls/Buttons/ButtonToolbar.vue"
import ButtonIcon from "./components/BasicControls/Buttons/ButtonIcon.vue"
import ButtonSeparator from "./components/BasicControls/Buttons/ButtonSeparator.vue"

// progress-bar
import ProgressBar from "./components/BasicControls/ProgressBar/ProgressBar.vue";

library.add(faUserSecret);

axiosSetup();

axios.defaults.baseURL = `/`;
moment.locale("pl");

if (encryptStorage.getItem("token") !== undefined && encryptStorage.getItem("token") !== null) {
    UserService.me();
} else {
    store.commit("destroyUser");
    //store.commit("turnOffInteractive");
}

//6LdNYHonAAAAAEBrrYunPEYPuMxHF4soDfN4M_px
//6LdNYHonAAAAANC4veVYroZGv7yXxwFe6lG8uKGt
createApp(App)
    .use(PerfectScrollbar)
    .use(store)
    .use(router)
    .use(FloatingVue)
    .use(VueSweetalert2)
    .directive("error", ErrorDirective)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component("eSesjaModal", eSesjaModal)
    .component("eSesjaModalBody", eSesjaModalBody)
    .component("eSesjaModalHeader", eSesjaModalHeader)
    .component("eSesjaModalFooter", eSesjaModalFooter)
    .component("eSesjaModalButtonCancel", eSesjaModalButtonCancel)
    .component("eSesjaModalButtonSave", eSesjaModalButtonSave)
    .component("eSesjaTab", eSesjaTab)
    .component("eSesjaTabNav", eSesjaTabNav)
    .component("eSesjaTabNavItem", eSesjaTabNavItem)
    .component("eSesjaTabContent", eSesjaTabContent)
    .component("eSesjaTabPane", eSesjaTabPane)
    .component("Card", CardWrapper)
    .component("CardHeader", CardHeader)
    .component("CardBody", CardBody)
    .component("CardTitle", CardTitle)
    .component("CardText", CardText)
    .component("ButtonBasic", ButtonBasic)
    .component("ButtonOutline", ButtonOutline)
    .component("ButtonGroup", ButtonGroup)
    .component("ButtonToolbar", ButtonToolbar)
    .component("ButtonIcon", ButtonIcon)
    .component("ButtonSeparator", ButtonSeparator)
    .component("ProgressBar", ProgressBar)
    .mount("#app");
