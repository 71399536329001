import { switchToRoman, switchIndexToLetter } from "@/helpers/index.conversion";

class PointsNumerationHelper {
    filterIndex(index, number, level, firstLevel, secondLevel, thirdLevel) {
        if (number != null && number.length > 0) {
            return number;
        }

        if (index == '-') {
            return "- (ukryty)";
        }

        if (level !== undefined && index !== undefined) {
            switch (level) {
                case 0:
                    return this.conversionLevel(firstLevel, index);
                case 1:
                    return this.conversionLevel(secondLevel, index);
                case 2:
                    return this.conversionLevel(thirdLevel, index);
                default:
                    return `${index}.`;
            }
        }
        else {
            return '';
        }
    }

    conversionLevel(optionLevel, index) {
        switch (optionLevel) {
            case 1:
                return `${switchToRoman(index)}`;
            case 2:
                return `${index}.`;
            case 3:
                return `${switchIndexToLetter(index)})`;
            case 4:
                return `${index})`;
            default:
                return `${index}.`;
        }
    }

    setOrderToDisplayRecursive(items) {
        if (items === null || items === undefined || items.length === 0) {
            return items;
        }

        let indexLevel = 0
        for (let item of items) {
            item.customNumbering = false;
            if (item.is_hidden === true) {
                item.orderToDisplay = "-";
            }
            else if (item.number) {
                item.customNumbering = true;
                item.orderToDisplay = item.number;
            }
            else {
                indexLevel += 1;
                item.orderToDisplay = indexLevel;
            }

            if (item.children) {
                item.children = this.setOrderToDisplayRecursive([...item.children])
            }
        }
        return items;
    }
}

export default new PointsNumerationHelper();