<template>
    <div class="esesja-progress progress flex-grow-1">
        <div :class="computedClass"
             :role="progressbar"
             :aria-label="label" 
             :aria-valuenow="current"
             :aria-valuemin="min"
             :aria-valuemax="max"
             :style="computedStyle">
            <slot></slot>
        </div>
    </div>
</template>
<script charset="utf-8">
    export default {
        name: "ProgressBar",
        props: {
            label: {
                type: String,
                default: "Progrss-Bar"
            },
            current: {
                type: Number,
                default: 0,
            },
            min: {
                type: Number,
                default: 0,
            },
            max: {
                type: Number,
                default: 100
            },
            type: {
                type: String,
                default: ""
            },
            isStriped: {
                type: Boolean,
                default: false,
            },
            isAnimated: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            computedClass() {
                let classesName = "progress-bar"

                if (this.isStriped === true) {
                    classesName += " progress-bar-striped"
                }

                if (this.isAnimated === true) {
                    classesName += " progress-bar-animated"
                }

                switch (this.type) {
                    case "success": classesName += " bg-success"; break;
                    case "info": classesName += " bg-info"; break;
                    case "warning": classesName += " bg-warning"; break;
                    case "danger": classesName += " bg-danger"; break;
                }

                return classesName;
            },

            computedStyle() {
                let width = parseInt(this.current) / parseInt(this.max - this.min);
                return `width: ${width*100}%`;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .esesja-progress {
        .bg-danger {
            background: #d80418 !important;
        }
    }
</style>