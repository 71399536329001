<template>
    <section class="contact-list">
        <ContactBox
            v-for="group in groups"
            :key="group.id"
            :name="group.name"
            :groupId="group.id"
            :people="group.people"
            :addContactHandler="addContactHandler"
            :sendSmsHandler="sendSmsHandler"
            :sendEmailHandler="sendEmailHandler"
            @reloadGroups="(groups) => this.$emit('reloadGroups', groups)"
            :isFilter="searchBar.length > 0"
        />
    </section>
</template>

<script>
import ContactBox from "./ContactBox.vue";

export default {
    name: "ContactList",
    data() {
        return {
            contacts: [],
        };
    },
    props: {
        groups: Array,
        addContactHandler: Function,
        sendSmsHandler: Function,
        sendEmailHandler: Function,
        searchBar: String,
    },
    components: {
        ContactBox,
    },
};
</script>

<style lang="scss" scoped></style>
