<template>
    <section class="show-note">
        <section class="show-note__wrapper">
            <button class="show-note__exit-btn"
                    @click="
                    (e)=>
                {
                e.preventDefault();
                this.$emit('closeNote');
                }
                "
                >
                <img src="../../../../assets/icons/components/exit-btn.svg" />
            </button>
            <h4 class="show-note__name">{{ noteData.name }}</h4>
            <section class="show-note__description"
                     v-html="noteDescription"></section>
            <section class="show-note__label">
                <p v-if="noteData.isPublic == true">Notatka publiczna</p>
                <p v-else>Notatka prywatna</p>
            </section>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script charset="utf-8">
    import LoaderInside from "../../../BasicControls/LoaderInside.vue";

    import AlertHelper from "../../../../helpers/alerts.helper";
    import AttachmentService from "../../../../services/attachments.service";

    export default {
        name: "ShowNote",
        data() {
            return {
                isLoading: false,
                noteData: {},
                noteDescription: "",
            };
        },
        components: {
            LoaderInside,
        },
        props: {
            noteID: Number,
            pid: Number
        },
        async mounted() {
            try {
                this.isLoading = true;
                let response = await AttachmentService.getNote(this.noteID, this.pid)
                if (response.status !== 200) {
                    throw await Promise.reject(response)
                }
                this.noteData = response.data;
                this.noteDescription = this.noteData.description;
            }
            catch {
                // info ze nie udało się pobrać notatki
                var toast = this.$swal.mixin(AlertHelper.errorToastElement());
                toast.fire(AlertHelper.errorToastAlert('Nie udało się pobrać notatki'));
                this.$emit('closeNote');
            }
            finally {
                this.isLoading = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .show-note {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 9;

        &__label {
            font-weight: 500;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-top: 10px;
        }

        &__wrapper {
            padding: 32px 34px;
            background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
            border-radius: 12px;
            max-height: 90vh;
            overflow-y: auto;
            min-width: 612px;
            min-height: 50vh;
        }

        &__exit-btn {
            border: 0px;
            background-color: transparent;
            position: relative;
            right: -95%;
            top: -10px;
        }

        &__name {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 32px;
            background: #ffffff;
            padding: 12.5px 16px;
            border-radius: 4px;
            border: 1px solid #d1d6df;
            width: 100%;
            box-sizing: border-box;
        }

        &__description {
            max-height: 40vh;
            height: 40vh;
            border: 1px solid #d1d6df;
            overflow-y: auto;
            line-height: 128%;
            color: #00082c;
            background: #ffffff;
            padding: 12.5px 16px;
            border-radius: 4px;
            width: 100%;
            box-sizing: border-box;

            h1 {
                font-size: 32px;
            }

            h2 {
                font-size: 24px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
            }

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }
        }
    }
</style>
