<template>
    <section class="d-flex flex-row justify-content-center py-0">
        <a href="https://wydarzenia.mwc.pl" target="_blank"><img class="banner-img" src="https://ssl.esesja.pl/images/forum.png"></a>
    </section>

</template>
<script charset="utf-8">
    export default {
        name: "AdvertisingBanner",
        methods: {
        }
    }
</script>
<style lang="scss">
    .banner-img {
        max-width: 100%;
        -webkit-box-shadow: 0 1px 7px 0 rgba(0,0,0,0.6);
        box-shadow: 0 1px 7px 0 rgba(0,0,0,0.6);
    }

</style>