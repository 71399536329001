<template>
    <section class="search-item" @click="moveToResource" v-tooltip="item.resouceData.sessionName">
        <section class="search-item-type">
            <img src="../../../../assets/icons/panel/header/list-check.svg" />
        </section>
        <section class="search-item-body">
            {{item.resouceData.name}}
        </section>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "SearchAgendaItem",
        props: {
            item: {
                type: Object,
                require: true,
            }
        },
        methods: {
            canSession() {
                return this.$store.state.appStore.groups.filter(
                    (group) => group.can_sessions
                );
            },

            getSessionGroupId(sessionId) {
                try {
                    let sessions = this.canSession();
                    let result = sessions.filter(group => {
                        let filteredSessions = group.sessions.filter(session => {
                            return session.id == sessionId;
                        })
                        return filteredSessions.length > 0;
                    })
                    if (result.length === 0) {
                        return -1;
                    }
                    return result[0].id;
                }
                catch (e) {
                    return -1;
                }
            },

            async moveToResource() {
                this.$emit("closeSearch");
                await this.$router.push({ name: "sessionEdit", params: { sessionid: this.item.resouceData.sessionId, groupid: this.getSessionGroupId(this.item.resouceData.sessionId) }, hash: `#point-${this.item.resouceData.id}` });
            }
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/SearchEngineItems.scss';
</style>