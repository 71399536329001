<template>
    <section class="search-item" @click="moveToResource">
        <div class="search-item-type">
            <img src="../../../../assets/icons/panel/header/user.svg" />
        </div>
        <section class="search-item-body">
            {{item.resouceData.first_name}} {{item.resouceData.last_name}} {{showEmail(item.resouceData.email)}} {{showRole(item.resouceData.role)}}
        </section>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "SearchPersonItem",
        props: {
            item: {
                type: Object,
                require: true,
            }
        },
        methods: {
            async moveToResource() {
                this.$emit("closeSearch");
                await this.$router.push({ name: "contact", query: { searchPeopleId: this.item.resouceData.id } });
            },
            showEmail(email) {
                if (email != null && email != undefined && email.length > 0)
                    return "(" + email + ")";
            },
            showRole(role) {
                if (role != null && role != undefined && role.length > 0)
                    return "- " + role;
            }
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/SearchEngineItems.scss';
</style>