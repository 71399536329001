<template>
    <section>
        <Bar :chart-options="chartOptions"
             :chart-data="chartData"
             :chart-id="chartId"
             :dataset-id-key="datasetIdKey"
             :plugins="plugins"
             :css-classes="cssClasses"
             :styles="styles"
             :width="width"
             :options="options"
             :height="height" />

    </section>
</template>

<script>
    import { Bar } from 'vue-chartjs'
    import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js'

    ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)

    export default {
        name: 'BarHorizontalChart',
        components: {  Bar },
        props: {
            chartId: {
                type: String,
                default: 'chart'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 450
            },
            height: {
                type: Number,
                default: 450
            },
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => { }
            },
            plugins: {
                type: Object,
                default: () => {}
            },
            options: {
                type: Object,
                default: () => {}
            },
            chartData: {
                type: Object,
                default: () => { }
            },
            type: Number,
        },
        data() {
            return {
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    indexAxis: 'y'
                },
            }
        }
    }


</script>

<style></style>