<template>
    <section class="checkobx-wrapper" @click="clickInput(value)">
        <input
            type="checkbox"
            :value="value"
            class="checkobx-btn"
            :id="id"
            :checked="checked"
            :disabled="disabled"
        />
        <section class="checkbox-mark">
            <img src="../../assets/icons/components/accept-mark.svg" />
        </section>
    </section>
</template>

<script>
export default {
    name: "CheckBoxButton",
    props: {
        value: [Number, String],
        id: [Number, String],
        idsArray: Array,
        checked: Boolean,
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleChange(e) {
            if (this.idsArray !== undefined) {
                let currentValue = [...this.idsArray];
                if (e.target.checked) {
                    currentValue.push(parseInt(e.target.value));
                } else {
                    currentValue = currentValue.filter(
                        (item) => item !== parseInt(e.target.value)
                    );
                }

                this.$emit("setGroupId", currentValue);
            }
        },
        clickInput(e) {
            if (this.idsArray === undefined) {
                return;
            }
            let currentValue = [...this.idsArray];

            if (currentValue.some(item => item === parseInt(e))) {
                currentValue = currentValue.filter(
                    (item) => item !== parseInt(e)
                );
            }
            else {
                currentValue.push(parseInt(e));
            }

            this.$emit("setGroupId", currentValue);
        }
    },
};
</script>

<style lang="scss" scoped>
.checkobx-wrapper {
    position: relative;
    cursor: pointer;
}

.checkobx-btn {
    width: 24px;
    height: 24px;
    position: relative;
    opacity: 1;
    cursor: pointer;

    &:checked ~ .checkbox-mark {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:checked ~ .add-contact-form__label--checkbox {
        padding-left: 0px;
    }

    &:before {
        content: "";
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
        /*border-radius: 4px;*/
        width: 24px;
        height: 24px;
        position: relative;
        display: block;
        top: 0px;
        left: 0px;
        opacity: 1;
        z-index: 2;
    }
}

.checkbox-mark {
    background: #00082c;
    border: 1px solid #00082c;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
    border-radius: 4px;
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 5;
    display: none;
    top: 0px;
    left: 0px;
    opacity: 1;
}
</style>
