<template>
    <div :class="cardClass">
        <slot></slot>
    </div>
</template>
<script charset="utf-8">
    export default {
        name: "CardHeader",
        props: {
            border: {
                type: String,
                default: ""
            }
        },
        computed: {
            cardClass() {
                let crdClass = "card-header";

                switch (this.border) {
                    case "primary": crdClass += " border-primary"; break;
                    case "secondary": crdClass += " border-secondary"; break;
                    case "success": crdClass += " border-success"; break;
                    case "danger": crdClass += " border-danger"; break;
                    case "warning": crdClass += " border-warning"; break;
                    case "info": crdClass += " border-info"; break;
                    case "light": crdClass += " border-light"; break;
                    case "dark": crdClass += " border-dark"; break;
                }

                crdClass += " d-flex flex-row justify-content-between align-items-center";

                return crdClass;
            }
        }
    }
</script>