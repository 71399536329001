<template>
    <section class="authenticate-view">
        <eSesjaLogo style="padding-top: 20px" />
        <router-view />
        <eSesjaFooter />
    </section>
</template>
<script charset="utf-8">
    import eSesjaFooter from "../components/Shared/eSesjaFooter.vue";
    import eSesjaLogo from "../components/Shared/eSesjaLogo.vue";

    export default {
        name: "AuthenticateView",
        components: {
            eSesjaFooter,
            eSesjaLogo
        },
    }
</script>
<style lang="scss" scoped>
    .authenticate-view {
        width: 100vw;
        height: 100vh;
        background: radial-gradient( 235.38% 418.45% at 0% 0%, #00082c 0%, #004dcc 100% );
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        font-family: Inter;
        position: relative;
    }
</style>