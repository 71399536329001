<template>
    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" 
            @click="() => $emit('save')" 
            :disabled="disabled">
        <slot></slot>
    </button>
</template>
<script charset="utf-8">
    export default {
        name: "eSesjaModalButtonSave",
        emits: ['save'],
        props: {
            disabled: {
                type: Boolean,
                default: false,
            }
        }
    }
</script>F