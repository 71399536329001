<template>
    <section class="app-spa">
        <section class="app-spa__box">
            <section class="app-spa__box__logo">
                <img src="@/assets/imgs/logo.png" />
            </section>
            <section class="app-spa__box__form">
                <h1 class="app-spa__box__form__header">Resetowanie hasła</h1>
                <section class="app-spa__box__form__wrapper" v-if="isSuccess && showResponse && !passworchChangedSuccess">

                    <section class="app-spa__box__form__fallback-error" v-if="showPasswordStatus && passworchChangedFailed">
                        <img src="@/assets/icons/components/error-alert.svg" class="app-spa__box__form__fallback-error__icon" alt="Ikona błędu" />
                        <span class="app-spa__box__form__fallback-error__text">
                            Sprawdź poprawność wpisanych danych
                        </span>
                    </section>
                    <section class="app-spa__box__form__row">
                        <label for="user-email" class="app-spa__box__form__label">Hasło</label>
                        <input type="password"
                               name="user-email"
                               id="user-email"
                               class="app-spa__box__form__input"
                               v-model="ressetPasswordRequest.password"
                               placeholder="Podaj nowe hasło"
                               autocomplete="off" />
                    </section>
                    <section class="app-spa__box__form__row">
                        <label for="user-email" class="app-spa__box__form__label">Powtórz hasło</label>
                        <input type="password"
                               name="user-email"
                               id="user-email"
                               class="app-spa__box__form__input"
                               v-model="ressetPasswordRequest.passwordConfirm"
                               placeholder="Podaj nowe hasło"
                               autocomplete="off" />
                    </section>
                    <ReCaptchaComponent v-model="ressetPasswordRequest.reCaptchToken" />
                    <button type="button"
                            class="app-spa__box__form__btn"
                            @click="resetPassword">
                        Zmień hasło
                    </button>
                </section>


                <section class="app-spa__box__form__fallback-success" v-else-if="isSuccess && showResponse && passworchChangedSuccess && showPasswordStatus">
                    <img src="@/assets/icons/components/success-alert.svg" class="app-spa__box__form__fallback-success__icon" alt="Ikona błędu" />
                    <span class="app-spa__box__form__fallback-error__text">
                        Hasło zostało zmienione! <br />
                        Możesz <router-link :to="{name: 'Login'}" style="cursor: pointer; text-decoration:underline; font-weight:bold;">zalogować się</router-link> na swoje konto
                    </span>
                </section>


                <section class="app-spa__box__form__fallback-error" v-else-if="isError && showResponse">
                    <img src="@/assets/icons/components/error-alert.svg" class="app-spa__box__form__fallback-error__icon" alt="Ikona błędu" />
                    <span class="app-spa__box__form__fallback-error__text">
                        {{ message }}
                    </span>
                </section>

                <section class="app-spa__box__form__fallback-checking" v-else>
                    <span class="app-spa__box__form__fallback-error__text">
                        Trwa sprawdzanie linku
                    </span>
                </section>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    import ReCaptchaComponent from "../../../BasicControls/ReCaptchaComponent.vue";
    import UserService from "../../../../services/user.service";
    export default {
        name: "UserResetPassword",
        components: {
            ReCaptchaComponent
        },
        computed: {
            passwordToken() {
                return this.$route.params.token;
            },
        },
        data() {
            return {
                showResponse: false,
                isError: false,
                isSuccess: false,
                message: "",

                passworchChangedSuccess: false,
                passworchChangedFailed: false,

                showPasswordStatus: false,

                ressetPasswordRequest: {
                    password: "",
                    passwordConfirm: "",
                    token: "",
                    reCaptchToken: ""
                }
            }
        },
        async mounted() {
            await this.validateToken();
        },
        methods: {
            async validateToken() {
                try {
                    this.showResponse = false;
                    this.isError = false;
                    this.isSuccess = false;
                    this.$store.commit("showLoader");
                    let response = await UserService.validateResetPasswordToken(this.passwordToken);
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }
                    this.isSuccess = true;
                }
                catch (e) {
                    this.isError = true;
                    this.message = e.response.data;
                }
                finally {
                    this.showResponse = true;
                    this.$store.commit("hideLoader");
                }
            },

            async resetPassword() {
                try {
                    this.passworchChangedSuccess = false;
                    this.passworchChangedFailed = false;
                    this.showPasswordStatus = false;
                    this.$store.commit("showLoader");
                    this.ressetPasswordRequest.token = this.passwordToken;

                    let response = await UserService.resetUserPassword(this.ressetPasswordRequest)

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.passworchChangedSuccess = true;
                }
                catch (e) {
                    this.passworchChangedFailed = true;
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.showPasswordStatus = true;
                    this.$store.commit("hideLoader");
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "~@/assets/scss/esesja-app-spa.scss";
    .app-spa__box__form__wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
</style>