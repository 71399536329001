<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Zgłoś błąd / sugestię</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$store.commit('setReportBugModalStatus', false);}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <label class="esesja-modal__wraper__body__label">Tytuł zgłoszenia</label>
                <section class="esesja-modal__row">
                    <input class="esesja-modal__wraper__body__input" v-model="bugModel.title" placecholder="Tytuł zgłoszenia" />
                </section>
                <section class="esesja-modal__row">
                    <select class="esesja-modal__wraper__body__input" v-model="bugModel.typeId">
                        <option v-for="(item, index) in availableTypes" :key="index" :value="item.id">{{item.name}}</option>
                    </select>
                </section>
                <label class="esesja-modal__wraper__body__label">Opis</label>
                <section class="esesja-modal__row">
                    <textarea class="esesja-modal__wraper__body__input" v-model="bugModel.description" placecholder="Tytuł zgłoszenia" rows="5"></textarea>
                </section>
                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="() => { $store.commit('setReportBugModalStatus', false); }"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="saveRequestBug">Wyślij</button>
                </section>
            </perfect-scrollbar>
        </section>
    </section>
</template>
<script charset="utf-8">
    import AlertHelper from "../../../helpers/alerts.helper";
    import ReportBugService from "../../../services/reportBug.service";

    export default {
        name: "ReportBugModal",
        data() {
            return {
                bugModel: {
                    userAgent: navigator.userAgent,
                    route: "",
                    title: "",
                    description: "",
                    typeId: 1,
                },

                availableTypes: []
            }
        },
        async mounted() {
            try {
                this.$store.commit("showLoader");
                this.bugModel.route = this.stringify(this.$route.name);
                await this.getTypes();
            }
            finally {
                this.$store.commit("hideLoader");
            }

        },
        methods: {
            async saveRequestBug(){
                try {
                    this.$store.commit("showLoader");

                    let response = await ReportBugService.Create(this.bugModel);

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.$store.commit('setReportBugModalStatus', false);

                    this.$swal(AlertHelper.successAlert("Dziękuje za zgłoszenie błędu. Nasz zespół niezwłocznie zapozna się z nim."));

                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            async getTypes() {
                try {
                    let response = await ReportBugService.GetTypes();

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.availableTypes = response.data;
                }
                catch (e) {
                    console.log(e);
                }
            },
            stringify(obj) {
                let cache = [];
                let str = JSON.stringify(obj, function (key, value) {
                    if (typeof value === "object" && value !== null) {
                        if (cache.indexOf(value) !== -1) {
                            // Circular reference found, discard key
                            return;
                        }
                        // Store value in our collection
                        cache.push(value);
                    }
                    return value;
                });
                cache = null; // reset the cache
                return str;
            }
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/esesja-modal.scss';
</style>