<template>
    <button class="esesja-modal__wraper__body__bottom-button" @click="() => $emit('close')">
        <slot></slot>
    </button>
</template>
<script charset="utf-8">
    export default {
        name: "eSesjaModalButtonCancel",
        emits: ['close']
    }
</script>