<template>
    <p class="card-text">
        <slot></slot>
    </p>
</template>

<script charset="utf-8">
export default {
    name: "CardText",
}
</script>