export const publicStore = {
    state: () => ({
        interactiveVotingModalStatus: false,
        publicPlaceholderViewModalStatus: false,
    }),
    mutations: {
        setInteractiveVotingModalStatus(state, status) {
            state.interactiveVotingModalStatus = status;
        },
        setPublicPlaceholderViewModalStatus(state, status) {
            state.publicPlaceholderViewModalStatus = status;
        },
    }
}