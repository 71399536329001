import meetingsService from "../services/meetings.service";
import store from "../store/mainStore";
export const meetingTemplateActions = [
    {
        id: 0,
        action: "sessionTemplateEdit",
        name: "Edytuj szablon",
        icon: "edit-session-list",
        link: "sessions-template-edit",
        handler: () => { },
    },
    //{
    //    id: 1,
    //    action: "sessionInteractive",
    //    name: "Tryb sesji interaktywnej",
    //    icon: "display",
    //    handler: () => {},
    //},
    {
        id: 2,
        action: "",
        name: "Ustawienia szablonu",
        icon: "settings",
        handler: (id) => {
            store.commit("setTemplateId", id);
            store.commit("showAddMeetingTemplate");
        },
    },
    {
        id: 3,
        action: "",
        name: "Przygotuj posiedzenie",
        icon: "save",
        handler: (id) => {
            store.commit("showAddMeeting", id);
        },
    },
    //{
    //    id: 4,
    //    action: "",
    //    name: "Pobierz projekt protokołu",
    //    icon: "download",
    //    handler: async (id) => {
    //        try {
    //            store.commit("showLoader");
    //            let result = await SessionService.getExportedSessionProtocolToWord(id);
    //            if (result.status == 200) {
    //                const contentType = result.headers['content-type'];
    //                let a = document.createElement('a');
    //                var blob = new Blob([result.data], { 'type': contentType });
    //                a.href = window.URL.createObjectURL(blob);
    //                var filename = result.headers['content-disposition'].split("filename=")[1];

    //                filename = filename.substr(0, filename.indexOf(';'));
    //                filename = filename.replaceAll('"', '');
    //                a.download = filename
    //                a.click();
    //            }
    //        }
    //        finally {
    //            store.commit("hideLoader");
    //        }
    //    },
    //},
    //{
    //    id: 5,
    //    action: "",
    //    name: "Dodaj protokół",
    //    icon: "add-protocol",
    //    handler: (id) => {
    //        store.commit("showAddProtocol", id);
    //    },
    //},
    //{
    //    id: 6,
    //    action: "",
    //    name: "Eksportuj porządek obrad do pliku .DOC",
    //    icon: "export-to-doc",
    //    handler: async (id) => {
    //        try {
    //            store.commit("showLoader");
    //            let result = await SessionService.getExportedSessionToWord(id);
    //            if (result.status == 200) {
    //                const contentType = result.headers['content-type'];
    //                let a = document.createElement('a');
    //                var blob = new Blob([result.data], { 'type': contentType });
    //                a.href = window.URL.createObjectURL(blob);
    //                var filename = result.headers['content-disposition'].split("filename=")[1];

    //                filename = filename.substr(0, filename.indexOf(';'));
    //                filename = filename.replaceAll('"', '');
    //                a.download = filename
    //                a.click();
    //            }
    //        }
    //        finally {
    //            store.commit("hideLoader");
    //        }
    //    },
    //},
    //{
    //    id: 7,
    //    action: "",
    //    name: "Pokaż metryczkę posiedzenia",
    //    icon: "timer",
    //    handler: (id) => {
    //        store.commit("showSessionMetadata", id);
    //    },
    //},
    {
        id: 8,
        action: "",
        name: "Usuń szablon",
        icon: "delete",
        handler: (id) => {
            const actionToFire = () => {
                store.commit("showLoader");
                meetingsService.removeSession(id).then(() => {
                    var templates = store.state.appStore.sessionTemplates;
                    var index = templates.findIndex(x => x.id == id);
                    if (index != -1) {
                        templates.splice(index, 1);
                    }
                    store.commit("hideLoader");
                    store.commit("showSuccess");
                });
            };
            store.commit("showConfirmationAlert", actionToFire);
        },
    },
];
