export default class UploaderFileState {

    static UploadWaiting = new UploaderFileState("UploaderStateWaiting");
    static UploadProgres = new UploaderFileState("UploaderStateInProgress")
    static UploadSuccess = new UploaderFileState("UploaderStateSuccess");
    static UploadFail = new UploaderFileState("UploaderStateFail");

    constructor(name) {
        this.name = name;
    }

    getStatusOrder() {
        return {
            UploadProgres: 10,
            UploadFail: 20,
            UploadWaiting: 30,
            UploadSuccess: 40
        }
    }
}