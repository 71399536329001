export const calendarStore = {
    state: () => ({
        weekDays: [
            { id: 0, order: 6, display: "Niedziela" },
            { id: 1, order: 0, display: "Poniedziałek" },
            { id: 2, order: 1, display: "Wtorek" },
            { id: 3, order: 2, display: "Środa" },
            { id: 4, order: 3, display: "Czwartek" },
            { id: 5, order: 4, display: "Piątek" },
            { id: 6, order: 5, display: "Sobota" }],
        
        currentFirstDayOfWeek: { id: 0, order: 7, display: "Niedziela" },
        currentLastDayOfWeek: { id: 0, order: 7, display: "Niedziela" },
        currentDaysInMonth: 0,
        currentDate: null,
        todayDate: null,
        dataArray: [],
        isShowContenxtMenu: false,
        contextMenuPos:{
            x: 0,
            y: 0,
        },
        contextMenuDate: null,
    }),
    mutations: {

    },
}