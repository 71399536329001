<template>
    <section class="calendar-event announcement" @click="pushRouter" v-tooltip="getTooltip">
        <span class="calendar-event--name">
            {{ event.name }}
        </span>
    </section>
</template>
<script charset="utf-8">
    export default{
        name: "CalendarEventAnnouncement",
        props:{
            event: {
                type: Object
            }
        },
        computed:{
            getTooltip(){
                return `Przejdź do komunikatu ${this.event.name}`
            }
        },
        methods:{
            async pushRouter(){
                await this.$router.push(this.event.router);
            }
        }
    }
</script>
<style lang="scss" scoped>
@import "@/assets/scss/calendar-event.scss";

.announcement{
    background: rgba(0, 77, 204, 0.6);
    color: #fff;
    &:hover{
        background: rgba(0, 77, 204, 1);
    }
}
</style>