<template>
    <section class="person">
        <PulseDot v-if="item.status === 10" color="orange" v-tooltip="'Nieaktywny'" />
        <PulseDot v-else-if="item.status === 30" color="green" v-tooltip="'Online'" />
        <img :src="item.avatar" :alt="`${item.first_name} ${item.last_name}`" class="person--avatar" />
        <section class="person--data">
            <span class="person--data--personal">
                <span v-html=item.first_name></span> <span v-html=item.last_name></span>
            </span>
            <span class="person--data--function">
                <span v-html=item.role></span>
            </span>
        </section>
    </section>
</template>
<script charset="utf-8">
import PulseDot from '../../../BasicControls/PulseDot.vue';

export default {
    name: "UserOnlineItem",
    components:{
        PulseDot
    },
    props: {
        item: {
            required: true,
            type: Object,
        }
    }
}
</script>
<style lang="scss">
.person {
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px 5px;

    &:nth-child(odd) {
        background: #f2f2f2;
    }

    &:hover {
        background: #e8ebef;
    }

    &--avatar {
        width: 40px;
        height: 40px;
        vertical-align: middle;
        border-radius: 50%;
    }

    &--data {
        display: flex;
        flex-direction: column;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &--personal {
            font-weight: 600;
        }

        &--function {
            font-weight: 600;
            font-size: 11px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #808396;
        }
    }

    &--match {
        color: #000;
        background: #9eb8ff;
    }
}
</style>