import ApiService from "./api.service";

class MeetingService extends ApiService {
    groups(ses) {
        return this.get(`Group/groups/${ses}`);
    }
    groupsForVoting(sessionId) {
        return this.get(`Group/getGroupsForVoting/${sessionId}`);
    }
    sessions() {
        return this.get("Session/sessions");
    }
    getArchiveSessions() {
        return this.get("Session/getArchiveSessions");
    }
    getSessionWithPointsById(id) {
        return this.get(`Session/sessionswithpoints/${id}`);
    }
    getSessionById(id) {
        return this.get(`Session/sessions/${id}`);
    }
    updateSession(id, data) {
        return this.post(`Session/sessions/${id}`, data);
    }
    removeSession(id) {
        return this.delete(`Session/sessions/${id}`);
    }
    add(data) {
        return this.post("Session/sessions", data);
    }

    addByTemplate(id, data) {
        return this.post(`Session/sessions/template/${id}/addNewSession`, data)
    }


    // niepotrzebne - zrobiono po filtracji wszystkich pobranych na poczatku
    //sessionsToday() {
    //    return this.post("/sessions/today");
    //}
    //sessionsTomorrow() {
    //    return this.post("/sessions/tomorrow");
    //}
    //sessionsThisWeek() {
    //    return this.post("/sessions/this-week");
    //}
    //sessionsThisMonth() {
    //    return this.post("/sessions/this-month");
    //}
}

export default new MeetingService();
