export const importContactStore = {
    state: () => ({
        importSteps: [
            {
                step: 1,
                label: "Importowanie",
                tooltip: "Importowanie",
                routeName: ""
            },
            {
                step: 2,
                label: "Edycja",
                tooltip: "Edycja",
                routeName: ""
            },
        ],
        currentStep: 1,
        stepsDone: [],
        requiredSteps: [1],
        importData: [],
        allContactsHaveGroups: true,
    }),
    mutations: {
        setCurrentImportStep(state, step) {
            state.currentStep = step;
        },

        setContactImportData(state, data) {
            state.importData = data;
        },
        setContactImportStepDone(state, step) {
            let internalSteps = [...state.stepsDone];
            internalSteps.push(step)
            state.stepsDone = [...new Set(internalSteps)];
        },
        setContactImportAllHaveGroups(state, status) {
            state.allContactsHaveGroups = status;
        }
    }
}