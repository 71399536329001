import ApiService from "./api.service";

class InterpellationService extends ApiService {
    getAllInterpelattions(page) {
        return this.get(`Interpellation/getAllInterpellations/${page}`)
    }
    searchInterpellation(data) {
        return this.post(`Interpellation/search`, data);
    }
    deleteInterpelation(id) {
        return this.delete(`Interpellation/deleteInterpelation/${id}`)
    }
    updateInterpellation(data) {
        return this.post(`Interpellation/updateInterpellation`, data)
    }
    addInterpellation(data) {
        return this.post(`Interpellation/addInterpellation`, data)
    }
    addInterpellationAttachement(data) {
        return this.post(`Interpellation/addInterpellationAttachement`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }
    deleteFile(intId, fileId) {
        return this.delete(`Interpellation/deleteFile/${intId}/${fileId}`)
    }
    downloadAttachment(intId, fileId) {
        return this.get(`Interpellation/downloadAttachment/${intId}/${fileId}`, { responseType: "arraybuffer" })
    }
    getInterpellationResponses(data) {
        return this.post(`Interpellation/getInterpellationResponses`, data)
    }
    deleteInterpelationResponse(id) {
        return this.delete(`Interpellation/deleteInterpelationResponse/${id}`)
    }
    addInterpellationResponse(data) {
        return this.post(`Interpellation/addInterpellationResponse`, data);
    }
    getUsersForInterpelattions() {
        return this.get(`Interpellation/getUsersForInterpelattions`);
    }
    getNewInterpelationsCount() {
        return this.get(`Interpellation/getNewInterpelationsCount`);
    }
}

export default new InterpellationService();