import ApiService from "./api.service";

class ToolsService extends ApiService {
    getUsersActivityData() {
        return this.get(`Tools/getUsersActivityData`);
    }

    getUserLastLogins(id, page) {
        return this.get(`Tools/getUserLastLogins/${id}/${page}`)
    }

    getUserLastLoginsDetails() {
        return this.get(`Tools/getUserLastLoginsDetails`);
    }
    getUserPresenceReport(data) {
        return this.post(`Tools/getUserPresenceReport`, data)
    }

    getDocuments(id, page) {
        return this.get(`Tools/getDocuments/${id}/${page}`)
    }

    changeDocumentName(data) {
        return this.post(`Tools/changeDocumentName`, data)
    }

    deleteDocument(data) {
        return this.post(`Tools/deleteDocument`, data)
    }

    addFile(data) {
        return this.post(`Tools/addNewDocument`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    downloadAttachment(id) {
        return this.get(`Tools/downloadFile/${id}`, { responseType: "arraybuffer" });
    }

    addSessionAttachementFromRepository(data) {
        return this.post(`Tools/addSessionAttachementFromRepository`, data)
    }

    getVersionsHistory() {
        return this.get(`Tools/getVersionsHistory`)
    }

    addVersionToHistory(data) {
        return this.post(`Tools/addVersionToHistory`, data)
    }

    deleteVersionFromHistory(id) {
        return this.delete(`Tools/deleteVersionFromHistory/${id}`)
    }
    getServerTime() {
        return this.get(`Tools/server-time`)
    }
}

export default new ToolsService();
