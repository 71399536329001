<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Adres E-Mail nie potwierdzony</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e) =>{ e.preventDefault(); this.$store.commit('toggleNoEmailConfirmDetails', false);}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section class="esesja-modal__wraper__body__fallback-error" v-if="showResponse && isFailed">
                    <section class="esesja-modal__wraper__body__fallback-error__icon">
                        <img src="@/assets/icons/components/error-alert.svg" />
                    </section>
                    <section class="esesja-modal__wraper__body__fallback-error__message">
                        {{ message }}
                    </section>
                </section>
                <section class="esesja-modal__wraper__body__fallback-success" v-if="showResponse && isSuccess">
                    <section class="esesja-modal__wraper__body__fallback-success__icon">
                        <img src="@/assets/icons/components/success-alert.svg" />
                    </section>
                    <section class="esesja-modal__wraper__body__fallback-success__message">
                        Wiadomość z linkiem potwierdzającym wysłano poprawnie. 
                    </section>
                </section>
                <section class="esesja-modal__row esesja-modal--text">
                    Brak potwierdzenia adresu E-Mail może powodować następujące skutki:
                    <ul>
                        <li>Brak możliwości resetowania hasła</li>
                        <li>Brak powiadomień systemowych</li>
                    </ul>
                    Aby potwierdzić adres należy kliknąć w link, który został wysłany na podany w systemie adres e-mail. Jeśli wiadomość nie przyszła należy sprawdzić folder spam oraz poprawność wpisanego adresu.
                    Jeśli link aktywacyjny ma zostać ponownie wysłany kliknij w poniższy przycisk.
                </section>
                <section class="esesja-modal__row esesja-modal--center" v-if="!isSuccess">
                    <button class="send-confirmaction-link-button send-confirmaction-link-button--white"
                            @click="sendNewEmailConfirmation">
                        Wyślij link aktywacyjny
                    </button>
                </section>
            </perfect-scrollbar>
        </section>
    </section>
</template>
<script charset="utf-8">
    import UserService from "@/services/user.service";
    export default {
        name: "NoEmailConfirmedDetails",
        data() {
            return {
                showResponse: false,
                isSuccess: false,
                isFailed: false,
                message: "",
            }
        },
        methods: {
            async sendNewEmailConfirmation() {
                try {
                    this.showResponse = false;
                    this.isSuccess = false;
                    this.isFailed = false;
                    this.message = "";
                    this.$store.commit("showLoader");
                    let response = await UserService.sendEmailActivation();
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }
                    this.isSuccess = true;
                }
                catch (e) {
                    this.isFailed = true;
                    this.message = e.response.data;
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.showResponse = true;
                    this.$store.commit("hideLoader");
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/esesja-modal.scss';

    .send-confirmaction-link-button {
        margin: 10px 0px 0px 0px;
        padding: 13.5px 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #d80418;
        border-radius: 4px;
        border: 0px;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #ad0313;
        }

        &__text {
            color: white;
            font-weight: 500;
            line-height: 132%;
        }

        &--small {
            padding: 7px 16px;
        }

        &--white {
            border: 1px solid #bbc2ce;
            background-color: white;


            &:hover {
                background-color: #e8ebef;
            }
        }
    }
</style>