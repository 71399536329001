<template>
    <section>
        <Transition>
            <ReportBugModalVue v-if="$store.state.modals.tools.reportBugModalStatus" />
        </Transition>
        <Transition>
            <SendMeetingRegarding v-if="$store.state.modals.sessions.sendMeetingRegardingModalStatus" />
        </Transition>
        <Transition>
            <PrepareMeetingInvitation v-if="$store.state.modals.sessions.prepareMeetingInvitationModalStatus" />
        </Transition>

        <Transition>
            <SessionAudioLinkModal v-if="$store.state.modals.sessions.audioVideoLinkModalStatus" />
        </Transition>

        <Transition>
            <AddPlaceholder v-if="$store.state.placeholderStore.isShowAddPlaceholder" />
        </Transition>
    </section>
</template>
<script charset="utf-8">
    /// Tools
    import SendMeetingRegarding from "./Session/SendMeetingRegarding.vue"
    import PrepareMeetingInvitation from "./Session/PrepareMeetingInvitation.vue"
    import ReportBugModalVue from "./Tools/ReportBugModal.vue"
    import SessionAudioLinkModal from "./Session/SessionAudioVideoLinkModal.vue";

    /// Placeholders
    import AddPlaceholder from "../Panel/Content/Settings/Parts/Modals/AddPlaceholder.vue"
    export default {
        name: "ModalsComponents",

        components: {
            /// Tools
            ReportBugModalVue,
            SendMeetingRegarding,
            PrepareMeetingInvitation,
            SessionAudioLinkModal,
            AddPlaceholder
        }
    }
</script>