<template>
    <section class="meeting-wrapper" v-if="list.length > 0" style="position: relative;">
        <p class="meeting-wrapper__title">{{ name }}<span v-if="date !== null">, {{ date }}</span></p>
        <section v-for="(item, index) in list" :key="index" style="position: relative; width: 100%; ">
        <!--<section v-for="item in list" :key="item.id" @contextmenu="openContextMenu" style="position: relative; width: 100%; ">-->
            <!--<MeetingsActions :sessionId="item.id" :groupID="item.groups[0].id" :sessionLocked="item.is_end" />-->
            <Meeting v-if="item.groups.length > 0"
                     :name="item.name"
                     :number="item.number"
                     :dateTime="item.meeting_date"
                     :endDateTime="item.end_meeting_date"
                     :id="item.id"
                     :isPublic="item.is_public"
                     :isAuthorized="item.is_authorized"
                     :groupid="item.groups[0].id"
                     :groupNumeration="item.groups[0].session_number_format_id" 
                     :groups="item.groups"
                     :place="item.meeting_place" />
        </section>
    </section>
    <section v-else class="meeting-wrapper">
        <p class="meeting-wrapper__title">{{ name }}</p>
        <p class="meeting-wrapper__no-meetings">Brak spotkań</p>
    </section> 
</template>

<script>
    //import MeetingsActions from "../../../NavMenu/MeetingsActions.vue";
    import Meeting from "./Meeting.vue";

    export default {
        name: "MeetingsWrapper",
        components: {
            Meeting,
            //MeetingsActions
        },
        props: {
            name: String,
            list: Array,
            date: String,
        },
        data() {
            return {
                tempContextMenu: null,
                isLoading: false,
            }
        },
        mounted() {
            document.addEventListener("click", (e) => {
                const agendaContext = document.getElementById("js-context-session-list");
                if (agendaContext !== null) {
                    const isClickInside = agendaContext.contains(e.target);
                    if (!isClickInside) {
                        document
                            .querySelectorAll(".calendar-data")
                            .forEach((box) => (box.style.display = "none"));
                        agendaContext.style.display = "none";
                        agendaContext.removeAttribute("id");
                    }
                }
            });
        },
        methods: {
            openContextMenu(e) {
                e.preventDefault();
                var rect = e.target.getBoundingClientRect();

                var x = e.clientX - rect.left;
                var y = e.clientY - rect.top;

                const agendaContext = document.querySelectorAll(
                    ".session-search-context-menu"
                );
                agendaContext.forEach((block) => (block.style.display = "none"));
                const contextMenu = e.currentTarget.children[0];
                contextMenu.style.display = "block";
                contextMenu.id = "js-context-session-list";
                const element = document.getElementById(contextMenu.id);
                const viewportOffset = element.getBoundingClientRect();
                const top = viewportOffset.top;
                const left = viewportOffset.left;

                if (left < 0 || top > window.innerHeight) {
                    contextMenu.style.position = "absolute";
                    contextMenu.style.bottom = "auto";
                    contextMenu.style.left = "auto";
                    contextMenu.style.top = "0px";
                    contextMenu.style.right = "0px";
                }
                else {
                    contextMenu.style.position = "absolute";
                    contextMenu.style.bottom = "auto";
                    contextMenu.style.top = `${y}px`;
                    contextMenu.style.left = `${x}px`;
                    contextMenu.style.right = "auto";
                }
                this.tempContextMenu = contextMenu;
            },

            closeContextMenu() {
                if (this.tempContextMenu != null) {
                    this.tempContextMenu.style.display = "none";
                }
            },
        }
    };
</script>

<style lang="scss" scoped>
    .meeting-wrapper {
        margin: 28px;

        &__title {
            font-weight: 600;
            font-size: 13px;
            line-height: 120%;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #666b80;
        }

        &__no-meetings {
            font-size: 20px;
            margin-top: 12px;
        }
    }
</style>
