<template>
    <section class="contacts">
        <section class="contacts__head">
            <h1 class="contacts__head__title">Interpelacje i zapytania</h1>
            <section class="contact-top__action-buttons" v-if="interAdminPrivilige && interSendAdminPrivilige && !showSpecyficInterpellation">
                <ButtonWithIcon icon="plus-small" style=""
                                text="Dodaj interpelację"
                                :classModified="true"
                                @click="(e) => addInterpellation(null)" />
            </section>
        </section>
        <section class="contact-top" v-if="!showSpecyficInterpellation">
            <section class="contact-top__search">
                <form class="contact-top__form">
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Wyszukaj interpelację lub zapytanie'" />
                        <SearchInputTextComponent v-model="internalRequestQuery.searchText"
                                                  :placeholder="'Wyszukaj ...'" />
                    </section>
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Data wysłania od'" />
                        <SearchInputDateComponent v-model="internalRequestQuery.dateFrom" />
                    </section>
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Data wysłania do'" />
                        <SearchInputDateComponent v-model="internalRequestQuery.dateTo" />
                    </section>
                </form>
            </section>
        </section>
        <Pagination v-if="!showSpecyficInterpellation"
                    :totalItems="paginatedData.totalItems"
                    :currentPage="paginatedData.currentPage"
                    :itemsPerPage="paginatedData.itemsPerPage"
                    @goToPage="changePage" />
        <section class="contacts__list" v-if="interpelattions != null && interpelattions.length > 0 && !showSpecyficInterpellation">
            <section class="contact-list">
                <section v-for="s in checkFilter" :key="s.id">
                    <section class="contact-box" :style="s.responseCount !== null && s.responseCount > 0 ? 'border: 1px solid green;' :'border: 1px solid red;' ">
                        <section class="contact-box__top">
                            <section>
                                <div style="margin-bottom: 10px;" v-if="s.isPublic">
                                    <span class="dot">Publiczne</span> 
                                </div>
                                <div>
                                    <span class="contact-box__title"><b>{{s.typeName }} {{ s.number != null ? s.number : s.id }}</b></span>
                                </div>

                                <div v-if="s.title != null && s.title != ''">
                                    <span class="contact-box__title">Tytuł: <b>{{ s.title }}</b></span>
                                </div>
                                <span class="contact-box__title">Autor: <b>{{ s.author }}</b> (Dodano: {{formatDateToDisplay(s.time)}}) Odpowiedzi: ({{s.responseCount != null ? s.responseCount : 0}}) </span>

                                <section style="margin-top:10px;" v-if="s.files != null && s.files.length > 0">
                                    <h5 class="add-note-form__label">Załączniki</h5>
                                    <div>
                                        <ul class="add-note-form__labelFile" v-for="item in s.files" :key="item.id">
                                            <li style="margin-top: 5px;">
                                                <span style="cursor: pointer; margin-right: 5px;" @click="downloadAttachement(s.id, item.id)">{{item.name}}</span>
                                                <button @click="deleteFile(s.id, item.id)" class="delete-button" v-tooltip="'Usuń załącznik'">
                                                    <img src="../../../../assets/icons/components/delete-point.svg" />
                                                </button>
                                            </li>
                                        </ul>
                                    </div>


                                </section>

                            </section>
                            <section class="contact-box-actions">

                                <ButtonOnlyWithIcon icon="eye" @click="showInterpellation(s)" v-if="interAdminPrivilige && interSendAdminPrivilige" v-tooltip="'Pokaż interpelację'" />

                                <ButtonOnlyWithIcon icon="edit" @click="addInterpellation(s)" v-if="interAdminPrivilige" v-tooltip="'Edytuj interpelację'" />

                                <ButtonOnlyWithIcon icon="attachment" @click="addFile(s)" v-if="interAdminPrivilige" v-tooltip="'Dodaj załącznik'" />

                                <ButtonOnlyWithIcon icon="delete-point" @click="deleteInterpelation(s.id)" v-if="interAdminPrivilige" v-tooltip="'Usuń interpelację'" />
                            </section>
                        </section>

                    </section>
                </section>

            </section>
        </section>
        <section class="contacts__list" v-else-if="interpelattions != null && interpelattions.length === 0 && !showSpecyficInterpellation">
            <section class="contact-list">
                <section>
                    <section class="contact-box">
                        <section class="contact-box__top">
                            <section>
                                <span class="contact-box__title">Brak wyników spełniających podane kryteria</span>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <Pagination v-if="!showSpecyficInterpellation"
                    :totalItems="paginatedData.totalItems"
                    :currentPage="paginatedData.currentPage"
                    :itemsPerPage="paginatedData.itemsPerPage"
                    @goToPage="changePage" />
        <Transition>
            <AddInterpellationtModal v-if="showAddInterpellationtModal" @closeAddInterpellation="closeAddInterpellationtModal" @updateInterpellations="updateInterpellationList" :options="typesOptions" :options2="usersOptions" :selected="selectedType" :selected2="selectedUsers" :elemToUpdate="elemToUpdate" />
        </Transition>

        <Transition>
            <ShowInterpellationtModal v-if="showSpecyficInterpellation" @closeShowInterpellation="closeShowInterpellationModal" @updateInterpellationsResponseCount="upRespCount" :inter="inter" :responses="responses" />
        </Transition>

    </section>
</template>

<script charset="utf-8">
    import SearchLabelComponent from "../../../BasicControls/SearchLabelComponent.vue";
    import SearchInputTextComponent from "../../../BasicControls/SearchInputTextComponent.vue";
    import SearchInputDateComponent from "../../../BasicControls/SearchInputDateComponent.vue";
    import Pagination from "../../../BasicControls/Pagination.vue";

    import InterpellationService from "../../../../services/interpellation.service";
    import ButtonWithIcon from "../../../BasicControls/ButtonWithIcon.vue";
    import ButtonOnlyWithIcon from "../../../BasicControls/ButtonOnlyWithIcon.vue";
    import AddInterpellationtModal from "../Interpellations/Modals/AddInterpellation.vue";
    import ShowInterpellationtModal from "../Interpellations/Modals/ShowInterpellation.vue";
    import { encryptStorage } from '../../../../services/encrypt.service';
    import AlertHelper from '@/helpers/alerts.helper.js';
    import CommonHelper from "../../../../helpers/common.helper";
    import uploaderService from "../../../../services/uploader.service";

    export default {
        name: "interpellations",
        data() {
            return {
                searchBar: "",
                interpelattions: [],
                types: [],
                showAddInterpellationtModal: false,
                interAdminPrivilige: encryptStorage.getItem('priv').zarzadzanie_interpelacjami,
                interSendAdminPrivilige: encryptStorage.getItem('priv').wysylanie_interpelacji,
                interpellationModule: encryptStorage.getItem('modules').int,
                selectedType: [],
                selectedUsers: [],
                typesOptions: [],
                usersOptions: [],
                files: [],
                elemToUpdate: {},
                groups: [],
                page: 1,
                showSpecyficInterpellation: false,
                inter: {},
                responses: [],
                paginatedData: {
                    currentPage: -1,
                    itemsPerPage: 10,
                    totalItems: -1,
                },
                internalRequestQuery: {
                    searchText: null,
                    searchStatus: null,
                    dateFrom: null,
                    dateTo: null,
                    page: 1,
                    itemsPerPage: 10,
                },

                isLoading: false,
            };
        },
        watch: {
            internalRequestQuery: {
                deep: true,
                handler: function (newValue) {
                    let queryParam = CommonHelper.getNonNullObjectEntries(newValue);
                    this.$router.replace({ name: "interpellations", query: queryParam });
                    //this.fetchSmsData();
                    //this.fetchSessions(queryParam);
                }
            },
            $route() {
                if (this.$route.name === "interpellations" && Object.keys(this.$route.query).length > 0) {
                    this.queryParamsToRequestSearch();
                    this.fetchInterpellation();
                }
            },

            isAnyModalOpen: {
                deep: true,
                handler: function (val) {
                    document.querySelector('body').style.overflow = val ? 'hidden' : 'auto';
                }
            },
        },
        computed: {
            checkFilter() {
                return this.interpelattions.filter((item) => {
                    var elem = item.typeName + " ";
                    if (item.number != null)
                        elem += item.number + " ";
                    elem += item.id + " ";
                    if (item.title != null)
                        elem += item.title + " ";
                    if (item.author != null)
                        elem += item.author + " ";
                    elem += this.formatDateToDisplay(item.time);

                        return elem.toLowerCase().includes(this.searchBar.toLowerCase());
                });
            },
            requestQuery() {
                return this.$route.query;
            },

            isAnyModalOpen(){
                return this.showAddInterpellationtModal;
            }
        },
        methods: {

            async fetchInterpellation() {
                try {
                    this.isLoading = true;
                    this.$store.commit("showLoader");
                    let requestData = this.internalRequestDataToAsyncRequestData();
                    let response = await InterpellationService.searchInterpellation(requestData);
                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }
                    this.interpelattions = response.data.items;
                    this.types = response.data.types;
                    this.paginatedData = response.data.paginated;
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading = false;
                    this.$store.commit("hideLoader");
                }
            },

            internalRequestDataToAsyncRequestData() {
                return {
                    data: {
                        searchText: this.internalRequestQuery.searchText,
                        searchStatus: this.internalRequestQuery.searchStatu,
                        dateFrom: this.internalRequestQuery.dateFrom,
                        dateTo: this.internalRequestQuery.dateTo,
                    },
                    paginated: {
                        page: this.internalRequestQuery.page,
                        itemsPerPage: this.internalRequestQuery.itemsPerPage,
                    }
                }
            },

            setDefaultRequestQuery() {
                this.clearRequestData();
                let queryParam = CommonHelper.getNonNullObjectEntries(this.internalRequestQuery);
                this.$router.replace({ name: "interpellations", query: queryParam });
            },

            changePage(page) {
                this.internalRequestQuery.page = page;
            },
            clearRequestData() {
                this.internalRequestQuery.searchText = null;
                this.internalRequestQuery.searchStatus = null;
                this.internalRequestQuery.dateFrom = null;
                this.internalRequestQuery.dateTo = null;
                this.internalRequestQuery.page = 1;
                this.internalRequestQuery.itemsPerPage = 10;
            },
            queryParamsToRequestSearch() {

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "searchText")) {
                    this.internalRequestQuery.searchText = this.requestQuery.searchText;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "dateFrom")) {
                    this.internalRequestQuery.dateFrom = this.requestQuery.dateFrom;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "dateTo")) {
                    this.internalRequestQuery.dateTo = this.requestQuery.dateTo;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "_r")) {
                    this.clearRequestData();
                }
            },
            addFile(value) {
                try {
                    let input = document.createElement('input');
                    input.type = 'file';
                    input.accept = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.png,.jpg,.jpeg,.xls,.ods,.odt,.txt,.rtf,.xlsx';
                    input.multiple = true;
                    input.click();
                    input.addEventListener('change', async (event) => {
                        try {
                            this.$store.commit("showLoader");
                            let response = await uploaderService.UploadInterpelationAttachements(value?.id, value?.lid, event, false);

                            var annoUp = this.interpelattions.find(x => x.id == value.id);
                            if (annoUp !== null) {
                                annoUp.files = annoUp.files.concat([...response]);
                            }
                        }
                        catch (e) {
                            console.log(e);
                        }
                        finally {
                            this.$store.commit("hideLoader");
                        }
                    });
                }
                catch (e) {
                    console.log(e);
                }
            },
            checkMimeType(type) {
                if (type != "application/pdf" && type != "image/jpeg" && type != "image/jpg" &&
                    type != "image/png" && type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                    type != "text/plain" && type != "application/vnd.oasis.opendocument.text" && type != "application/vnd.oasis.opendocument.spreadsheet" &&
                    type != "application/vnd.ms-excel" && type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && type != "application/rtf" && type != "application/msword") {
                    return false;
                } else {
                    return true;
                }
            },
            deleteFile(intId, fileId) {
                const actionToFire = () => {
                    this.isLoading = true;
                    InterpellationService.deleteFile(intId, fileId).then(() => {
                        var index = this.interpelattions.findIndex(x => x.id == intId);
                        if (index != -1) {
                            var z = this.interpelattions[index].files.findIndex(x => x.id == fileId);
                            if (z != -1) {
                                this.interpelattions[index].files.splice(z, 1);
                            }
                        }
                    });
                };
                this.$store.commit("showConfirmationAlert", actionToFire);
            },
            getNewInterpellationCountForDisplay() {
                InterpellationService.getNewInterpelationsCount().then((response) => {
                    this.$store.commit('setNewInterpellationsCount', response.data);
                }).catch((e) => {
                    console.log(e);
                });
            },
            async downloadAttachement(intId, fileId) {
                try {
                    var result = (await InterpellationService.downloadAttachment(intId, fileId));
                    if (result.status == 200) {
                        const contentType = result.headers['content-type'];
                        var a = document.createElement('a');
                        var blob = new Blob([result.data], { 'type': contentType });
                        a.href = window.URL.createObjectURL(blob);
                        a.download = result.headers['content-disposition'].split('filename=')[1].split(';')[0];
                        a.click();
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async sendFileToDb(int, files) {
                try {
                    let formData = new FormData();
                    for (var i = 0; i < files.length; i++) {
                        let file = files[i][0];
                        formData.append('files[' + i + ']', file);
                    }

                    formData.append("id", int.id);
                    formData.append("lid", int.lid);
                    formData.append("response", false);

                    var result = (await InterpellationService.addInterpellationAttachement(formData)).data;
                    if (Object.keys(result).length > 0) {
                        this.files = [];
                        var annoUp = this.interpelattions.find(x => x.id == int.id);
                        if (annoUp != null) {
                            var f = {
                                id: result.id,
                                name: result.name,
                            };
                            annoUp.files.push(f);
                        }

                        this.getNewInterpellationCountForDisplay();
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            closeAddInterpellationtModal() {
                this.selectedType = [];
                this.selectedUsers = [];
                this.elemToUpdate = {};
                this.typesOptions = [];
                this.usersOptions = [];
                this.showAddInterpellationtModal = false;
            },
            closeShowInterpellationModal() {
                this.responses = [];
                this.inter = {};
                this.showSpecyficInterpellation = false;
            },
            deleteInterpelation(value) {
                const actionToFire = () => {
                    InterpellationService.deleteInterpelation(value).then((result) => {
                        if (result.status == 200) {
                            var index = this.interpelattions.findIndex(x => x.id == value);
                            if (index != -1) {
                                this.interpelattions.splice(index, 1);
                            }
                        }
                        this.getNewInterpellationCountForDisplay();
                    });
                };
                this.$store.commit("showConfirmationAlert", actionToFire);
            },
            upRespCount(data) {
                if (data != null && data.id != null) {
                    var index = this.interpelattions.findIndex(x => x.id == data.id);
                    if (index != -1) {
                        this.interpelattions[index].responseCount += data.value;
                        this.getNewInterpellationCountForDisplay();
                    }
                }
            },
            async showInterpellation(interpellation) {
                try {

                    var data = {
                        id: interpellation.id,
                        time: interpellation.time
                    };


                    var result = (await InterpellationService.getInterpellationResponses(data)).data;
                    if (Object.keys(result).length > 0) {
                        this.responses = result;
                    }
                    this.inter = interpellation;
                    this.showSpecyficInterpellation = true;
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async addInterpellation(value) {
                try {
                    this.$store.commit('showLoader');
                    var result = (await InterpellationService.getUsersForInterpelattions()).data;
                    if (Object.keys(result).length > 0) {
                        this.typesOptions = [];
                        this.usersOptions = [];

                        for (var i = 0; i < result.length; i++) {
                            for (var j = 0; j < result[i].options.length; j++) {
                                if (this.usersOptions.find(x => x.value == result[i].options[j].value) == null) {
                                    this.usersOptions.push(result[i].options[j]);
                                }
                            }
                        }

                        this.typesOptions = this.types;
                        this.selectedType = [];
                        this.selectedUsers = [];

                        if (value != null) {
                        
                            this.elemToUpdate = {};
                            this.selectedType.push(value.type);

                            for (var k = 0; k < value.lid.length; k++) {
                                this.selectedUsers.push(value.lid[k]);
                            }

                            this.elemToUpdate = {
                                typeName: value.typeName,
                                type: value.type,
                                responseCount: value.responseCount,
                                id: value.id,
                                author: value.author,
                                body: value.body,
                                isPublic: value.isPublic,
                                time: value.time,
                                number: value.number,
                                title: value.title,
                                isEdit: true,
                            }
                        } else {
                            this.elemToUpdate = {
                                typeName: '',
                                type: 0,
                                responseCount: 0,
                                id: 0,
                                author: '',
                                body: '',
                                isPublic: false,
                                time: new Date(),
                                number: '',
                                title: '',
                                isEdit: false,
                            }
                        }
                        this.showAddInterpellationtModal = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally{
                    this.$store.commit('hideLoader');
                }
            },
            updateInterpellationList(value) {
                if (value == 1 || value == 2) {
                    this.$store.commit("showLoader");
                    InterpellationService.getAllInterpelattions(1).then((response) => {
                        this.interpelattions = response.data.interpellations;
                        this.types = response.data.types;
                        this.$store.commit("hideLoader");

                        this.getNewInterpellationCountForDisplay();
                    });
                }
            },
            formatDateToDisplay(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    weekday: 'long',
                    day: 'numeric'
                });

                return d;
            },
            async nextPage() {
                this.page += 1;
                try {
                    this.interpelattions = [];
                    this.$store.commit("showLoader");
                    this.interpelattions = (await InterpellationService.getAllInterpelattions(this.page)).data.interpellations;
                    this.$store.commit("hideLoader");
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async previousPage() {
                if (this.page > 1) {
                    this.page -= 1;
                    try {
                        this.interpelattions = [];
                        this.$store.commit("showLoader");
                        this.interpelattions = (await InterpellationService.getAllInterpelattions(this.page)).data.interpellations;
                        this.$store.commit("hideLoader");
                    } catch (e) {
                        if (e.response.status == 401)
                            this.$router.push({ name: 'Login' })
                    }
                }
            }
        },
        components: {
            ButtonWithIcon,
            AddInterpellationtModal,
            ButtonOnlyWithIcon,
            ShowInterpellationtModal,
            Pagination,
            SearchInputTextComponent,
            SearchInputDateComponent,
            SearchLabelComponent
            //Attachemnt,
        },
        async mounted() {

            if (this.interpellationModule !== 1) {
                this.$swal(AlertHelper.errorAlert("Brak wykupionego modułu interpelacje."));
                return;
            }

            if (CommonHelper.isObjectIsEmpty(this.requestQuery)) {
                this.setDefaultRequestQuery();
            }
            else {
                this.queryParamsToRequestSearch();
                await this.fetchInterpellation();
            }

            //if (this.interpellationModule == 1) {
            //    this.$store.commit("showLoader");
            //    InterpellationService.getAllInterpelattions(1).then((response) => {
            //        this.interpelattions = response.data.interpellations;
            //        this.types = response.data.types;
            //        this.$store.commit("hideLoader");
            //    });
            //} else {
                
            //}
        },
    };
</script>

<style lang="scss" scoped>

    .delete-button {
        cursor: pointer;
        border: none;
        background: none;
    }

    .dot {
        font-weight: 600;
        font-size: 13px;
        line-height: 132%;
        padding-left: 16px;
        position: relative;
        white-space: nowrap;

        &::after {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: #60a800;
            box-shadow: 1px 2px 4px rgba(65, 114, 0, 0.16);
            border-radius: 100%;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .defoult_button_pagination {
        width: 10%;
        height: 32px;
        background: #ffffff;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;
        /*&:hover {
            background-color: #e8ebef;
        }*/
    }
    .meetings-settings {
        margin-top: 20px;

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        &__wrapper {
            position: relative;

            /*&::after {
                content: "";
                position: absolute;
                top: 8px;
                background: #e2e5eb;
                width: 1px;
                height: 24px;
                right: 40px;
                z-index: 99;
            }*/
        }

        &__select {
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            padding: 8px 10px 8px 16px;
            font-weight: normal;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url("../../../../assets/icons/components/select-arrow.svg");
            background-repeat: no-repeat;
            background-position-x: calc(100% - 14px);
            background-position-y: 50%;
            min-width: 300px;
            min-height: 48px;

            &:focus {
                outline: none;
            }
        }
    }

    .contacts {
        margin: 49px 48px;

        &__head {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &__title {
                font-weight: 600;
                font-size: 28px;
                line-height: 128%;
                color: #00082c;
                margin-bottom: 20px;
            }
        }

        &__add-user-btn {
            position: fixed;
            bottom: 40px;
            right: 48px;
            width: 56px;
            height: 56px;
            border: 0px;
            background: #d80418;
            box-shadow: 8px 8px 28px rgba(173, 3, 19, 0.16);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.2 ease-in-out;

            &:hover {
                background: #ad0313 !important;
            }
        }

        &__add-user-btn-icon {
            width: 25px;
            height: 24px;
        }
    }

    .picker {
        padding: 12.5px 16px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        min-width: 238px;
        height: 56px;
        font-size: 16px;
        line-height: 144%;
        color: #00082c;

        &[type="date"]::-webkit-calendar-picker-indicator {
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url("../../../../assets/icons/components/callendar.svg") no-repeat;
            background-position-x: 100%;
            width: 22px;
            height: 22px;
            border-width: thin;
        }

        &[type="time"]::-webkit-calendar-picker-indicator {
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url("../../../../assets/icons/components/clock.svg") no-repeat;
            width: 22px;
            height: 22px;
            border-width: thin;
        }
    }

    .defoult_button {
        width: 50px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #e8ebef;
        }
    }

    .picker-wrapper {
        position: relative;

        /*&::after {
            content: "";
            position: absolute;
            top: 8px;
            background: #e2e5eb;
            width: 1px;
            height: 40px;
            right: 56px;
            z-index: 99;
        }*/
    }

    .contact-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        &__input {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            width: 326px;
            box-sizing: border-box;
            padding: 12.5px 16px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__button-search {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left: 0px;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 32px;
                background: #e2e5eb;
                top: 8px;
                left: 0px;
            }
        }

        &__form {
            display: flex;
            align-items: center;
            column-gap: 20px;

            &__item {
                display: flex;
                flex-direction: column;
                row-gap: 5px;
            }
        }

        &__search-icon {
            padding: 14px;
        }

        &__action-buttons {
            display: flex;
        }
    }

    .contact-list-no-result {
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contact-box {
        background: #ffffff;
        width: 100%;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
        border-radius: 12px;
        margin-bottom: 12px;
        transition: all 0.2s ease-in-out;

        &:hover .contact-box-actions {
            opacity: 1;
        }

        &:hover {
            border: 1px solid #bbc2ce;
        }

        &__title {
            margin-left: 16px;
            margin-right: 16px;
            font-weight: 400;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
        }

        &__top {
            display: flex;
            align-items: center;
            padding: 29px;
            justify-content: space-between;
        }

        &__people {
            transition: all 0.3s ease-in-out;

            &--close {
                max-height: 0px;
                display: none;
            }

        }

        &__people-container {
            padding: 16px;
            background: #f4f6fa;
            border-radius: 12px;
            margin: 0px 16px;

            &--close {
                max-height: 0px;
            }
        }

        &__arrow {
            transform: rotate(0deg);
            transition: all 0.3s ease-in-out;

            &--open {
                transform: rotate(180deg);
            }
        }
    }

    .contact-box-description {
        margin: 25px 34px 16px 34px;

        &__data {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 13px;
            line-height: 120%;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #666b80;

            &:nth-child(1) {
                margin-right: 228px;

                @media only screen and (max-width: 1600px) {
                    margin-right: 138px;
                }
            }

            &:nth-child(2) {
                margin-right: 193px;
            }
        }
    }

    .add-contact-button {
        display: flex;
        align-items: center;
        margin: 20px 32px;
        cursor: pointer;

        &__text {
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;
            color: #00082c;
            margin-left: 8px;
        }

        &--no-margin {
            margin: 37px 0px 0px 0px;
        }
    }

    .contacts-no-data {
        display: flex;
        margin: 27px;
        flex-direction: column;
    }

    .contact-box-actions {
        display: flex;
        align-items: center;
        opacity: 0;
        transition: all 0.2s ease-in-out;
    }

</style>
