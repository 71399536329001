<template>
    <section class="edit-meeting">
        <form class="edit-meeting__form">
            <button @click="
                    (e)=>
                {
                e.preventDefault();
                this.$store.commit('hideAddMeeting');
                }
                "
                class="edit-meeting__close"
                >
                <img src="../../../../../assets/icons/components/exit-btn.svg" />
            </button>
            <section class="edit-meeting__form-wrapper">
                <h2 class="edit-meeting__section-title">Dodaj posiedzenie</h2>
                <section class="edit-meeting__top">
                    <h3 class="
                            edit-meeting__section-title
                            edit-meeting__section-title--small
                        ">
                        Posiedzenie
                    </h3>
                    <section class="edit-meeting__row-double">
                        <section class="edit-meeting__col-double">
                            <label class="edit-meeting__label">Termin posiedzenia</label>
                            <section class="edit-meeting__picker-wrapper">
                                <input type="date"
                                       class="edit-meeting__picker"
                                       v-model="meeting_date"
                                       v-error="{
                                        errors,
                                        input_key: 'meeting_date',
                                    }" />
                                <ErrorMessage v-if="isError('meeting_date', this.errors)"
                                              :errorMsgs="
                                        passErrorMsg(
                                            'meeting_date',
                                            this.errors
                                        )
                                    " />
                            </section>
                        </section>
                        <section class="edit-meeting__col-double">
                            <label class="edit-meeting__label">Godzina</label>
                            <section class="edit-meeting__picker-wrapper">
                                <input type="time"
                                       class="edit-meeting__picker"
                                       v-model="meeting_time"
                                       v-error="{
                                        errors,
                                        input_key: 'meeting_date',
                                    }" />
                            </section>
                        </section>
                    </section>

                    <section class="edit-meeting__row-double">
                        <section class="edit-meeting__col-double">
                            <label class="edit-meeting__label">Data zakończenia posiedzenia</label>
                            <section class="edit-meeting__picker-wrapper">
                                <input type="date"
                                       class="edit-meeting__picker"
                                       v-model="end_meeting_date"
                                       v-error="{
                                        errors,
                                        input_key: 'end_meeting_date',
                                    }" />
                                <ErrorMessage v-if="isError('end_meeting_date', this.errors)"
                                              :errorMsgs="
                                        passErrorMsg(
                                            'end_meeting_date',
                                            this.errors
                                        )
                                    " />
                            </section>
                        </section>
                    </section>

                    <section class="edit-meeting__row-double">
                        <section class="edit-meeting__col-double">
                            <label class="edit-meeting__label">Numer posiedzenia</label>
                            <input type="number"
                                   class="
                edit-meeting__input-text
                edit-meeting__input-text--small
            "
                                   placeholder="Wpisz numer..."
                                   v-model="number"
                                   v-error="{ errors, input_key: 'number' }" />
                            <ErrorMessage v-if="isError('number', this.errors)"
                                          :errorMsgs="passErrorMsg('number', this.errors)" />
                        </section>
                        <section class="edit-meeting__col-double">
                            <label class="edit-meeting__label">Sygnatura</label>
                            <input type="text"
                                   class="
                edit-meeting__input-text
                edit-meeting__input-text--small
            "
                                   placeholder="Wpisz sygnaturę..."
                                   v-model="signature"
                                   v-error="{ errors, input_key: 'signature' }" />
                            <ErrorMessage v-if="isError('signature', this.errors)"
                                          :errorMsgs="
                passErrorMsg('signature', this.errors)
            " />
                        </section>
                    </section>


                    <section class="edit-meeting__row">
                        <label class="edit-meeting__label">Nazwa posiedzenia</label>
                        <input type="text"
                               class="edit-meeting__input-text"
                               placeholder="Wpisz nazwę posiedzenia..."
                               v-model="name"
                               v-error="{ errors, input_key: 'name' }" />
                        <ErrorMessage v-if="isError('name', this.errors)"
                                      :errorMsgs="passErrorMsg('name', this.errors)" />
                    </section>
                    <section class="edit-meeting__row">
                        <label class="edit-meeting__label">Miejsce posiedzenia</label>
                        <textarea rows="4"
                                  class="edit-meeting__input-text"
                                  placeholder="Wpisz miejsce posiedzenia..."
                                  v-model="meeting_place"
                                  v-error="{ errors, input_key: 'meeting_place' }"></textarea>
                        <ErrorMessage v-if="isError('meeting_place', this.errors)"
                                      :errorMsgs="
                                passErrorMsg('meeting_place', this.errors)
                            " />
                    </section>
                </section>
                <section class="edit-meeting__groups">
                    <h3 class="
                            edit-meeting__section-title
                            edit-meeting__section-title--small
                        ">
                        Uczestniczące grupy
                    </h3>
                    <ErrorMessage v-if="isError('group_ids', this.errors)"
                                  :errorMsgs="passErrorMsg('group_ids', this.errors)" />
                    <section class="edit-meeting__checkbox-section"
                             v-for="group in canSession()"
                             :key="group.id">
                        <CheckBoxButton :value="group.id"
                                        :id="group.id"
                                        :checked="checkedGroupsIds.indexOf(group.id) !== -1"
                                        @change="getGroupSignature(group.id)"
                                        @setGroupId="(value)=>(this.checkedGroupsIds = value)"
                                        :idsArray="checkedGroupsIds" />
                            <label class="edit-meeting__label edit-meeting__label--checkbox"
                                   :for="group.id">{{ group.name }}
                            </label>
                    </section>
                </section>
                <section class="edit-meeting__identity">
                    <h3 class="
                            edit-meeting__section-title
                            edit-meeting__section-title--small
                        ">
                        Ustawienia
                    </h3>

                    <section>
                        <div class="form-bootstrap-check form-bootstrap-switch">
                            <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="public-meeting" v-model="isPublic" @click="setIsAuthorized()"/>
                            <label class="form-bootstrap-check-label" for="public-meeting">Posiedzenie publiczne</label>
                            <section name="externalTrigger2"
                                     class="edit-meeting__tip-btn">
                                <img src="../../../../../assets/icons/components/tooltip.svg"
                                     class="edit-meeting__tooltip" />
                                <Hint title="Posiedzenie publiczne"
                                      text="Posiedzenie oznaczone jako publiczne jest widoczne dla wszystkich zalogowanych użytkowników systemu, a także dla internautów w Portalu Mieszkańca, o ile takowy został włączony." />
                            </section>
                        </div>
                    </section>

                    <section>
                        <div class="form-bootstrap-check form-bootstrap-switch">
                            <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="authorized-meeting" v-model="is_authorized" @click="setIsPublic()"/>
                            <label class="form-bootstrap-check-label" for="authorized-meeting">Posiedzenie widoczne dla użytkowników</label>
                            <section name="externalTrigger2"
                                     class="edit-meeting__tip-btn">
                                <img src="../../../../../assets/icons/components/tooltip.svg"
                                     class="edit-meeting__tooltip" />
                                <Hint style="left: calc(100% - 310px)" title="Posiedzenie widoczne dla użytkowników"
                                      text="Posiedzenie będzie widoczne dla zalogowanych i uprawnionych użytkowników systemu" />
                            </section>
                        </div>
                    </section>

                </section>
                <section class="edit-meeting__bottom">
                    <button class="edit-meeting__bottom-btn"
                            @click="
                            (e)=>
                        {
                        e.preventDefault();
                        this.$store.commit('hideAddMeeting');
                        }
                        "
                        >
                        Anuluj
                    </button>
                    <button @click="(e) => handleSaveMeeting(e)"
                            class="
                            edit-meeting__bottom-btn
                            edit-meeting__bottom-btn--red
                        ">
                        Zapisz
                    </button>
                    <LoaderInside v-if="isLoading" />
                </section>
            </section>
        </form>
    </section>
</template>

<script>
    import moment from "moment";
    import MeetingsService from "../../../../../services/meetings.service";
    import Hint from "../../../../BasicControls/Hint.vue";
    import MeetingService from "../../../../../services/meetings.service";
    import CheckBoxButton from "../../../../BasicControls/CheckBoxButton.vue";
    import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
    import ErrorMessage from "../../../../BasicControls/ErrorMessage.vue";
    import {
        passErrorMsg,
        isError,
        setErrors,
    } from "../../../../../formValidation/main";

    export default {
        name: "AddMeeting",
        data() {
            return {
                groups: [],
                checkedGroupsIds: [],
                name: "",
                isPublic: false,
                is_authorized: false,
                is_published: false,
                meeting_date: this.getCurrentDate(),
                end_meeting_date: null,
                meeting_time: "12:00",
                meeting_place: "",
                number: "",
                signature: "",
                isLoading: false,
                id: null,
                errors: []
            };
        },
        components: {
            CheckBoxButton,
            LoaderInside,
            ErrorMessage,
            Hint
        },
        mounted() {
            try {
                this.isLoading = true;
                if (this.$store.state.appStore.groupId !== null) {
                    this.checkedGroupsIds.push(this.$store.state.appStore.groupId);
                }
                if (this.$store.state.appStore.meetingDate !== "") {
                    this.meeting_date = moment(
                        this.$store.state.appStore.meetingDate
                    ).format("yyyy-MM-DD");
                }

                this.groups = this.$store.state.appStore.groups;
                this.isLoading = false;

            } catch {
                this.isLoading = false;
            }
        },
        computed: {
            isBasedOnTemplate() {
                return this.$store.state.appStore.addMeetingBasedOnTemplateId !== null;
            },

            templateId() {
                return this.$store.state.appStore.addMeetingBasedOnTemplateId
            }
        },
        methods: {
            setIsAuthorized() {
                if (this.is_authorized == false) {
                    this.is_authorized = true;
                }
            },
            setIsPublic() {
                if (this.is_authorized == true) {
                    this.isPublic = false;
                }
            },
            getCurrentDate() {
                let curDate = new Date();
                let stringDate = "";
                stringDate += `${curDate.getFullYear()}-`;
                let month = curDate.getMonth() + 1;

                if (month < 10)
                    stringDate += `0${month}-`;
                else
                    stringDate += `${month}-`;

                let day = curDate.getDate();

                if (day < 10)
                    stringDate += `0${day}`;
                else
                    stringDate += `${day}`;

                return stringDate;
            },
            handleSaveMeeting(e) {
                e.preventDefault();

                if (this.validate() == false)
                    return

                var d = {
                    name: this.name,
                    number: this.number,
                    signature: this.signature,
                    meeting_place: this.meeting_place,
                    meeting_date: this.meeting_date,
                    meeting_time: this.meeting_time,
                    is_published: this.is_published,
                    is_public: this.isPublic,
                    is_authorized: this.is_authorized,
                    group_ids: this.checkedGroupsIds,
                    end_meeting_date: this.end_meeting_date
                };

                if (d.number == "")
                    d.number = null;

                if (this.number.length > 0) {
                    d["number"] = this.number;
                }

                this.isLoading = true;
                this.errors = [];

                try {
                    if (this.isBasedOnTemplate) {
                        MeetingService.addByTemplate(this.templateId, d)
                            .then((r) => {
                                try {
                                    d.id = r.data;
                                    d.meeting_date = new Date(d.meeting_date + "T" + d.meeting_time)
                                    d.agendaItems = [];
                                    this.finishRequest(d);
                                } catch (e) {
                                    this.isLoading = false;
                                    this.errors = setErrors(e);
                                }
                            })
                            .catch((error) => {
                                this.isLoading = false;
                                this.errors = setErrors(error);
                            });
                    }
                    else {
                        MeetingsService.add(d)
                            .then((r) => {
                                try {
                                    d.id = r.data;
                                    d.meeting_date = new Date(d.meeting_date + "T" + d.meeting_time)
                                    d.agendaItems = [];
                                    this.finishRequest(d);
                                } catch (e) {
                                    this.isLoading = false;
                                    this.errors = setErrors(e);
                                }
                            })
                            .catch((error) => {
                                this.isLoading = false;
                                this.errors = setErrors(error);
                            });
                    }
                        
                } catch (e) {
                    this.isLoading = false;
                    this.errors = setErrors(e);
                }

            },
            validate() {
                var bo = true;
                var err = {};
                //if (this.name.length <= 0) {
                //    err.name = ['Pole wymagane']
                //    bo = false
                //}

                if (this.meeting_date.length <= 0) {
                    err.meeting_date = ['Pole wymagane']
                    bo = false
                }

                if (this.meeting_time.length <= 0) {
                    err.meeting_time = ['Pole wymagane']
                    bo = false
                }

                if (moment(this.meeting_date, 'yyyy-MM-DD', true).isValid() == false) {
                    err.meeting_date = ['Zły format daty']
                    bo = false
                }

                if (this.end_meeting_date !== null && this.end_meeting_date !== "") {
                    if (moment(this.end_meeting_date, 'yyyy-MM-DD', true).isValid() == false) {
                        err.end_meeting_date = ['Zły format daty']
                        bo = false
                    }
                    else {
                        let endMeetingDateParse = moment(this.end_meeting_date, 'yyyy-MM-DD');
                        let meetingDateParse = moment(this.meeting_date, 'yyyy-MM-DD')
                        if (meetingDateParse > endMeetingDateParse) {
                            err.end_meeting_date = ['Data zakończenia posiedzenia nie może być wcześniejsza niż data posiedzenia']
                            bo = false
                        }
                    }
                }

                if (moment(this.meeting_time, 'LT', true).isValid() == false) {
                    err.meeting_time = ['Zły format godziny']
                    bo = false
                }

                if (Object.keys(this.checkedGroupsIds).length <= 0) {
                    err.group_ids = ['Zaznacz grup(ę)/(y)']
                    bo = false
                }

                if (!bo) {
                    this.isLoading = false;
                    this.errors = setErrors(err)
                }
                return bo
            },
            finishRequest() {
                this.isLoading = false;
                this.name = "";
                this.is_published = false;
                this.is_public = false;
                this.is_authorized = false;
                this.signature = "";
                this.meeting_date = "";
                this.meeting_time = "";
                this.meeting_place = "";
                this.end_meeting_date = "";
                this.checkedGroupsIds = [];
                this.session_number = "";
                this.isLoading = false;
                this.id = 0;
                //this.$store.commit("hideAddMeeting");

                this.$store.commit("hideAddMeeting");
                MeetingService.groups(false).then((response) =>
                    this.$store.commit("setGroups", response.data)
                );

                this.$store.commit("setAddSessionStatus", true);
                //var d = this.$store.state.appStore.groups;
                //var grIds = data.group_ids;
                //for (var i = 0; i < grIds.length; i++) {
                //    var index = d.findIndex(x => x.id == grIds[i]);
                //    if (index != -1) {
                //        d[index].sessions.push(data)
                //    }
                //}
                //this.$store.commit("setGroups", d)
            },
            getGroupSignature(grId) {
                if (grId > 0) {
                    var group = this.groups.filter(x => x.id == grId);
                    if (group !== null && group.length > 0 && group.length == 1) {
                        if (group[0].session_default_signature != undefined && group[0].session_default_signature != null && group[0].session_default_signature.length > 0) {
                            if (this.checkedGroupsIds.length == 0) {
                                this.signature = "";
                                this.meeting_place = "";
                            }
                            else {
                                if (this.checkedGroupsIds.includes(group[0].id)) {
                                    this.signature = group[0].session_default_signature;
                                    this.meeting_place = group[0].session_default_place;
                                }
                                else {
                                    let lastElement = this.checkedGroupsIds[this.checkedGroupsIds.length - 1];
                                    var group2 = this.groups.filter(x => x.id == lastElement);
                                    if (group2 !== null && group2.length > 0 && group2.length == 1) {
                                        this.signature = group2[0].session_default_signature;
                                        this.meeting_place = group2[0].session_default_place;
                                    }
                                }
                                
                            }
                        }
                    }
                }
            },
            canSession() {
                return this.groups.filter((group) => group.can_sessions);
            },
            passErrorMsg,
            isError,
            setErrors,
        },
    };
</script>

<style lang="scss" scoped>
    .add-meeting {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 9;

        &__form {
            padding: 32px 56px;
            background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
            border-radius: 12px;
            padding-right: 0px;
            padding-bottom: 56px;
        }

        &__form-wrapper {
            max-height: 80vh;
            overflow-y: auto;
            padding-right: 56px;

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }
        }

        &__exit-btn {
            border: 0px;
            background-color: transparent;
            position: relative;
            right: -90%;
        }

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 32px;
        }

        &__col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        &__row {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            align-items: flex-start;
            justify-content: flex-start;

            &--double {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
            }
        }

        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-bottom: 8px;

            &--checkbox {
                padding-left: 36px;
                font-weight: normal;
                z-index: 6;
                font-weight: normal;
                font-size: 16px;
                line-height: 144%;
                color: #333956;
                margin-bottom: 0px;
                cursor: pointer;
            }

            &--weight-normal {
                font-weight: normal;
            }
        }

        &__checkbox-section {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
        }

        &__input-text {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            min-width: 500px;
            height: 56px;
            font-size: 16px;
            color: #00082c;

            &:focus {
                border: 1px solid #00082c;
            }

            &::placeholder {
                font-size: 16px;
                line-height: 144%;
                color: #808396;
            }

            &--adjust-width {
                width: 100%;
                min-width: 238px;
            }
        }

        &__textarea {
            padding: 17px 16px 8px 20px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            min-width: 500px;
            height: 160px;
            resize: none;
            font-size: 16px;
            color: #00082c;

            &::placeholder {
                font-size: 16px;
                line-height: 144%;
                color: #808396;
            }
        }

        &__select {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            min-width: 238px;
            height: 56px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url("../../../../../assets/icons/components/select-arrow.svg");
            background-repeat: no-repeat;
            background-position-x: calc(100% - 20px);
            background-position-y: 50%;
        }

        &__date-time-picker {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            min-width: 238px;
            height: 56px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;

            &[type="date"]::-webkit-calendar-picker-indicator {
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url("../../../../../assets/icons/components/callendar.svg") no-repeat;
                background-position-x: 100%;
                width: 22px;
                height: 22px;
                border-width: thin;
            }

            &[type="time"]::-webkit-calendar-picker-indicator {
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url("../../../../../assets/icons/components/clock.svg") no-repeat;
                background-position-x: 100%;
                width: 22px;
                height: 22px;
                border-width: thin;
            }
        }

        &__bottom {
            display: flex;
            justify-content: space-between;
        }

        &__button-bottom {
            padding: 18px 40px 17px;
            min-width: 238px;
            height: 56px;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
            background-color: transparent;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                background: #d80418;
                color: white;
                border: 0px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: #ad0313;
                }
            }
        }

        &__select-wrapper {
            position: relative;
            /*&::after {
            content: "";
            position: absolute;
            top: 8px;
            background: #e2e5eb;
            width: 1px;
            height: 40px;
            right: 56px;
            z-index: 99;
        }*/
        }
    }

    .edit-meeting {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 11;

        &__form-wrapper {
            max-height: 80vh;
            overflow-y: auto;
            padding-right: 56px;

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }
        }

        &__form {
            max-height: 90vh;
            padding: 28px 56px;
            background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
            border-radius: 12px;
            max-width: 612px;
            box-sizing: border-box;
            padding-bottom: 0px;
            padding-right: 0px;
        }

        &__close {
            border: 0px;
            position: relative;
            right: calc(-100% + 48px);
            background-color: transparent;
        }

        &__section-title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 32px;

            &--small {
                font-size: 20px;
                margin-bottom: 24px;
            }
        }

        &__top {
            padding-top: 28px;
            padding-bottom: 28px;
            border-top: 1px solid #dde0e7;
            border-bottom: 1px solid #dde0e7;
        }

        &__row-double {
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;
        }

        &__col-double {
            display: flex;
            flex-direction: column;
        }

        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-bottom: 8px;

            &--checkbox {
                padding-left: 32px;
                font-weight: normal;
                font-size: 16px;
                line-height: 144%;
                color: #333956;
                margin-bottom: 0px;
                z-index: 8;
                cursor: pointer;
            }
        }

        &__picker {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            min-width: 238px;
            height: 56px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;

            &[type="date"]::-webkit-calendar-picker-indicator {
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url("../../../../../assets/icons/components/callendar.svg") no-repeat;
                background-position-x: 100%;
                width: 22px;
                height: 22px;
                border-width: thin;
            }

            &[type="time"]::-webkit-calendar-picker-indicator {
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url("../../../../../assets/icons/components/clock.svg") no-repeat;
                width: 22px;
                height: 22px;
                border-width: thin;
            }
        }

        &__picker-wrapper {
            position: relative;
            /*&::after {
                content: "";
                position: absolute;
                top: 8px;
                background: #e2e5eb;
                width: 1px;
                height: 40px;
                right: 56px;
                z-index: 99;
            }*/
        }

        &__row {
            margin-bottom: 24px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
        }

        &__input-text {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            min-width: 500px;
            //height: 56px;
            font-size: 16px;
            color: #00082c;

            &:focus {
                border: 1px solid #00082c;
            }

            &::placeholder {
                font-size: 16px;
                line-height: 144%;
                color: #808396;
            }

            &--small {
                min-width: 238px;
            }
        }

        &__groups {
            margin-top: 28px;
            padding-bottom: 38px;
            border-bottom: 1px solid #dde0e7;
        }

        &__checkbox-section {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
        }

        &__identity {
            margin-top: 28px;
        }

        &__bottom {
            display: flex;
            justify-content: space-between;
            padding-bottom: 56px;
            padding-top: 30px;
        }

        &__bottom-btn {
            padding: 18px 40px 17px;
            width: 238px;
            height: 56px;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            background-color: transparent;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                background-color: #d80418;
                border: 0px;
                color: white;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: #ad0313;
                }
            }
        }

        &__switch-section {
            display: flex;
            margin-bottom: 40px;
        }

        &__tooltip {
            position: relative;
            left: 5px;
            top: -3px;
        }

        &__tip-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0px;
            background-color: transparent;
            width: 20px;
            height: 20px;
            position: relative;
            cursor: pointer;

            &:hover .hint {
                opacity: 1;
                display: block;
            }
        }
    }
</style>
