<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Importer porządku obrad</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); $store.commit('hideImportSessionAgenda');}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section class="esesja-modal__row">
                    <label class="esesja-modal__wraper__body__label">Wklej punkty porządku obrad</label>
                    <textarea id="description-points" type="text" rows="16" class="edit-point-form__input" v-model="description"
                              onkeydown="if(event.keyCode===9){var v=this.value,s=this.selectionStart,e=this.selectionEnd;this.value=v.substring(0, s)+'\t'+v.substring(e);this.selectionStart=this.selectionEnd=s+1;return false;}"/>
                </section>

                <section class="esesja-modal__row">
                    <label class="esesja-modal__wraper__body__label">lub wybierz plik (docx) z dysku</label>
                    <input type="file" ref="agendaFileInput" class="esesja-modal__wraper__body__input" @change="uploadFile" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
                </section>
                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="$store.commit('hideImportSessionAgenda')"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="importAgendaItems" :disabled="checkSaveButtonDisable()">Zapisz</button>
                </section>
            </perfect-scrollbar>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
    //components
    import LoaderInside from "@/components/BasicControls/LoaderInside.vue";

    //services
    import SessionService from "@/services/session.service";
    import AlertHelper from '@/helpers/alerts.helper.js';

    export default {
        name: "ImportSessionAgenda",
        components: {
            LoaderInside,
        },
        computed: {
            sessionId() {
                return this.$store.state.appStore.importSessionId;
            }
        },
        data() {
            return {
                isLoading: false,
                isUploadedFile: false,
                errorMessage: "Import agendy możliwy jest tylko dla posiedzeń, które nie mają już zdefiniowanych punktów",
                requestData: new FormData(),
                description: '',
            }
        },
        async mounted() {
            this.$store.commit("setagendaForSessionImported", false);
            this.requestData.append("sessionId", this.sessionId);
        },
        methods: {
            async importAgendaItems() {
                try {
                    this.isLoading = true;

                    if (this.isUploadedFile) {

                        let response = await SessionService.importAgendatItems(this.sessionId, this.requestData);

                        if (response.status !== 200) {
                            throw await Promise.reject(response);
                        }

                        this.$store.commit('hideImportSessionAgenda')

                        if (this.$route.name === "sessionEdit") {
                            this.$store.commit('setagendaForSessionImported', true);
                        }
                    }
                    else {
                        if (this.description.length > 0) {

                            var doc = document.getElementById("description-points");
                            doc.value += " ";

                            var data = {
                                text: doc.value
                            }

                            let response = await SessionService.importAgendatItemsFronText(this.sessionId, data);

                            if (response.status !== 200) {
                                throw await Promise.reject(response);
                            }
                            this.description = '';
                            this.$store.commit('hideImportSessionAgenda')

                            if (this.$route.name === "sessionEdit") {
                                this.$store.commit('setagendaForSessionImported', true);
                            }
                        }
                    }
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sessionTemplate' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading = false;
                }
            },
            uploadFile() {
                if (!this.checkMimeType(this.$refs.agendaFileInput.files[0].type))
                {
                    this.$swal(AlertHelper.errorAlert("Nieobsługiwany typ pliku. Pliki obsługiwane to: DOCX"));
                    return;
                }
                if (this.requestData.has("agendaFile")) {
                    this.requestData.set("agendaFile", this.$refs.agendaFileInput.files[0])
                }
                else {
                    this.requestData.append("agendaFile", this.$refs.agendaFileInput.files[0])
                }
                this.isUploadedFile = true;
            },

            checkMimeType(type) {
                if (type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                    return false;
                } else {
                    return true;
                }
            },
            checkSaveButtonDisable() {
                return (!this.isUploadedFile && this.description.length == 0) ? true : false;
            }
        },
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/esesja-modal.scss';
</style>