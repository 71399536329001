<template>
    <eSesjaModal>
        <eSesjaModalHeader @close="$store.commit('setIsShowAddPlaceholderStatus', false)">
            Plansza
        </eSesjaModalHeader>
        <eSesjaModalBody>
            <label class="esesja-modal__wraper__body__label">Nazwa</label>
            <section class="esesja-modal__row">
                <input class="esesja-modal__wraper__body__input"
                        type="text"
                        v-model="placeholder.name"
                        placecholder="Nazwa"
                        v-error="{ errors, input_key: 'name' }" />
                <ErrorMessage   v-if="isError('name', this.errors)"
                                :errorMsgs="passErrorMsg('name', this.errors)" />
            </section>
            <label class="esesja-modal__wraper__body__label">Opis pomocniczy komponentu</label>
            <section class="esesja-modal__row">
                <textarea class="esesja-modal__wraper__body__input"
                        v-model="placeholder.description"
                        placecholder="Opis"
                        v-error="{ errors, input_key: 'description' }">
                </textarea>
                <ErrorMessage   v-if="isError('description', this.errors)"
                                :errorMsgs="passErrorMsg('description', this.errors)" />
            </section>
            <label class="esesja-modal__wraper__body__label">Plansza</label><br />
            <label class="esesja-modal__wraper__body__label__info">Zalecany rozmiar to <strong>1920px x 1080px</strong> lub inny w proporcjach <strong>16:9</strong></label>
            <section class="esesja-modal__row">
                <input type="file" class="esesja-modal__wraper__body__input esesja-modal__wraper__body__input__file" 
                       @change="filePreview" 
                       accept="image/png, image/jpeg" 
                       v-error="{ errors, input_key: 'file' }"/>
                <ErrorMessage v-if="isError('file', this.errors)"
                              :errorMsgs="passErrorMsg('file', this.errors)" />
            </section>
            <label class="esesja-modal__wraper__body__label" v-if="placeholder.path !== null && placeholder.selectedFile === null">Podgląd</label>
            <section class="d-flex justify-content-center" v-if="placeholder.path !== null && placeholder.selectedFile === null">
                <img :src="placeholder.path" :alt="placeholder.name" width="500"/>
            </section>
        </eSesjaModalBody>
        <eSesjaModalFooter>
            <eSesjaModalButtonCancel @close="$store.commit('setIsShowAddPlaceholderStatus', false)">
                Anuluj
            </eSesjaModalButtonCancel>
            <eSesjaModalButtonSave @save="savePlaceholder">
                Zapisz
            </eSesjaModalButtonSave>
        </eSesjaModalFooter>
    </eSesjaModal>
</template>
<script charset="utf-8">
    import PlaceholderService from "@/services/placeholder.service";
    import ErrorMessage from "@/components/BasicControls/ErrorMessage.vue";
    import { passErrorMsg, isError, setErrors } from "@/formValidation/main";
    import { formatBytes } from "../../../../../../helpers/utils";
    export default{
        name: "AddPlaceholder",
        components:{
            ErrorMessage
        },
        computed:{
            selectedPlaceholder(){
                return this.$store.state.placeholderStore.selectedPlaceholder;
            }
        },
        data(){
            return {
                placeholder:{
                    id: null,
                    name: "",
                    description: "",
                    selectedFile: null,
                    path: null,
                },
                validationRule: {
                    supportFileMimeType: ["image/jpeg", "image/png"], 
					maxFileSize: 20971520 // 20 MiB
                },
                errors: [],
                isValidationErrors: false,
            }
        },
        mounted(){
            if(this.selectedPlaceholder !== null){
                this.placeholder.id = this.selectedPlaceholder.id;
                this.placeholder.name = this.selectedPlaceholder.name;
                this.placeholder.description = this.selectedPlaceholder.description ?? ""
                this.placeholder.path = this.selectedPlaceholder.path;
            }
        },
        methods: {
            filePreview(e){
                if(e.target.files.length != 1){
                    throw new Error();
                }
                // todo walidacja typu czy zgodny
                this.placeholder.selectedFile = e.target.files[0];
            },

            async savePlaceholder(){
                if(this.placeholder.id !== null){
                    await this.internalUpdatePlaceholder();
                }
                else{
                    await this.internalSavePlaceholder();
                }
            },
            async internalUpdatePlaceholder(){
                try{
                    this.errors = [];
					this.validate(false);
                    this.$store.commit("showLoader");
            
                    await PlaceholderService.Update(this.placeholder.id, this.getFormData());

                    this.$store.commit('setIsShowAddPlaceholderStatus', false)
                }
                catch(e){

                    if (e.response !== undefined) {
                         this.parseUnprocessableEnttiy(e.response);
                    }
                }
                finally{
                    this.$store.commit("hideLoader");
                }
            },
            async internalSavePlaceholder(){
                try{
                    this.errors = [];
                    this.validate(true);
                    this.$store.commit("showLoader");

                    await PlaceholderService.Create(this.getFormData());

                    this.$store.commit('setIsShowAddPlaceholderStatus', false)
                }
                catch (e) {
                    if (e.response !== undefined) {
                         this.parseUnprocessableEnttiy(e.response);
                    }
                }
                finally{
                    this.$store.commit("hideLoader");
                }
            },
            validate(fileRequired = false) {
				if (this.placeholder.selectedFile === null && fileRequired) {
                    this.addError("file", "Nie wybrano pliku");
                }

                if (this.placeholder.selectedFile !== null) {
                    if (!this.validationRule.supportFileMimeType.includes(this.placeholder.selectedFile.type)) {
						this.addError("file", "Nieobsługiwany format pliku. Dozwolony format to .jpg oraz .png");
                    }

                    if (this.placeholder.selectedFile.size > this.validationRule.maxFileSize) {
						this.addError("file", `Maksymalna wielkość pliku to ${formatBytes(this.validationRule.maxFileSize)}`);
                    }
                }

                if (this.errors.length > 0) {
                    this.isValidationErrors = true;
                }
                this.errors = setErrors(this.errors)
                
                console.log("errors", this.errors.length > 0);
                if(this.errors.length > 0){
                    throw new Error();
                }

            },
            addError(key, message) {
                if (this.errors[key] === undefined || this.errors[key] === null) {
                    this.errors[key] = [];
                }
                this.errors[key].push(message);
            },
            parseUnprocessableEnttiy(response){
                if(response.status === 422){
                    for (const err of response.data) {
                        this.addError(err.propertyName.toLowerCase(), err.errorMessage);
                    }
                    this.errors = setErrors(this.errors)
                }
            },
            getFormData(){
                let fd = new FormData();
                fd.append("name", this.placeholder.name);
                fd.append("description", this.placeholder.description);
                if(this.placeholder.selectedFile !== null){
                    fd.append("file", this.placeholder.selectedFile);
                }
                return fd;
            },
            passErrorMsg,
            setErrors,
            isError,
        }
    }
</script>
<style lang="scss">
    .esesja-modal__wraper__body__input__file {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            padding-left: 0px !important;


            &::file-selector-button {
                background: #fff;
                padding: 12.5px 15px;
                border: none;
                transition: all 0.2s ease-in-out;
                cursor: pointer;

                &:hover {
                    background: #e8ebef;
                }
            }
        }
    .esesja-modal__wraper__body__label__info{
        font-size: 0.8em
    }
</style>
