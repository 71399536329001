<template>
    <section class="esesja-modal__wraper__body__bottom">
        <slot></slot>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "eSesjaModalFooter"
    }
</script>
<style lang="scss" scoped>
    .esesja-modal__wraper__body__bottom{
        padding:0px 32px 32px 32px;
    }
</style>