<template>
    <section class="component">
        <section class="component__header">
            <span class="component__header__title">
                Dane personalne
            </span>
            <section class="component__header__action">
                <button class="component__button
                               component__button--small
                               component__button--white"
                        @click="() => this.$store.commit('toggleEditPersonalData', true)">
                    <img src="@/assets/icons/components/edit.svg" />
                    <span class="component__button__text">Edytuj</span>
                </button>
            </section>
        </section>
        <section class="component__body personal-info">
            <section class="personal-info__item">
                <section class="personal-info__item__label">Identyfikator systemowy:</section>
                <section class="personal-info__item__value">{{userData.lid}}</section>
            </section>
            <section class="personal-info__item">
                <section class="personal-info__item__label">Imię:</section>
                <section class="personal-info__item__value">{{getValue(userData.firstName)}}</section>
            </section>
            <section class="personal-info__item">
                <section class="personal-info__item__label">Nazwisko:</section>
                <section class="personal-info__item__value">{{getValue(userData.lastName)}}</section>
            </section>
            <section class="personal-info__item">
                <section class="personal-info__item__label">Funkcja:</section>
                <section class="personal-info__item__value">{{getValue(userData.role)}}</section>
            </section>
            <section class="personal-info__item">
                <section class="personal-info__item__label">Telefon:</section>
                <section class="personal-info__item__value">{{getValue(userData.telephone)}}</section>
            </section>
            <section class="personal-info__item">
                <section class="personal-info__item__label">Fex:</section>
                <section class="personal-info__item__value">{{getValue(userData.fax)}}</section>
            </section>
            <section class="personal-info__item">
                <section class="personal-info__item__label">Adres E-Mail:</section>
                <section class="personal-info__item__value">{{getValue(userData.email)}}</section>
            </section>
            <section class="personal-info__item">
                <section class="personal-info__item__label">Adres:</section>
                <section class="personal-info__item__value">{{getAddress(userData.street, userData.postalCode, userData.city)}}</section>
            </section>
            <section class="personal-info__item">
                <section class="personal-info__item__label">Data dołączenia:</section>
                <section class="personal-info__item__value">{{getData(userData.accountSince)}}</section>
            </section>
        </section>
        <Transition>
            <EditPersonalDataModal v-if="showEditPersonalInfo"
                                   v-model="userData" 
                                   @closeModal="closeModal" />
        </Transition>
    </section>
</template>
<script charset="utf-8">
    import EditPersonalDataModal from "./Modals/EditPersonalDataModal.vue";
    import UserService from "../../../../../services/user.service";
    import moment from "moment";

    export default {
        name: "PersonalInfo",
        components: {
            EditPersonalDataModal
        },
        computed: {
            showEditPersonalInfo() {
                return this.$store.state.appStore.isShowEditPersonalData;
            },
            isEditPersonalDataOnLoad() {
                if (this.$route.query.editPersonalData === undefined)
                    return false;
                return this.$route.query.editPersonalData;
            }
        },
        data() {
            return {
                userData: {
                    lid: 0,
                    firstName: '',
                    lastName: '',
                    role: '',
                    telephone: '',
                    fax: '',
                    email: '',
                    street: '',
                    postalCode: '',
                    city: '',
                    accountSince: '',
                },
                isLoading: false,
            }
        },
        watch: {
            userData: {
                deep: true,
                handler: function (value) {
                    this.$store.commit("updateUserFirstName", value.firstName);
                    this.$store.commit("updateUserLastName", value.lastName);
                }

            }
        },
        async mounted() {
            try {
                this.isLoading = true;
                await this.fetchUserData();
                if (this.isEditPersonalDataOnLoad) {
                    this.$store.commit('toggleEditPersonalData', true)
                }

            }
            catch (e) {
                if (e.response !== undefined && e.response !== null) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
            }
            finally {
                this.isLoading = false;
            }
            
        },
        methods: {
            async fetchUserData() {
                let response = await UserService.getPersonalInfo();

                if (response.status !== 200) {
                    throw await Promise.reject(response)
                }
                this.userData = response.data;
                
            },

            async closeModal() {
                try {
                    this.isLoading = true;
                    this.$store.commit('toggleEditPersonalData', false)

                    await this.fetchUserData();
                }
                catch (e) {
                    if (e.response !== undefined && e.response !== null) {
                        if (e.response.status == 404) {
                            this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                        }
                        else if (e.response.status == 401) {
                            this.$router.push({ name: 'Login' })
                        }
                    }
                }
                finally {
                    this.isLoading = false;
                }
            },

            getValue(value) {
                if (value === '') return 'Brak';
                return value;
            },

            getAddress(street, postalCode, city) {
                if (street !== '' && postalCode !== '' && city !== '') return `${street}, ${postalCode} ${city}`
                return 'Brak';
            },
            getData(value) {
                try {
                    let internal = this.getValue(value);
                    return moment(internal).format("DD MMMM YYYY HH:mm");
                }
                catch {
                    return this.getValue(value);
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/user-profile-component.scss";
    .component {
        //position: absolute;
        height: fit-content;
    }
    .personal-info {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding: 15px 20px;

        &__item {
            display: flex;
            flex-direction: row;
            column-gap: 10px;

            &__label {
                font-weight: 800;
            }
        }
    }
</style>