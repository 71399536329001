<template>
    <section class="esesja-licenses-info-component">
        <section class="esesja-licenses-info-component__license-box">
            <section class="esesja-licenses-info-component__license-box-data-wrapper">
                <section v-tooltip="'Konta z nadanym loginem i hasłem'">Licencje dostępowe:</section>
                <section class="esesja-licenses-info-component__license-numbers"> (<span class="esesja-licenses-info-component__user-count">{{licensesInformation.usersCount}}</span>/{{licensesInformation.userLicenses}}) </section>
                <section class="esesja-licenses-info-component__info-separator"> | </section>
                <section v-tooltip="'Konta administratorów systemu'"> Licencje administracyjne:</section>
                <section class="esesja-licenses-info-component__license-numbers"> (<span class="esesja-licenses-info-component__user-count">{{licensesInformation.adminCount}}</span>/{{licensesInformation.adminLicenses}}) </section>
                <section class="esesja-licenses-info-component__info-separator"> | </section>
                <section>SUMA:</section>
            <section class="esesja-licenses-info-component__license-numbers"> (<span class="esesja-licenses-info-component__user-count">{{licensesInformation.usersCount + licensesInformation.adminCount}}</span>/{{licensesInformation.userLicenses + licensesInformation.adminLicenses}}) </section>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    import UserService from "../../../../../../services/user.service";

    export default {
        name: "LicensesInfo",
        data() {
            return {
                licensesInformation: {
                    userLicenses: 0,
                    adminLicenses: 0,
                    usersCount: 0,
                    adminCount: 0,
                },
            }
        },
        watch: {
            '$store.state.contacts.updateClientLicenses': {
                deep: true,
                handler: async function () {
                    if (this.$route.name === "contact" && this.$store.state.contacts.updateClientLicenses == true) {
                        await this.getClientLicenseInformation();
                        this.$store.commit('setUpdateLicenses', false);
                    }
                }
            },
        },
        async mounted() {
            await this.getClientLicenseInformation();
        },
        methods: {
            async getClientLicenseInformation() {
                try {
                    let response = await UserService.getClientLicenseInformation();

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.licensesInformation = response.data;
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
    .esesja-licenses-info-component {
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: default;

        &__icon {
            padding: 14px;
        }

        &__license-box {
            height: 48px;
            padding: 14px 16px;
            background: #ffffff;
            border: 1px solid #dce4ee;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__license-box-data-wrapper {
            display: flex;
            align-items: center;

            &__text {
                font-weight: 500;
                font-size: 14px;
                line-height: 145%;
                color: #00082c;
            }
        }

        &__info-separator {
            margin-left: 3px;
            margin-right: 3px;
            color: grey;
        }

        &__license-numbers {
            font-weight: 600;
            margin-left: 2px;
        }

        &__user-count {
            color: red;
        }
    }
</style>


