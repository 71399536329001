<template>
    <vue-recaptcha v-show="internalConfig.showRecaptcha" :sitekey="internalConfig.siteKey"
                   size="120px"
                   :theme="config.theme"
                   :hl="config.language"
                   :loading-timeout="internalConfig.loadingTimeout"
                   @verify="recaptchaVerified"
                   @expire="recaptchaExpired"
                   @fail="recaptchaFailed"
                   @error="recaptchaError"
                   ref="vueRecaptcha" />
</template>
<script charset="utf-8">
    import vueRecaptcha from 'vue3-recaptcha2';
    import { reCaptchConfig } from '../../config/recaptcha.config';

    export default {
        name: "ReCaptchaComponent",
        components: {
            vueRecaptcha
        },
        props: {
            modelValue: {
                type: String,
                default: null,
                required: true
            },
            config: {
                type: Object,
                required: true,
                default() {
                    return {
                        language: 'pl',
                        theme: 'light', 
                    }
                }
            }
        },
        data() {
            return {
                internalConfig: {
                    loadingTimeout: 30000, // 30 seconds
                    showRecaptcha: true, // show button "I`m not a robot"
                    siteKey: ''
                },
                internalToken: '',
                reason: '',
            }
        },
        watch: {
            internalToken: {
                handler(value) {
                    this.$emit("update:modelValue", value);
                }
            }
        },
        mounted() {
            this.internalConfig = { ...reCaptchConfig }
        },
        methods: {
            recaptchaVerified(response) {
                this.internalToken = response;
            },
            recaptchaExpired() {
                this.$refs.vueRecaptcha[0].reset();
                this.internalToken = '';
            },
            recaptchaFailed() {
                this.internalToken = '';
            },
            recaptchaError(reason) {
                this.reason = reason;
                this.internalToken = '';
            }
        }
    }
</script>