<template>
    <section class="warning-box__errors">
        <section class="warning-box__errors__item">
            <section class="warning-box__errors__item__icon">
                <img src="@/assets/icons/components/error-alert.svg" />
            </section>
            <section class="warning-box__errors__item__body">
                <slot></slot>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "WarningBox"
    }
</script>
<style lang="scss" scoped>
    .warning-box {

        &__errors {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            &__item {
                display: flex;
                flex-direction: row;
                column-gap: 20px;
                padding: 20px;
                border: 1px solid red;
                background: rgba(255,0,0, 0.3);
                align-items: center;

                &__button {
                    padding: 13.5px 33px;
                    align-items: center;
                    justify-content: center;
                    background: #d80418;
                    border-radius: 4px;
                    border: 0px;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        background-color: #ad0313;
                    }

                    &__text {
                        color: white;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 132%;
                        margin-left: 8px;
                    }

                    &--small {
                        padding: 7px 16px;
                    }

                    &--white {
                        border: 1px solid #bbc2ce;
                        background-color: white;


                        &:hover {
                            background-color: #e8ebef;
                        }

                        .component__button__text {
                            color: #00082c;
                        }
                    }
                }
            }
        }
    }
</style>