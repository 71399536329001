<template>
    <section :class="getRouteName">
        <router-view />
        <section v-if="isLoading" class="main-loader">
            <Loader />
        </section>
        <ConfirmationAlert v-if="isConfirmationAlertVisible" />
        <ConfirmationAlertWithData v-if="isConfirmationAlertWithDataVisible" />
    </section>
</template>
<script charset="utf-8">
import Loader from "./components/Loader.vue";
import ConfirmationAlert from "./components/Alerts/ConfirmationAlert.vue";
import ConfirmationAlertWithData from "./components/Alerts/ConfirmationAlertWithData.vue";
import alertsHelper from "./helpers/alerts.helper";

import { Tab } from "bootstrap";

export default {
    components: {
        Loader,
        ConfirmationAlert,
        ConfirmationAlertWithData
    },
    computed: {
        getRouteName() {
            return `page-${this.$route.name}`;
        },
        isLoading() {
            return this.$store.state.userStore.isLoading;
        },
        isSuccessVisible() {
            return this.$store.state.appStore.successVisible;
        },
        isErrorVisible() {
            return this.$store.state.appStore.errorVisible;
        },
        isNoticeVisible() {
            return this.$store.state.appStore.noticeVisible;
        },
        isAlertVisible() {
            return (
                this.$store.state.appStore.successVisible ||
                this.$store.state.appStore.errorVisible ||
                this.$store.state.appStore.noticeVisible
            );
        },
        isConfirmationAlertVisible() {
            return this.$store.state.appStore.isConfirmationAlertVisible;
        },
        isConfirmationAlertWithDataVisible() {
            return this.$store.state.appStore.isConfirmationAlertWithDataVisible;
        },
    },
    watch: {
        getRouteName: {
            immediate: true,
            handler(val) {
                if (val === null || val === undefined) {
                    return;
                }
                document.body.id = this.getRouteName
            }
        },
        isErrorVisible:{
            immediate: true,
            async handler(val){
                this.$store.commit("hideError");
                if(val){
                    const toast = this.$swal.mixin(alertsHelper.errorToastElement());
                    await toast.fire(alertsHelper.errorToastAlert('W trakcie przetwarzania akcji wystąpił nieoczekiwany błąd.'));
                }
            }
        },
        isSuccessVisible:{
            immediate: true,
            async handler(val){
                const message = this.$store.state.appStore.successCustomMessage ?? "Akcja wykonana pomyślnie. Zmiany zostały zapisane.";
                this.$store.commit("hideSuccess");
                if(val){
                    var t = this.$swal.mixin(alertsHelper.informationSuccessElement());
                    await t.fire(alertsHelper.successAlert(message));
                    
                }
            }
        },
        isNoticeVisible:{
            immediate: true,
            async handler(val){
                this.$store.commit("hideNotice");
                if(val){
                    const message = "Powiadomienie";
                    var t = this.$swal.mixin(alertsHelper.informationSuccessElement());
                    await t.fire(alertsHelper.informationAlert(message));
                    
                }
            }
        }
    },
    mounted() {
        try {
            new Tab(document.body);
        }
        catch (e) {
            console.log(e);
        }
    }
};
</script>
<style lang="scss">
@import "assets/scss/common.scss";
@import "assets/scss/bootstrap/bootstrap.scss";

* {
    margin: 0px;
    font-family: "Inter", sans-serif;
}

a {
    text-decoration: none;

    &:visited {
        text-decoration: none;
        color: initial;
    }
}

button,
input,
select,
textarea {
    &:focus {
        outline: none;
    }
}

button {
    cursor: pointer;
}

.main-loader {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
}

#page-DiscussionQuestList,
.page-DiscussionQuestList {
    overflow: hidden;
    height: 100vh;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 350ms ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.alerts-container {
    position: fixed;
    bottom: 118px;
    right: 48px;
    z-index: 30;
    display: flex;
    flex-direction: column;
}
</style>
