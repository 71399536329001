<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Edycja ustawień email</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeModal');}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section class="column">
                    <section class="column-item">
                        <label class="esesja-modal__wraper__body__label">Nazwa nadawcy</label>
                        <section class="esesja-modal__row">
                            <input class="esesja-modal__wraper__body__input"
                                   type="text"
                                   v-model="name"
                                   placecholder="Nazwa nadawcy" v-error="{ errors, input_key: 'name' }" />
                            <ErrorMessage v-if="isError('name', this.errors)" :errorMsgs="passErrorMsg('name', this.errors)" />
                        </section>
                    </section>
                    <section class="column-item">
                        <label class="esesja-modal__wraper__body__label">Zwrotny adres e-mail</label>
                        <section class="esesja-modal__row">
                            <input class="esesja-modal__wraper__body__input"
                                   type="text"
                                   v-model="email"
                                   placecholder="Zwrotny adres email" v-error="{ errors, input_key: 'email' }" />
                            <ErrorMessage v-if="isError('email', this.errors)"
                                          :errorMsgs="passErrorMsg('email', this.errors)" />
                        </section>
                    </section>
                </section>
                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="this.$emit('closeModal')"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="updateEmailSettings">Zapisz</button>
                </section>
            </perfect-scrollbar>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
    import UserService from "../../../../../../services/user.service";
    import ErrorMessage from "@/components/BasicControls/ErrorMessage.vue";
    import {
        passErrorMsg,
        isError,
        setErrors,
    } from "@/formValidation/main";

    export default {
        name: "EditEmailSettings",
        emits: ['closeModal'],
        data() {
            return {
                errors: [],
                showErrors: false,
                email: this.$store.state.userStore.settings.userReplyToEmail,
                name: this.$store.state.userStore.settings.userEmailSenderName
            }
        },
        components: {
            ErrorMessage
        },
        mounted() {
            this.internalData = this.modelValue
        },
        methods: {
            validate() {
                var validateStatus = true;
                this.errors = [];
                var errors = {};

                if (this.name.length <= 0) {
                    validateStatus = false;
                    errors.name = ['Pole wymagane']
                }

                if (this.email.length == 0) {
                    errors.email = ['Nie można dodać pustego adresu email']
                    validateStatus = false;
                }

                if (this.email.length > 0) {
                    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email) == false) {
                        errors.email = ['Niepoprawny format email lub dodano więcej niż jeden adres email']
                        validateStatus = false;
                    }
                }

                if (!validateStatus) {
                    this.isLoading = false;
                    this.errors = setErrors(errors)
                }

                return validateStatus;
            },
            async updateEmailSettings(){
                try {
                    this.showErrors = false;
                    if (!this.validate()) {
                        return;
                    }

                    this.$store.commit("showLoader");
                    var data = {
                        userReplyToEmail: this.email,
                        userEmailSenderName: this.name
                    };

                    let response = await UserService.updateEmailSettings(data)

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.$store.commit("updateEmailSettings", data)
                    this.$emit('closeModal');
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'fetchSessionData' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            passErrorMsg,
            setErrors,
            isError,
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/esesja-modal.scss';

    .in-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 24px;
    }

    .in-row .esesja-modal__row {
        margin-bottom: 0px;
    }
</style>