<template>
    <section class="public-placeholder-view" :style="{ 'background-image': 'url(' + placeholder.path + ')'}">
    </section>
</template>
<script charset="utf-8">
    export default{
        name: "PublicPlaceholderView",
        props:{
            placeholder: {
                type: Object,
                default(){
                    return{
                        id: -1,
                        name: "",
                        description: "",
                        path: "",
                    }
                }
            }
        }
    }
</script>
<style lang="scss">
    .public-placeholder-view{
        position: fixed;
        top: 0vh;
        left: 0vw;
        z-index: 19;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
    }
</style>