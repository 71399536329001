<template>
    <section class="session" ref="sessionContainerTemplate">
        <section class="session__top">
            <h2 class="session__title" style="width: 100%;">
                {{ getTemplateNameFormStore() }}
            </h2>
            <section class="session-top-btn-wrapper">
                <button class="
                        session-top-btn-wrapper__button
                        session-top-btn-wrapper__button--spacing
                        session-top-btn-wrapper__button--white
                    "
                        @click="handleEditTemplate">
                    <img src="../../../../assets/icons/components/edit.svg" />
                    <span class="
                            session-top-btn-wrapper__text
                            session-top-btn-wrapper__text--white
                        ">Ustawienia</span>
                </button>

            </section>
        </section>
        <SessionPointsList :items="agendaItems"
                           v-if="agendaItems.length > 0 && isLoaded"
                           :session="templateData"
                           @end="setList"
                           @refreshList="getList"
                           @reloadData="getList"
                           :firstLevel="config.firstLevel"
                           :secondLevel="config.secondLevel"
                           :thirdLevel="config.thirdLevel"
                           :checkedPointIds="point_ids"
                           :isTemplate="isTemplate"
                           @setPointIds="(value)=> { setPointsIds(value); this.isAddPointToParent = false;}"
                           @handleEditPoint="handleEditPoint" />

        <AddPointBottomMenu :session="templateData"
                            :isAddPointToParent="isAddPointToParent"
                            v-if="point_ids.length === 0 || isAddPointToParent"
                            @setSession="(session) => (this.templateData = session)"
                            @addPoint="(point) => this.addPoint(point)"
                            @refreshList="getList()"
                            @cancelAddPointToParent="isAddPointToParent = false" />

        <EditPointsBottomMenu v-if="point_ids.length > 0 && !isAddPointToParent"
                              :point_ids="point_ids"
                              @cancel="point_ids = []"
                              @checkAll="(points) => (point_ids = points)"
                              :agendaItems="agendaItems"
                              :isTemplate="isTemplate"
                              @addPoint="(point) => this.addPoint(point)"
                              @reloadData="getList"
                              @refreshList="getList"
                              @pointMoved="setList"
                              @addPointAsChildren="isAddPointToParent = true" />


        <EditPoint v-if="editPointVisible"
                   :pickedPoint="pickedPoint"
                   @closeEdit="()=>{ this.editPointVisible = false; this.pickedPoint = {}; }"
                   @reloadData="getList"
                   @reloadPoint="(data) => (pickedPoint = data)" />

    </section>
</template>
<script charset="utf-8">
    import SessionService from "../../../../services/session.service";
    import { setErrors } from "../../../../formValidation/main";

    import AddPointBottomMenu from "./AddPointBottomMenu.vue";
    import EditPointsBottomMenu from "./EditPointsBottomMenu.vue";
    import SessionPointsList from "./SessionPointsList.vue";
    import EditPoint from "./EditPointModal/EditPoint.vue";

    export default {
        name: "sessionTemplateEdit",
        components: {
            AddPointBottomMenu,
            SessionPointsList,
            EditPointsBottomMenu,
            EditPoint
        },
        computed: {
            templateId() {
                return this.$route.params.sessionid;
            },

            isAnyModalOpen(){
                return this.editPointVisible
                    || this.$store.state.appStore.isAddMeetingVisible
                    || this.$store.state.appStore.isAddMeetingTemplateVisible;
            }
        },
        data() {
            return {
                isLoaded: false,
                isTemplate: true,
                templateData: {},
                editPointVisible: false,
                pickedPoint: {},
                agendaItems: [],
                config: {
                    firstLevel: 1,
                    secondLevel: 1,
                    thirdLevel: 4,
                },
                point_ids: [],
                isAddPointToParent: false,
            }
        },
        watch: {
            $route() {
                if (this.templateId !== undefined) {
                    this.fetchData();
                    this.point_ids = [];
                }
            },
            isAnyModalOpen: {
                deep: true,
                handler: function (val) {
                    document.querySelector('body').style.overflow = val ? 'hidden' : 'auto';
                }
            },
        },
        created() {
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            getTemplateNameFormStore() {
                return this.$store.state.appStore.sessionTemplates.find(x => x.id == this.templateId).signature;
            },
            async fetchData() {
                try {
                    this.isLoaded = false;
                    this.$store.commit("showLoader");
                    let response = await SessionService.getSessionWithPointsById(this.templateId);
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }
                    this.templateData = response.data;
                    this.agendaItems = response.data.agendaItems;
                    this.isLoaded = true;
                    
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sessionTemplate'} });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            setPointsIds(ids) {
                this.point_ids = ids;
            },

            async getList() {
                try {
                    this.$store.commit("showLoader");
                    let response = await SessionService.getSessionWithPointsById(this.templateId);

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.agendaItems = response.data.agendaItems;

                    if (!this.isAddPointToParent) {

                        setTimeout(() => {
                            this.$refs.sessionContainerTemplate.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                        }, 100);
                    }

                    this.isAddPointToParent = false;   

                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sessionTemplate' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            async setList() {
                try {

                    this.$store.commit("showLoader");
                    const newList = this.orderRecursive(this.agendaItems);
                    let response = await SessionService.movePoints(this.templateId, newList);


                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    await this.getList();
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sessionTemplate' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            addPoint(pointName) {
                const newPoint = {
                    name: pointName,
                    order: this.agendaItems.length + 1,
                };

                if (this.isAddPointToParent) {
                    newPoint["parent_id"] = this.point_ids[0];
                    this.findParentElement(this.agendaItems);
                    //var index = this.agendaItems.find(x => x.id == this.point_ids[0]);
                    var index = this.findParentElement(this.agendaItems);
                    var order = index.children.length + 1;
                    newPoint.order = order;
                }

                this.$store.commit("showLoader");
                this.errors = [];
                SessionService.addPoint(this.templateId, newPoint)
                    .then(() => {
                        this.$store.commit("hideLoader");
                        this.getList();

                    })
                    .catch((err) => {
                        this.$store.commit("hideLoader");
                        this.errors = setErrors(err);
                    });
            },

            findParentElement(pointsData) {
                try {
                    var index = pointsData.find(x => x.id == this.point_ids[0]);

                    if (index !== undefined)
                        return index;

                    for (var el in pointsData) {
                        var childRet = this.findParentElement(pointsData[el].children);
                        if (childRet !== undefined) {
                            return childRet;
                        }
                    }
                }
                catch (e) {
                    console.error(e);
                }
            },

            orderRecursive(list) {
                return list.map((item, key) => {
                    item.order = key + 1;
                    item.children = this.orderRecursive(item.children);
                    return item;
                });
            },

            handleEditTemplate() {
                this.$store.commit("setTemplateId", this.templateId);
                this.$store.commit("showAddMeetingTemplate");
            },
            handleEditPoint(item) {
                this.pickedPoint = item;
                this.editPointVisible = true;
            },
            //addMeetingBasedOnTemplate() {
            //    this.$store.commit("showAddMeeting", this.templateId);
            //}
        }
    }
</script>
<style lang="scss" scoped>
    @import "../../../../assets/scss/session-points-list.scss"
</style>