<template>
    <section class="calendar-event session" :class="commonSessionClass" @click="pushRouter" v-tooltip="getTooltip"
        @mouseover="mouseOver" @mouseleave="mouseLeave">
        <span class="calendar-event--date">
            {{ getHumanTime }}
        </span>
        <span class="calendar-event--name">
            {{ event.name }}
        </span>
    </section>
</template>
<script charset="utf-8">
export default {
    name: "CalendarEventSession",
    props: {
        event: {
            type: Object
        }
    },
    data() {
        return {
            sessionClassPrefix: 'session-event-identifier'
        }
    },
    computed: {
        getTooltip() {
            return `Przejdź do posiedzenia ${this.event.name}`
        },
        getHumanTime() {
            return `${this.event.date.getHours().toString().padStart(2, '0')}:${this.event.date.getMinutes().toString().padStart(2, '0')}`
        },
        commonSessionClass() {
            return `${this.sessionClassPrefix}-${this.event.id}`;
        }
    },
    methods: {
        async pushRouter() {
            await this.$router.push(this.event.router);
        },
        mouseOver(e) {
            try {
                let commonElements = this.getElementsWithCommonClassName(e);

                for (let element of commonElements) {
                    element.classList.add("common-session-highlight")
                }
            }
            catch (e) {
                console.log(e);
            }
        },
        mouseLeave(e) {
            try {
                let commonElements = this.getElementsWithCommonClassName(e);

                for (let element of commonElements) {
                    element.classList.remove("common-session-highlight")
                }
            }
            catch (e) {
                console.log(e);
            }
        },
        getElementsWithCommonClassName(e) {
            if (!e.target.className.includes(this.sessionClassPrefix)) {
                return [];
            }
            let searchClassess = e.target.className.split(" ").filter(item => item.includes(this.sessionClassPrefix)) ?? [];
            if (searchClassess.length !== 1) {
                return [];
            }
            let searchClass = searchClassess[0]
            let commonElements = Array.from(document.getElementsByClassName(searchClass)) ?? [];
            if (commonElements.length === 1) {
                return [];
            }
            return commonElements;
        }

    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/calendar-event.scss";

.session {
    background: rgba(216, 4, 24, 0.6);
    color: #ffffff;

    &:hover {
        background: rgba(216, 4, 24, 1);
    }
}

.common-session-highlight {
    background: rgba(216, 4, 24, 1);
}
</style>