<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Zmiana hasła</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$store.commit('hideShowUserChangeOwnPassword');}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section class="esesja-modal__wraper__body__fallback-error" v-if="changePasswordError">
                    <img src="@/assets/icons/components/exit-btn.svg" class="esesja-modal__wraper__body__fallback-error__icon" alt="Ikona błędu" />
                    <span class="esesja-modal__wraper__body__fallback-error__text">
                        Sprawdź poprawność wpisanych danych
                    </span>
                </section>
                <label class="esesja-modal__wraper__body__label">Dotychczasowe hasło</label>
                <section class="esesja-modal__row">
                    <input class="esesja-modal__wraper__body__input"
                           type="password"
                           v-model="passwords.oldPassword"
                           placecholder="Dotychczasowe hasło"
                           v-error="{ errors, input_key: 'oldPassword' }" />
                    <ErrorMessage v-if="isError('oldPassword', this.errors)"
                                  :errorMsgs="passErrorMsg('oldPassword', this.errors)" />
                </section>

                <label class="esesja-modal__wraper__body__label">Nowe hasło</label>
                <section class="esesja-modal__row">
                    <section class="esesja-modal__wraper__body__input-with-button" data-tip="Hasło musi zawierać min. 12 znaków">
                        <input class="esesja-modal__wraper__body__input"
                               :type="passwordFieldType"
                               v-model="passwords.newPassword"
                               placecholder="Nowe hasło"
                               v-error="{ errors, input_key: 'newPassword' }" />
                        <section class="esesja-modal__wraper__body__input__append-button" @click="switchVisibility">
                            <img src="@/assets/icons/eye.svg" />
                        </section>
                    </section>
                    <ErrorMessage v-if="isError('newPassword', this.errors)"
                                  :errorMsgs="passErrorMsg('newPassword', this.errors)" />
                </section>

                <label class="esesja-modal__wraper__body__label">Powtórz nowe hasło</label>
                <section class="esesja-modal__row">
                    <section style="display: flex" data-tip="Hasło musi zawierać min. 12 znaków">
                        <input class="esesja-modal__wraper__body__input"
                               :type="passwordFieldType"
                               v-model="passwords.confirmNewPassword"
                               placecholder="Nowe hasło"
                               v-error="{ errors, input_key: 'confirmNewPassword' }" />
                    </section>
                    <ErrorMessage v-if="isError('confirmNewPassword', this.errors)"
                                  :errorMsgs="passErrorMsg('confirmNewPassword', this.errors)" />
                </section>

                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="this.$store.commit('hideShowUserChangeOwnPassword')">Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="changePassword">Zapisz</button>
                </section>
            </perfect-scrollbar>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
    import ErrorMessage from "@/components/BasicControls/ErrorMessage.vue";
    import {
        passErrorMsg,
        isError,
        setErrors,
    } from "@/formValidation/main";
    import UserService from "../../../../../services/user.service";
    export default {
        name: "ChangeOwnPasswordModal",
        components: {
            ErrorMessage
        },
        data() {
            return {
                passwords: {
                    oldPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                },
                errors: [],
                changePasswordError: false,
                passwordFieldType: "password",
            }
        },
        methods: {
            async changePassword() {
                try {
                    this.changePasswordError = false;
                    this.$store.commit("showLoader");
                    if (!this.validate()) {
                        return;
                    }

                    let response = await UserService.updateOwnPassword(this.passwords);

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.changePasswordError = !response.data;
                    if (response.data === true) {
                        this.$store.commit('hideShowUserChangeOwnPassword');
                        return;
                    }



                }
                catch (e) {
                    this.changePasswordError = true;
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            switchVisibility() {
                if (this.passwordFieldType === "password") {
                    this.passwordFieldType = "text";
                }
                else {
                    this.passwordFieldType = "password";
                }
            },
            validate() {
                var validateStatus = true;
                var errors = {};

                if (this.passwords.oldPassword.length <= 0) {
                    validateStatus = false;
                    errors.oldPassword = ['Pole wymagane']
                }

                if (this.passwords.newPassword.length < 12) {
                    validateStatus = false;
                    errors.newPassword = ['Hasło nie może byc krótsze niż 12 znaków']
                }
                else {
                    if (this.passwords.oldPassword === this.passwords.newPassword) {
                        validateStatus = false;
                        errors.oldPassword = ['Nowe hasło nie może być identyczne jak stare']
                        errors.newPassword = ['Nowe hasło nie może być identyczne jak stare']
                    }
                }

                if (this.passwords.confirmNewPassword.length < 12) {
                    validateStatus = false;
                    errors.confirmNewPassword = ['Hasło nie może byc krótsze niż 12 znaków']
                }
                else {
                    if (this.passwords.confirmNewPassword !== this.passwords.newPassword) {
                        validateStatus = false;
                        errors.newPassword = ['Podane hasła się nie zgadzają']
                        errors.confirmNewPassword = ['Podane hasła się nie zgadzają']
                    }
                }

                if (!validateStatus) {
                    this.isLoading = false;
                    this.errors = setErrors(errors)
                }

                return validateStatus;
            },
            passErrorMsg,
            setErrors,
            isError,
        }

    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/esesja-modal.scss';
    @import '@/assets/scss/esesja-data-tip.scss'; 
</style>