<template>
    <section class="calendar-contenxt-menu"
        :style="{ 'bottom': 'auto', 'right': 'auto', 'left': `${x}px`, 'top': `${y}px` }">
        <section class="calendar-contenxt-menu__item" @click="$emit('addMetting')">
            <img src="@/assets/icons/navMenu/posiedzenia.svg"
                class="calendar-contenxt-menu__item__icon calendar-contenxt-menu__item__gray-to-black" />
            <span class="calendar-contenxt-menu__item__text">Dodaj posiedzenie</span>
        </section>
        <section class="calendar-contenxt-menu__item" @click="$emit('addAnnoucement')" v-if=hasAnnouncementModule>
            <img src="@/assets/icons/navMenu/contact.svg"
                class="calendar-contenxt-menu__item__icon calendar-contenxt-menu__item__gray-to-black" />
            <span class="calendar-contenxt-menu__item__text">Dodaj komunikat</span>
        </section>
    </section>
</template>
<script charset="utf-8">
import { encryptStorage } from '../../../../services/encrypt.service'

export default {
    name: "CalendarContextMenu",
    emits: ['addMetting', 'addAnnoucement'],
    computed: {
        x() {
            return this.$store.state.calendarStore.contextMenuPos.x
        },
        y() {
            return this.$store.state.calendarStore.contextMenuPos.y
        }
    },
    data(){
        return {
            hasAnnouncementModule: encryptStorage.getItem('modules').inf === 1 ? true : false,
        }
    }
}
</script>
<style lang="scss" scoped>
.calendar-contenxt-menu {
    padding: 12px 0px;
    position: absolute;
    z-index: 128;
    background-color: white;
    border-radius: 12px;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    box-shadow: 16px 32px 48px rgba(0, 8, 44, 0.12);
    min-width: 270px;

    &__item {
        padding: 12px 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &__icon {
            width: 20px;
            height: 20px;
        }

        &__text {
            margin-left: 12px;
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;
            color: #00082c;
        }

        &__text2 {
            margin-left: 12px;
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;
            color: #d80418;
        }

        &__gray-to-black {
            filter: saturate(100%) brightness(0%) contrast(100%);
        }

        &:hover {
            background: rgba(0, 0, 0, 0.2);
        }
    }
}
</style>