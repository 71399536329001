<template>
    <section class="esesja-modal__wraper__header" tabindex="0" @keydown.esc="() => $emit('close')">
        <h3 class="esesja-modal__wraper__header__title">
            <slot></slot>
        </h3>
        <button class="esesja-modal__wraper__header__exit-btn"
                @click=" (e)=>{e.preventDefault(); $emit('close')}">
            <img src="@/assets/icons/components/exit-btn.svg" />
        </button>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "eSesjaModalHeader",
        emits: ["close"]
    }
</script>