<template>
    <section class="characters-counter" :class="{'has-error': text.length > maxLenght }">
        <span class="counter">
            {{ text.length }} / {{ maxLenght }}
        </span>
    </section>
</template>
<script charset="utf-8">
export default {
    name: "CharactersCounter",
    props: {
        text: {
            type: String,
            default: ""
        },
        maxLenght: {
            type: Number,
            default: 100,
        }
    }
}
</script>
<style lang="scss">
.characters-counter {
    display: flex;
    padding-top: 5px;
    justify-content: end;

    .counter {
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #00082c;
    }
}

.has-error{
    .counter{
        color: red;
    }
}
</style>