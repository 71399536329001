class CommonHelper {
    getRandomVersionImageForUrlRefresh(minimum, maximum) {

        var number = Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
        return "?version=img" + number;
    }

    getNonNullObjectEntries(object) {
        return Object.fromEntries(Object.entries(object).filter(([v]) => object[v] !== null && object[v] !== ''));
    }

    isObjectIsEmpty(object) {
       return Object.keys(object).length === 0 && object.constructor === Object
    }

    isObjectHasProperty(object, propertyName) {
        return Object.hasOwn(object, propertyName);
    }

    toArrayInt(obj) {
        if (Array.isArray(obj)) {
            return obj.map(Number);
        }
        return [parseInt(obj)];
    }
}

export default new CommonHelper();