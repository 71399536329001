<template>
    <section class="esesja-modal" @keyup.esc="this.$emit('close')">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">e-Mail Komunikator: Adresat spoza listy</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('close');}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <!--<label class="esesja-modal__wraper__body__label">Imię</label>
                <section class="esesja-modal__row">
                    <input type="text" class="esesja-modal__wraper__body__input" placecholder="Imię" v-model="formModel.name" required />
                </section>
                <label class="esesja-modal__wraper__body__label">Naziwsko</label>
                <section class="esesja-modal__row">
                    <input type="text" class="esesja-modal__wraper__body__input" placecholder="Nazwisko" v-model="formModel.surname" required />
                </section>-->
                <label class="esesja-modal__wraper__body__label">e-Mail</label>
                <section class="esesja-modal__row">
                    <input type="email" ref="customRegardingEmailInput" class="esesja-modal__wraper__body__input" placecholder="e-Mail" v-model="formModel.email" required v-error="{ errors, input_key: 'customUserRecipent' }" @keyup.enter="addTemporaryUser" />
                    <ErrorMessage v-if="isError('customUserRecipent', this.errors)" :errorMsgs="passErrorMsg('customUserRecipent', this.errors)" />
                </section>
                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="$emit('close')"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="addTemporaryUser">Dodaj</button>
                </section>
            </perfect-scrollbar>
        </section>
    </section>
</template>
<script charset="utf-8">
    import {
        passErrorMsg,
        isError,
        setErrors,
    } from "../../../formValidation/main";

    import ErrorMessage from "../../BasicControls/ErrorMessage.vue";

    export default {
        name: "AddCutomMeetingRegardingUser",
        emits: ["close", "save"],
        components: {
            ErrorMessage
        },
        data() {
            return {
                formModel: {
                    name: "",
                    surname: "",
                    email: "",
                },
                errors: [],
                showErrors: false,
            }
        },
        mounted() {
            try {
                this.$refs.customRegardingEmailInput.focus();
            }
            catch (e) {
                console.log(e);
            }
        },
        methods: {
            addTemporaryUser() {
                try {
                    this.showErrors = false;

                    this.errors = [];

                    if (this.validateEmail(this.formModel.email) == false)
                        return

                    this.$emit('save', { ...this.formModel });
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            validateEmail(email) {

                var bo = true;
                var err = {};

                if (email.length == 0) {
                    err.customUserRecipent = ['Nie można dodać pustego adresu email']
                    bo = false
                }

                if (email.length > 0) {
                    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) == false) {
                        err.customUserRecipent = ['Niepoprawny format email lub dodano więcej niż jeden adres email']
                        bo = false
                    }
                }

                this.showErrors = !bo;
                if (!bo) {
                    this.errors = setErrors(err)
                }
                return bo
            },
            passErrorMsg,
            setErrors,
            isError,
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/esesja-modal.scss';
</style>