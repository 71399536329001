export const contactsStore = {
    state: () => ({
        selectedPeopleToSendMessage: [],
        sendMessageSuccess: false,
        updateClientLicenses: false,
    }),
    mutations: {
        setSelectedPeopleToSendMessage(state, status) {
            state.selectedPeopleToSendMessage = status;
        },
        setSendMessageSuccess(state, status) {
            state.sendMessageSuccess = status;
        },
        setUpdateLicenses(state, status) {
            state.updateClientLicenses = status;
        },
    }
}