<template>
    <section class="session-metadata-modal">
        <section class="session-metadata-modal__wrapper">
            <section class="session-metadata-modal__wrapper__header">
                <section class="session-metadata-modal__wrapper__header__title">
                    <h3>Protokół z posiedzenia</h3>
                </section>
                <section class="session-metadata-modal__wrapper__header__action">
                    <button class="session-metadata-modal__wrapper__header__action__exit-btn"
                            @click="(e)=> { e.preventDefault();this.$store.commit('hideAddProtocol');}">
                        <img src="../../../../../assets/icons/components/exit-btn.svg" />
                    </button>
                </section>
            </section>
            <section class="session-metadata-modal__wrapper__body">
                <section class="session-metadata-modal__wrapper__body__session-data">
                    <h2>{{sessionProtocol.clientName}}</h2>
                    <p>
                        {{sessionProtocol.sessionName}} w dniu {{ formatDate(sessionProtocol.sessionDate,'yyyy-MM-DD')}}
                    </p>
                </section>
                <section class="session-metadata-modal__wrapper__body__metadata">
                    <div>
                        <table v-if="sessionProtocol.protocols.length > 0" class="table-bootstrap table-bootstrap-hover table-bootstrap-bordered table-bootstrap-striped align-middle">
                            <thead>
                                <tr class="align-middle">
                                    <th>#</th>
                                    <th>Nazwa</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in sessionProtocol.protocols" :key="index" class="align-middle">
                                    <td style="width: 50px;">{{index + 1}}</td>
                                    <td>
                                        <img v-if="item.type == 2" src="../../../../../assets/icons/components/attachment.svg" />
                                        <a v-if="item.id != tempChangeNameProtocolId" class="points-list__attachment-link" @click="handleDownloadProtocolClick(item)">
                                            {{ item.name }}
                                        </a>
                                        <input v-if="item.id == tempChangeNameProtocolId" v-model="tempProtocolName" />
                                    </td>
                                    <td style="width: 50px;">
                                        <button v-if="item.id != tempChangeNameProtocolId" class="points-list__action-btnatt" @click="handleEditProtocolClick(item)">
                                            <img src="../../../../../assets/icons/components/edit.svg" class="points-list__action-icon" />
                                        </button>
                                        <div v-if="item.id == tempChangeNameProtocolId">
                                            <button class="points-list__action-btnatt" @click="handleSaveProtocolClick(item)">
                                                <img src="../../../../../assets/icons/components/accept-mark-green.svg" class="points-list__action-icon" />
                                            </button>
                                            <br />
                                            <button class="points-list__action-btnatt" @click="handleCencelProtocolClick()">
                                                <img src="../../../../../assets/icons/components/exit-btn.svg" class="points-list__action-icon" />
                                            </button>
                                        </div>
                                    </td>
                                    <td style="width: 50px;">
                                        <button class="points-list__action-btnatt points-list__action-btnatt--red" @click="handleDeleteProtocolClick(item)">
                                            <img src="../../../../../assets/icons/components/delete-point.svg" class="points-list__action-icon" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p v-else>
                            Brak dodanych protokołów
                        </p>
                    </div>
                </section>
                <section class="edit-point-form__buttons-bottom" v-if="exportPrivilige">
                    <button class="edit-point-form__bottom-button edit-point-form__bottom-button--red" @click="addFile(1)" > Dodaj protokół </button>
                    <button class="edit-point-form__bottom-button edit-point-form__bottom-button--red" @click="addFile(2)" > Dodaj załacznik </button>
                </section>
            </section>
        </section>
    </section>
</template>

<script charset="utf-8">
    import moment from "moment";
    import ProtocolService from "../../../../../services/protocol.service";
    import { encryptStorage } from '../../../../../services/encrypt.service';
    import AlertHelper from '@/helpers/alerts.helper.js';

    export default {
        name: "SessionAddProtcolModal",
        components: {

        },
        computed: {
            getSessionId() {
                return this.$store.state.appStore.sessionAddProtocolId;
            },
        },
        data() {
            return {
                sessionProtocol: {
                    clientName: '',
                    sessionName: '',
                    sessionDate: null,
                    protocols: [],
                },
                tempChangeNameProtocolId: 0,
                tempProtocolName: '',
                files: [],
                exportPrivilige: encryptStorage.getItem('priv').eksport
            }
        },
        mounted() {
            this.fetchSessionProtocolData();
        },
        methods: {
            addFile(type) {
                let input = document.createElement('input');
                input.type = 'file';
                input.accept = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.png,.jpg,.jpeg';
                input.multiple = false;
                input.click();
                input.addEventListener('change', (event) => {
                    if (event.target.files[0].size <= 30000000) {
                        if (this.checkMimeType(event.target.files[0].type)) {
                            if (this.files.length > 0) {
                                for (var i = 0; i < this.files.length; i++) {
                                    if (this.files[i][0].name == event.target.files[0].name)
                                        return;
                                }
                                this.files.push(event.target.files);
                            }
                            else {
                                this.files.push(event.target.files);
                            }

                            this.sendFileToDb(type, this.files);
                        }
                        else {
                            this.$swal(AlertHelper.errorAlert("Nieobsługiwany typ pliku. Pliki obsługiwane to: DOC,DOCX,PDF,JPG,JPEG,PNG"));
                        }
                    } else {
                        this.$swal(AlertHelper.errorAlert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 30MB"));
                    }
                });
            },
            async sendFileToDb(type, files) {
                try {
                    let formData = new FormData();
                    for (var i = 0; i < files.length; i++) {
                        let file = files[i][0];
                        formData.append('files[' + i + ']', file);
                    }

                    formData.append("sid", this.getSessionId);
                    formData.append("type", type);

                    var result = (await ProtocolService.uploadProtocol(formData)).data;

                    if (Object.keys(result).length > 0) {
                        this.files = [];

                        for (var j = 0; j < result.length; j++) {
                            this.sessionProtocol.protocols.push(result[j]);
                        }
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            checkMimeType(type) {
                if (type != "application/pdf" && type != "image/jpeg" && type != "image/jpg" &&
                    type != "image/png" && type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                    type != "text/plain" && type != "application/msword") {
                    return false;
                } else {
                    return true;
                }
            },
            handleCencelProtocolClick() {
                this.tempChangeNameProtocolId = 0;
                this.tempProtocolName = '';
            },
            async handleSaveProtocolClick(item) {
                try {

                    if (this.tempProtocolName.length < 3)
                        return;

                    var data = {
                        id: item.id,
                        name: this.tempProtocolName,
                    };

                    var result = await ProtocolService.updateProtocolName(data, this.getSessionId);

                    if (result.status == 200) {
                        var index = this.sessionProtocol.protocols.findIndex(x => x.id == item.id);
                        if (index != -1) {
                            this.sessionProtocol.protocols[index].name = this.tempProtocolName;
                        }
                    }

                    this.tempProtocolName = '';
                    this.tempChangeNameProtocolId = 0;

                } catch {
                    this.$store.commit("showError");
                } finally {
                    this.$store.commit("hideLoader");
                }
            },
            async handleDownloadProtocolClick(item) {
                try {
                    var result = (await ProtocolService.downloadProtocol(this.getSessionId, item.id));
                    if (result.status == 200) {
                        const contentType = result.headers['content-type'];
                        var a = document.createElement('a');
                        var blob = new Blob([result.data], { 'type': contentType });
                        a.href = window.URL.createObjectURL(blob);
                        a.download = item.name;
                        a.click();
                    }

                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            handleEditProtocolClick(item) {
                this.tempChangeNameProtocolId = item.id;
                this.tempProtocolName = item.name;
            },
            async handleDeleteProtocolClick(item) {
                try {
                    var result = await ProtocolService.deleteProtocol(this.getSessionId, item.id);

                    if (result.status == 200) {
                        var index = this.sessionProtocol.protocols.findIndex(x => x.id == item.id);
                        if (index != -1) {
                            this.sessionProtocol.protocols.splice(index, 1);
                        }
                    }

                } catch (e) {
                    this.$store.commit("showError");
                } finally {
                    this.$store.commit("hideLoader");
                }
            },
            async fetchSessionProtocolData() {
                try {
                    this.$store.commit("showLoader");
                    let result = await ProtocolService.getProtocolsData(this.getSessionId);

                    if (result.status != 200)
                        throw new Error();

                    this.sessionProtocol = result.data;

                }
                catch (e) {
                    this.$store.commit("showError");
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            formatDate(date, format) {
                return moment(date).format(format);
            }
        }
    }
</script>

<style lang="scss" scoped>

    .edit-point-form {
        &__buttons-bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            padding-bottom: 10px;
        }

        &__bottom-button {
            width: 200px;
            height: 56px;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            color: #00082c;
            background: transparent;
            transition: all 0.2s ease-in-out;

            &:hover {
                background: #e8ebef;
            }

            &--red {
                border: 0px;
                background: #d80418;
                color: white;

                &:hover {
                    background: #ad0313 !important;
                }
            }
        }

    }

    .points-list {
        &__attachment-link {
            font-weight: normal;
            font-size: 14px;
            //line-height: 145%;
            color: #333956;
            cursor: pointer;
            margin-left: 11px;

            &:hover {
                text-decoration-line: underline;
                color: #808396;
            }
        }

        &__action-btn {
            width: 32px;
            height: 32px;
            margin-left: 8px;
            background-color: white;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                border: 1px solid hsla(354, 96%, 43%, 0.317);
            }
        }

        &__action-btnatt {
            width: 25px;
            height: 25px;
            margin-left: 8px;
            background-color: white;
            border: none;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                //border: 1px solid hsla(354, 96%, 43%, 0.317);
            }
        }
    }

    .session-metadata-modal {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        overflow: hidden;
        z-index: 9;

        &__wrapper {
            background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
            border-radius: 12px;
            max-height: 100vh;
            padding-bottom: 0px;
            padding-right: 0px;
            width: 40vw;
            overflow: hidden;

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__title {
                    margin-left: 20px;
                }

                &__action {
                    &__exit-btn {
                        border: 0px;
                        background-color: transparent;
                        position: relative;
                        right: calc(-100% + 48px);
                        padding: 15px 0px;
                    }
                }
            }

            &__body {
                max-height: 65vh;
                overflow-y: auto;
                padding: 15px;
                display: flex;
                flex-direction: column;
                row-gap: 30px;

                &::-webkit-scrollbar {
                    width: 12px;
                    height: 6px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #b5b8bd;
                    border-radius: 30px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: #b5b8bd;
                }

                &::-webkit-scrollbar-track {
                    background: #ffffff;
                    border: 0.8px solid #dce4ee;
                    border-radius: 30px;
                    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
                }

                &__metadata {
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;

                    &__label {
                        font-weight: normal;
                    }

                    &__value {
                        font-weight: bolder;
                    }
                }
            }
        }
    }
</style>