<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Edycja adresacji aplikacji kamer</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); $store.commit('setCameraIpSettingsModalStatus', false);}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section class="column">
                    <section class="column-item in-row">
                        Wprowadź adres IP aplikacji kamer:
                    </section>
                    <section >
                        <section class="esesja-modal__row">
                            <input class="esesja-modal__wraper__body__input" placeholder="localhost" v-model="ipToUpdate" />
                        </section>
                    </section>
                </section>
                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="$store.commit('setCameraIpSettingsModalStatus', false);"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="updateAccountSettings" :disabled="ipToUpdate.length < 7 || !validateIPaddress()">Zapisz</button>
                </section>
            </perfect-scrollbar>
        </section>

    </section>
</template>
<script charset="utf-8">

    export default {
        name: "SetCameraAddressModal",
        props: {
            ip: String
        },
        data() {
            return {
               ipToUpdate: this.ip,
            }
        },
        mounted() {
            
        },
        methods: {
            updateAccountSettings() {
                this.$store.commit('setCameraIpSettingsModalStatus', false);
                this.$emit("setIPAddress", this.ipToUpdate);
            },
            validateIPaddress() {

                if (this.ipToUpdate.toLowerCase() == "localhost")
                    return true;

                if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.ipToUpdate))
                    return true
                
                return false
            }  
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/esesja-modal.scss';

    .in-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 24px;
    }

    .in-row .esesja-modal__row {
        margin-bottom: 0px;
        margin-right: 10px;
    }
</style>