<template>
    <section class="esesja-logo">
        <img src="@/assets/imgs/logo.png" />
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "eSesjaLogo"
    }
</script>
<style lang="scss">
    .esesja-logo {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 157px;
            height: 47px;
        }
    }
</style>