<template>
    <section class="search-item" @click="moveToResource">
        <section class="search-item-type"> 
            <img src="../../../../assets/icons/panel/header/posiedzenia.svg" />
        </section>
        <section class="search-item-body">
            {{item.resouceData.name}} {{showDate(item.resouceData.meeting_date)}} {{showPlace(item.resouceData.meeting_place)}}
        </section>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "SearchSessionItem",
        props: {
            item: {
                type: Object,
                require: true,
            }
        },
        methods: {
            showPlace(place) {
                if (place != null && place != undefined && place.length > 0)
                    return "w " + place;
            },
            showDate(date) {
                if (date != null && date != undefined && date.length > 0)
                    return "- Dnia " + this.formatDateToDisplay(date);
            },
            formatDateToDisplay(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'numeric',
                    weekday: 'long',
                    day: 'numeric'
                });

                return d;
            },
            canSession() {
                return this.$store.state.appStore.groups.filter(
                    (group) => group.can_sessions
                );
            },

            getSessionGroupId(sessionId) {
                try {
                    let sessions = this.canSession();
                    let result = sessions.filter(group => {
                        let filteredSessions = group.sessions.filter(session => {
                            return session.id == sessionId;
                        })
                        return filteredSessions.length > 0;
                    })
                    if (result.length === 0) {
                        return -1;
                    }
                    return result[0].id;
                }
                catch (e) {
                    return -1;
                }
            },
            async moveToResource() {
                this.$emit("closeSearch");
                await this.$router.push({ name: "sessionEdit", params: { sessionid: this.item.resouceData.id, groupid: this.getSessionGroupId(this.item.resouceData.id) } });
            }
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/SearchEngineItems.scss';
</style>