<template>
    <button @click="$emit('nextMonth')" class="action-btn">
        <img src="@/assets/icons/components/calendar-chevron-right.svg" class="action-btn--image" :width="width"/>
        <slot></slot>
    </button>
</template>
<script charset="utf-8">
export default {
    name: "ButtonCalendarRight",
    emits: ['nextMonth'],
    props:{
        width:{
            type: Number,
            defalt: 16,
        }
    }
}
</script>
<style lang="scss">
.action-btn {
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}
</style>