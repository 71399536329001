<template>
    <section class="calendar-data">
        <section class="calendar-data__top-wrapper"
                 v-if="meetings.length > 0 || messages.length > 0">
            <p class="calendar-data__title" v-if="isMeetingsChecked">
                Zaplanowane posiedzenia ({{ meetings.length }})
            </p>
            <section v-if="meetings.length > 0 && isMeetingsChecked">
                <router-link :to="{
                        name: 'sessionEdit',
                        params: {
                            sessionid: meeting.id,
                            groupid: meeting.groups[0].id,
                        },
                    }"
                             v-for="(meeting, index) in meetings"
                             :key="index">
                    <section class="calendar-data__box">
                        <img src="../../../../../assets/icons/components/callendar.svg"
                             class="calendar-data__icon" />
                        <section class="calendar-data__info">
                            <p class="calendar-data__name">
                                {{ meeting.name }} 
                                <span v-if="meeting.number"> nr {{ meeting.number !== null ? numberConversion(meeting.number, meeting.groups[0].session_number_format_id) : "0" }}</span>
                            </p>
                        </section>
                    </section>
                </router-link>
            </section>
            <section v-if="isMeetingsChecked && meetings.length === 0">
                <p class="calendar-data__empty">Brak posiedzeń</p>
            </section>
            <p class="calendar-data__title" v-if="isMessagesChecked && checkAnnouncementModule">
                Zaplanowane komunikaty ({{ messages.length }})
            </p>
            <section v-if="messages.length > 0 && isMessagesChecked && checkAnnouncementModule">
                <section v-for="(message, index) in messages"
                         :key="index"
                         class="calendar-data__box" @click="goToAnnouncementPage(message)">
                   
                        <img src="../../../../../assets/icons/components/message.svg"
                             class="calendar-data__icon" />
                        <section class="calendar-data__info">
                            <p class="calendar-data__name">{{ message.name }}</p>
                        </section>
                    
                </section>
            </section>
            <section v-if="isMessagesChecked && messages.length === 0 && checkAnnouncementModule">
                <p class="calendar-data__empty">Brak komunikatów</p>
            </section>
        </section>
        <section class="calendar-data__bottom">
            <section class="calendar-data__btn-wrapper" @click="addAnnouncement" v-if="checkAnnouncementModule">
                <button class="calendar-data__btn">
                    <img src="../../../../../assets/icons/components/plus-small.svg"
                         class="calendar-data__plus-icon" />
                </button>
                <span class="calendar-data__btn-text">Dodaj komunikat</span>
            </section>
            <section class="
                    calendar-data__btn-wrapper calendar-data__btn-wrapper--red
                "
                     @click="addMeeting">
                <button class="calendar-data__btn calendar-data__btn--red">
                    <img src="../../../../../assets/icons/components/plus-small.svg"
                         class="calendar-data__plus-icon" />
                </button>
                <span class="calendar-data__btn-text calendar-data__btn-text--red">Dodaj posiedzenie</span>
            </section>
        </section>

        <Transition>
            <AnnouncementModal v-if="showAnnouncementModal" 
                @closeAnnoucement="closeAnnouncementModal" 
                @updateCalendar="updateCalendarAnno" 
                :datem="datem" 
                :options="annoOptions" 
                :options2="annoOptions2" 
                :selected="selectedEmail" 
                :selected2="selectedEmail" 
                :elemToUpdate="elemToUpdate"/>
        </Transition>
    </section>
</template>

<script>
import AnnouncementModal from "../../Home/Modals/AddAnnouncement.vue";
import ContactService from "../../../../../services/contact.service";
import AlertHelper from '@/helpers/alerts.helper.js';
import {
    switchToRoman,
    switchIndexToLetter,
} from "../../../../../helpers/index.conversion";

export default {
    name: "CalendarMeetings",
    props: {
        meetings: Array,
        messages: Array,
        date: String,
        isMeetingsChecked: Boolean,
        isMessagesChecked: Boolean,
        checkAnnouncementModule: Boolean
    },
    data() {
        return {
            showAnnouncementModal: false,
            annoOptions: [],
            annoOptions2: [],
            selectedEmail: [],
            datem: this.date,
            elemToUpdate: {
                id: 0,
                author: "",
                description: "",
                isInCalendar: false,
                meetingDate: this.datem,
                status: 3,
                title: "",
                isEdit: false,
            }
        }
    },
    components: {
        AnnouncementModal
    },
    methods: {
        numberConversion(index, groupNumeration) {
            switch (groupNumeration) {
                case 1:
                    return `${switchToRoman(index)}`;
                case 2:
                    return `${index}.`;
                case 3:
                    return `${index})`;
                case 4:
                    return `${switchIndexToLetter(index)})`;
                default:
                    return `${index}.`;
            }
        },
        addMeeting() {
            //var z = document.getElementById("js-data-box");
            //z.style.display = "none";
            this.$store.commit("setMeetingDate", this.date);
            this.$store.commit("showAddMeeting");
            this.$emit("close-calendar", true)
        },
        async addAnnouncement() {
            if (this.checkAnnouncementModule) {
                try {
                    var result = (await ContactService.getUsersToSmsOrEmail(3)).data;
                    if (Object.keys(result).length > 0) {
                        this.annoOptions = [];
                        this.annoOptions2 = [];

                        for (var i = 0; i < result.length; i++) {
                            var d = {
                                value: result[i].id,
                                label: result[i].label
                            }
                            this.annoOptions.push(d);

                            for (var j = 0; j < result[i].options.length; j++) {
                                if (this.annoOptions2.find(x => x.value == result[i].options[j].value) == null) {
                                    this.annoOptions2.push(result[i].options[j]);
                                }
                            }
                        }
                        this.showAnnouncementModal = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            } else {
                this.$swal(AlertHelper.errorAlert("Brak wykupionego modułu informator."));
            }
            
        },
        updateCalendarAnno(value) {
            if (value != null) {
                this.$emit("update-message", value);
            }            
        },
        closeAnnouncementModal() {
            this.showAnnouncementModal = false;
        },
        async goToAnnouncementPage(message) {
            await this.$router.push({ name: "announcement", params: { searchId: message.id } });
        }
    },
};
</script>

<style lang="scss" scoped>
.calendar-data {
    position: absolute;
    right: 100%;
    top: 50%;
    z-index: 11;
    background: #ffffff;
    border: 1px solid #dce4ee;
    box-sizing: border-box;
    box-shadow: 16px 32px 48px rgba(0, 8, 44, 0.12);
    border-radius: 12px;
    display: none;
    padding: 2px 12px;

    &__icon {
        width: 16px;
        height: 16px;
    }

    &__title {
        font-weight: 600;
        font-size: 11px;
        line-height: 120%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #00082c;
        margin-bottom: 6px;
        margin: 24px 12px 0px 12px;
    }

    &__box {
        background: #ffffff;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 8px;
        margin-top: 6px;
        padding: 12px;
        display: flex;
        margin: 6px 12px 0px 12px;
        min-width: 388px;
        transition: all 0.2s ease-in-out;

        &:hover {
            background: #f4f6fa;
            border: 1px solid #bbc2ce;
        }
    }

    &__info {
        margin-left: 11px;
    }

    &__name {
        font-weight: 600;
        font-size: 13px;
        line-height: 132%;
        color: #00082c;
    }

    &__number {
        font-weight: normal;
        font-size: 12px;
        line-height: 145%;
        color: #333956;
    }

    &__empty {
        color: gray;
        font-weight: normal;
        margin: 12px;
        font-size: 12px;
    }

    &__bottom {
        margin-top: 12px;
    }

    &__btn-wrapper {
        widows: 100%;
        padding: 12px;
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in-out;
        border-radius: 4px;
        cursor: pointer;
        min-width: 196px;

        &--red {
            margin-bottom: 12px;
            &:hover {
                background: #fdf3f4 !important;
            }
        }

        &:hover {
            background: #f4f6fa;
        }
    }

    &__btn {
        background: #00082c;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        width: 20px;
        height: 20px;

        &--red {
            background: #d80418;
        }
    }

    &__plus-icon {
        width: 12px;
        height: 12px;
    }

    &__btn-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        color: #00082c;
        margin-left: 12px;

        &--red {
            color: #d80418;
        }
    }
}
</style>
