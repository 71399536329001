<template>
    <section class="component" style="position: relative">
        <section class="component__header">
            <span class="component__header__title">
                Plansze
            </span>
            <section class="component__header__action">
                <button class="component__button component__button--small component__button--white" @click="$store.commit('setIsShowAddPlaceholderStatus', true)">
                    <img src="@/assets/icons/components/plus-small.svg" style="filter: invert(1);"/>
                    <span class="component__button__text">Dodaj</span>
                </button>
            </section>
        </section>
        <section class="component__body placeholders">
            <table class="table-bootstrap table-bootstrap-hover table-bootstrap-bordered table-bootstrap-striped align-middle" v-if="isAnyPlaceholders">
                <thead>
                    <tr>
                        <th>Nazwa</th>
                        <th>Data dodania</th>
                        <th style="width: 20px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in availablePlaceholders" :key="index">
                        <td>{{ item.name }}</td>
                        <td>{{ humanDate(item.createdDate) }}</td>
                        <td>
                            <ButtonGroup>
                                <ButtonOutline color="light" type="sm" @click="editPlaceholder(item)">
                                    <ButtonIcon icon="edit" />
                                    Edytuj
                                </ButtonOutline>
                                <ButtonOutline color="danger" type="sm" @click="deletePlaceholder(item)">
                                    <ButtonIcon icon="delete" />
                                    Usuń
                                </ButtonOutline>
                            </ButtonGroup>
                        </td>
                    </tr>
                </tbody>
            </table>
            <section class="text-center" v-else>
                Brak dodanych plansz
            </section>
        </section>
        <SmallLoader v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
    import SmallLoader from "../../../../BasicControls/SmallLoader.vue";
    import PlaceholderService from "@/services/placeholder.service";

    import moment from "moment";

    export default {
        name: "PlaceholderSettings",
        components: {
            SmallLoader,
        },
        data(){
            return {
                isLoading: false,
            }
        },
        computed: {
            availablePlaceholders(){
                return this.$store.state.placeholderStore.placeholders;
            },
            isAnyPlaceholders(){
                let placeholders = this.availablePlaceholders;
                return (placeholders !== undefined && placeholders !== null && placeholders.length > 0)
            }
        },
        async mounted(){
            try{
                this.isLoading = true;
                await PlaceholderService.GetAll();
            }
            catch(e){
                console.log(e);
            }
            finally{
                this.isLoading = false;
            }
        },
        methods:{
            async deletePlaceholder(item){
                try{
                    this.isLoading = true;
                    await PlaceholderService.Delete(item.id);
                }
                finally{
                    this.isLoading = false;
                }
            },
            editPlaceholder(item){
                this.$store.commit('setSelectedPlaceholder', item)
                this.$store.commit('setIsShowAddPlaceholderStatus', true)
            },
            humanDate(date){
                return moment(date).format("DD MMMM YYYY HH:mm");
            }
        }
    }
</script>
<style lang="scss">
    @import "@/assets/scss/user-profile-component.scss";
</style>