<template>
    <div class="btn-group" role="group" :aria-label="label">
        <slot></slot>
    </div>
</template>
<script charset="utf-8">
    export default {
        name: "ButtonGroup",
        props: {
            label: {
                type: String,
                default: ""
            }
        }
    }
</script>