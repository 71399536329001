<template>
    <section class="esesja-search-component-label">
        <span class="esesja-search-component-label__text">{{label}}</span>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "SearchLabelComponent",
        props: {
            label: {
                type: String,
                default: '',
                required: true
            }
        }
    }
</script>
<style lang="scss" scoped>
    .esesja-search-component-label {
        display: flex;
        align-items: start;
        flex-direction: column;

        &__text {
            font-weight: 600;
            font-size: 14px;
            line-height: 128%;
            color: #00082c;
        }
    }
</style>