import ApiService from "./api.service";

class SpeechTimerService extends ApiService {
    startSpeechTimer(value, sessionId){
        let obj = {
            sessionId: sessionId,
            speechTime: value
        }
        return this.post("interactive/discussion/dummy-speech", obj);
    }
    
    endSpeechTimer(sessionId){
        let obj = {
            sessionId: sessionId,
            speechTime: -1
        }

        return this.delete("interactive/discussion/dummy-speech", obj);
    }
}

export default new SpeechTimerService();