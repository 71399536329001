<template>
    <section class="files-uploader">
        <section class="files-uploader__action d-flex flex-row justify-content-end">
            <ButtonOnlyWithIconVue :icon="'exit-btn'"
                                   v-tooltip="'Zamknij'"
                                   :classModified=false
                                   @click="$store.commit('setUploaderModalStauts', false)" />
        </section>
        <section class="files-uploader__summary d-flex flex-row justify-content-between px-2 py-2">
            <span>Przekazano </span>
            <span> {{ successFiles }} / {{ allFiles }}</span>
        </section>
        <perfect-scrollbar class="files-uploader__items d-flex flex-column">
            <TransitionGroup>
                <FileUpload v-for="(item, index) in internalDataFiles" :key="index" :item="item" />
            </TransitionGroup>
        </perfect-scrollbar>
    </section>
</template>
<script charset="utf-8">
    import FileUpload from "./FileUpload.vue";
    import UploaderFileState from "../../../../services/uploader.file.state";
    import ButtonOnlyWithIconVue from "../../../BasicControls/ButtonOnlyWithIcon.vue";
    import { uploaderConfig } from "../../../../config/uploader.file.config";

    export default {
        name: "FilesUploader",
        components: {
            FileUpload,
            ButtonOnlyWithIconVue
        },
        computed:{
            files() {
                let files = Object.entries(this.$store.state.uploaderStore.fileUploadDictionary).map(item => item[1]);
                return files;
            },
            allFiles() {
                return Object.entries(this.files).length;
            },
            successFiles() {
                return Object.entries(this.files)
                    .filter(item => item[1]?.status?.name === UploaderFileState.UploadSuccess.name).length;
            },

            allFilesSuccess() {
                return parseInt(this.allFiles) === parseInt(this.successFiles)
            }
        },
        data() {
            return {
                internalDataFiles: [],
                statusOrder: {},
            }
        },
        mounted() {
            this.statusOrder[UploaderFileState.UploadProgres.name] = 10;
            this.statusOrder[UploaderFileState.UploadFail.name] = 20;
            this.statusOrder[UploaderFileState.UploadWaiting.name] = 30;
            this.statusOrder[UploaderFileState.UploadSuccess.name] = 40;

        },
        watch: {
            allFilesSuccess: {
                handler(status) {
                    if (status === true && uploaderConfig.global.autoCloseOnSuccess === true) {
                        setTimeout(() => {
                            this.$store.commit('setUploaderModalStauts', false);
                        }, uploaderConfig.global.autoCloseOnSuccessTimeout); 
                    }
                }
            },
            files: {
                immediate: true,
                deep: true,
                handler(data) {
                    let arr = Array.from(data);
                    let sorted = arr.sort((a, b) => {
                        return this.statusOrder[a.status.name] - this.statusOrder[b.status.name] 
                    })
                    this.internalDataFiles = sorted;
                }
            }
        }
    }
</script>
<style lang="scss">
    .files-uploader {
        position: fixed;
        z-index: 1000;
        top: 120px;
        right: 30px;
        background: #fff;
        border: 1px solid #bbc2ce;
        width: 300px;

        &__action {

            .button-only-icon {
                border: none !important;
                width: 22px !important;
                height: 22px !important;
                margin-right: 0px !important;

                .button-only-icon__icon {
                    width: 18px !important;
                    height: 18px !important;
                }
            }
        }

        &__items{
            max-height: 60vh;
        }
    }
</style>