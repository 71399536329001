export const speechTimerStore ={
    state: () => ({
        selectedSpeechTime: null,
        currentSpeechTimestamp: null,
        estimatedSpeechEndTimestamp: null,
    }),
    mutations: {
        setSelectedSpeechTime(state, payload){
            state.selectedSpeechTime = payload;
        },
        setCurrentSpeechTimestamp(state, payload){
            state.currentSpeechTimestamp = payload;
        },
        setEstimatedSpeechEndTimestamp(state, payload){
            state.estimatedSpeechEndTimestamp = payload;
        }
    }
}