import ApiService from "./api.service";

class AttachmentsService extends ApiService {
    addFile(id, data) {
        return this.post(`Session/agenda-items/${id}/attachment/file`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    addFiles(id, data) {
        return this.post(`Session/agenda-items/${id}/attachment/files`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    addNote(id, data) {
        return this.post(`Session/agenda-items/${id}/attachment/note`, data);
    }

    addLink(id, data) {
        return this.post(`Session/agenda-items/${id}/attachment/link`, data);
    }

    getNote(id, pid) {
        return this.get(`Session/attachment/${pid}/note/${id}`);
    }

    downloadAttachment(pid, id) {
        return this.get(`Session/attachment-download/${pid}/file/${id}`, { responseType: "arraybuffer" });
    }

    updateFile(id, pid, data) {
        return this.post(`Session/attachment/${pid}/file/${id}`, data);
    }

    updateNote(id, pid, data) {
        return this.post(`Session/attachment/${pid}/note/${id}`, data);
    }

    deleteFile(id, pid) { 
        return this.delete(`Session/attachment/${pid}/file/${id}`);
    }

    deleteNote(id, pid) {
        return this.delete(`Session/attachment/${pid}/note/${id}`);
    }

    deleteAnnouncementFile(id, elemid) {
        return this.delete(`Message/attachment-announcement/${elemid}/file/${id}`);
    }

    updateAnnouncementFile(id, elemid, data) {
        return this.post(`Message/attachment-announcement/${elemid}/file/${id}`, data);
    }

    addAttachementToAnnouncement(data) {
        return this.post(`Message/announcement/attachment/file`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    downloadAnnouncementAttachment(elemId, id) {
        return this.get(`Message/announcement/attachment-download/${elemId}/file/${id}`, { responseType: "arraybuffer" });
    }

    addOrUpdateStatement(pointId, data) {
        return this.post(`Session/addOrUpdateStatement/${pointId}`, data);
    }

    deleteStatementFromAgenda(pointId, id) {
        return this.delete(`Session/deleteStatementFromAgenda/${pointId}/${id}`)
    }
}

export default new AttachmentsService();
