const setMenuElementPosition = (menuItem, subMenu) => {
    if (menuItem !== undefined && subMenu !== undefined) {

        if (Array.isArray(menuItem)) {
            menuItem = menuItem[0];
        }

        if (Array.isArray(subMenu)) {
            subMenu = subMenu[0];
        }
        const menuItemPos = menuItem.getBoundingClientRect();

        let windowHeight = window.innerHeight;

        let proposedStyleTop = menuItemPos.y - 12;
        if (subMenu.children[0] !== undefined && subMenu.children[0] !== null) {
            let childHeight = subMenu.children[0].getBoundingClientRect().height;
            let endOfBox = childHeight + menuItemPos.y;
            if (endOfBox > windowHeight) {
                proposedStyleTop += windowHeight - endOfBox;
            }
        }

        subMenu.style.top = proposedStyleTop + "px";
        subMenu.style.left = menuItemPos.x + Math.round(menuItem.offsetWidth) + "px";
    }
};

export default setMenuElementPosition;
