<template>
    <eSesjaModal class="export-sms">
        <eSesjaModalHeader @close="$store.commit('setExportRegistryModalStatus', false)">
            Export Rejestru
        </eSesjaModalHeader>
        <eSesjaModalBody class="d-flex flex-column gap-3">
            <section class="d-flex flex-grow-1 gap-3">
                <section class="d-flex flex-column flex-grow-1 gap-1">
                    <SearchLabelComponent label="Data wysłania od" />
                    <SearchInputDateComponent v-model="model.minDate" />
                </section>
                <section class="d-flex flex-column flex-grow-1 gap-1">
                    <SearchLabelComponent label="Data wysłania do" />
                    <SearchInputDateComponent v-model="model.maxDate" />
                </section>
            </section>
            <section class="d-flex flex-grow-1 gap-3">
                <section class="d-flex flex-column flex-grow-1 gap-1">
                    <SearchLabelComponent label="Grupa" />
                    <SearchSelectComponent v-model="selectedGroups" :options="availableGroups"
                        placeholder="Wybierz grupe..." style="width: 100%;" />
                </section>
                <section class="d-flex flex-column flex-grow-1 gap-1">
                    <SearchLabelComponent label="Posiedzenie" />
                    <SearchSelectComponent v-model="selectedSession" :options="availableSessions"
                        placeholder="Wybierz posiedzenie..." style="width: 100%;" />
                </section>
            </section>
            <section class="d-flex flex-column gap-1">
                <SearchLabelComponent label="Format pliku wynikowego" />
                <SearchSelectComponent v-model="formatOptions.selected" :options="formatOptions.available"
                    placeholder="Wybierz format..." style="width: 100%;" />
            </section>
        </eSesjaModalBody>
        <eSesjaModalFooter>
            <eSesjaModalButtonCancel @close="$store.commit('setExportRegistryModalStatus', false)">
                Anuluj
            </eSesjaModalButtonCancel>
            <eSesjaModalButtonSave @click="saveData" :disabled="formatOptions.selected.value === null">
                Pobierz
            </eSesjaModalButtonSave>
        </eSesjaModalFooter>
    </eSesjaModal>
</template>
<script charset="utf-8">
// components
import SearchLabelComponent from "@/components/BasicControls/SearchLabelComponent.vue";
import SearchInputDateComponent from "@/components/BasicControls/SearchInputDateComponent.vue";
import SearchSelectComponent from "@/components/BasicControls/SearchSelectComponent.vue";

// services
import RegisterService from "@/services/register.service";
import alertsHelper from "@/helpers/alerts.helper";

// helpers
import { fileDownloadHelper } from "@/helpers/file.download.helper";

export default {
    name: "ExportRegistryModal",
    components: {
        SearchLabelComponent,
        SearchInputDateComponent,
        SearchSelectComponent,
    },
    props:{
        availableGroups:{
            type: Array,
            default: () => []
        },
        availableSessions:{
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            model: {
                minDate: null,
                maxDate: null,
                format: null,
                groupId: null,
                sessionId: null,
            },

            formatOptions: {
                selected: { value: 0, label: "Microsoft Word (.docx)" },
                available: [
                    { value: 0, label: "Microsoft Word (.docx)" },
                    { value: 10, label: "Microsoft Excel (.xlsx)" }
                ]
            },
            selectedGroups: this.availableGroups[0],
            selectedSession: { value: null, label: "Wybierz posiedzenie..."}
        }
    },
    methods: {
        async saveData() {
            try {
                this.$store.commit("showLoader");
                this.model.format = this.formatOptions.selected.value;
                this.model.groupId = this.selectedGroups.value;
                this.model.sessionId = this.selectedSession.value;

                const response = await RegisterService.exportRegistry(this.model);

                if (response.status !== 200) {
                    throw await Promise.reject(response)
                }

                fileDownloadHelper(response);
                this.$store.commit('setExportRegistryModalStatus', false);
                this.$swal(alertsHelper.successAlert("Plik z exportem został pobrany."));
            }
            catch (e) {
                console.log(e);
            }
            finally {
                this.$store.commit("hideLoader");
            }
        }
    }
}
</script>
<style lang="scss">
.export-sms {
    .esesja-search-component-date__input {
        width: 100%;
    }

    .esesja-search-component-select__control {
        width: 100%;
    }
}
</style>