<template>
    <section class="calendar-view" @click="() => $store.state.calendarStore.isShowContenxtMenu = false">
        <CalendarComponent />
    </section>
</template>
<script charset="utf-8">
    import CalendarComponent from "../components/Panel/Content/Calendar/CalendarComponent.vue"
    export default {
        name: "CalendarView",
        components:{
            CalendarComponent
        }
    }
</script>
<style lang="scss">
    .calendar-view{
        padding: 49px 48px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
</style>