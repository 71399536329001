<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Utwórz wiele głosowań</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeAddMultipleVotingsModalAction');}">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <label class="esesja-modal__wraper__body__label">Nazwy głosowań</label>
                <section class="esesja-modal__row">
                    <textarea type="text" rows="5"
                              placeholder="Wpisz nazwy głosowań..."
                              class="esesja-modal__wraper__body__input"
                              v-model="name" />
                </section>
                
                <section class="hint-section">
                    *W polu tekstowym można wprowadzić tytuły glosowań które chcemy utworzyć. Nazwa każdego głosowania powinna rozpoczynać się od nowej linii (Entera). Po wprowadzeniu nazw i zatwierdzeniu przyciskiem "Utwórz", w punkcie powstanie tyle glosowań ile wprowadzono nazw, wszystkie o tych samych parametrach, wprowadzonych w poprzednim oknie.
                </section>

                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="this.$emit('closeAddMultipleVotingsModalAction')">
                        Anuluj
                    </button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="handleSaveClick" :disabled="name.length < 3">
                        Utwórz
                    </button>
                </section>
            </perfect-scrollbar>

        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import VotingService from "../../../../../services/voting.service";

export default {
    name: "AddMultipleVotingModal",
    data() {
        return {
            name: "",
            isLoading: false,
        };
    },
    components: {
        LoaderInside,
        },
    props: {
        globalParametersForMultiple: Object,
    },
    methods: {
        handleSaveClick() {
            this.isLoading = true;
            const data = {
                isMultiple: true,
                name: this.name,
                votingGlobal: this.globalParametersForMultiple,
            };

            VotingService.addMultipleVotings(this.globalParametersForMultiple.pid, data).then(() => {
                this.name = "";
                this.$emit("saveMultipleVotings");
                this.isLoading = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>

    @import "../../../../../assets/scss/esesja-modal.scss";

    .hint-section {
        display: flex;
        margin-bottom: 12px;
        text-align: justify;
        font-size: 12px;
    }

    .tooltip {
        position: relative;
        left: 5px;
        top: -3px;
    }

    .tip-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        background-color: transparent;
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;

        &:hover .hint {
            opacity: 1;
            display: block;
        }
    }
</style>

