<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
                <section>
                    <cropper class="cropper"
                             ref="cropper"
                             :src="imgContent"
                             :canvas="{ minHeight: 200, minWidth: 200, maxHeight: 200, maxWidth: 200 }"
                             :stencil-size="{ width: 200, height: 200 }"
                             :stencil-props="{ handlers: {}, movable: false, resizable: false, aspectRatio: 1 }"
                             :default-size="defaultSize"
                             image-restriction="stencil" />
                </section>
                <section class="information">
                    <p class="info-dialog">Zdjęcie wykadrowane do {{defaultSize.width}}x{{defaultSize.height}} px.</p>
                    <p>W celu powiększenia lub pomniejszenia zdjęcia, najedź myszką na obraz i przekręć kółkiem myszy. Przeciągając zdjęcie myszką możesz też ustawić odpowiedni kadr.</p>
                </section>
                <section style="margin-top: 10px;">
                    <section class="esesja-modal__wraper__body__bottom">
                        <button class="esesja-modal__wraper__body__bottom-button" @click="close()"> Anuluj</button>
                        <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="crop()">Zatwierdź</button>
                    </section>
                </section>    
        </section>
    </section>
</template>

<script>
    import { Cropper } from 'vue-advanced-cropper';
    import 'vue-advanced-cropper/dist/style.css';
    export default {
        components: {
            Cropper
        },
        data: () => ({
            image: {},
            imgToUpdate: '',
            defaultSize: {
                width: 200,
                height: 200
            }
        }),
        props: {
            imgContent: {
                type: String
            },
        },
        methods: {
            crop() {
                const { coordinates, canvas, } = this.$refs.cropper.getResult();
                this.coordinates = coordinates;
                this.image = canvas.toDataURL();
                this.$emit('imguser-update', this.image);
            },
            close() {
                this.$emit('closeImageModal');
            },
        }
    };
</script>

<style lang="scss" scoped>

    .information {
        margin-top: 10px;
        font-size: 12px;
        max-width: 500px;
        word-wrap: break-word;

        .info-dialog {
            font-size: 14px;
            margin-bottom: 10px;
            text-align: center;
        }
    }

    .esesja-modal {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 15;

        &__col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            &-double {
                display: flex;
                flex-direction: column;
            }
        }

        &__row {
            margin-bottom: 24px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;

            &-double {
                display: flex;
                justify-content: space-between;
                margin-bottom: 24px;
                align-items: center;
                column-gap: 12px;
            }
        }

        &__wraper {
            background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
            border-radius: 12px;
            padding: 30px;
            //width: 700px;
            //height: 700px;

            &__header {
                display: flex;
                justify-content: space-between;
                padding-bottom: 10px;

                &__title {
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 128%;
                    color: #00082c;
                    padding: 32px 32px 0px 32px;

                    &--small {
                        font-size: 20px;
                        margin-bottom: 27px;
                    }
                }

                &__exit-btn {
                    border: 0px;
                    background-color: transparent;
                    margin: 0px 10px 0px 0px;
                }
            }

            &__body {
                padding: 0px 32px 32px 32px;
                overflow-y: auto;
                max-height: 70vh;

                &__label {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 145%;
                    color: #00082c;
                }

                &__labelFile {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 145%;
                    color: #00082c;
                }

                &__labelred {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 145%;
                    color: red;
                }

                &__labelcount {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 100%;
                    color: #00082c;
                    text-align: right;
                }

                &__input {
                    padding: 12.5px 16px;
                    background: #ffffff;
                    border: 1px solid #d1d6df;
                    box-sizing: border-box;
                    box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
                    border-radius: 4px;
                    margin-top: 8px;
                    font-size: 16px;
                    line-height: 144%;
                    color: #00082c;

                    &:focus {
                        border: 1px solid #00082c;
                    }
                }

                &__wyswig {
                    padding: 12.5px 16px;
                    background: #ffffff;
                    border: 1px solid #d1d6df;
                    box-sizing: border-box;
                    box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
                    border-radius: 4px;
                    margin-top: 8px;
                    font-size: 16px;
                    line-height: 144%;
                    color: #00082c;
                    height: 170px;
                    max-height: 170px;

                    &:focus {
                        border: 1px solid #00082c;
                    }
                }




                &__picker-wrapper {
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 8px;
                        background: #e2e5eb;
                        width: 1px;
                        height: 40px;
                        right: 56px;
                        z-index: 99;
                    }
                }

                &__bottom {
                    display: flex;
                    justify-content: space-between;
                    column-gap: 12px;
                    clear: both;
                }

                &__bottom-button {
                    padding: 18px 40px 17px;
                    width: 238px;
                    height: 56px;
                    border: 1px solid #bbc2ce;
                    box-sizing: border-box;
                    border-radius: 4px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 132%;
                    color: #00082c;
                    background-color: transparent;
                    background: none;

                    &--red {
                        border: 0px;
                        background: #d80418;
                        color: white;
                    }

                    &--red:disabled {
                        border: 0px;
                        background: #D3D3D3;
                        color: white;
                        cursor: default;
                    }
                }
            }
        }
    }

    .cropper {
        height: 500px;
        width: 500px;
        background: #DDD;
    }
</style>