<template>
    <section class="clock d-flex flex-row gap-2 align-content-center justify-content-center">
        <span class="clock--label fs-3">{{ displayLabel }}</span>
        <img v-if="withIcon" class="clock--icon fs-3" src="@/assets/icons/panel/editSession/meeting-time.svg" alt="Clock" />
    </section>
</template>
<script charset="utf-8">
import { debounce, getLeadZero } from '../../helpers/utils';
export default {
    name: "ClockComponent",
    props: {
        withIcon: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            clockFlag: false,
            displayLabel: "00:00",
        }
    },
    watch: {
        clockFlag: debounce(function () {
            this.getClock();
            this.clockFlag = !this.clockFlag;
        }, 100)
    },
    mounted() {
        this.clockFlag = !this.clockFlag;
    },
    methods: {
        getClock() {
            try {
                const date = new Date();
                this.displayLabel = `${getLeadZero(date.getHours())}:${getLeadZero(date.getMinutes())}`;
            }
            catch {
                this.displayLabel = "00:00"
            }
        }
    }
}
</script>
<style lang="scss">
.clock {
    &--icon {
        filter: brightness(0) invert(1);
        width: 25px;
    }
}
</style>