<template>
    <section class="contacts">
        <h1 class="contacts__title">Repozytorium dokumentów</h1>

        <section class="edit-meeting__row-double">
            <section class="edit-meeting__col-double">
                <label class="edit-meeting__label">Kategorie:</label>
                <section class="meetings-settings__wrapper">
                    <select class="meetings-settings__select" v-model="selected" @change="getDocumentsForCategory()">
                        <option v-for="s in categories" :key="s.id"> {{s.nazwa}}</option>
                    </select>
                </section>
            </section>
        </section>
        <section class="table_section">
            <ButtonWithIcon icon="plus-small" class="download-button" v-if="fromSession != 'true'"
                            text="Dodaj dokument"
                            ref="file"
                            :classModified="true"
                            @click="addNewDocument()" />
            <div>
                <h3>Dokumenty</h3>
                <TableComponent :search="search"
                                :pagination="pagination"
                                :fields="fields"
                                :dataArr="dataArr"
                                :p="page"
                                @loadPagination="showPage"
                                @callAction="buttonActions" />
            </div>
        </section>

        <RenameDocumentModal v-if="showRenameModal" :data="tempEditElem" @closeRename="closeModal" @changeName="rename" />
    </section>
</template>

<script>
    import ButtonWithIcon from "../../../BasicControls/ButtonWithIcon.vue";
    import ToolsService from "../../../../services/tools.service";
    import CommunicationService from "../../../../services/communication.service";
    import TableComponent from '../../../../components/Table/TableComponent.vue'
    import RenameDocumentModal from '../../Content/Tools/Modals/RenameDocumentModal.vue'
    import uploaderService from "../../../../services/uploader.service";
    import { formatBytes } from "../../../../helpers/utils";
    import {
        passErrorMsg,
        isError,
        setErrors,
    } from "../../../../formValidation/main";


    export default {
        name: "doc",
        data() {
            return {
                tempdata: [],
                complex: [],
                dataArr: [],
                lastUserLoginsArr: [],
                pagination: true,
                fields: ['ID', 'Nazwa', 'Autor', 'Czas dodania', 'Rozmiar', '&Otwórz', '&Edytuj', '&Usuń', '&Dodaj'],
                search: true,
                errors: [],
                categories: [],
                selected: 'Ogólne',
                page: 1,
                showRenameModal: false,
                tempEditElem: {},
                files: [],
                fromSession: false,
                sesid: 0,
                pid: 0,
                grid: 0
            };
        },
        methods: {
            formatDateToDisplayWithHour(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    weekday: 'long',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                });

                return d;
            },
            createObj(_raw, _parsed) {
                return {
                    raw: _raw,
                    parsed: _parsed
                };
            },
            async getDocumentsForCategory() {
                try {
                    this.errors = [];
                    this.dataArr = [];

                    this.$store.commit("showLoader");

                    var grId = this.categories.find(x => x.nazwa.replace(/\s/g, '') == this.selected.replace(/\s/g, '')).id;

                    this.complex = (await ToolsService.getDocuments(grId, this.page)).data;

                    if (this.complex != undefined && this.complex != null && this.complex.length > 0) {
                        for (var j = 0; j < this.complex.length; j++) {

                            var temp = {};
                            if (this.fromSession != undefined && this.fromSession == 'true') {
                                if (this.complex[j].legislator == false) {
                                    temp = {
                                        ID: this.createObj(this.complex[j].id, this.complex[j].id),
                                        Nazwa: this.createObj(this.complex[j].name, this.complex[j].name),
                                        Autor: this.createObj(this.complex[j].author, this.complex[j].author),
                                        'Czas dodania': this.createObj(this.complex[j].time, this.formatDateToDisplayWithHour(this.complex[j].time)),
                                        Rozmiar: this.createObj(parseInt(this.complex[j].size) * 1000, formatBytes(parseInt(this.complex[j].size) * 1000)),
                                        '&Dodaj': 'action',
                                        mark: this.createObj(this.complex[j].legislator, this.complex[j].legislator),
                                        grId: this.createObj(grId, grId),
                                    };
                                }
                            } else {
                                temp = {
                                    ID: this.createObj(this.complex[j].id, this.complex[j].id),
                                    Nazwa: this.createObj(this.complex[j].legislator ? 'Legislator - ' + this.complex[j].name : this.complex[j].name, this.complex[j].legislator ? 'Legislator - ' + this.complex[j].name : this.complex[j].name),
                                    Autor: this.createObj(this.complex[j].author, this.complex[j].author),
                                    'Czas dodania': this.createObj(this.complex[j].time, this.formatDateToDisplayWithHour(this.complex[j].time)),
                                    Rozmiar: this.createObj(parseInt(this.complex[j].size) * 1000, formatBytes(parseInt(this.complex[j].size) * 1000)),
                                    '&Otwórz': 'action',
                                    '&Edytuj': 'action',
                                    '&Usuń': 'action',
                                    mark: this.createObj(this.complex[j].legislator, this.complex[j].legislator),
                                    grId: this.createObj(grId, grId),
                                };
                            }

                            if (Object.keys(temp).length > 0) {
                                this.dataArr.push({...temp});
                            }

                            console.log(this.dataArr)
                        }
                    }

                    this.$store.commit("hideLoader");
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            closeModal() {
                this.showRenameModal = false;
                this.tempEditElem = {};
            },
            async showPage(page) {
                this.page = page;
                await this.getDocumentsForCategory();
            },
            rename(item) {
                var index = this.dataArr.findIndex(x => x.ID.raw == item.ID);
                if (index != -1) {
                    this.dataArr[index].Nazwa = this.createObj(item.name, item.name);
                }
            },
            async buttonActions(item, action) {
                try {
                    if (action == 'Otwórz') {
                        try {
                            var result = (await ToolsService.downloadAttachment(item.ID.raw));
                            if (result.status == 200) {
                                const contentType = result.headers['content-type'];
                                var a = document.createElement('a');
                                var blob = new Blob([result.data], { 'type': contentType });
                                a.href = window.URL.createObjectURL(blob);
                                a.download = name;
                                a.click();
                            }

                        } catch (e) {
                            if (e.response.status == 401)
                                this.$router.push({ name: 'Login' })
                        }
                    }

                    if (action == 'Dodaj') {
                        var res = this.$route.query.resolution;
                        const actionToFire = () => {
                            var t = {
                                id: item.ID.raw,
                                grId: item.grId.raw,
                                resolution: res === 'true',
                                pid: parseInt(this.pid),
                                sessionId: parseInt(this.sesid)
                            };

                            ToolsService.addSessionAttachementFromRepository(t).then((response) => {
                                if (response.status == 200) {
                                    this.$router.push({ name: 'sessionEdit', params: { sessionid: this.sesid, groupid: this.grid } });
                                }
                            });
                        };

                        this.$store.commit("showConfirmationAlert", actionToFire);
                    }

                    if (action == 'Edytuj') {
                        this.tempEditElem = {
                            ID: item.ID.raw,
                            grId: item.grId.raw,
                            name: item.Nazwa.parsed
                        };

                        this.showRenameModal = true;
                    }

                    if (action == 'Usuń') {
                        const actionToFire = () => {
                            var t = {
                                id: item.ID.raw,
                                grId: item.grId.raw,
                            };

                            ToolsService.deleteDocument(t).then((response) => {
                                if (response.status == 200) {
                                    var index = this.dataArr.findIndex(x => x.ID.raw == item.ID.raw);
                                    if (index != -1) {
                                        var temp = [...this.dataArr];
                                        temp.splice(index, 1)
                                        this.dataArr = [];
                                        for (var i = 0; i < temp.length; i++) {
                                            this.dataArr.push({...temp[i]})
                                        }
                                    }
                                }
                            });
                        };

                        this.$store.commit("showConfirmationAlert", actionToFire);
                    }

                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async addNewDocument() {
                try {
                    this.$store.commit('showLoader');

                    let input = document.createElement('input');
                    input.type = 'file';
                    input.accept = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.png,.jpg,.jpeg,.zipx,.xml,.zip';
                    input.multiple = true;
                    input.click();
                    input.addEventListener('change', async (event) => {
                        try {
                            this.$store.commit('showLoader');

                            let grId = this.categories.find(x => x.nazwa.replace(/\s/g, '') == this.selected.replace(/\s/g, '')).id;

                            await uploaderService.UploadDocument(grId, event);

                            await this.internalMounted();
                        }
                        catch (e) {
                            if (e.response.status == 401)
                                this.$router.push({ name: 'Login' })
                        }
                        finally {
                            this.$store.commit('hideLoader');
                        }
                    });

                }
                finally {
                    this.$store.commit('hideLoader');
                }

            },
            async internalMounted() {
                this.sesid = this.$route.query.sesId
                this.grid = this.$route.query.grId
                this.pid = this.$route.query.pId
                this.fromSession = this.$route.query.ses
                var res = (await CommunicationService.getGroups()).data;

                if (res != null && res != undefined && res.length > 0) {
                    this.categories = res.filter(x => x.can_session == true);
                }
                else {
                    this.categories = [];
                }

                var d = {
                    nazwa: 'Ogólne',
                    id: 0
                }
                this.categories.push(d);
                this.getDocumentsForCategory()

            },
            passErrorMsg,
            isError,
            setErrors,
        },
        components: {
            TableComponent,
            ButtonWithIcon,
            RenameDocumentModal
        },
        async mounted() {
            try {
                this.$store.commit("showLoader");
                await this.internalMounted();

            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
            finally {
                this.$store.commit("hideLoader");
            }
        },
    };
</script>

<style lang="scss" scoped>
    .generate-button {
        margin-top: 30px;
        margin-left: auto;
    }

    .download-button {
        margin-left: auto;
        margin-right: 0;
        float: right;
    }

    .table_section {
        background-color: white;
        border-radius: 10px;
        padding: 10px;
        margin-top: 20px;

        h3 {
            padding: 10px;
        }

        p {
            padding: 10px;
        }
    }

    .chart_section {
        background-color: white;
        border-radius: 10px;
        max-height: 500px;
        padding: 10px;
    }

    .meetings-settings {
        margin-top: 20px;

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        &__wrapper {
            position: relative;
            /* &::after {
                content: "";
                position: absolute;
                top: 8px;
                background: #e2e5eb;
                width: 1px;
                height: 24px;
                right: 40px;
                //z-index: 99;
            }*/
        }

        &__select {
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            padding: 8px 10px 8px 16px;
            font-weight: normal;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url("../../../../assets/icons/components/select-arrow.svg");
            background-repeat: no-repeat;
            background-position-x: calc(100% - 14px);
            background-position-y: 50%;
            min-width: 300px;
            min-height: 56px;

            &:focus {
                outline: none;
            }
        }
    }

    .contacts {
        margin: 49px 48px;

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 20px;
        }

        &__add-user-btn {
            position: fixed;
            bottom: 40px;
            right: 48px;
            width: 56px;
            height: 56px;
            border: 0px;
            background: #d80418;
            box-shadow: 8px 8px 28px rgba(173, 3, 19, 0.16);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.2 ease-in-out;

            &:hover {
                background: #ad0313 !important;
            }
        }

        &__add-user-btn-icon {
            width: 25px;
            height: 24px;
        }
    }

    .contact-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        &__input {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            width: 326px;
            box-sizing: border-box;
            padding: 12.5px 16px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__button-search {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left: 0px;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 32px;
                background: #e2e5eb;
                top: 8px;
                left: 0px;
            }
        }

        &__form {
            display: flex;
            align-items: center;
        }

        &__search-icon {
            padding: 14px;
        }

        &__action-buttons {
            display: flex;
        }
    }

    .contact-list-no-result {
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .edit-meeting {
        &__row-double {
            display: flex;
            //justify-content: space-between;
            margin-bottom: 24px;
        }

        &__col-double {
            display: flex;
            flex-direction: column;
            margin-right: 15px;
        }

        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-bottom: 8px;
        }

        &__picker {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            min-width: 238px;
            height: 56px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;

            &[type="date"]::-webkit-calendar-picker-indicator {
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url("../../../../assets/icons/components/callendar.svg") no-repeat;
                background-position-x: 100%;
                width: 22px;
                height: 22px;
                border-width: thin;
            }

            &[type="time"]::-webkit-calendar-picker-indicator {
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url("../../../../assets/icons/components/clock.svg") no-repeat;
                width: 22px;
                height: 22px;
                border-width: thin;
            }
        }

        &__picker-wrapper {
            position: relative;
            /* &::after {
                content: "";
                position: absolute;
                top: 8px;
                background: #e2e5eb;
                width: 1px;
                height: 40px;
                right: 56px;
                z-index: 99;
            }*/
        }
    }
</style>
