<template>
    <section class="search-filter-wrapper">
        <section class="search-filter-wrapper__item">
            <section class="search-filter-wrapper__item__text">
                {{prepend}} {{value}}
            </section>
            <section class="search-filter-wrapper__item__action" @click="$emit('remove', identifier)">
                <img src="../../assets/icons/components/exit-btn.svg" class="search-filter-wrapper__item__action__remove-icon" />
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "SearchFilterItem",
        emits: ['remove'],
        props: {
            prepend: {
                type: String,
                default: "",
                required: false
            },
            value: {
                type: String,
                required: true
            },
            identifier: {
                required: false,
                default: "",
            }
        }
    }
</script>
<style lang="scss" scoped>
    .search-filter-wrapper {
        background: linear-gradient(139.17deg, #00082c 0%, #004dcc 148.71%);
        color: #c6ccd6;
        border-radius: 10px;

        &__item {
            display: flex;
            flex-direction: row;
            column-gap: 5px;
            align-items: center;
            transition: all 0.3s ease-in-out;


            &__text {
                padding: 10px 0px 10px 10px;
            }

            &__action {
                padding: 10px 10px 10px 0px;
                cursor: pointer;
                display: flex;
                align-items: center;

                &__remove-icon {
                    filter: brightness(0) invert(0.8);
                    height: 15px;
                }
            }
        }



        &:hover .search-filter-wrapper__item {
            background: rgba(255, 255, 255, 0.2);
            color: #ffffff;
        }

        &:hover .search-filter-wrapper__item__action__remove-icon {
            filter: brightness(0) invert(1);
        }
    }
</style>