<template>
    <section class="loader">
        <section :class="{'loader__tiny_spin' : isTiny, 'loader__spin': !isTiny}"></section>
    </section>
</template>

<script charset="utf-8">
    export default {
        name: "LoaderInside",
        props: {
            isTiny: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style lang="scss" scoped>
    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        top: 0px;
        left: 0px;
        position: absolute;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.482);
        z-index: 5;

        &__spin {
            border: 8px solid #f3f3f3;
            border-top: 8px solid #004dcc;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            animation: spin 2s linear infinite;
        }

        &__tiny_spin {
            border: 4px solid #f3f3f3;
            border-top: 4px solid #004dcc;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            animation: spin 2s linear infinite;
        }
    }

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
