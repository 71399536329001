<template>
    <section class="add-new-guest-modal-overlay">
        <section class="add-new-guest-modal-container">
            <header class="add-new-guest-modal-container__header">
                <section class="add-new-guest-modal-container__header__menu">
                    <a class="menu-item" @click="addTemporaryUser">
                        <img src="../../../../assets/icons/components/save.svg" class="menu-icon" />
                        <span class="menu-text">Dodaj gościa</span>
                    </a>
                </section>
                <section class="add-new-guest-modal-container__header__action">
                    <button class="add-new-guest-modal-container__header__action__exit-btn"
                            @click="(e)=> { e.preventDefault();this.$store.commit('hideAddNewGuestModal');}">
                        <img src="../../../../assets/icons/components/exit-btn.svg" />
                    </button>
                </section>
            </header>
            <section class="add-new-guest-modal-container__title">
                <span class="modal-title">
                    Dodawanie nowego gościa
                </span>
            </section>
            <section class="add-new-guest-modal-container__body">
                <div>
                    <label for="guest-username" class="form-bootstrap-label">Imię oraz nazwisko</label>
                    <input type="text" class="form-bootstrap-control" id="guest-username" v-model="guestData.username" />
                </div>
                <div>
                    <label for="guest-role" class="form-bootstrap-label">Funkcja gościa (opcjonalnie)</label>
                    <input type="text" class="form-bootstrap-control" id="guest-role" v-model="guestData.role" />
                </div>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">

    import eSesjaInteractiveService from "../../../../services/esesja.interactive.service";

    export default {
        name: "AddNewGuestModal",
        components: {},
        props: {},
        data() {
            return {
                guestData: {
                    username: "",
                    role: "",
                }
            }
        },
        mounted() {
        },
        methods: {

            async addTemporaryUser() {
                try {
                    this.$store.commit("showLoader");
                    await eSesjaInteractiveService.addNewGuest(this.guestData.username, this.guestData.role)
                    this.$store.commit('hideAddNewGuestModal')
                    this.$store.commit("showSuccessCustomMessage", `Pomyślnie dodano gościa <b>${this.guestData.username}</b>`);
                }
                catch {
                    this.$store.commit("showError");
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .add-new-guest-modal-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        overflow: hidden;
        z-index: 9;

        .add-new-guest-modal-container {
            background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
            border-radius: 12px;
            max-height: 100vh;
            padding-bottom: 0px;
            padding-right: 0px;
            min-width: 50vh;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            row-gap: 15px;

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__menu {

                    .menu-item {
                        display: flex;
                        column-gap: 10px;
                        align-items: center;
                        float: left;
                        font-size: 16px;
                        color: #000;
                        text-align: center;
                        padding: 19px 10px 19px 10px;
                        text-decoration: none;
                        cursor: pointer;
                        transition: all 0.2s ease-in-out;

                        .menu-icon {
                            height: 20px;
                        }

                        .menu-text {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 132%;
                        }

                        &:hover {
                            background-color: #e8ebef;
                        }
                    }
                }


                &__action {
                    &__exit-btn {
                        border: 0px;
                        background-color: transparent;
                        position: relative;
                        right: calc(-100% + 48px);
                    }
                }
            }

            &__title {
                display: flex;
                justify-content: center;
                font-size: 30px;
            }

            &__body {
                max-height: 65vh;
                overflow-y: auto;
                padding: 0px 50px 20px 25px;
                display: flex;
                flex-direction:column;
                row-gap:15px;
            }
        }
    }
</style>