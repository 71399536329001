<template>
    <section class="break-board-overlay">
        <section class="break-board-container">
            <button @click="stopBrake()">
                <img src="../../../assets/icons/components/exit-btn.svg" />
            </button>
            <section class="break-board-container__header">
                Przerwa
            </section>
            <section class="break-board-container__timer">
                <span class="overtime" v-if="isOvertime">+</span>
                <span class="timer">{{displayTime}}</span>
            </section>
            <!--<section class="break-board-container__logo">
                logo
            </section>-->
        </section>

    </section>
</template>
<script charset="utf-8">
    import eSesjaInteractiveService from "../../../services/esesja.interactive.service";
    import { debounce, getLeadZero, timeUnitsBetween } from '../../../helpers/utils';

    export default {
        name: "BreakBoard",
        computed: {
            breakeDuration() {
                return parseInt(this.$store.state.interactiveStore.breakDuration) * 60;
            },
            breakTimestamp(){
                return this.$store.state.interactiveStore.breakTimestamp;
            }
        },
        data() {
            return {
                breakTimer: null,
                isOvertime: false,
                debounceFlag: false,
                displayTime: "00:00:00",
                estimateEndTime: null,
            }
        },
        watch: {
            debounceFlag: debounce(function(){
                const currentTime = new Date();
                this.isOvertime = (this.estimateEndTime - currentTime) < 0;
                let obj = timeUnitsBetween(currentTime, this.estimateEndTime);
                this.displayTime = `${getLeadZero(obj.hours + 24*obj.days)}:${getLeadZero(obj.minutes)}:${getLeadZero(obj.seconds)}`;
                this.debounceFlag = !this.debounceFlag;
            }, 100),
            breakeDuration: {
                immediate: true,
                deep: true,
                handler(val) {
                    this.isOvertime = false;
                    this.debounceFlag = !this.debounceFlag;
                    this.estimateEndTime = new Date(this.breakTimestamp);
                    this.estimateEndTime = this.estimateEndTime.setSeconds(this.estimateEndTime.getSeconds() + val);
                    //this.breakTimer = useTimer(this.getTimeToCutdownTimer(val));
                }
            },
        },
        methods: {
            async stopBrake() {
                await eSesjaInteractiveService.stopBreak(0, this.$store.state.appStore.sessionId);
                this.$emit('stopBreak');
            }
        }
    }
</script>
<style lang="scss" scoped>
    .break-board-overlay {
        position: fixed;
        z-index: 99999999;
        bottom: 0;
        left: 0;
        min-height: 30vh;
        min-width: 17vw;
        background: #4c4c4c;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        .break-board-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 30px;

            &__header {
                font-size: 20px;
            }

            &__timer {
                font-size: 30px;
            }
        }
    }
</style>