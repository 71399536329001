<template>
    <section>
        <Pie :chart-options="chartOptions"
             :chart-data="chartData"
             :chart-id="chartId"
             :dataset-id-key="datasetIdKey"
             :plugins="plugins"
             :css-classes="cssClasses"
             :styles="styles"
             :width="width"
             :options="options"
             :height="height" />
    </section>
</template>

<script>
    import { Pie } from 'vue-chartjs'
    import { Chart as ChartJS, Title, Tooltip, ArcElement } from 'chart.js'

    ChartJS.register(Title, Tooltip, ArcElement)

    export default {
        name: 'PieChart',
        components: { Pie },
        props: {
            chartId: {
                type: String,
                default: 'chart'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 450
            },
            height: {
                type: Number,
                default: 450
            },
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => { }
            },
            plugins: {
                type: Object,
                default: () => {}
            },
            options: {
                type: Object,
                default: () => {}
            },
            chartData: {
                type: Object,
                default: () => { }
            },
            type: Number,
        },
        data() {
            return {
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                },
            }
        }
    }


</script>

<style></style>