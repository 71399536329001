<template>
    <section>
        <section class="contact-top" v-if="search">
            <section class="contact-top__search">
                <SearchInputTextComponent v-model="searchBar" :placeholder="'Wyszukaj...'" />
            </section>
        </section>
        <section>
            <table id="tableComponent" class="table table-bordered table-striped" v-if="checkFiltered.length > 0">
                <thead>
                    <tr>
                        <th v-for="field in fields" :key='field' @click="sortByColumn(field)" class="sort_column">
                            {{field.includes('&') == false ? field : ''}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in checkFiltered" :key='item' :id="item._internalGUID" :class="[ item.mark !== undefined && item.mark.raw !== undefined && item.mark.raw !== null && item.mark.raw ? 'mark_row' : '']">
                        <td v-for="(field, index) in fields" :key='index' :id="item._internalGUID +'_'+index">
                            {{item[field] !== 'action' ? item[field]?.parsed ?? '' : createButtonClick(item._internalGUID, item, index)}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <section v-else><section style="padding: 10px;">Brak danych.</section></section>
            <section v-if="pagination && checkFiltered.length > 0" class="pagination">
                <button class="defoult_button_pagination" @click="previousPage()" :disabled="page <= 1"> &laquo;</button>
                <button class="defoult_button_pagination" @click="nextPage()" :disabled="arr.length < 10">&raquo; </button>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    import SearchInputTextComponent from "../BasicControls/SearchInputTextComponent.vue";
    import { generateUUID } from "../../helpers/utils";

    export default {
        name: 'TableComponent',
        data() {
            return {
                arr: [...this.dataArr],              
                searchBar: '',
                sortDirection: [],
                page: this.p,
                preButton: 0
            }
        },
        components: {
            SearchInputTextComponent
        },
        computed: {
            checkFiltered() {
                return this.arr
                .filter(item => {
                    var elem = '';
                        
                    for (const it of Object.entries(item)) {
                        if (it != 'action') {
                            elem += `${it[1]?.parsed} `;
                        }
                    }
                    return elem.toLowerCase().includes(this.searchBar.toLowerCase());
                })
                .map(item => {
                    let itemCopy = {...item}
                    itemCopy._internalGUID =  generateUUID();
                    return itemCopy;
                })
            }
        },
        watch: {
            dataArr: function () {
                this.arr = this.dataArr;
            }
        },
        props: {
            dataArr: {type: Array},
            fields: { type: Array },
            pagination: { type: Boolean },
            search: { type: Boolean },
            p: { type: Number }
        },
        methods: {
            createButtonClick(id, item, index) {
                var thisVue = this;
                window.setTimeout(() => {
                    var b = document.getElementById(id + '_' + index);
                    if (b != null && b.firstChild == null) {
                        var button = document.createElement("BUTTON");
                        var act = Object.keys(item).filter(key => item[key] === 'action');
                        var actionsLength = act.length;

                        var actName = '';
                        if (actionsLength > 1) {
                            for (var i = 1; i < actionsLength; i++) {

                                if (this.preButton == actionsLength) {
                                    this.preButton = 1;
                                }
                                else {
                                    this.preButton = this.preButton+i;
                                }

                                if (this.preButton == 1) {
                                    actName = Object.keys(item).find(key => item[key] === 'action').toString().slice(1);
                                    break;
                                }
                                else {
                                    actName = act[this.preButton-1].slice(1);
                                    break;
                                }
                            }
                        }
                        else {
                            actName = Object.keys(item).find(key => item[key] === 'action').toString().slice(1);
                        }
                        button.innerHTML = actName;
                        button.onclick = function () { thisVue.madeAction(item, actName) };
                        button.id = id + '_' + index + '_btn';
                        button.style.background = '#ffffff';
                        button.style.border = '1px solid #bbc2ce';
                        button.style.boxSizing = 'border-box';
                        button.style.borderRadius = '4px';
                        button.style.marginRight = '2px';
                        button.style.transition = 'all 0.2s ease-in-out';
                        button.style.padding = '5px'
                        button.onmouseover = function () {
                            button.style.backgroundColor = '#e8ebef';
                        }

                        button.onmouseout = function () {
                            button.style.backgroundColor = '#ffffff';
                        }

                        b.appendChild(button);
                    }
                }, 200);
            },
            madeAction(item, actName) {
                this.$emit('callAction', item, actName);
            },
            sortByColumn(index) {
                if (this.arr.length > 0) {
                    if (!this.sortDirection.includes(index)) {
                        this.arr.sort(function (a, b) {
                            let textA = a[index].raw;
                            if (typeof textA === 'string')
                                textA = textA.trim().toUpperCase();

                            let textB = b[index].raw;
                            if (typeof textB === 'string')
                                textB = textB.trim().toUpperCase();

                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                        this.sortDirection.push(index);
                    }
                    else {
                        this.arr.sort(function (a, b) {
                            let textA = a[index].raw;
                            if (typeof textA === 'string')
                                textA = textA.trim().toUpperCase();

                            let textB = b[index].raw;
                            if (typeof textB === 'string')
                                textB = textB.trim().toUpperCase();
                            return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
                        });
                        var ine = this.sortDirection.indexOf(index);
                        if (ine > -1)
                            this.sortDirection.splice(ine, 1);
                    }
                }
            },
            async nextPage() {
                this.page += 1;
                this.$emit('loadPagination', this.page);
            },
            async previousPage() {
                if (this.page > 1) {
                    this.page -= 1;
                    this.$emit('loadPagination', this.page);
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
    .mark_row {
        background-color: #2b9bb3 !important;
    }

    .pagination {
        display: flex;
        align-items: center;
        justify-content: right;
        margin: 5px;
    }

    .defoult_button_pagination {
        background: #ffffff;
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        transition: background-color .3s;
        //border: 1px solid #ddd;
        border: none;
        font-size: 22px;
        margin: 5px;
    }

    .defoult_button_pagination:disabled {
        cursor: default;
        background-color: #F8F8F8;
        color: #ddd;
    }

    .defoult_button_pagination:hover:enabled:not(.active) {
        background-color: #ddd;
    }

        .sort_column {
            cursor: pointer;
        }

        #tableComponent {
            //font-family: Arial, Helvetica, sans-serif;
            border-collapse: collapse;
            width: 100%;
        }

        #tableComponent td, #tableComponent th {
            //border: 1px solid #ddd;
            padding: 8px;
        }

        #tableComponent tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        #tableComponent tr:hover {
            background-color: #ddd;
        }

    #tableComponent th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #33599e;
        color: white;
    }

        .contact-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            margin-top: 20px;

            &__input {
                height: 48px;
                background: #ffffff;
                border: 1px solid #d1d6df;
                box-sizing: border-box;
                box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
                border-radius: 4px;
                width: 100%;
                box-sizing: border-box;
                padding: 12.5px 16px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-right: 0px;

                &:focus {
                    border: 1px solid #00082c;
                }
            }

            &__button-search {
                height: 48px;
                background: #ffffff;
                border: 1px solid #d1d6df;
                box-sizing: border-box;
                box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-left: 0px;
                position: relative;
                cursor: pointer;

                &::after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 32px;
                    background: #e2e5eb;
                    top: 8px;
                    left: 0px;
                }
            }

            &__form {
                display: flex;
                align-items: center;
            }

            &__search-icon {
                padding: 14px;
            }

            &__action-buttons {
                display: flex;
            }
        }
</style>
