<template>
    <section class="component">
        <section class="component__header">
            <span class="component__header__title">
                Avatar
            </span>
            <section class="component__header__action">
                <button class="component__button
                               component__button--small
                               component__button--white"
                        @click="selectAvatar">
                    <img src="@/assets/icons/components/edit.svg" />
                    <span class="component__button__text">Zmień</span>
                </button>
            </section>
        </section>
        <section class="component__body avatar">
            <section class="avatar__picture">
                <img :src="loadAvatar" :alt="fullName" />
            </section>
        </section>
        <ImageDialog v-if="showAvatarCropModal" 
                     :imgContent="rawAvatar" 
                     @imguser-update="updateAvatar" 
                     @closeImageModal="closeAvatarModal"/>
    </section>
</template>
<script charset="utf-8">
    import ImageDialog from "@/components/Panel/Content/Contacts/Modals/AddContact/ImageModal.vue";
    import ContactService from "@/services/contact.service";
    import CommonHelper from '@/helpers/common.helper.js';
    export default {
        name: "Avatar",
        components: {
            ImageDialog
        },
        data() {
            return {
                rawAvatar: '',
                showAvatarCropModal: false,
            }
        },
        computed: {
            loadAvatar() {
                return this.$store.state.userStore.userData.avatar;
            },

            fullName() {
                return `${this.$store.state.userStore.userData.first_name} ${this.$store.state.userStore.userData.last_name}`;
            },

            userLid() {
                const avatar = this.$store.state.userStore.userData.avatar;
                const regex = /\/(\d+)\.jpg/;
                const match = avatar.match(regex);
                if (match && match.length > 1) {
                    return parseInt(match[1])
                }
                else {
                    return -1;
                }

            }
        },
        methods: {
            async updateAvatar(cropedImage) {
                try {
                    this.$store.commit("showLoader");
                    let user = this.userLid;
                    if (user === -1) {
                        alert("Wystąpił problem")
                        return;
                    }

                    let request = {
                        image: cropedImage,
                        userId: user
                    }
                    let response = await ContactService.prepareImageToSend(request);
                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }

                    if (response.data === true) {
                        let newAvatar = "https://ssl.esesja.pl/users/" + user + ".jpg" + CommonHelper.getRandomVersionImageForUrlRefresh(0, 100);
                        this.$store.commit("updateAvatar", newAvatar);
                    }

                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.showAvatarCropModal = false;
                    this.$store.commit("hideLoader");
                }
            },
            selectAvatar() {
                let input = document.createElement('input');
                input.type = 'file';
                input.accept = '.jpg,.jpeg';
                input.multiple = false;
                input.click();
                input.addEventListener('change', event => {
                    if (event.target.files.length == 0)
                        return;

                    let proposedFile = event.target.files[0];
                    if (proposedFile.size > 10000000) {
                        alert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 10MB");
                        return;
                    }

                    if (!this.checkMimeType(proposedFile.type)) {
                        alert("Nieobsługiwany typ pliku. Pliki obsługiwane to: JPG,JPEG")
                        return;
                    }

                    let reader = new FileReader();
                    reader.readAsDataURL(proposedFile);
                    reader.onload = e => {
                        this.rawAvatar = e.target.result;
                        this.showAvatarCropModal = true;
                    }
                });
            },
            checkMimeType(type) {
                if (type != "image/jpeg" && type != "image/jpg" /*&& type != "image/png"*/) {
                    return false;
                } else {
                    return true;
                }
            },
            closeAvatarModal() {
                this.rawAvatar = '';
                this.showAvatarCropModal = false;
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/user-profile-component.scss";
    .component {
        width: 350px;
        height: fit-content;
    }
    .avatar {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 15px 20px;
        align-items: center;
        justify-content: center;

        &__picture {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__actions {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
</style>