export const uploaderStore = {
    state: () => ({
        fileUploadDictionary: {},
        uploaderModalStauts: false,
    }),
    mutations: {
        setUploaderFileDictionary(state, data) {
            state.fileUploadDictionary = data;
        },
        setUploaderModalStauts(state, status) {
            state.uploaderModalStauts = status;
        }
    }
}