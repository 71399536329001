<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Wyeksportuj wynik głosowania</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeExportVotingToOtherSessionModal');}">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <label class="esesja-modal__wraper__body__label">Wybrane głosowanie do wyeksportowania do innego posiedzenia:</label>
                <section class="esesja-modal__row" style="padding: 15px; font-weight: 600">
                    <p>{{voting.name}}</p>
                </section>

                <label class="esesja-modal__wraper__body__label">Wybierz posiedzenie:</label>
                <section class="esesja-modal__row">
                    <select class="esesja-modal__wraper__body__input" v-model="selectedSession" @change="checkSelectedSession($event)">
                        <option v-for="s in sessionsToSelect" :value="s.id" :key="s.id"> {{showSessionName(s)}} {{showDate(s.meeting_date)}}</option>
                    </select>
                </section>

                <label class="esesja-modal__wraper__body__label" v-if="sSInfoForDisplay != null">Wybierz punkt:</label>
                <section class="esesja-modal__row" v-if="sSInfoForDisplay != null">
                    <select class="esesja-modal__wraper__body__input" v-model="selectedPoint" @change="checkSelectedPoint($event)">
                        <option v-for="s in pointsToSelect" :value="s.id" :key="s.id"> {{s.displayName}}</option>
                    </select>
                </section>

                <label class="esesja-modal__wraper__body__label">Uzupełnij nazwę wyniku głosowania:</label>
                <section class="esesja-modal__row">
                    <input type="text"
                           placeholder="Wpisz nazwę..."
                           class="esesja-modal__wraper__body__input"
                           v-model="name" />
                </section>

                <label class="esesja-modal__wraper__body__label" v-if="selectedSession > 0 && sSInfoForDisplay != null">Wybrane posiedzenie:</label>
                <section class="esesja-modal__row" v-if="selectedSession > 0 && sSInfoForDisplay != null">
                    <section class="preview">
                        <p class="meetings-options__number-format-presentation">
                            <span class="meetings-options__number-format-presentation--blue">{{showSessionName(sSInfoForDisplay)}} {{showDate(sSInfoForDisplay.meeting_date)}}</span>
                        </p>
                    </section>
                </section>

                <label class="esesja-modal__wraper__body__label"  v-if="selectedPoint > 0 && p1.length > 0">Wybrany punkt:</label>
                <section class="esesja-modal__row" v-if="selectedPoint > 0 && p1.length > 0">
                    <section class="preview">
                        <section class="preview__row" v-if="p1.length > 0">
                            <section class="preview__left">
                                <span class="preview__text preview__text--blue">{{n1}} </span>
                                <span class="preview__text" :class="{'bold-selected': p2.length == 0}">{{p1}}</span>
                            </section>
                        </section>
                        <section class="preview__row" v-if="p2.length > 0">
                            <section class="preview__left">
                                <img src="../../../../../assets/icons/components/L-top.svg"
                                     class="preview__icon" />
                                <span class="preview__text preview__text--blue">{{n2}} </span>
                                <span class="preview__text" :class="{'bold-selected': p3.length == 0}">{{p2}}</span>
                            </section>
                        </section>
                        <section class="preview__row" v-if="p3.length > 0">
                            <section class="preview__left">
                                <img src="../../../../../assets/icons/components/L-bottom.svg"
                                     class="preview__icon preview__icon--large" />
                                <span class="preview__text preview__text--blue">{{n3}} </span>
                                <span class="preview__text" :class="{'bold-selected': p3.length > 0}">{{p3}}</span>
                            </section>
                        </section>
                    </section>
                </section>

                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button"
                            @click="this.$emit('closeExportVotingToOtherSessionModal')">
                        Anuluj
                    </button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="handleSaveClick" :disabled="name.length < 3 || selectedPoint == 0 || selectedSession == 0">
                        Wyeksportuj
                    </button>
                </section>
            </perfect-scrollbar>

        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import VotingService from "../../../../../services/voting.service";
import SessionService from "../../../../../services/session.service";
import PointsNumerationHelper from "@/helpers/pointsNumeration.helper";

export default {
    name: "ExportVotingToOtherSessionModal",
    data() {
        return {
            voting: this.tempVotingToExport,
            isLoading: false,
            pointsToSelect: [],
            sessionsToSelect: [],
            selectedPoint: 0,
            selectedSession: this.sessionId,
            name: this.defaultName,
            p1: '',
            p2: '',
            p3: '',
            n1: '',
            n2: '',
            n3: '',
            sSInfoForDisplay: null,
        };
    },
    props: {
        pointsForVotingDrag: Array,
        tempVotingToExport: Object,
        pointID: Number,
        sessionId: Number,
        firstLvl: Number,
        secondLvl: Number,
        thirdLvl: Number,
        sessions: Array,
        defaultName: String
    },
    components: {
        LoaderInside,
    },
    mounted(){
        this.sessionsToSelect = this.sessions;
        this.selectedSession = this.sessionId;
    },
    methods: {
        showSessionName(session) {

            var name = session.name;
            var number = session.number;
            var group = session.default_group_name;
            var nameToDisplay = '';

            if (name.length > 0 && group.length == 0) {
                nameToDisplay = name;

                if (number != null && number > 0) {
                    nameToDisplay += " nr " + number;
                }
            }
            else if (name.length > 0 && group.length > 0) {
                nameToDisplay = name;

                if (number != null && number > 0) {
                    nameToDisplay += " nr " + number;
                }

                nameToDisplay += " (" + group + ")";
            }
            else {
                nameToDisplay = 'Posiedzenie ';

                if (number != null && number > 0) {
                    nameToDisplay += " nr " + number;
                }

                nameToDisplay += " (" + group + ")";
            }

            return nameToDisplay;
        },
        showDate(date) {
            if (date != null && date != undefined && date.length > 0)
                return "z dnia " + this.formatDateToDisplay(date);
        },
        formatDateToDisplay(value) {
            var date = new Date(value)
            var d = date.toLocaleString('default', {
                year: 'numeric',
                month: 'long',              
                day: 'numeric'
            });

            return d;
        },
        checkSelectedSession(e) {
            this.sSInfoForDisplay = this.sessionsToSelect.filter(x => x.id == e.target.value)[0];
            this.isLoading = true;
            SessionService.getPoints(this.sSInfoForDisplay.id).then((response) => {
                this.prepareAllPointsForSelect(response.data);
                this.isLoading = false;
            }).catch((e) => {
                console.log(e);
                this.isLoading = false;
            });
        },
        checkSelectedPoint(e) {
            var setElems = this.pointsToSelect.filter(x => x.id == e.target.value);

            switch (setElems[0].lvl) {
                case 0:
                    this.p1 = setElems[0].name;
                    this.p2 = '';
                    this.p3 = '';
                    this.n1 = this.filterIndexSelected(setElems[0].orderToDisplay, setElems[0].number, 0);
                    this.n2 = '';
                    this.n3 = '';
                    break;
                case 1:
                    this.p1 = setElems[0].parentFullName1;
                    this.p2 = setElems[0].name;
                    this.p3 = '';
                    this.n1 = this.filterIndexSelected(setElems[0].parentOrderToDisplay1, setElems[0].parentNumber1, 0);
                    this.n2 = this.filterIndexSelected(setElems[0].orderToDisplay, setElems[0].number, 1);
                    this.n3 = '';
                    break;
                case 2:
                    this.p1 = setElems[0].parentFullName1;
                    this.p2 = setElems[0].parentFullName2;
                    this.p3 = setElems[0].name;
                    this.n1 = this.filterIndexSelected(setElems[0].parentOrderToDisplay1, setElems[0].parentNumber1, 0);
                    this.n2 = this.filterIndexSelected(setElems[0].parentOrderToDisplay2, setElems[0].parentNumber2, 1);
                    this.n3 = this.filterIndexSelected(setElems[0].orderToDisplay, setElems[0].number, 2);
                    break;
                default:
                    this.p1 = '';
                    this.p2 = '';
                    this.p3 = '';
                    this.n1 = '';
                    this.n2 = '';
                    this.n3 = '';
                    break;
            }
        },
        prepareAllPointsForSelect(list) {
            var tempPoints = [];
            var pointsToSelect = PointsNumerationHelper.setOrderToDisplayRecursive([...list]);

            for (var i = 0; i < pointsToSelect.length; i++) {

                var d1 = {
                    id: pointsToSelect[i].id,
                    name: pointsToSelect[i].name,
                    lvl: 0,
                    parentFullName1: '',
                    parentFullName2: '',
                    orderToDisplay: pointsToSelect[i].orderToDisplay,
                    number: pointsToSelect[i].number,
                    parentNumber1: null,
                    parentNumber2: null,
                    parentOrderToDisplay1: null,
                    parentOrderToDisplay2: null
                };

                var display = pointsToSelect[i].name.length > 80 ? pointsToSelect[i].name.substring(0, 60) + '...' : pointsToSelect[i].name;
                d1.displayName = this.filterIndex(pointsToSelect[i], 0) + " " + display;
                tempPoints.push(d1);

                if (pointsToSelect[i].children != null && pointsToSelect[i].children.length > 0) {
                    for (var j = 0; j < pointsToSelect[i].children.length; j++) {

                        var d2 = {
                            id: pointsToSelect[i].children[j].id,
                            name: pointsToSelect[i].children[j].name,
                            lvl: 1,
                            parentFullName1: pointsToSelect[i].name,
                            parentFullName2: '',
                            orderToDisplay: pointsToSelect[i].children[j].orderToDisplay,
                            number: pointsToSelect[i].children[j].number,
                            parentNumber1: pointsToSelect[i].number,
                            parentNumber2: null,
                            parentOrderToDisplay1: pointsToSelect[i].orderToDisplay,
                            parentOrderToDisplay2: null
                        };

                        var checkLength = '\xA0\xA0\xA0 ' + pointsToSelect[i].children[j].name;

                        if (checkLength.length > 80) {
                            d2.displayName = '\xA0\xA0\xA0 ' + this.filterIndex(pointsToSelect[i].children[j], 1) + " " + pointsToSelect[i].children[j].name.substring(0, 60) + '...';
                        }
                        else {
                            d2.displayName = '\xA0\xA0\xA0 ' + this.filterIndex(pointsToSelect[i].children[j], 1) + " " + pointsToSelect[i].children[j].name;
                        }

                        tempPoints.push(d2);

                        if (pointsToSelect[i].children[j].children != null && pointsToSelect[i].children[j].children.length > 0) {
                            for (var k = 0; k < pointsToSelect[i].children[j].children.length; k++) {

                                var d3 = {
                                    id: pointsToSelect[i].children[j].children[k].id,
                                    name: pointsToSelect[i].children[j].children[k].name,
                                    lvl: 2,
                                    parentFullName1: pointsToSelect[i].name,
                                    parentFullName2: pointsToSelect[i].children[j].name,
                                    orderToDisplay: pointsToSelect[i].children[j].children[k].orderToDisplay,
                                    number: pointsToSelect[i].children[j].children[k].number,
                                    parentNumber1: pointsToSelect[i].number,
                                    parentNumber2: pointsToSelect[i].children[j].number,
                                    parentOrderToDisplay1: pointsToSelect[i].orderToDisplay,
                                    parentOrderToDisplay2: pointsToSelect[i].children[j].orderToDisplay
                                };

                                var checkLength2 = '\xA0\xA0\xA0\xA0\xA0\xA0 ' + pointsToSelect[i].children[j].children[k].name;

                                if (checkLength2.length > 80) {
                                    d3.displayName = '\xA0\xA0\xA0\xA0\xA0\xA0 ' + this.filterIndex(pointsToSelect[i].children[j].children[k], 2) + " " + pointsToSelect[i].children[j].children[k].name.substring(0, 60) + '...';
                                }
                                else {
                                    d3.displayName = '\xA0\xA0\xA0\xA0\xA0\xA0 ' + this.filterIndex(pointsToSelect[i].children[j].children[k], 2) + " " + pointsToSelect[i].children[j].children[k].name;
                                }

                                tempPoints.push(d3);
                            }
                        }
                    }
                }

            }

            this.pointsToSelect = tempPoints;
        },
        filterIndex(elem, lvl) {
            return PointsNumerationHelper.filterIndex(elem.orderToDisplay, elem.number, lvl, this.firstLvl, this.secondLvl, this.thirdLvl);
        },
        filterIndexSelected(order, number, lvl) {
            return PointsNumerationHelper.filterIndex(order, number, lvl, this.firstLvl, this.secondLvl, this.thirdLvl);
        },
        handleSaveClick() {
            this.isLoading = true;
            const data = {
                pointFrom: this.pointID,
                pointTo: this.selectedPoint,
                sidFrom: this.sessionId,
                sidTo: this.selectedSession,
                votingId: this.voting.id,
                name: this.name
            };

            VotingService.exportVotingResultsToOtherSession(this.sessionId, data).then(() => {
                this.voting = {};
                this.pointsToSelect = [];
                this.sessionsToSelect = [];
                this.selectedPoint = 0;
                this.selectedSession = 0;
                this.name = '';
                this.p1 = '';
                this.p2 = '';
                this.p3 = '';
                this.n1 = '';
                this.n2 = '';
                this.n3 = '';
                this.$emit("reload");
                this.$emit("closeExportVotingToOtherSessionModal");
                this.isLoading = false;
            }).catch((e) => {
                console.log(e)
                this.isLoading = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/esesja-modal.scss";

    .bold-selected {
        font-weight: bold !important;
    }

    .preview {
        padding: 15px 16px;
        background: #e5efff;
        border: 1px dashed #3380ff;
        border-radius: 4px;

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 7px;

            &:nth-last-child(1) {
                margin-bottom: 0px;
            }
        }

        &__left {
            display: flex;
            align-items: center;
        }

        &__text {
            color: #333956;
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;

            &--blue {
                color: #004dcc;
                margin-right: 16px;
            }
        }

        &__icon {
            margin-right: 6px;

            &--large {
                position: relative;
                top: -8px;
            }
        }

        &__desc {
            font-weight: 500;
            font-size: 11px;
            line-height: 120%;
            text-align: right;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #808396;
        }
    }

</style>

