<template>
    <section class="voting">
        <div class="voting-form">
            <button class="voting-form__exit-btn" v-if="!votingIsRunning"
                    @click="closeModale">
                <img src="../../../../../assets/icons/components/exit-btn.svg" />
            </button>

            <section class="voting-form__form-wrapper">
                <section class="voting-form__header">
                    <section class="voting-form__title" v-if="internalBasicData.isCheckPresence == true">
                        {{internalBasicData.name}}
                    </section>
                    <section class="voting-form__title" v-else>
                        Głosowanie z dnia {{formatDateToDisplay(new Date())}}: <b>{{internalBasicData.name}}</b>
                    </section>
                    <section class="voting-form__action" v-if="isInteractive()">
                        <ButtonOnlyWithIcon v-if="isAutoGoToNextVotingAfterSave" icon="automatyczne_przejscie" @click="changeAutoGoToNextVotingAfterSave(false)" v-tooltip.bottom="'Wyłacz automatyczne przejście do kolejnego głosowania'" :disable="this.settings.isLoadingAutoGoToNextVotingAfterSave" class="active-icon-color" />
                        <ButtonOnlyWithIcon v-if="!isAutoGoToNextVotingAfterSave" icon="automatyczne_przejscie" @click="changeAutoGoToNextVotingAfterSave(true)" v-tooltip.bottom="'Włącz automatyczne przejście do kolejnego głosowania'" :disable="this.settings.isLoadingAutoGoToNextVotingAfterSave" />

                        <ButtonOnlyWithIcon v-if="isAutoStartNextVoting" icon="automatyczne_uruchomienie" @click="changeAutoStartNextVoting(false)" v-tooltip.bottom="'Wyłacz automatyczne uruchamianie kolejnego głosowania'" :disable="this.settings.isLoadingAutoStartNextVoting" class="active-icon-color" />
                        <ButtonOnlyWithIcon v-if="!isAutoStartNextVoting" icon="automatyczne_uruchomienie" @click="changeAutoStartNextVoting(true)" v-tooltip.bottom="'Włącz automatyczne uruchamianie kolejnego głosowania'" :disable="this.settings.isLoadingAutoStartNextVoting" />

                        <ButtonOnlyWithIcon v-if="isAutoEndVoting" icon="automatyczne_konczenie" @click="changeAutoEndMode()" v-tooltip.bottom="'Wyłacz automatyczne kończenie głosowania'" :disable="this.settings.isLoadingAutoEndVoting" class="active-icon-color" />
                        <ButtonOnlyWithIcon v-if="!isAutoEndVoting" icon="automatyczne_konczenie" @click="changeAutoEndMode()" v-tooltip.bottom="'Włącz automatyczne kończenie głosowania'" :disable="this.settings.isLoadingAutoEndVoting" />

                        <ButtonOnlyWithIcon v-if="internalBasicData.isPublic && !isHideResults" @click="hideResults()" icon="ukryj_wyniki_w_trakcie" v-tooltip.bottom="'Ukryj wyniki w trakcie głosowania'" class="active-icon-color" />
                        <ButtonOnlyWithIcon v-if="internalBasicData.isPublic && isHideResults" @click="showResults()" icon="ukryj_wyniki_w_trakcie" v-tooltip.bottom="'Pokaż wyniki w trakcie głosowania'" />
                    </section>
                </section>
                <hr />
                <h2 style="text-align: center;">UPRAWNIENI DO GŁOSOWANIA</h2>
                <perfect-scrollbar :style="scrollBarStyle">
                    <div class="available-users">
                        <div class="user" v-for="(item, key) in internalBasicData.users" :key="key" :class="getVoteUserClass(item, $store.state.settings.client.interactiveModalVotingShowAvatar)" @click="openVoteForUserModal(item)">
                            <div class="user-avatar" v-if="$store.state.settings.client.interactiveModalVotingShowAvatar">
                                <img :src="getUserAvata(item.userId)" :alt="`${item.surname} ${item.name}`" />
                            </div>
                            <div class="user-data">
                                <span class="user-name">{{item.firstName}} {{item.lastName}}</span>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
                <hr v-if="votingIsRunning" />
                <div v-if="votingIsRunning" class="running-voting">
                    <span>ZAGŁOSOWAŁO: {{votedUsers}} / {{ presentsUsers }}</span>
                    <div class="results" v-if="internalBasicData.isPublic && !isHideResults">
                        <div class="uprawniony" :class="optionToClassName(s.option)" v-for="s in votingPossibleAnswers" :value="s.id" :key="s.id" :style="{'background-color': formatAnswersColor(s), 'color': formatAnswersColorText(s.option)}">{{s.option}}: <b>{{s.count}}</b></div>
                    </div>
                    <div class="results" v-else>
                        <div class="uprawniony" style="background-color: #aba9a2">Oddane głosy <b>{{hiddenVoteResult}}</b></div>
                    </div>
                </div>

                <section v-if="isInteractive() && isQuorumForVoting == true" class="voting-form__buttons-bottom">
                    <button class="voting-form__bottom-button"
                            @click="cencelVoting()" :disabled="!votingIsRunning">
                        Anuluj
                    </button>
                    <button class="voting-form__bottom-button voting-form__bottom-button--red"
                            :class="is_automatic && selectedAutomaticVoting > 0 && showTimer ? 'timerclass' : ''" @click="startVoting()"
                            style="display: flex; align-items: center; justify-content: center; column-gap: 15px;
                        "
                            :style="leftFiveSec == true ? 'color: red !important' : ''"
                            :disabled="votingIsRunning || isStartVotingIsBusy">
                        <InlineLoader v-if="isStartVotingIsBusy" /> {{ is_automatic && selectedAutomaticVoting > 0 && showTimer ? getTime() : 'Rozpocznij'}}
                    </button>
                    <button class="
                            voting-form__bottom-button
                            voting-form__bottom-button--red" @click="saveResults()" :disabled="!votingIsRunning">
                        Zapisz wyniki
                    </button>
                </section>
                <section v-if="isInteractive() && isQuorumForVoting == false">
                    <h2 style="text-align: center; padding: 15px;">Nie osiągnięto wymaganego kworum {{basicData.isQourun}}% obecności</h2>
                </section>

            </section>
        </div>
        <VoteForUserModal v-if="showVoteForUserModal" :user="userVoteFor" :votinoptions="adminAnsversForUserVote" :basic="internalBasicData" @sendVoteForUserAdmin="sendVoteForUserAdmin" @closeVotingForUserModal="closeVotingForUserModal"></VoteForUserModal>
        <LoaderInsideText v-if="isLoading" :text="'Trwa uruchamianie kolejnego głosowania...'" />
    </section>
</template>

<script charset="utf-8">
    import esesjaInteractiveService from "../../../../../services/esesja.interactive.service";
    import VotingService from "../../../../../services/voting.service";
    import dingSound from "../../../../../assets/sounds/ding.wav";
    import LoaderInsideText from "../../../../BasicControls/LoaderInsideWithText.vue"
    import ButtonOnlyWithIcon from "../../../../BasicControls/ButtonOnlyWithIcon.vue";
    import InlineLoader from "../../../../BasicControls/InlineLoader.vue";
    import { useTimer } from 'vue-timer-hook';
    import VoteForUserModal from '../Modals/VoteForUserModal.vue';
    import CommonHelper from '@/helpers/common.helper.js';
    import settingsService from "../../../../../services/settings.service";
    import AlertHelper from "../../../../../helpers/alerts.helper";
    import { debounce } from "../../../../../helpers/utils";
    import InteractiveService from "../../../../../services/interactive.service";

    export default {
        name: "InteractiveVotingModal",
        data() {
            return {
                settings: {
                    isLoadingAutoEndVoting: false,
                    isLoadingAutoStartNextVoting: false,
                    isLoadingAutoGoToNextVotingAfterSave: false,
                },
                isStartVotingIsBusy: false,
                isLoading: false,
                errors: [],
                interactiveVotingToUpdate: this.interactiveVoting,
                votingIsRunning: false,
                votingPossibleAnswers: [],
                adminAnsversForUserVote: [],
                internalBasicData: this.basicData,
                hiddenVoteResult: 0,
                audioDingPath: '',
                isHideResults: this.$store.state.interactiveStore.hideVotingResultsDuringVoting,
                votingTimer: null,
                is_automatic: this.$store.state.interactiveStore.startAutomaticVotingByTimeSet.isAutomatic,
                selectedAutomaticVoting: this.$store.state.interactiveStore.startAutomaticVotingByTimeSet.time,
                showTimer: false,
                leftFiveSec: false,
                showVoteForUserModal: false,
                userVoteFor: {},
                tempPossibleAnswersNotVisible: [],
                isQuorumForVoting: true,
                cyclicQueryFlag: false,
                avatarVersion: CommonHelper.getRandomVersionImageForUrlRefresh(0, 100)
            };
        },
        components: {
            LoaderInsideText,
            ButtonOnlyWithIcon,
            VoteForUserModal,
            InlineLoader
        },
        props: {
            interactiveVoting: Object,
            basicData: Object,
            openAutomatic: Boolean
        },
        watch: {
            cyclicQueryFlag: debounce(async function () {
                if (this.votingIsRunning) {
                    this.cyclicQueryFlag = !this.cyclicQueryFlag; // negujemy stan żeby ponownie wyzwolić debouce 
                    await this.getCurrentVotingResult();
                    
                }
            }, 1000)
        },
        mounted() {
            this.checkQuorumForPossibleStartVoting();

            if (this.isInteractive() && this.openAutomatic && this.isAutoGoToNextVotingAfterSave && this.isAutoStartNextVoting) {
                this.isLoading = true;
                setTimeout(async () => { await this.startVoting(); this.isLoading = false }, this.getAutoStartNextVotingTime);
            }
        },
        computed: {
            isAutoStartNextVoting() {
                return this.$store.state.settings.client.autoStartNextVoting;
            },
            getAutoStartNextVotingTime() {
                return this.$store.state.settings.client.autoStartNextVotingTime * 1000;
            },
            isAutoGoToNextVotingAfterSave() {
                return this.$store.state.settings.client.autoGoToNextVotingAfterSave;
            },
            isAutoEndVoting() {
                return this.$store.state.settings.client.autoEndVotingAfterAllVoted;
            },
            scrollBarStyle() {
                return {
                    'max-height': this.votingIsRunning ? "calc(90vh - 425px)" : "calc(90vh - 290px)"
                }
            },
            presentsUsers() {
                return this.internalBasicData.users.filter(item => parseInt(item.presence) !== 2).length ?? 0
            },
            votedUsers() {
                return this.internalBasicData.users.filter(item => parseInt(item.response) !== 0).length ?? 0
            }
        },
        methods: {
            async changeAutoStartNextVoting(turn_on) {
                try {

                    this.settings.isLoadingAutoStartNextVoting = true;
                    let data = { ...this.$store.state.settings.client };

                    if (turn_on) {
                        data.autoStartNextVoting = true;
                        data.autoGoToNextVotingAfterSave = true;
                    }
                    else {
                        data.autoStartNextVoting = false;
                    }
                    
                    await settingsService.updateClientSettings(data);
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.settings.isLoadingAutoStartNextVoting = false;
                }
            },

            async changeAutoGoToNextVotingAfterSave(turn_on) {
                try {
                    this.settings.isLoadingAutoGoToNextVotingAfterSave = true;
                    let data = { ...this.$store.state.settings.client };

                    if (turn_on) {
                        data.autoGoToNextVotingAfterSave = true;
                    }
                    else {

                        data.autoGoToNextVotingAfterSave = false;
                        if (this.isAutoStartNextVoting == true) {
                            data.autoStartNextVoting = false;
                        }
                    }
 
                    await settingsService.updateClientSettings(data);
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.settings.isLoadingAutoGoToNextVotingAfterSave = false;
                }
            },

            async changeAutoEndMode() {
                try {
                    this.settings.isLoadingAutoEndVoting = true;
                    let data = { ...this.$store.state.settings.client };
                    data.autoEndVotingAfterAllVoted = !this.isAutoEndVoting;
                    await settingsService.updateClientSettings(data);
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.settings.isLoadingAutoEndVoting = false;
                }
            },

            optionToClassName(option){
                switch (option) {
                    case 'ZA':
                    case 'TAK':
                        return "vote-for";
                    case 'PRZECIW':
                    case 'NIE':
                        return "vote-no";
                    case 'WSTRZYMUJĘ SIĘ':
                    case 'NIE WIEM':
                        return "vote-abstain";
                    case 'BRAK GŁOSU':
                        return "vote-no-vote";
                    case 'NIEOBECNI':
                    case 'NIEOBECNY':
                        return "vote-absent";
                    case 'WYKLUCZENI':
                    case 'WYŁĄCZENI':
                        return "vote-exclude";
                    default:
                        return "vote-default";

                }
            },

            checkQuorumForPossibleStartVoting() {
                var isCheckQuorum = this.basicData.isCheckPresence;
                if (isCheckQuorum == false) {
                    var quorumRequiredPercentage = this.basicData.isQourun;
                    var allUsers = this.basicData.users.length;
                    var usersActualPresence = this.basicData.users.filter(x => x.presence == 1).length;

                    var calculate = Math.round((usersActualPresence / allUsers) * 100);

                    if (calculate >= quorumRequiredPercentage) {
                        this.isQuorumForVoting = true;
                    }
                    else {
                        this.isQuorumForVoting = false;
                    }
                }
            },
            getTime() {
                if (this.votingTimer === null)
                    return '00:00';

                var timeTodisplay = `${this.getLeadZero(this.votingTimer.minutes)}:${this.getLeadZero(this.votingTimer.seconds)}`;

                if (timeTodisplay == '00:05') {
                    this.leftFiveSec = true;
                }

                if (timeTodisplay == '00:00') {
                    this.saveResults();
                }

                return timeTodisplay;
            },
            getLeadZero(number) {
                if (number < 10)
                    return `0${number}`;

                return `${number}`;
            },
            async hideResults() {
                this.$store.commit("setHideVotingResultsDuringVoting", true);
                this.isHideResults = true;

                if (this.votingIsRunning) {
                    await esesjaInteractiveService.hideVotingResultsLive(this.internalBasicData.sessionId);
                }

            },
            async showResults() {
                this.$store.commit("setHideVotingResultsDuringVoting", false);
                this.isHideResults = false;

                if (this.votingIsRunning) {
                    await esesjaInteractiveService.showVotingResultsLive(this.internalBasicData.sessionId);
                }

            },
            async getCurrentVotingResult() {
                try {

                     var data = {
                         votingId: this.internalBasicData.votingId,
                         sessionId: this.internalBasicData.sessionId,
                         pointId: this.internalBasicData.pointId,
                         skipVotingResults: null
                     };

                    var result = (await InteractiveService.getVotingPartialResults(data));
                    if (result.status == 200) {
                        this.partialVoteResult(result.data);
                    }
                }
                catch (e) {
                    console.log(e);
                    //this.$store.commit("showError");
                }
            },
            openVoteForUserModal(item) {
                if (this.votingIsRunning && this.internalBasicData.gType != 4 && item.excluded == false && item.gadmin != 0 && item.presence != 2 && this.internalBasicData.maxAnswers == 1 && this.internalBasicData.minAnswers == 1 && this.$store.state.settings.client.adminVoteForUser == true) {

                    this.userVoteFor = item;
                    this.adminAnsversForUserVote = [];

                    var temp = this.votingPossibleAnswers;
                    for (var i = 0; i < temp.length; i++) {
                        var opt = { id: temp[i].id, option: temp[i].option };
                        this.adminAnsversForUserVote.push(opt);
                    }

                    var isPresence = this.votingPossibleAnswers.find(x => x.option == "OBECNY");
                    if (isPresence == undefined) {
                        var d = { id: 0, option: "BRAK GŁOSU" };
                        this.adminAnsversForUserVote.push(d);
                    }

                    var n = this.tempPossibleAnswersNotVisible.filter(x => x.option == "NIEOBECNI" || x.option == "NIEOBECNY");

                    var d2 = { id: n[0].id, option: n[0].option };
                    this.adminAnsversForUserVote.push(d2);

                    this.showVoteForUserModal = true;
                }
            },
            closeVotingForUserModal() {
                this.userVoteFor = {};
                this.showVoteForUserModal = false;
            },
            async sendVoteForUserAdmin(data) {
                try {
                    if (this.votingIsRunning && this.internalBasicData.gType != 4) {
                        var result = (await VotingService.sendVoteForUserByAdmin(data, data.votingId));

                        await this.getCurrentVotingResult();

                        if (result.status == 409) {
                            var toast = this.$swal.mixin(AlertHelper.errorToastElement());
                            toast.fire(AlertHelper.errorToastAlert(result.data));
                        }
                    }

                } catch (e) {

                    if (e.response.status == 409) {
                        var toast2 = this.$swal.mixin(AlertHelper.errorToastElement());
                        toast2.fire(AlertHelper.errorToastAlert(e.response.data));

                        await this.getCurrentVotingResult();
                    }

                    if (e.response.status == 422) {
                        var toast3 = this.$swal.mixin(AlertHelper.errorToastElement());
                        toast3.fire(AlertHelper.errorToastAlert(e.response.data));

                        await this.getCurrentVotingResult();
                    }

                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            partialVoteResult(data) {
                if (this.cyclicQueryFlag == false || this.votingIsRunning == false)
                    return;

                this.internalBasicData.users = [...new Map(data.map((item) => [item["userId"], item])).values()];

                this.votingPossibleAnswers = this.votingPossibleAnswers.filter(obj => {

                    if (this.internalBasicData.gType != 4) {
                        if (this.internalBasicData.isPublic) {
                            // for public
                            obj.count = data.filter(item => { return parseInt(item.responseId) === obj.id }).length;

                            // for hidden public
                            this.hiddenVoteResult = data.filter(item => { return parseInt(item.response) !== 0 }).length;
                        }
                        else {
                            this.hiddenVoteResult = data.filter(item => { return parseInt(item.response) === 10 }).length;
                        }
                    }
                    else {
                        if (this.internalBasicData.isPublic) {
                            // for public
                            obj.count = data.filter(item => { return parseInt(item.responseId) === obj.id && parseInt(item.option) === 1 }).length;

                            // for hidden public
                            this.hiddenVoteResult = data.filter(item => { return parseInt(item.response) !== 0 && parseInt(item.option) === 1 }).length;
                        }
                        else {
                            this.hiddenVoteResult = data.filter(item => { return parseInt(item.response) === 10 && parseInt(item.option) === 1 }).length;
                        }
                    }

                    return true;
                })

                this.checkIfAllUserVoted(this.internalBasicData.users);
            },

            checkIfAllUserVoted(usersVotes) {
                try {
                    if (this.isAutoEndVoting === false) {
                        return;
                    }
                    let localCopy = [...usersVotes];
                    let leftUsers = localCopy.filter(item => item.response === 0 && (item.presence !== 2 || item.presence === null) && item.excluded === false);
                    if (leftUsers.length === 0) {
                        this.saveResults();
                    }
                }
                catch (e) {
                    console.log(e);
                }
            },

            getVoteUserClass(item, showAvatar = true) {

                let classes = ""

                if (item === null)
                    classes = "";

                let userVoice = parseInt(item.response);

                if (userVoice === 0) {

                    if (item.excluded) {
                        classes = "excluded";
                    }

                    if (parseInt(item.presence) === 2) {
                        classes = "absent";
                    }
                }
                else {

                    if (this.isHideResults) {
                        if (userVoice != 99)
                            classes = "vote-hidden";
                    } else {
                        switch (userVoice) {
                            case 0: classes = "vote-lack"; break;
                            case 1: classes = "vote-for"; break;
                            case 2: classes = "vote-against"; break;
                            case 3: classes = "vote-abstain"; break;
                            case 4: classes = "absent"; break;
                            case 10:
                            //case 99:
                                classes = "vote-hidden"; break;
                        }
                    }
                }

                if (!showAvatar) {
                    classes += ' no-avatar'
                }
                return classes;
            },
            formatDateToDisplay(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    weekday: 'long',
                    day: 'numeric'
                });

                return d;
            },
            isInteractive() {
                return this.$store.state.appStore.isInInteractiveMode;
            },
            formatAnswersColorText(result) {
                if (result == "NIEOBECNI" || result == "NIEOBECNY" || result == "WYŁĄCZENI" || result == 'PRZECIW' || result == 'NIE')
                    return 'white';
                else
                    return 'black';
            },
            formatAnswersColor(result) {
                if (result != null) {
                    switch (result.option) {
                        case 'ZA':
                        case 'TAK':
                            result.color = '#79e563'; 
                            break;
                        case 'PRZECIW':
                        case 'NIE':
                            result.color = '#e40030';
                            break;
                        case 'WSTRZYMUJĘ SIĘ':
                        case 'NIE WIEM':
                            result.color = '#ffce56';
                            break;
                        case 'BRAK GŁOSU':
                            result.color = '#c0c0c0';
                            break;
                        case 'NIEOBECNI':
                        case 'NIEOBECNY':
                            result.color = '#000';
                            break;
                        default:
                            result.color = '#c0c0c0';
                            break;
                    }
                    return result.color;
                }
            },
            async startVoting() {
                try {

                    if (this.isStartVotingIsBusy) {
                        return;
                    }

                    this.isStartVotingIsBusy = true;

                    if (this.isQuorumForVoting == false)
                        return;

                    
                    var data = {
                        votingId: this.internalBasicData.votingId,
                        sessionId: this.internalBasicData.sessionId,
                        pointId: this.internalBasicData.pointId,
                        skipVotingResults: null
                    };
                    
                    let hubResult = (await InteractiveService.startVoting(data)).data;

                    var result = (await VotingService.getVotingsPossibleAnswers(this.internalBasicData.votingId));

                    if (result.status != 200) {
                        throw new Error("Cannot get getVotingsPossibleAnswers()");
                    }

                    if (Object.keys(result).length > 0) {
                        this.tempPossibleAnswersNotVisible = result.data.filter(x => x.option === "NIEOBECNI" || x.option === "NIEOBECNY" || x.option === "BRAK GŁOSU");
                        this.votingPossibleAnswers = result.data.filter(x => x.option !== "NIEOBECNI" && x.option !== "NIEOBECNY" && x.option !== "BRAK GŁOSU");
                    }

                    this.votingIsRunning = hubResult;

                    if (this.votingIsRunning) {
                        this.cyclicQueryFlag = !this.cyclicQueryFlag // negujemy stan by wyzwolić pop raz pierwszy debouce
                    }

                    var audio = new Audio(dingSound);
                    audio.play();

                    if (this.is_automatic && this.selectedAutomaticVoting > 0) {
                        const time = new Date();
                        time.setSeconds(time.getSeconds() + this.selectedAutomaticVoting);
                        this.votingTimer = useTimer(time);
                        this.showTimer = true;
                    }

                    if (this.isHideResults) {
                        await esesjaInteractiveService.hideVotingResultsLive(this.internalBasicData.sessionId);
                    }
                    else {
                        await esesjaInteractiveService.showVotingResultsLive(this.internalBasicData.sessionId);
                    }

                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally {
                    this.isStartVotingIsBusy = false
                }
            },
            async cencelVoting() {
                try {

                    this.cyclicQueryFlag = false;

                     var data = {
                         votingId: this.internalBasicData.votingId,
                         sessionId: this.internalBasicData.sessionId,
                         pointId: this.internalBasicData.pointId,
                         skipVotingResults: null
                     };

                    let hubResult = (await InteractiveService.cancelVoting(data)).data;

                    this.votingIsRunning = !hubResult;

                    this.hiddenVoteResult = 0;
                    this.showTimer = false;
                    this.leftFiveSec = false;

                    for (var i = 0; i < this.internalBasicData.users.length; i++) {
                        this.internalBasicData.users[i].response = 0;
                    }
                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async closeModale(e) {
                try {
                    e.preventDefault();

                    if (this.votingIsRunning)
                        await this.cencelVoting();
                    
                    this.$emit('closeInteractiveVotingt');
                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            saveResults() {
                try {
                    var skip_results = this.$store.state.interactiveStore.startAutomaticVotingByTimeSet.isAutomatic || this.$store.state.settings.client.autoGoToNextVotingAfterSave ? true : false;

                     var data = {
                         votingId: this.internalBasicData.votingId,
                         sessionId: this.internalBasicData.sessionId,
                         pointId: this.internalBasicData.pointId,
                         skipVotingResults: skip_results
                     };

                    InteractiveService.saveVoting(data).then(() => {
                        this.votingIsRunning = false;
                        this.$emit('closeInteractiveVotingt');

                        var data = {
                            votingId: this.internalBasicData.votingId,
                            sessionId: this.internalBasicData.sessionId,
                            pointId: this.internalBasicData.pointId
                        }

                        this.showTimer = false;
                        this.leftFiveSec = false;
                        this.$emit('saveVotingAction', data);
                    });
                }
                catch (e) {
                    this.$store.commit("showError");
                }
            },
            getUserAvata(uid) {
                return `https://api.esesja.pl/users/${uid}.jpg${this.avatarVersion}`;
            },
        },
    };
</script>

<style lang="scss" scoped>

    .active-icon-color {
        background-color: #d5f5d5;
    }

    .timerclass {
        color: black !important;
        font-size: 30px !important;
    }

    .uprawnieni {
        display:block;
    }

    .results {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 10px;
        justify-content: center;

        .vote-for {
            background-color: rgb(121, 229, 99);
            color: black;
            text-decoration: none;
        }

        .vote-no {
            background-color: rgb(228, 0, 48);
            color: white;
            text-decoration: none;
        }

        .vote-abstain {
            background-color: rgb(255, 206, 86);
            color: black;
            text-decoration: none;
        }

        .vote-no-vote {
            background-color: rgb(192, 192, 192);
            color: black;
            text-decoration: none;
        }

        .vote-absent {
            background-color: rgb(0, 0, 0);
            color: white;
            text-decoration: none;
        }
    }

    .uprawniony {
        display: inline-block;
        padding: 8px;
        font-size: 1.4em;
        font-weight: 300;
        background-color: #e5e5e5;
    }


    .voting {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 9;
    }

    .voting-form {
        padding: 32px 56px;
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        max-height: 100vh;
        padding-bottom: 0px;
        padding-right: 0px;
        width: 90vw;
        //height: 100%;

        &__form-wrapper {
            max-height: 90vh;
            //overflow-y: auto;
            padding-right: 56px;
            display: flex;
            flex-direction: column;
            row-gap: 15px;

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }

            .available-users {
                display: flex;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 10px;
                justify-content: center;
                margin-bottom: 20px;



                .user {
                    width: 250px;
                    display: flex;
                    column-gap: 10px;
                    cursor: pointer;
                    align-items: center;
                    background-color: #e5e5e5;

                    .user-avatar img {
                        width: 70px;
                        vertical-align: middle;
                    }

                    .user-data .user-name {
                        font-size: 1.4em;
                        font-weight: 300;
                    }

                    &:hover {
                        background: #ddd;
                        box-shadow: 0px 0px 10px 5px #b1b1b1;
                    }
                }

                .no-avatar {
                    padding: 5px 10px;
                    min-height: 64px;
                    width: 180px;
                }

                .user.absent {
                    background: #000 !important;
                    color: white !important;
                }

                .user.excluded {
                    background: #363532 !important;
                    text-decoration-line: line-through !important;
                    color: white;
                }

                .user.vote-for {
                    background: #79e563 !important;
                }

                .user.vote-against {
                    background: #e40030 !important;
                    color: white !important;
                }

                .user.vote-abstain {
                    background: #ffce56 !important;
                }

                .user.vote-hidden {
                    background: #7cd1eb !important;
                }
            }
        }

        &__exit-btn {
            border: 0px;
            background-color: transparent;
            position: relative;
            right: calc(-100% + 48px);
        }

        &__row {
            margin-bottom: 10px;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
        }

        &__title {
            font-weight: 600;
            font-size: 20px;
            line-height: 128%;
            color: #00082c;
            width: 100%;

            &--small {
                font-size: 20px;
                border-bottom: 0px;
                padding-bottom: 16px;
                margin: 0px;
            }

            &--no-padding {
                padding: 0px;
            }

            &--comission {
                width: 100%;
                margin-top: 30px;
                padding-top: 28px;
                border-top: 1px solid #dde0e7;
            }
        }

        &__action {
            display: flex;
            flex-direction: row;
            column-gap: 5px;
        }

        &__section-double {
            display: flex;
            justify-content: space-between;
            width: 500px;
        }

        &__col-double {
            width: 238px;
        }

        &__section-triple {
            display: flex;
            justify-content: space-between;
            width: 500px;
        }

        &__col-triple {
            width: 150px;
        }

        &__input {
            width: 100%;
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            margin: 8px 0px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;

            &--checkbox {
                padding-left: 32px;
                z-index: 10;
                cursor: pointer;
                font-weight: normal !important;
            }

            &--normal {
                font-weight: normal !important;
            }
        }

        &__point-status {
            display: flex;
            flex-direction: column;
            //margin-top: 32px;
            padding-bottom: 32px;
            //border-bottom: 1px solid #dde0e7;
            //margin-bottom: 24px;
        }

        &__checkbox-top {
            display: flex;
            align-items: center;
            margin-top: 12px;

            &--col {
                margin-bottom: 13px;
                align-items: flex-start;
            }
        }

        &__checkbox {
            width: 0px;
            height: 0px;
            display: none;

            &:checked ~ .edit-point-form__checkbox-mark {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:checked ~ .add-contact-form__label--checkbox {
                padding-left: 0px;
            }
        }

        &__checkbox-wrapper {
            width: 0px;
            height: 24px;
            position: relative;
            margin-left: 20px;

            &:before {
                content: "";
                background: #ffffff;
                border: 1px solid #d1d6df;
                box-sizing: border-box;
                box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
                border-radius: 4px;
                width: 24px;
                height: 24px;
                position: relative;
                display: block;
                top: 0px;
                left: 0px;
                opacity: 1;
            }
        }

        &__checkbox-mark {
            background: #00082c;
            border: 1px solid #00082c;
            box-sizing: border-box;
            box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            width: 24px;
            height: 24px;
            position: absolute;
            z-index: 5;
            display: none;
            top: 0px;
            left: 0px;
            opacity: 1;
        }

        &__buttons-bottom {
            display: flex;
            justify-content: space-between;
            padding-bottom: 30px;
        }

        &__bottom-button {
            //padding: 18px 40px 17px;
            width: 238px;
            height: 56px;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
            background: transparent;
            transition: all 0.2s ease-in-out;

            &:disabled {
                background-color: #e5e5e5;
                border: 0;
                color: silver;
                cursor: default;
                pointer-events: none;
            }

            &:hover {
                background: #e8ebef;
            }

            &--red {
                border: 0px;
                background: #d80418;
                color: white;

                &:hover {
                    background: #ad0313 !important;
                }
            }
        }

        &__no-attachments {
            margin-bottom: 34px;
        }

        &__attachments {
            padding-top: 31px;
            border-top: 1px solid #dde0e7;
            margin-top: 26px;
        }

        &__attachments-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 27px;
        }

        &__upload-btn {
            display: flex;
            align-items: center;
            border: 0px;
            padding: 9px 16px;
            background: rgba(220, 228, 238, 0.56);
            border-radius: 4px;
        }

        &__upload-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;
            color: #00082c;
            white-space: nowrap;
            margin-left: 8px;
        }
    }

    .file-input {
        display: none;
    }

    .running-voting {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 20px;

        span {
            font-size: 1.5em;
            font-weight: bold;
            unicode-bidi: isolate;
        }
    }
</style>
