export function debounce(fn, wait) {
    let timer;
    return function (...args) {
        if (timer) {
            clearTimeout(timer); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer = setTimeout(() => {
            fn.apply(context, args); // call the function if time expires
        }, wait);
    }
}

export function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export function validateEmail(email){
    var matches = email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return matches === null;
}

export function validatePhone(phone) {
    var matches = phone.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s/0-9]*$/g);
    return matches === null;
}

export function isDevelopment() {
    return window.location.hostname === "localhost";
}

export function isDev() {
    return window.location.hostname === "dev.esesja.pl" || window.location.hostname === "devesesja.mwc.pl";
}

export function isProd() {
    return window.location.hostname === "app.esesja.pl";
}

export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function firstLetterLowerCase(string) {

    if (string.length === 0) {
        return string
    }

    return string[0].toLowerCase() + string.slice(1)
}

export function isUrlIsValid(url) {
    try {
        let uri = new URL(url);
        if (uri === null || uri === undefined) {
            throw new Error('Invalid url');
        }
        return true;
    }
    catch {
        return false;
    }
}

export function timeUnitsBetween(startDate, endDate) {
    let delta = Math.abs(endDate - startDate) / 1000;
    const isNegative = startDate > endDate ? -1 : 1;
    return [
        ['days', 24 * 60 * 60],
        ['hours', 60 * 60],
        ['minutes', 60],
        ['seconds', 1]
    ].reduce((acc, [key, value]) => (acc[key] = Math.floor(delta / value) * isNegative, delta -= acc[key] * isNegative * value, acc), {});
}

export function getLeadZero(number) {
    if (Math.abs(number) < 10)
        return `0${Math.abs(number)}`;

    return `${Math.abs(number)}`;
}

export function validateIp(ip){
    const parts = ip.split(/[.:]/);

    if (parts.length === 4) {

        // Check IPv4 parts
        for (const part of parts) {
            const num = parseInt(part);
            if (isNaN(num) || num < 0 || num > 255) {
                return false;
            }
        }
        return true;
    } else if (parts.length === 8) {

        // Check IPv6 parts
        for (const part of parts) {
            if (!/^[0-9a-fA-F]{1,4}$/.test(part)) {
                return false;
            }
        }
        return true;
    }
    return false;
}