<template>
    <section class="check-quourm-overlay">
        <section class="check-quorum-container">
            <header class="check-quorum-container__header">
                <section class="check-quorum-container__header__menu">
                    <a class="menu-item" @click="saveQuorumResults">
                        <img src="../../../../assets/icons/components/save.svg" class="menu-icon" alt="Zapisz" />
                        <span class="menu-text">Zapisz</span>
                    </a>
                    <a class="menu-item" @click="allPresences">
                        <span class="menu-text">Wszyscy obecni</span>
                    </a>
                    <a class="menu-item" @click="allAbsent">
                        <span class="menu-text">Wszyscy nieobecni</span>
                    </a>
                </section>
                <section class="check-quorum-container__header__action">
                    <button class="check-quorum-container__header__action__exit-btn"
                            @click="closeModal">
                        <img src="../../../../assets/icons/components/exit-btn.svg" />
                    </button> 
                </section>
            </header>
            <section class="check-quorum-container__title">
                <span class="modal-title">
                    Sprawdzanie kworum
                </span>
            </section>
            <section class="check-quorum-container__body">
                <div class="user-quorum-container">
                    <div class="user-quorum-container__item" v-for="(item, index) in internalPresencesData" :key="index" :class="getQuorumUserClass(item, $store.state.settings.client.interactiveModalQuorumShowAvatar)" @click="changeUserPresence(item)">
                        <div class="user-quorum-container__item__avatar" v-if="$store.state.settings.client.interactiveModalQuorumShowAvatar">
                            <img :src="getUserAvata(item.id)" :alt="`${item.surname} ${item.name}`" />
                        </div>
                        <div class="user-quorum-container__item__data">
                            <span class="username">{{item.surname}} {{item.name}}</span>
                        </div>
                        <SmallLoader v-if="item.isBusy" :isTiny=true />
                    </div>
                </div>
            </section>
            <section class="check-quorum-container__footer">
                <div class="check-quorum-container__footer__presence">
                    <div class="presence-type">
                        Obecni: 
                    </div>
                    <div class="presence-count">
                        {{internalPresencesData.filter(obj => {return obj.isPresence}).length}}
                    </div>
                </div>
                <div class="check-quorum-container__footer__absent">
                    <div class="presence-type">
                        Nieobecni:
                    </div>
                    <div class="presence-count">
                        {{internalPresencesData.filter(obj => {return !obj.isPresence}).length}}
                    </div>
                </div>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    import CommonHelper from '@/helpers/common.helper.js';
    import { debounce } from "../../../../helpers/utils";
    import interactiveService from "../../../../services/interactive.service";
    import SmallLoader from "../../../BasicControls/SmallLoader.vue";
    import AlertHelper from '@/helpers/alerts.helper.js';

    export default {
        name: "CheckQorumModal",
        components: {
            SmallLoader
        },
        computed: {
            sessionId() {
                return parseInt(this.$route.params.sessionid);
            },
            isCheckQuorumStart() {
                return this.$store.state.interactiveStore.isShowCheckQourum ?? false;
            }
        },
        props: {
            presencesData: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                internalPresencesData: [],
                cyclicQueryFlag: false,
                offset: 1, // wartość w sekundach, określa co ile sekund ma nastąpić odpytanie
                avatarVersion: CommonHelper.getRandomVersionImageForUrlRefresh(0, 100)
            }
        },
        emits: [
            'saveUserPresents'
        ],
        watch: {
            cyclicQueryFlag: debounce(async function () {
                try {
                    if (this.isCheckQuorumStart) {
                        await this.getQuorumResultChange();
                        this.cyclicQueryFlag = !this.cyclicQueryFlag; // negujemy stan żeby ponownie wyzwolić debouce 
                    }
                }
                catch (e) {
                    console.log(e);
                }
            }, 1000)
        },

        async mounted() {
            await this.intialize();
        },
        methods: {
            async getQuorumResultChange() {
                try {
                    var data = { sessionId: this.sessionId };
                    var result = await interactiveService.quorumResultsChanged(data);

                    if (result.status === 200) {
                        this.qorumResultChanged(result.data);
                    }

                } catch (e) {
                    console.log(e);
                }
            },
            async intialize() {
                try {
                    this.$store.commit("showLoader");
                    if (this.presencesData === null)
                        return;

                    var data = { sessionId: this.sessionId };

                    await interactiveService.startCheckQuorum(data);

                    this.cyclicQueryFlag = !this.cyclicQueryFlag;
                    this.internalPresencesData = this.presencesData.filter(obj => {
                        obj.isPresence = false;
                        return true;
                    });
                }
                catch {
                    var t = this.$swal.mixin(AlertHelper.errorToastElement());
                    t.fire(AlertHelper.errorToastAlert('Błąd otwarcia okna sprawdzenia kworum'));
                    this.closeModal();
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            async saveQuorumResults() {
                await this.$emit('saveUserPresents', this.internalPresencesData);
                this.internalPresencesData = [];
            },
            async closeModal() {
                try {
                    this.$store.commit("showLoader");
                    var data = { sessionId: this.sessionId };
                    await interactiveService.stopCheckQuorum(data);
                    this.internalPresencesData = [];
                    this.$emit("closePresenceModal");
                    this.$store.commit('hideCheckQourum');
                }
                catch (e) {
                    console.log(e)
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            getUserAvata(uid) {
                return `https://api.esesja.pl/users/${uid}.jpg${this.avatarVersion}`;
            },

            async changeUserPresence(obj) {
                try {
                    obj.isBusy = true;
                    if (obj.isPresence === true) {
                        await interactiveService.deleteUserPresence(this.sessionId, obj.id)
                    }
                    else {
                        await interactiveService.setUserPresence(this.sessionId, obj.id)
                    }

                    await this.getQuorumResultChange();
                }
                catch (e) {

                    console.log(e);
                }
                finally {
                    obj.isBusy = false;
                }
            },
            async allPresences(){
                this.internalPresencesData = this.internalPresencesData.filter(obj => {
                    obj.isPresence = true;
                    return true;
                })

                await this.checkIfAllUserRequestedPresents();
            },
            async allAbsent(){
                this.internalPresencesData = this.internalPresencesData.filter(obj => {
                    obj.isPresence = false;
                    return true;
                })

                await this.checkIfAllUserRequestedNotPresents();
            },

            async checkIfAllUserRequestedNotPresents() {
                try {
                    let localCopy = [...this.internalPresencesData];
                    let leftUsers = localCopy.filter(item => item.isPresence == true);

                    if (leftUsers.length === 0) {
                        await this.saveQuorumResults();
                    }
                }
                catch (e) {
                    console.log(e);
                }
            },

            async checkIfAllUserRequestedPresents() {
                try {
                    let localCopy = [...this.internalPresencesData];
                    let leftUsers = localCopy.filter(item => item.isPresence == false);

                    if (leftUsers.length === 0) {
                        await this.saveQuorumResults();
                    }
                }
                catch (e) {
                    console.log(e);
                }
            },

            qorumResultChanged(data) {
                this.internalPresencesData = data;
                this.checkIfAllUserRequestedPresents();
            },

            getQuorumUserClass(item, showAvatar = true) {

                let classes = ""

                if (item === null)
                    classes = "";

                if (item.isPresence) {
                    classes = "user-presence";
                }

                if (!showAvatar) {
                    classes += ' no-avatar'
                }
                return classes;
            },
        }
    }
</script>
<style lang="scss" scoped>
    .check-quourm-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        overflow: hidden;
        z-index: 9;

        .check-quorum-container {
            background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
            border-radius: 12px;
            max-height: 90vh;
            padding-bottom: 0px;
            padding-right: 0px;
            width: 90vw;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__menu {

                    .menu-item {
                        display: flex;
                        column-gap: 10px;
                        align-items: center;
                        float: left;
                        font-size: 16px;
                        color: #000;
                        text-align: center;
                        padding: 19px 10px 19px 10px;
                        text-decoration: none;
                        cursor: pointer;
                        transition: all 0.2s ease-in-out;

                        .menu-icon {
                            height: 20px;
                        }

                        .menu-text {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 132%;
                        }

                        &:hover {
                            background-color: #e8ebef;
                        }
                    }
                }

                &__action {
                    &__exit-btn {
                        border: 0px;
                        background-color: transparent;
                        position: relative;
                        right: calc(-100% + 48px);
                    }
                }
            }

            &__title {
                display: flex;
                justify-content: center;
                font-size: 30px;
            }

            &__body {
                max-height: 65vh;
                overflow-y: auto;
                padding: 15px;

                &::-webkit-scrollbar {
                    width: 12px;
                    height: 6px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #b5b8bd;
                    border-radius: 30px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: #b5b8bd;
                }

                &::-webkit-scrollbar-track {
                    background: #ffffff;
                    border: 0.8px solid #dce4ee;
                    border-radius: 30px;
                    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
                }

                .user-quorum-container {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 10px;
                    row-gap: 10px;
                    justify-content: center;

                    &__item {
                        width: 250px;
                        display: flex;
                        column-gap: 10px;
                        cursor: pointer;
                        align-items: center;
                        background: #f2f2f2;
                        transition: 0.1s;
                        position: relative;

                        &__avatar img {
                            width: 70px;
                            vertical-align: middle;
                        }

                        &__data .username {
                            font-size: 20px;
                        }

                        &:hover {
                            background: #ddd;
                            box-shadow: 0px 0px 10px 5px #b1b1b1;
                        }
                    }

                    &__item.user-presence {
                        background: #caffc1;
                    }
                }

                .no-avatar {
                    padding: 5px 10px;
                    min-height: 64px;
                    width: 180px;
                }
            }

            &__footer {
                display: flex;
                justify-content: center;
                column-gap: 20px;
                font-size: 25px;
                padding: 10px 0px;
                border-top: 1px solid #dde0e7;
                ;

                &__presence, &__absent {
                    display: flex;
                    column-gap: 10px;
                }

                .presence-count {
                    font-weight: bold;
                }
            }
        }
    }
</style>