<template>
    <section class="image-tooltip">
            <img :src="imgContent" style="width: 60%; height: 60%;" />
    </section>
</template>

<script>
    export default {
        components: {

        },
        data() {
            return {

            }
        },
        props: {
            imgContent: {
                type: String
            },
        },
        methods: {

        }
    };
</script>

<style lang="scss" scoped>
    .image-tooltip {
        position: absolute;
        margin-left: 250px;
        z-index: 200;
    }
</style>