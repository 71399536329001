import * as CryptoJS from 'crypto-js';

class EncryptionDecryption {
    key = CryptoJS.enc.Utf8.parse("ee2d1c1ade074f04");
    iv = CryptoJS.enc.Utf8.parse("15ssd586ortans5p");

    Decryption(text) {
        var decrypted = CryptoJS.AES.decrypt(text, this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    Encription(text) {
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }
}

export default new EncryptionDecryption();