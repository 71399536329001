<template>
    <section class="esesja-search-component-text">
        <input type="text"
               v-model="searchInput"
               :placeholder="placeholder"
               :disabled="disabled"
               :style="inputWidth"
               ref="searchInputRef"
               @blur="$emit('blured')"
               @focus="$emit('focused')"
               class="esesja-search-component-text__input" 
               :class="{'esesja-search-component-text__input__border-right': !withButton}"/>
        <button class="esesja-search-component-text__button" v-if="withButton">
            <img src="~@/assets/icons/panel/Contacts/search.svg"
                 class="esesja-search-component-text__icon" />
        </button>
    </section>
</template>
<script charset="utf-8">
    import { debounce } from "../../helpers/utils";

    export default {
        name: "SearchInputTextComponent",
        emits: ["focused", "blured"],
        props: {
            modelValue: {
                type: String,
                default: '',
                required: true
            },
            placeholder: {
                type: String,
                default() {
                    return ""
                }
            },
            disabled: {
                type: Boolean,
                default() {
                    return false
                }
            },
            width: {
                type: String,
                default: "326"
            },
            withButton: {
                type: Boolean,
                default: true,
            }
        },
        data() {
            return {
                searchInput: '',
            }
        },
        computed: {
            inputWidth() {
                if (this.width === "100%")
                    return `width: 100%`;

                return `width: ${this.width}px`
            }
        },
        watch:{
            searchInput: debounce(function () {
                this.$emit('update:modelValue', this.searchInput)
            }, 350),
            modelValue: {
                handler(newValue) {
                    this.searchInput = newValue;
                }
            },
        },
        mounted(){
            this.searchInput = this.modelValue;
        }
    }
</script>
<style lang="scss" scoped>
    .esesja-search-component-text {
        display: flex;
        align-items: center;

        &__input {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            box-sizing: border-box;
            padding: 12.5px 16px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;

            &:focus {
                border: 1px solid #00082c;
            }

            &__border-right {
                border: 1px solid #d1d6df;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px
            }
        }

        &__button {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left: 0px;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 32px;
                background: #e2e5eb;
                top: 8px;
                left: 0px;
            }
        }

        &__icon {
            padding: 14px;
        }
    }
</style>


