<template>
    <ul class="nav-container">
        <h3 class="nav-container__title">{{ blockTitle }}</h3>
        <li class="nav_container__link" v-for="link in links" :key="link.id">
            <NavLink
                :name="link.name"
                :abbr="link.abbr"
                :link="link.link"
                :routerName="link.routerName"
                :icon="link.icon_src"
                :additionalMenu="link.additionalMenu"
                :linkid="link.id"
            />
        </li>
    </ul>
</template>

<script>
    import NavLink from "./NavLink.vue";
export default {
    name: "NavBlock",   
    props: {
        blockTitle: { type: String, value: "" },
        links: { type: Array, value: [] },
        additionalMenu: Array,
    },
    components: {
        NavLink,
    },
    methods: {
        
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.nav-container {
    margin-bottom: 13px;
    padding: 0px;
    list-style: none;
    &__title {
        color: $navHeader;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 120%;
        margin-left: 32px;
        margin-bottom: 16px;
        letter-spacing: 0.02em;

        @media only screen and (max-width:1600px) {
            margin-bottom: 8px;
            margin-left: 19px;
        }
    }
}


    .collapsed-menu .nav-container {
        &__title {
            display: none;
        }
    }

</style>
