import { toolsModalStore } from "./modals/toolsModalsStore"
import { settingsModalsStore } from "./modals/settingsModalsStore"
import { sessionsModalStore } from "./modals/sessionsModalsStore"

export const modalsStore = {
    modules: {
        tools: toolsModalStore,
        sessions: sessionsModalStore,
        settings: settingsModalsStore
    }
}