<template>
    <section class="additional-menu">
        <ul class="additional-menu__groups" v-if="canSession().length > 0">
            <li v-for="(group, key) in canSession()"
                :key="key"
                class="additional-menu__link-wrapper"
                @click="goToRegisterPage(group.id)"
                :ref="`mainContainer${key}`"
                @mouseover="
                ()=>
                setMenuElementPosition(
                this.$refs[`mainContainer${key}`],
                this.$refs[`subMenu${key}`]
                )
                "
                >
                <span class="additional-menu__link">{{ group.registry_name }}</span>
            </li>
        </ul>
        <p v-else class="sessions__no-data">Brak danych</p>
    </section>
</template>

<script charset="utf-8">
/*import MeetingService from "../../../services/meetings.service";*/
import setMenuElementPosition from "../../../helpers/setMenuElementPosition";

export default {
    name: "AdditionalMenuRegister",
    data() {
        return {
            isLoading: true,
        };
    },
    components: {
    },
        mounted() {
        
        //MeetingService.groups(false).then(() => {
        //    this.isLoading = false;
        //});
    },
    methods: {
        canSession() {
            return this.$store.state.appStore.groups.filter(
                (group) => group.can_registry
            );
        },
        setMenuElementPosition,
        goToRegisterPage(id) {
            this.$router.push({ name: 'rejestr', query: { group: id, page: 1, itemsPerPage: 20 }});
        }
    },
};</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/additional-menu.scss";

    .additional-menu {
        margin-top: 0px !important;
    }


</style>
