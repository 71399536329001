<template>
    <ul class="action-list" ref="list">
        <router-link
            :to="{ name: action.action, params: { sessionid: sessionId, groupid: this.groupID }, }"
            v-for="action in checkActionsIsEnabled()"
            :key="action.id"
        >
            <li class="action-list__item" @click="action.handler(sessionId)" >
                <img class="action-list__img" :src="require(`@/assets/icons/meetingsActions/${action.icon}.svg`)"/>
                <span class="action-list__name">{{ action.name }}</span>
            </li>
        </router-link>
    </ul>
</template>

<script>
import { meetingsActions } from "@/componentsData/meetingsActions.js";
import { isOnScreen } from "@/helpers/isOnScreen";
import { encryptStorage } from '@/services/encrypt.service';

export default {
    name: "MenuActions",
    data() {
        return {
            actions: [],
            bipModule: encryptStorage.getItem('modules').bip,
            camModule: encryptStorage.getItem('modules').cam,
        };
    },
    props: {
        sessionId: Number,
        groupID: Number,
        sessionLocked: Boolean,
    },
    mounted() {
        isOnScreen(this.$refs.list);
        //this.checkActionsIsEnabled();
        //console.log("mount")
    },
    methods: {
        checkActionsIsEnabled() {
            var actions = [];
            for (var i = 0; i < meetingsActions.length; i++) {
                if (meetingsActions[i].id == 95) {
                    if (this.bipModule == 1 && this.sessionLocked !== true) {
                        actions.push(meetingsActions[i]);
                    }
                }
                else {
                    if (this.sessionLocked == false) {

                        switch (parseInt(meetingsActions[i].id)) {
                            case 0:
                            case 10:
                            case 40:
                            case 50:
                            case 60:
                            case 64:
                            case 65:
                            case 67:
                            case 69:
                            case 70:
                            case 75:
                            case 90:
                            case 99:
                            case 100:
                                actions.push(meetingsActions[i]);
                                break;
                            default:
                                break;
                        }
                    }
                    else {

                       switch (parseInt(meetingsActions[i].id)) {
                            case 50:
                            case 70:
                            case 90:
                            case 98:
                            case 100:
                                actions.push(meetingsActions[i]);
                                break;
                            default:
                                break;
                       }
                    }
                }
            }

            return actions;
        }
    },
};
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/meeting-action-menu.scss";

    .borderline {
        border-top: 1px solid rgb(217, 217, 217);
    }

</style>
