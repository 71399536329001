<template>
    <section class="points-list__attachments" :style="checkPointElements(item) == true ? 'padding-top: 10px;' : ''">
        <span v-if="item.description != null && item.description.length > 0" class="points-list__referer">Dotyczy: <b>{{item.description.trim() + ' '}}</b></span>
        <span v-if="item.applicant_name != null && item.applicant_name.length > 0" class="points-list__referer">Wnioskodawca: <b>{{item.applicant_name + ' '}}</b></span>
        <span v-if="item.reporter_name != null && item.reporter_name.length > 0" class="points-list__referer">Referuje: <b>{{item.reporter_name + ' '}}</b></span>
        <span v-if="item.groups_opinion != null && item.groups_opinion.length > 0" class="points-list__referer">Opiniują: <b>{{getOpinionGroups(item.groups_opinion)}}</b></span>
    </section>
</template>

<script>
    export default {
        name: "PointInfoPart",
        props: {
            item: Object,
        },
        data() {
            return {
            };
        },
        methods: {
            checkPointElements(item) {
                if ((item.description != null && item.description.length > 0) ||
                    (item.applicant_name != null && item.applicant_name.length > 0) ||
                    (item.reporter_name != null && item.reporter_name.length > 0) ||
                    (item.groups_opinion != null && item.groups_opinion.length > 0)) {
                    return true;
                }

                return false;
            },
            getOpinionGroups(groups) {
                var output = '';
                if (groups != null && groups.length > 0) {
                    for (var i = 0; i < groups.length; i++) {
                        output += groups[i].name + ", ";
                    }
                    output = output.replace(/,\s*$/, "");
                }

                return output;
            },
        }
    };
</script>

<style lang="scss" scoped>
    .points-list {
        width: 100%;
        position: relative;

        &__referer {
            font-weight: normal;
            font-size: 14px;
            color: #333956;
            padding-top: 10px;
        }
    }
</style>
