import ApiService from "./api.service";

class PublicService extends ApiService {
    getPublicSession(code) {
        return this.get(`PublicActions/publicAgendaList/${code}`);
    }

    getFile(ssoToken) {
        return this.get(`PublicActions/publicGetFile?file=${ssoToken}`, { responseType: "arraybuffer" });
    }

    getDataForVotingPublic(ssoToken) {
        return this.get(`PublicActions/votingVisualisation?tk=${ssoToken}`);
    }

    getPublicSessionInfo(code) {
        return this.get(`PublicActions/publicAgendaListInfo/${code}`);
    }

    downloadAttachmentPublicInfo(data) {
        return this.post(`PublicActions/downloadAttachment`, data, { responseType: "arraybuffer" });
    }

    getVotingInteractiveDataForResults(data) {
        return this.post(`PublicActions/getVotingInteractiveDataForResultsPublic`, data)
    }

    getVotingsSummaryResults(data) {
        return this.post(`PublicActions/getVotingsSummaryResultsPublic`, data)
    }
}

export default new PublicService();
