export const setErrors = (errors) => {
    const errorsList = [];
    for (const [key, value] of Object.entries(errors)) {
        const data = {
            key: key,
            msgs: value,
        };
        errorsList.push(data);
    }
    return errorsList;
};
export const isError = (key, errors) => {
    if (errors.length > 0) {
        for (const error of errors) {
            if (error.key === key) {
                return true;
            }
        }
    }
    return false;
};
export const passErrorMsg = (key, errors) => {
    const error = errors.find((error) => {
        if (error.key === key) {
            return error;
        }
    });
    return error.msgs;
};
