<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Edycja typu wykresu</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); $store.commit('setChangeChartTypeModalStatus', false);}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section class="column">
                    <section class="column-item in-row">
                        Wybierz typ wykresu dla prezentacji wyników w przeprowadzonych głosowaniach
                    </section>
                    <section class="column-item in-row">
                        <section class="esesja-modal__row">
                            <div class="form-bootstrap-check form-bootstrap-switch">
                                <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="bar-chart" v-model="barChart" @click="changeSelectedChartType(0)" />
                            </div>
                        </section>
                        <label class="esesja-modal__wraper__body__label">Wykres słupkowy</label>
                    </section>

                    <section class="column-item in-row">
                        <section class="esesja-modal__row">
                            <div class="form-bootstrap-check form-bootstrap-switch">
                                <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="bar-horizontal-chart" v-model="barHorizontalChart" @click="changeSelectedChartType(3)" />
                            </div>
                        </section>
                        <label class="esesja-modal__wraper__body__label">Wykres słupkowy poziomy</label>
                    </section>

                    <section class="column-item in-row">
                        <section class="esesja-modal__row">
                            <div class="form-bootstrap-check form-bootstrap-switch">
                                <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="pie-chart" v-model="pieChart" @click="changeSelectedChartType(1)" />
                            </div>
                        </section>
                        <label class="esesja-modal__wraper__body__label">Wykres kołowy</label>
                    </section>

                    <section class="column-item in-row">
                        <section class="esesja-modal__row">
                            <div class="form-bootstrap-check form-bootstrap-switch">
                                <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="donat-chart" v-model="donatChart" @click="changeSelectedChartType(2)" />
                            </div>
                        </section>
                        <label class="esesja-modal__wraper__body__label">Wykres pierścieniowy</label>
                    </section>

                    <section class="column-item in-row">
                        <section class="esesja-modal__row">
                            <div class="form-bootstrap-check form-bootstrap-switch">
                                <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="not-chart" v-model="notChart" @click="changeSelectedChartType(4)" />
                            </div>
                        </section>
                        <label class="esesja-modal__wraper__body__label">Bez wykresu</label>
                    </section>

                </section>
                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="$store.commit('setChangeChartTypeModalStatus', false);"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="updateAccountSettings" :disabled="checkSelected()">Zapisz</button>
                </section>
            </perfect-scrollbar>
        </section>
        
    </section>
</template>
<script charset="utf-8">
    import SettingsService from "../../../../../../services/settings.service";

    export default {
        name: "ChangeChartTypeModal",
        props: {
            type: Number
        },
        data() {
            return {
                barChart: false,
                barHorizontalChart: false,
                pieChart: false,
                donatChart: false,
                notChart: false,
                selected: 0,
            }
        },
        mounted() {
            this.checkSelectedChart(this.type);
        },
        methods: {
            checkSelected() {
                return this.barChart == false && this.pieChart == false && this.donatChart == false && this.notChart == false && this.barHorizontalChart == false;
            },
            changeSelectedChartType(type) {
                this.checkSelectedChart(type);
            },
            checkSelectedChart(type) {
                switch (type) {
                    case 0:
                        this.barChart = true;
                        this.barHorizontalChart = false;
                        this.pieChart = false;
                        this.donatChart = false;
                        this.notChart = false;
                        this.selected = 0;
                        break;
                    case 1:
                        this.barChart = false;
                        this.barHorizontalChart = false;
                        this.pieChart = true;
                        this.donatChart = false;
                        this.notChart = false;
                        this.selected = 1;
                        break;
                    case 2:
                        this.barChart = false;
                        this.barHorizontalChart = false;
                        this.pieChart = false;
                        this.donatChart = true;
                        this.notChart = false;
                        this.selected = 2;
                        break;
                    case 3:
                        this.barChart = false;
                        this.barHorizontalChart = true;
                        this.pieChart = false;
                        this.donatChart = false;
                        this.notChart = false;
                        this.selected = 3;
                        break;
                    case 4:
                        this.barChart = false;
                        this.barHorizontalChart = false;
                        this.pieChart = false;
                        this.donatChart = false;
                        this.notChart = true;
                        this.selected = 4;
                        break;
                    default:
                        break;
                }
            },
            async updateAccountSettings(){
                try {
                    this.$store.commit("showLoader");

                    var data = {
                        chartType: this.selected
                    };

                    let response = await SettingsService.setVotingChartType(data);

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.$emit("savechangeChartType", this.selected);
                    
                    this.$store.commit('setChangeChartTypeModalStatus', false);
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'ChangeChartTypeModal' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/esesja-modal.scss';

    .in-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 24px;
    }

    .in-row .esesja-modal__row {
        margin-bottom: 0px;
        margin-right: 10px;
    }
</style>