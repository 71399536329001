<template>
    <section class="d-flex flex-row justify-content-between pb-3" style="padding: 0px !important;">
        <section class="esesja-modal__wraper__body__label info">
            <p class="info-elem" v-tooltip="'Ustawiona nazwa nadawcy w wiadomości e-mail'">Nazwa nadawcy: <b>{{this.$store.state.userStore.settings.userEmailSenderName}}</b></p>
            <p class="info-elem" v-tooltip="'Ustawiony zwrotny adres e-mail dla wiadomości'">Zwrotny adres e-mail: <b>{{this.$store.state.userStore.settings.userReplyToEmail}}</b></p>
        </section>
    </section>
    
</template>
<script charset="utf-8">
    export default {
        name: "UserAccountEmailSettingsInfo",
        methods: {

        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/esesja-modal.scss';
    .info {
        font-weight: 400 !important;
        font-size: 12px !important;
        margin: 0px !important;
        padding-bottom: 10px !important;
    }

    .info-elem {
        cursor: default;
        margin: 0px !important;
        text-align: left;
    }

</style>