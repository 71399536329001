<template>
    <button 
            class="button-only-icon"
            :class="{ 'button-only-icon--red': classModified }" :disabled="disable">
        <img
            :src="require(`../../assets/icons/components/${icon}.svg`)"
            class="button-only-icon__icon"
        />
    </button>
</template>

<script>
export default {
    name: "ButtonOnlyWithIcon",
    props: {
        icon: String,
        disable: {
            type: Boolean, 
            default: false,
        },
        classModified: Boolean,
    },
};
</script>

<style lang="scss" scoped>
    .button-only-icon {
        width: 32px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;

        &:hover:not([disabled]) {
            background-color: #e8ebef;
        }

        &--red {
            background-color: #d80418;
            color: #ffffff;
            border: 0px;

            &:hover:not([disabled]) {
                background-color: #ad0313;
            }

            .button-only-icon__icon {
                filter: invert(100%)
            }
        }
    }
</style>
