<template>
    <section class="edit-point-bottom-menu">
        <section class="edit-point-bottom-menu__left">
            <section class="edit-point-bottom-menu__actions-wrapper">
                <section class="edit-point-bottom-menu__checkbox-wrapper">
                    <input type="checkbox"
                           class="edit-point-bottom-menu__input-checkbox"
                           id="check-all" />
                    <section class="edit-point-bottom-menu__checkbox-mark">
                        <img src="../../../../assets/icons/panel/editSession/line.svg"
                             class="edit-point-bottom-menu__checkbox-icon" />
                    </section>
                </section>
                <label class="edit-point-bottom-menu__picked-text"
                       for="check-all"
                       @click="handlePickedClick">
                    Elementy:
                    <span class="
                            edit-point-bottom-menu__picked-text
                            edit-point-bottom-menu__picked-text--bold
                        ">{{ point_ids.length }}</span>
                </label>
            </section>
            <section class="edit-point-bottom-menu__actions-wrapper"
                     v-if="point_ids.length === 1">
                <button class="edit-point-bottom-menu__action-btn"
                        @click="duplicatePoint">
                    <img src="../../../../assets/icons/panel/editSession/duplicate.svg"
                         class="edit-point-bottom-menu__action-icon" />
                    <span class="edit-point-bottom-menu__action-name">Powiel</span>
                </button>

                <button v-if="!isTemplate"
                        class="edit-point-bottom-menu__action-btn"
                        @click="copyPoint">
                    <img src="../../../../assets/icons/panel/editSession/duplicate.svg"
                         class="edit-point-bottom-menu__action-icon" />
                    <span class="edit-point-bottom-menu__action-name">Kopiuj</span>
                </button>
                <button class="edit-point-bottom-menu__action-btn"
                        @click="addPointAsChildren"
                        v-if="
                        getPointNestedLevel(
                        this.agendaItems,
                        this.point_ids[0]
                        ) < 1
                    "
                >
                    <img
                        src="../../../../assets/icons/panel/editSession/duplicate.svg"
                        class="edit-point-bottom-menu__action-icon"
                    />
                    <span class="edit-point-bottom-menu__action-name"
                        >Dodaj podpunkt</span
                    >
                </button>
                <button class="edit-point-bottom-menu__action-btn"
                        @click="setPickedPointData">
                    <img src="../../../../assets/icons/panel/editSession/edit.svg"
                         class="edit-point-bottom-menu__action-icon" />
                    <span class="edit-point-bottom-menu__action-name">Edytuj</span>
                </button>
                
            </section>
            <section class="edit-point-bottom-menu__actions-wrapper"
                     v-if="point_ids.length === 1">

                <button class="
                        edit-point-bottom-menu__action-btn
                        edit-point-bottom-menu__action-btn--arrow
                        "
                        @click="moveLeft"
                        v-if="canMoveLeft">
                    <img src="../../../../assets/icons/panel/editSession/arrow-left.svg"
                         class="
                            edit-point-bottom-menu__action-icon
                            edit-point-bottom-menu__action-icon--big
                        " />
                </button>

                <button v-if="canMoveUp"
                        class="
                        edit-point-bottom-menu__action-btn
                        edit-point-bottom-menu__action-btn--arrow
                    "
                        @click="moveUp">
                    <img src="../../../../assets/icons/panel/editSession/arrow-top.svg"
                         class="
                            edit-point-bottom-menu__action-icon
                            edit-point-bottom-menu__action-icon--big
                        " />
                </button>
                <button v-if="canMoveDown"
                        class="
                        edit-point-bottom-menu__action-btn
                        edit-point-bottom-menu__action-btn--arrow
                    "
                        @click="moveDown">
                    <img src="../../../../assets/icons/panel/editSession/arrow-bottom.svg"
                         class="
                            edit-point-bottom-menu__action-icon
                            edit-point-bottom-menu__action-icon--big
                        " />
                </button>
                <button v-if="canMoveUp || canMoveDown" class="edit-point-bottom-menu__action-btn edit-point-bottom-menu__action-btn--arrow" @click="setPointSpecyficPlace">
                    <img src="../../../../assets/icons/panel/editSession/up-down.svg"
                         class="edit-point-bottom-menu__action-icon edit-point-bottom-menu__action-icon--big" />
                </button>
            </section>
        </section>
        <section class="edit-point-bottom-menu__right">

            <button v-if="canHidePoints" class="edit-point-bottom-menu__action-btn" @click="hidePoints">
                <img src="@/assets/icons/components/eye.svg" class="edit-point-bottom-menu__action-icon" />
                <span class="edit-point-bottom-menu__action-name">Ukrycie punktów</span>
            </button>

            <button v-if="canUnhidePoints" class="edit-point-bottom-menu__action-btn" @click="unhidePoints">
                <img src="@/assets/icons/components/eye.svg" class="edit-point-bottom-menu__action-icon" />
                <span class="edit-point-bottom-menu__action-name">Cofnij ukrycie punktów</span>
            </button>

            <button v-if="canTakeOff" class="edit-point-bottom-menu__action-btn" @click="takenOfPoints">
                <img src="@/assets/icons/components/exit.svg" class="edit-point-bottom-menu__action-icon" />
                <span class="edit-point-bottom-menu__action-name">Zdejmij punkty z porządku</span>
            </button>

            <button v-if="canUntakeOff" class="edit-point-bottom-menu__action-btn" @click="untakenOfPoints">
                <img src="@/assets/icons/components/exit.svg" class="edit-point-bottom-menu__action-icon" />
                <span class="edit-point-bottom-menu__action-name">Przwróć punkty do porządku obrad</span>
            </button>

            <button v-if="!isTemplate && point_ids.length > 1" class="edit-point-bottom-menu__action-btn" @click="copyPoint">
                <img src="../../../../assets/icons/panel/editSession/duplicate.svg" class="edit-point-bottom-menu__action-icon" />
                <span class="edit-point-bottom-menu__action-name">Kopiuj punkty</span>
            </button>

            <button v-if="!isTemplate && point_ids.length > 1" class="edit-point-bottom-menu__action-btn" @click="addVotingToMultiplePoints">
                <img src="../../../../assets/icons/components/voting-add.svg" class="edit-point-bottom-menu__action-icon" />
                <span class="edit-point-bottom-menu__action-name">Utwórz głosowania</span>
            </button>

            <button class="edit-point-bottom-menu__action-btn"
                    @click="this.$emit('cancel')">
                <img src="../../../../assets/icons/panel/editSession/cancel.svg"
                     class="edit-point-bottom-menu__action-icon" /><span class="edit-point-bottom-menu__action-name">Anuluj</span>
            </button>
            <button class="edit-point-bottom-menu__action-btn edit-point-bottom-menu__action-btn--red"
                    @click="deletePoints"
                    v-tooltip="'Usuń zaznaczone punkty'">
                <img src="../../../../assets/icons/components/bin.svg"
                     class="
                        edit-point-bottom-menu__action-icon
                        edit-point-bottom-menu__action-icon--white
                    " />
            </button>
        </section>
        <EditPoint v-if="isEditVisible"
                   @closeEdit="isEditVisible = false"
                   @reloadData="
                   ()=>
            {
            this.reloadPickedPoint();
            this.$emit('reloadData');
            }
            "
            @refreshList="
            () => {
            this.reloadPickedPoint();
            this.$emit('refreshList');
            }
            "
            :pickedPoint="pointToDoAction"
            />

            <MovePointModal v-if="isMovePointModalVisible" 
                            :pointList="tempPointList" 
                            :pointToMove="pointToDoAction"
                            :firstLevel="firstLevel"
                            :secondLevel="secondLevel"
                            :thirdLevel="thirdLevel"
                            :sid="tempSid"
                            @reload="() => { this.$emit('reloadData');}"
                            @closeMovePointModal="() => { this.isMovePointModalVisible = false; this.tempPointList = []; }" />

    </section>
</template>

<script charset="utf-8">
    import SessionService from "../../../../services/session.service";
    import { encryptStorage } from '../../../../services/encrypt.service';
    import EditPoint from "./EditPointModal/EditPoint.vue";
    import MovePointModal from "./Modals/MovePointModal.vue";
    import getPointNestedLevel from "../../../../helpers/getPointNestedLevel";
import sessionService from "../../../../services/session.service";

    export default {
        name: "EditPointsBottomMenu",
        emits: ["refreshList", "cancel", "refreshSessionVotings", "addVotingToMultiplePointsEmit", "checkAll", "showCopySessionPoint", "pointMoved", "reloadData", "addPointAsChildren"],        
        data() {
            return {
                checkedIds: [],
                exportPrivilige: encryptStorage.getItem('priv').eksport,
                isEditVisible: false,
                isMovePointModalVisible: false,
                pointToDoAction: {},
                pointParent: {},
                tempPointList: [],
                selectedPoints: [],
                tempSid: parseInt(this.$route.params.sessionid),
            };
        },
        computed: {
            canMoveLeft() {
                this.searchForItemRecurective(this.agendaItems);
                return this.pointToDoAction.depth > 0;
            },
            canMoveUp() {
                this.searchForItemRecurective(this.agendaItems);
                if ((this.pointParent === undefined || this.pointParent === null) && this.agendaItems.length > 1) {
                    return true;
                }

                if (this.pointParent === undefined || this.pointParent === null || this.pointParent.children === undefined || this.pointParent.children === null || this.pointParent.children.length === 0) {
                    return false;
                }

                if (this.pointParent === undefined || this.pointParent === null || this.pointParent.children[0].id === this.pointToDoAction.id) {
                    return false;
                }
                return true;
            },
            canMoveDown() {
                this.searchForItemRecurective(this.agendaItems);
                if ((this.pointParent === undefined || this.pointParent === null) && this.agendaItems.length > 1) {
                    return true;
                }

                if (this.pointParent === undefined || this.pointParent === null || this.pointParent.children === undefined || this.pointParent.children === null || this.pointParent.children.length === 0) {
                    return false;
                }

                if (this.pointParent === undefined || this.pointParent === null || this.pointParent.children[this.pointParent.children.length - 1].id === this.pointToDoAction.id) {
                    return false;
                }
                return true;
            },

            canHidePoints(){
                if(this.isTemplate){
                    return false;
                }

                if(this.point_ids === undefined || this.point_ids == null || this.point_ids.length <= 1){
                    return false;
                }

                const hiddenPoints = this.selectedPoints.filter(item => item.is_hidden);

                if(hiddenPoints && hiddenPoints.length > 0){
                    return false;
                }

                return true;
            },

            canUnhidePoints(){
                if(this.isTemplate){
                    return false;
                }

                if(this.point_ids === undefined || this.point_ids == null || this.point_ids.length <= 1){
                    return false;
                }

                const hiddenPoints = this.selectedPoints.filter(item => item.is_hidden === false);

                if(hiddenPoints && hiddenPoints.length > 0){
                    return false;
                }

                return true;
            },

            canTakeOff(){
                if(this.isTemplate){
                    return false;
                }

                if(this.point_ids === undefined || this.point_ids == null || this.point_ids.length <= 1){
                    return false;
                }

                const hiddenPoints = this.selectedPoints.filter(item => item.is_taken_off);

                if(hiddenPoints && hiddenPoints.length > 0){
                    return false;
                }

                return true;
            },

            canUntakeOff(){
                if(this.isTemplate){
                    return false;
                }

                if(this.point_ids === undefined || this.point_ids == null || this.point_ids.length <= 1){
                    return false;
                }

                const hiddenPoints = this.selectedPoints.filter(item => item.is_taken_off === false );

                if(hiddenPoints && hiddenPoints.length > 0){
                    return false;
                }

                return true;
            }
        },
        components: {
            EditPoint,
            MovePointModal,
        },
        props: {
            point_ids: Array,
            agendaItems: Array,
            isTemplate: {
                type: Boolean,
                default: false
            },
            firstLevel: Number,
            secondLevel: Number,
            thirdLevel: Number,
        },
        watch:{
            point_ids:{
                immediate: true,
                deep: true,
                handler(val){
                    this.selectedPoints = this.searchForItemsRecurive([...this.agendaItems], [...val]);
                }
            }
        },
        mounted() {
            this.checkedIds = this.point_ids;
        },
        methods: {
            getItemDepth(arr, searchId) {
                let isFind = arr.some(item => item.id === searchId);
                if (isFind) {
                    let res = arr.filter(item => item.id === searchId)
                    return res[0].depth
                }

                if (arr[0].children.length) {
                    return this.getItemDepth(arr[0].children, searchId);
                }
            },
            async reloadPickedPoint() {
                try{
                    this.$store.commit("showLoader");
                    const response = await SessionService.getPoint(this.pointToDoAction.id, this.$route.params.sessionid);
                    
                    if(response.status === 200){
                        this.pointToDoAction = response.data;
                    }
                }
                finally{
                    this.$store.commit("hideLoader");
                }
            },
            setPickedPointData() {
                this.searchForItemRecurective(this.agendaItems);
                this.isEditVisible = true;
            },
            deletePoints() {
                const actionToFire = () => {
                    this.$store.commit("showLoader");
                    SessionService.removeFewPoints(this.point_ids, this.$route.params.sessionid).then(() => {
                        this.$emit("refreshList");
                        this.$emit("cancel");
                        this.$emit("refreshSessionVotings");
                    });
                };

                this.$store.commit("showConfirmationAlert", actionToFire);
            },
            addVotingToMultiplePoints() {
                this.$emit("addVotingToMultiplePointsEmit", this.point_ids);
            },
            handlePickedClick() {
                this.isAllChecked = !this.isAllChecked;
                if (this.isAllChecked) {
                    this.checkedIds = [];
                    this.setAllPoints(this.agendaItems);
                    this.$emit("checkAll", this.checkedIds);
                } else {
                    this.$emit("cancel");
                }
            },
            setAllPoints(items) {
                return items.map((item) => {
                    this.checkedIds.push(item.id);
                    this.setAllPoints(item.children);
                });
            },
            duplicatePoint() {
                this.$store.commit("showLoader");
                this.searchForItemRecurective(this.agendaItems);
                const sessionId = this.$route.params.sessionid;
                SessionService.duplicatePoints(
                    sessionId,
                    this.pointToDoAction.id,
                    this.agendaItems.length
                ).then(() => {
                    this.$emit("refreshList");
                });
            },
            copyPoint() {
                this.$store.commit("showCopySessionPoint", this.point_ids);
            },
            async moveLeft() {
                try {

                    let response = await SessionService.movePointLeft(this.$route.params.sessionid, this.point_ids[0]);
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.$emit("refreshList");
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sessionTemplate' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
            },
            moveUp() {
                this.searchForItemRecurective(this.agendaItems);
                const arrayAfterMove = this.agendaItems;
                if (this.pointParent === null) {
                    const pointIndex = arrayAfterMove.indexOf(this.pointToDoAction);
                    if (pointIndex > 0) {
                        const swap = arrayAfterMove[pointIndex];
                        arrayAfterMove[pointIndex] = arrayAfterMove[pointIndex - 1];
                        arrayAfterMove[pointIndex - 1] = swap;
                    }
                } else {
                    const pointIndex = this.pointParent.children.indexOf(
                        this.pointToDoAction
                    );
                    if (pointIndex > 0) {
                        const swap = this.pointParent.children[pointIndex];
                        this.pointParent.children[pointIndex] =
                            this.pointParent.children[pointIndex - 1];
                        this.pointParent.children[pointIndex - 1] = swap;
                    }
                }
                this.$emit("pointMoved");
            },
            moveDown() {
                this.searchForItemRecurective(this.agendaItems);
                const arrayAfterMove = this.agendaItems;
                if (this.pointParent === null) {
                    const pointIndex = arrayAfterMove.indexOf(this.pointToDoAction);
                    if (pointIndex < arrayAfterMove.length - 1) {
                        const swap = arrayAfterMove[pointIndex];
                        arrayAfterMove[pointIndex] = arrayAfterMove[pointIndex + 1];
                        arrayAfterMove[pointIndex + 1] = swap;
                    }
                } else {
                    const pointIndex = this.pointParent.children.indexOf(
                        this.pointToDoAction
                    );
                    if (pointIndex < this.pointParent.children.length - 1) {
                        const swap = this.pointParent.children[pointIndex];
                        this.pointParent.children[pointIndex] =
                            this.pointParent.children[pointIndex + 1];
                        this.pointParent.children[pointIndex + 1] = swap;
                    }
                }
                this.$emit("pointMoved");
            },
            setPointSpecyficPlace() {
                this.searchForItemRecurective(this.agendaItems);

                switch (this.pointToDoAction.depth) {
                    case 0: // 1 point lvl
                        this.tempPointList = this.agendaItems;
                        break;
                    case 1: // 2 point lvl
                        var temp = this.agendaItems.find(x => x.id === this.pointParent.id);
                        if (temp != null) {
                            this.tempPointList = temp.children;
                        }

                        break;
                    case 2: // 3 point lvl
                        this.agendaItems.find((x) => {
                            x.children.find((z) => {
                                if (z.id === this.pointParent.id) {
                                    this.tempPointList = z.children;
                                }
                            })
                        });
                        break;
                    default:
                        this.tempPointList = [];
                        break;
                }

                if (this.tempPointList != null && this.tempPointList.length > 1) {
                    this.isMovePointModalVisible = true;
                }
            },
            addPointAsChildren() {
                this.$emit("addPointAsChildren");
            },
            searchForItemRecurective(items, parrent = null) {
                items.find((item) => {
                    if (item.id === this.point_ids[0]) {
                        this.pointToDoAction = item;
                        this.pointParent = parrent;
                    }
                    this.searchForItemRecurective(item.children, item);
                });
            },
            searchForItemsRecurive(items, ids, arr = null){
                if(arr == null){
                    arr = [];
                }

                for(const item of items){
                    if(item.children){
                        this.searchForItemsRecurive(item.children, ids, arr);
                    }

                    if(ids.includes(item.id)){
                        arr.push(item);
                    }
                }
                return arr;
            },
            async hidePoints(){
                try{
                    this.$store.commit("showLoader");

                    await sessionService.hideMultiplePoints(this.$route.params.sessionid, this.point_ids);

                    this.$emit("refreshList");
                    this.$emit("cancel");
                }
                finally{
                    this.$store.commit("hideLoader");
                }
            },
            async unhidePoints(){
                try{
                    this.$store.commit("showLoader");

                    await sessionService.unhideMultiplePoints(this.$route.params.sessionid, this.point_ids);

                    this.$emit("refreshList");
                    this.$emit("cancel");
                }
                finally{
                    this.$store.commit("hideLoader");
                }
            },

            async takenOfPoints(){
                try{
                    this.$store.commit("showLoader");

                    await sessionService.takenOffMultiplePoints(this.$route.params.sessionid, this.point_ids);

                    this.$emit("refreshList");
                    this.$emit("cancel");
                }
                finally{
                    this.$store.commit("hideLoader");
                }
            },

            async untakenOfPoints(){
                try{
                    this.$store.commit("showLoader");

                    await sessionService.untakenOffMultiplePoints(this.$route.params.sessionid, this.point_ids);

                    this.$emit("refreshList");
                    this.$emit("cancel");
                }
                finally{
                    this.$store.commit("hideLoader");
                }
            },
            getPointNestedLevel,
        },
    };
</script>

<style lang="scss" scoped>
    .edit-point-bottom-menu {
        position: fixed;
        bottom: 24px;
        right: 24px;
        width: calc(100% - 410px);
        z-index: 8;
        background-color: white;
        display: flex;
        padding: 28px 0px;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 0px 0px 32px rgba(0, 8, 44, 0.04), 8px 12px 24px rgba(0, 8, 44, 0.08);
        border-radius: 12px;

        @media only screen and (max-width: 1600px) {
            width: calc(100% - 310px);
        }

        &__left {
            display: flex;
            align-items: center;
        }

        &__actions-wrapper {
            display: flex;
            padding: 0px 24px;
            align-items: center;
            position: relative;

            @media only screen and (max-width: 1600px) {
                padding: 0px 12px;
            }

            &:nth-child(1),
            &:nth-child(2) {
                &::after {
                    content: "";
                    width: 1px;
                    height: 38px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0px;
                    background: #dde0e7;
                }
            }
        }

        &__checkbox-wrapper {
            position: relative;
            width: 0px;
            height: 24px;

            &::after {
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                width: 24px;
                height: 24px;
                background: #ffffff;
                border: 1px solid #d1d6df;
                box-sizing: border-box;
                box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
                border-radius: 4px;
            }
        }

        &__input-checkbox {
            display: none;

            &:checked ~ .edit-point-bottom-menu__checkbox-mark {
                display: flex;
                z-index: 4;
            }
        }

        &__checkbox-mark {
            display: none;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 24px;
            height: 24px;
            background: #00082c;
            border: 1px solid #00082c;
            box-sizing: border-box;
            box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            justify-content: center;
            align-items: center;
            z-index: 2;
        }

        &__checkbox-icon {
            width: 12px;
        }

        &__picked-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;
            z-index: 5;
            cursor: pointer;
            padding-left: 36px;

            &--bold {
                font-weight: 600;
                padding-left: 0px;
            }
        }

        &__action-btn {
            background: #ffffff;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            transition: all 0.2s ease-in-out;
            padding: 12px 24px;
            display: flex;
            align-items: center;
            margin-right: 12px;

            @media only screen and (max-width: 1600px) {
                padding: 12px 11px;
            }

            &--arrow {
                width: 40px;
                padding: 0px;
                height: 40px;
                justify-content: center;
            }

            &--red {
                background: #d80418;
                border: 0px;

                @media only screen and (max-width: 1600px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px;
                    width: 40px;
                    height: 40px;
                }

                &:hover {
                    background: #ad0313 !important;
                }
            }

            &:nth-last-of-type(1) {
                margin-right: 0px;
            }

            &:hover {
                background: #e8ebef;
            }
        }

        &__action-name {
            margin-left: 8px;
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;
            color: #00082c;

            &--red {
                color: white;

                @media only screen and (max-width: 1600px) {
                    display: none;
                }
            }
        }

        &__right {
            display: flex;
            padding-right: 24px;
        }

        &__action-icon {
            width: 18px;
            height: 18px;

            &--white {
                filter: brightness(0) invert(1);
            }
        }

        &__file-input {
            display: none;
        }
    }
</style>
