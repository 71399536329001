<template>
    <section class="voting">
        <div class="voting-form">
            <button class="voting-form__exit-btn"
                    @click="closeModale">
                <img src="../../../../../assets/icons/components/exit-btn.svg" />
            </button>
            
            <section class="voting-form__form-wrapper">
                <section class="voting-form__title">
                    Głosowanie: <b>{{vname}}</b>
                </section>
                <hr />
                <h4 style="text-align: center; padding: 15px;">WYŁĄCZ Z GŁOSOWANIA WSKAZANE OSOBY</h4>
                <div class="available-users">
                    <div class="user" v-for="(item, key) in internalBasicData" :key="key" :class="getVoteUserClass(item)" @click="selectUserForExcluted(item)" >
                        <div class="user-avatar" >
                            <img :src="getUserAvata(item.userId)" :alt="`${item.surname} ${item.name}`" />
                        </div>
                        <div class="user-data">
                            <span class="user-name">{{item.firstName}} {{item.lastName}}</span>
                        </div>
                    </div>
                </div>

                <section class="voting-form__buttons-bottom">
                    <button class="voting-form__bottom-button"
                            @click="closeModale">
                        Anuluj
                    </button>
                    <button v-if="internalBasicData.length > 0" class="
                            voting-form__bottom-button
                            voting-form__bottom-button--red" @click="saveResults()">
                        Potwierdź
                    </button>
                </section>
            </section>
        </div>
       
    </section>
</template>

<script>
    //import VotingService from "../../../../../services/voting.service";
    import CommonHelper from '@/helpers/common.helper.js';

    export default {
        name: "ExcludedUsersModal",
        data() {
            return {
                internalBasicData: this.basicData,
                avatarVersion: CommonHelper.getRandomVersionImageForUrlRefresh(0, 100),
            };
        },
        components: {
        },
        props: {
            basicData: Object,
            vname: String
        },

        mounted() {

        },
        methods: {
            saveResults() {
                this.$emit('saveExcludedUsers', this.internalBasicData);
            },
            selectUserForExcluted(item) {
                item.excluded = !item.excluded;
            },
            getVoteUserClass(item) {
                if (item === null)
                    return "";

                if (item.excluded) {
                    return "excluded";
                }
            },
            async closeModale(e) {
                e.preventDefault();
                this.$emit('closeExcludedUsersModal');
            },
            getUserAvata(uid) {
                return `https://api.esesja.pl/users/${uid}.jpg${this.avatarVersion}`;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .uprawnieni {
        display:block;
    }

    .uprawniony {
        display: inline-block;
        padding: 8px;
        font-size: 1.4em;
        font-weight: 300;
        margin-right: 10px;
        margin-bottom: 10px;
        background-color: #e5e5e5;
        border-bottom: 1px solid #e5e5e5
    }

    .voting {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 20;
    }

    .voting-form {
        padding: 32px 56px;
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        max-height: 100vh;
        padding-bottom: 0px;
        padding-right: 0px;
        width: 1200px;
        //height: 100%;

        &__form-wrapper {
            max-height: 80vh;
            overflow-y: auto;
            padding-right: 56px;


            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }

            .available-users {
                display: flex;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 10px;
                justify-content: center;
                margin-bottom: 20px;

                .user {
                    width: 250px;
                    display: flex;
                    column-gap: 10px;
                    cursor: pointer;
                    align-items: center;
                    background-color: #e5e5e5;

                    .user-avatar img {
                        width: 70px;
                        vertical-align: middle;
                    }

                    .user-data .user-name {
                        font-size: 1.4em;
                        font-weight: 300;
                    }

                    &:hover {
                        background: #ddd;
                        box-shadow: 0px 0px 10px 5px #b1b1b1;
                    }
                }

                .user.absent {
                    background: #000 !important;
                    cursor: default !important;
                    color: white !important;
                }

                .user.excluded {
                    background: #363532 !important;
                    text-decoration-line: line-through !important;
                    color: white;
                }

                .user.vote-for {
                    background: #9bcc5f !important;
                }

                .user.vote-against {
                    background: #cc615f !important;
                }

                .user.vote-abstain {
                    background: #cca65f !important;
                }

                .user.vote-hidden {
                    background: #7cd1eb !important;
                }
            }
        }

        &__exit-btn {
            border: 0px;
            background-color: transparent;
            position: relative;
            right: calc(-100% + 48px);
        }

        &__row {
            margin-bottom: 10px;
        }

        &__title {
            font-weight: 600;
            font-size: 20px;
            line-height: 128%;
            color: #00082c;
            width: 100%;
            padding-bottom: 15px;

            &--small {
                font-size: 20px;
                border-bottom: 0px;
                padding-bottom: 16px;
                margin: 0px;
            }

            &--no-padding {
                padding: 0px;
            }
        }

        &__buttons-bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            padding-bottom: 56px;
        }

        &__bottom-button {
            //padding: 18px 40px 17px;
            width: 238px;
            height: 56px;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
            background: transparent;
            transition: all 0.2s ease-in-out;

            &:disabled {
                background-color: #e5e5e5;
                border: 0;
                color: silver;
                cursor: default;
                pointer-events: none;
            }

            &:hover {
                background: #e8ebef;
            }

            &--red {
                border: 0px;
                background: #d80418;
                color: white;

                &:hover {
                    background: #ad0313 !important;
                }
            }
        }
    }
</style>
