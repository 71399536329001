<template>
    <eSesjaModal class="import-contact-modal">
        <eSesjaModalHeader @close="$store.commit('isImportContactModalStatus', false)">
            &nbsp;
        </eSesjaModalHeader>
        <eSesjaModalBody>
            <StepsHeader :steps="steps"
                         :currentStep="currentStep"
                         :stepsDone="stepsDone"
                         @goToStep="goToStep" />

            <ImportContactImporter v-if="currentStep === 1" />
            <ImportContactSummary v-else-if="currentStep === 2"/>
        </eSesjaModalBody>
        <eSesjaModalFooter>
            <eSesjaModalButtonCancel @close="$store.commit('isImportContactModalStatus', false)">
                Anuluj
            </eSesjaModalButtonCancel>
            <eSesjaModalButtonSave @click="saveData" v-if="currentStep === 2 && isDataOk === true">
                Zapisz
            </eSesjaModalButtonSave>
            <eSesjaModalButtonSave v-else-if="currentStep === 2 && isDataOk === false" :disabled="true" v-tooltip="saveButtonTooltip">
                Zapisz
            </eSesjaModalButtonSave>
        </eSesjaModalFooter>
    </eSesjaModal>
</template>
<script charset="utf-8">
    import StepsHeader from "../../../../../Shared/Steps/StepsHeader.vue";
    import ImportContactImporter from "./Parts/ImportContactImporter.vue";
    import ImportContactSummary from "./Parts/ImportContactSummary.vue";
    import contactService from "../../../../../../services/contact.service";
    import importContactService from "../../../../../../services/import.contact.service";

    export default {
        name: "ImportContactModal",
        emits: ["refreshGroups"],
        components: {
            StepsHeader,
            ImportContactImporter,
            ImportContactSummary
        },
        computed: {
            steps() {
                return this.$store.state.importContactStore.importSteps;
            },
            currentStep() {
                return this.$store.state.importContactStore.currentStep;
            },
            stepsDone() {
                return this.$store.state.importContactStore.stepsDone;
            },
            allContactsHaveGroup() {
                return this.$store.state.importContactStore.allContactsHaveGroups;
            },
            isDataOk() {
                return this.allContactsHaveGroup && this.$store.state.importContactStore.importData?.length > 0;
            },
            isAnyRowIsEdited() {
                if (this.$store.state.importContactStore.importData === undefined || this.$store.state.importContactStore.importData === null || this.$store.state.importContactStore.importData?.length === 0) {
                    return false;
                }
                let filtered = this.$store.state.importContactStore.importData.filter(item => item.isEdit);

                return filtered.length > 0;
            },
            saveButtonTooltip() {
                if (this.isAnyRowIsEdited) {
                    return "Zakończ edycję danych"
                }
                return "Formularz zawiera błędy"
            }
        },
        data(){
            return{
            
            }
        },
        mounted() {
            importContactService.clearStore();
        },
        methods:{
            setMode(mode){
                console.log(mode);
            },
            goToStep(data) {
                if (data.step === this.currentStep)
                    return;

                this.$store.commit('setCurrentImportStep', data.step);
            },
            async saveData() {
                try {
                    this.$store.commit("showLoader");
                    let requestData = this.$store.state.importContactStore.importData.map(item => {
                        return {
                            name: item.name.value ?? "",
                            surname: item.surname.value ?? "",
                            phonenumber: item.phonenumber.value ?? "",
                            email: item.email.value ?? "",
                            groups: item.groups?.value.map(group => group.id),
                            isUser: item.isUser
                        }
                    })

                    let response = await contactService.addMultiplePersons(requestData);
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }
                    this.$emit('refreshGroups');
                    importContactService.clearStore();
                    this.$store.commit('isImportContactModalStatus', false)
                }
                catch (e) {
                    this.$store.commit("hideLoader");
                    console.log(e);
                }
            }
        }
    }
</script>
<style lang="scss">
    .import-contact-modal {
        .esesja-modal__wraper {
            max-width: 80vw !important;
            min-width: 65vw !important;
        }
    }
</style>