<template>
    <div class="card-body">
        <slot></slot>
    </div>
</template>

<script charset="utf-8">
export default {
    name: "CardBody",
    props: {
        border: {
            type: String,
            default: ""
        }
    },
    computed: {
        cardClass() {
            let crdClass = "card-body";

            switch (this.border) {
                case "primary": crdClass += " border-primary"; break;
                case "secondary": crdClass += " border-secondary"; break;
                case "success": crdClass += " border-success"; break;
                case "danger": crdClass += " border-danger"; break;
                case "warning": crdClass += " border-warning"; break;
                case "info": crdClass += " border-info"; break;
                case "light": crdClass += " border-light"; break;
                case "dark": crdClass += " border-dark"; break;
            }

            return crdClass;
        }
    }
}
</script>