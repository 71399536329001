<template>
    <section class="user-presence-overlay">
        <section class="user-presence-container">
            <header class="user-presence-container__header">
                <section class="user-presence-container__header__menu">
                    <a class="menu-item" @click="$emit('saveUserPresents', internalPresencesData)">
                        <img src="../../../../assets/icons/components/save.svg" class="menu-icon" alt="Zapisz" />
                        <span class="menu-text">Zapisz</span>
                    </a>
                    <a class="menu-item" @click="allUserPresents">
                        <span class="menu-text">Wszyscy obecni</span>
                    </a>
                    <a class="menu-item" @click="allUserAbsents">
                        <span class="menu-text">Wszyscy nieobecni</span>
                    </a>
                    <a class="menu-item" @click="setPresenceBasedOnOnlineStatus">
                        <span class="menu-text">Zaznacz online ({{ peopleOnline.length }})</span>
                    </a>
                    <a class="menu-item" @click="printUserListToWord" v-tooltip="'Pobierz listę obecności do podpisu .docx'">
                        <span class="menu-text">Lista do podpisu</span>
                    </a>
                    <a class="menu-item" @click="getUserPresenceTimeList" v-tooltip="'Pobierz godzinową listę obecności .docx'">
                        <span class="menu-text">Raport obecności</span>
                    </a>
                    <a class="menu-item" @click="$store.commit('setIsShowAddSessionGuest', true)">
                        <span class="menu-text">Goście</span>
                    </a>
                    <!--<a class="menu-item">
        <img src="../../../../assets/icons/components/user-check-quorum.svg" class="menu-icon" alt="Raport" />
        <span class="menu-text">Raport</span>
    </a>-->
                </section>
                <section class="user-presence-container__header__action">
                    <button class="user-presence-container__header__action__exit-btn"
                            @click="closePresenceModal()">
                        <img src="../../../../assets/icons/components/exit-btn.svg" />
                    </button>
                </section>
            </header>
            <section class="user-presence-container__body">
                <table class="table-bootstrap table-bootstrap-hover table-bootstrap-bordered table-bootstrap-striped align-middle">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Nazwisko i imię</th>
                            <th>Obecność ({{getPresenceUsersCount()}}/{{internalPresencesData.length}})</th>
                            <th>Spóźnienie</th>
                            <th>Wyjście</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in internalPresencesData" :key="index" class="align-middle">
                            <td>{{index +1 }}</td>
                            <td>
                                <span class="user-name">
                                    {{item.surname}} {{item.name}}
                                </span>
                                <span class="is-user-state" v-if="item.userId !== 0">
                                    [U]
                                </span>
                            </td>
                            <td>
                                <div class="form-bootstrap-check form-bootstrap-switch">
                                    <input class="form-bootstrap-check-input" type="checkbox" role="switch" :id="`id-presence-${index}`" v-model="item.isPresence"/>
                                    <label class="form-bootstrap-check-label" :for="`id-presence-${index}`">{{getLabel(item.isPresence)}}</label>
                                </div>
                            </td>
                            <td>
                                <div class="input-bootstrap-group">
                                    <input type="number" class="form-bootstrap-control" v-model="internalPresencesData[index].late"/>
                                </div>
                            </td>
                            <td>
                                <div class="input-bootstrap-group">
                                    <input type="number" class="form-bootstrap-control" v-model="internalPresencesData[index].exit" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </section>
        <AddSessionGuestModal v-if="$store.state.interactiveStore.isShowAddSessionGuest"/>
    </section>
</template>
<script charset="utf-8">
    import AddSessionGuestModal from "./AddSessionGuestModal.vue";

    export default {
        name: "UserPresenceModal",
        components: {
            AddSessionGuestModal
        },
        props: {
            presencesData: {
                type: Array,
                required: true,
            }
        },
        computed:{
            peopleOnline(){
                const peopleOnline = this.$store.state.userStore.online ?? []
                return peopleOnline.filter(item => {
                    return this.presencesData.some(obj => obj.id === item.id) && item.status === 30;
                })
            }
        },
        data() {
            return {
                internalPresencesData: [],
            }
        },
        emits: [
            'saveUserPresents'
            ],
        watch: {
            presencesData: {
                immediate: true,
                deep: true,
                handler(val) {
                    this.internalPresencesData = val;
                }
            },


        },

        methods: {
            closePresenceModal() {
               this.internalPresencesData = [];
               this.$emit("closePresenceModal");
               this.$store.commit('hidePresenceList');
            },
            getLabel(presenceState) {
                if (presenceState == 1)
                    return "Obecny"

                return "Nieobecny";
            },

            getPresenceUsersCount() {
                return this.internalPresencesData.filter(obj => obj.isPresence === true).length;
            },

            allUserPresents() {
                this.internalPresencesData = this.internalPresencesData.filter(obj => {
                    obj.isPresence = true;
                    return true;
                });
            },
            allUserAbsents() {
                this.internalPresencesData = this.internalPresencesData.filter(obj => {
                    obj.isPresence = false;
                    return true;
                });
            },
            printUserListToWord() {
                this.$emit("printListToWord");
            },
            getUserPresenceTimeList() {
                this.$emit("getPresenceTimeList");
            },
            setPresenceBasedOnOnlineStatus(){
                this.internalPresencesData = this.internalPresencesData.filter(obj => {
                    obj.isPresence = this.peopleOnline.some(item => item.id === obj.id);
                    return true;
                });
            }
        }
    }
</script>
<style lang="scss" scoped>

    .user-presence-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        overflow: hidden;
        z-index: 9;

        .user-presence-container {
            background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
            border-radius: 12px;
            max-height: 100vh;
            padding-bottom: 0px;
            padding-right: 0px;
            width: 60vw;
            overflow: hidden;

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__menu {

                    .menu-item {
                        display: flex;
                        column-gap: 10px;
                        align-items: center;
                        float: left;
                        font-size: 16px;
                        color: #000;
                        text-align: center;
                        padding: 19px 10px 19px 10px;
                        text-decoration: none;
                        cursor: pointer;
                        transition: all 0.2s ease-in-out;

                        .menu-icon {
                            height: 20px;
                        }

                        .menu-text {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 132%;
                        }

                        &:hover {
                            background-color: #e8ebef;
                        }
                    }
                }

                &__action {
                    &__exit-btn {
                        border: 0px;
                        background-color: transparent;
                        position: relative;
                        right: calc(-100% + 48px);
                    }
                }
            }

            &__body {
                max-height: 80vh;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 12px;
                    height: 6px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #b5b8bd;
                    border-radius: 30px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: #b5b8bd;
                }

                &::-webkit-scrollbar-track {
                    background: #ffffff;
                    border: 0.8px solid #dce4ee;
                    border-radius: 30px;
                    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
                }
            }
        }
    }
</style>