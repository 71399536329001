<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Edycja ustawień konta</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeModal');}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section class="column">
                    <section class="column-item in-row">
                        <label class="esesja-modal__wraper__body__label">Automatyczne blokowanie aplikacji</label>
                        <section class="esesja-modal__row">
                            <div class="form-bootstrap-check form-bootstrap-switch">
                                <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="anebale-autolock" v-model="internalData.autolockEnable" />
                            </div>
                        </section>
                    </section>
                    <section class="column-item">
                        <label class="esesja-modal__wraper__body__label">Czas bezczynności</label>
                        <section class="esesja-modal__row">
                            <select class="esesja-modal__wraper__body__input"
                                     v-model="internalData.autolockInterval" >
                                <option v-for="(item, index) in availableInterval" :key="index" :value="item.value">{{item.label}}</option>
                            </select>
                        </section>
                    </section>
                </section>
                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="this.$emit('closeModal')"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="updateAccountSettings">Zapisz</button>
                </section>
            </perfect-scrollbar>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
    import UserService from "../../../../../../services/user.service";

    export default {
        name: "EditAccountSettings",
        emits: ['closeModal'],
        props: {
            modelValue: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                internalData: {
                    autolockEnable: false,
                    autolockInterval: 60000,
                },

                availableInterval: [
                    { value: 60000, label: "1 minuta" },
                    { value: 300000, label: "5 minut" },
                    { value: 600000, label: "10 minut" },
                    { value: 900000, label: "15 minut" },
                    { value: 1800000, label: "30 minut" },
                    { value: 3600000, label: "60 minut" },
                ]
            }
        },
        mounted() {
            this.internalData = this.modelValue
        },
        methods: {
            async updateAccountSettings(){
                try {
                    this.$store.commit("showLoader");

                    let response = await UserService.updateAccountSettings(this.internalData)

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.$emit('update:modelValue', response.data);
                    this.$emit('closeModal');

                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'fetchSessionData' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/esesja-modal.scss';

    .in-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 24px;
    }
    .in-row .esesja-modal__row{
        margin-bottom: 0px;
    }
</style>