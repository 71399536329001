<template>
    <div class="tab-pane fade"
         :class="{ 'show active': isActive }"
         :id="tabId" 
         role="tabpanel" 
         :aria-labelledby="ariaLabelledby">
        <slot></slot>
    </div>
</template>
<script charset="utf-8">
    export default {
        name: "eSesjaTabPane",
        props: {
            tabId: {
                type: String,
                required: true
            },
            isActive: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ariaLabelledby() {
                return `${this.tabId}-tab`
            }
        }
    }
</script>