import mainStore from "../store/mainStore";
import sessionService from "./session.service"
import communicationService from "./communication.service";
import CalendarEntryEnum from "./calendar.entry.enum";
import router from "../router/main-router.js";
import { encryptStorage } from "./encrypt.service";

class CalendarService {
     constructor() {
        mainStore.state.calendarStore.currentDate = new Date();
    }

    async init(){
        await this.calculateCalendar();
    }

    async previousMonth() {
        let temp = new Date(mainStore.state.calendarStore.currentDate);
        mainStore.state.calendarStore.currentDate = new Date(temp.getFullYear(), temp.getMonth() - 1, 1);
        await this.calculateCalendar();
    }

    async nextMonth() {
        let temp = new Date(mainStore.state.calendarStore.currentDate);
        mainStore.state.calendarStore.currentDate = new Date(temp.getFullYear(), temp.getMonth() + 1, 1);
        await this.calculateCalendar();
    }

    async calculateCalendar() {
        this._getFistDateOfMonth(mainStore.state.calendarStore.currentDate.getFullYear(), mainStore.state.calendarStore.currentDate.getMonth());
        this._getDaysInMonth(mainStore.state.calendarStore.currentDate.getFullYear(), mainStore.state.calendarStore.currentDate.getMonth());
        this._getLastDateOfMonth(mainStore.state.calendarStore.currentDate.getFullYear(), mainStore.state.calendarStore.currentDate.getMonth());
        await this._createArrayOfDates();
    }

    _getFistDateOfMonth(year, month) {
        let firstDayOfMonth = new Date(year, month, 1).getDay()
        let filtered = mainStore.state.calendarStore.weekDays.filter(item => item.id === firstDayOfMonth);
        if (filtered.length === 1) {
            mainStore.state.calendarStore.currentFirstDayOfWeek = filtered[0];
        }
    }
    _getDaysInMonth(year, month) {
        mainStore.state.calendarStore.currentDaysInMonth = new Date(year, month + 1, 0).getDate();
    }
    _getLastDateOfMonth(year, month) {
        let lastDayOfMoth = new Date(year, month, mainStore.state.calendarStore.currentDaysInMonth).getDay();
        let filtered = mainStore.state.calendarStore.weekDays.filter(item => item.id === lastDayOfMoth);
        if (filtered.length === 1) {
            mainStore.state.calendarStore.currentLastDayOfWeek = filtered[0];
        }
    }

    _getTotalDays() {
        return mainStore.state.calendarStore.currentDaysInMonth + mainStore.state.calendarStore.currentFirstDayOfWeek.id
    }
    async _createArrayOfDates() {
        let minDate = this._getProviousDateData();
        let maxDate = this._getNexDateData();
        const [sessions, announcements] = await this._fetchData(this._getQueryDate(minDate), this._getQueryDate(maxDate))

        mainStore.state.calendarStore.dataArray = []
        for (var d = new Date(minDate); d <= new Date(maxDate); d.setDate(d.getDate() + 1)) {
            let tempDate = new Date(d);
            let endOfDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate() +1, 0,0,-1);
            let entries = [];
            entries = entries.concat(this._getSessionInDate(tempDate, endOfDate, [...sessions]));
            entries = entries.concat(this._getAnnouncemensInDate(tempDate, endOfDate, [...announcements]))
            mainStore.state.calendarStore.dataArray.push({
                date: tempDate,
                isOutsideMain: tempDate.getMonth() !== mainStore.state.calendarStore.currentDate.getMonth(),
                isWeekend: tempDate.getDay() === 6 || tempDate.getDay() === 0,
                entries: entries.sort((a, b) => {
                    if(a.date > b.date){
                        return 1;
                    }
                    else {
                        return -1;
                    }
                })
            });
        }
    }

    async _fetchData(minDate, maxDate){
        let sessionQuery = sessionService.getSessionsBetweenDates(minDate, maxDate);

        let sessions = {};
        let announcements = {
            data: []
        };

        let announcementQuery = Promise.resolve();
        
        if(this._hasAnnoucementModule())
        {
            announcementQuery = communicationService.getAnnoucementBetweenDates(minDate, maxDate);
        }

        [sessions, announcements] = await Promise.all([sessionQuery, announcementQuery]);

        if(sessions.status === 200){
            sessions.data = sessions.data.map(item => {
                item.meeting_date = new Date(Date.parse(item.meeting_date));
                if(item.end_meeting_date !== undefined && item.end_meeting_date !== null){
                    item.end_meeting_date = new Date(Date.parse(item.end_meeting_date));
                }
                return item;
            })
        }

        if(this._hasAnnoucementModule()){
            if(announcements.status === 200){
                announcements.data = announcements.data.map(item => {
                    item.date = new Date(Date.parse(item.date));
                    return item;    
                });
            }
        }
        else{
            announcements = {
                data: []
            }
        }

        return [sessions.data, announcements.data];
    }
    _getSessionInDate(minDate, maxDate, sessions){
        let internalSession = sessions.filter(item => {
            if(item.end_meeting_date === undefined || item.end_meeting_date === null){
                return (item.meeting_date >= minDate && item.meeting_date <= maxDate)
            }
            else{
                return (this._getQueryDate(minDate) >= this._getQueryDate(item.meeting_date) && this._getQueryDate(minDate) <= this._getQueryDate(item.end_meeting_date));
            }
        });
        return this._normalizeSessionToCalendarEntry([...internalSession], minDate);
    }

    _normalizeSessionToCalendarEntry(sessions, currentDate){
        if(sessions === undefined || sessions === null || sessions.length === 0){
            return [];
        }
        return sessions.map(item => {
            return {
                id: item.id,
                name: item.name,
                type: CalendarEntryEnum.Session, // to do const
                router: router.resolve({
                    name: 'sessionEdit', 
                    params: {
                        sessionid: item.id,
                        groupid: item.groups[0]?.id ?? -1
                    }
                }),
                date: item.end_meeting_date === null ? item.meeting_date : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), item.meeting_date.getHours(), item.meeting_date.getMinutes()),
                raw: item
            }
        });
    }

    _getAnnouncemensInDate(minDate, maxDate, announcemets){
        let internalAnnouncements = announcemets.filter(item => {
            return (item.date >= minDate && item.date <= maxDate)
        })
        return this._normalizeAnnouncementToCalendarEntry(internalAnnouncements);
    }

    _normalizeAnnouncementToCalendarEntry(announcements){
        if(announcements === undefined || announcements === null || announcements.length === 0){
            return [];
        }

        return announcements.map(item => {
            return {
                id: item.id,
                name: item.name,
                type: CalendarEntryEnum.Announcement, // to do const,
                router: router.resolve({
                    name: 'announcement',
                    params:{
                        searchId: item.id,
                    }
                }),
                date: item.date,
                raw: item
            }
        })
    }

    _getQueryDate(date){
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),0, 0, 0, 0));
    }

    _getProviousDateData() {
        let proviousDaysInMonth = new Date(mainStore.state.calendarStore.currentDate.getFullYear(), mainStore.state.calendarStore.currentDate.getMonth(), 0).getDate();
        let minNeceseryDayOfMotnth = proviousDaysInMonth - mainStore.state.calendarStore.currentFirstDayOfWeek.id + 2
        return new Date(mainStore.state.calendarStore.currentDate.getFullYear(), mainStore.state.calendarStore.currentDate.getMonth() - 1, minNeceseryDayOfMotnth);
    }
    _getNexDateData() {
        let diff = (6 - mainStore.state.calendarStore.currentLastDayOfWeek.id);
        if (diff === 6) {
            return new Date(mainStore.state.calendarStore.currentDate.getFullYear(), mainStore.state.calendarStore.currentDate.getMonth(), mainStore.state.calendarStore.currentDaysInMonth)
        }
        else {
            return new Date(mainStore.state.calendarStore.currentDate.getFullYear(), mainStore.state.calendarStore.currentDate.getMonth() + 1, diff + 1);
        }
    }

    _hasAnnoucementModule(){
        return encryptStorage.getItem('modules').inf == 1 ? true : false;
    }
}

export default new CalendarService();