<template>
    <section class="contact-box">
        <section class="contact-box__top" >
            <section @click="toogleOpenBox()" class="pointer-bar">
                <img src="../../../../../assets/icons/panel/Contacts/contact-icon.svg" class="contact-box__icon" />
                <span class="contact-box__title">{{ name }} ({{ people.length }})</span>
                <img src="../../../../../assets/icons/panel/Contacts/list-arrow.svg" class="contact-box__arrow" :class="{ 'contact-box__arrow--open': isBoxOpen }" />
            </section>
            <section class="contact-box-actions" v-if="adminMenagePrivilige">
                <ButtonWithIconContacts v-if="people.length > 0 && checkPeoplePhoneNumber()" icon="send-sms" text="SMS" @click="() => sendSmsHandler( 2, groupId, null, people)" v-tooltip="'Wyślij SMS do grupy'" />
                <ButtonWithIconContacts v-if="people.length > 0 && checkPeopleEmail()" icon="send-mail" text="e-mail" @click="() => sendEmailHandler( 2, groupId, null, people)" v-tooltip="'Wyślij e-mail do grupy'" />

                <ButtonWithIconContacts icon="add-contact-small-black" text="Dodaj kontakt"
                                        @click="(e)=> addContactHandler(e, 'add-contact', { id: groupId, action: 'add-contact-to-group', })" />
                <ButtonWithIconContacts icon="edit" text="Ustawienia"
                                        @click="(e)=> { addContactHandler(e, 'add-group', { id: groupId, action: 'edit-group',}); }" />
                <ButtonOnlyWithIcon icon="delete" @click="() => checkDeleteIsPossible(this.groupId)"  />
            </section>
        </section>
        <section class="contact-box__people contact-box__people--open" v-if="isBoxOpen || isFilter">
            <section v-if="people.length > 0">
                <section class="contact-box-description">
                    <span class="contact-box-description__data">imię i nazwisko</span>
                    <span class="contact-box-description__data">adres e-mail</span>
                    <span class="contact-box-description__data">telefon</span>
                </section>
                <section class="contact-box__people-container">
                    <People v-for="person in people"
                            :key="person.id"
                            :name="person.first_name"
                            :lastName="person.last_name"
                            :mail="person.email"
                            :number="person.phone_number"
                            :id="person.id"
                            :onlineStatus="getOnlineStatus(person.id)"
                            :isLocked="false"
                            :avatar="person.avatar"
                            :isInUserTable="person.isInUserTable"
                            :isQrCodeLogin="person.isQrCodeLogin"
                            @checkedPeopleForMessage="checkedPeopleForMessage"
                            @reloadGroups="(groups) => this.$emit('reloadGroups', groups)"
                            :addContactHandler="addContactHandler"
                            :sendEmailHandler="sendEmailHandler"
                            :sendSmsHandler="sendSmsHandler"
                            />
                </section>

                <section v-if="adminMenagePrivilige"
                         class="add-contact-button"
                         @click="(e)=> addContactHandler(e, 'add-contact', { id: groupId, action: 'add-contact-to-group', }) " >
                    <SmallAddButtonRed />
                    <span class="add-contact-button__text">Dodaj kontakt</span>
                </section>
            </section>
            <section v-else class="contacts-no-data">
                <p>Brak kontaktów</p>
                <section v-if="adminMenagePrivilige"
                         class="add-contact-button add-contact-button--no-margin"
                         @click="(e)=> addContactHandler(e, 'add-contact', { id: groupId, action: 'add-contact-to-group', })" >
                    <SmallAddButtonRed />
                    <span class="add-contact-button__text">Dodaj kontakt</span>
                </section>
            </section>
        </section>
    </section>
</template>

<script>
import ContactService from "../../../../../services/contact.service";
import { encryptStorage } from '../../../../../services/encrypt.service';
import People from "./People.vue";
import SmallAddButtonRed from "../../../../BasicControls/SmallAddButtonRed.vue";
import ButtonOnlyWithIcon from "../../../../BasicControls/ButtonOnlyWithIcon.vue";
import ButtonWithIconContacts from "../../../../BasicControls/ButtonWithIconContacts.vue";
import AlertHelper from '@/helpers/alerts.helper.js';

export default {
    name: "ContactBox",
    data() {
        return {
            isBoxOpen: false,
            adminContactPrivilige: encryptStorage.getItem("priv").komunikator,
            adminMainPrivilige: encryptStorage.getItem("priv").uprawnienia,
            adminMenagePrivilige: encryptStorage.getItem("priv").zarzadzanie_kontaktami,
            checked: false,
        };
    },
    computed:{
        peopleOnline(){
            return this.$store.state.userStore.online ?? []
        }
    },
    props: {
        name: String,
        groupId: Number,
        people: Array,
        addContactHandler: Function,
        isFilter: Boolean,
        sendSmsHandler: Function,
        sendEmailHandler: Function,
    },
    components: {
        People,
        SmallAddButtonRed,
        ButtonOnlyWithIcon,
        ButtonWithIconContacts,
    },
    methods: {
        toogleOpenBox() {
            if (!this.isBoxOpen) {
                //open
                
            }
            else {
                //close
                var storeList = this.$store.state.contacts.selectedPeopleToSendMessage;
                var people = this.people.map(el => el.id);

                for (var i = 0; i < people.length; i++) {
                    var index = storeList.findIndex(x => x == people[i]);
                    if (index != -1) {
                        storeList.splice(index, 1);
                    }
                }
                this.$store.commit('setSelectedPeopleToSendMessage', storeList);
            }

            this.isBoxOpen = !this.isBoxOpen;
        },
        checkDeleteIsPossible(id) {
            var containsActiveUsers = this.people.filter(x => x.isInUserTable == true);
            if (containsActiveUsers != null && containsActiveUsers.length > 0) {
                // cannot delete group
                var t = this.$swal.mixin(AlertHelper.informationToastElement());
                t.fire(AlertHelper.informationAlert('W grupie znajdują się aktywni użytkownicy, aby odblokować możliwość usunięcia grupy przenieś użytkowników do innej grupy lub deaktywuj konta użytkowników za pośrednictwem <a href="https://panelklienta.mwc.pl" target="_blank" > Panelu Klienta </a>'));
            }
            else {
                this.handleDeleteClick(id)
            }
        },
        handleDeleteClick(id) {
            const actionToFire = () => {
                this.$store.state.userStore.isLoading = true;
                ContactService.deleteGroup(id).then(() => {
                    var d = this.$store.state.appStore.groups;
                    var index = d.findIndex(x => x.id == id);
                    if (index != -1) {
                        d.splice(index, 1);
                    }
                    this.$store.state.userStore.isLoading = false;
                    this.$emit("reloadGroups", d);
                    this.$store.commit("setGroups",d);
                });
            };
            this.$store.commit("showConfirmationAlert", actionToFire);
        },
        checkPeoplePhoneNumber() {
            const number = this.people.filter(x => x.phone_number != null && x.phone_number.length > 0);
            if (number.length > 0)
                return true;

            return false;
        },
        checkPeopleEmail() {
            const email = this.people.filter(x => x.email != null && x.email.length > 0);
            if (email.length > 0)
                return true;

            return false;
        },
        checkedPeopleForMessage(data) {
            var storeList = this.$store.state.contacts.selectedPeopleToSendMessage;

            if (data.checked == true) {
                var index1 = storeList.findIndex(x => x == data.id);
                if (index1 == -1) {
                    storeList.push(data.id);
                }
            }
            else {
                var index = storeList.findIndex(x => x == data.id);
                if (index != -1) {
                    storeList.splice(index, 1);
                }
            }

            this.$store.commit('setSelectedPeopleToSendMessage', storeList);
        },
        getOnlineStatus(personId){
            let filtered = this.peopleOnline.filter(item => item.id === personId);
            return filtered.length === 1 ? filtered[0].status : 0;
        }
    },
};
</script>

<style lang="scss" scoped>

    .pointer-bar {
        cursor: pointer;
    }

    .contact-box {
        background: #ffffff;
        width: 100%;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
        border-radius: 12px;
        margin-bottom: 12px;
        transition: all 0.2s ease-in-out;

        &:hover .contact-box-actions {
            opacity: 1;
        }

        &:hover {
            border: 1px solid #bbc2ce;
        }

        &__title {
            margin-left: 16px;
            margin-right: 16px;
            font-weight: 600;
            font-size: 18px;
            line-height: 132%;
            color: #00082c;
        }

        &__top {
            display: flex;
            align-items: center;
            padding: 29px;
            border-bottom: 1px solid #dce4ee;
            //cursor: pointer;
            justify-content: space-between;
        }

        &__people {
            transition: all 0.3s ease-in-out;

            &--close {
                max-height: 0px;
                display: none;
            }
        }

        &__people-container {
            padding: 16px;
            background: #f4f6fa;
            border-radius: 12px;
            margin: 0px 16px;

            &--close {
                max-height: 0px;
            }
        }

        &__arrow {
            transform: rotate(0deg);
            transition: all 0.3s ease-in-out;

            &--open {
                transform: rotate(180deg);
            }
        }
    }

    .contact-box-description {
        margin: 25px 34px 16px 34px;

        &__data {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 13px;
            line-height: 120%;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #666b80;

            &:nth-child(1) {
                margin-right: 228px;

                @media only screen and (max-width: 1600px) {
                    margin-right: 138px;
                }
            }

            &:nth-child(2) {
                margin-right: 270px;

                @media only screen and (max-width: 1600px) {
                    margin-right: 210px;
                }
            }
        }
    }

.add-contact-button {
    display: flex;
    align-items: center;
    margin: 20px 32px;
    cursor: pointer;

    &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        color: #00082c;
        margin-left: 8px;
    }

    &--no-margin {
        margin: 37px 0px 0px 0px;
    }
}

.contacts-no-data {
    display: flex;
    margin: 27px;
    flex-direction: column;
}

.contact-box-actions {
    display: flex;
    align-items: center;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}
</style>
