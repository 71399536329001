<template>
    <div :class="classes" :id="id">
        <slot></slot>
    </div>
</template>
<script charset="utf-8">
    export default {
        name: "eSesjaTabContent",
        props: {
            id: {
                type: String,
                required: true
            },
            mode: {
                type: String,
                default: "horizontal"
            }
        },
        computed: {
            classes() {
                let className = "tab-content";

                switch (this.mode) {
                    case "vertical": className += " flex-grow-1"; break;
                }

                return className;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .tab-content {
        padding: 15px 0px;
    }
</style>