<template>
    <section class="contacts">
        <section class="contacts__head">
            <h1 class="contacts__head__title">Historia aktualizacji</h1>
            <section class="contact-top__action-buttons" v-if="mwc_admin">
                <ButtonWithIcon v-if="mwc_admin" icon="plus-small" style=""
                                text="Dodaj aktualizację"
                                :classModified="true"
                                @click="(e) => addActualization()" />
            </section>
        </section>

        <section class="contacts__list" v-if="history.length > 0">
            <section class="contact-list">
                <section v-for="his in history" :key="his.id">
                    <section class="contact-box">
                        <section class="contact-box__top">
                            <section>
                                <section class="title">
                                    <img src="../../../../assets/icons/components/callendar.svg" width="20" />
                                    <span class="act-title">{{formatDateToDisplay(his.time)}}</span>
                                </section>
                            </section>
                            
                            <section class="contact-box__right contact-box__right__waiting">
                                <ButtonOnlyWithIcon v-if="mwc_admin" icon="edit" @click="() => handleEditClick(his)" v-tooltip="'Edytuj aktualizację'" />
                                <ButtonOnlyWithIcon v-if="mwc_admin" icon="delete" @click="() => handleDeleteClick(his.id)" v-tooltip="'Usuń aktualizację'" />
                            </section>

                        </section>
                        <section style="padding: 10px" class="contact-box__people contact-box__people--open">
                            <section v-if="his.description.length > 0">
                                <section class="contact-box__people-container">
                                    <div v-html="his.description"></div>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <section class="contacts__list" v-else>
            <section class="contact-list">
                <section>
                    <section class="contact-box">
                        <section class="contact-box__top">
                            <section>
                                <span class="contact-box__title">Brak historii aktualizacji</span>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
       
        <VHModal v-if="$store.state.modals.tools.addVersionsHistoryModalStatus" :options="options" @reloadHistoryList="reloadHistoryList" />
    </section>
</template>

<script charset="utf-8">
    // components
    import ButtonWithIcon from "../../../BasicControls/ButtonWithIcon.vue";
    import ButtonOnlyWithIcon from "../../../BasicControls/ButtonOnlyWithIcon.vue";
    import VHModal from '../Tools/Modals/AddVersionToHistoryModal.vue';
    import moment from "moment";

    // services
    import { encryptStorage } from '../../../../services/encrypt.service';
    import ToolsService from "../../../../services/tools.service";


    export default {
        name: "versions-history",
        data() {
            return {
                history: [],
                options: {
                    id: 0,
                    time: null,
                    description: '',
                    isPublic: false,
                    isDeleted: false
                },
                mwc_admin: encryptStorage.getItem('priv').mwc,

                isLoading: {
                    data: false,
                    statistics: false,
                }
            };
        },
        async mounted() {
            await this.fetchHistoryData();
            this.$store.commit('updateAccountErrors', this.getNewNotifications());
        },
        methods: {
            getNewNotifications() {
                var notifications = this.$store.state.userStore.accountErrors;
                notifications = notifications.filter(item => item.errorTypeHuman !== 'changeinapphistory');
                return notifications;
            },
            async fetchHistoryData() {
                try {
                    this.isLoading.data = true;
                    let response = await ToolsService.getVersionsHistory();
                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }

                    this.history = response.data;
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'versions-history' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading.data = false;
                }
            },
            async reloadHistoryList() {
                await this.fetchHistoryData();
            },
            handleDeleteClick(id) {
                if (this.mwc_admin) {
                    const actionToFire = () => {
                        ToolsService.deleteVersionFromHistory(id).then(async () => {
                            await this.fetchHistoryData();
                        });
                    };
                    this.$store.commit("showConfirmationAlert", actionToFire);
                }
            },
            addActualization() {
                if (this.mwc_admin) {

                    var date = new Date();

                    this.options = {
                        id: 0,
                        time: moment(date).format("YYYY-MM-DD"),
                        description: '',
                        isPublic: false,
                        isDeleted: false
                    };
                    
                    this.$store.commit('setAddVersionsHistoryModalStatus', true);
                    
                }
            },
            handleEditClick(elem) {
                if (this.mwc_admin) {
                    this.options = elem;
                    this.options.time = moment(this.options.time).format("YYYY-MM-DD")
                    this.$store.commit('setAddVersionsHistoryModalStatus', true);
                }
            },
            formatDateToDisplay(value) {
                if (value == null)
                    return '';

                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    weekday: 'long',
                    day: 'numeric'
                });

                return d;
            },
        },
        components: {
            ButtonWithIcon,
            VHModal,
            ButtonOnlyWithIcon
        }
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/search-component.scss";


    .contact-box__people--open {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .contact-box__people-container {
        padding:0px;
        background:none;
        border-radius: 12px;
        margin: 0 16px
    }

    .contact-box__top {
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #dce4ee;
        cursor: pointer;
        justify-content: space-between
    }

    .act-title {
        font-weight: bold;
    }

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
    }

</style>
