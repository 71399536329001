export const settingsModalsStore = {
    state: () => ({
        changeChartTypeModalStatus: false,
        logoSettingsModalStatus: false,
        cameraIpSettingsModalStatus: false,
    }),
    mutations: {
        setChangeChartTypeModalStatus(state, status) {
            state.changeChartTypeModalStatus = status;
        },
        setlogoSettingsModalStatus(state, status) {
            state.logoSettingsModalStatus = status;
        },
        setCameraIpSettingsModalStatus(state, status) {
            state.cameraIpSettingsModalStatus = status;
        },
        
    }
}