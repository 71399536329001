<template>
    <section class="switch">
        <section
            class="switch__btn"
            :class="{ 'switch__btn--selected': isPublic }"
        >
            <input
                type="checkbox"
                class="switch__checkbox"
                @change="handleChange"
                :checked="isPublic"
                id="is-public"
            />
            <span class="switch__slider"></span>
        </section>
        <label class="switch__label" for="is-public">{{ label }}</label>
    </section>
</template>

<script>
export default {
    name: "SwitchButton",
    props: {
        label: String,
        isPublic: Boolean,
    },
    methods: {
        handleChange() {
            this.$emit("setIsPublic", !this.isPublic);
        },
    },
};
</script>

<style lang="scss" scoped>
    .switch {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;

        &__label {
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            color: #333956;
            padding-left: 54px;
            position: relative;
            z-index: 10;
            cursor: pointer;
        }

        &__checkbox {
            width: 0px;
            opacity: 0px;

            &:checked + .switch__slider {
                left: calc(100% - 20px);
            }
        }

        &__btn {
            height: 24px;
            width: 42px;
            position: absolute;
            top: 0px;
            left: 0px;
            background: #e8ebef;
            border-radius: 100px;
            transition: all 0.2s ease-in-out;
            border: 1px solid #bbc2ce;

            &--selected {
                background: #d80418;
            }
        }

        &__slider {
            position: absolute;
            top: 50%;
            left: 3px;
            transform: translateY(-50%);
            background: #ffffff;
            box-shadow: 2px 2px 4px rgba(0, 8, 44, 0.06);
            width: 18px;
            height: 18px;
            border-radius: 100%;
            transition: all 0.3s ease-in-out;
        }
    }
</style>
