<template>
    <section class="steps-header">
        <ul class="steps">
            <li v-for="(item, index) in steps" :key="index" :class="stepClass(item.step)" >
                <div class="step-content" v-tooltip="item.tooltip" @click="$emit('goToStep', item)">
                    <span class="step-circle">{{ index + 1 }}</span>
                    <span class="step-line"></span>
                    <span class="step-text">{{ item.label }}</span>
                </div>
            </li>
        </ul>
    </section>
</template>
<script charset="utf-8">

    export default {
        name: "StepsHeader",
        emits: ["goToStep"],
        props:{
            steps:{
                type: Array,
                default(){
                    return [];
                }
            },
            currentStep:{
                type: Number,
                default: 1,
            },
            stepsDone: {
                type: Array,
                default(){
                    return [];
                }
            }
        },
        methods: {
            stepClass(step) {
                let returnClass = "step";
                
                if (step === this.currentStep) {
                    returnClass += " active";
                }

                if (this.stepsDone.includes(step)) {
                    returnClass += " done";
                }
                
                return returnClass;
            },
        }
    }
</script>
<style lang="scss">
    .steps-header {
        position: relative;

        .steps {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            overflow-x: auto;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: baseline;


            .step {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0px;

                &:last-child {
                    .step-content {
                        .step-line {
                            width: 150%;
                        }
                    }
                }

                .step-content {
                    box-sizing: content-box;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    position: relative;
                    cursor: pointer;

                    .step-line {
                        background-image: radial-gradient(#adb5bd 2px, transparent 1px);
                        background-size: 5px 1px;
                        height: 3px;
                        width: 100%;
                        position: absolute;
                        left: -50%;
                        top: 20px;
                    }

                    .step-circle {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        color: #adb5bd;
                        border: 3px solid #adb5bd;
                        border-radius: 100%;
                        background-color: #fff;
                        z-index: 1;
                    }

                    .step-text {
                        color: #adb5bd;
                        word-break: break-all;
                        margin-top: 0.25em;
                        box-sizing: border-box;
                    }
                }
            }

            .done {
                .step-content {
                    .step-circle {
                        color: green;
                        border-color: green;
                    }

                    .step-line {
                        background: green;
                    }

                    .step-text {
                        color: green;
                    }
                }

                &:last-child {
                    .step-content {
                        .step-line {
                            background: green;
                        }
                    }
                }
            }

            .active {
                .step-content {
                    cursor: default;

                    .step-circle {
                        color: blue;
                        border-color: blue;
                        width: 60px;
                        height: 60px;
                    }

                    .step-line {
                        background: blue;
                        top: 30px;
                    }

                    .step-text {
                        color: blue;
                    }
                }

                &:last-child {
                    .step-content {
                        cursor: default;

                        .step-line {
                            background: blue;
                        }
                    }
                }
            }
        }
    }
</style>