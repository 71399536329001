<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Edytor wypowiedzi</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeAddStatement');}">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <label class="esesja-modal__wraper__body__label">Autor wypowiedzi</label>
                <section class="esesja-modal__row">
                    <Multiselect v-model="value"
                                 placeholder="Wyszukaj..."
                                 :close-on-select="false"
                                 :searchable="true"
                                 class="multiselect-green"
                                 noResultsText="brak wyników"
                                 noOptionsText="brak opcji"
                                 :groups="true"
                                 :options="options" />

                </section>

                <label class="esesja-modal__wraper__body__label">Treść</label>
                <section class="esesja-modal__row">
                    <textarea class="esesja-modal__wraper__body__wyswig" v-model="statement"></textarea>
                </section>

                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="this.$emit('closeAddStatement')"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="handleSaveClick" :disabled="statement.length < 3">Wyślij</button>
                </section>
            </perfect-scrollbar>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import Multiselect from '@vueform/multiselect'
import AttachmentsService from "../../../../../services/attachments.service";

export default {
    name: "AddStatementModal",
    data() {
        return {
            isLoading: false,
            statement: this.stat.description,
            value: this.selected,
        };
    },
    props: {
        options: Array,
        selected: Number,
        stat: Object,
        pointId: Number,
    },
    components: {
        LoaderInside,
        Multiselect
    },
    computed: {

    },
    methods: {
        handleSaveClick() {
            this.isLoading = true;

            const data = {
                id: this.stat.id,
                description: this.statement,
                autor: this.value == null ? 0 : this.value
            };

            AttachmentsService.addOrUpdateStatement(this.pointId, data).then(() => {
                this.value = [];
                this.statement = "";
            }).finally(() => {
                this.$emit("closeAddStatement");
                this.$emit("reload");
                this.isLoading = false;
            }); 
        },
    },
    mounted() {
        
    },
};</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>

    @import "../../../../../assets/scss/esesja-modal.scss";
    .multiselect-green {
        --ms-tag-bg: #D1FAE5;
        --ms-tag-color: #059669;
    }
</style>