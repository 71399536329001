<template>
    <ul class="action-list" ref="templateAciontList">
        <router-link v-for="action in templateActions"
                     :to="{
                        name: action.action,
                        params: { sessionid: sessionTemplateId },
                     }"
                     :key="action.id">
            <li class="action-list__item" @click="action.handler(sessionTemplateId)">
                <img :src="
                        require(`@/assets/icons/meetingsActions/${action.icon}.svg`)
                    " />
                <span class="action-list__name">{{ action.name }}</span>
            </li>
        </router-link>
    </ul>
</template>
<script charset="utf-8">
    import { meetingTemplateActions } from "@/componentsData/meetingsTemplateAction.js"
    import { isOnScreen } from "@/helpers/isOnScreen";
    export default {
        name: "MenuSessionTemplateActions",
        components: {
        },
        data() {
            return {
                templateActions: meetingTemplateActions
            }
        },
        props: {
            sessionTemplateId: Number,
        },
        mounted() {
            isOnScreen(this.$refs.templateAciontList);
        },
        methods: {
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/meeting-action-menu.scss";

    .session-template-item:hover .action-list {
        display: block;
    }
</style>