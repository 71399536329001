<template>
    <section ref="selectAudioInputModal" class="esesja-modal" tabindex="0" 
             @keydown.esc="$store.commit('setSelectAudioInputDeviceModal', false)"
             @keydown.enter="setAudioInputDevice">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Wybierz urządzenie audio</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click="$store.commit('setSelectAudioInputDeviceModal', false)">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <section class="esesja-modal__wraper__body">
                <label data-v-ea42a7a6="" class="esesja-modal__wraper__body__label">Dostępne urządzenia audio</label>
                <select v-model="internalSelectAudioDevice" class="esesja-modal__wraper__body__input">
                    <option v-for="(item, index) in availableDevices" :key="index" :value="item">
                        {{ item.label }}
                    </option>
                </select>
                <section class="esesja-modal__wraper__body__bottom" style="margin-top: 20px">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="$store.commit('setSelectAudioInputDeviceModal', false)"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="setAudioInputDevice">Zapisz</button>
                </section>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    // services
    import AlertHelper from "../../../../../helpers/alerts.helper";
    import audioRecorderService from "../../../../../services/audio.recorder.service";


    export default {
        name: "SelectAudioInputDevice",
        data() {
            return {
                internalSelectAudioDevice: null,
            }
        },
        computed: {
            availableDevices() {
                return this.$store.state.audioRecorder.availableDevices
            },

            selectedAudioDevice() {
                return this.$store.state.audioRecorder.selectedDevice;
            }

        },
        async mounted() {
            try {
                this.$store.commit("showLoader");
                this.$refs.selectAudioInputModal.focus();
                await audioRecorderService.getAudioDevices();

                if (this.selectedAudioDevice === null) {
                    if (this.availableDevices.length > 0) {
                        this.internalSelectAudioDevice = this.availableDevices[0]
                    }
                }
                else {
                    this.internalSelectAudioDevice = this.selectedAudioDevice;
                }
            }
            catch {
                var toast = this.$swal.mixin(AlertHelper.errorToastElement());
                toast.fire(AlertHelper.errorToastAlert('Nie udało się pobrać dostępnych urządzń audio"'));
            }
            finally {
                this.$store.commit("hideLoader");
            }
        },
        methods: {
            async setAudioInputDevice() {
                try {
                    this.$store.commit("showLoader");
                    await audioRecorderService.selectAudioDevice(this.internalSelectAudioDevice);
                    this.$store.commit('setSelectAudioInputDeviceModal', false)
                }
                catch {
                    var toast = this.$swal.mixin(AlertHelper.errorToastElement());
                    toast.fire(AlertHelper.errorToastAlert('Nie udało się wybrać urządznia audio do nagrywania'));
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            }
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/esesja-modal.scss';
</style>