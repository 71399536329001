<template>
    <section class="app-spa">
        <section class="app-spa__box">
            <section class="app-spa__box__logo">
                <img src="@/assets/imgs/logo.png" />
            </section>
            <section class="app-spa__box__form">
                <h1 class="app-spa__box__form__header">Potwierdź adres E-Mail</h1>
                <section class="app-spa__box__form__fallback-success" v-if="isSuccess && showResponse">
                    <img src="@/assets/icons/components/success-alert.svg" class="app-spa__box__form__fallback-error__icon" alt="Ikona błędu" />
                    <span class="app-spa__box__form__fallback-error__text">
                        Adres e-mail został potwierdzony
                    </span>
                </section>

                <section class="app-spa__box__form__fallback-error" v-else-if="isError && showResponse">
                    <img src="@/assets/icons/components/exit-btn.svg" class="app-spa__box__form__fallback-error__icon" alt="Ikona błędu" />
                    <span class="app-spa__box__form__fallback-error__text">
                        {{ message }}
                    </span>
                </section>

                <section class="app-spa__box__form__fallback-checking" v-else>
                    <span class="app-spa__box__form__fallback-error__text">
                        Trwa potwierdzanie adresu e-mail
                    </span>
                </section>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    import UserService from "../../../../services/user.service";
    export default {
        name: "UserEmailConfirmation",
        computed: {
            emailConfirmToken() {
                return this.$route.params.token;
            },
        },
        data() {
            return {
                showResponse: false,
                isError: false,
                isSuccess: false,
                message: "",
            }
        },
        async mounted() {
            await this.confirmEmail();
        },
        methods: {
            async confirmEmail() {
                try {
                    this.showResponse = false;
                    this.isError = false;
                    this.isSuccess = false;
                    this.$store.commit("showLoader");
                    let response = await UserService.confirmEmailAddress(this.emailConfirmToken);
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }
                    this.isSuccess = true;
                }
                catch (e) {
                    this.isError = true;
                    this.message = e.response.data;
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.showResponse = true;
                    this.$store.commit("hideLoader");
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "~@/assets/scss/esesja-app-spa.scss";
</style>