<template>
    <section class="session" ref="sessionContainer">

        <section class="session__header">
            <h2 class="session__title">
                <img v-if="session.is_end" src="@/assets/icons/meetingsActions/lock.svg" style="width: 2%;" />
                {{session.name}}
                <span v-if="session.number">
                    nr {{ session.number !== null ? numberConversion(session.number) : "0" }}
                </span>
            </h2>
            <span class="session__place" v-if="session.meeting_place">
                <img src="@/assets/icons/panel/editSession/location-dot.svg" style="filter: brightness(0) invert(0.7);"/>
                <span>{{ session.meeting_place }}</span>
            </span>
        </section>

        <WarningBox v-if="showVotingsGroupAlert">
            Przygotowane posiedzenie zawiera błędy! Istnieją głosowania z błędnym przypisaniem grup.<br />
            Najprawdopodobniej zostały zmienione grupy uczestniczące w posiedzeniu. Edytuj głosowania w celu prawidłowego przypisania grup.
        </WarningBox>

        <section class="session__top">
            <section class="session__top-left">
                <section class="session__top-box session__top-box__separator">
                    <img src="@/assets/icons/panel/editSession/meeting-date.svg" />
                    <span class="session__top-text">{{ getDate }}</span>
                </section>
                <section class="session__top-box">
                    <img src="@/assets/icons/panel/editSession/meeting-time.svg" />
                    <span class="session__top-text">Godzina: {{ getTime }}</span>
                </section>
            </section>
            <section class="session-top-btn-wrapper">
                <button  v-if="session.is_end === false && $store.state.appStore.isInInteractiveMode" 
                    class="session-top-btn-wrapper__button session-top-btn-wrapper__button--white" 
                    v-tooltip="disableDragAndDropTooltip"
                    @click="$store.commit('setIsLockSessionDragAndDrop', !$store.state.interactiveStore.isLockSessionDragAndDrop)" style="width: 92px">
                    <img src="../../../../assets/icons/meetingsActions/lock.svg" v-if="!$store.state.interactiveStore.isLockSessionDragAndDrop" style=" width: 18px;" />
                    <img src="../../../../assets/icons/meetingsActions/lock-open.svg" v-else style=" width: 24px;" />                   
                </button>
                <button v-if="session.is_end == false && $store.state.appStore.isInInteractiveMode == false" class="
                        session-top-btn-wrapper__button
                        session-top-btn-wrapper__button--spacing
                        session-top-btn-wrapper__button--white
                    "
                        @click="isEditMeetingVisible = true">
                    <img src="../../../../assets/icons/components/edit.svg" />
                    <span class="
                            session-top-btn-wrapper__text
                            session-top-btn-wrapper__text--white
                        ">Ustawienia</span>
                </button>
                <button v-if="sessionPublicLink.length > 0 && session.is_end == false"
                        class="
                        session-top-btn-wrapper__button
                        session-top-btn-wrapper__button--spacing
                         session-top-btn-wrapper__button--white
                        "
                        @click="goToPublicSession()">
                    <img src="../../../../assets/icons/panel/editSession/add-point.svg" style="filter: brightness(0) invert(0);" />
                    <span class="
                          session-top-btn-wrapper__text
                          session-top-btn-wrapper__text--white
                          ">Okno wizualizacji</span>
                </button>

                <button v-if="iteractive.isReady == false && session.is_end == false"
                        class="session-top-btn-wrapper__button session-top-btn-wrapper__button--spacing" :disabled="showVotingsGroupAlert == true"
                        @click="startInteractiveSession" v-tooltip="showVotingsGroupAlert == true ? 'Posiedzenie zawiera błędy, nie można rozpocząć posiedzenia' : ''">
                    <img src="../../../../assets/icons/meetingsActions/display.svg" style="filter: brightness(0) invert(1);" />
                    <span class="
                            session-top-btn-wrapper__text
                          ">Rozpocznij</span>
                </button>
                <button v-if="iteractive.isReady == true && session.is_end == false"
                        class="
                        session-top-btn-wrapper__button
                        session-top-btn-wrapper__button--spacing
                        "
                        @click="endInteractiveSession">
                    <img src="../../../../assets/icons/meetingsActions/display.svg" style="filter: brightness(0) invert(1);" />
                    <span class="
                            session-top-btn-wrapper__text
                            session-top-btn-wrapper__text">Zakończ</span>
                </button>

                <button class="session-top-btn-wrapper__button session-top-btn-wrapper__button--white" @click="toggleContexMenuOptions">
                    <img src="../../../../assets/icons/panel/editSession/more2.svg" style=" width: 7px;" />
                </button>
                <SessionSearchContextMenu style="margin-top: 55px; margin-right: 48px; right: 0;"
                                          :sessionId="parseInt($route.params.sessionid)"
                                          :groupId="parseInt($route.params.groupid)"
                                          :skippedActionId="skippedActionId"
                                          :globalbrakeline="globalbrakeline"
                                          :globalSingleSessionSelected="true"
                                          @actionSelected="closeContexMenuOptions" />
            </section>
        </section>
        <SessionPointsList :items="agendaItems"
                           v-if="agendaItems.length > 0 && isLoaded"
                           :session="session"
                           :wrongVotings="wrong_votings"
                           @end="setList"
                           @changeActivePoint="changeActivePoint"
                           @refreshList="getList"
                           @refreshSessionVotings="getAllVotingsInSession"
                           @reloadData="getList"
                           :firstLevel="groupData.agenda_number_format_1_id"
                           :secondLevel="groupData.agenda_number_format_2_id"
                           :thirdLevel="groupData.agenda_number_format_3_id"
                           :checkedPointIds="point_ids"
                           @setPointIds="(value)=> { setPointsIds(value); this.isAddPointToParent = false; }"
                           @handleShowVotingInteractive="handleShowVotingInteractive"
                           @handleShowVotingResults="handleShowVotingResults"
                           @handleaddPointBlockVotingsResults="handleaddPointBlockVotingsResults"
                           @handleEditVotingNameVoting="handleEditVotingNameVoting"
                           @handleDragVotingToPoint="handleDragVotingToPoint"
                           @handleExportVotingToOtherSession="handleExportVotingToOtherSession"
                           @handleCopyVoting="handleCopyVoting"
                           @handleCreateVoting="handleCreateVoting"
                           @handleAddNote="handleAddNote"
                           @handleAddLink="handleAddLink"
                           @handleUpdateFileName="handleUpdateFileName"
                           @handleShowNote="handleShowNote"
                           @handleaddStatement="handleaddStatement"
                           @handlePassResolution="handlePassResolution"
                           @handleOpenAttachmentViewer="handleOpenAttachmentViewer"
                           @handleEditPoint="handleEditPoint" />

        <section v-else>
            <p>Brak punktów</p>
        </section>

        <AddPointBottomMenu :session="session"
                            :isAddPointToParent="isAddPointToParent"
                            v-if="session.is_end == false && (point_ids.length === 0 || isAddPointToParent)"
                            @setSession="(session) => (this.session = session)"
                            @addPoint="(point) => this.addPoint(point)"
                            @refreshList="getList"
                            @cancelAddPointToParent="isAddPointToParent = false" />

        <EditPointsBottomMenu v-if="session.is_end == false && (point_ids.length > 0 && !isAddPointToParent)"
                              :point_ids="point_ids"
                              :firstLevel="groupData.agenda_number_format_1_id"
                              :secondLevel="groupData.agenda_number_format_2_id"
                              :thirdLevel="groupData.agenda_number_format_3_id"
                              @cancel="point_ids = []"
                              @checkAll="(points) => (point_ids = points)"
                              :agendaItems="agendaItems"
                              @addPoint="(point) => this.addPoint(point)"
                              @reloadData="getList"
                              @refreshSessionVotings="getAllVotingsInSession"
                              @refreshList="getList"
                              @pointMoved="setList"
                              @addVotingToMultiplePointsEmit="addVotingToMultiplePoints"
                              @addPointAsChildren="isAddPointToParent = true" />
        <Transition>
            <EditMeeting v-if="isEditMeetingVisible && session.is_end == false"
                         @setVisible="() => (isEditMeetingVisible = false)"
                         :sessionId="session.id"
                         @setSession="reloadSession"
                         />
        </Transition>

        <Transition>
            <CopyPoint v-if="copyPointModalStatus && session.is_end == false" />
        </Transition>


        <!-- specyfic point actions section -->
        <Transition>
            <InteractiveVotingModal v-if="interactiveVotingVisible && votingModule == 1"
                                    :interactiveVoting="interactiveVoting"
                                    :basicData="basicData"
                                    :openAutomatic="open_automatic"
                                    @closeInteractiveVotingt="closeInteractiveVoting"
                                    @saveVotingAction="saveVotingAction"
                                    @reloadData="getList" />
        </Transition>

        <Transition>
            <InteractiveVotingResults v-if="resultsVotingVisible && votingModule == 1"
                                      :basicData="resultsVotingData"
                                      :votingResults="res"
                                      :chartData="chartData"
                                      :isPublicView="false"
                                      :fromEndVoting="fromEndVoting"
                                      @closeResultVotingt="closeResultVotingt"
                                      @closeResultsInVisualization="closeResultsInVisualization"
                                      @showResultsInVisualization="showResultsInVisualization"
                                      @reloadData="getList" />
        </Transition>

        <Transition>
            <InteractiveBlockVotingResults v-if="showPointBlockVotingResults && votingModule == 1"
                                           :pointBlockVotingResults="pointBlockVotingResults"
                                           :pointIdForResults="pointIdForResults"
                                           @closePiontBlockVotingsResults="closePointBlockVotingResults" />
        </Transition>

        <Transition>
            <EditVotingNameModal v-if="showEditVotingNameModal && votingModule == 1 && session.is_end == false"
                                 :tempVotingNameToEdit="tempVotingNameToEdit"
                                 :tempVotingId="tempVotingId"
                                 :pointID="pickedPoint.id"
                                 :session="session.id"
                                 @closeEditVotingNameModal="closeEditVotingNameModal"
                                 @reload="getList" />
        </Transition>

        <Transition>
            <DragVotingToPointModal v-if="showDragVotingToPointModal && votingModule == 1 && session.is_end == false"
                                    :pointsForVotingDrag="pointsForVotingDrag"
                                    :tempVotingToDrag="tempVotingToDrag"
                                    :pointID="pickedPoint.id"
                                    :session="session.id"
                                    :firstLvl="groupData.agenda_number_format_1_id"
                                    :secondLvl="groupData.agenda_number_format_2_id"
                                    :thirdLvl="groupData.agenda_number_format_3_id"
                                    @closeDragVotingToPointModal="closeDragVotingToPointModal"
                                    @reload="getList" />
        </Transition>

        <Transition>
            <ExportVotingToOtherSessionModal v-if="showExportVotingToOtherSession && votingModule == 1 && session.is_end == false"
                                             :pointsForVotingDrag="pointsForVotingDrag"
                                             :tempVotingToExport="tempVotingToExport"
                                             :pointID="pickedPoint.id"
                                             :sessionId="session.id"
                                             :sessions="tempSessionsListToExportVoting"
                                             :firstLvl="groupData.agenda_number_format_1_id"
                                             :secondLvl="groupData.agenda_number_format_2_id"
                                             :thirdLvl="groupData.agenda_number_format_3_id"
                                             :defaultName="exportedVotingDefaultName"
                                             @closeExportVotingToOtherSessionModal="closeExportVotingToOtherSessionModal"
                                             @reload="getList" />
        </Transition>

        <Transition>
            <AddVotingModal v-if="addVotingVisible && votingModule == 1 && session.is_end == false"
                            :voting="voting"
                            :pointIds="tempPointsIdsForVoting"
                            :mode="votingMode"
                            @startVoting="startVotingFromCreation"
                            @closeAddVotingt="addVotingVisible = false; pickedPoint = {}; voting = {}; tempPointsIdsForVoting = []"
                            @reloadData="getList"
                            @getSessionVotings="getAllVotingsInSession"
                            />
        </Transition>

        <Transition>
            <AddNote v-if="addNoteVisible && session.is_end == false"
                    @closeAddNote="addNoteVisible = false; att = {}; pickedPoint = {}"
                    :pointID="pickedPoint.id"
                    :att="att"
                    @reload="getList" />
        </Transition>

        <Transition>
            <AddLink v-if="addLinkVisible && session.is_end == false"
                    @closeAddLink="addLinkVisible = false; att = {}; pickedPoint = {}"
                    :pointID="pickedPoint.id"
                    :att="att"
                    @reload="getList" />
        </Transition>

        <Transition>
            <UpdateFileNameModal v-if="addUpdateFileNameVisible && session.is_end == false"
                             @closeUpdateFileName="addUpdateFileNameVisible = false; att = {}; pickedPoint = {}"
                             :pointID="pickedPoint.id"
                             :att="att"
                             :isResolution="att.isResolution"
                             @reload="getList" />
        </Transition>


        <Transition>
            <ShowNote v-if="showNote"
                    @closeNote="showNote = false; noteID = null; pid = 0;"
                    :noteID="noteID"
                    :pid="pid" />
        </Transition>

        <Transition>
            <AddStatement v-if="addStatementVisible && session.is_end == false"
                        @closeAddStatement="closeStatementModal"
                        :options="statementOptions"
                        :selected="statementSelected"
                        :stat="statement"
                        :pointId="pid"
                        @reload="getList" />
        </Transition>

        <Transition>
            <PassResolutionModal v-if="passResolutionVisible && session.is_end == false"
                                @closePassResolution="passResolutionVisible = false; resolution = {}, pickedPoint = {}"
                                :pointID="pickedPoint.id"
                                :resolution="resolution"
                                :sid="session.id"
                                @reload="getList" />
        </Transition>

        <Transition>
            <AttachmentViewer v-if="attachmentModalOpen"
                            :pointId="attachmentViewer.pointId"
                            :attachmentId="attachmentViewer.attachmentId"
                            :filename="attachmentViewer.filename"
                            :sessionId="session.id"
                            :fileData="attachmentViewer.fileData"
                            @close="cloeAttachmentViewer" />
        </Transition>

        <Transition>
            <EditPoint v-if="editPointVisible && session.is_end == false"
                    :pickedPoint="pickedPoint"
                    @closeEdit="()=>{ this.editPointVisible = false; this.pickedPoint = {}; }"
                    @reloadData="getList"
                    @reloadPoint="(data) => (pickedPoint = data)" />
        </Transition>

        <!-- end specyfic point actions section -->

    </section>
</template>

<script charset="utf-8">
    import SessionSearchContextMenu from "../../../BasicControls/SessionSearchContextMenu.vue";
    import SessionService from "../../../../services/session.service";
    import ContactService from "../../../../services/contact.service";
    import UserService from "../../../../services/user.service";
    import InteractiveService from "../../../../services/interactive.service";
    import eSesjaInteractiveService from "../../../../services/esesja.interactive.service";
    import moment from "moment";
    import { setErrors } from "../../../../formValidation/main";
    //import AlertHelper from '@/helpers/alerts.helper.js';
    import EditMeeting from "../Home/Modals/EditMeeting.vue";
    import AddPointBottomMenu from "./AddPointBottomMenu.vue";
    import SessionPointsList from "./SessionPointsList.vue";
    import EditPointsBottomMenu from "./EditPointsBottomMenu.vue";
    import CopyPoint from "./Modals/CopyPoint.vue";
    import { encryptStorage } from '../../../../services/encrypt.service';
    import { sessionNumberConversion } from "../../../../helpers/session.helper";

    // specyfic point actions section
    import VotingService from "../../../../services/voting.service";
    import RegisterService from "../../../../services/register.service";
    import AttachmentService from "../../../../services/attachments.service";
    import InteractiveVotingModal from "./Modals/InteractiveVotingModal.vue";
    import InteractiveVotingResults from "./Modals/InteractiveVotingResults.vue";
    import InteractiveBlockVotingResults from "./Modals/InteractiveVotingBlockResultsModal.vue";
    import EditVotingNameModal from "./Modals/EditVotingNameModal.vue";
    import DragVotingToPointModal from "./Modals/DragVotingToPointModal.vue";
    import ExportVotingToOtherSessionModal from "./Modals/ExportVotingToOtherSessionModal.vue";
    import AddVotingModal from "./Modals/AddVotingModal.vue";
    import AddNote from "./AddNote.vue";
    import AddLink from "./Modals/AddLinkModal.vue";
    import UpdateFileNameModal from "./Modals/UpdateFileNameModal.vue";
    import ShowNote from "./ShowNote.vue";
    import AddStatement from "./Modals/AddStatementModal.vue";
    import PassResolutionModal from "./Modals/PassResolutionModal.vue";
    import AttachmentViewer from "./Modals/AttachmentViewer.vue";
    import EditPoint from "./EditPointModal/EditPoint.vue";
    import sessionService from "../../../../services/session.service";
    import PointsNumerationHelper from "@/helpers/pointsNumeration.helper";
    import WarningBox from "@/components/BasicControls/WarningBox.vue";
    //import AudioRecorder from "../AudioRecorder/AudioRecorder.vue";

    export default {
        name: "sessionEdit",
        data() {
            return {
                wrong_votings: [],
                showVotingsGroupAlert: false,
                allVotingsInSession: [],
                bipModule: encryptStorage.getItem('modules').bip,
                camModule: encryptStorage.getItem('modules').cam,
                session: {},
                agendaItems: [],
                isLoaded: false,
                errors: [],
                isEditMeetingVisible: false,
                point_ids: [],
                groupData: {},
                isAddPointToParent: false,
                pickedPoint: {},
                sessionPublicLink: '',
                bottom: '',
                iteractive: {
                    isReady: false
                },
                skippedActionId: [],                
                optionsContextMenu: null,
                globalbrakeline: false,

                // specyfic point actions section
                votingModule: encryptStorage.getItem('modules').g,
                editPointVisible: false,
                interactiveVotingVisible: false,
                open_automatic: false,
                interactiveVoting: {},
                basicData: {},
                chartData: {
                    labels: [],
                    datasets: [{ data: [] }],
                },
                resultsVotingVisible: false,
                resultsVotingData: {},
                res: {},
                showPointBlockVotingResults: false,
                pointBlockVotingResults: [],
                pointIdForResults: 0,
                showEditVotingNameModal: false,
                tempVotingNameToEdit: '',
                tempVotingId: 0,
                tempPointsIdsForVoting: [],
                showDragVotingToPointModal: false,
                pointsForVotingDrag: [],
                tempVotingToDrag: {},
                showExportVotingToOtherSession: false,
                tempVotingToExport: {},
                tempSessionsListToExportVoting: [],
                exportedVotingDefaultName: '',
                addVotingVisible: false,
                voting: {},
                votingMode: "standard",
                addNoteVisible: false,
                att: {},
                addLinkVisible: false,
                addUpdateFileNameVisible: false,
                showNote: false,
                fromEndVoting: false,
                noteID: null,
                pid: 0,
                addStatementVisible: false,
                statementOptions: [],
                statementSelected: null,
                statement: { id: 0, autor: 0, description: '', date: null },
                passResolutionVisible: false,
                resolution: {},
                attachmentModalOpen: false,
                attachmentViewer: {
                    pointId: -1,
                    attachment: -1,
                    fileData: {},
                    filename: "",
                },
                suupportAttachmentContentType: [
                    'application/pdf',
                    "image/apng",
                    "image/avif",
                    "image/gif",
                    "image/jpeg",
                    "image/png",
                    "image/svg+xml",
                    "image/webp",
                    "text/plain",
                ],

            };
        },
        components: {
            AddPointBottomMenu,
            SessionPointsList,
            EditMeeting,
            EditPointsBottomMenu,
            CopyPoint,
            SessionSearchContextMenu,

            // specyfic point actions section
            InteractiveVotingModal,
            InteractiveVotingResults,
            InteractiveBlockVotingResults,
            EditVotingNameModal,
            DragVotingToPointModal,
            ExportVotingToOtherSessionModal,
            AddVotingModal,
            AddNote,
            AddLink,
            UpdateFileNameModal,
            ShowNote,
            AddStatement,
            PassResolutionModal,
            AttachmentViewer,
            EditPoint,
            WarningBox
            //AudioRecorder
        },
        created() {
            var $this = this;
            window.addEventListener('beforeunload', function (e) {
                if ($this.iteractive.isReady) {
                    e = e || window.event;

                    // browsers block custom alerts - shows only defaults

                    if (e) {
                        e.returnValue = 'Sure?';
                    }

                    // For Safari
                    return 'Sure?';
                }
            });
        },
        mounted() {
            this.iteractive.isReady = this.$store.state.appStore.isInInteractiveMode;

            this.getData();

            document.addEventListener("click", (e) => {
                let agendaContext = document.getElementsByClassName("display-context-menu");
                let isClickInside = false;
                for (let item of agendaContext) {
                    if (!isClickInside) {
                        isClickInside = item.contains(e.target);
                    }

                }

                if (!isClickInside) {
                    for (let item of agendaContext) {
                        if (item.classList.contains("session-search-context-menu")) {
                            item.style.display = "none"
                            
                        }

                    }
                }
            });
        },
        computed: {
            getDate() {
                return `${moment(this.session.meeting_date).format(
                    "dddd"
                )}, ${moment(this.session.meeting_date).format("DD.MM.YYYY")}`;
            },
            getTime() {
                return moment(this.session.meeting_date).format("LT");
            },

            copyPointModalStatus() {
                return this.$store.state.appStore.isSessionCopyPoint;
            },

            anyModalOpen() {
                return this.addStatementVisible ||
                    this.showNote ||
                    this.passResolutionVisible ||
                    this.addUpdateFileNameVisible ||
                    this.addLinkVisible ||
                    this.addNoteVisible ||
                    this.showPointBlockVotingResults ||
                    this.resultsVotingVisible ||
                    this.interactiveVotingVisible ||
                    this.addVotingVisible ||
                    this.editPointVisible ||
                    this.showEditVotingNameModal ||
                    this.showDragVotingToPointModal ||
                    this.showExportVotingToOtherSession ||
                    this.attachmentModalOpen ||
                    this.isEditMeetingVisible
            },
            disableDragAndDropTooltip(){
                if(this.$store.state.interactiveStore.isLockSessionDragAndDrop){
                    return "Odblokuj edycje agendy metodą przeciągnij i upuść";
                }
                return "Zablokuj edycje agendy metodą przeciągnij i upuść";
            }
        },
        watch: {
            $route() {
                this.getData();
                this.point_ids = [];
            },

            '$store.state.appStore.agendaForSessionImported': {
                deep: true,
                handler: function (newValue) {
                    if (newValue && this.$route.name === "sessionEdit") {
                        this.$store.commit("setagendaForSessionImported", false);
                        this.getData();
                    }
                }
            },

            '$store.state.appStore.sessionLocked': {
                deep: true,
                handler: function (newValue) {
                    if (newValue && this.$route.name === "sessionEdit") {
                        this.$store.commit("setLockSessionStatus", false);
                        this.getData();
                    }
                }
            },

            '$store.state.appStore.sessionUnlocked': {
                deep: true,
                handler: function (newValue) {
                    if (newValue && this.$route.name === "sessionEdit") {
                        this.$store.commit("setUnlockSessionStatus", false);
                        this.getData();
                    }
                }
            },

            '$store.state.appStore.sessionEdited': {
                deep: true,
                handler: function (newValue) {
                    if (newValue && this.$route.name === "sessionEdit") {
                        this.$store.commit("setEditSessionStatus", false);
                    }
                }
            },

            anyModalOpen: {
                deep: true,
                handler: function (val) {
                    document.querySelector('body').style.overflow = val ? 'hidden' : 'auto';
                    this.$store.commit('setAnySessionModalOpenStatus', val);
                }
            },

        },
        methods: {
            checkSkippedActions() {
                if (this.$store.state.appStore.isInInteractiveMode) {
                    this.globalbrakeline = false;
                    this.skippedActionId = [0, 15, 16, 20, 30, 40, 60, 64, 65, 69, 70, 80, 95, 98, 99, 100];

                    if (this.camModule == 0 || this.session.is_end == true) {
                        this.skippedActionId.push(96);
                    }

                }
                else {
                    this.globalbrakeline = true;
                    this.skippedActionId = [0, 10, 96, 100];
                    if (this.bipModule == 0 || this.session.is_end == true) {
                        this.skippedActionId.push(95);
                    }

                    if (this.session.is_end == true) {
                        this.skippedActionId.push(99);
                        this.skippedActionId.push(80);
                        this.skippedActionId.push(60);
                        this.skippedActionId.push(40);
                        this.skippedActionId.push(30);
                        this.skippedActionId.push(20);
                        this.skippedActionId.push(65);
                        this.skippedActionId.push(64);
                        this.skippedActionId.push(68);
                        this.skippedActionId.push(69);
                    } else {
                        this.skippedActionId.push(98);
                    }
                } 
            },
            setPointsIds(ids) {
                this.point_ids = ids;
            },
            registerInteractiveEvents() {
                eSesjaInteractiveService.events.on("SignalREndsPoints", this.setPointsDone);
                //eSesjaInteractiveService.events.on("CurrentAgendaItems", this.CurrentAgendaItems);
                eSesjaInteractiveService.events.on("ReloadSessionForCaller", this.ReloadSessionForCaller);
            },
            unregisterInteractiveEvents() {
                eSesjaInteractiveService.events.removeListener("SignalREndsPoints", this.setPointsDone);
                //eSesjaInteractiveService.events.removeListener("CurrentAgendaItems", this.CurrentAgendaItems);
                eSesjaInteractiveService.events.removeListener("ReloadSessionForCaller", this.ReloadSessionForCaller);
            },
            setPointsDone(points) {
                if (points != null && points.length > 0) {

                    for (var i = 0; i < points.length; i++) {
                        this.agendaItems.forEach((el) => {
                            if (el.id == points[i]) {
                                el.is_done = true;
                                el.done_at = new Date();
                            }
                            el.children.forEach((e) => {
                                if (e.id == points[i]) {
                                    e.is_done = true;
                                    e.done_at = new Date();
                                }
                                e.children.forEach((c) => {
                                    if (c.id == points[i]) {
                                        c.is_done = true;
                                        c.done_at = new Date();
                                    }
                                });
                            });
                        });
                    }
                }
            },
            dectivatePoints() {
                this.agendaItems.forEach((el) => {
                    el.is_active = false;
                    el.children.forEach((ew) => {
                        ew.is_active = false;
                        ew.children.forEach((c) => {
                            c.is_active = false;
                        });
                    });
                });
            },
            activatePoint(point) {
                this.agendaItems.forEach((el) => {
                    el.id === point ? el.is_active = true : el.is_active = false;

                    el.children.forEach((e) => {
                        e.id === point ? e.is_active = true : e.is_active = false;
                        e.children.forEach((c) => {
                            c.id === point ? c.is_active = true : c.is_active = false;
                            c.child_is_active = false;
                        });

                        let res = e.children.filter(c => c.is_active);
                        if (res !== undefined && res.length > 0) {
                            e.child_is_active = true;
                        }
                        else {
                            e.child_is_active = false;
                        }
                    });

                    let res = el.children.filter(e => e.is_active || e.child_is_active);
                    if (res !== undefined && res.length > 0) {
                        el.child_is_active = true;
                    }
                    else {
                        el.child_is_active = false;
                    }
                });
            },
            async ReloadSessionForCaller(sessionId) {
                if (this.isInteractive()) {
                    if (sessionId == parseInt(this.session.id)) {
                        this.$store.commit("showLoader");
                        this.point_ids = [];
                        SessionService.getPoints(this.session.id).then(async (response) => {
                            this.agendaItems = response.data ?? [];
                            this.agendaItems = PointsNumerationHelper.setOrderToDisplayRecursive([...this.agendaItems]);
                            await this.changeActivePoint();
                        }).finally(() => {
                            this.$store.commit("hideLoader");
                        });
                    }
                }
            },
            async reloadVisualisation() {
                if (this.isInteractive()) {
                    await eSesjaInteractiveService.changeInAgenda(this.session.id);
                    await this.changeActivePoint();
                }
            },
            async changeActivePoint() {
                if (this.isInteractive()) {

                    var activePoint = this.$store.state.interactiveStore.activePoint;

                    if (activePoint == 0) {
                        this.dectivatePoints();
                    }
                    else {
                        this.activatePoint(activePoint);
                    }
                }
            },
            isInteractive() {
                return this.$store.state.appStore.isInInteractiveMode;
            },

            async startInteractiveSession() {
                try {
                    this.registerInteractiveEvents();

                    this.$store.commit("showLoader");

                    let response = await UserService.getCurrentRid();

                    if (response.status !== 200)
                        throw new Error("Problem with get rid");

                    let activateResponse = await SessionService.activateSession(this.session.id);

                    if (activateResponse.status !== 200)
                        throw new Error("Cannot activate session");

                    this.iteractive.isReady = await eSesjaInteractiveService.start(response.data);

                    await eSesjaInteractiveService.joinRoom(this.session.id)

                    await eSesjaInteractiveService.changeInteractiveSessionStatus(this.session.id, true);

                    this.$store.commit("turnOnInteractive");

                    InteractiveService.getActivePoint(this.session.id).then((response) => {

                        var point = 0;
                        if (response.data.pid != null)
                            point = response.data.pid;

                        this.$store.commit("setActivePoint", point);

                        this.changeActivePoint();
                    });

                    this.checkSkippedActions();

                    await eSesjaInteractiveService.sendMainViewToCameraIntegration(this.session.id)
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            async endInteractiveSession() {
                try {
                    this.$store.commit("showLoader");
                    this.$store.commit('setIsLockSessionDragAndDrop', false);
                    this.dectivatePoints();
                    await sessionService.deActivateSession(this.session.id);
                    await eSesjaInteractiveService.changeInteractiveSessionStatus(this.session.id, false);
                    //await eSesjaInteractiveService.changeActivePointInSession(0, this.session.id, false);
                    await eSesjaInteractiveService.leaveRoom(this.session.id);

                    await eSesjaInteractiveService.stop();
                    
                    this.iteractive.isReady = false;
                    this.$store.commit("turnOffInteractive");

                    this.checkSkippedActions();

                    this.unregisterInteractiveEvents();
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            findParentElement(pointsData) {
                try {
                    var index = pointsData.find(x => x.id == this.point_ids[0]);

                    if (index !== undefined)
                        return index;

                    for (var el in pointsData) {
                        var childRet = this.findParentElement(pointsData[el].children);
                        if (childRet !== undefined) {
                            return childRet;
                        }
                    }
                }
                catch (e) {
                    console.error(e);
                }
            },

            addPoint(pointName) {
                const newPoint = {
                    name: pointName,
                    order: this.agendaItems.length + 1,
                };

                if (this.isAddPointToParent) {
                    newPoint["parent_id"] = this.point_ids[0];
                    this.findParentElement(this.agendaItems);
                    //var index = this.agendaItems.find(x => x.id == this.point_ids[0]);
                    var index = this.findParentElement(this.agendaItems);
                    var order = index.children.length + 1;
                    newPoint.order = order;
                }

                this.$store.commit("showLoader");
                this.errors = [];
                SessionService.addPoint(this.session.id, newPoint)
                    .then(() => {
                        this.$store.commit("hideLoader");

                        SessionService.getPoints(this.session.id).then(
                            (response) => {
                                this.agendaItems = response.data ?? []
                                this.agendaItems = PointsNumerationHelper.setOrderToDisplayRecursive([...this.agendaItems]);
                                if (!this.isAddPointToParent) {

                                    setTimeout(() => {
                                        this.$refs.sessionContainer.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                                    }, 100);
                                }

                                this.isAddPointToParent = false;   
                                this.reloadVisualisation();
                            }
                        );
                        
                    })
                    .catch((err) => {
                        this.$store.commit("hideLoader");
                        this.errors = setErrors(err);
                    });
            },
            reloadSession(data) {
                this.$router.replace({ name: "sessionEdit", params: { sessionid: data.id, groupid: data.groups[0].id } });
                this.getData();
            },
            getData() {
                this.$store.commit("showLoader");
                const id = parseInt(this.$route.params.sessionid);
                this.$store.commit("setSessionId", id);
                const groupID = this.$route.params.groupid;
                if (id !== undefined && groupID !== undefined) {
                    ContactService.showGroupData(parseInt(groupID)).then((response) => {
                        this.groupData = response.data;
                        const anyMatched = response.data.sessions.find(
                            (session) => (session.id = id)
                        );
                        if (anyMatched !== undefined) {
                            SessionService.getSessionWithPointsById(id).then((response) => {
                                this.session = response.data;
                                this.isLoaded = true;
                                this.$store.commit("hideLoader");
                                this.agendaItems = response.data.agendaItems ?? []
                                
                                this.agendaItems = PointsNumerationHelper.setOrderToDisplayRecursive([...this.agendaItems]);

                                SessionService.getPublicSessionLink(id).then((response) => {
                                    this.sessionPublicLink = response.data.link;
                                });

                                this.checkSkippedActions();
                                this.getAllVotingsInSession();
                            });
                        } else {
                            this.$router.push({ name: "home" });
                            //this.$store.commit("showError");
                        }
                    });
                } else {
                    this.$store.commit("hideLoader");
                }
            },
            async getAllVotingsInSession() {
                if (this.votingModule == 1) {
                    try {
                        var data = { groups : [] };
                        for (var i = 0; i < this.session.groups.length; i++) {
                            data.groups.push(this.session.groups[i].id)
                        }
                        let result = await VotingService.getAllVotingsInSession(this.session.id, data)

                        if (result.status == 200) {
                            this.allVotingsInSession = result.data;
                            this.validateVotingsGroups();
                        }

                    } catch (e) {
                        if (e.response.status == 401)
                            this.$router.push({ name: 'Login' })
                    }
                }
            },
            validateVotingsGroups() {
                this.wrong_votings = [];
                var selected_session_groups = this.session.groups;
                var session_gropus_ids = selected_session_groups.map((group) => group.id);

                if (this.allVotingsInSession != null && this.allVotingsInSession.length > 0) {

                    // check specyfic voting
                    var wrong_votings = [];
                    for (var j = 0; j < this.allVotingsInSession.length; j++) {

                        if (this.allVotingsInSession[j].selected_groups.length == 0) {
                            wrong_votings.push(this.allVotingsInSession[j]);
                        }
                        else if (this.allVotingsInSession[j].selected_groups.every(v => session_gropus_ids.includes(v)) == false) {
                            wrong_votings.push(this.allVotingsInSession[j]);
                        }
                    }

                    if (wrong_votings.length > 0) {
                        this.showVotingsGroupAlert = true;
                    } else {
                        this.showVotingsGroupAlert = false;
                    }

                    this.wrong_votings = wrong_votings;
                }
                else {
                    this.showVotingsGroupAlert = false;
                    return;
                }
            },
            getList() {
                this.$store.commit("showLoader");
                this.point_ids = [];
                SessionService.getPoints(this.session.id).then((response) => {
                    
                    this.agendaItems = response.data ?? [];
                    this.agendaItems = PointsNumerationHelper.setOrderToDisplayRecursive([...this.agendaItems]);
                    this.reloadVisualisation();
                }).finally(() => {
                    this.$store.commit("hideLoader");
                });
            },
            setList() {
                const newList = this.orderRecursive(this.agendaItems);
                const id = parseInt(this.$route.params.sessionid);
                if (newList.length > 0) {
                    this.$store.commit("showLoader");
                    SessionService.movePoints(id, newList).then(() =>{
                        this.agendaItems = PointsNumerationHelper.setOrderToDisplayRecursive([...this.agendaItems]);
                        this.getList();
                        this.reloadVisualisation();
                    })
                }
            },
            orderRecursive(list) {
                return list.map((item, key) => {
                    item.order = key + 1;
                    item.children = this.orderRecursive(item.children);
                    return item;
                });
            },
            numberConversion(index) {
                return sessionNumberConversion(this.groupData.session_number_format_id, index);
            },
            goToPublicSession() {
                var link = "/PublicSession/" + this.sessionPublicLink;
                window.open(link, "_blank");
            },
            toggleContexMenuOptions(e) {
                e.preventDefault();
                if (this.optionsContextMenu !== null) {
                    if (this.optionsContextMenu.style.display === "none") {
                        this.optionsContextMenu.style.display = "block";
                        e.target.classList.add("display-context-menu")
                        this.optionsContextMenu.classList.add("display-context-menu");
                    }
                    else {
                        this.optionsContextMenu.style.display = "none";
                    }
                    return;
                }


                const agendaContext = document.querySelectorAll(
                    ".session-search-context-menu"
                );
                agendaContext.forEach((block) => (block.style.display = "none"));
                this.optionsContextMenu = agendaContext[0];
                this.optionsContextMenu.style.display = "block";
                e.target.classList.add("display-context-menu")
                this.optionsContextMenu.classList.add("display-context-menu");
            },
            closeContexMenuOptions() {
                if (this.optionsContextMenu !== null) {
                    this.optionsContextMenu.style.display = "none";
                }
            },
            setErrors,

            async startVotingFromCreation(pointId, votingId) {
                let pointObj = {
                    id: pointId
                }

                let votingObj = {
                    id: votingId
                }

                await this.handleShowVotingInteractive(pointObj, votingObj, false);
            },

            // specyfic point actions section
            async handleShowVotingInteractive(point, voting, open_automatic) {
                if (this.votingModule == 1) {
                    try {
                        this.open_automatic = open_automatic;
                        let result = await VotingService.getVotingInteractiveData(this.session.id, point.id, voting.id)

                        if (result.status == 200) {
                            this.basicData = result.data;

                            if (result.data.isCheckPresence == true) {
                                this.basicData.users.forEach((el) => { el.presence = null; });
                            }

                            this.interactiveVotingVisible = true;
                            this.resultsVotingVisible = false;
                            this.fromEndVoting = false;
                            this.chartData = {
                                labels: [],
                                datasets: [{ data: [] }]
                            };
                        }

                    } catch (e) {
                        if (e.response.status == 401)
                            this.$router.push({ name: 'Login' })
                    }
                }
            },
            async saveVotingAction(data) {
                if (this.votingModule == 1) {
                    this.getList();

                    if (this.$store.state.interactiveStore.startAutomaticVotingByTimeSet.isAutomatic || this.$store.state.settings.client.autoGoToNextVotingAfterSave) {
                        await this.openNextVotingModalForAutomatic(data);
                    }
                    else {
                        await this.showResultsAfterVoting(data);
                    }
                }
            },

            closeInteractiveVoting(){
                this.interactiveVotingVisible = false; 
                this.pickedPoint = {}; 
                this.interactiveVoting = {}; 
                this.basicData = {};
            },
            async openNextVotingModalForAutomatic(data) {
                if (data != null) {
                    if (this.$store.state.interactiveStore.startAutomaticVotingByTimeSet.isAutomatic || this.$store.state.settings.client.autoGoToNextVotingAfterSave) {
                        var pointId = data.pointId;
                        var votingId = data.votingId;

                        var pointVotings = this.findNextVoting(pointId);
                        var votings = pointVotings.votings.filter(x => x.status != 3 && x.id != votingId);

                        if (votings != null && votings.length > 0) {
                            var order = votings.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
                            this.handleShowVotingInteractive(pointVotings, order[0], true);
                        }
                        //else {
                        //    await this.showResultsAfterVoting(data);
                        //}
                    }
                }
            },
            findNextVoting(pointId) {
                var tempPoint = {};
                this.agendaItems.forEach((el) => {
                    if (el.id == pointId) {
                        tempPoint = el;
                    }
                    el.children.forEach((e) => {
                        if (e.id == pointId) {
                            tempPoint = e;
                        }
                        e.children.forEach((c) => {
                            if (c.id == pointId) {
                                tempPoint = c;
                            }
                        });
                    });
                });

                return tempPoint;
            },
            async showResultsAfterVoting(data) {
                if (data != null && this.votingModule == 1) {
                    this.fromEndVoting = true;
                    await this.handleShowVotingResults(data.pointId, data.votingId, this.session.id)
                }
            },
            async closeResultVotingt() {
                this.resultsVotingVisible = false;
                this.fromEndVoting = false;
                this.pickedPoint = {};
                this.interactiveVoting = {};
                this.resultsVotingData = {};
                this.chartData = { labels: [], datasets: [{ data: [] }] };

                if (this.isInteractive()) {
                    await eSesjaInteractiveService.closeResultVotingModal(this.session.id);
                }
            },
            async closeResultsInVisualization() {
                if (this.isInteractive()) {
                    await eSesjaInteractiveService.closeResultVotingModal(this.session.id);
                }
            },
            async showResultsInVisualization() {
                if (this.isInteractive()) {
                    await eSesjaInteractiveService.openResultVotingModal(this.session.id, this.res, this.resultsVotingData);
                }
            },
            async handleShowVotingResults(point, voting, sessionId) {
                try {
                    this.$store.commit("showLoader");
                    let result = await VotingService.getVotingInteractiveDataForResults(sessionId, point, voting)

                    if (result.status == 200) {
                        this.resultsVotingData = result.data;

                        var resultVoting = (await VotingService.getVotingsSummaryResults(voting, this.resultsVotingData.mode.id, this.resultsVotingData.isPublic, this.resultsVotingData.gType));
                        if (resultVoting.status == 200) {
                            this.res = resultVoting.data;
                            
                            if (this.resultsVotingData.gType != 4) {
                                var tempdata = [];

                                if (this.res != null) {
                                    this.chartData.datasets[0].backgroundColor = []
                                    for (var i = 0; i < this.res.options.length; i++) {
                                        this.chartData.labels.push(this.res.options[i].option);
                                        tempdata.push(this.res.options[i].count);
                                        this.chartData.datasets[0].data = tempdata;
                                        this.chartData.datasets[0].backgroundColor.push(this.formatAnswersColor(this.res.options[i].option));
                                    }
                                }
                            }

                            this.resultsVotingVisible = true;
                            this.interactiveVotingVisible = false;
                        }
                    }

                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally{
                    this.$store.commit("hideLoader");
                }
            },
            formatAnswersColor(result) {
                if (result != null && result.length > 0) {
                    switch (result) {
                        case 'ZA':
                        case 'TAK':
                            return '#79e563';
                        case 'PRZECIW':
                        case 'NIE':
                            return '#e40030';
                        case 'WSTRZYMUJĘ SIĘ':
                        case 'NIE WIEM':
                            return '#ffce56';
                        case 'BRAK GŁOSU':
                            return '#c0c0c0';
                        case 'NIEOBECNI':
                        case 'NIEOBECNY':
                            return '#000';
                        case 'WYŁĄCZENI':
                            return '#363532';
                        default:
                            return '#7cd1eb';
                    }
                }
            },
            closePointBlockVotingResults() {
                this.pointBlockVotingResults = [];
                this.showPointBlockVotingResults = false;
                this.pointIdForResults = 0;
            },
            handleaddPointBlockVotingsResults(point) {
                VotingService.getBlockVotingsResults(point.id, this.session.id).then((result) => {
                    if (result.status == 200) {
                        this.pointBlockVotingResults = result.data;
                        this.showPointBlockVotingResults = true;
                        this.pointIdForResults = point.id;
                    }
                });
            },
            handleEditVotingNameVoting(item, voting) {
                if (this.votingModule == 1 && voting.id > 0) {
                    this.tempVotingId = voting.id;
                    this.tempVotingNameToEdit = voting.name;
                    this.pickedPoint = item;
                    this.showEditVotingNameModal = true;
                }
            },
            closeEditVotingNameModal() {
                this.tempVotingId = 0;
                this.tempVotingNameToEdit = '';
                this.pickedPoint = {};
                this.showEditVotingNameModal = false;
            },
            handleDragVotingToPoint(item, voting) {
                if (this.votingModule == 1 && voting.id > 0) {
                    this.pointsForVotingDrag = this.agendaItems;
                    this.pickedPoint = item;
                    this.tempVotingToDrag = voting;
                    this.showDragVotingToPointModal = true;
                }
            },
            closeDragVotingToPointModal() {
                this.tempVotingToDrag = {};
                this.pointsForVotingDrag = [];
                this.pickedPoint = {};
                this.showDragVotingToPointModal = false;
            },
            handleExportVotingToOtherSession(item, voting) {
                this.$store.commit("showLoader");
                SessionService.getAllSessionsYear().then((response) => {
                    this.tempSessionsListToExportVoting = response.data;
                    this.pickedPoint = item;
                    this.tempVotingToExport = voting;
                    this.showExportVotingToOtherSession = true;
                }).catch((e) => {
                    console.log(e)
                });

                VotingService.getExportVotingDefaultName(this.session.id, item.id, voting.id).then((response) => {
                    var resp = response.data;
                    if (resp != null && resp != undefined) {
                        var temp = '';
                        for (var i = 0; i < resp.length; i++) {
                            temp += resp[i] + ", ";
                        }
                        temp = temp.slice(0, -2);
                        this.exportedVotingDefaultName += temp + " - opinia";
                    }

                }).catch((e) => {
                    console.log(e);
                });

                this.$store.commit("hideLoader");
            },
            async addVotingToMultiplePoints(ids) {
                this.tempPointsIdsForVoting = ids;
                var data = {
                    id: 0,
                    name: ''
                };
                
                await this.handleCreateVoting(data, null);
            },
            closeExportVotingToOtherSessionModal() {
                this.pickedPoint = {};
                this.tempVotingToExport = {};
                this.tempSessionsListToExportVoting = [];
                this.exportedVotingDefaultName = '';
                this.showExportVotingToOtherSession = false;
            },
            async handleCopyVoting(item, voting) {
                try {
                    this.$store.commit("showLoader");
                    if (this.votingModule !== 1 || voting.id == 0) {
                        throw new Error();
                    }
                    this.votingMode = "custom"
                    let response = await VotingService.getVotingData(voting.id, item.id, this.session.id);
                    let data = { ...this.getVotingDataTemplate(voting.name, item.id) };
                    data = { ...this.mapVotingTemplateToData({ ...data }, { ...response.data }) }
                    data.name = `${data.name} (REASUMPCJA)`;
                    data.id = 0;
                    this.voting = data;
                    this.addVotingVisible = true;
                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally{
                    this.$store.commit("hideLoader");
                }
                
            },
            async handleCreateVoting(item, edit) {
                if (this.votingModule == 1) {
                    try {
                        this.$store.commit("showLoader");
                        this.votingMode = "standard";
                        var data = { ...this.getVotingDataTemplate(item.name, item.id) };
                        if (edit == null) {
                            var result1 = (await VotingService.getBasicVotingModesData()).data;
                            if (Object.keys(result1).length > 0) {
                                data.modes = result1;
                            }
                        }
                        else {
                            var result = (await VotingService.getVotingData(edit, item.id, this.session.id)).data;
                            if (Object.keys(result).length > 0) {

                                data = { ...this.mapVotingTemplateToData({ ...data }, { ...result })}
                            }
                        }
                        this.voting = data;
                        this.voting.forMultipleData = true
                        this.addVotingVisible = true;

                    } catch (e) {
                        if (e.response.status == 401)
                            this.$router.push({ name: 'Login' })
                    }
                    finally{
                        this.$store.commit("hideLoader");
                    }
                }
            },

            getVotingDataTemplate(name, pointId) {
                return {
                    name: name,
                    description: "ZA\r\nPRZECIW\r\nWSTRZYMUJĘ SIĘ",
                    kworum: 0,
                    max: 1,
                    min: 1,
                    selected_answers: 'ZA/PRZECIW/WSTRZYMUJĘ SIĘ',
                    is_public_voting: true,
                    is_secret_voting: false,
                    is_application: false,
                    check_presence: false,
                    is_special_voting: false,
                    selected: 1,
                    sid: this.session.id,
                    pid: pointId,
                    selected_groups: [],
                    pinCode: '',
                    modes: [],
                    templates: [{ id: 1, temp: 'ZA/PRZECIW/WSTRZYMUJĘ SIĘ' }, { id: 2, temp: 'TAK/NIE/NIE WIEM' }, { id: 3, temp: 'ZA/PRZECIW' }],
                    id: 0,
                    is_hide_voting: false,
                    excluded_count: 0
                }
            },
            mapVotingTemplateToData(data, result) {
                data.modes = result.modes;
                data.name = result.voting.name;
                data.description = result.voting.description;
                data.kworum = result.voting.kworum;
                data.max = result.voting.max;
                data.min = result.voting.min;
                data.selected_answers = result.voting.selected_answers;
                data.is_public_voting = result.voting.is_public_voting;
                data.is_secret_voting = result.voting.is_secret_voting;
                data.is_application = result.voting.is_application;
                data.check_presence = result.voting.check_presence;
                data.is_special_voting = result.voting.is_special_voting;
                data.selected = result.voting.selected;
                data.sid = this.session.id;
                data.selected_groups = result.voting.selected_groups;
                data.pinCode = result.voting.pinCode;
                data.id = result.voting.id;
                data.is_hide_voting = result.voting.hide;
                data.excluded_count = result.voting.excluded_count;
                return data;
            },
            handleAddNote(item, point, isEdit) {
                if (isEdit)
                    this.att = item;

                if (Object.keys(this.att).length === 0) {
                    this.att = {
                        id: 0,
                        name: '',
                        description: '',
                        public: false
                    };
                    this.pickedPoint = item;
                } else {
                    this.pickedPoint = point;
                }

                this.addNoteVisible = true;
            },
            handleAddLink(item, point, isEdit) {
                if (isEdit)
                    this.att = item;

                if (Object.keys(this.att).length === 0) {
                    this.att = {
                        id: 0,
                        name: '',
                        url: 'http://',
                        public: false
                    };
                    this.pickedPoint = item;
                } else {
                    this.pickedPoint = point;
                }
                this.addLinkVisible = true;
            },
            handleUpdateFileName(item, point, isEdit, isResolution) {
                if (isEdit){
                    this.att = item;
                    this.att.isResolution = isResolution;
                }


                if (Object.keys(this.att).length === 0) {
                    this.att = {
                        id: 0,
                        name: '',
                        public: false,
                        show_in_register: false,
                        isResolution: isResolution,
                    };
                    this.pickedPoint = item;
                } else {
                    this.pickedPoint = point;
                }
                this.addUpdateFileNameVisible = true;
            },
            handleShowNote(item, id) {
                this.pid = item;
                this.noteID = id;
                this.showNote = true;
            },
            closeStatementModal() {
                this.addStatementVisible = false;
                this.pickedPoint = {};
                this.statementOptions = [];
                this.statementSelected = null;
                this.statement = { id: 0, autor: 0, description: '', date: null };
                this.pid = 0;
            },
            async handleaddStatement(point, stat) {
                try {
                    var result = (await ContactService.getUsersToSmsOrEmail(3)).data;
                    if (Object.keys(result).length > 0) {
                        this.statement = { id: 0, autor: 0, description: '', date: null };
                        this.pickedPoint = point;
                        this.statementOptions = result;
                        this.statementSelected = null;
                        this.pid = point.id;

                        if (stat != null && stat != undefined) {
                            if (stat.autor > 0)
                                this.statementSelected = stat.autor;

                            this.statement = stat;
                        }

                        this.addStatementVisible = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            handlePassResolution(item, point) {
                if (item.type_id == 1) {
                    RegisterService.getRegisterByAttachementId(item.id, point.id).then((result) => {
                        this.resolution = {
                            id: result.data.id,
                            name: item.name,
                            isPass: result.data.isPass,
                            number: result.data.number,
                            attId: result.data.attId
                        };
                        this.pickedPoint = point;
                        this.passResolutionVisible = true;
                    });
                }
            },
            async cloeAttachmentViewer() {
                try {
                    if (this.isInteractive()) {
                        await eSesjaInteractiveService.closeAttachment(this.session.id);
                    }
                    this.attachmentViewer.fileData = {};
                    this.attachmentModalOpen = false;
                }
                catch (e) {
                    console.log(e);
                }
            },
            async handleOpenAttachmentViewer(pointId, attachmentId, name) {
                try {
                    this.$store.commit('showLoader');
                    let response = await AttachmentService.downloadAttachment(pointId, attachmentId);
                    this.attachmentViewer.pointId = pointId;
                    this.attachmentViewer.attachmentId = attachmentId;
                    this.attachmentViewer.fileData = { ...response }
                    this.attachmentViewer.filename = name

                    if (this.suupportAttachmentContentType.includes(response.headers['content-type'])) {

                        if (this.isInteractive()) {
                            await eSesjaInteractiveService.openAttachment(this.session.id, pointId, attachmentId);
                        }

                        this.attachmentModalOpen = true;
                    }
                    else {
                        var a = document.createElement('a');
                        var blob = new Blob([response.data], { 'type': response.headers['content-type'] });
                        a.href = window.URL.createObjectURL(blob);
                        a.download = name;
                        a.click();
                    }
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.$store.commit('hideLoader');
                }

            },
            handleEditPoint(item) {
                this.pickedPoint = item;
                this.editPointVisible = true;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../../../assets/scss/session-points-list.scss";



</style>