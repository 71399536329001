export const audioRecorderStore = {
    state: () => ({
        availableDevices: [],
        selectedDevice: null,
        selectAudioInputDeviceModalStatus: false,
        selectSessionModalStatus: false,
        mediaStream: null,
        audioChunks: [],
        recorder: null,
        recorderTimer: null,
        isBusy: false,
        model: {
            sessionId: -1,
            startTimestamp: null,
        },
        audioBlobData: null,
        isRecording: false,
        isMaximize: true,
        isOpen: false,
        isRecordingCanceled: false,

        currentPosition: {
            x: 40,
            y: 30,
        }
    }),
    mutations: {
        setAvailableDevices(state, devices) {
            state.availableDevices = devices;
        },
        setDevice(state, device = null) {
            state.selectedDevice = device;
        },
        setSelectAudioInputDeviceModal(state, status) {
            state.selectAudioInputDeviceModalStatus = status;
        },
        setMediaStream(state, stream = null) {
            state.audioChunks = [];
            state.audioBlobData = null,
            state.mediaStream = stream;
        },
        appendAudioData(state, data) {
            state.audioChunks.push(data);
        },
        setBusyStatus(state, status) {
            state.isBusy = status;
        },
        setAudioBlob(state, data) {
            state.audioBlobData = data;
        },
        setRecordingStatus(state, status) {
            state.isRecording = status;
        },
        setMaximizeStatus(state, status) {
            state.isMaximize = status;
        },
        setRecorderOpenStatus(state, status) {
            state.isOpen = status;
        },
        setRecorderSelectSessionModalStatus(state, status) {
            state.selectSessionModalStatus = status;
        },
        setRecordingCanceledStatus(state, status) {
            state.isRecordingCanceled = status;

        }
    }
}