<template>
    <section class="calendar">
        <section class="calendar-top">
            <section class="left">
                <router-link :to="{name: 'app-new-calendar'}" v-tooltip="'Przejdź do kalendarza'" class="d-flex">
                    <img src="../../../../assets/icons/panel/home/calendar-days.svg" class="calendar-top__icon" />
                    <h2 class="calendar-top__title">Kalendarz posiedzeń</h2>
                </router-link>
            </section>
            <section class="right">
                <router-link :to="{name: 'app-new-calendar'}" class="button" v-tooltip="'Przejdź do kalendarza'">
                    <img src="@/assets/icons/components/up-right-and-down-left-from-center.svg" width="20"/>
                </router-link>
            </section>
        </section>
        <section class="calendar-dates">
            <section class="calendar-dates__top">
                <span class="calendar-dates__date">{{ month }} {{ year }}</span>
                <section class="calendar-dates__actions">
                    <ButtonCalendarLeft @prevMonth="prevMonth" />
                    <ButtonCalendarRight @nextMonth="nextMonth" />
                </section>
            </section>
            <section
                class="calendar-dates__wrapper"
                id="js-calendar-dates-wrapper"
            >
                <section class="calendar-dates__week-days">
                    <span class="calendar-dates__week-day">PN</span>
                    <span class="calendar-dates__week-day">WT</span>
                    <span class="calendar-dates__week-day">ŚR</span>
                    <span class="calendar-dates__week-day">CZ</span>
                    <span class="calendar-dates__week-day">PT</span>
                    <span class="calendar-dates__week-day">SB</span>
                    <span class="calendar-dates__week-day">ND</span>
                </section>
                <section class="calendar-dates__days">
                    <section
                        class="calendar-dates__empty-day"
                        v-for="day in startDay"
                        :key="day"
                    ></section>
                    <section v-for="day in monthDays"
                             :key="day"
                             class="calendar-dates__day"
                             :class="{
                            'calendar-dates__day--today':
                                day === today && checkMonths(),
                        }"
                             @click="openContextMenu">
                        <p class="calendar-dates__day-number"
                           :class="{
                                'calendar-dates__day-number--today':
                                    day === today,
                            }">
                            {{ day }}
                        </p>
                        <CalendarMeetings :meetings="getSessionsInDay(day)"
                                          :messages="getAnnouncementInDay(day)"
                                          :checkAnnouncementModule="checkAnnouncementModule"
                                          :date="getFullDate(day)"
                                          :isMeetingsChecked="isMeetingsChecked"
                                          :isMessagesChecked="isMessagesChecked"
                                          @update-message="updateCalendarMeetings"
                                          @close-calendar="closeContextMenu" />
                        <span class="calendar-dates__meetings"
                              v-if="
                              getSessionsInDay(day).length>
                            0 &&
                            isMeetingsChecked
                            "
                            >{{ getSessionsInDay(day).length }}
                        </span>
                        <span class="calendar-dates__announcement"
                              v-if=" getAnnouncementInDay(day).length > 0 && isMessagesChecked">{{getAnnouncementInDay(day).length}}
                        </span>
                    </section>
                </section>
            </section>
            <section class="calendar-dates__filters">
                <section class="calendar-dates__filter-wrapper">
                    <input type="checkbox" id="posiedzenia" :checked="isMeetingsChecked" class="calendar-dates__checkbox" />
                    <section class="calendar-dates__checkbox__mark" v-if="isMeetingsChecked" @click="isMeetingsChecked = !isMeetingsChecked">
                        <img src="../../../../assets/icons/components/accept-mark.svg"  />
                    </section>
                    <label class="calendar-dates__filter-name" for="posiedzenia" @click="isMeetingsChecked = !isMeetingsChecked">Posiedzenia</label>
                </section>
                <section class="calendar-dates__filter-wrapper" v-if="checkAnnouncementModule">
                    <input type="checkbox" id="komunikaty" :checked="isMessagesChecked" class="calendar-dates__checkbox" />
                    <section class="calendar-dates__checkbox__mark calendar-dates__checkbox__mark--blue" v-if="isMessagesChecked" @click="isMessagesChecked = !isMessagesChecked">
                        <img src="../../../../assets/icons/components/accept-mark.svg"  />
                    </section>
                    <label class="calendar-dates__filter-name" for="komunikaty" @click="isMessagesChecked = !isMessagesChecked" >Informator</label>
                </section>
            </section>
            <SmallLoader v-if="isLoading" />
        </section>
        <section v-if="reloadData"></section>
    </section>
</template>

<script>
import SmallLoader from "../../../BasicControls/SmallLoader.vue";
import CalendarMeetings from "./Modals/CalendarMeetings.vue";
import { encryptStorage } from '../../../../services/encrypt.service';
import moment from "moment";
import SessionService from "../../../../services/session.service";
import CommunicationService from "../../../../services/communication.service";
import ButtonCalendarLeft from "../../../BasicControls/Buttons/ButtonCalendarLeft.vue";
import ButtonCalendarRight from "../../../BasicControls/Buttons/ButtonCalendarRight.vue";

export default {
    name: "Calendar",
    data() {
        return {
            sessions: [],
            messages: [],
            monthDays: 0,
            month: 0,
            year: 0,
            startDay: 0,
            today: 0,
            isMeetingsChecked: true,
            isMessagesChecked: true,
            isLoading: false,
            checkAnnouncementModule: false
        };
    },
    components: {
        SmallLoader,
        CalendarMeetings,
        ButtonCalendarLeft,
        ButtonCalendarRight
    },
    computed: {
        reloadData() {
            this.fetchData();
            return this.$store.state.appStore.groups === "";
        },
    },
    methods: {

        normalizeVariableToIso(val) {
            if (parseInt(val) < 10)
                return `0${val}`;
            return `${val}`;
        },

        getStartDay(year, month) {

            const date = `${year}-${this.normalizeVariableToIso(month)}-01`;
            if (parseInt(moment(date).startOf("month").format("d")) - 1 === -1)
                return 6;

            return parseInt(moment(date).startOf("month").format("d")) - 1;
        },
        nextMonth() {
            const monthNumber = moment().month(this.month).format("M");
            const newDate = moment(`${this.year}-${this.normalizeVariableToIso(monthNumber)}-01`).add(
                1,
                "Month"
            );
            this.setCalendarData(newDate);
        },
        prevMonth() {
            const monthNumber = moment().month(this.month).format("M");
            const newDate = moment(`${this.year}-${this.normalizeVariableToIso(monthNumber)}-01`).subtract(
                1,
                "Month"
            );
            this.setCalendarData(newDate);
        },
        setCalendarData(date) {
            const month = moment(date).format("MMMM");
            const year = moment(date).format("YYYY");

            const currentMonth = moment().format("MMMM");
            const currentYear = moment().format("YYYY");

            if (month === currentMonth && year === currentYear) {
                const thisDay = moment().format("D");
                this.today = parseInt(thisDay);
            } else {
                this.today = -1;
            }

            this.startDay = this.getStartDay(year, moment(date).format("M"));
            this.month = month;
            this.year = year;
            this.monthDays = moment(date).daysInMonth();
            this.fetchData();
        },
        fetchData() {
            if (this.year != 0) {
                const monthNumber = moment().month(this.month).format("M");
                const endOfMonth = moment(
                    `${this.year}-${this.normalizeVariableToIso(monthNumber)}-01`
                ).daysInMonth();
                const start = `${this.year}-${this.normalizeVariableToIso(monthNumber)}-01`;
                const end = `${this.year}-${this.normalizeVariableToIso(monthNumber)}-${endOfMonth}`;

            
                this.isLoading = true;
                SessionService.getSessionsBetweenDates(start, end).then(
                    (response) => {
                        this.sessions = this.prepareMultiDaySession(response.data);
                    }
                );

                if (this.checkAnnouncementModule) {
                    CommunicationService.getAnnoucementBetweenDates(start, end).then(
                        (response) => {
                            this.messages = response.data;
                        }
                    );
                }

                this.isLoading = false;
            }
        },
        prepareMultiDaySession(sessions) {
            try {
                let sessionsWithSpecifiedEndDate = sessions.filter(item => { return item.end_meeting_date !== undefined && item.end_meeting_date !== null });
                if (sessionsWithSpecifiedEndDate.length === 0)
                    return sessions;

                let multiDaySession = sessionsWithSpecifiedEndDate.filter(item => {
                    let splitedMeetingDate = item.meeting_date.split("T")[0];
                    let splitedEndMeetingDate = item.end_meeting_date.split("T")[0];
                    let diff = this.getDiffBetweenStringDates(splitedMeetingDate, splitedEndMeetingDate);
                    const diffDays = this.getDiifBettweenDateInDays(diff);
                    return diffDays !== 0;
                })

                if (multiDaySession.length === 0)
                    return sessions
                let mulipliedSessions = []
                for (let item of multiDaySession) {
                    let splitedMeetingDate = item.meeting_date.split("T")[0];
                    let splitedEndMeetingDate = item.end_meeting_date.split("T")[0];
                    let diff = this.getDiffBetweenStringDates(splitedMeetingDate, splitedEndMeetingDate);
                    const diffDays = this.getDiifBettweenDateInDays(diff);
                    for (let offset = 1; offset <= diffDays; offset++) {
                        let newItem = { ...item };
                        let newDate = new Date(item.meeting_date);
                        newDate.setDate(newDate.getDate() + offset);
                        if (newDate.getDay() !== 6 && newDate.getDay() !== 0) {
                            newItem.meeting_date = this.dateToString(newDate);
                            mulipliedSessions.push(newItem);
                        }

                    }

                }
                return sessions.concat(mulipliedSessions);
            }
            catch {
                return sessions;
            }
        },

        getDiffBetweenStringDates(firstDate, secondData) {
            let internalFirstDate = new Date(firstDate);
            let internalSecondDate = new Date(secondData);
            return Math.abs(internalSecondDate - internalFirstDate);
        },

        getDiifBettweenDateInDays(diff) {
            return Math.ceil(diff / (1000 * 60 * 60 * 24));
        },

        dateToString(date) {
            return `${date.getFullYear()}-${this.normalizeVariableToIso(date.getMonth() + 1)}-${this.normalizeVariableToIso(date.getDate())}T${this.normalizeVariableToIso(date.getHours())}:${this.normalizeVariableToIso(date.getMinutes())}`; 
        },


        getSessionsInDay(day) {

            if (this.year == 0)
                return 0;

            const monthNumber = moment().month(this.month).format("M");
            const date = moment(`${this.year}-${this.normalizeVariableToIso(monthNumber)}-${this.normalizeVariableToIso(day)}`).format();
            const sessions = this.sessions.filter(
                (session) =>
                    moment(session.meeting_date).isSame(date, "date") &&
                    session.groups.length > 0
            );
            return sessions;
        },
        getAnnouncementInDay(day) {
            if (this.year == 0)
                return 0;

            const monthNumber = moment().month(this.month).format("M");
            const date = moment(`${this.year}-${this.normalizeVariableToIso(monthNumber)}-${this.normalizeVariableToIso(day)}`).format();
            const m = this.messages.filter(
                (message) =>
                    moment(message.date).isSame(date, "date") 
            );
            return m;
        },
        openContextMenu(e) {
            const contextMenu = e.currentTarget.children[1];
            // Return if context menu is hidden
            if (window.getComputedStyle(contextMenu).display !== "none") {
                return;
            }

            const dataContainer = document.querySelectorAll(".calendar-data");
            dataContainer.forEach((block) => (block.style.display = "none"));

            const rect = e.target.getBoundingClientRect();
            const x = e.clientX - rect.right;
            const y = e.clientY - rect.top;

            contextMenu.style.inset = "auto auto auto auto";

            contextMenu.style.display = "block";
            contextMenu.id = "js-data-box";

            contextMenu.style.position = "absolute";
            contextMenu.style.top = `${Math.round(y)}px`;
            contextMenu.style.right = `${Math.round(x)}px`;
            contextMenu.style.left = "auto";
            contextMenu.style.bottom = "auto";

            const contextMenuRect = contextMenu.getBoundingClientRect();

            if (contextMenuRect.x < 0) {
                contextMenu.style.left = "0";
                contextMenu.style.right = "auto";
            }

            if (
                contextMenuRect.y + contextMenu.offsetWidth >
                window.innerHeight
            ) {
                contextMenu.style.bottom = "0";
                contextMenu.style.top = "auto";
            }
        },
        updateCalendarMeetings(value) {
            //var gr = this.$store.state.userStore.userData.groups;
            //var user = this.$store.state.userStore.userData.id;
            //if (value.users.includes(user) || value.groups.some(a => gr.includes(a))) {
            if (!value.isEdit) {
                var d = {
                    id: value.id,
                    name: value.title,
                    date: value.meetingDate
                }
                this.messages.push(d);
            } else {
                var index = this.messages.findIndex(x => x.id == value.id)
                if (index != -1) {
                    this.messages[index].name = value.name;
                    this.messages[index].date = value.meetingDate;
                }
            }
            //}
            this.closeContextMenu();
        },
        closeContextMenu() {
            setTimeout(() => {
                document.getElementById("js-data-box").style.display = "none";
            }, 100);
        },
        getFullDate(day) {
            const monthNumber = moment().month(this.month).format("M");
            const fullDate = `${monthNumber}.${day}.${this.year}`;
            return fullDate;
        },
        checkMonths() {
            const monthNumber = moment().month(this.month).format("M");
            const currentMonth = moment().format("M");
            return monthNumber === currentMonth;
        },
    },
    mounted() {
        this.monthDays = moment().daysInMonth();
        const thisDay = moment().format("D");
        this.today = parseInt(thisDay);
        this.month = moment().format("MMMM");
        this.year = moment().format("YYYY");
        this.startDay = this.getStartDay(
            moment().format("YYYY"),
            moment().format("M")
        );

        this.checkAnnouncementModule = encryptStorage.getItem('modules').inf == 1 ? true : false;

        this.fetchData();

        document.addEventListener("click", (e) => {
            const calerndarDataBox = document.getElementById("js-data-box");
            const calendarWrapper = document.getElementById(
                "js-calendar-dates-wrapper"
            );

            if (calerndarDataBox !== null) {
                const isClickInside = calendarWrapper.contains(e.target);
                if (!isClickInside) {
                    document
                        .querySelectorAll(".calendar-data")
                        .forEach((box) => (box.style.display = "none"));
                    calerndarDataBox.style.display = "none";
                    calerndarDataBox.removeAttribute("id");
                }
            }
        });
    },
};
</script>

<style lang="scss" scoped>
    .calendar {
        max-width: 485px;
        background-color: white;
        border: 1px solid #dce4ee;
        box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
        border-radius: 12px;
        box-sizing: border-box;
        padding-top: 20px;
        height: 615px;

        &__dates {
            padding: 28px;
        }
    }

.calendar-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #dce4ee;
    padding: 0px 28px 20px 28px;

    .left{
        display: flex;
        align-items: center;
    }
    .right{
        .button{
            padding: 7px 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-radius: 4px;
            border: 0px;
            color: #00082c;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            transition: all 0.2s ease-in-out;
            border: 1px solid #bbc2ce;
            &:hover{
                background-color: #e8ebef;
            }
        }
    }

    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 12px;
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 132%;
        color: #00082c;
    }
}

    .calendar-dates {
        padding: 28px 25px;
        position: relative;

        &__wrapper {
            border-radius: 4px;
        }

        &__actions {
            display: flex;
        }

        // &__action-btn {
        //     border: 0px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     background-color: transparent;
        // }

        &__date {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
        }

        &__days {
            display: flex;
            flex-wrap: wrap;
        }

        &__day {
            width: 61.7px;
            height: 62px;
            box-sizing: border-box;
            border: 1px solid #dce4ee;
            padding: 8px;
            position: relative;
            cursor: pointer;

            &--today {
                background: #fdf3f4 !important;
                border: 1px solid #f3b4ba;
            }

            &:nth-child(7n),
            &:nth-child(7n - 1) {
                background: #f4f5f7;

                .calendar-dates__day-number {
                    color: #bfc1ca;
                }
            }

            &:hover {
                background: #f4f6fa;
            }
        }

        &__week-days {
            display: flex;
            padding: 10px 0px;
            background: linear-gradient(90.71deg, #00082c 0%, #004dcc 150.66%);
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        &__week-day {
            width: 62px;
            color: white;
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 120%;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            text-align: center;
        }

        &__empty-day {
            width: 61.7px;
            height: 62px;
            box-sizing: border-box;
            border: 1px solid #dce4ee;
        }

        &__day-number {
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 120%;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #00082c;

            &--today {
                color: #d80418;
            }
        }

        &__filters {
            margin-top: 24px;
            display: flex;
        }

        &__filter-name {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            padding-left: 26px;
            cursor: pointer;
            z-index: 3;
        }

        &__filter-wrapper {
            margin-right: 32px;
            display: flex;
            align-items: center;
            position: relative;
        }

        &__checkbox {
            width: 0px;
            height: 16px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                width: 16px;
                height: 16px;
                background: transparent;
                border-radius: 3px;
                border: 1px solid #dce4ee;
                box-sizing: border-box;
            }
        }

        &__checkbox__mark {
            position: absolute;
            left: 0px;
            top: 2px;
            background-color: #d80418;
            box-shadow: 3px 4px 8px rgba(173, 3, 19, 0.12);
            z-index: 5;
            width: 16px;
            height: 16px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
                width: 8px;
                height: 6px;
            }

            &--blue {
                background: #004dcc;
                box-shadow: 3px 4px 8px rgba(14, 61, 138, 0.12);
            }
        }

        &__meetings {
            font-weight: 600;
            font-size: 11px;
            line-height: 120%;
            letter-spacing: 0.02em;
            color: #ffffff;
            padding: 1px 4px;
            background: #d80418;
            box-shadow: 3px 4px 8px rgba(173, 3, 19, 0.16);
            border-radius: 100%;
            cursor: pointer;
            position: relative;

            &:hover .calendar-data {
                display: block;
                opacity: 1;
            }
        }

        &__announcement {
            margin-left: 3px;
            font-weight: 600;
            font-size: 11px;
            line-height: 120%;
            letter-spacing: 0.02em;
            color: #ffffff;
            padding: 1px 4px;
            background: #004dcc;
            box-shadow: 3px 4px 8px rgba(173, 3, 19, 0.16);
            border-radius: 100%;
            cursor: pointer;
            position: relative;

            &:hover .calendar-data {
                display: block;
                opacity: 1;
            }
        }
    }
</style>
