<template>
    <section class="attachment-edit">
        <section class="attachment-edit__top">
            <section class="attachment-edit__name">
                <div v-if="attachment.type_id == 1">
                    <p>
                        <img :src="require(`../../../../../assets/icons/components/attachment-red.svg`)" class="attachment-edit__type-icon" />
                        <b @click="downloadAttachement()">{{getRegisterNameBySchema()}}</b>
                    
                        <button class="attachment-edit__action-btn" @click="editRegister()">
                            <img src="../../../../../assets/icons/components/edit.svg" class="attachment-edit__action-icon" />
                        </button>
                    </p>
                    <p class="attachment-edit__sub-name-text">{{ getRegisterName() }}</p>
                </div>
                <div v-else>
                    <img :src="require(`../../../../../assets/icons/components/attachment.svg`)" class="attachment-edit__type-icon" />
                    <span class="attachment-edit__att-name-text" @click="downloadAttachement()">{{ getRegisterName() }}</span>
                </div>
                
            </section>
            
        </section>
        <!--<section v-if="attachment.type_id == 1" style="padding-left: 40px;">
            <p>- Numer uchwały: {{attachment.number}}</p>
            <p>- Data podjęcia: {{formatDateToDisplay(attachment.registerTime)}}</p>
            <p v-if="attachment.to != null">- Data obowiązywania: {{formatDateToDisplay(attachment.to)}}</p>
            <p v-if="attachment.desc != null">- Uwagi: {{attachment.desc}} </p>
        </section>-->
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script charset="utf-8">
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import AttachmentService from "../../../../../services/attachments.service";

export default {
    name: "Attachment",
    data() {
        return {
            isLoading: false,
        };
    },
    props: {
        attachment: Object,
        elemID: Number,
        schema: Object,
        sessionNumber: String
    },
    components: {
        LoaderInside
    },
    methods: {
        async downloadAttachement() {
            try {
                var result = (await AttachmentService.downloadAttachment(this.elemID, this.attachment.id));
                if (result.status == 200) {
                    const contentType = result.headers['content-type'];
                    var a = document.createElement('a');
                    var blob = new Blob([result.data], { 'type': contentType });
                    a.href = window.URL.createObjectURL(blob);
                    a.download = this.getRegisterName();
                    a.click();
                }

            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        },
        getRegisterName() {
            if (this.attachment.registerName != null && this.attachment.registerName.length > 0)
                return this.attachment.registerName;
            else
                return this.attachment.name
        },
        getRegisterNameBySchema() {
            if (this.schema == undefined || this.schema == null || this.schema.schema == "")
                return this.getRegisterName();

            let chunks = this.schema.schema.toLowerCase().split("/");

            let result = `${this.schema.docName} `;

            for(let chunk of chunks){
                switch(chunk.trim()){
                    case "np": {
                        if(this.sessionNumber !== undefined && this.sessionNumber !== null && this.sessionNumber !== ""){
                            result += `${this.sessionNumber}/`;
                        }
                    }  break;
                    case "n":{
                        if(this.attachment.number !== undefined && this.attachment.number !== null && this.attachment.number !== ""){
                            result += `${this.attachment.number}/`;
                        }
                    }  break;
                    case "rr": result += `${this.getYearFromDate(this.attachment.registerTime)}/`; break;
                    case "rrrr": result += `${this.getFullYearFromDate(this.attachment.registerTime)}/`; break;
                }
            }
            return result.slice(0, -1);
        },
        editRegister() {
            this.$emit("showRegisterEdit", this.attachment, this.elemID);
        },
        getFullYearFromDate(value) {
            var date = new Date(value);
            return date.getFullYear();
        },
        getYearFromDate(value) {
            var date = new Date(value);
            return date.getFullYear().toString().substr(-2);
        },
        formatDateToDisplay(value) {
            var date = new Date(value)
            var d = date.toLocaleString('default', {
                year: 'numeric',
                month: 'long',
                weekday: 'long',
                day: 'numeric'
            });

            return d;
        },
    },
    mounted() {
    },
};
</script>

<style lang="scss" scoped>
    .attachment-edit {
        &:hover .attachment-edit__actions-wrapper {
            opacity: 1;
        }

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 16px 4px 16px;
        }

        &__name {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        &__type-icon {
            margin-right: 11px;
        }

        &__name-text {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;
        }

        &__sub-name-text {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 144%;
            color: #00082c;
            cursor: default;
        }

        &__att-name-text {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 144%;
            color: #00082c;
            cursor: default;
        }

        &__actions-wrapper {
            display: flex;
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }

        &__action-btn {
            width: 32px;
            height: 32px;
            //display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            background: #ffffff;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                border: 1px solid hsla(354, 96%, 43%, 0.324);
            }
        }
    }
</style>
