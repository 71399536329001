export const AddContactOptions = {
    defaultRomanum: "III",
    defaultNumeric: "3",
    defaultNumericWithBracket: "3)",
    defaultLetters: "c)",
    pickValueDefaultRomanum: "III",
    pickValueDefaultNumberDot: "1.",
    pickValueDefaultNumberBracket: "2)",
    pickValueDefaultLetter: "a)",
};

export const expireTimeMinus = 60;
