<template>
    <section class="esesja-search-itemrs-per-page">
        <SearchSelectComponent :options="options"
                               :placeholder="'Wybierz'"
                               :disabled="disabled"
                               v-model="selecteModel"/>
    </section>
</template>
<script charset="utf-8">
    import SearchSelectComponent from "./SearchSelectComponent.vue";
    import CommonHelper from "../../helpers/common.helper";
    export default {
        name: "ItemsPerPage",
        components: {
            SearchSelectComponent
        },
        props: {
            modelValue: {
                type: Object,
                required: true,
                default() {
                    return {
                        label: "10",
                        value: 10,
                    }
                }
            },
            disabled: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        data() {
            return {
                selecteModel: {
                    label: "10",
                    value: 10,
                },
                options: [],
            }
        },
        watch: {
            selecteModel: {
                handler(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            modelValue: {
                handler(value) {
                    this.selecteModel = value;
                }
            }
        },
        mounted() {
            this.options = [
                {
                    label: "10",
                    value: 10,
                },
                {
                    label: "20",
                    value: 20,
                },
                {
                    label: "50",
                    value: 50,
                },
                {
                    label: "100",
                    value: 100,
                },
            ]

            this.$emit('update:modelValue', this.selecteModel)

            if (CommonHelper.isObjectIsEmpty(this.modelValue)) {
                this.selecteModel = this.emptyOptions;
            }
            else {
                this.selecteModel = this.modelValue;
            }

        }
    }
</script>
<style lang="scss">
    .esesja-search-component-select__control {
        min-width: 150px !important;
    }
</style>