<template>
    <section>
        <button class="filter-button" :class="{ 'filter-button--selected': selected }" > {{ name }} </button>
    </section>
</template>

<script>
export default {
    name: "FilterButton",
    props: {
        name: String,
        selected: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.filter-button {
    margin-right: 8px;
    background: #f4f6fa;
    border-radius: 4px;
    border: 0px;
    padding: 7px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 132%;
    color: #333956;
    cursor: pointer;

    &--selected {
        color: #ad0313;
        background: #fdf3f4;
    }
}
</style>
