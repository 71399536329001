<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Dodaj aktualizację</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); $store.commit('setAddVersionsHistoryModalStatus', false); $emit('reloadHistoryList');}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section class="esesja-modal__row">
                    <label class="esesja-modal__wraper__body__label">Opis w formacie html</label>
                    <textarea type="text" rows="16" class="edit-point-form__input" v-model="optionsToUpdate.description" />
                </section>

                <section class="esesja-modal__row-double">
                    <section class="esesja-modal__col-double">
                        <label class="esesja-modal__wraper__body__label">Data aktualizacji</label>
                        <section class="esesja-modal__picker-wrapper">
                            <input type="date" class="esesja-modal__wraper__body__picker" v-model="optionsToUpdate.time" />
                        </section>
                    </section>
                    <section class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="public-version" v-model="optionsToUpdate.isPublic" />
                        <label class="form-bootstrap-check-label" for="public-version">Upublicznij aktualizację</label>
                        <section name="externalTrigger2"
                                 class="tip-btn">
                            <img src="../../../../../assets/icons/components/tooltip.svg"
                                 class="tooltip" />
                            <Hint style="left: calc(100% - 310px)" title="Upublicznij"
                                  text="Po zaznaczeniu tej opcji, ten wpis będzie widoczny u innych użytkowników eSesji." />
                        </section>
                    </section>
                </section>

                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="$store.commit('setAddVersionsHistoryModalStatus', false); $emit('reloadHistoryList');"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="addVersion" :disabled="checkSaveButtonDisable()">Zapisz</button>
                </section>
            </perfect-scrollbar>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
    //components
    import LoaderInside from "@/components/BasicControls/LoaderInside.vue";
    import Hint from "../../../../BasicControls/Hint.vue";
    import moment from "moment";
    //services
    import ToolsService from "@/services/tools.service";

    export default {
        name: "AddVersionToHistoryModal",
        components: {
            LoaderInside,
            Hint
        },
        computed: {
            sessionId() {
                return this.$store.state.appStore.importSessionId;
            }
        },
        props: {
            options: Object
        },
        data() {
            return {
                isLoading: false,
                isUploadedFile: false,
                requestData: new FormData(),
                optionsToUpdate: this.options
            }
        },
        methods: {
            async addVersion() {
                try {
                    this.isLoading = true;

                    if (this.optionsToUpdate.description.length > 0 && this.optionsToUpdate.time != null) {

                        this.optionsToUpdate.time = moment(this.optionsToUpdate.time).format("YYYY-MM-DD");
                            
                        let response = await ToolsService.addVersionToHistory(this.optionsToUpdate);

                        if (response.status !== 200) {
                            throw await Promise.reject(response);
                        }

                        this.$store.commit('setAddVersionsHistoryModalStatus', false);
                        this.$emit("reloadHistoryList");
                    }               
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'AddVersionToHistoryModal' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading = false;
                }
            },
            
            checkSaveButtonDisable() {
                return (this.optionsToUpdate.time == null || this.optionsToUpdate.description.length == 0) ? true : false;
            }
        },
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/esesja-modal.scss';

    .esesja-modal {

        &__wraper {
            width: 600px;
        }
    }

    .form-bootstrap-switch {
        padding-bottom: 20px;
    }

    .tooltip {
        position: relative;
        left: 5px;
        top: -3px;
    }

    .tip-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        background-color: transparent;
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;

        &:hover .hint {
            opacity: 1;
            display: block;
        }
    }

</style>