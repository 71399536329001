<template>
    <div :class="cardClass">
        <slot></slot>
    </div>
</template>
<script charset="utf-8">
    export default {
        name: "CardWrapper",
        props: {
            color: {
                type: String,
                default: ""
            },
            border: {
                type: String,
                default: ""
            }
        },
        computed: {
            cardClass() {
                let crdClass = "card";

                switch (this.color) {
                    case "primary": crdClass += " text-white bg-primary"; break;
                    case "secondary": crdClass += " text-white bg-secondary"; break;
                    case "success": crdClass += " text-white bg-success"; break;
                    case "danger": crdClass += " text-white bg-danger"; break;
                    case "warning": crdClass += " text-dark bg-warning"; break;
                    case "info": crdClass += " text-dark bg-info"; break;
                    case "light": crdClass += " text-dark bg-light"; break;
                    case "dark": crdClass += " text-white bg-dark"; break;
                }

                switch (this.border) {
                    case "primary": crdClass += " border-primary"; break;
                    case "secondary": crdClass += " border-secondary"; break;
                    case "success": crdClass += " border-success"; break;
                    case "danger": crdClass += " border-danger"; break;
                    case "warning": crdClass += " border-warning"; break;
                    case "info": crdClass += " border-info"; break;
                    case "light": crdClass += " border-light"; break;
                    case "dark": crdClass += " border-dark"; break;
                }

                return crdClass;
            }
        }
    }
</script>
<style lang="scss">
    .card {
        border: 1px solid #bbc2ce;

        &:hover {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
    }
</style>