<template>
    <button class="
            addquest-button
            addquest-button__white"
            @click="$emit('enableAddNewGuestModal')"
            v-tooltip="'Szukaj kontaktów'">
        <img src="~@/assets/icons/panel/Contacts/search.svg" class="button-image" alt="Szukaj" />
    </button>
</template>
<script charset="utf-8">
    export default {
        name: "SearchQuestButton"
    }
</script>
<style lang="scss" scoped>
    .addquest-button {
        margin: 10px 10px 10px 10px;
        flex-grow: 1;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 0px;
        transition: all 0.2s ease-in-out;

        .button-image {
            height: 20px;
            margin: 0px 5px 0px 0px;
        }

        &__white {
            background: #fff;
            border: 1px solid #bbc2ce;

            &:hover {
                background: #e8ebef;
            }
        }
    }
</style>