<template>
    <section class="import-contact-summary">
        <table class="table-bootstrap table-bootstrap-bordered table-bootstrap-hover" v-if="importData?.length > 0">
            <thead>
                <tr class="align-middle">
                    <th>#</th>
                    <th>Imię</th>
                    <th>Nazwisko</th>
                    <th>Numer telefonu</th>
                    <th>E-Mail</th>
                    <th>Grupy</th>
                    <th>Utworzyć dane logowania</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in importData" :key="index" class="align-middle align-items-center">
                    <td>
                        {{ index + 1 }}
                    </td>
                    <td :class="{'table-bootstrap-danger': item.name.hasError}">
                        <div class="input-bootstrap-group" v-if="item.isEdit">
                            <input type="text" class="form-bootstrap-control" v-model="item.name.value" v-tooltip="item.name.value" />
                        </div>
                        <span v-else>{{ item?.name?.value }}</span>
                    </td>
                    <td :class="{'table-bootstrap-danger': item.surname.hasError}">
                        <div class="input-bootstrap-group" v-if="item.isEdit">
                            <input type="text" class="form-bootstrap-control" v-model="item.surname.value" v-tooltip="item.surname.value" />
                        </div>
                        <span v-else>{{ item?.surname?.value }}</span>
                    </td>
                    <td :class="{'table-bootstrap-danger': item.phonenumber.hasError}">
                        <div class="input-bootstrap-group" v-if="item.isEdit">
                            <input type="tel" class="form-bootstrap-control" v-model="item.phonenumber.value" v-tooltip="item.phonenumber.value" />
                        </div>
                        <span v-else>{{ item?.phonenumber?.value }}</span>
                    </td>
                    <td :class="{'table-bootstrap-danger': item.email.hasError}">
                        <div class="input-bootstrap-group" v-if="item.isEdit">
                            <input type="email" class="form-bootstrap-control" v-model="item.email.value" v-tooltip="item.email.value" />
                        </div>
                        <span v-else>{{ item?.email?.value }}</span>
                    </td>
                    <td v-if="item.isEdit" style="min-width: 200px">
                        <Multiselect ref="searchUsers"
                                     v-model="selectedGroups"
                                     mode="tags"
                                     placeholder="Wyszukaj..."
                                     :close-on-select="false"
                                     :searchable="true"
                                     noResultsText="brak wyników"
                                     noOptionsText="brak opcji"
                                     :options="availableGroups" />
                    </td>
                    <td :class="[item.groups.hasError ? 'table-bootstrap-danger' : 'd-flex flex-column align-items-stretch']" v-else>
                        <span v-for="(group, groupIndex) in item?.groups?.value" :key="groupIndex">{{ group?.name }}</span>
                    </td>
                    <td style="width: 65px">
                        <div class="form-bootstrap-check form-bootstrap-switch justify-content-center">
                            <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-votings" v-model="item.isUser" style="width: 50px" @change="computedValidation(importData)"/>
                        </div>
                    </td>
                    <td>
                        <ButtonGroup>
                            <ButtonOutline color="light" @click="setEdit(item)" v-if="item.isEdit === false">
                                <ButtonIcon icon="edit" />Edytuj
                            </ButtonOutline>
                            <ButtonOutline color="light" @click="setSave(item)" style="align-items: center;" v-else>
                                <ButtonIcon icon="save" />Zapisz <PulseDot />
                            </ButtonOutline>
                            <ButtonBasic color="danger" @click="remove(item)">
                                <ButtonIcon icon="delete" />Usuń
                            </ButtonBasic>
                        </ButtonGroup>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>
<script charset="utf-8">
    import Multiselect from '@vueform/multiselect'
    import PulseDot from "../../../../../../../components/BasicControls/PulseDot.vue";
    import importContactService from "../../../../../../../services/import.contact.service";

    import { validateEmail, validatePhone } from "../../../../../../../helpers/utils";

    export default {
        name: "ImportContactSummary",
        components: {
            Multiselect,
            PulseDot
        },
        computed: {
            importData() {
                return this.$store.state.importContactStore.importData ?? []
            },
            isAnyInEditMode() {
                let data = this.importData;

                if (data.length === 0) {
                    return false;
                }

                let filtered = data.filter(item => item.isEdit);

                return filtered.length > 0;
            }
        },
        data() {
            return {
                selectedGroups: [],
                availableGroups: [],
            }
        },
        mounted() {
            this.availableGroups = this.$store.state.appStore.groups.map(item => {
                return {
                    value: item.id,
                    label: item.name,
                }
            })
            this.computedValidation(this.importData);
        },
        watch: {
            importData: {
                immediate: true,
                deep: true,
                handler(val) {
                    this.computedValidation(val);
                }
            }
        },
        methods: {
            computedValidation(val) {
                try {
                    let dataStatus = this.validateImportData(val);
                    this.$store.commit('setContactImportAllHaveGroups', dataStatus && !this.isAnyInEditMode);
                }
                catch {
                    this.$store.commit('setContactImportAllHaveGroups', false);
                }
            },

            validateImportData(val){
                try {
                    let status = true;
                    for (let item of val) {
                        item.name.hasError = this.isEmpty(item.name.value);
                        item.surname.hasError = this.isEmpty(item.surname.value);
                        item.phonenumber.hasError = this.ifPhoneIsNotUniqueIsNotEmpty(item, val) || this.isPhoneIsValidIfNotEmpty(item)
                        item.email.hasError = this.ifEmailIsNotUniqueIsNotEmpty(item, val) || this.ifEmailIsIfContactIsUser(item) || this.isEmailIsValidIfNotEmpty(item)
                        item.groups.hasError = item.groups.value.length === 0

                        if (status == true && (item.name.hasError || item.surname.hasError || item.phonenumber.hasError || item.email.hasError || item.groups.hasError)) {
                            status = false;
                        }
                    }
                    return status;
                }
                catch {
                    return false; 
                }
            },

            isEmpty(val) {
                return val === undefined || val === null || val === "" || val === '';
            },

            ifPhoneIsNotUniqueIsNotEmpty(item, items) {
                if (this.isEmpty(item.phonenumber.value) === false) {
                    let filtered = items.filter(obj => obj.id !== item.id).map(obj => obj.phonenumber.value);
                    return filtered.includes(item.phonenumber.value);
                }
                return false;
            },

            ifEmailIsNotUniqueIsNotEmpty(item, items) {
                if (this.isEmpty(item.email.value) === false) {
                    let filtered = items.filter(obj => obj.id !== item.id).map(obj => obj.email.value);
                    return filtered.includes(item.email.value);
                }
                return false;
            },

            ifEmailIsIfContactIsUser(item) {
                if (item.isUser) {
                    return this.isEmpty(item.email.value);
                }
                return false;
            },

            isEmailIsValidIfNotEmpty(item) {
                if (this.isEmpty(item.email.value) === false) {
                    return validateEmail(item.email.value);
                }
                return false;
            },

            isPhoneIsValidIfNotEmpty(item) {
                if (this.isEmpty(item.phonenumber.value) === false) {
                    return validatePhone(item.phonenumber.value)
                }
                return false;
            },

            remove(item) {
                try {
                    importContactService.removeItem(item);
                }
                catch (e) {
                    console.log(e);
                }
            },
            setEdit(item) {
                try {
                    this.selectedGroups = item.groups.value.map(group => group.id);
                    importContactService.setEdit(item)

                }
                catch (e) {
                    console.log(e);
                }
            },
            setSave(item) {
                try {
                    importContactService.setSave(item, [...this.selectedGroups]);
                    this.selectedGroups = [];
                }
                catch (e) {
                    console.log(e);
                }
            }
        }
    }
</script>