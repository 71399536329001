export const interactiveStore = {
    state: () => ({
        isShowPresenceList: false,
        isShowCheckQourum: false,
        isShowResultCheckQourum: false,
        isShowCustomBreakModal: false,
        isStartBreak: false,
        breakDuration: 0,
        breakTimestamp: null,
        isShowAddNewGuest: false,
        activePoint: 0,
        hideVotingResultsDuringVoting: false,
        startAutomaticVotingByTimeSet: { isAutomatic: false, time: 10 },
        tempSessionId: 0,
        isShowAddSessionGuest: false,
        isLockSessionDragAndDrop: false,
    }),
    mutations: {
        showPresenceList(state, sid) {
            state.isShowPresenceList = true;
            state.tempSessionId = sid;
        },
        hidePresenceList(state) {
            state.isShowPresenceList = false;
            state.tempSessionId = 0;
        },

        showCheckQourum(state) {
            state.isShowCheckQourum = true;
        },
        hideCheckQourum(state) {
            state.isShowCheckQourum = false;
        },

        showResultCheckQourum(state) {
            state.isShowResultCheckQourum = true;
        },
        hideResultCheckQourum(state) {
            state.isShowResultCheckQourum = false;
        },

        showCustomBreakModal(state) {
            state.isShowCustomBreakModal = true;
        },
        hideCustomBreakModal(state) {
            state.isShowCustomBreakModal = false;
        },

        startBreak(state) {
            state.isStartBreak = true;
        },
        stopBreak(state) {
            state.isStartBreak = false;
        },
        setBreakDuration(state, duration) {
            state.breakDuration = duration;
        },
        setBreakTimestamp(state, timestamp){
            state.breakTimestamp = timestamp;
        },
        showAddNewGuestModal(state) {
            state.isShowAddNewGuest = true;
        },
        hideAddNewGuestModal(state) {
            state.isShowAddNewGuest = false;
        },
        setActivePoint(state, point) {
            state.activePoint = point;
        },
        setHideVotingResultsDuringVoting(state, hide) {
            state.hideVotingResultsDuringVoting = hide;
        },
        setStartAutomaticVoting(state, automatic) {
            state.startAutomaticVotingByTimeSet = automatic;
        },
        setIsShowAddSessionGuest(state, status){
            state.isShowAddSessionGuest = status
        },
        setIsLockSessionDragAndDrop(state, status){
            state.isLockSessionDragAndDrop = status;
        }
    }
}