<template>
    <section class="sticky-bottom" :class="customClassName">
        <div class="sticky-bottom__container">
            <div class="sticky-bottom__container__text">
                <p v-if="infoCase == 1" class="quorum">Trwa sprawdzanie kworum...</p>
                <p v-if="infoCase == 2" class="quorum">Trwa głosowanie w sprawie: {{ elemToDispay }}</p>
                <div v-if="infoCase == 3" class="quorum-still w-100">
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-row justify-content-between">
                            <p class="p-break">Trwa przerwa w obradach</p>
                            <p class="p-break"> {{ isOvertime ? '+' : '' }} {{ displayTime }}</p>
                        </div>
                        <p>Szacowana godzina wznowienia: {{ elemToDispay.split(':', 2).join(':') }}</p>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script charset="utf-8">
import { debounce, getLeadZero, timeUnitsBetween } from '../../helpers/utils';
export default {
    name: " InfoModal",
    data() {
        return {
            breakTimer: null,
            isOvertime: false,
            debounceFlag: false,
            startTime: null,
            estimateEndTime: null,
            currentTime: null,
            displayTime: "00:00:00",
            customClassName: ""
        };
    },
    props: {
        infoCase: Number,
        elemToDispay: String,
        elemObj: Object
    },
    watch: {
        debounceFlag: debounce(function () {
            if(this.infoCase === 3){
                this.currentTime = new Date();
                let diff = this.estimateEndTime - this.currentTime;
                if (diff < 0) {
                    this.isOvertime = true;
                }
                let obj = timeUnitsBetween(this.currentTime, this.estimateEndTime);
                this.displayTime = `${getLeadZero(obj.hours + 24*obj.days)}:${getLeadZero(obj.minutes)}:${getLeadZero(obj.seconds)}`;
                this.debounceFlag = !this.debounceFlag;
            }
            
        }, 100),
        elemObj: {
            deep: true,
            immediate: true,
            handler(val) {
                if (this.infoCase == 3) {
                    this.startTime = new Date(Date.parse(val.startTime));
                    this.estimateEndTime = new Date(this.startTime.getFullYear(), this.startTime.getMonth(), this.startTime.getDate(), this.startTime.getHours(), this.startTime.getMinutes() + parseInt(this.elemObj.duration), this.startTime.getSeconds());
                }
            }
        },
        infoCase: {
            immediate: true,
            handler(val) {
                if (val === 3) this.customClassName = "break-info"
                else if (val === 2) this.customClassName =  "voting-in-progress"
                else if (val === 1) this.customClassName = "quorum-in-progress"
                else this.customClassName = ""                
            }
        }
    },
    mounted() {
        if(this.infoCase == 3){
            this.debounceFlag = !this.debounceFlag;
        }
    },
};
</script>

<style lang="scss" scoped>
    .p-break {
        font-size: 2.0em;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .sticky-bottom {
        position: -webkit-fixed;
        position: fixed;
        bottom: 0;
        border-color: red;
        background: radial-gradient(100% 100% at 0% 0%, #00082c 0%, #004dcc 100%);
        height: 140px;
        left: 0px;
        right: 0px;
        padding: 50px;
        z-index: 20;

        &__container {
            display: flex;
            font-size: 35px;
            line-height: 144%;
            color: white;
            padding: 25px;

            &__text {
                margin: 10px;
                display: unset;
            }
        }
    }

.break-info {
    .sticky-bottom__container {
        width: 100%;

        .sticky-bottom__container__text {
            width: 100%;
        }
    }
}

    .voting-in-progress {
        max-height: 10vh;
        padding: 0;

        .sticky-bottom__container {
            padding: 12px;

            .quorum {
                text-wrap: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100vw;
            }
        }
    }

.quorum {
    color: white;
    animation: pulse 4s infinite;
}

.quorum-still {
    color: white;
}

@keyframes pulse {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>