<template>
    <section class="add-note">
        <section class="add-note-form">
            <button class="add-note-form__exit-btn"
                    @click=" (e)=>{e.preventDefault(); this.$emit('closeShowResponse');}">
                <img src="../../../../../assets/icons/components/exit-btn.svg" />
            </button>
            <h3 class="add-note-form__title">Dodaj odpowiedź</h3>
            <section class="add-note__form-wrapper">
                <label class="add-note-form__label">Treść</label>
                <section>
                    <textarea class="add-note-form__wyswig" v-model="body"></textarea>
                </section>

                <section class="add-note-form__bottom">
                    <button class="add-note-form__bottom-button" @click="this.$emit('closeShowResponse')"> Anuluj</button>
                    <button class="add-note-form__bottom-button add-note-form__bottom-button--red" @click="addResponse()" :disabled="body.length <= 3 ">Wyślij</button>
                </section>
            </section>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import InterpellationService from "../../../../../services/interpellation.service";

export default {
    name: "AddAnnouncement",
    data() {
        return {
            isLoading: false,
            body: '',
            errors: [],
        };
    },
    components: {
        LoaderInside,
    },
    props: {
        intId: Number,
        isPublic: Boolean
    },
    methods: {
        async addResponse() {
            try {
                this.isLoading = true;

                var object = {
                    id: this.intId,
                    body: this.body,
                    isPublic: this.isPublic
                };

                var result = (await InterpellationService.addInterpellationResponse(object));
                if (Object.keys(result).length > 0) {
                    this.$emit("updateResponses", result);
                }
               
                this.body = "";
                this.$emit("closeShowResponse");

            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        },
    },
};</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
    .label {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 145%;
        color: #00082c;
        margin-bottom: 8px;

        &--checkbox {
            padding-left: 36px;
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            z-index: 9;
            color: #333956;
            cursor: pointer;
        }
    }

    .multiselect-green {
        --ms-tag-bg: #D1FAE5;
        --ms-tag-color: #059669;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .is-active{
        background-color: white !important;
    }

    .add-note {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 15;
    }

    .add-note-form {
        padding: 32px 56px;
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        max-height: 90vh;
        overflow-y: auto;
        width: 612px;

        &::-webkit-scrollbar {
            width: 12px;
            height: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background: #b5b8bd;
            border-radius: 30px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #b5b8bd;
        }

        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 30px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }

        &__exit-btn {
            border: 0px;
            background-color: transparent;
            position: relative;
            right: -100%;
        }

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 32px;

            &--small {
                font-size: 20px;
                margin-bottom: 27px;
            }
        }

        &__input-wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
        }

        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
        }

        &__labelFile {
            font-weight: 300;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
        }

        &__labelred {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: red;
        }

        &__labelcount {
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            color: #00082c;
            text-align: right;
        }

        &__input {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            margin-top: 8px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;
            margin-bottom: 16px;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__wyswig {
            margin-top: 8px;
            height: 170px;
            max-height: 170px;
            width: 490px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            border-radius: 4px;
        }

        &__bottom {
            display: flex;
            justify-content: space-between;
            clear: both;
        }

        &__bottom-button {
            padding: 18px 40px 17px;
            width: 238px;
            height: 56px;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
            background-color: transparent;
            margin-top: 40px;
            background: none;

            &--red {
                border: 0px;
                background: #d80418;
                color: white;
            }

            &--red:disabled {
                border: 0px;
                background: #D3D3D3;
                color: white;
                cursor: default;
            }
        }
    }

    switch-section {
        display: flex;
        margin-bottom: 40px;
    }

    .attachment-edit {
        width: 100%;
        background: white;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        margin-bottom: 16px;

        &:hover .attachment-edit__actions-wrapper {
            opacity: 1;
        }

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 16px 8px 16px;
        }

        &__name {
            display: flex;
            align-items: center;
        }

        &__type-icon {
            margin-right: 11px;
        }

        &__name-text {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;
        }

        &__actions-wrapper {
            display: flex;
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }

        &__action-btn {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            background: #ffffff;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                border: 1px solid hsla(354, 96%, 43%, 0.324);
            }
        }
    }

    .upload-btn {
        display: flex;
        align-items: center;
        border: 0px;
        padding: 5px 5px;
        background: rgba(220, 228, 238, 0.56);
        border-radius: 4px;
    }

    .input {
        width: 100%;
        padding: 12.5px 16px;
        background: #ffffff;
        border: 1px solid #d1d6df;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
        border-radius: 4px;
        margin: 8px 0px;
        font-size: 16px;
        line-height: 144%;
        color: #00082c;

        &:focus {
            border: 1px solid #00082c;
        }
    }

    .checkbox {
        font-weight: normal;
        font-size: 16px;
        line-height: 144%;
        color: #333956;
        margin-left: 12px;
        margin-bottom: 0px;
        padding-left: 24px;
        z-index: 0;
        cursor: pointer;
    }

    .checkbox-wrapper {
        display: flex;
        padding: 5px;
        float: left;
    }

    .filessection {
        padding-top: 10px;
        display: block;
        clear: both;
        border-top: 1px solid #dde0e7
    }

    .multiSelectrow {
        --ms-tag-bg: #D1FAE5;
        --ms-tag-color: #059669;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 200px;
    }

    .selectedSection {
        background-color: #dce4ee;
        padding: 5px;
        border-radius: 0px 5px 5px 5px;
    }

    .tabs {
        margin-left: 0px;
        cursor: pointer;
        padding: 5px;
        background-color: #D3D3D3;
        border-radius: 5px 5px 0px 0px;
    }

    .selected {
        background-color: #dce4ee;
        margin: 0px;
        cursor: pointer;
        padding: 5px;
        border-radius: 5px 5px 0px 0px;
    }

    .edit-meeting {
        &__row-double {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0px;
        }

        &__col-double {
            display: flex;
            flex-direction: column;
        }

        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-bottom: 0px;

            &--checkbox {
                padding-left: 32px;
                font-weight: normal;
                font-size: 16px;
                line-height: 144%;
                color: #333956;
                margin-bottom: 0px;
                z-index: 8;
                cursor: pointer;
            }
        }

        &__picker {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            min-width: 238px;
            height: 56px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;

            &[type="date"]::-webkit-calendar-picker-indicator {
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url("../../../../../assets/icons/components/callendar.svg") no-repeat;
                background-position-x: 100%;
                width: 22px;
                height: 22px;
                border-width: thin;
            }

            &[type="time"]::-webkit-calendar-picker-indicator {
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url("../../../../../assets/icons/components/clock.svg") no-repeat;
                width: 22px;
                height: 22px;
                border-width: thin;
            }
        }

        &__picker-wrapper {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 8px;
                background: #e2e5eb;
                width: 1px;
                height: 40px;
                right: 56px;
                z-index: 99;
            }
        }
    }

</style>

<style lang="scss">
    .ProseMirror {
        height: 160px;
        max-height: 160px;

        h1 {
            font-size: 32px;
        }

        h2 {
            font-size: 24px;
        }

        p {
            font-size: 16px;
        }
    }
</style>
