<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h2 class="esesja-modal__wraper__header__title">Wyślij SMS</h2>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click="this.$emit('close');">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section>
                    <section class="d-flex flex-row justify-content-between pt-3">
                        <label class="esesja-modal__wraper__body__label">Kontakty</label>
                        <label :class="[useCredits <= availableCredits ? 'esesja-modal__wraper__body__labelcount' : 'esesja-modal__wraper__body__labelred']"
                               v-tooltip="'Ilość kredytów na wiadomość * ilość zaznaczonych kontaktów'">
                            Wykorzystane kredyty: {{useCredits}}/{{availableCredits}}
                        </label>
                    </section>
                    <Multiselect ref="searchUsers"
                                 v-model="value"
                                 mode="tags"
                                 placeholder="Wyszukaj..."
                                 :close-on-select="false"
                                 :searchable="true"
                                 class="multiselect-green"
                                 noResultsText="brak wyników"
                                 noOptionsText="brak opcji"
                                 :groups="true"
                                 :searchFilter="customSearchFilter"
                                 :options="availableOptions"
                                 @select="selectedOptions" />

                </section>
                <label class="esesja-modal__wraper__body__label">Treść</label>
                <section class="esesja-modal__row">
                    <textarea class="esesja-modal__wraper__body__wyswig" v-model="sms"></textarea>
                </section>
                <section class="d-flex flex-row justify-content-between pb-3">
                    <label :class="[checkSMSCount < 160 ? 'esesja-modal__wraper__body__labelcount' : 'esesja-modal__wraper__body__labelred']">Ilość znaków: {{checkSMSCount}}</label>
                    <label v-tooltip="'Ilość znaków / 160'"
                           class="esesja-modal__wraper__body__labelcount">
                        Ilość kredytów na wiadomość: {{smsPerMessage}}
                    </label>
                </section>
                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="this.$emit('close')"> Anuluj</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="handleSaveClick" :disabled="value.length == 0 || sms.length == 0 || sms.length > 600 || useCredits > availableCredits">Wyślij</button>
                </section>
            </perfect-scrollbar>
            <LoaderInside v-if="isLoading" />
        </section>
    </section>
</template>

<script charset="utf-8">
    import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
    import Multiselect from '@vueform/multiselect'
    import ContactService from "../../../../../services/contact.service";
    import CommunicationService from "../../../../../services/communication.service";
    import AlertHelper from "../../../../../helpers/alerts.helper";

    export default {
        name: "SendSms",
        data() {
            return {
                name: "",
                isLoading: false,
                count: 0,
                sms: "",
                value: [...this.selected],
                sel: {},
                smsPerMessage: 1,
                availableCredits: 0,
            };
        },
        props: {
            options: Array,
            selected: Array
        },
        components: {
            LoaderInside,
            Multiselect
        },
        computed: {
            checkSMSCount() {
                return this.sms.length;
            },
            useCredits() {
                return this.smsPerMessage * (this.value.length ?? 0);
            },
            availableOptions() {
                return this.options.map(item => ({
                    id: item.id,
                    label: item.label,
                    options: item.options.map(opt => ({
                        value: opt.value,
                        label: opt.label,
                        groupId: item.id
                    }))
                }));
            }
        },
        watch: {
            checkSMSCount: {
                handler(val) {
                    if (val <= 160)
                        this.smsPerMessage = 1;
                    else if (val > 160 && val <= 300)
                        this.smsPerMessage = 2;
                    else if (val > 300 && val <= 450)
                        this.smsPerMessage = 3;
                    else if (val > 450)
                        this.smsPerMessage = 4;
                        
                    //this.smsPerMessage = Math.ceil(parseFloat(val) / 160.0);
                }
            }
        },
        methods: {
            async handleSaveClick() {
                try {
                    this.isLoading = true;
                    const data = {
                        ids: this.value,
                        description: this.sms,
                    };

                    let response = await ContactService.sendSMSToUsers(data)

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    this.value = [];
                    this.sms = "";
                    this.$emit("close");
                    this.$store.commit('setSendMessageSuccess', true);
                    this.$store.commit('setSelectedPeopleToSendMessage', []);
                }
                catch (e) {
                    if (e.response !== undefined) {
                        if (e.response.status === 401) {
                            this.$router.push({ name: 'Login' })
                        }
                        else if (e.response.status === 422) {
                            var toast = this.$swal.mixin(AlertHelper.errorToastElement());
                            toast.fire(AlertHelper.errorToastAlert(`Nie wystarczająca liczba kredytów. Dostępne: ${e.response.data?.availableCredits}, wymagane: ${e.response.data?.requiredCredits}`));
                        }
                        else if (e.response.status == 409) {
                            var toast1 = this.$swal.mixin(AlertHelper.errorToastElement());
                            toast1.fire(AlertHelper.errorToastAlert(`Za długa wiadomość sms - limit 600 znaków`));
                        }
                        else {
                            var toast2 = this.$swal.mixin(AlertHelper.errorToastElement());
                            toast2.fire(AlertHelper.errorToastAlert(`Wystąpił ogólny błąd`));
                        }
                    }
                }
                finally {
                    this.isLoading = false;
                }
            },
            customSearchFilter(option, query) {
                let localQuery = query.toUpperCase();
                let localcopy = [...this.availableOptions];
                // check if any groups meets criteria
                let filterGroup = localcopy.filter(item => {
                    let copyGroupLabel = item.label.toUpperCase()
                    return copyGroupLabel.includes(localQuery);
                })

                if (filterGroup.length > 0) {
                    let isOptionIsInGroup = filterGroup.filter(item => item.id === option.groupId);
                    return isOptionIsInGroup.length > 0;
                }
                return option.label.toUpperCase().includes(localQuery);
            },
            selectedOptions() {
                this.$refs.searchUsers.clearSearch();
            }
        },
        async mounted() {
            try {
                let availableCreditsResponse = await CommunicationService.getSmsCredits();
                if (availableCreditsResponse.status !== 200) {
                    throw await Promise.reject(availableCreditsResponse);
                }

                this.availableCredits = availableCreditsResponse.data;
            }
            catch {
                this.availableCredits = 0;
            }
        },
    };
</script>
<style lang="scss" scoped>
    @import "@vueform/multiselect/themes/default.css";
    @import "../../../../../assets/scss/esesja-modal.scss";


    .multiselect-green {
        --ms-tag-bg: #D1FAE5;
        --ms-tag-color: #059669;
    }
</style>