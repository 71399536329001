<template>
    <section class="sticky-bottom">
        <div class="sticky-bottom__container">
            <img v-if="$store.state.settings.client.interactiveDiscussionShowAvatar && speeker.userId !== undefined && speeker.userId > 0" class="sticky-bottom__container__avatar" :src="getUserAvata()" :alt="`${speeker.surname}}`" />
            <div class="sticky-bottom__container__text">
                <p class="speaker-name-container">{{speeker.name}} {{speeker.surname}}</p>
                <p class="speaker-role-container">{{speeker.role}}</p>
            </div>
            <div class="user-speech-time" :class="{'user-speech-time--red' : isSpeakOutOfRange}"><span v-if="isSpeakOutOfRange">+</span>{{displayTime}}</div>
            <img src="../../assets/icons/components/speaker-white.svg" alt="Trwa wypowiedź" class="user-speech-image" />
        </div>
    </section>
</template>

<script charset="utf-8">
    import CommonHelper from '@/helpers/common.helper.js';
    import toolsService from '../../services/tools.service';
    import { debounce, timeUnitsBetween, getLeadZero } from '../../helpers/utils';

    export default {
        name: "SpeekerModal",
        data() {
            return {
                session: {},
                code: '',
                error: false,
                client: '',
                debounceFlag: false,
                startSpeechTimestamp: null,
                estimatedEndTime: null,
                isSpeakOutOfRange: false,
                fronStartSpeechTimestamp: null,
                currentServerTimestamp: null,
                displayTime: "00:00:00",
                avatarVersion: CommonHelper.getRandomVersionImageForUrlRefresh(0, 100)
            };
        },
        props: {
            speeker: Object,
        },
        watch: {
            debounceFlag: debounce(function(){
                let internalDiff = null;
                let offset = timeUnitsBetween(this.fronStartSpeechTimestamp, this.currentServerTimestamp);
                let internalDate = new Date();
                internalDate.setHours(internalDate.getHours() + offset.hours);
                internalDate.setMinutes(internalDate.getMinutes() + offset.minutes);
                internalDate.setSeconds(internalDate.getSeconds() + offset.seconds);
                if(this.speeker.isCountdown){
                    this.isSpeakOutOfRange = (this.estimatedEndTime - internalDate) < 0;
                    internalDiff = timeUnitsBetween(internalDate, this.estimatedEndTime);
                }
                else{
                    internalDiff = timeUnitsBetween(this.startSpeechTimestamp, internalDate);
                }
                
                if(internalDiff !== null){
                    this.displayTime = `${getLeadZero(internalDiff.hours + 24*internalDiff.days)}:${getLeadZero(internalDiff.minutes)}:${getLeadZero(internalDiff.seconds)}`;
                }
                this.debounceFlag = !this.debounceFlag;
            }, 100),
            speeker: {
                immediate: true,
                deep: true,
                async handler(val) {
                    this.startSpeechTimestamp = new Date(Date.parse(val.startTime));
                    this.fronStartSpeechTimestamp = new Date();
                    this.currentServerTimestamp = new Date((await toolsService.getServerTime()).data);
                    if (val.isCountdown) {
                        this.estimatedEndTime = this.startSpeechTimestamp.setSeconds(this.startSpeechTimestamp.getSeconds() + val.seconds)
                    }
                    this.debounceFlag = !this.debounceFlag;
                }
            }
        },
        methods: {
            getUserAvata() {
                return `https://api.esesja.pl/users/${this.speeker.userId}.jpg${this.avatarVersion}`;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .speaker-role-container {
        font-size: 1.4em;
    }

    .speaker-name-container {
        font-weight: bold;
        font-size: 2.4em;
        margin-bottom: 16px
    }

    .sticky-bottom {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        border-color: red;
        background: radial-gradient( 100% 100% at 0% 0%, #00082c 0%, #004dcc 100% );
        height: 140px;

        &__container {
            display: flex;
            font-size: 25px;
            line-height: 144%;
            color: white;
            padding: 25px;

            &__avatar {
                width: 100px;
                vertical-align: middle;
            }

            &__text {
                margin: 10px;
                display: unset;
            }
        }
    }

    .user-speech-image {
        height: 80px;
        right: 40px;
        position: absolute;
        color: white;
        animation: pulse 2s infinite;
    }

    .user-speech-time {
        right: 150px;
        position: absolute;
        color: white;
        font-size: 36px;

        &--red{
            color: #ff0018;
        }
    }

    @keyframes pulse {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
</style>