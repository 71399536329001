<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <slot></slot>
        </section>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "eSesjaModal"
    }
</script>