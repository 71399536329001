<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Kopiowanie punktu</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$store.commit('hideCopySessionPoint');}">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <section class="esesja-modal__row-double">
                    <section class="esesja-modal__col-double">
                        <label class="edit-meeting__label">Groupa</label>
                        <multiselect v-model="groups.selected"
                                     placeholder="Wybierz grupę"
                                     :close-on-select="true"
                                     class="multiselect-green"
                                     :searchable="true"
                                     :object="true"
                                     :options="groups.available"
                                     noResultsText="brak wyników"
                                     noOptionsText="brak opcji"
                                     style="min-width: 280px; padding: 12.5px 16px;" />
                    </section>
                    <section class="esesja-modal__col-double">
                        <label class="edit-meeting__label">Posiedzenie</label>
                        <multiselect v-model="session.selected"
                                     placeholder="Wybierz posiedzenie"
                                     :close-on-select="true"
                                     class="multiselect-green"
                                     :searchable="true"
                                     :object="true"
                                     :options="session.available"
                                     noResultsText="brak wyników"
                                     noOptionsText="brak opcji"
                                     style="min-width: 280px; padding: 12.5px 16px;" />
                    </section>
                    <section class="esesja-modal__col-double">
                        <button class="esesja-modal__wraper__body__bottom-button" @click="addPointDestination">Dodaj</button>
                    </section>
                </section>

                <section class="esesja-modal__row">
                    <table class="table-bootstrap table-bootstrap-hover table-bootstrap-bordered table-bootstrap-striped align-middle" v-if="destinationSession.length > 0">
                        <thead>
                            <tr class="align-middle">
                                <th></th>
                                <th>Sesja</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in destinationSession" :key="index" class="align-middle">
                                <td>{{index + 1 }}</td>
                                <td>{{item.label}}</td>
                                <td>
                                    <button class="
                        esesja-modal__wraper__body__bottom-button
                        esesja-modal__wraper__body__bottom-button--red
                    "
                                            @click="removeDestinationItem(index)">
                                        <img src="../../../../../assets/icons/components/bin.svg"
                                             class="esesja-modal__wraper__body__bottom-button-icon" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span v-else>Brak wybranych posiedzień</span>
                </section>

                <section class="esesja-modal__row">
                    <section class="esesja-modal__col-double">
                        <label class="edit-meeting__label">Opcje kopiowania punktu</label>
                    </section>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-votings" v-model="requestData.options.withVotings" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-votings">Głosowania</label> <span style="font-size: 11px;">(głosowaniom zostaną przypisane wszystkie grupy docelowego posiedzenia)</span>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-attachemnts" v-model="requestData.options.withAttachemnts" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-attachemnts">Załączniki</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-subpoints" v-model="requestData.options.withSubpoints" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-subpoints">Z podpunktami</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-applicant" v-model="requestData.options.withApplicant" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-applicant">Z wnioskodawcą</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-reporter" v-model="requestData.options.withReporter" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-reporter">Z referującym</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-opinion" v-model="requestData.options.withOpinion" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-opinion">Z opiniującymi</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-responsible" v-model="requestData.options.withResponsible" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-responsible">Z komisjami odpowiedzialnymi</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-notes" v-model="requestData.options.withNotes" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-notes">Z notatkami</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-links" v-model="requestData.options.withLinks" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-links">Z linkami</label>
                    </div>
                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="copy-point-with-statements" v-model="requestData.options.withStatements" />
                        <label class="form-bootstrap-check-label" for="copy-point-with-statements">Z wypowiedziami</label>
                    </div>
                </section>

                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button"
                            @click="this.$store.commit('hideCopySessionPoint')">
                        Anuluj
                    </button>
                    <button class="
                        esesja-modal__wraper__body__bottom-button
                        esesja-modal__wraper__body__bottom-button--red
                    "
                            :disabled="isSaveIsDisable()"
                            @click="proceedCopyPoint">
                        Zapisz
                    </button>
                </section>
            </perfect-scrollbar>

        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
    import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
    import Multiselect from '@vueform/multiselect'
    import {
        switchToRoman,
        switchIndexToLetter,
    }
    from "../../../../../helpers/index.conversion";
    import moment from "moment";
    import SessionService from "../../../../../services/session.service";
    import AlertHelper from "@/helpers/alerts.helper";
    export default {
        name: "CopyPoint",
        components: {
            LoaderInside,
            Multiselect
        },
        data() {
            return {
                isLoading: false,
                groups: {
                    available: [],
                    selected: {},
                },

                session: {
                    available: [],
                    selected: {},
                },

                destinationSession: [],

                requestData: {
                    pointId: -1,
                    destinationSessions: [],
                    options: {
                        withVotings: true,
                        withAttachemnts: true,
                        withSubpoints: true,
                        withNotes: true,
                        withLinks: true,
                        withStatements: false,
                        withApplicant: true,
                        withReporter: true,
                        withOpinion: true,
                        withResponsible: true,
                    }
                }
            };
        },

        mounted() {
            this.groups.available = this.$store.state.appStore.groups
                .filter(group => { return group.can_sessions })
                .map(item => {
                    return {
                        value: item.id,
                        label: item.name,
                    }
                })
        },

        watch: {
            groups: {
                handler(val) {
                    this.session.selected = {};
                    if (val.selected === undefined || val.selected === null || typeof val.selected !== 'object' || val.selected.value === undefined) {
                        this.session.available = [];
                    }
                    else {
                        this.getAvailableSessions(val);
                    }
                },
                deep: true
            }
        },

        methods: {

            getSessionName(item) {
                let sessionName = item.name;
                sessionName += " nr "
                sessionName += item.number !== null ? this.numberConversion(item.number) : "0"
                sessionName += " dnia "
                sessionName += this.getDate(item.meeting_date)
                return sessionName;
            },

            numberConversion(index) {
                switch (this.groups.selected.session_number_format_id) {
                    case 1:
                        return `${switchToRoman(index)}`;
                    case 2:
                        return `${index}.`;
                    case 3:
                        return `${index})`;
                    case 4:
                        return `${switchIndexToLetter(index)})`;
                    default:
                        return `${index}.`;
                }
            },

            getDate(date) {
                return `${moment(date).format(
                    "dddd"
                )}, ${moment(date).format("DD.MM.YYYY")}`;
            },

            getAvailableSessions(val) {
                let filterdSession = this.$store.state.appStore.groups
                    .filter(group => { return group.id === val.selected.value })

                if (filterdSession.length != 1)
                    return;

                this.session.available = filterdSession[0].sessions
                    .filter(item => {
                        return !this.destinationSession.some(obj => obj.value === item.id)
                    })
                    .map(item => {
                        return {
                            value: item.id,
                            label: this.getSessionName(item),
                        }
                    })
            },

            addPointDestination() {
                try {
                    if (this.session.selected === undefined || this.session.selected === null || typeof this.session.selected !== 'object' || this.session.selected.value === undefined) {
                        this.$store.commit("showError");
                        return;
                    }

                    this.destinationSession.push(this.session.selected);
                    this.session.selected = {};
                    this.getAvailableSessions(this.groups);

                }
                catch (e) {
                    console.warn(e);
                }
            },

            removeDestinationItem(index) {
                try {
                    this.destinationSession.splice(index, 1);
                    this.getAvailableSessions(this.groups);
                }
                catch (e) {
                    console.warn(e);
                }
            },

            isSaveIsDisable() {

                if (this.destinationSession.length > 0)
                    return false;

                return true;
            },

            async proceedCopyPoint() {
                try {
                    this.$store.commit("showLoader");
                    this.requestData.pointIds = this.$store.state.appStore.sessionCopyPointIds; 
                    this.requestData.destinationSessions = this.destinationSession.map(obj => obj.value);

                    let response = await SessionService.copyPointsBetweenSessions(this.requestData);
                    if (response.status == 200) {
                        this.destinationSession = [];
                        this.getAvailableSessions(this.groups);
                        this.$swal(AlertHelper.successAlert("Skopiowano punkty do wskazanych posiedzeń"));
                    }
                }
                catch (e) {
                    console.error(e);
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "../../../../../assets/scss/esesja-modal.scss";

    .esesja-modal__row-double {
        align-items: end;
    }

    .esesja-modal__row-double .esesja-modal__wraper__body__bottom-button
     {
        padding: 5px;
        width: 100px;
        height: 40px;
    }

    .table-bootstrap .esesja-modal__wraper__body__bottom-button {
        padding: 10px;
        width: 50px;
        height: 38px;
    }
    .table-bootstrap .esesja-modal__wraper__body__bottom-button .esesja-modal__wraper__body__bottom-button-icon {
        width: 18px;
        height: 18px;
    }

    .table-bootstrap .esesja-modal__wraper__body__bottom-button--red .esesja-modal__wraper__body__bottom-button-icon {
        filter: brightness(0) invert(1);
    }

    .multiselect-green {
        --ms-tag-bg: #D1FAE5;
        --ms-tag-color: #059669;
        padding: 12.5px 16px;
    }
</style>