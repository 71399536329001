import eSesjaSocketAction from "./esesja.socket.action";
import eSesjaDiscussionListState from "./esesja.discussion.list.state"
export class eSesjaSocketParserService {
    constructor() {

    }

    parse(message) {
        let internalData = decodeURI(message).split("|");
        let response = {
            action: {},
            data: {}
        };

        if (internalData.lenght < 1)
            throw new Error("Wrong message");


        switch (internalData[0]) {
            case "activepoint":
                response.action = eSesjaSocketAction.ActivePoint;
                response.data = this._parseActivePoint(internalData);
                break;
            case "glosowanie":
                response.action = eSesjaSocketAction.StartVoting;
                response.data = this._parseStartVoting(internalData);
                break
            case "cancelvote":
                response.action = eSesjaSocketAction.CancelVoting;
                break;
            case "voteresult":
                response.action = eSesjaSocketAction.SaveVotingResult;
                response.data = this._parseSaveVoting(internalData);
                break;
            case "api_dlistupdated":
            case "api_dlistchanged":
                response.action = eSesjaSocketAction.DiscussionListChanged;
                response.data = this._parseDiscussionListChanged(internalData);
                break;
            case "getactive":
                response.action = eSesjaSocketAction.GetCurrentDiscussionList;
                break;
            case "kw_open":
                response.action = eSesjaSocketAction.QuorumCheckStart;
                response.data = this._parseQuorumCheckStart(internalData);
                break;
            case "kw_close":
                response.action = eSesjaSocketAction.QuorumCheckEnd;
                response.data = this._parseQuorumCheckStart(internalData);
                break;
            case "obecny":
                response.action = eSesjaSocketAction.UserConfirmPresence;
                response.data = this._parseUserConfirmPresenceData(internalData);
                break;
            case "getresults":
                response.action = eSesjaSocketAction.GetCurrentVotingResult;
                break;

        }
        return response;
    }

    _parseActivePoint(data) {
        return {
            agendaItemId: parseInt(data[1])
        }
    }
    _parseStartVoting(data) {
        return {
            votingId: parseInt(data[1]),
            votingCode: data[2],
            isCheckPresence: parseInt(data[3]) === 1
        }
    }
    _parseSaveVoting(data) {
        return {
            votingId: parseInt(data[1]),
            sessionId: parseInt(data[2]),
            votingResultCode: data[3],
        }
    }
    _parseDiscussionListChanged(data) {
        let obj = {
            userId: parseInt(data[1]),
            state: {}
        }

        switch (parseInt(data[2])) {
            case 1: obj.state = eSesjaDiscussionListState.AddedToList; break;
            case 2: obj.state = eSesjaDiscussionListState.GotSpeechFromList; break;
            case 3: obj.state = eSesjaDiscussionListState.GotSpeechFromGuestList; break;
            case 4: obj.state = eSesjaDiscussionListState.RemovedFromList; break;
            case 5: obj.state = eSesjaDiscussionListState.EndSpeech; break;
        }

        return obj;
    }

    _parseQuorumCheckStart(data) {
        return {
            sessionId: parseInt(data[1])
        }
    }

    _parseUserConfirmPresenceData(data) {
        return {
            userId: parseInt(data[1]),
            sessionId: parseInt(data[2])
        }
    }
}
