<template>
    <section class="app-spa">
        <section class="app-spa__box">
            <section class="app-spa__box__logo">
                <img src="@/assets/imgs/logo.png" />
            </section>
            <section class="app-spa__box__form">
                <h1 class="app-spa__box__form__header">Resetowanie hasła</h1>
                <section class="app-spa__box__form__wrapper" v-if="!isDone">
                    <p class="app-spa__box__form__text">
                        Podaj adres E-Mail skojarzony z Twoim kontem <br /> aby uzyskać spersjonalizowany link do odzyskania hasła
                    </p>
                    <section class="app-spa__box__form__row">
                        <label for="user-email" class="app-spa__box__form__label">E-Mail</label>
                        <input type="email"
                               name="user-email"
                               id="user-email"
                               class="app-spa__box__form__input"
                               v-model="request.email"
                               placeholder="Wpisz swój adres E-Mail"
                               autocomplete="off"
                               @keyup.enter="getResetPassword" />
                    </section>
                    <ReCaptchaComponent v-model="request.captchaToken" />
                    
                    <section >
                        <button type="button"
                                class="app-spa__box__form__bottom-button"
                                @click="backToLoginPage">
                            Powrót do logowania
                        </button>

                        <button type="button"
                                class="app-spa__box__form__bottom-button app-spa__box__form__bottom-button--red"
                                :disabled="request.captchaToken === '' || request.email === ''"
                                @click="getResetPassword">
                            Resetuj hasło
                        </button>
                    </section>

                </section>
                <section v-else class="app-spa__box__form__text">
                    Jeśli adres e-mail zgadzam się z tym podanym w systemie <br /> w ciągu kilku chwil otrzymasz wiadomść z linkiem do resetowania hasła
                </section>
            </section>
        </section>
    </section>
</template>
<script charset="utf-8">
    import ReCaptchaComponent from "../../../BasicControls/ReCaptchaComponent.vue";
    import UserService from "../../../../services/user.service";

    export default {
        name: "UserForgotPassword",
        components: {
            ReCaptchaComponent 
        },
        computed: {
            
        },
        data() {
            return {
                request: {
                    email: "",
                    captchaToken: "",
                },

                isDone: false,

                recaptchaSettings: {
                    siteKey: '6LdriHonAAAAADnpvtlDnDjWY0wdv8wv-27uxYHp',
                    showRecaptcha: true,
                    loadingTimeout: 30000 // 30 seconds
                }
            }
        },
        mounted() {
        },
        methods: {
            async getResetPassword() {
                try {
                    this.isDone = false;
                    this.$store.commit("showLoader");
                    await UserService.forgotPasswordRequest(this.request);
                }
                finally {
                    this.$store.commit("hideLoader");
                    this.isDone = true;
                }
            },
            backToLoginPage() {
                this.$router.push({ name: 'Login' });
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "~@/assets/scss/esesja-app-spa.scss";
    .app-spa__box__form__wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .app-spa__box{
        max-width: 1024px;
    }
</style>