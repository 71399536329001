<template>
    <section class="search-item" @click="moveToResource">
        <section class="search-item-type">
            <img src="../../../../assets/icons/panel/header/contact.svg" />
        </section>
        <section class="search-item-body"> 
            Komunikat: {{item.resouceData.title}}
        </section>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "SearchAnnouncementItem",
        props: {
            item: {
                type: Object,
                require: true,
            }
        },
        methods: {
            async moveToResource() {
                this.$emit("closeSearch");
                await this.$router.push({ name: "announcement", params: { searchId: this.item.resouceData.id } });
            }
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/SearchEngineItems.scss';
</style>