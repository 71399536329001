<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">{{publicInfo.id == 0 ? 'Dodaj' : 'Edytuj'}} dane publiczne</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closePublicInfo');}">
                    <img src="../../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <h4 class="esesja-modal__wraper__header">Użytkownik: {{personName}}</h4><br />
                <label class="esesja-modal__wraper__body__label">Dyżur radnej/radnego</label>
                <section class="esesja-modal__row">
                    <input type="text"
                           placeholder="dyżur..."
                           class="esesja-modal__wraper__body__input"
                           v-model="publicInfoUpdate.duty" />
                </section>

                <label class="esesja-modal__wraper__body__label">Numer okręgu wyborczego</label>
                <section class="esesja-modal__row">
                    <input type="text"
                           placeholder="Okręg wyborczy..."
                           class="esesja-modal__wraper__body__input"
                           v-model="publicInfoUpdate.constituency" />
                </section>

                <label class="esesja-modal__wraper__body__label">Obszar regionu wyborczego</label>
                <section class="esesja-modal__row">
                    <input type="text"
                           placeholder="Obszar..."
                           class="esesja-modal__wraper__body__input"
                           v-model="publicInfoUpdate.constituencyDescription" />
                </section>

                <label class="esesja-modal__wraper__body__label">Klub, do którego należy radna/radny</label>
                <section class="esesja-modal__row">
                    <input type="text"
                           placeholder="Klub..."
                           class="esesja-modal__wraper__body__input"
                           v-model="publicInfoUpdate.club" />
                </section>

                <label class="esesja-modal__wraper__body__label">Publiczny telefon kontaktowy</label>
                <section class="esesja-modal__row">
                    <input type="text"
                           placeholder="Telefon..."
                           class="esesja-modal__wraper__body__input"
                           v-model="publicInfoUpdate.phone" />
                </section>

                <label class="esesja-modal__wraper__body__label">Publiczny adres email</label>
                <section class="esesja-modal__row">
                    <input type="text"
                           placeholder="Email..."
                           class="esesja-modal__wraper__body__input"
                           v-model="publicInfoUpdate.email"
                           v-error="{ errors, input_key: 'email' }" />
                    <ErrorMessage v-if="isError('email', this.errors)"
                                  :errorMsgs="passErrorMsg('email', this.errors)" />
                </section>

                <label class="esesja-modal__wraper__body__label">Adres ePuAP</label>
                <section class="esesja-modal__row">

                    <input type="text"
                           placeholder="http://"
                           class="esesja-modal__wraper__body__input"
                           v-model="publicInfoUpdate.epulap" />
                </section>


                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button"
                            @click="this.$emit('closePublicInfo')">
                        Anuluj
                    </button>
                    <button class="
                        esesja-modal__wraper__body__bottom-button
                        esesja-modal__wraper__body__bottom-button--red
                    "
                            @click="handleSaveClick">
                        Zapisz
                    </button>
                </section>
            </perfect-scrollbar>

        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import LoaderInside from "../../../../../BasicControls/LoaderInside.vue";
import ContactService from "../../../../../../services/contact.service";
import ErrorMessage from "../../../../../BasicControls/ErrorMessage.vue";
import {
    passErrorMsg,
    isError,
    setErrors,
    } from "../../../../../../formValidation/main";

export default {
    name: "PublicInfoModal",
    data() {
        return {
            isLoading: false,
            publicInfoUpdate: this.publicInfo,
            errors: [],
        };
    },
    props: {
        lid: Number,
        publicInfo: Object,
        personName: String
    },
    components: {
        LoaderInside,
        ErrorMessage
    },
    methods: {
        handleSaveClick() {
            this.isLoading = true;
            this.errors = [];

            if (this.validate(this.publicInfoUpdate) == false)
                return

            ContactService.addorupdatePublicUserInfo(this.lid, this.publicInfoUpdate).then(() => {
                this.$emit("reload");
                this.$emit("closePublicInfo");
                this.publicInfoUpdate = {};
            });

            this.isLoading = false;
        },
        validate(data) {
            var bo = true;
            var err = {};
            
            if (data.email != null && data.email.length > 0) {
                if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(data.email) == false) {
                    err.email = ['Niepoprawny format email']
                    bo = false
                }
            }

            if (!bo) {
                this.isLoading = false;
                this.errors = setErrors(err)
            }
            return bo
        },
        passErrorMsg,
        setErrors,
        isError,
    },
};
</script>

<style lang="scss" scoped>

    @import "@/assets/scss/esesja-modal.scss";
    

    .switch-section {
        display: flex;
        margin-bottom: 12px;
    }

    .tooltip {
        position: relative;
        left: 5px;
        top: -3px;
    }

    .tip-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        background-color: transparent;
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;

        &:hover .hint {
            opacity: 1;
            display: block;
        }
    }
</style>

