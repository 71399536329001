<template>
    <button @click="$emit('prevMonth')" class="action-btn">
        <img src="@/assets/icons/components/calendr-chevron-left.svg" class="action-btn--image" :width="width">
        <slot></slot>
    </button>
</template>
<script charset="utf">
export default {
    name: "ButtonCalendarLeft",
    emits: ['prevMonth'],
    props:{
        width:{
            type: Number,
            defalt: 16,
        }
    }
}
</script>
<style lang="scss">
.action-btn {
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}
</style>
