<template>
    <section class="additional-menu">
        <router-link :to="{ name: 'sessionSearchRoute', query: { _r: Date.now() } }" class="sessions__show-all">
            <span class="sessions__show-all__icon">
                <img src="../../../assets/icons/navMenu/show-all.svg" class="sessions__show-all__icon__icon" />
            </span>
            <span class="sessions__show-all__text">
                Pokaż wszystkie posiedzenia
            </span>
        </router-link>
        <ul class="additional-menu__groups">
            <li v-for="(group, key) in canSession()"
                :key="key"
                class="additional-menu__link-wrapper"
                :ref="`mainContainer${key}`"
                @mouseover="
                ()=>
                setMenuElementPosition(
                this.$refs[`mainContainer${key}`],
                this.$refs[`subMenu${key}`]
                )
                "
                >
                <router-link :to="{ name: 'sessionSearchRoute', query: { group: group.id } }">
                    <span class="additional-menu__link">{{ group.name }}</span>
                </router-link>
                <img src="../../../assets/icons/components/additional-arrow.svg"
                        class="additional-menu__arrow" />
                <div class="additional-menu__sub-wrapper"
                        :ref="`subMenu${key}`">
                    <MenuSessionList :groupID="group.id"
                                     :group="group"
                                     :sessions="group.sessions" />
                </div>
            </li>
            <SmallLoader v-if="isLoading" />
        </ul>
        <section class="additional-menu__add"
                 @click="$store.commit('showAddMeeting')">
            <SmallAddButtonRed />
            <span class="additional-menu__add-text">Dodaj posiedzenie</span>
        </section>
    </section>
</template>

<script charset="utf-8">
    import SmallAddButtonRed from "@/components/BasicControls/SmallAddButtonRed.vue";
    import MeetingService from "@/services/meetings.service";
    import MenuSessionList from "./MenuSessionList.vue";
    import SmallLoader from "@/components/BasicControls/SmallLoader.vue";
    import setMenuElementPosition from "@/helpers/setMenuElementPosition";

    export default {
        name: "AdditionalMenuMeetings",
        data() {
            return {
                isLoading: true,
            };
        },
        components: {
            SmallAddButtonRed,
            MenuSessionList,
            SmallLoader,
        },
        mounted() {
            MeetingService.groups(false).then((response) => {
                this.isLoading = false;
                this.$store.commit("setGroups", response.data);
            });
        },
        methods: {
            canSession() {
                return this.$store.state.appStore.groups.filter(
                    (group) => group.can_sessions
                );
            },
            setMenuElementPosition,
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/additional-menu.scss";

    .sessions {
        &__show-all {
            display: flex;
            flex-direction: row;
            padding-top: 14px;
            padding-bottom: 14px;
            column-gap: 5px;
            align-items: baseline;
            border-bottom: 1px solid #dce4ee;
            width: 100%;

            &__icon {
                display: flex;
                align-items: center;

                &__icon {
                    height: 15px;
                }
            }

            &__text {
                color: #000000;
            }
        }
    }
   
</style>
