import meetingsService from "../services/meetings.service";
import store from "../store/mainStore";
import SessionService from "../services/session.service"
import VotingService from "../services/voting.service";
import router from "../router/main-router.js"
import AlertHelper from '@/helpers/alerts.helper.js';
import Swal from 'sweetalert2/dist/sweetalert2.js';



export const meetingsActions = [
    {
        id: 0,
        action: "sessionEdit",
        name: "Edytuj porządek obrad",
        icon: "edit-session-list",
        link: "sessions",
        handler: () => { },
    },
    //{
    //    id: 1,
    //    action: "sessionInteractive",
    //    name: "Tryb sesji interaktywnej",
    //    icon: "display",
    //    handler: () => {},
    //},
    {
        id: 10,
        action: "",
        name: "Ustawienia posiedzenia",
        orderInteractive: 4,
        icon: "settings",
        handler: (id) => {
            store.commit("showEditMeeting", id);
        },
    },
    //{
    //    id: 3,
    //    action: "",
    //    name: "Przygotuj zaproszenia na posiedzenie",
    //    icon: "wallet",
    //    handler: () => {},
    //},

    {
        id: 15,
        action: "",
        brakeline: true,
        name: "Następne posiedzenie",
        order: 14,
        icon: "rotate-right",
        handler: async (id) => {
            try {
                store.commit("showLoader");
                let result = await SessionService.getNextPreviousSessionId(id, 2);
                if (result.status === 200) {
                    var s = result.data;
                    if (s > 0) {
                        var url = window.location.href.split(`sessions/${id}/`);
                        if (url.length > 0) {
                            router.push({ name: "sessionEdit", params: { sessionid: s, groupid: url[1] } });
                        }
                    }
                }
            }
            finally {
                store.commit("hideLoader");
            }
        }
    },

    {
        id: 16,
        action: "",
        name: "Poprzednie posiedzenie",
        order: 15,
        icon: "rotate-left",
        handler: async (id) => {
            try {
                store.commit("showLoader");
                let result = await SessionService.getNextPreviousSessionId(id, 1);
                if (result.status === 200) {
                    var s = result.data;
                    if (s > 0) {
                        var url = window.location.href.split(`sessions/${id}/`);
                        if (url.length > 0) {
                            router.push({ name: "sessionEdit", params: { sessionid: s, groupid: url[1] } });
                        }
                    }
                }
            }
            finally {
                store.commit("hideLoader");
            }
        }
    },

    {
        id: 20,
        action: "",
        order: 1,
        name: "Lista obecności",
        icon: "user-check-presence",
        handler: async (id) => {
            try {
                store.commit("showLoader");
                store.commit('showPresenceList', id);
            }
            finally {
                store.commit("hideLoader");
            }
        }
    },

    {
        id: 30,
        action: "",
        name: "Zapisz posiedzenie jako szablon",
        order: 10,
        icon: "save",
        handler: async (id) => {
            try {
                store.commit("showLoader");
                let result = await SessionService.saveSessionAsTemplate(id);
                if (result.status === 200) {
                    let response = await SessionService.getSessionTemplate();
                    if (response.status === 200) {
                        store.state.appStore.sessionTemplates = response.data;
                    }
                }
            }
            finally {
                store.commit("hideLoader");
            }
        }
    },
    {
        id: 40,
        action: "",
        name: "Duplikuj posiedzenie",
        order: 11,
        icon: "duplicate",
        handler: (id) => {
            store.commit("showDuplicateSession", id);
        }
    },
    {
        id: 50,
        action: "",
        name: "Pobierz projekt protokołu",
        order: 3,
        orderInteractive: 3,
        icon: "download",
        handler: async (id) => {
            try {
                store.commit("showLoader");
                let result = await SessionService.getExportedSessionProtocolToWord(id);
                if (result.status == 200) {
                    const contentType = result.headers['content-type'];
                    let a = document.createElement('a');
                    var blob = new Blob([result.data], { 'type': contentType });
                    a.href = window.URL.createObjectURL(blob);
                    var filename = result.headers['content-disposition'].split("filename=")[1];

                    filename = filename.substr(0, filename.indexOf(';'));
                    filename = filename.replaceAll('"', '');
                    a.download = filename
                    a.click();
                }
            }
            finally {
                store.commit("hideLoader");
            }
        },
    },
    {
        id: 60,
        action: "",
        name: "Dodaj protokół",
        order: 4,
        icon: "add-protocol",
        handler: (id) => {
            store.commit("showAddProtocol", id);
        },
    },
    {
        id: 64,
        action: "",
        name: "Przygotuj zaproszenia na posiedzenie",
        order: 8,
        icon: "pen-to-square",
        handler: (id) => {
            store.commit("setPrepareMeetingInvitationModalSessionId", id);
            store.commit("setPrepareMeetingInvitationModalStatus", true);
        },
    },
    {
        id: 65,
        action: "",
        name: "Wyślij materiały dotyczące posiedzenia",
        order: 8,
        icon: "paper-plane-top-regular",
        handler: (id) => {
            store.commit("setSendMeetingRegardingModalSessionId", id);
            store.commit("setSendMeetingRegardingModalStatus", true);
        },
    },

    {
        id: 67,
        action: "",
        name: "Odtwórz nagranie audio",
        orderInteractive: 6,
        order: 8,
        icon: "play",
        handler: async (id) => {
            try {
                store.commit("showLoader");
                let result = await SessionService.getSessionCodeToRecorded(id);
                if (result.status == 200 && result.data != null) {
                    var url = "https://ssl.esesja.pl/player/" + id + "/" + result.data;
                    window.open(url, "_blank");
                }
                else {
                    var t = Swal.mixin(AlertHelper.informationToastElement());
                    t.fire(AlertHelper.informationAlert('Brak nagrania audio do odtworzenia...'));
                }
            }
            catch {
                var t1 = Swal.mixin(AlertHelper.informationToastElement());
                t1.fire(AlertHelper.informationAlert('Brak nagrania audio do odtworzenia...'));
            }
            finally {
                store.commit("hideLoader");
            }
        },
    },

    {
        id: 68,
        action: "",
        name: "Nagraj audio z posiedzenia",
        orderInteractive: 7,
        order: 9,
        icon: "record-vinyl",
        handler: () => {
            store.commit('setRecorderOpenStatus', true);
        },
    },

    {
        id: 69,
        action: "",
        name: "Publikuj nagrania z posiedzenia",
        orderInteractive: 8,
        order: 8,
        icon: "video-arrow-up-right",
        handler: (id) => {
            store.commit("setAudioVideoLinkSessionId", id);
            store.commit("setAudioVideoLinkModalStatus", true);
        },
    },

    {
        id: 70,
        action: "",
        name: "Eksportuj porządek obrad do pliku .DOC",
        order: 5,
        icon: "export-to-doc",
        handler: async (id) => {
            try {
                store.commit("showLoader");
                let result = await SessionService.getExportedSessionToWord(id);
                if (result.status === 200) {
                    const contentType = result.headers['content-type'];
                    let a = document.createElement('a');
                    var blob = new Blob([result.data], { 'type': contentType });
                    a.href = window.URL.createObjectURL(blob);
                    var filename = result.headers['content-disposition'].split("filename=")[1];

                    filename = filename.substr(0, filename.indexOf(';'));
                    filename = filename.replaceAll('"', '');
                    a.download = filename
                    a.click();
                }
            }
            finally {
                store.commit("hideLoader");
            }
        },
    },
    {
        id: 75,
        action: "",
        name: "Raport z przeprowadzonych głosowań",
        order: 6,
        orderInteractive: 2,
        icon: "download",
        handler: async (id) => {
            try {
                store.commit("showLoader");
                let result = await VotingService.getVotingReportBySession(id);

                if (result.status === 200) {
                    const contentType = result.headers['content-type'];
                    let a = document.createElement('a');
                    var blob = new Blob([result.data], { 'type': contentType });
                    a.href = window.URL.createObjectURL(blob);
                    var filename = result.headers['content-disposition'].split("filename=")[1];

                    filename = filename.substr(0, filename.indexOf(';'));
                    filename = filename.replaceAll('"', '');
                    a.download = filename
                    a.click();
                }
            }
            finally {
                store.commit("hideLoader");
            }
        }
    },
    {
        id: 80,
        action: "",
        name: "Importuj porządek obrad",
        order: 2,
        icon: "export-to-doc",
        handler: (id) => {
            store.commit("showImportSessionAgenda", id);
        }
    },
    {
        id: 90,
        action: "",
        brakeline: true,
        name: "Pokaż metryczkę posiedzenia",
        order: 9,
        orderInteractive: 5,
        icon: "timer",
        handler: (id) => {
            store.commit("showSessionMetadata", id);
        },
    },
    {
        id: 95,
        action: "",
        name: "Eksportuj wyniki głosowania do BIP",
        order: 7,
        icon: "bip-simple",
        handler: (id) => {
            const actionToFire = () => {
                store.commit("showLoader");
                SessionService.exportSessionVotingsToBip(id).then((response) => {
                    store.commit("hideLoader");

                    if (response.status == 200) {
                        store.commit("showSuccess");
                    }
                    else {
                        store.commit("showError");
                    }

                }).catch(() => {
                    store.commit("hideLoader");
                });
            };
            store.commit("showConfirmationAlert", actionToFire);
        },
    }, 
    {
        id: 96,
        action: "",
        name: "Sterowanie kamerami",
        orderInteractive: 1,
        icon: "camera-web",
        handler: async (id) => {
            try {
                store.commit("showLoader");
                let result = await SessionService.getCameraTokenSSO(id);
                if (result.status == 200) {
                    var url = "https://" + store.state.settings.client.cameraApiAddress + ":" + result.data;
                    window.open(url, "_blank");
                }
            }
            finally {
                store.commit("hideLoader");
            }
        },
    },

    {
        id: 98,
        action: "",
        name: "Otwórz posiedzenie",
        order: 12,
        icon: "lock-open",
        handler: async (id) => {
            const actionToFire = () => {
                store.commit("showLoader");
                SessionService.unlockSelectedSession(id).then((response) => {
                    store.commit("hideLoader");

                    if (response.status == 200) {

                        meetingsService.groups(false).then((response) => {
                            store.commit("setGroups", response.data)
                        });

                        store.commit("showSuccess");
                        store.commit("setUnlockSessionStatus", true);
                    }
                    else {
                        store.commit("showError");
                    }

                }).catch(() => {
                    store.commit("hideLoader");
                });
            };
            store.commit("showConfirmationAlert", actionToFire);
        },
    },

    {
        id: 99,
        action: "",
        name: "Zamknij posiedzenie",
        order: 13,
        icon: "lock",
        handler: async (id) => {
            const actionToFire = () => {
                store.commit("showLoader");
                SessionService.lockSelectedSession(id).then((response) => {
                    store.commit("hideLoader");

                    if (response.status == 200) {

                        meetingsService.groups(false).then((response) => {
                            store.commit("setGroups", response.data)
                        });

                        store.commit("showSuccess");
                        store.commit("setLockSessionStatus", true);
                    }
                    else {
                        store.commit("showError");
                    }

                }).catch(() => {
                    store.commit("hideLoader");
                });
            };
            store.commit("showConfirmationAlert", actionToFire);
        },
    },

    {
        id: 100,
        action: "",
        name: "Usuń posiedzenie",
        icon: "delete",
        handler: (id) => {
            const actionToFire = () => {
                store.commit("showLoader");
                meetingsService.removeSession(id).then(() => {

                    meetingsService.groups(false).then((response) => {
                        store.commit("setGroups", response.data)
                    });

                    store.commit("hideLoader");
                    store.commit("showSuccess");
                    store.commit("setSessionDeletedStatus", true);

                }).catch(() => {
                    store.commit("hideLoader");
                });
            };
            store.commit("showConfirmationAlert", actionToFire);
        },
    },
];
