<template>
    <section class="interactive-view-discussion">
        <router-view></router-view>
        <eSesjaFooter />
    </section>
</template>
<script charset="utf-8">
    import eSesjaFooter from '../../Shared/eSesjaFooter.vue';

    export default {
        name: "InteractiveDiscussion",
        components: {
            eSesjaFooter,
        }
    }
</script>
<style lang="scss">
    .interactive-view-discussion {
        background: #e5e5e5;
        width: 100vw;
        height: calc(100vh - 10px);
        display: flex;
        padding: 10px 0px;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        overflow: hidden;
    }
</style>