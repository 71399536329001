<template>
    <section class="component" style="position: relative">
        <section class="component__header">
            <span class="component__header__title">
                Ustawienia głosowania
            </span>
        </section>
        <section class="component__body settings">
            <section class="settings__item">
                <section class="column-item in-row">
                    <section class="esesja-modal__row">
                        <div class="form-bootstrap-check form-bootstrap-switch">
                            <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                                   id="adminVoteForUser_id"
                                   v-model="$store.state.settings.client.adminVoteForUser"
                                   @change="updateAdminVoteForUser()" />
                        </div>
                    </section>
                    <label class="esesja-modal__wraper__body__label" for="adminVoteForUser_id">
                        Możliwość ręcznego odnotowywania głosu użytkownika
                    </label>
                </section>

                <section class="column-item in-row">
                    <section class="esesja-modal__row">
                        <div class="form-bootstrap-check form-bootstrap-switch">
                            <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                                   id="autoEndVotingAfterAllVoted_id"
                                   v-model="$store.state.settings.client.autoEndVotingAfterAllVoted"
                                   @change="updateAutoEndVotingAfterAllVoted()" />
                        </div>
                    </section>
                    <label class="esesja-modal__wraper__body__label" for="autoEndVotingAfterAllVoted_id">
                        Automatyczne kończenie głosowania
                    </label>
                </section>

                <section class="column-item in-row">
                    <section class="esesja-modal__row">
                        <div class="form-bootstrap-check form-bootstrap-switch">
                            <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                                   id="autoGoToNextVotingAfterSave_id"
                                   v-model="$store.state.settings.client.autoGoToNextVotingAfterSave"
                                   @change="updateAutoGoToNextVotingAfterSave()" />
                        </div>
                    </section>
                    <label class="esesja-modal__wraper__body__label" for="autoGoToNextVotingAfterSave_id">
                        Automatyczne przechodzenie do kolejnego głosowania
                    </label>
                </section>

                <section class="column-item in-row">
                    <section class="esesja-modal__row">
                        <div class="form-bootstrap-check form-bootstrap-switch">
                            <input class="form-bootstrap-check-input" type="checkbox" role="switch"
                                   id="autoStartNextVoting_id" v-model="$store.state.settings.client.autoStartNextVoting"
                                   @change="updateAutoStartNextVoting()" />
                        </div>
                    </section>
                    <label class="esesja-modal__wraper__body__label" for="autoStartNextVoting_id">
                        Automatyczne uruchamianie kolejnego głosowania
                    </label>
                </section>

                <section class="column-item in-row" style="padding: 0 10px;">
                    <section class="esesja-modal__row">
                        <select class="edit-point-form__input"
                                v-model="this.$store.state.settings.client.autoStartNextVotingTime"
                                @change="updateAutoStartNextVotingTime()"
                                :disabled="this.$store.state.settings.client.autoStartNextVoting == false">
                            <option v-for="s in automaticNextVotingTime" :value="s.id" :key="s.id"> {{ s.type }}</option>
                        </select>
                    </section>
                    <label class="esesja-modal__wraper__body__label" for="autoStartNextVoting_id">
                        Czas po którym uruchomi się kolejne głosowanie
                    </label>
                </section>
                <section class="column-item in-row" style="padding: 0 10px; justify-content: space-between;">
                    <section class="d-flex flex-column">
                        <section class="esesja-modal__row">
                            <SearchLabelComponent label="Adress IP uprawniony do głosowania" />
                            <InputComponent v-model="$store.state.settings.allowIpAddress" :isError="!isValidIp" :errorTooltip="allowedIpTooltip" />
                        </section>
                    </section>
                    <ButtonOutline @click="saveAllowedIp" color="light" :disable="!isValidIp">Zapisz</ButtonOutline>
                </section>
            </section>
        </section>
        <SmallLoader v-if="isLoading" />
    </section>
</template>
<script charset="utf-8">
import settingsService from "../../../../../services/settings.service";
import SmallLoader from "../../../../BasicControls/SmallLoader.vue";
import { validateIp } from "../../../../../helpers/utils";
import alertsHelper from "../../../../../helpers/alerts.helper";

import InputComponent from "../../../../BasicControls/InputComponent.vue";
import SearchLabelComponent from "../../../../BasicControls/SearchLabelComponent.vue";  
import ButtonOutline from "../../../../BasicControls/Buttons/ButtonOutline.vue";

export default {
    name: "VotingSettings",
    components: {
        SmallLoader,
        InputComponent,
        SearchLabelComponent,
        ButtonOutline
    },
    computed:{
        allowedIpTooltip(){
            if(!this.isValidIp){
                return "Adres IP nie jest prawidłowy";
            }
            return "";
        }
    },
    data() {
        return {
            isLoading: false,
            automaticNextVotingTime: [
                { id: 3, type: '3 sek.' },
                { id: 4, type: '4 sek.' },
                { id: 5, type: '5 sek.' },
                { id: 6, type: '6 sek.' },
                { id: 7, type: '7 sek.' },
                { id: 8, type: '8 sek.' },
                { id: 9, type: '9 sek.' },
                { id: 10, type: '10 sek.' },
            ],
            isValidIp: false,
        }
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this._internalGetAllowedIp();
        }
        finally {
            this.isLoading = false;
        }
    },
    watch:{
       '$store.state.settings.allowIpAddress':{
            immediate: true,
            handler(val){
                if(val === "" || val === undefined || val == null){
                    this.isValidIp = true;
                }
                else{
                    this.isValidIp = validateIp(val);
                }
            }
       }
    },
    methods: {
        async updateAutoEndVotingAfterAllVoted() {
            try {
                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.autoEndVotingAfterAllVoted = this.$store.state.settings.client.autoEndVotingAfterAllVoted;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async updateAutoGoToNextVotingAfterSave() {
            try {

                if (this.$store.state.settings.client.autoGoToNextVotingAfterSave == false && this.$store.state.settings.client.autoStartNextVoting == true) {
                    this.$store.state.settings.client.autoStartNextVoting = false;
                }

                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.autoGoToNextVotingAfterSave = this.$store.state.settings.client.autoGoToNextVotingAfterSave;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async updateAutoStartNextVoting() {
            try {
                this.isLoading = true;
                if (this.$store.state.settings.client.autoGoToNextVotingAfterSave == false && this.$store.state.settings.client.autoStartNextVoting == true) {
                    this.$store.state.settings.client.autoGoToNextVotingAfterSave = true;
                }
                let data = { ...this.$store.state.settings.client };
                data.autoStartNextVoting = this.$store.state.settings.client.autoStartNextVoting;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async updateAutoStartNextVotingTime() {
            try {
                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.autoStartNextVotingTime = this.$store.state.settings.client.autoStartNextVotingTime;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async updateAdminVoteForUser() {
            try {
                this.isLoading = true;
                let data = { ...this.$store.state.settings.client };
                data.adminVoteForUser = this.$store.state.settings.client.adminVoteForUser;

                await this._internalUpdateData(data);
            }
            finally {
                this.isLoading = false;
            }
        },

        async _internalUpdateData(data) {
            return await settingsService.updateClientSettings(data);
        },

        async _internalGetAllowedIp() {
            return await settingsService.getVotingAllowedIp();
        },

        async saveAllowedIp() {
            try {
                this.isLoading = true;

                await settingsService.setVotingAllowedIp(this.$store.state.settings.allowIpAddress);

                var t = this.$swal.mixin(alertsHelper.informationSuccessElement());
                t.fire(alertsHelper.successAlert('Adres IP zapisany poprawnie'));
            }
            finally {
                this.isLoading = false;
            }
        }
    }
}
</script>
<style lang="scss">
@import "@/assets/scss/user-profile-component.scss";

.in-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.in-row .esesja-modal__row {
    margin-bottom: 0px;
    margin-right: 10px;
}

.esesja-modal__wraper__body__label {
    cursor: pointer;
}

.settings {
    padding: 15px 10px;
}
</style>