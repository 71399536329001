<template>
    <section class="add-contact">
        <section class="add-contact-form">
            <button class="add-contact-form__exit-btn" @click="(e) => handler(e, 'none')">
                <img src="../../../../../../assets/icons/components/exit-btn.svg" />
            </button>
            <section class="add-contact-form__form-wrapper">
                <section class="add-contact__header">
                    <section class="add-contact-form__title">  {{ isEdit ? "Edytuj" : "Dodaj" }} kontakt </section>
                    <section class="import-from-db">
                        <ButtonWithIconContacts v-if="isEdit && phoneNumber.length > 0" icon="send-sms" text="SMS" @click="openSendSMSModal()" />
                        <ButtonWithIconContacts v-if="isEdit && mail.length > 0" icon="send-mail" text="e-mail" @click="openSendEmailModal()" />
                        <ButtonWithIconContacts v-if="isEdit && adminMainPrivilige" icon="lock" text="uprawnienia" @click="openPriviligesModal()" />
                    </section>
                </section>
                <section class="add-contact__body">
                    <section class="add-contact-form__inputs">
                        <section class="add-contact-form__header">
                            <h3 class="add-contact-form__title add-contact-form__title--small">
                                Dane kontaktowe <span v-if="storedUserEditData.id != null" style="font-size: 12px; font-weight: 400;">(ID: {{storedUserEditData.id}})</span>
                            </h3>
                        </section>
                        <section class="add-contact-form__body">
                            <section class="add-contact-form__row">
                                <section class="add-contact-form__col">
                                    <label class="add-contact-form__label">Imię</label>
                                    <input type="text"
                                           v-model="firstName"
                                           class="add-contact-form__input-text"
                                           v-error="{ errors, input_key: 'first_name' }" />
                                    <ErrorMessage v-if="isError('first_name', this.errors)"
                                                  :errorMsgs="
                                    passErrorMsg('first_name', this.errors)
                                " />
                                </section>
                                <section class="add-contact-form__col">
                                    <label class="add-contact-form__label">Nazwisko</label>
                                    <input type="text"
                                           v-model="lastName"
                                           class="add-contact-form__input-text"
                                           v-error="{ errors, input_key: 'last_name' }" />
                                    <ErrorMessage v-if="isError('last_name', this.errors)"
                                                  :errorMsgs="
                                    passErrorMsg('last_name', this.errors)
                                " />
                                </section>
                            </section>
                            <section class="add-contact-form__row">
                                <section class="add-contact-form__col">
                                    <label class="add-contact-form__label">Tytuł</label>
                                    <input type="text"
                                           v-model="title"
                                           class="add-contact-form__input-text" />

                                </section>
                                <section class="add-contact-form__col">
                                    <label class="add-contact-form__label">Funkcja</label>
                                    <input type="text"
                                           v-model="role"
                                           class="add-contact-form__input-text"
                                           v-error="{ errors, input_key: 'role' }" />
                                    <ErrorMessage v-if="isError('role', this.errors)" :errorMsgs="passErrorMsg('role', this.errors)" />

                                </section>
                            </section>
                            <section class="add-contact-form__row">
                                <section class="add-contact-form__col">
                                    <label class="add-contact-form__label">Telefon</label>
                                    <input v-model="phoneNumber"
                                           class="add-contact-form__input-text"
                                           v-error="{ errors, input_key: 'phone_number' }" />
                                    <ErrorMessage v-if="isError('phone_number', this.errors)"
                                                  :errorMsgs="
                                    passErrorMsg('phone_number', this.errors)
                                " />
                                </section>
                                <section class="add-contact-form__col">
                                    <label class="add-contact-form__label">E-mail</label>
                                    <input type="mail"
                                           v-model="mail"
                                           class="add-contact-form__input-text"
                                           v-error="{ errors, input_key: 'email' }" />
                                    <ErrorMessage v-if="isError('email', this.errors)"
                                                  :errorMsgs="passErrorMsg('email', this.errors)" />
                                </section>
                            </section>
                            <section class="add-contact-form__row-single">
                                <section class="add-contact-form__col-single">
                                    <label class="add-contact-form__label">Adres</label>
                                    <input type="text"
                                           v-model="street"
                                           class="add-contact-form__input-text" />

                                </section>

                            </section>
                            <section class="add-contact-form__row">
                                <section class="add-contact-form__col">
                                    <label class="add-contact-form__label">Miejscowość</label>
                                    <input type="text"
                                           v-model="city"
                                           class="add-contact-form__input-text" />

                                </section>
                                <section class="add-contact-form__col">
                                    <label class="add-contact-form__label">Kod pocztowy</label>
                                    <input type="text"
                                           v-model="postal"
                                           class="add-contact-form__input-text" />

                                </section>
                            </section>
                        </section>
                    </section>
                    <section class="add-contact-form__groups">
                        <section class="add-contact-form__header">
                            <h3 class="add-contact-form__title add-contact-form__title--small">
                                Członkostwo w grupach
                            </h3>
                        </section>
                        <section class="add-contact-form__body">
                            <ErrorMessage v-if="isError('group_ids', this.errors)"
                                          :errorMsgs="passErrorMsg('group_ids', this.errors)" />
                            <section v-for="group in avaliableGroups"
                                     :key="group.id"
                                     class="add-contact-form__checkbox">
                                <CheckBoxButton :id="group.id"
                                                :value="group.id"
                                                @setGroupId="
                                                (value)=>
                                    (this.checkedGroupsId = value)
                                    "
                                    :idsArray="checkedGroupsId"
                                    :checked="
                                    group.id === storedGroupId ||
                                    checkedGroupsId.indexOf(group.id) !== -1
                                    "
                                    />
                                    <label class="add-contact-form__label add-contact-form__label--checkbox" :for="group.id">{{ group.name }}</label>
                            </section>
                        </section>
                        
                    </section>
                </section>
                <section class="add-contact-form__bottom-btns">
                    <button class="add-contact-form__action-btn add-contact-form__action-btn--red" @click="(e) => handleAddContactClick(e)">
                        Zapisz kontakt
                    </button>
                    <button class="add-contact-form__action-btn" @click="(e) => handler(e, 'none')"> Anuluj </button>

                </section>
            </section>
            <SMSModal v-if="showSMSModal" @close="closeSMSModal" :options="smsOptions" :selected="selected" />
            <EmailModal v-if="showEmailModal" @closeEmail="closeEmailModal" :options="emailOptions" :selected="selectedEmail" />
            <PermissionModal v-if="showPermissionModal && adminMainPrivilige" @closePermission="closePermissionModal" :permission="permission" :lid="tempLid" />
        </section>
    </section>
</template>

<script>
    import CheckBoxButton from "../../../../../BasicControls/CheckBoxButton.vue";
    import ContactService from "../../../../../../services/contact.service";
    import ErrorMessage from "../../../../../BasicControls/ErrorMessage.vue";
    import ButtonWithIconContacts from "../../../../../BasicControls/ButtonWithIconContacts.vue";
    import { encryptStorage } from '../../../../../../services/encrypt.service';
    import SMSModal from "../../../Home/Modals/SendSMS.vue";
    import EmailModal from "../../../Home/Modals/SendEmail.vue";
    import PermissionModal from "../../Modals/AddContact/AddPermission.vue";
    import AlertHelper from '@/helpers/alerts.helper.js';
    import {
        passErrorMsg,
        isError,
        setErrors,
    } from "../../../../../../formValidation/main";

    export default {
        name: "AddContact",
        data() {
            return {
                permission: {},
                smsOptions: [],
                selected: [],
                emailOptions: [],
                selectedEmail: [],
                tempLid: 0,
                showSMSModal: false,
                showEmailModal: false,
                showPermissionModal: false,
                adminMainPrivilige: encryptStorage.getItem("priv").uprawnienia,
                firstName: "",
                lastName: "",
                title: "",
                role: "",
                phoneNumber: "",
                mail: "",
                street: "",
                houseNumber: "",
                localNumber: "",
                city: "",
                postal: "",
                checkedGroupsId: [],
                isEdit: false,

                city_error: {
                    status: false,
                    msg: "",
                },
                errors: [],
            };
        },
        components: {
            CheckBoxButton,
            ErrorMessage,
            ButtonWithIconContacts,
            SMSModal,
            EmailModal,
            PermissionModal,
        },
        props: {
            groups: Array,
            handler: Function,
            storedGroupId: Number,
            storedUserEditData: Object, 
        },
        computed: {
            avaliableGroups() {
                let copy = [...this.groups]
                return copy.sort((group) => {
                    if (this.checkedGroupsId.indexOf(group.id) === -1) {
                        return 1;
                    }
                    return -1;
                })
            }
        },
        mounted() {
            if (this.storedGroupId !== null) {
                this.checkedGroupsId.push(this.storedGroupId);
            }
            if (Object.keys(this.storedUserEditData).length > 0) {
                const {
                    first_name,
                    last_name,
                    role,
                    phone_number,
                    email,
                    street,
                    postal,
                    city,
                    title,
                    groups,
                } = this.storedUserEditData;

                this.title = title;
                this.firstName = first_name;
                this.lastName = last_name;
                this.role = role;
                this.phoneNumber = phone_number;
                this.mail = email;
                this.street = street;
                this.postal = postal;
                this.city = city;
                this.checkedGroupsId = groups;
                this.isEdit = true;
            }
        },
        methods: {
            validate(data) {

                var bo = true;
                var err = {};
                if (data.first_name == null || data.first_name.length <= 0) {
                    err.first_name = ['Pole wymagane']
                    bo = false
                }

                if (data.last_name == null || data.last_name.length <= 0) {
                    err.last_name= ['Pole wymagane']
                    bo = false
                }

                if (data.email.length > 0) {
                    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(data.email) == false) {
                        err.email = ['Niepoprawny format email']
                        bo = false
                    }
                }

                if (data.phone_number.length > 0) {
                    if (/^[0-9]+$/.test(data.phone_number) == false) {
                        err.phone_number = ['Niepoprawny format telefonu']
                        bo = false
                    }
                }

                if (data.role.length > 0) {
                    if (data.role.length > 150) {
                        err.role = ['Limit do 150 znaków']
                        bo = false
                    }
                }

                if (Object.keys(data.group_ids).length <= 0) {
                    err.group_ids = ['Zaznacz grup(ę)/(y)']
                    bo = false
                }

                if (!bo) {
                    this.$store.state.userStore.isLoading = false;
                    this.errors = setErrors(err)
                }
                return bo
            },
            handleAddContactClick(e) {
                e.preventDefault();
                this.errors = [];

                this.$store.state.userStore.isLoading = true;

                const data = {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    role: this.role,
                    phone_number: this.phoneNumber,
                    fax: "",
                    email: this.mail,
                    street: this.street,
                    postal: this.postal,
                    city: this.city,
                    title: this.title,
                    group_ids: this.checkedGroupsId,
                };
                if (this.validate(data) == false)
                    return

                if (Object.keys(this.storedUserEditData).length > 0) {

                    ContactService.updateContact(this.storedUserEditData.id, data)
                        .then((r) => {

                            if (r.status !== 200) {
                                if (r.status === 400 && r.data === 'Adres email validacja') {
                                    var t = this.$swal.mixin(AlertHelper.errorToastElement());
                                    t.fire(AlertHelper.errorToastAlert('Wskazany adres email nie jest poprawny lub jest już zajęty'));
                                }
                                this.$store.state.userStore.isLoading = false;
                            }
                            else {
                                this.reloadContacts();
                            }
                        
                        })
                        .catch((err) => {
                            this.$store.state.userStore.isLoading = false;

                            if (err.response.data === 'Adres email validacja') {
                                var t = this.$swal.mixin(AlertHelper.errorToastElement());
                                t.fire(AlertHelper.errorToastAlert('Wskazany adres email nie jest poprawny lub jest już zajęty'));
                            }
                            else {
                                this.errors = setErrors(err);
                            }
                        });
                } else {
                    ContactService.checkContactExist(data).then((res) => {
                        if (res.status === 200) {
                            if (res.data != null && res.data.length > 0) {

                                const actionToFire = () => {

                                    if (this.$store.state.appStore.selectedButtonActionId == 1) {
                                        // create new contact
                                        this.createContact(data);
                                    }
                                    else if (this.$store.state.appStore.selectedButtonActionId == 2) {
                                        // open edit selected user modal
                                        this.handler(null, "none");
                                        this.handler(null, 'add-contact', { id: res.data[0].id, action: 'edit-contact', });
                                        this.$store.state.userStore.isLoading = false;
                                    }
                                    else {
                                        // wrong id
                                        this.$store.state.userStore.isLoading = false;
                                    }  

                                    this.$store.state.appStore.selectedButtonActionId = 0;
                                };

                                var alertData = {
                                    mainText: this.createInformationToAlert(res.data),
                                   
                                };

                                if (res.data.length > 1) {
                                    alertData.buttons = [
                                        {
                                            id: 1,
                                            text: 'Utwórz nowy kontakt',
                                            color: '#d80418'
                                        },
                                    ];
                                }
                                else {
                                    alertData.buttons = [
                                        {
                                            id: 1,
                                            text: 'Utwórz nowy kontakt',
                                            color: '#d80418'
                                        },
                                        {
                                            id: 2,
                                            text: 'Edytuj konto odnalezionego użytkownika',
                                            color: '#122fa3'
                                        },
                                    ];
                                }

                                this.$store.commit("setAlertData", alertData);
                                this.$store.commit("showConfirmationAlertWithData", actionToFire);
                            }
                            else {
                                this.createContact(data);
                            }
                        }
                        else {
                            this.$store.state.userStore.isLoading = false;
                            this.errors = setErrors(res.data);
                        }
                    }).catch((err) => {
                        this.$store.state.userStore.isLoading = false;
                        this.errors = setErrors(err);
                    });
                }
            },
            createInformationToAlert(data) {
                var info = '';
                for (var i = 0; i < data.length; i++) {

                    info += "<p>Użytkownik (<b>" + data[i].first_name + " " + data[i].last_name + "</b>) już istnieje i posiada dane logowania.</p>";
                    info += "<p>Należy do grup:</p>";

                    for (var j = 0; j < data[i].groups.length; j++) {
                        info += "<p>- " + data[i].groups[j].name + "</p>";
                    }

                    info += "<br>"
                }

                info += "<p>Utworzenie nowego konta, o takim samym imieniu i nazwisku, jest poprawne tylko w przypadku, kiedy są to fizycznie dwie różne osoby.</p>";
                info += "<p>Jeśli jest to ta sama osoba, należy przypisać jej istniejące w systemie konto, do grupy, której ma być członkiem.</p>";
                info += "<p>W tym celu należy przejść do edycji konta tego użytkownika.</p>";

                return info;
            },
            createContact(data) {
                ContactService.createContact(data)
                    .then(() => {
                        this.reloadContacts();
                    })
                    .catch((err) => {
                        this.$store.state.userStore.isLoading = false;
                        this.errors = setErrors(err);
                    });
            },
            reloadContacts() {
                ContactService.show(false).then((response) => {
                    this.$emit("renewGroups", response.data);
                    this.firstName = "";
                    this.lastName = "";
                    this.title = "";
                    this.role = "";
                    this.phoneNumber = "";
                    this.mail = "";
                    this.street = "";
                    this.houseNumber = "";
                    this.localNumber = "";
                    this.city = "";
                    this.postal = "";
                    this.checkedGroupsId = [];
                    this.handler(null, "none");
                    this.$store.state.userStore.isLoading = false;
                });
            },
            async openSendSMSModal() {
                try {
                    var result = (await ContactService.getUsersToSmsOrEmail(1)).data;
                    if (Object.keys(result).length > 0) {
                        this.selected = [];
                        this.selected.push(this.storedUserEditData.id);
                        this.smsOptions = result;
                        this.showSMSModal = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async openSendEmailModal() {
                try {
                    var result = (await ContactService.getUsersToSmsOrEmail(2)).data;
                    if (Object.keys(result).length > 0) {
                        this.selectedEmail = [];
                        this.selectedEmail.push(this.storedUserEditData.id);
                        this.emailOptions = result;
                        this.showEmailModal = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async openPriviligesModal() {
                try {
                    var result = (await ContactService.getUserDataToEdit(this.storedUserEditData.id)).data;
                    if (Object.keys(result).length > 0) {
                        this.permission = result;
                        this.tempLid = this.storedUserEditData.id;
                        this.showPermissionModal = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            closeSMSModal() {
                this.showSMSModal = false;
                this.smsOptions = [];
                this.selected = [];
            },
            closeEmailModal() {
                this.showEmailModal = false;
                this.emailOptions = [];
                this.selectedEmail = [];
            },
            closePermissionModal() {
                this.showPermissionModal = false;
                this.permission = {};
                this.tempLid = 0;
            },
            passErrorMsg,
            setErrors,
            isError,
        },
    };
</script>

<style lang="scss" scoped>
    .add-contact {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 8;
        background: #00082c75;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #dde0e7;
            padding: 15px 0;
        }

        &__body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            column-gap: 20px;
        }
    }


    .add-contact-form {
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        padding: 32px 56px;
        min-width: 1200px;
        max-height: 90vh;
        padding-right: 0px;

        &__form-wrapper {
            max-height: 80vh;
            overflow-y: auto;
            padding-right: 56px;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }
        }

        &__exit-btn {
            border: 0px;
            background-color: transparent;
            position: relative;
            right: calc(-100% + 48px);
        }

        &__title {
            font-weight: 600;
            font-size: 28px;
            color: #00082c;

            &--small {
                font-size: 20px;
            }
        }

        &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #dde0e7;
            padding-top: 10px;
        }

        &__row {
            display: flex;
            justify-content: space-between;
        }

        &__row-single {
            display: flex;
            justify-content: space-between;
        }

        &__col {
            display: flex;
            flex-direction: column;
            margin-bottom: 5px;

            &:nth-child(1) {
                margin-right: 24px;
            }
        }

        &__col-duo {
            display: flex;
        }

        &__col-single {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
        }

        &__label {
            font-weight: 500;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            margin-bottom: 8px;

            &--checkbox {
                font-weight: normal;
                font-size: 16px;
                line-height: 144%;
                color: #333956;
                margin-left: 12px;
                margin-bottom: 0px;
                padding-left: 24px;
                z-index: 6;
                cursor: pointer;
            }
        }

        &__input-text {
            margin-bottom: 10px;
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            width: 100%;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;
            min-width: 238px;

            &:focus {
                border: 1px solid #00082c;
            }

            &--small {
                min-width: 107px;
                margin-top: 7px;
            }
        }

        &__col-double {
            &__label {
                font-weight: 500;
                font-size: 14px;
                line-height: 145%;
                color: #00082c;
                margin-bottom: 8px;
            }

            &:nth-child(1) {
                margin-right: 24px;
            }
        }

        &__inputs, &__groups {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
        }

        &__checkbox {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }

        &__check-icon {
            width: 0px;
            height: 24px;
            position: relative;
            opacity: 1;

            &:checked ~ .checkbox-mark {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:checked ~ .add-contact-form__label--checkbox {
                padding-left: 0px;
            }

            &:before {
                content: "";
                background: #ffffff;
                border: 1px solid #d1d6df;
                box-sizing: border-box;
                box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
                border-radius: 4px;
                width: 24px;
                height: 24px;
                position: relative;
                display: block;
                top: 0px;
                left: 0px;
                opacity: 1;
            }
        }

        &__bottom-btns {
            clear: both;
            border-top: 1px solid #dde0e7;
            padding: 15px 0px;
            display: flex;
            flex-direction: row-reverse;
            column-gap: 20px;
        }

        &__action-btn {
            padding: 18px 40px 17px;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            min-width: 238px;
            border: 1px solid #bbc2ce;
            background-color: transparent;
            transition: all 0.2s ease-in-out;
            float: right;


            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                border: 0px;
                background: #d80418;
                color: white;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: #ad0313;
                }
            }
        }
    }

.import-from-db {
    display: flex;    
}

.checkbox-mark {
    background: #00082c;
    border: 1px solid #00082c;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
    border-radius: 4px;
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 99;
    display: none;
    top: 0px;
    left: 0px;
    opacity: 1;
}
</style>
