export const speechTimerConfig = {
    availableOptions: [
        { label: "1 minuta", value: 60 },
        { label: "2 minuty", value: 120 },
        { label: "3 minuty", value: 180 },
        { label: "5 minut", value: 300 },
        { label: "10 minut", value: 600 },
        { label: "15 minut", value: 900 },
        { label: "20 minut", value: 1200 },
        { label: "30 minut", value: 1800 }
    ]
}