export function fileDownloadHelper(response) {
    const contentType = response.headers['content-type'];
    let a = document.createElement('a');
    const blob = new Blob([response.data], { 'type': contentType });
    a.href = window.URL.createObjectURL(blob);
    let filename = extractFilenameFromHeader(response.headers['content-disposition']);
    a.download = filename
    a.click();
}

export function extractFilenameFromHeader(header){
    if (header.includes('UTF')) {
        const filenames = header.split(";");
        return decodeURIComponent(filenames[2].trim().replaceAll("filename*=UTF-8''", ""));
    }
    else {
        let filename = header.split("filename=")[1];
        filename = filename.substr(0, filename.indexOf(';'));
        return filename.replaceAll('"', '');
    }
}