import ApiService from "./api.service";

class VotingService extends ApiService {
    getBasicVotingModesData() {
        return this.get(`Voting/getBasicVotingModesData`)
    }
    getVotingData(id, pid, sid) {
        return this.get(`Voting/getVotingData/${id}/${pid}/${sid}`)
    }
    getVotingMetadata(id, pid, sid) {
        return this.get(`Voting/getVotingMetadata/${id}/${pid}/${sid}`)
    }
    removeVoting(id, pid) {
        return this.delete(`Voting/removeVoting/${id}/${pid}`)
    }
    addOrUpdateVoting(pid, data) {
        return this.post(`Voting/addOrUpdateVoting/${pid}`, data)
    }
    addVotingToMultiplePoints(sid, data) {
        return this.post(`Voting/addVotingToMultiplePoints/${sid}`, data)
    }
    getVotingUsersList(sid, gid) {
        return this.get(`Voting/getVotingUsersList/${sid}/${gid}`)
    }
    getUsersForVotingExcluded(gid, sid) {
        return this.get(`Voting/getUsersForVotingExcluded/${gid}/${sid}`)
    }
    excludeUserFromVoting(gid, sid, data) {
        return this.post(`Voting/excludeUserFromVoting/${gid}/${sid}`, data)
    }
    getVotingsPossibleAnswers(gid) {
        return this.get(`Voting/getVotingsPossibleAnswers/${gid}`)
    }
    getVotingsSummaryResults(gid, mode, isPublic, type) {
        return this.get(`Voting/getVotingsSummaryResults/${gid}/${mode}/${isPublic}/${type}`)
    }
    getVotingSummaryToWordFile(data) {
        return this.post(`Voting/getVotingSummaryToWordFile`, data, { responseType: "arraybuffer" })
    }
    getVotingInteractiveDataForResults(sessionId, pointId, votingId) {
        return this.get(`Voting/getVotingInteractiveDataForResults/${sessionId}/${pointId}/${votingId}`)
    }
    getVotingInteractiveData(sessionId, pointId, votingId) {
        return this.get(`Voting/getVotingInteractiveData/${sessionId}/${pointId}/${votingId}`)
    }
    reorderVotings(pointId, votings) {
        return this.post(`Voting/reorderVotings/${pointId}`, votings)
    }
    sendVoteForUserByAdmin(data, gid) {
        return this.post(`Voting/sendVoteForUserByAdmin/${gid}`,data)
    }
    getBlockVotingsResults(pointId, sessionId) {
        return this.get(`Voting/getBlockVotingsResults/${pointId}/${sessionId}`)
    }
    addMultipleVotings(pid, data) {
        return this.post(`Voting/addMultipleVotings/${pid}`, data)
    }
    editVotingName(pid, data) {
        return this.post(`Voting/editVotingName/${pid}`, data)
    }
    dragVotingToPoint(sid, data) {
        return this.post(`Voting/dragVotingToPoint/${sid}`, data)
    }
    exportVotingResultsToOtherSession(sid, data) {
        return this.post(`Voting/exportVotingResultsToOtherSession/${sid}`, data)
    }
    removeExportingVoting(sid, data) {
        return this.delete(`Voting/removeExportingVoting/${sid}`, data)
    }
    getExportVotingDefaultName(sid, pid, gid) {
        return this.get(`Voting/getExportVotingDefaultName/${sid}/${pid}/${gid}`)
    }
    getVotingReportBySession(sessionId) {
        return this.get(`Voting/getComputedVotingReportBySession/${sessionId}`, { responseType: "arraybuffer" });
    }
    getAllVotingsInSession(sid, data) {
        return this.post(`Voting/getAllVotingsInSession/${sid}`, data)
    }
}

export default new VotingService();