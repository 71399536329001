<template>
    <div class="results">
        <div class="result-info-msg" v-if="msg_result != null"><b :class="getVotingResultMessageClass(msg_result)">{{msg_result}}</b></div>
    </div>
</template>

<script>
    export default {
        name: "VotingResultMessage",
        data() {
            return {
                
            };
        },
        props: {
            msg_result: String,
        },
        methods: {
            getVotingResultMessageClass(item) {
                let classes = ""
                if (item === null)
                    return "";

                if (item.includes('Nie podjęto')) {
                    classes = "red-color-text";
                }
                else {
                    classes = "green-color-text";
                }
                
                return classes;
            },
        },
    };
</script>

<style lang="scss" scoped>

    .red-color-text {
        color: #e40030;
    }

    .green-color-text {
        color: #79e563;
    }

    .result-info-msg {
        margin-top: 15px;
        font-size: 24px;
    }

    .results {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 10px;
        justify-content: center;
    }

</style>