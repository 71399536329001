<template>
    <section class="session-search-context-menu">
        <router-link v-for="(action, index) in actions"
                     :key="index"
                     :to="{name: action.action, params: { sessionid: sessionId, groupid: this.groupId }}"
                     class="session-search-context-menu__item" :class="{'borderline' : globalbrakeline && action.brakeline }"
                     @click="menuItemClickHanlder(action)">
            <img :src="require(`@/assets/icons/meetingsActions/${action.icon}.svg`)"
                 class="session-search-context-menu__item__icon" />
            <span class="session-search-context-menu__item__text">{{action.name}}</span>
        </router-link>
    </section>
</template>
<script charset="utf-8">
    import { meetingsActions } from "@/componentsData/meetingsActions.js";
    export default {
        emits: ["actionSelected"],
        name: "SessionSearchContextMenu",
        props: {
            sessionId: Number,
            groupId: Number,
            skippedActionId: {
                type: Array,
                default() {
                    return [];
                }
            },
            globalbrakeline: Boolean,
            globalSingleSessionSelected: Boolean
        },
        data() {
            return {
            };
        },
        computed: {
            actions() {
                if (this.skippedActionId.length > 0) {
                    if (this.globalSingleSessionSelected) {
                        if (this.$store.state.appStore.isInInteractiveMode) {
                            return meetingsActions.filter(item => {
                                return !(this.skippedActionId.indexOf(item.id) !== -1)
                            }).sort((a, b) => (a.orderInteractive > b.orderInteractive) ? 1 : ((b.orderInteractive > a.orderInteractive) ? -1 : 0));
                        }
                        else {
                            return meetingsActions.filter(item => {
                                return !(this.skippedActionId.indexOf(item.id) !== -1)
                            }).sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
                        }
                    }
                    else {
                        return meetingsActions.filter(item => {
                            return !(this.skippedActionId.indexOf(item.id) !== -1)
                        });
                    }
                }
                else {
                    return meetingsActions;
                }
                 
            }
        },
        methods: {
            menuItemClickHanlder(item) {
                item.handler(this.sessionId);
                this.$emit("actionSelected");
            },
        }
    }
</script>
<style lang="scss" scoped>

    .borderline {
        border-top: 1px solid rgb(217, 217, 217);
    }

    .session-search-context-menu {
        padding: 12px 0px;
        position: absolute;
        z-index: 128;
        background-color: white;
        border-radius: 12px;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 16px 32px 48px rgba(0, 8, 44, 0.12);
        display: none;

        &__item {
            padding: 12px 24px;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            

            &__icon {
                width: 20px;
                height: 20px;
            }

            &__text {
                margin-left: 12px;
                font-weight: 500;
                font-size: 14px;
                line-height: 132%;
                color: #00082c;

                &--red {
                    color: #d80418;
                }
            }

            &:hover {
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }
</style>