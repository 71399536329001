<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <section class="esesja-pagination" v-if="totalItems > 0">
        <ul class="esesja-pagination__items">
            <li class="esesja-pagination__items__page">
                <span class="esesja-pagination__items__page__link"
                      :class="{'disabled': currentPage === 1}"
                      @click="internalGoToPage(1)">« Pierwsza</span>
            </li>
            <li class="esesja-pagination__items__page">
                <span class="esesja-pagination__items__page__link"
                      :class="{'disabled': currentPage === 1}"
                      @click="internalGoToPage(currentPage - 1)">‹ Poprzednia</span>
            </li>
            <li class="esesja-pagination__items__page"
                v-for="item in pageToDisplay"
                :key="item">
                <span class="esesja-pagination__items__page__link"
                      :class="{'active' : item === currentPage}"
                      @click="internalGoToPage(item)">{{item}}</span>
            </li>
            <!--<li class="esesja-pagination__items__page"
                v-else
                v-for="index in maxPages"
                :key="index">
                <span class="esesja-pagination__items__page__link"
                      :class="{'active' : index === currentPage}"
                      @click="internalGoToPage(index)">{{index}}</span>
            </li>-->
            <li class="esesja-pagination__items__page">
                <span class="esesja-pagination__items__page__link"
                      :class="{'disabled': currentPage === maxPages}"
                      @click="internalGoToPage(currentPage + 1)">Następna ›</span>
            </li>
            <li class="esesja-pagination__items__page">
                <span class="esesja-pagination__items__page__link"
                      :class="{'disabled': currentPage === maxPages}"
                      @click="internalGoToPage(maxPages)">Ostatnia »</span>
            </li>
        </ul>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "Pagination",
        emits: ["goToPage"],
        props: {
            totalItems: Number,
            currentPage: Number,
            itemsPerPage: Number,
        },
        computed: {

        },
        data() {
            return {
                offset: 5,
                pageToDisplay: [],
                maxPages: 0,
            }
        },

        watch: {
            currentPage: {
                deep: true,
                handler: function () {
                    this.pages();
                    this.availablePages();
                }
            },
            itemsPerPage: {
                deep: true,
                handler: function () {
                    this.pages();
                    this.availablePages();
                }
            },
            totalItems: {
                deep: true,
                handler: function () {
                    this.pages();
                    this.availablePages();
                }
            }
        },
        created() {
            
        },
        mounted() {
            this.pages();
            this.availablePages();
        },
        methods: {
            internalGoToPage(internalPage) {
                if (internalPage === parseInt(this.currentPage))
                    return;

                if (internalPage > this.maxPages)
                    return;

                if (internalPage < 1)
                    return;

                this.$emit('goToPage', internalPage);
            },
            pages() {
                this.maxPages =  Math.ceil(this.totalItems / this.itemsPerPage);
            },

            availablePages() {
                let doubleOffset = this.offset * 2;
                this.pageToDisplay = [];
                let internalMinPage = parseInt(this.currentPage) - this.offset;
                let internalMaxPage = parseInt(this.currentPage) + this.offset;
                if (parseInt(this.maxPages) <= doubleOffset) {
                    internalMinPage = 1;
                    internalMaxPage = this.maxPages
                }
                else {
                    let maxAvailablePage = this.maxPages;

                    if (internalMinPage < 1) {
                        internalMinPage = 1;
                    }

                    if (internalMaxPage > maxAvailablePage) {
                        internalMaxPage = maxAvailablePage;
                    }

                    let currentOffset = internalMaxPage - internalMinPage;

                    if (currentOffset < doubleOffset) {
                        let diff = doubleOffset - currentOffset;
                        if ((internalMinPage - diff) - 1 < 0) {
                            internalMaxPage += diff;

                        }
                        else if ((internalMaxPage + diff) + 1 > maxAvailablePage) {
                            internalMinPage -= diff
                        }
                    }

                    if (internalMinPage < 1) {
                        internalMinPage = 1;
                    }

                    if (internalMaxPage > maxAvailablePage) {
                        internalMaxPage = maxAvailablePage;
                    }

                   
                }

                for (let index = internalMinPage; index <= internalMaxPage; index++) {
                    this.pageToDisplay.push(index)
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .esesja-pagination {
        display:flex;
        align-items:center;
        justify-content:center;

        &__items {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            display: flex;
            padding-left: 0;
            list-style: none;
            border-radius: 0.25rem;

            &__page {

                &__link {
                    cursor: pointer;
                    position: relative;
                    display: block;
                    padding: 0.5rem 0.75rem;
                    margin-left: -1px;
                    line-height: 1.25;
                    color: #00082c;
                    background-color: #fff;
                    border: 1px solid #bbc2ce;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        background-color: #e8ebef;
                    }
                }

                .active {
                    cursor: default;
                    background-color: #e8ebef !important;
                }

                .disabled {
                    cursor: default;
                    background: #fff !important;
                    color: rgba(16, 16, 16, 0.3) !important;
                }
            }
        }
    }
</style>