<template>
    <section class="component">
        <section class="component__header">
            <span class="component__header__title">
                Aplikacja sterowania kamerami
            </span>
            <section class="component__header__action">
                <button class="component__button
                               component__button--small
                               component__button--white"
                        @click="changeIp" v-tooltip="'Ustaw adres IP aplikacji kamer'" v-if="camModule == 1">
                    <img src="@/assets/icons/components/edit.svg" />
                    <span class="component__button__text">Zmień</span>
                </button>
            </section>

        </section>
        <section class="avatar-info__item">
            <section class="avatar-info__item__label">Wybrana adresacja aplikacji kamer:</section>
        </section>
        <section class="component__body avatar">
            <section>
               {{addressIp}}
            </section>
        </section>

        <Transition>
            <CameraIpModal v-if="$store.state.modals.settings.cameraIpSettingsModalStatus" :ip="addressIp" @setIPAddress="setIPAddress" />
        </Transition>

    </section>
</template>
<script charset="utf-8">
    import SettingsService from "@/services/settings.service";
    import CameraIpModal from "./Modals/SetCameraAddressModal.vue";
    import { encryptStorage } from '@/services/encrypt.service';
    export default {
        name: "CameraAPiAddressSettings",
        components: {
            CameraIpModal
        },
        data() {
            return {
                addressIp: this.$store.state.settings.client.cameraApiAddress,
                camModule: encryptStorage.getItem('modules').cam,
            }
        },
        mounted() {
            
        },
        methods: {
            changeIp() {
                this.$store.commit('setCameraIpSettingsModalStatus', true);
            },
            async setIPAddress(address) {
                try {
                    this.$store.commit("showLoader");

                    let request = {
                        cameraApiAddress: address,
                    }

                    let response = await SettingsService.setCameraApiAddress(request);
                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }

                    this.addressIp = address;
                    this.$store.commit('seCameraApiAddress', address);
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'CameraAPiAddressSettings' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.showAvatarCropModal = false;
                    this.$store.commit("hideLoader");
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/user-profile-component.scss";

    .component {
        width: 400px;
        height: fit-content;
    }

    .avatar {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 15px 20px;
        align-items: center;
        justify-content: center;
        font-size: 24px;
    }

    .avatar-info {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        &__item {
            display: flex;
            flex-direction: row;
            column-gap: 10px;
            padding: 10px;
            text-align: center;

            &__label {
                font-weight: 800;
            }
        }
    }
</style>