<template>
    <section class="inline-loader">
        <section class="inline-loader__spin"></section>
    </section>
</template>
<script charset="utf-8">
    export default {
        name: "InlineLoader"
    }
</script>
<style lang="scss" scoped>
    .inline-loader {
        width: 25px;
        &__spin {
            border: 3px solid #f3f3f3;
            border-top: 3px solid #004dcc;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            animation: spin 2s linear infinite;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>