<template>
    <section class="add-note">
        <section class="add-note-form">
            <button class="add-note-form__exit-btn"
                    @click="
                    (e)=>
                {
                e.preventDefault();
                this.$emit('closePassResolution');
                }
                "
                >
                <img src="../../../../../assets/icons/components/exit-btn.svg" />
            </button>
            <h2 class="add-note-form__title">Podjęcie uchwały</h2>
            <label>{{name}}</label>
            <section class="add-note-form__input-wrapper">
                <label class="add-note-form__label">Numer uchwały</label>
                <input type="number"
                       placeholder="Wpisz numer uchwały..."
                       class="add-note-form__input"
                       v-model="number" />
            </section>

            <section>
                <div class="form-bootstrap-check form-bootstrap-switch">
                    <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="pass-resolution" v-model="isPass" />
                    <label class="form-bootstrap-check-label" for="pass-resolution">Podjąć uchwałę?</label>
                    <section name="externalTrigger2"
                             class="tip-btn">
                        <img src="../../../../../assets/icons/components/tooltip.svg"
                             class="tooltip" />
                        <Hint title="Podjęcie uchwały"
                              text="Aktywny oznacza, że uchwała zostanie podjęta. Odznaczenie spowoduje usunięcie z rejestru uchwał, jeżeli uchwała została podjęta wcześniej." />
                    </section>
                </div>
            </section>

            <section class="add-note-form__bottom">
                <button class="add-note-form__bottom-button"
                        @click="this.$emit('closePassResolution')">
                    Anuluj
                </button>
                <button class="
                        add-note-form__bottom-button
                        add-note-form__bottom-button--red
                    "
                        @click="handleSaveClick" :disabled="name.length == 0">
                    Zapisz
                </button>
            </section>
        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script>
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import Hint from "../../../../BasicControls/Hint.vue";
import RegisterService from "../../../../../services/register.service";
import AlertHelper from '@/helpers/alerts.helper.js';

export default {
    name: "PassResolutionModal",
    data() {
        return {
            name: this.resolution.name,
            number: this.resolution.number,
            isLoading: false,
            isPass: this.resolution.isPass
        };
    },
    props: {
        pointID: Number,
        resolution: Object,
        sid: Number
    },
    components: {
        LoaderInside,
        Hint
    },
    methods: {
        handleSaveClick() {
            this.isLoading = true;
            const data = {
                id: this.resolution.id,
                name: this.name,
                number: this.number,
                isPass: this.isPass
            };
            RegisterService.saveRegister(this.resolution.attId, this.pointID, this.sid, data)
                .then((r) => {

                     if (r.status !== 200) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert(r.data));              
                     }
                    
                    this.cleanResult();
                }).catch((error) => {

                    if (error.response.status === 404) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert(error.response.data));
                    }
                    
                    this.cleanResult();
                });
        },
        cleanResult() {
            this.name = "";
            this.isPass = false;
            this.number = 0;
            this.$emit("reload");
            this.$emit("closePassResolution");
            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
    .add-note {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 15;
    }

    .add-note-form {
        padding: 32px 56px;
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        max-height: 90vh;
        overflow-y: auto;
        width: 630px;

        &::-webkit-scrollbar {
            width: 12px;
            height: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background: #b5b8bd;
            border-radius: 30px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #b5b8bd;
        }

        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 30px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }

        &__exit-btn {
            border: 0px;
            background-color: transparent;
            position: relative;
            right: -100%;
        }

        &__title {
            font-weight: 600;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 32px;
        }

        &__input-wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
        }

        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
        }

        &__input {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            margin-top: 8px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;
            margin-bottom: 16px;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__wyswig {
            margin-top: 8px;
            height: 170px;
            max-height: 170px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            border-radius: 4px;
        }

        &__bottom {
            display: flex;
            justify-content: space-between;
        }

        &__bottom-button {
            padding: 18px 40px 17px;
            width: 238px;
            height: 56px;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
            background-color: transparent;
            margin-top: 20px;
            background: none;

            &--red {
                border: 0px;
                background: #d80418;
                color: white;
            }

            &--red:disabled {
                border: 0px;
                background: #D3D3D3;
                color: white;
                cursor: default;
            }
        }
    }

    .switch-section {
        display: flex;
        margin-bottom: 0px;
        margin-top: 10px;
    }

    .tooltip {
        position: relative;
        left: 5px;
        top: -3px;
    }

    .tip-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        background-color: transparent;
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;

        &:hover .hint {
            opacity: 1;
            display: block;
        }
    }
</style>

<style lang="scss">
    .ProseMirror {
        height: 160px;
        max-height: 160px;

        h1 {
            font-size: 32px;
        }

        h2 {
            font-size: 24px;
        }

        p {
            font-size: 16px;
        }
    }
</style>
