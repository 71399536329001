<template>
    <img :src="require(`@/assets/icons/components/${icon}.svg`)"
         class="btn-component__icon" />
</template>
<script charset="utf-8">
    export default {
        name: "ButtonIcon",
        props: {
            icon: {
                type: String
            }
        }
    }
</script>
<style lang="scss">
    .btn-component__icon{
        width: 18px;
    }
</style>